import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

class OldDetails extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  handleClick = (event) => {
    const link = closestTag(event.target, 'a')

    if (link && link.className.includes('btn-back')) {
      const url = this.backUrl()
      if (url) {
        this.props.history.push(url)
      } else {
        this.props.history.goBack()
      }
      return true
    }
    return false
  }

  alterIframeDocument = (iframeDocument) => {
    const backLink = iframeDocument.querySelector('a.btn-back')

    if (backLink) {
      const textNode = backLink.childNodes[1]
      textNode.nodeValue = 'Back'
    }
  }

  backUrl() {
    return this.props.location.query.backToPath
  }

  render() {
    const listingDetailsUrl = `/listings/${this.props.match.params.listingId}`

    return (
      <OldInterfaceIframe
        url={listingDetailsUrl}
        onClick={this.handleClick}
        alterIframeDocument={this.alterIframeDocument}
        location={this.props.location}
      />
    )
  }
}
export default connect()(OldDetails)
