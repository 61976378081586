import css from "./List.sass";
import React, { useEffect, useRef } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { connect } from "react-redux";
import { isEqual, pick, isEmpty } from "lodash";

import { fetchPeople } from "~brokerage/actions/people";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import Loader from "~brokerage/components/shared/Loader";
import Spinner from "~brokerage/components/shared/Spinner";
import { CATEGORIES_SEARCH_PARAMS } from "~brokerage/constants/people/categories";
import ListItem from "./ListItem";
import TableHeader from "./TableHeader";
import { buildNoContactsMessage } from "./utils";
import { INFINITE_SCROLL_DELAY } from "./constants";

const List = ({
  people,
  currentPage,
  totalPages,
  isFetching,
  dispatch,
  location,
  isBrokerageTabOpenedByBroker,
  hideEmail,
  hidePhone,
  isFirstPage
}) => {
  const fetchPeopleTimeout = useRef(null);
  const prevQueryRef = useRef();

  const hasNextPage = currentPage < totalPages;

  const dispatchPeopleFetching = action => {
    if (fetchPeopleTimeout.current) {
      clearTimeout(fetchPeopleTimeout.current);
    }

    if (isFetching) {
      fetchPeopleTimeout.current = setTimeout(
        () => dispatchPeopleFetching(action),
        200
      );
    } else {
      dispatch(action);
    }
  };

  const loadMorePeople = () => {
    dispatchPeopleFetching(fetchPeople(location.query, currentPage + 1));
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: loadMorePeople,
    delayInMs: INFINITE_SCROLL_DELAY
  });

  useEffect(() => {
    dispatchPeopleFetching(fetchPeople(location.query));
  }, []);

  useEffect(() => {
    const prevQuery = prevQueryRef.current;
    const currentQuery = location.query;

    const currentQueryValues = pick(currentQuery, CATEGORIES_SEARCH_PARAMS);
    const prevQueryValues = pick(prevQuery, CATEGORIES_SEARCH_PARAMS);

    if (!isEqual(prevQueryValues, currentQueryValues)) {
      dispatch(fetchPeople(location.query));
    }

    prevQueryRef.current = currentQuery;
  }, [location.query]);

  if (isFetching && isFirstPage) {
    return <Loader active={isFetching} />;
  }

  return (
    <div className={css.base} ref={rootRef}>
      {!isEmpty(people) ? (
        <>
          <table>
            <TableHeader
              location={location}
              hideEmail={hideEmail}
              hidePhone={hidePhone}
            />
            <tbody>
              {people.map(person => (
                <ListItem
                  key={person.id}
                  person={person}
                  isBrokerageTabOpenedByBroker={isBrokerageTabOpenedByBroker}
                  location={location}
                  hideEmail={hideEmail}
                  hidePhone={hidePhone}
                />
              ))}
            </tbody>
          </table>
          {hasNextPage && (
            <div className={css.hasMoreLoader} ref={sentryRef}>
              <Spinner />
            </div>
          )}
        </>
      ) : (
        <EmptyMessage>{buildNoContactsMessage(location)}</EmptyMessage>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    entities: people,
    page: currentPage,
    totalPages,
    isFetching,
    isBrokerageTabOpenedByBroker,
    hideEmail,
    hidePhone,
    isFirstPage
  } = state.people.list.people;

  return {
    people,
    currentPage,
    totalPages,
    isFetching,
    isBrokerageTabOpenedByBroker,
    hideEmail,
    hidePhone,
    isFirstPage
  };
};

export default connect(mapStateToProps)(List);
