import { CALL_API } from "~brokerage/middlewares/api";

export function fetchNotifications(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "notifications",
      params
    }
  };
}
