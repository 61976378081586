import css from "./Header.sass";
import { connect } from "react-redux";
import React, { useState } from "react";
import Button from "~brokerage/components/shared/Button";
import { callApi } from "~brokerage/middlewares/api";
import { fetchShowingRoute } from "~brokerage/actions/routes";
import { toast } from "react-toastify";

const Header = ({ route, dispatch, timesEqual }) => {
  const {
    id: routeId,
    status,
    unsavedChanges = [],
    optimized,
    showings = [],
    map_geometry
  } = route;
  const [loading, setLoading] = useState(false);

  const commitUnsavedChanges = () =>
    Promise.all(
      unsavedChanges.map(s => {
        const isStop = s.type === "stop";
        const origShowing =
          showings.find(showing =>
            isStop ? showing.id === s.id : showing.unique_id === s.id
          ) || {};
        const timeChanged = !timesEqual(
          origShowing.start_time,
          s.requestedTimeRaw
        );
        const params = {
          duration: origShowing.duration !== s.duration ? s.duration : null,
          travel_time:
            origShowing.travelTime !== s.travelTime ? s.travelTime : null,
          requested_time: timeChanged ? s.requestedTimeRaw : null
        };
        const sanitizedParams = {
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v != null)
          )
        };
        if (!isStop)
          return callApi(
            `showings/${s.id}/update_requested_time`,
            sanitizedParams,
            {},
            "patch"
          );
        else {
          const { address, coordinates } = s;
          const stop_id = s.id;
          const stopParams = {
            ...sanitizedParams,
            stop_id,
            address,
            coordinates
          };
          if (stop_id < 0) stopParams.create_stop = true;
          return callApi(
            `routes/${routeId}/add_update_stop`,
            {},
            stopParams,
            "put"
          );
        }
      })
    );

  const requestAllShowings = async () => {
    await callApi(`routes/${routeId}/request_to_all`, {}, {}, "put");
  };

  const handleRequest = async () => {
    const isOptimized =
      optimized && showings.some(s => s.unique_id === optimized);
    const persistedValues = isOptimized ? { optimized, map_geometry } : null;
    setLoading(true);
    try {
      await commitUnsavedChanges();
      await requestAllShowings();
      dispatch(fetchShowingRoute(routeId, persistedValues));
      setLoading(false);
      toast.success("Appointments requested.");
    } catch (error) {
      toast.error("Something went wrong. Please try again");
      console.log(error);
    }
  };

  const requestable =
    (showings && showings.some(s => s.status.toLowerCase() === "not_sent")) ||
    unsavedChanges.length > 0;

  const hasConflict = unsavedChanges.some(
    change => change.conflict === "unavailable"
  );

  const disableRequests =
    status !== "active" || loading || !requestable || hasConflict;

  return (
    <div className={css.layout}>
      <div className={css.right}>
        <Button
          variant="green"
          disabled={Boolean(disableRequests)}
          onClick={handleRequest}
        >
          Request Appointments
        </Button>
      </div>
    </div>
  );
};

export default connect()(Header);
