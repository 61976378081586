import css from "./index.sass";
import React, { useState, useEffect } from "react";
import {
  TEAM_MEMBER_NOTIFICATION_LEVELS,
  SEARCH_CHAR_LIMIT
} from "./constants";
import {
  buildAvailableMembers,
  shouldIncludeCalendarEvent,
  getNotificationLevelLabel,
  getAgentFullName,
  buildFormData
} from "./utils";

import {
  fetchAvailableMembers,
  clearAvailableAgents,
  disableSaveButton
} from "~brokerage/actions/listings";
import { Row, Col, Radio, Dropdown } from "~brokerage/components/shared/Form";
import Autocomplete from "~brokerage/components/shared/Autocomplete";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import PropTypes from "prop-types";

const TeamMemberAddForm = ({
  availableMembers,
  onCancel,
  onSubmit,
  person,
  notificationLevels,
  teamMembers,
  dispatch
}) => {
  const [formData, setFormData] = useState(buildFormData(person));
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (person) {
      setFormData(buildFormData(person));
    }
  }, [person?.userId]);

  const handleChange = ({ target: { value } }) => {
    if (value.length > SEARCH_CHAR_LIMIT) {
      dispatch(fetchAvailableMembers(value));
    } else {
      dispatch(clearAvailableAgents());
    }

    setSearchTerm(value);
  };

  const handleSelect = ({ value }) => {
    if (!value) return;

    const agent = availableMembers.find(agent => agent.agentId === value);
    if (agent) {
      dispatch(disableSaveButton());
      setFormData(previous => ({ ...previous, selectedAgent: agent }));
    }
  };

  const handleCalendarInviteChange = ({ target: { name, value } }) => {
    setFormData(previous => ({ ...previous, [name]: value === "true" }));
  };

  const handleDropdownChange = ({ value }) => {
    setFormData(previous => ({
      ...previous,
      ...{
        notificationLevel: value,
        includeCalendarEvent: shouldIncludeCalendarEvent(value)
      }
    }));
  };

  const handleSubmit = () => {
    const memberData = {
      ...selectedAgent,
      notificationLevelLabel: getNotificationLevelLabel(
        formData.notificationLevel,
        notificationLevels
      ),
      notificationLevel: formData.notificationLevel,
      includeCalendarEvent: formData.includeCalendarEvent
    };
    const isRemovable = memberData?.isRemovable ?? true;
    const memberDataWithPermissions = {
      ...memberData,
      isEditable: isRemovable,
      isRemovable
    };

    onSubmit(memberDataWithPermissions);
  };

  const autoCompleteOptions = buildAvailableMembers(
    availableMembers,
    teamMembers
  );

  const { notificationLevel, includeCalendarEvent, selectedAgent } = formData;
  const showCalendarEvent = shouldIncludeCalendarEvent(notificationLevel);
  const isEditing = !!person;

  return (
    <div className={css.formWrapper}>
      {!selectedAgent ? (
        <Autocomplete
          focused
          value={searchTerm}
          options={autoCompleteOptions}
          onSelect={handleSelect}
          onChange={handleChange}
        />
      ) : (
        <div>
          <Row offset="0">
            <div className={css.teamMember}>
              <div className={css.avatar}>
                <Avatar
                  src={selectedAgent.photoUrl}
                  name={getAgentFullName(selectedAgent)}
                />
              </div>
              {selectedAgent.name}
            </div>
          </Row>
          <Row offset="10">
            <div>
              <span className={css.notificationLevelLabel}>
                Notification preferences
              </span>
              <div className={css.notificationLevelDropdown}>
                <Dropdown
                  options={TEAM_MEMBER_NOTIFICATION_LEVELS}
                  selected={notificationLevel}
                  onChange={handleDropdownChange}
                  variant="outlineSellerTenantInvolvement"
                />
              </div>
              {showCalendarEvent && (
                <div className={css.includeCalendarInviteWrapper}>
                  <span className={css.calendarInviteLabel}>
                    Include calendar event
                  </span>
                  <div className={css.calendarInviteCheckbox}>
                    <Radio
                      value="true"
                      name="includeCalendarEvent"
                      checked={includeCalendarEvent}
                      onChange={handleCalendarInviteChange}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value="false"
                      name="includeCalendarEvent"
                      checked={!includeCalendarEvent}
                      onChange={handleCalendarInviteChange}
                    >
                      No
                    </Radio>
                  </div>
                </div>
              )}
            </div>
          </Row>
        </div>
      )}
      <Row>
        <Col>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        {notificationLevel && (
          <Col modifier="offset10">
            <Button variant="primary" onClick={handleSubmit}>
              <Icon name="userAdd" />
              {isEditing ? "Update Team Member" : "Save Team Member"}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

TeamMemberAddForm.PropTypes = {
  availableMembers: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  notificationLevels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  teamMembers: PropTypes.array,
  person: PropTypes.object,
  dispatch: PropTypes.func
};

export default TeamMemberAddForm;
