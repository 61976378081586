import {
  ATTACHMENT_CREATE_REQUEST,
  ATTACHMENT_CREATE_SUCCESS,
  ATTACHMENT_CREATE_FAILURE,
  ATTACHMENT_CONFIRM_REQUEST,
  ATTACHMENT_CONFIRM_SUCCESS,
  ATTACHMENT_CONFIRM_FAILURE,
  ATTACHMENT_DESTROY_REQUEST,
  ATTACHMENT_DESTROY_SUCCESS,
  ATTACHMENT_DESTROY_FAILURE,
  ATTACHMENTS_CLEAR_UPLOADED,
  ATTACHMENTS_CLEAR_ERRORS,
  ATTACHMENTS_UPLOAD_PROGRESS_UPDATE
} from '~brokerage/constants/actionTypes'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'

export const defaultState = {
  entities: {},
  errors: {},
  isCreating: false
}

export default function attachmentsReducer(state = defaultState, action) {
  switch (action.type) {
    case ATTACHMENT_CREATE_REQUEST:
      return {
        ...state,
        isCreating: true
      }
    case ATTACHMENT_CREATE_SUCCESS:
      return updateForEntity(state, action.data.attachment.id,
        {
          ...action.data.attachment,
          uploadData: {
            url: action.data.uploadUrl,
            form: action.data.form,
            file: action.file
          }
        },
        { isCreating: false }
      )
    case ATTACHMENT_CREATE_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: {
          ...state.errors,
          ...action.errors
        }
      }

    case ATTACHMENT_CONFIRM_REQUEST:
      return updateForEntity(state, action.id, { isConfirming: true })
    case ATTACHMENT_CONFIRM_SUCCESS:
      return updateForEntity(state, action.id, { isConfirming: false, isUploaded: true })
    case ATTACHMENT_CONFIRM_FAILURE:
      return updateForEntity(state, action.id, { isConfirming: false })

    case ATTACHMENT_DESTROY_REQUEST:
      return updateForEntity(state, action.id, { isDeleting: true })
    case ATTACHMENT_DESTROY_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.id)
      }
    case ATTACHMENT_DESTROY_FAILURE:
      return updateForEntity(state, action.id, { isDeleting: false })

    case ATTACHMENTS_CLEAR_UPLOADED:
      return {
        ...state,
        entities: omitBy(state.entities, (a) => a.isUploaded)
      }
    case ATTACHMENTS_CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      }
    case ATTACHMENTS_UPLOAD_PROGRESS_UPDATE:
      return updateForEntity(state, action.id, { uploadProgress: action.uploadProgress })

    default:
      return state
  }
}

function updateForEntity(state, id, newEntityState, newGlobalState = {}) {
  return {
    ...state,
    ...newGlobalState,
    entities: {
      ...state.entities,
      [id]: {
        ...state.entities[id],
        ...newEntityState
      }
    }
  }
}
