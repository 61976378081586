import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Group,
  Radio,
  Row,
  Controls,
  Col
} from "~brokerage/components/shared/Form";
import {
  changeSellerTenantCanViewFeedback,
  changeSellerTenantFeedbackVisibility
} from "~brokerage/actions/listings";
import css from "./Person.sass";
import Switch from "~brokerage/components/shared/Switch";

const PersonCanViewFeedback = ({ person, onChange, dispatch }) => {
  const handleChangeCanViewFeedback = value => {
    dispatch(changeSellerTenantCanViewFeedback(person, value));
    if (onChange) onChange();
  };

  const handleChangeSellerTenantFeedbackVisibility = value => {
    dispatch(changeSellerTenantFeedbackVisibility(person, value));
    if (onChange) onChange();
  };

  return (
    <>
      <Row offset="10">
        <span className={css.communicationInvolvementLabelText}>
          Can View Feedback
        </span>
        <div className={css.preferredCommunicationContainer}>
          <Controls>
            <Group modifier="leftFloatedChildren">
              <Col variant="liquid" modifier="half">
                <Switch
                  name="canViewFeedback"
                  checked={person.canViewFeedback}
                  onChange={() => {
                    handleChangeCanViewFeedback(!person.canViewFeedback);
                  }}
                />
              </Col>
            </Group>
          </Controls>
        </div>
      </Row>
      {person.canViewFeedback && (
        <Row offset="10">
          <div className={css.feedbackVisibilityContainer}>
            <Controls>
              <Group modifier="leftFloatedChildren">
                <Radio
                  className={css.sellerTenantFeedbackVisibility}
                  name="sellerTenantFeedbackVisibility"
                  value="show_all"
                  checked={
                    person.sellerTenantFeedbackVisibility === "show_all" ||
                    (person.canViewFeedback === true &&
                      person.sellerTenantFeedbackVisibility !== "approved_only")
                  }
                  onChange={() => {
                    handleChangeSellerTenantFeedbackVisibility("show_all");
                  }}
                >
                  All Feedback
                </Radio>
                <Radio
                  name="sellerTenantFeedbackVisibility"
                  value="approved_only"
                  checked={
                    person.sellerTenantFeedbackVisibility === "approved_only"
                  }
                  onChange={() => {
                    handleChangeSellerTenantFeedbackVisibility("approved_only");
                  }}
                >
                  Only Approved Feedback
                </Radio>
              </Group>
            </Controls>
          </div>
        </Row>
      )}
    </>
  );
};

PersonCanViewFeedback.propTypes = {
  person: PropTypes.shape({
    canViewFeedback: PropTypes.string,
    sellerTenantFeedbackVisibility: PropTypes.string
  }),
  onChange: PropTypes.func
};

export default connect()(PersonCanViewFeedback);
