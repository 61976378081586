import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '~brokerage/components/shared/Link'

export default class Loader extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    if (this.props.to || this.props.href) {
      return <Link {...this.props}/>
    } else {
      return <span>{this.props.children}</span>
    }
  }
}
