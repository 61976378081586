import css from './Categories.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import omit from 'lodash/omit'
import Button from '~brokerage/components/shared/Button'

class Categories extends React.PureComponent {
  static propTypes = {
    current: PropTypes.string,
    location: PropTypes.object,
    pending: PropTypes.number,
    all: PropTypes.number,
    dispatch: PropTypes.func
  }

  formatCount(_count) {
    if (!_count) {
      return
    }
    const count = _count > 99 ? '99+' : _count
    return `(${count})`
  }

  render() {
    const { current, location } = this.props

    return (
      <ul className={css.base}>
        <li className={css.li}>
          <Button
            to={{
              ...location,
              query: { ...omit(location.query, 'status') }
            }}
            variant="category"
            active={!current}
          >
            Upcoming {this.formatCount(this.props.all)}
          </Button>
        </li>
        {' '}
        <li className={css.li}>
          <Button
            to={{
              ...location,
              query: { ...location.query, status: 'pending' }
            }}
            variant="category"
            active={current === 'pending'}
          >
            Pending {this.formatCount(this.props.pending)}
          </Button>
        </li>
        {' '}
        <li className={css.li}>
          <Button
            to={{
              ...location,
              query: { ...location.query, status: 'passed' }
            }}
            variant="category"
            active={current === 'passed'}
          >
            Past
          </Button>
        </li>
      </ul>
    )
  }
}

function mapStateToProps(state) {
  const { filter } = state.showings.list.showings
  const counts = state.showings.list.showings.counts

  return {
    current: filter.status,
    all: Object.values(counts).reduce((acc, e) => acc + e, 0),
    pending: counts.pending
  }
}
export default connect(mapStateToProps)(Categories)
