import React from "react";
import mlsLogo from "~brokerage/assets/images/mls-aligned-logo.jpg";
import css from "~brokerage/app/components/login/MlsAlignedLogo.sass";

const MlsAlignedLogo = () => {
  return (
    <div className={css.container} >
      <img className={css.mlsLogo} src={mlsLogo} />
      <p className={css.copyright}>Copyright @ MLS Aligned</p>
    </div>
  );
}

export default MlsAlignedLogo;
