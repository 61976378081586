import pluralize from "pluralize";
import {
  MAX_FILE_SIZE_BYTES,
  FILE_PICKER_ACCEPTED_TYPES,
  HUMANIZED_ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE_MB
} from "./constants";

const getHumanizedFileTypes = mimeTypes => {
  return mimeTypes
    .map(type => HUMANIZED_ACCEPTED_FILE_TYPES[type] || type)
    .join(", ");
};

export const getFileNameWithoutExtension = ({ name }) => {
  return name.replace(/\.[^/.]+$/, ""); // Remove the file extension
};

export const formatSize = size => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
  return `${(size / (1024 * 1024)).toFixed(1)} MB`;
};

export const checkFileSizeLimit = file => file.size > MAX_FILE_SIZE_BYTES;

export const checkInvalidFile = file =>
  !FILE_PICKER_ACCEPTED_TYPES.includes(file.type);

export const getFileSizeExceededErrorMessage = ({ name }) =>
  `File ${name} exceeds the maximum size of ${MAX_FILE_SIZE_MB} MB.`;

export const getInvalidFileErrorMessage = ({ name }) => {
  const acceptedFileTypes = getHumanizedFileTypes(FILE_PICKER_ACCEPTED_TYPES);

  return `File ${name} is not an accepted file type. Only ${acceptedFileTypes} ${pluralize(
    "is",
    FILE_PICKER_ACCEPTED_TYPES.length
  )} accepted.`;
};
