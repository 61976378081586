import React, { PureComponent } from 'react'
import ReactList from 'react-list'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { scrollShowings } from '~brokerage/actions/showings'
import EmptyMessage from '~brokerage/components/shared/EmptyMessage'
import Loader from '~brokerage/components/shared/Loader'
import ListItemLoader from '~brokerage/components/shared/ListItemLoader'
import ListItem from './ListItem'

const NOTHING_TO_SHOW = 'Nothing to show'
const NO_SHOWING_MESSAGES = {
  upcoming: 'No upcoming showings',
  pending: 'No pending showings',
  passed: 'No passed showings'
}
class List extends PureComponent {

  static propTypes = {
    showings: PropTypes.array,
    isFetching: PropTypes.bool,
    currentShowingId: PropTypes.string,
    filter: PropTypes.object,
    isScrollFetching: PropTypes.bool,
    hasMoreShowings: PropTypes.bool,
    dispatch: PropTypes.func,
    status: PropTypes.string
  }

  loadMore() {
    this.props.dispatch(scrollShowings(this.props.filter))
  }

  needLoadMore(index) {
    return (Number(this.props.showings.length - index) === 1) &&
           (!this.props.isScrollFetching) &&
           (this.props.hasMoreShowings)
  }

  renderItem(index, key) {
    const showing = this.props.showings[index]

    return (
      <ListItemLoader
        key={key}
        needLoadMore={this.needLoadMore(index)}
        loadMore={::this.loadMore}
        uniqueId={showing.id}
      >
        <ListItem
          key={key}
          showing={showing}
          active={showing.id === this.props.currentShowingId}
          listFilter={this.props.filter}
        />
      </ListItemLoader>
    )
  }

  renderEmptyMessage() {
    if (!this.props.status) { return NO_SHOWING_MESSAGES.upcoming }
    if (!NO_SHOWING_MESSAGES[this.props.status]) { return NOTHING_TO_SHOW }

    return NO_SHOWING_MESSAGES[this.props.status]
  }

  render() {
    if (this.props.isFetching) {
      return <Loader active={this.props.isFetching}/>
    }

    if (!this.props.showings.length) {
      return <EmptyMessage>{this.renderEmptyMessage()}</EmptyMessage>
    }

    return (
      <ReactList
        itemRenderer={::this.renderItem}
        length={this.props.showings.length}
        type='variable'
      />
    )
  }
}

function mapStateToProps(state) {
  const { entities: showings,
          isFetching,
          filter,
          isScrollFetching,
          hasMoreShowings } = state.showings.list.showings

  return {
    showings,
    hasMoreShowings,
    isScrollFetching,
    isFetching,
    filter
  }
}
export default connect(mapStateToProps)(List)
