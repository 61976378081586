import React from 'react'
import css from './Row.sass'

const Row = ({ children, className, ...rest }) => (
  <div className={`${css.row} ${(typeof className !== "undefined") ? className : ""}`} {...rest}>
    {children}
  </div>
)

export default Row;
