import React from 'react'
import PropTypes from 'prop-types'
import Button from '~brokerage/components/shared/Button'
import ListingHorizontal from '~brokerage/components/shared/ListingHorizontal'
import List from '~brokerage/components/shared/List'

const Listing = (props) => {
  const { onClick, ...rest } = props

  return (
    <List.Item variant="bordered">
      <Button variant="listingHorizontal" onClick={onClick}>
        <ListingHorizontal {...rest}/>
      </Button>
    </List.Item>
  )
}

Listing.propTypes = {
  onClick: PropTypes.func
}
export default Listing
