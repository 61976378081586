import React, { useState, useEffect } from "react";
import { callApi } from "~brokerage/middlewares/api";

import ModalContents from "./ModalContents";
import { toast } from "react-toastify";

const defaultDetails = {
  message: "",
  requestACall: null,
  explanation: null
};

const toastMessages = {
  pending: "Updating Appointment",
  success: "Appointment Declined",
  error: "Sorry, Something went wrong"
};

const AppointmentDecline = ({
  showingId,
  closeModal,
  refreshCallback,
  getInstructions,
  altPush,
  seller,
  isInstructionsEditable
}) => {
  const [loading, setLoading] = useState(true);
  const [originalDetails, setOriginalDetails] = useState(defaultDetails);
  const [details, setDetails] = useState(defaultDetails);

  const pushData = {
    status: "declined",
    showing_response: {
      request_a_call: details.requestACall || false,
      message: details.message || "",
      explanation: details.explanation
    }
  };

  const pushDetails = () => {
    setLoading(true);
    toast
      .promise(
        altPush !== null
          ? altPush({ showingId, pushData })
          : callApi(`showings/${showingId}`, {}, pushData, "put"),
        toastMessages
      )
      .then(() => {
        refreshCallback();
      }) //onSuccess
      .catch() //onFailure
      .then(() => {
        setLoading(false);
        closeModal(true);
      }); //onComplete
  };

  const changeDetails = update_params => {
    setDetails({ ...details, ...update_params });
  };

  const resetDetails = () => {
    setDetails(originalDetails);
  };

  useEffect(() => {
    getInstructions().then(({ data }) => {
      const instructions = { ...data, ...{ message: "" } };

      setOriginalDetails(instructions);
      setDetails(instructions);
      setLoading(false);
    });
  }, []);

  return (
    <ModalContents
      loading={loading}
      details={details}
      changeDetails={changeDetails}
      pushDetails={pushDetails}
      resetDetails={resetDetails}
      closeModal={() => closeModal(false)}
      seller={seller}
      isInstructionsEditable={isInstructionsEditable}
    />
  );
};

export default AppointmentDecline;
