const nullFunc = function () {}

const supportsTransition = (function () {
  let result

  return function () {
    if (result !== undefined) {
      return result
    }

    const body = document.body || document.documentElement
    const style = body.style
    result = style.transition !== undefined ||
             style.WebkitTransition !== undefined ||
             style.MozTransition !== undefined ||
             style.MsTransition !== undefined ||
             style.OTransition !== undefined

    return result
  }
}())

export const unbindTransitionEnd = function (el, handler) {
  el.removeEventListener('transitionend', handler)
  el.removeEventListener('webkitTransitionEnd', handler)
  el.removeEventListener('oTransitionEnd', handler)
}

export const bindTransitionEnd = function (el, handler) {
  if (supportsTransition()) {
    el.addEventListener('transitionend', handler)
    el.addEventListener('webkitTransitionEnd', handler)
    el.addEventListener('oTransitionEnd', handler)
  } else {
    handler()
    return nullFunc
  }

  return unbindTransitionEnd.bind(null, el, handler)
}
