import css from './TextOverflow'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TextOverflow extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { variant = 'base', children } = this.props
    return (
      <div className={css[variant]}>
        {children}
      </div>
    )
  }
}
