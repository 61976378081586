import React from "react";
import PropTypes from "prop-types";
import { updateShowingInstructions } from "~brokerage/actions/showings";
import EditShowingDetailsForm from "~brokerage/components/shared/EditShowingDetailsForm";
import { Modal, Header, Body } from "~brokerage/components/modals/BaseModal";

export default class ModalShowingDetails extends React.PureComponent {
  static propTypes = {
    showingInstructions: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string,
    showingId: PropTypes.string,
    role: PropTypes.string,
    onSave: PropTypes.func,
    dispatch: PropTypes.func
  };

  handleSave = payload => {
    const { dispatch, showingId, role } = this.props;
    dispatch(updateShowingInstructions(showingId, role, payload)).then(() =>
      this.props.onSave(true)
    );
  };

  render() {
    const { showingInstructions, isSubmitting, onClose } = this.props;

    return (
      <Modal modifier="width530">
        <Header
          variant="bigger"
          title="Edit Details"
          onClose={() => onClose(false)}
        />

        <Body>
          <EditShowingDetailsForm
            showingInstructions={showingInstructions}
            isSubmitting={isSubmitting}
            onSave={this.handleSave}
            status={status}
          />
        </Body>
      </Modal>
    );
  }
}
