import css from './Contacts.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'
import { format as formatPhone } from '~brokerage/libs/helpers/PhonesHelper'

export default class Contacts extends React.PureComponent {
  static propTypes = {
    person: PropTypes.shape({
      license: PropTypes.string,
      homePhone: PropTypes.string,
      officePhone: PropTypes.string,
      mobilePhone: PropTypes.string,
      email: PropTypes.string,
      officeEmail: PropTypes.string,
      websiteUrl: PropTypes.string
    })
  }

  stripHttp(website) {
    return website.replace(/^http:\/\//, '')
  }

  renderContactInfo(value, label, iconName) {
    if (value) {
      return (
        <li className={css.li}>
          <i className={css.liIcon}><Icon name={iconName}/></i>
          {label}: <span className={css.contactValue}>{value}</span>
        </li>
      )
    }
  }

  renderLicense(value, label) {
    if (value) {
      return this.renderContactInfo(value, label, 'id')
    }
  }

  renderPhone(value, label) {
    if (value) {
      return this.renderContactInfo(formatPhone(value), label, 'phone')
    }
  }

  renderEmail(value, label) {
    if (value) {
      return this.renderContactInfo(value, label, 'mail')
    }
  }

  renderWebsite(value, label) {
    if (value) {
      return this.renderContactInfo(this.stripHttp(value), label, 'web')
    }
  }

  render() {
    const { license, homePhone, officePhone, mobilePhone, email, officeEmail, websiteUrl } = this.props.person

    return (
      <div className={css.base}>
        <h2>Contact Details</h2>
        <ul className={css.ul}>
          {this.renderLicense(license, 'License')}
          {this.renderPhone(mobilePhone, 'Primary Phone')}
          {this.renderPhone(officePhone, 'Office')}
          {this.renderPhone(homePhone, 'Personal')}
          {this.renderEmail(email, 'Email')}
          {this.renderEmail(officeEmail, 'Office')}
          {this.renderWebsite(websiteUrl, 'Website')}
        </ul>
      </div>
    )
  }
}
