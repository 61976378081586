import css from "./Categories.sass";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { isEqual, pick } from "lodash";
import { withRouter } from "react-router";
import {
  fetchPeopleCounts,
  fetchPeopleCategories
} from "~brokerage/actions/people";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import CategoryButton from "./CategoryButton";
import { categoryCount, categoryIconName } from "./utils";
import {
  CATEGORY_TYPES,
  CATEGORIES_SEARCH_PARAMS,
  ASSISTANTS_PAGE_ROUTE,
  MODAL_ACTIONS
} from "~brokerage/constants/people/categories";

const Categories = ({
  categories,
  peopleCounts,
  isFetching,
  location,
  history,
  dispatch
}) => {
  const fetchPeopleCountsTimeout = useRef(null);
  const prevQueryRef = useRef();

  const fetchAndDispatchPeopleCount = action => {
    if (fetchPeopleCountsTimeout.current) {
      clearTimeout(fetchPeopleCountsTimeout.current);
    }

    if (isFetching) {
      fetchPeopleCountsTimeout.current = setTimeout(
        () => fetchAndDispatchPeopleCount(action),
        200
      );
    } else {
      dispatch(action);
    }
  };

  useEffect(() => {
    dispatch(fetchPeopleCategories());
    fetchAndDispatchPeopleCount(fetchPeopleCounts(location.query));
  }, []);

  useEffect(() => {
    const prevQuery = prevQueryRef.current;
    const currentQuery = location.query;

    const currentQueryValues = pick(currentQuery, CATEGORIES_SEARCH_PARAMS);
    const prevQueryValues = pick(prevQuery, CATEGORIES_SEARCH_PARAMS);

    if (!isEqual(prevQueryValues, currentQueryValues)) {
      dispatch(fetchPeopleCounts(location.query));
    }

    prevQueryRef.current = currentQuery;
  }, [location.query]);

  const currentCategory = location.query.category || CATEGORY_TYPES.all_agents;
  const isAssistantsPage = location.pathname === ASSISTANTS_PAGE_ROUTE;

  const isCategoryActive = category =>
    category === currentCategory && !isAssistantsPage;

  const handleCreateCustomer = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: MODAL_ACTIONS.create_customer }
    });
  };

  const handleExport = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: MODAL_ACTIONS.export_contacts }
    });
  };

  const renderExportButton =
    isCategoryActive(CATEGORY_TYPES.seller_tenant) ||
    isCategoryActive(CATEGORY_TYPES.buyer);
  const renderCreateCustomerButton = isCategoryActive(CATEGORY_TYPES.customers);

  return (
    <ul className={css.base}>
      {categories?.map((category, index) => (
        <li key={index} className={css.li}>
          <CategoryButton
            location={location}
            categoryValue={category.value}
            categoryLabel={category.label}
            iconName={categoryIconName(category)}
            count={categoryCount(category.value, peopleCounts)}
            active={isCategoryActive(category.value)}
          />
        </li>
      ))}
      {renderCreateCustomerButton && (
        <li className={css.rightLi}>
          <Button onClick={handleCreateCustomer} variant="green">
            <Icon name="plus" modifier="offset5 valignTextDefault" />
            Add new customer
          </Button>
        </li>
      )}
      {renderExportButton && (
        <li className={css.rightLi}>
          <Button onClick={handleExport} variant="green">
            <span className={css.exportButton}>Export</span>
          </Button>
        </li>
      )}
    </ul>
  );
};

function mapStateToProps({ people: { list, categories: mainCategories } }) {
  const { entity: peopleCounts, isFetching: isFetchingCounts } = list.counts;
  const { entities: categories, isFetching: isFetchingCategories } =
    mainCategories;

  return {
    categories: categories || [],
    peopleCounts,
    isFetching: isFetchingCategories || isFetchingCounts
  };
}

export default withRouter(connect(mapStateToProps)(Categories));
