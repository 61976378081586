import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { Tr, Td } from "~brokerage/components/shared/Table";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import TextOverflow from "~brokerage/components/shared/TextOverflow";
import { renderTimeRangesFor, renderDays, renderDates } from "~brokerage/libs/helpers/RestrictionsHelper";

const Restriction = props => {
  const {
    restriction,
    firstOfType,
    onRemove
  } = props;

  const handleRemoveClick = (e) => {
    e.stopPropagation()
    onRemove(restriction.id);
  };

  const isRecurring = restriction.repetitionType === "weekly";

  return (
    <Tr {...omit(props, ["restriction", "onRemove", "firstOfType"])}>
      {(() => {
        if (firstOfType) {
          return (
            <Td width="130" modifier="nowrap">
              <Icon name="showingRestricted" modifier="offset5 valignTextDefault"/>
              {isRecurring ? "Recurring" : "Specific Dates"}
            </Td>
          )
        } else {
          return <Td width="130" modifier="noBorderTop"/>
        }
      })()}
      <Td>
        <Icon name="showingRestricted" modifier="offset5 valignTextDefault floatLeft"/>
        <TextOverflow>
          {isRecurring ? renderDays(restriction) : renderDates(restriction)} {renderTimeRangesFor(restriction)}
        </TextOverflow>
      </Td>
      <Td width="1%" modifier="nowrap noPadding">
        <Button variant="peopleRemove" onClick={handleRemoveClick}>
          <Icon name="close" modifier="offset5 valignTextDefault"/>
          Remove
        </Button>
      </Td>
    </Tr>
  )
};

Restriction.propTypes = {
  restriction: PropTypes.object,
  firstOfType: PropTypes.bool,
  onRemove: PropTypes.func
};

export default Restriction;
