import css from "./index.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import AdditionalInstructions from "~brokerage/app/components/shared/ShowingScreen/Sidebar/InternalInstructions";

const ShowingDetails = ({
  showingDetails: {
    lockboxKind,
    lockboxCode,
    accompanyAgent,
    accompanySeller,
    requestACall,
    message,
    lockboxBrand
  }
}) => {
  const IconLine = ({ text, icon }) => (
    <li className={css.li}>
      <i className={css.liIcon}>
        <Icon name={icon} />
      </i>{" "}
      {text}
    </li>
  );

  const Lockbox = () => {
    if (lockboxKind === "combo") {
      return <IconLine text={`Combo Lockbox: ${lockboxCode}`} icon="combo" />;
    } else if (lockboxKind === "electronic") {
      return <IconLine text={`Electronic Lockbox${lockboxBrand ? ":" : ""} ${lockboxBrand}`} icon="lockbox" />;
    } else {
      return <></>;
    }
  };

  return (
    <ul className={css.ul}>
      <Lockbox />
      {accompanyAgent && <IconLine text="Agent will accompany" icon="user" />}
      {accompanySeller && <IconLine text="Seller will accompany" icon="user" />}
      {requestACall && <IconLine text="Callback is requested" icon="phone" />}
      {message && <AdditionalInstructions message={message} expandable />}
    </ul>
  );
};

ShowingDetails.propTypes = {
  showingDetails: PropTypes.shape({
    lockboxKind: PropTypes.string,
    lockboxCode: PropTypes.string,
    accompanyAgent: PropTypes.bool,
    accompanySeller: PropTypes.bool,
    requestACall: PropTypes.bool,
    message: PropTypes.string,
    lockboxBrand: PropTypes.string
  })
};

export default ShowingDetails;
