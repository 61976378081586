import React from "react";
import PropertySpecs from "~brokerage/components/shared/PropertySpecs";
import OnBehalfAgentSearch from "./OnBehalfAgentSearch";
import css from "./ListingSummary.sass";

const ListingSummary = props => {
  const {
    address,
    status,
    contingencyString,
    specs,
    canScheduleShowingOnBehalfOfBuyerAgent,
    selectedBuyerAgent,
    setSelectedBuyerAgent
  } = props;

  return (
    <div className={css.heading}>
      <div className={css.listingRow}>
        <div className={css.address}>{address}</div>
        <div className={css.status}>{`${status}${contingencyString}`}</div>
      </div>
      <div className={css.listingRow}>
        <PropertySpecs
          beds={specs.beds}
          baths={specs.baths}
          sqft={specs.sqft}
        />
        {canScheduleShowingOnBehalfOfBuyerAgent && (
          <OnBehalfAgentSearch
            selectedBuyerAgent={selectedBuyerAgent}
            setSelectedBuyerAgent={setSelectedBuyerAgent}
          />
        )}
      </div>
    </div>
  );
};

export default ListingSummary;
