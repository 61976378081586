import React, { useState } from "react";
import css from "../index.sass";
import { reasonText } from "~brokerage/constants/appointments";
import Link from "~brokerage/components/shared/Link";
import Icon from "~brokerage/components/shared/Icon";
import EditType from "./EditType";
import { connect } from "react-redux";
import { updateShowingType } from "~brokerage/actions/showings";

const ShowingType = ({
  appointmentReason,
  isEditable,
  showingId,
  dispatch
}) => {
  const [editing, setEditing] = useState(false);

  const editLink = (
    <span className={css.btnEdit}>
      <Link onClick={() => setEditing(!editing)}>
        {editing ? (
          <>Cancel</>
        ) : (
          <>
            <Icon name="edit" /> Edit
          </>
        )}
      </Link>
    </span>
  );
  return (
    <div className={css.sectionWithBorder}>
      <h3 className={css.h3}>Showing Type {isEditable && editLink}</h3>
      {editing ? (
        <EditType
          appointmentReason={appointmentReason}
          showingId={showingId}
          setEditing={setEditing}
          onTypeChangeSuccess={type => dispatch(updateShowingType(type))}
        />
      ) : (
        <ul className={css.ul}>
          <li className={css.li}>{reasonText[appointmentReason]}</li>
        </ul>
      )}
    </div>
  );
};

export default connect()(ShowingType);
