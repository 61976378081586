import React from "react";
import css from "./shared.sass";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import SubmitSettings from "~brokerage/components/settings/components/SubmitSettings";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import Button from "~brokerage/components/shared/Button";

const Submit = ({ onReset }) => {
  return (
    <Row className={composeClassName(css, "inputRow", "submit")}>
      <Column className={css.submitButtons}>
        <Button variant="outline" type="reset" onClick={onReset}>
          Cancel
        </Button>
        <SubmitSettings />
      </Column>
    </Row>
  );
};

export default Submit;
