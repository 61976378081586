import React from "react";
import { statusIcon, statusColor } from "~brokerage/app/constants/appointments";
import { formatPhone } from "~brokerage/libs/helpers/FormatHelper";

const EventBase = ({ css, content, event, contactDetail = null }) => {
  const {
    showingData,
    popupDispatch,
    isPassed = false,
    isSelected = false
  } = event;
  const { id, status } = showingData;

  const Icon = statusIcon(status);
  const color = statusColor(status, isPassed);
  const [name, number] = contactDetail
    ? contactDetail.split(",")
    : [null, null];

  return (
    <div
      id={id}
      className={isSelected ? css.SelectedEventContainer : css.EventContainer}
      onClick={e => {
        if (status !== "stop")
          popupDispatch({
            action: "toggle",
            newData: showingData,
            newRect: e.currentTarget.getBoundingClientRect()
          });
      }}
    >
      <div className={css.IconContainer} style={{ backgroundColor: color }}>
        <Icon color="#fff" size={14} />
      </div>
      <div className={css.TitleContainer}>{content}</div>
      {!!contactDetail && (
        <div className={css.contactInfo}>
          {name}, {formatPhone(number)}
        </div>
      )}
    </div>
  );
};

export default EventBase;
