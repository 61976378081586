import { pixelsToMinutes } from "~brokerage/libs/helpers/TimeHelper";
import { PIXELS_PER_MINUTE, MINUTES_STEP } from "~brokerage/constants/timeline";

const getNextDate = (container, topDisregardingContainer, minDate) => {
  const containerTop = container.getBoundingClientRect().top;
  let top = topDisregardingContainer - containerTop + container.scrollTop;
  top = top - (top % (MINUTES_STEP * PIXELS_PER_MINUTE));
  const dayOffsetMinutes = pixelsToMinutes(top);
  return minDate.clone().add(dayOffsetMinutes, "minutes");
};

export default getNextDate;
