export const STATUS_APPROVED = "approved";
export const STATUS_PENDING = "pending";
export const STATUS_DECLINED = "declined";
export const STATUS_NOT_SENT = "not_sent";
export const STATUS_CANCELLED = "cancelled";
export const DROPDOWN_STATUSES = [
  { label: "Approved", value: STATUS_APPROVED },
  { label: "Pending", value: STATUS_PENDING },
  { label: "Declined", value: STATUS_DECLINED },
  { label: "Cancelled", value: STATUS_CANCELLED }
]
