import {
  MARKER_COLORS,
  DEFAULT_COLOR
} from "~brokerage/constants/routes/colors";

const getColorMappings = () => {
  const colorMappings = ["match", ["get", "status"]];

  Object.keys(MARKER_COLORS).map(key => {
    colorMappings.push(key);
    colorMappings.push(MARKER_COLORS[key]);
  });
  colorMappings.push("stop");
  colorMappings.push("#000");

  colorMappings.push(DEFAULT_COLOR);
  return colorMappings;
};

const plotListings = (map, data) => {
  const searchResultsSource = map.getSource("search-results");
  const searchResultsPins = map.getLayer("pins");
  const searchResultsLabels = map.getLayer("labelsFront");

  if (searchResultsSource) {
    map.getSource("search-results").setData(data);
    map.getSource("search-results-text").setData(data);
  } else {
    map.addSource("search-results", { type: "geojson", data });
    map.addSource("search-results-text", { type: "geojson", data });
  }

  if (!searchResultsPins) {
    map.addLayer({
      id: "pins",
      type: "symbol",
      source: "search-results",
      layout: {
        "icon-allow-overlap": true,
        "icon-image": "base-marker",
        "icon-size": 1
      },
      paint: {
        "icon-color": getColorMappings()
      }
    });
  }

  if (!searchResultsLabels) {
    map.addLayer({
      id: "labelsFront",
      type: "symbol",
      source: "search-results-text",
      layout: {
        "text-allow-overlap": true,
        "text-field": ["get", "label"],
        "text-justify": "center",
        "text-font": ["Open Sans Bold"],
        "text-size": 12,
        "text-offset": [0, -1.8],
        "text-anchor": "top"
      },
      paint: {
        "text-color": "#202"
      }
    });

    map.addLayer(
      {
        id: "labelsBack",
        type: "circle",
        source: "search-results",
        paint: {
          "circle-translate": [0, -14],
          "circle-radius": 7,
          "circle-color": "#fff"
        }
      },
      "labelsFront"
    );
  }
};
export default plotListings;
