import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '~brokerage/components/shared/Button'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Avatar from '~brokerage/components/shared/Avatar'

export default class InvitedUser extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    description: PropTypes.string,

    onResend: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
  }

  handleResend = () => {
    const { id, onResend } = this.props
    if (onResend) onResend(id)
  }

  handleEdit = () => {
    const { id, onEdit } = this.props
    if (onEdit) onEdit(id)
  }

  handleRemove = () => {
    const { id, onRemove } = this.props
    if (onRemove) onRemove(id)
  }

  render() {
    const { firstName, lastName, description, onResend, onEdit, onRemove } = this.props

    const fullName = [firstName, lastName].join(' ')
    const fullText = description ? `${fullName} - ${description}` : fullName

    return (
      <div className={css.borderedBlock}>
        <div className={css.inline}>
          <Avatar size="30" name={fullName}/>
        </div>
        {fullText}

        <div className={css.rightInner}>
          { onResend &&
          <Button variant='grayText' onClick={this.handleResend}>
            <IconedLabel icon='arrowLeftRound' label='Resend Invite'/>
          </Button>
          }
          { onEdit &&
          <Button variant='grayText' onClick={this.handleEdit}>
            <IconedLabel icon='edit' label='Edit'/>
          </Button>
          }
          { onRemove &&
          <Button variant='grayText' onClick={this.handleRemove}>
            <IconedLabel icon='close' label='Remove'/>
          </Button>
          }
        </div>
      </div>
    )
  }
}
