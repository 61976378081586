import css from './Showing.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

const Showing = ({ showing }) => {
  const { listingAgent, duration, status } = showing

  return (
    <div className={composeClassName(css, 'base', `duration${duration} ${status}Status`)}>
      <div className={css.agent}>{listingAgent}</div>
      <div className={css.duration}>{duration} mins</div>
    </div>
  )
}

Showing.propTypes = {
  showing: PropTypes.object
}
export default Showing
