import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addListingRestriction,
  removeListingRestriction,
  updateListingRestriction,
  fetchListingRestrictions
} from "~brokerage/actions/listings";
import {
  Table,
  Tbody,
  Tfoot,
  Tr,
  Td
} from "~brokerage/components/shared/Table";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Restriction from "./Restriction";
import RestrictionsAddForm from "~brokerage/components/shared/TimeDateRestrictions/RestrictionsAddForm";
import { sortRestrictions } from "~brokerage/libs/helpers/RestrictionsHelper";
import css from "~brokerage/components/listings/edit/TimeDateRestrictions/Restrictions.sass";
import { toast } from "react-toastify";

class Restrictions extends React.PureComponent {
  static propTypes = {
    restrictions: PropTypes.array,
    isAdding: PropTypes.bool,
    onEnableAddMode: PropTypes.func,
    onDisableAddMode: PropTypes.func,
    listingId: PropTypes.string,
    onChange: PropTypes.func,
    dispatch: PropTypes.func
  };

  state = {
    currentRestriction: null
  };

  clearCurrentRestriction = () => {
    this.setState({ currentRestriction: null });
  };

  handleAdd = restriction => {
    const restrictionData = {
      repetition_type: restriction.repetitionType,
      weekdays: restriction.days,
      all_day: restriction.allDay,
      time_ranges: restriction.timeRanges,
      restriction_dates: restriction.dates
    };

    this.props.onDisableAddMode();
    const isUpdate = !!restriction.id;

    const updateOrAddAction = isUpdate ? updateListingRestriction(
      this.props.listingId,
      restriction.id,
      restrictionData
    ) : addListingRestriction(this.props.listingId, restrictionData)

    this.props.dispatch(
      updateOrAddAction
    ).then(() => {
      this.props.dispatch(fetchListingRestrictions(this.props.listingId));
      toast.success(`Restriction ${isUpdate ? 'updated' : 'added'} successfully!`);
    })

    this.clearCurrentRestriction();
  };

  handleRemove = id => {
    const {
      restrictions: allRestrictions,
      listingId,
      onChange,
      dispatch
    } = this.props;
    const restrictions = allRestrictions.filter(e => e.id !== id);

    dispatch(removeListingRestriction(listingId, id)).then(() => {
      onChange && onChange({ restrictions });
      this.clearCurrentRestriction();
    });
  };

  handleRestrictionClick = restriction => {
    this.setState({ currentRestriction: restriction });
    this.props.onEnableAddMode();
  };

  handleCancelClick = () => {
    this.clearCurrentRestriction();
    this.props.onDisableAddMode();
  };

  render() {
    return (
      <div>
        {(() => {
          if (this.props.isAdding) {
            return (
              <RestrictionsAddForm
                restrictions={this.props.restrictions}
                onAdd={this.handleAdd}
                onCancel={this.handleCancelClick}
                currentRestriction={this.state.currentRestriction}
              />
            );
          } else {
            const sortedRestrictions = sortRestrictions(
              this.props.restrictions
            );

            return (
              <Table className={css.restriction}>
                <Tbody>
                  {sortedRestrictions.map((restriction, restrictionIndex) => {
                    const isFirstOfType =
                      !restrictionIndex ||
                      restriction.repetitionType !==
                        sortedRestrictions[restrictionIndex - 1].repetitionType;
                    return (
                      <Restriction
                        key={restrictionIndex}
                        restriction={restriction}
                        firstOfType={isFirstOfType}
                        onRemove={this.handleRemove}
                        onClick={() => this.handleRestrictionClick(restriction)}
                      />
                    );
                  })}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Td
                      colSpan="3"
                      modifier={sortedRestrictions.length ? "" : "noBorderTop"}
                    >
                      <Button
                        variant="outline"
                        onClick={this.props.onEnableAddMode}
                      >
                        <Icon
                          name="showingRestricted"
                          modifier="offset5 valignTextDefault"
                        />
                        Add Time/Date Restriction
                      </Button>
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            );
          }
        })()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    listingId: state.listings.single.listing.entity.id
  };
}
export default connect(mapStateToProps)(Restrictions);
