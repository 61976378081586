import css from './AutocompleteList.sass'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import classNames from 'classnames'

export default class AutocompleteList extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array,
    toggleWidth: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    height: PropTypes.number,
    scrollable: PropTypes.bool,
    onOptionClick: PropTypes.func,
    onOutsideClick: PropTypes.func,
    variant: PropTypes.string
  }

  componentDidMount() {
    this.id = v4()
    this.$list = $(ReactDOM.findDOMNode(this))

    this.updatePosition()

    $(document).on(`click.aiAutocomplete_${this.id}`, (event) => {
      if (!$(event.target).closest(this.$list).length) {
        this.props.onOutsideClick(event)
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.updatePosition()
    }
  }

  componentWillUnmount() {
    $(document).off(`click.aiAutocomplete_${this.id}`)
  }

  updatePosition() {
    const listOuterWidth = this.$list.outerWidth()
    const windowWidth = $(window).width()
    const listOffsetLeft = this.$list.offset().left

    if (listOffsetLeft + listOuterWidth > windowWidth) {
      const newListOffsetLeft = windowWidth - listOuterWidth - 10
      this.$list.css('left', newListOffsetLeft).addClass(css.listDisplaced)
    } else {
      if (listOuterWidth <= this.props.toggleWidth) {
        this.$list.outerWidth(this.props.toggleWidth)
      }
    }
  }

  render() {
    const styles = {
      top: this.props.top,
      left: this.props.left,
      maxHeight: this.props.height
    }

    const { variant, scrollable } = this.props
    const className = classNames(css[variant || 'base'], scrollable && css.scrollable)

    return (
      <div className={className} style={styles}>
        <ul>
          {this.props.options.map((option, optionIndex) => {
            return (
              <li
                key={optionIndex}
                onClick={this.props.onOptionClick.bind(this, option)}
              >
                {option.label}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
