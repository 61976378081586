import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchShowingRoutes(params) {
  return api.routes.fetchShowingRoutes(params, [
    types.ROUTES_REQUEST,
    types.ROUTES_SUCCESS,
    types.ROUTES_FAILURE
  ]);
}

export function fetchShowingRoute(id, persistedValues) {
  return api.routes.fetchShowingRoute(id, persistedValues, [
    types.ROUTE_REQUEST,
    types.ROUTE_SUCCESS,
    types.ROUTE_FAILURE
  ]);
}

export function createRoute(params) {
  return api.routes.createRoute(params, [
    types.ROUTE_CREATE_REQUEST,
    types.ROUTE_CREATE_SUCCESS,
    types.ROUTE_CREATE_FAILURE
  ]);
}

export function addAppointments(id, payload) {
  return api.routes.addAppointments(id, payload, [
    types.ROUTE_ADD_APPT_REQUEST,
    types.ROUTE_ADD_APPT_SUCCESS,
    types.ROUTE_ADD_APPT_FAILURE
  ]);
}

export function clearRequest() {
  return {
    type: types.ROUTE_CLEAR_REQUEST
  };
}

export function updateShowingRoute(data) {
  return {
    type: types.ROUTE_UPDATE,
    data
  };
}

export function updateRouteFromResponse(data) {
  return {
    type: types.ROUTE_SUCCESS,
    data
  };
}

export function removeRouteUnsaved() {
  return {
    type: types.ROUTE_REMOVE_UNSAVED
  };
}
