import { updatePreferences } from '~brokerage/actions/myAccount'

export default class PreferencesHandler {
  constructor(message, props) {
    this.message = message
    this.props   = props
  }

  handle() {
    this.props.dispatch(updatePreferences(this.message))
  }
}
