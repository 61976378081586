import fetch from "~brokerage/libs/fetch.js";

const API_ROOT = "/api/v2/brokerage";

export function callApi(endpoint, params, data, method, withJWT = true) {
  return fetch(
    `${API_ROOT}/${endpoint}`,
    {
      params: params,
      data: data,
      method: method
    },
    withJWT
  );
}

export function externalApi(endpoint, params, data, method) {
  return fetch(
    endpoint,
    {
      params: params,
      data: data,
      method: method
    },
    false
  );
}

export const CALL_API = Symbol("Call API");

const xhrs = {};

export default () => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const {
    endpoint,
    params,
    types,
    data,
    method = "get",
    actionParams
  } = callAPI;

  if (!Array.isArray(types)) {
    throw new Error("Expected an array of action types.");
  } else if (endpoint === void 0) {
    throw new Error("Expected an endpoint of type string or null.");
  } else {
    if (endpoint === null && types.length !== 2) {
      throw new Error("Expected two action types when there is no endpoint.");
    } else if (typeof endpoint === "string" && types.length !== 3) {
      throw new Error(
        "Expected an array of three action types when there is an endpoint."
      );
    }
  }

  function actionWith(obj) {
    const finalAction = { ...action, ...obj };
    delete finalAction[CALL_API];
    return finalAction;
  }

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, params, ...actionParams }));

  if (xhrs[requestType]) {
    xhrs[requestType].cancel();
    delete xhrs[requestType];
  }

  if (!endpoint) {
    return next(
      actionWith({
        type: successType,
        receivedAt: Date.now(),
        didInvalidate: false,
        ...actionParams
      })
    );
  }

  const xhr = callApi(endpoint, params, data, method);
  if (method === "get") {
    xhrs[requestType] = xhr;
  }

  xhr
    .then(data => {
      if (xhrs[requestType]) {
        delete xhrs[requestType];
      }
      return data;
    })
    .then(responseData => {
      return next(
        actionWith({
          type: successType,
          data: responseData.data,
          receivedAt: Date.now(),
          didInvalidate: false,
          ...actionParams
        })
      );
    })
    .catch(error => {
      // Cancel is not an Error
      if (!(error instanceof Error)) {
        return;
      }

      let errorMessage;

      console.error(error); // eslint-disable-line no-console
      if (window.Bugsnag) {
        const request = {
          requestType,
          method,
          endpoint,
          params,
          data,
          ...actionParams
        };
        window.Bugsnag.notifyException(error, { request });
      }
      const responseData = error.response && error.response.data;
      const responseError =
        (typeof responseData === "object" && responseData.errors) ||
        (typeof responseData === "object" && responseData.error);
      errorMessage = responseError || error.message || "Something bad happened";

      return next(
        actionWith({
          type: failureType,
          errors: errorMessage,
          ...actionParams
        })
      );
    });

  return xhr;
};
