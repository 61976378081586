const confirmSelections = data => {
  const {
    listing_email_notification_enabled,
    buying_email_notification_enabled,
    listing_text_notification_enabled,
    buying_text_notification_enabled,
    allow_showing_request
  } = data;

  let confirmMessage;

  const noOptionsSelected =
    !listing_email_notification_enabled &&
    !buying_email_notification_enabled &&
    !listing_text_notification_enabled &&
    !buying_text_notification_enabled;

  const noListingOptionsSelected =
    allow_showing_request &&
    !listing_email_notification_enabled &&
    !listing_text_notification_enabled;

  const noBuyingOptionsSelected =
    !buying_email_notification_enabled && !buying_text_notification_enabled;

  if (noOptionsSelected) {
    confirmMessage =
      "You have not selected any notification options. Are you sure you want to continue?";
  }

  if (noListingOptionsSelected) {
    confirmMessage =
      "You have not selected any listing notification options. Are you sure you want to continue?";
  }

  if (noBuyingOptionsSelected) {
    confirmMessage =
      "You have not selected any buying notification options. Are you sure you want to continue?";
  }

  return confirmMessage;
};

export default confirmSelections;
