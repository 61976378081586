import React, { useState } from "react";
import css from "./ModalContents.sass";
import { Header, Body, Footer } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";

const ModalContents = props => {
  const { value, onChange, handleSave, onClose } = props;

  return (
    <div className={css.base}>
      <Header title="Edit Team Instructions" onClose={onClose} />

      <Body>
        <div className={css.section}>
          <div className={css.left}>
            <h4>Team instructions</h4>
            <p>
              Internal notes for your team members about this listing or showing
              this property. Only your team members will see this information.
            </p>
          </div>

          <div className={css.right}>
            <textarea
              className={css.textarea}
              placeholder="Edit Team Instructions"
              value={value}
              onChange={onChange}
            ></textarea>
          </div>
        </div>
      </Body>

      <Footer>
        <div className={css.footerBtn}>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </div>
      </Footer>
    </div>
  );
};

export default ModalContents;
