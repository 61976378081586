import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { callApi } from "~brokerage/middlewares/api";
import groupBy from "lodash/groupBy";
import css from "./Listings.sass";
import Loader from "~brokerage/components/shared/Loader";
import Listing from "./Listing";
import ShowingsChart from "./ShowingsChart";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import moment from "moment";
import AgentCard from "./AgentCard";
// TODO: Create an api for reports that combines listing/showing data
// This is currently inefficent

const Listings = ({ platformId, enablePrint }) => {
  const [showings, setShowings] = useState([]);
  const [groupedShowings, setGroupedShowings] = useState([]);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  const start = moment().subtract(2, "months").startOf("month");
  const end = moment().endOf("month");

  const dateString = `(${start.format("MM/DD/YYYY")} - ${end.format(
    "MM/DD/YYYY"
  )})`;
  useEffect(() => {
    fetchShowings();
  }, []);

  useEffect(() => {
    if (showings) {
      fetchListings();
    }
  }, [showings]);

  const fetchShowings = () => {
    setLoading(true);
    const timestamp = start.toISOString().slice(0, -5) + "Z";
    const endTimestamp = end.toISOString().slice(0, -5) + "Z";
    callApi(
      "calendar/showings",
      { timestamp, endTimestamp, size: 1, approvedOnly: true },
      {},
      "get"
    ).then(response => {
      const approved = response.data.sort((a, b) =>
        a.requested_time < b.requested_time
          ? -1
          : a.requested_time > b.requested_time
          ? 1
          : 0
      );
      const groupedByListing = groupBy(approved, "mls_number");
      setGroupedShowings(groupedByListing);
      setShowings(approved);
    });
  };

  const fetchListings = () => {
    callApi(
      "my_organization/listings",
      {
        "filter[agent]": platformId,
        "filter[property_statuses]": "All",
        per_page: 100
      },
      {},
      "get"
    ).then(response => {
      const sorted = response.data.listings.sort((a, b) => {
        const numAListings = groupedShowings[a.mlsNumber]
          ? groupedShowings[a.mlsNumber].length
          : 0;
        const numBListings = groupedShowings[b.mlsNumber]
          ? groupedShowings[b.mlsNumber].length
          : 0;
        return numBListings - numAListings;
      });
      setListings(sorted);
      setLoading(false);
      enablePrint(true);
    });
  };

  if (loading) {
    return <Loader active />;
  }

  const listingsPerPrintPage = 8;

  return (
    <div className={css.container} id="print-target">
      <div className={css.heading}>
        <div className={css.left}>
          <h1>My Listings Activity</h1>
        </div>
        <div className={css.right}>
          <AgentCard />
        </div>
      </div>
      <ShowingsChart showings={showings} dateString={dateString} />
      <h3 className={css.title}>
        Showings by Listing: <small>{dateString}</small>
      </h3>

      <div className={css.listings}>
        {listings.length === 0 && (
          <EmptyMessage>No listings found.</EmptyMessage>
        )}
        {listings.map((listing, index) => {
          const showingData = showings.filter(
            s => s.mls_number === listing.mlsNumber
          );
          const pageBreak =
            index === 1 ||
            (index - 2) % listingsPerPrintPage === listingsPerPrintPage - 1;

          return (
            <>
              <Listing
                key={listing.id}
                listing={listing}
                showings={showingData}
              />
              {pageBreak && <div className={css.pagebreak} />}
            </>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ currentUser }) => {
  const { platformId } = currentUser;
  return { platformId };
};
export default connect(mapStateToProps)(Listings);
