import React, { useEffect, useState } from "react";
import { callApi } from "~brokerage/middlewares/api";
import Loader from "~brokerage/components/shared/Loader";
import css from "./AgentCard.sass";

const AgentCard = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setLoading(true);
    callApi("profile/profile_preferences", {}, {}, "get")
      .then(payload => payload.data.data.profile)
      .then(profile => {
        setUserDetails(profile);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader active />;
  }

  const {
    first_name: firstName,
    last_name: lastName,
    mls_name: mlsName,
    phone,
    email,
    broker_office_name: brokerOfficeName,
    broker_office_address: brokerOfficeAddress
  } = userDetails;

  const displayName = `${firstName || ""} ${lastName || ""}`;

  return (
    <div className={css.container}>
      <div className={css.details}>
        <div className={css.name}>
          Provided By: <br />
          {displayName}
        </div>
        <div>{brokerOfficeName}</div>
        <div>{brokerOfficeAddress}</div>
        <div>{mlsName}</div>
      </div>
    </div>
  );
};

export default AgentCard;
