import React from "react";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import PropTypes from "prop-types";

const AddAppointmentOption = ({ routeId, css }) => (
  <div className={css.addAppointment}>
    <Button variant="link" to={`/my_showings/${routeId}/add-appointments`}>
      <Icon name="plus" modifier="offset5 valignTextDefault" />
      Add New Appointment
    </Button>
  </div>
);

AddAppointmentOption.propTypes = {
  routeId: PropTypes.number.isRequired,
  css: PropTypes.object
};

export default AddAppointmentOption;
