import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";
import { pick, isEmpty } from "lodash";

export const buildAddAppointmentsPayload = (selected, buyers) => ({
  listings: selected.map(item => item.id).join(","),
  buyer_contacts: deepObjectKeysToSnakeCase(buyers)
});

export const buildRoutesPayload = ({ name, times, buyers }) => {
  return {
    name,
    start_at: times[0].time.format(),
    showings: times.map(({ id, time, duration, travelTime }) => ({
      listingId: id,
      requestedTime: time.format(),
      duration: duration,
      travelTime: travelTime
    })),
    ...(!isEmpty(buyers) && {
      buyer_contacts: deepObjectKeysToSnakeCase(buyers)
    })
  };
};

export const buildBuyerOptions = buyers =>
  buyers?.map(buyer => ({
    ...pick(buyer, ["id", "name", "uuid", "route_id"]),
    isExisting: !!buyer.route_id
  }));

export const hasListingThatRequireBuyers = (
  listings,
  mlsBuyerRequiredEnabled
) =>
  listings.some(
    ({ buyerNameRequired }) => mlsBuyerRequiredEnabled && buyerNameRequired
  );

export const buildContactDetails = ({
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  secondaryPhone = "",
  notes = "",
  isArchived = false
}) => ({
  firstName,
  lastName,
  email,
  primaryPhone: phone,
  secondaryPhone,
  notes,
  isArchived
});
