import css from './Pane.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

export default class Tab extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { variant = 'base', active, children } = this.props

    return (
      <div className={composeClassName(css, variant, { active })}>
        {children}
      </div>
    )
  }
}
