import React from "react";
import values from "lodash/values";
import without from "lodash/without";

import Loader from "~brokerage/components/shared/Loader";
import Header from "./Header";
import Footer from "./Footer";
import RadioButton from "./RadioButton";
import { TextField } from "~brokerage/components/shared/Form";
import css from "./ModalContents.sass";
import { OPTIONS_TYPE } from "~brokerage/components/shared/EditSurveyModal/constants";

const ModalContents = ({
  loading,
  details,
  showing,
  selectedAnswers,
  clickOption,
  onChangeTextAnswer,
  submitFeedback,
  closeModal,
  broadcastEnabled,
  onBroadcastSettingChange
}) => {
  if (loading) {
    return <Loader active />;
  }

  const { questions, address } = details;
  const { photoUrl, isLa } = showing;

  without(values(selectedAnswers), null);

  const questionComponent = data => {
    const { id: questionId, text, answers, answer_type } = data;
    const isOptionType = answer_type === OPTIONS_TYPE;
    const currentAnswer = selectedAnswers[questionId];
    return (
      <div key={`question-${questionId}`} className={css.questionContainer}>
        <div className={css.questionHeader}>{text}</div>
        {isOptionType ? (
          answers.map(data => {
            const { id: answerId, text } = data;
            return (
              <RadioButton
                key={`answer-${questionId}-${answerId}`}
                label={text}
                checked={currentAnswer === answerId}
                onChange={() => clickOption(questionId, answerId)}
              />
            );
          })
        ) : (
          <TextField
            placeholder="Type in your answer"
            value={currentAnswer || ""}
            onChange={e => onChangeTextAnswer(questionId, e.target.value)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Header
        closeModal={closeModal}
        address={address}
        listingPhoto={photoUrl}
        isListingAgent={isLa}
        broadcastEnabled={broadcastEnabled}
        onBroadcastSettingChange={onBroadcastSettingChange}
      />
      <div className={css.scrollContainer}>
        {questions.map(questionComponent)}
      </div>
      <Footer
        submitFeedback={submitFeedback}
        selectedAnswers={selectedAnswers}
      />
    </>
  );
};

export default ModalContents;
