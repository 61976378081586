import { connect } from 'react-redux'
import Summary from './Summary'

function mapStateToProps(state) {
  const { interlocutors, name } = state.messages.single

  return {
    interlocutors,
    name
  }
}
export default connect(mapStateToProps)(Summary)
