import css from "./ListingSpecs.sass";
import React from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";
import { platformUrl } from "~brokerage/constants/externalLinks";
import Link from "~brokerage/components/shared/Link";

export default class ListingSpecs extends React.PureComponent {
  static propTypes = {
    listing: PropTypes.shape({
      id: PropTypes.string,
      platformName: PropTypes.string,
      mlsNumber: PropTypes.string,
      price: PropTypes.string,
      showingsCount: PropTypes.number,
      specs: PropTypes.shape({
        beds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        baths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    })
  };

  render() {
    const { mlsNumber, price, specs, platformName, id } = this.props.listing;
    const externalMLSLink = platformUrl(mlsNumber, platformName, id);

    return (
      <ul className={css.base}>
        {mlsNumber && (
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name="info" />
              {`MLS# ${mlsNumber}`}
            </div>
          </li>
        )}

        {price && <li className={css.li}>{price}</li>}

        {specs.beds > 0 && (
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name="listingBed" />
            </div>
            {specs.beds} {specs.beds === 1 ? "beds" : "beds"}
          </li>
        )}

        {specs.baths > 0 && (
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name="listingBath" />
            </div>
            {specs.baths} {specs.baths === 1 ? "bath" : "baths"}
          </li>
        )}

        {specs.sqft > 0 && (
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name="listingSqft" />
            </div>
            {formatNumberUsingCommas(specs.sqft)} sqft
          </li>
        )}

        {mlsNumber && (
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name="house" />
              <Link
                className={css.addressLink}
                href={externalMLSLink}
                external={true}
              >
                View Listing
              </Link>
            </div>
          </li>
        )}
      </ul>
    );
  }
}
