import React, { useState } from "react";
import MyShowingsHeader from "./MyShowingsHeader";
import Content from "~brokerage/components/shared/Content";
import RouteList from "brokerage/app/components/myShowings/RouteList";

const MyShowings = props => {
  const [view, setView] = useState("upcoming");

  const { location } = props;

  return (
    <>
      <MyShowingsHeader view={view} setView={setView} location={location} />
      <Content header="single" id="routes">
        {view === "upcoming" && <RouteList location={location} />}
        {view === "past" && <RouteList past location={location} />}
      </Content>
    </>
  );
};

export default MyShowings;
