import React from "react";

import { Group, Checkbox, Row } from "~brokerage/components/shared/Form";

const ShowingOptions = ({
  accompanyAgent,
  accompanySeller,
  requestACall,
  changeDetails
}) => {
  const ROWS = [
    {
      text: "Agent will accompany",
      checked: accompanyAgent,
      name: "accompanyAgent"
    },
    {
      text: "Seller will accompany",
      checked: accompanySeller,
      name: "accompanySeller"
    },
    {
      text: "Request call for details",
      checked: requestACall,
      name: "requestACall"
    }
  ];

  return (
    <Group variant="well">
      {ROWS.map((row, index) => (
        <div key={index}>
          <Row offset={index === 0 ? "0" : "20"}>
            <Checkbox
              variant="bigger"
              modifier="rightAligned"
              name={row.name}
              checked={row.checked}
              onChange={e => {
                changeDetails({ [e.target.name]: e.target.checked });
              }}
            >
              {row.text}
            </Checkbox>
          </Row>
        </div>
      ))}
    </Group>
  );
};

export default ShowingOptions;
