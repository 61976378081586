import { isEmpty, pick } from "lodash";

export const formattedCityState = ({ city, state }) => {
  const isStateEmpty = isEmpty(state);
  const isCityEmpty = isEmpty(city);

  if (isCityEmpty && isStateEmpty) return "_";

  const formattedCity = isCityEmpty ? "_" : city;
  const formattedState = isStateEmpty ? "_" : state;

  return `${formattedCity}, ${formattedState}`;
};

export const buildNoContactsMessage = ({ query }) => {
  const category = pick(query, ["category"]).category;
  return category === "my_team"
    ? "Nobody is on your team yet."
    : "Nothing to show.";
};

export const isBuyerCategory = category => category === "buyer";
export const isSellerTenantCategory = category => category === "seller_tenant";
export const isMyAccountsCategory = category => category === "my_accounts";

export const buildTableTitles = ({
  query,
  isImpersonating,
  isBrokerageTabOpenedByBroker,
  hideEmail,
  hidePhone
}) => {
  const category = pick(query, ["category"]).category;

  const isCategorySellerTenant = isSellerTenantCategory(category);
  const isCategorySellerTenantBuyer =
    isCategorySellerTenant || isBuyerCategory(category);

  let titles = ["Name"];

  if (!isCategorySellerTenantBuyer) titles.push("");
  if (
    (isBrokerageTabOpenedByBroker || isMyAccountsCategory(category)) &&
    !isImpersonating
  )
    titles.push("");
  if (!hideEmail) titles.push("Email");
  if (!hidePhone) titles.push("Phone");
  if (!isCategorySellerTenantBuyer)
    titles.push("Brokerage Name", "City, State");
  if (isCategorySellerTenantBuyer) titles.push("Created at");
  if (isCategorySellerTenant) titles.push("Last visited");
  if (isCategorySellerTenantBuyer) titles.push("Notes");

  return titles;
};
