import css from './ModalInviteUser.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Header, Body, Footer } from '~brokerage/components/modals/BaseModal'
import { Form, Row, Col, TextField } from '~brokerage/components/shared/Form'
import Button from '~brokerage/components/shared/Button'
import Checkbox from '~brokerage/components/shared/Form/Checkbox'
//import Dropdown from 'Form/Dropdown'
import { USER_ROLE_OPTIONS } from '~brokerage/constants/settings/userRoleOptions'
import {
  inviteBrokerageUser,
  updateInvitedBrokerageUser,
  fetchShowingCoordinators,
  fetchAssistants,
  clearErrors
} from '~brokerage/actions/settings'
import { withRouter } from 'react-router-dom'

class ModalInviteUser extends React.PureComponent {
  static contextTypes = {
    currentUser: PropTypes.object,
  }

  static propTypes = {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    isAdmin: PropTypes.bool,
    errors: PropTypes.object,
    isInviteOrUpdateSuccessful: PropTypes.bool,
    dispatch: PropTypes.func
  }

  constructor() {
    super()
    this.state = {
      roleDropdownVariant: "outline2Block"
    }
  }

  componentWillMount() {
    this.setState({
      id: this.props.id,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      role: this.props.role,
      isAdmin: this.props.isAdmin,
      errors: {},
      isInviteOrUpdateSuccessful: false
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      errors: nextProps.errors,
      isInviteOrUpdateSuccessful: nextProps.isInviteOrUpdateSuccessful
    })
    if (nextProps.isInviteOrUpdateSuccessful) this.props.history.push(this.props.location.pathname)
  }

  componentWillUnmount() {
    this.props.dispatch(clearErrors())
  }

  handleFirstNameChange = (event) => this.setState({ firstName: event.target.value })
  handleLastNameChange = (event) => this.setState({ lastName: event.target.value })
  handleEmailChange = (event) => this.setState({ email: event.target.value })
  handleIsAdminChange = (event) => this.setState({ isAdmin: event.target.checked })
  handleRoleChange = (changes) => this.setState({ role: changes['role'],
                                                  roleDropdownVariant: "outline2Block" })

  handleSubmit = () => {
    const { id, firstName, lastName, email, isAdmin, isInviteOrUpdateSuccessful } = this.state
    const role = USER_ROLE_OPTIONS[0].value

    if (!role) return

    const user = { firstName, lastName, email, role, admin: isAdmin || false }
    if (this.isUpdate()) {
      this.props.dispatch(updateInvitedBrokerageUser(id, user))
      this.props.dispatch(fetchAssistants())
      this.props.dispatch(fetchShowingCoordinators())
    } else {
      this.props.dispatch(inviteBrokerageUser(user))
    }

    if (isInviteOrUpdateSuccessful) this.props.history.push(this.props.location.pathname)
  }

  handleValidate = () => {
    if (!this.state.role) this.setState({roleDropdownVariant: "withError"})
    return true
  }

  isUpdate = () => this.state.id !== void 0

  render() {
    const { firstName, lastName, email, isAdmin, errors/*, role, roleDropdownVariant*/} = this.state
    const title = this.isUpdate() ? 'Update Invited User' : 'Invite New User'

    return (
      <div className={css.base}>
        <Form onSubmit={this.handleSubmit} onValidate={this.handleValidate} validate>
          <Header title={title}/>
          <Body>
            <Row offset="15">
              <Col variant="liquid" modifier="half">
                <TextField block={true}
                           focused
                           required
                           name="firstName"
                           placeholder="First Name"
                           value={firstName}
                           serverErrors={errors['first_name']}
                           onChange={this.handleFirstNameChange}/>
              </Col>
              <Col variant="liquid" modifier="half">
                <TextField block={true}
                           required
                           name="lastName"
                           placeholder="Last Name"
                           value={lastName}
                           serverErrors={errors['last_name']}
                           onChange={this.handleLastNameChange}/>
              </Col>
            </Row>
            <Row offset="15">
              <Col variant="liquid" modifier="half">
                <TextField block={true}
                           required
                           name="email"
                           type="email"
                           placeholder="Email Address"
                           value={email}
                           serverErrors={errors['email']}
                           onChange={this.handleEmailChange}/>
              </Col>
              {/*<Col variant="liquid" modifier="half">*/}
                {/*<Dropdown variant={roleDropdownVariant}*/}
                          {/*name="role"*/}
                          {/*selected={role}*/}
                          {/*options={USER_ROLE_OPTIONS}*/}
                          {/*onChange={this.handleRoleChange}*/}
                          {/*placeholder="Select Role"/>*/}
              {/*</Col>*/}
            </Row>
          </Body>
          <Footer>
            <div className={css.footerCheckbox}>
              <Checkbox name="administrator"
                        onChange={this.handleIsAdminChange}
                        defaultChecked={isAdmin}>
                Give Administrative privileges
              </Checkbox>
            </div>
            <div className={css.footerBtn}>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Footer>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const userId = parseInt(ownProps.location.query.user_id)
  const { invitedUsers, userErrors: errors, isInviteOrUpdateSuccessful } = state.settings.brokerage
  const invitedUser = invitedUsers[userId]

  if (invitedUser) {
    return {
      id: invitedUser.id,
      firstName: invitedUser.firstName,
      lastName: invitedUser.lastName,
      email: invitedUser.email,
      role: invitedUser.role,
      isAdmin: invitedUser.isAdmin,
      errors,
      isInviteOrUpdateSuccessful
    }
  } else {
    const { coordinators } = state.settings.showingCoordinators
    const { assistants } = state.settings.assistants
    const coordinator = coordinators[userId] || assistants[userId]

    return coordinator || { errors, isInviteOrUpdateSuccessful }
  }
}
export default withRouter(connect(mapStateToProps)(ModalInviteUser));
