import css from './NoMatch.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class NoMatch extends React.PureComponent {
  static propTypes = {
    notify: PropTypes.bool
  }

  static defaultProps = {
    notify: true
  }

  componentWillMount() {
    if (this.props.notify) {
      window.Bugsnag.notify('Not Found', 'User reached wrong route in the brokerage scope.')
    }
  }

  render() {
    return (
      <div className={css.base}>
        <h1 className={css.h1}>Page Not Found</h1>
        <p className={css.p}>
          Looks like something went wrong and we have notified the Aligned Showings team.
        </p>
        <p className={css.p}> Return to your <Link className={css.link} to="/messages">inbox</Link></p>
      </div>
    )
  }
}
