import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "~brokerage/components/shared/Button";
import SmartModal from "./SmartModal";
import { withRouter } from "react-router-dom";

class SmartModalToggle extends Component {
  static propTypes = {
    name: PropTypes.string,
    modal: PropTypes.node,
    modalVariant: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    active: false
  };

  componentDidMount() {
    this.unregisterLocationChangeHandler = this.props.history.listen(
      this.handleLocationChange
    );
  }

  componentWillUnmount() {
    this.unregisterLocationChangeHandler();
  }

  handleLocationChange = location => {
    const isActive = location.query.modal === this.props.name;
    if (isActive && !this.state.active) {
      this.setState({ active: isActive });
    } else if (!isActive) {
      this.setState({ active: false });
    }
  };

  render() {
    const { name, modal, modalVariant, location, staticContext, ...rest } =
      this.props;

    return (
      <Button
        to={{
          ...location,
          query: { ...location.query, modal: name }
        }}
        onClick={this.handleClick}
        {...rest}
      >
        {this.props.children}
        {(() => {
          if (this.state.active) {
            return <SmartModal modal={modal} variant={modalVariant} />;
          }
        })()}
      </Button>
    );
  }
}

export default withRouter(SmartModalToggle);
