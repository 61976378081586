import React from "react";
import css from "./FilterSwitch.sass";
import CheckboxBlank from "remixicon-react/CheckboxBlankLineIcon";
import Checkbox from "remixicon-react/CheckboxFillIcon";

const FilterSwitch = ({ value, onClick, text, color }) => {
  return (
    <div onClick={onClick} className={css.filterContent}>
      {value ? (
        <Checkbox className={css.icon} />
      ) : (
        <CheckboxBlank className={css.icon} />
      )}
      <div className={css.status} style={{ color }}>
        {text}
      </div>
    </div>
  );
};

export default FilterSwitch;
