import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";

const ShowingDateTime = ({ time, duration }) => (
  <ul className={css.ul}>
    <li className={css.li}>
      {time} ({duration} mins)
    </li>
  </ul>
);

ShowingDateTime.propTypes = {
  time: PropTypes.string,
  duration: PropTypes.number
};

export default ShowingDateTime;
