import { combineReducers } from "redux";

import {
  PEOPLE_REQUEST,
  PEOPLE_SUCCESS,
  PEOPLE_FAILURE,
  PEOPLE_COUNTS_REQUEST,
  PEOPLE_COUNTS_SUCCESS,
  PEOPLE_COUNTS_FAILURE,
  PEOPLE_SET_Q
} from "~brokerage/constants/actionTypes";

const defaultState = {
  entities: [],
  page: 1,
  isFetching: false,
  isFirstPage: true,
  q: ""
};

function people(state = defaultState, action) {
  switch (action.type) {
    case PEOPLE_SET_Q:
      return { ...state, q: action.q };
    case PEOPLE_REQUEST: {
      const isFirstPage = action.params.page === 1;
      return { ...state, isFetching: true, isFirstPage };
    }
    case PEOPLE_SUCCESS: {
      const isFirstPage = action.data.page === 1;
      const newEntities = isFirstPage
        ? action.data.people
        : [...state.entities, ...action.data.people];

      return {
        ...state,
        entities: newEntities,
        page: action.data.page,
        totalPages: action.data.totalPages,
        isBrokerageTabOpenedByBroker: action.data.isBrokerageTabOpenedByBroker,
        hideEmail: action.data.hideEmail,
        hidePhone: action.data.hidePhone,
        isFetching: false
      };
    }
    case PEOPLE_FAILURE:
      return { ...state, errors: action.errors, isFetching: false };
    default:
      return state;
  }
}

function counts(state = { entity: {} }, action) {
  switch (action.type) {
    case PEOPLE_COUNTS_REQUEST:
      return { ...state, isFetching: true };
    case PEOPLE_COUNTS_SUCCESS:
      return { ...state, isFetching: false, entity: action.data.counts };
    case PEOPLE_COUNTS_FAILURE:
      return { ...state, isFetching: false, errors: true };
    default:
      return state;
  }
}

export default combineReducers({
  people,
  counts
});
