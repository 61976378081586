import css from "./index.sass";
import React from "react";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import {
  addRecipientToNewMessageRecipients,
  clearNewMessageRecipients
} from "~brokerage/actions/messages";
import { useLocation, useHistory } from "react-router-dom";

const NoCoordinatorMessage = ({ dispatch }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <div className={css.emptyMessage}>
      <h2 className={css.h2}>
        {location.query.agent
          ? "This agent has not enabled showing coordinator."
          : "No one in the brokerage has enabled showing coordinator."}
      </h2>

      <div>
        <Button
          onClick={event =>
            handleNewMessageButtonClick(event, history, location, dispatch)
          }
          variant="outline"
        >
          <Icon name="message" /> Message
        </Button>
      </div>
    </div>
  );
};

const handleNewMessageButtonClick = (event, history, location, dispatch) => {
  const agent = location.query.agent;

  dispatch(clearNewMessageRecipients());
  if (agent) {
    dispatch(addRecipientToNewMessageRecipients(agent));
  }

  history.push({
    pathname: location.pathname,
    query: { ...location.query, modal: "new_message" }
  });

  event.stopPropagation();
};

export default NoCoordinatorMessage;
