import React from "react";
import css from "./index.sass";
import Icon from "~brokerage/components/shared/Icon";
import { formatPhone } from "~brokerage/libs/helpers/FormatHelper";

export default function BuyerInvolvement({ buyers }) {
  return (
    <ul className={css.ul}>
      {buyers.map(buyer => {
        const buyerName = `${buyer.firstName} ${buyer.lastName}`;
        const phoneNumber = formatPhone(buyer.primaryPhone);
        const buyerInfo = phoneNumber
          ? `${buyerName} - ${phoneNumber}`
          : buyerName;

        return (
          <li key={`buyer-involvement-${buyer.uuid}`} className={css.li}>
            <i className={css.liIcon}>
              <Icon name="user" />
            </i>
            {buyerInfo}
          </li>
        );
      })}
    </ul>
  );
}
