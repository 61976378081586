import css from "./CalendarHeaderNav.sass";
import React from "react";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";

const CalendarHeaderNav = ({ location, returnUrl }) => {
  return (
    <div className={css.layout}>
      <div className={css.right}>
        <Button
          to={{
            pathname: returnUrl,
            state: { prevPath: location.pathname }
          }}
          variant="green"
        >
          <Icon name="plus" modifier="offset5 valignTextDefault" />
          Return to Listing
        </Button>
      </div>
    </div>
  );
};

export default CalendarHeaderNav;
