import React, { useRef } from "react";
import { toast } from "react-toastify";
import {
  checkFileSizeLimit,
  checkInvalidFile,
  getFileSizeExceededErrorMessage,
  getInvalidFileErrorMessage
} from "./utils";
import { MAX_NO_OF_FILE_UPLOAD_AT_A_TIME } from "./constants";
import css from "./FilePicker.sass";

const FilePicker = ({ types = [], isMultiple = false, onChange, children }) => {
  const inputRef = useRef();

  const accept = types.reduce((acc, ext) => `${acc}, ${ext}`, "");

  const handleInputChange = ({ target }) => {
    if (!target.files) return;
    if (target.files.length > MAX_NO_OF_FILE_UPLOAD_AT_A_TIME) {
      toast.error("You can upload a maximum of 5 files.");
      return;
    }

    for (let file of target.files) {
      if (checkFileSizeLimit(file)) {
        toast.error(getFileSizeExceededErrorMessage(file));
        return;
      }

      if (checkInvalidFile(file)) {
        toast.error(getInvalidFileErrorMessage(file));
        return;
      }
    }

    onChange(target.files);

    inputRef.current.value = "";
    handleClick();
  };

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <input
        {...{ accept }}
        className={css.hidden}
        ref={inputRef}
        type="file"
        onChange={handleInputChange}
        multiple={isMultiple}
      />
    </>
  );
};

export default FilePicker;
