import css from './SelectDateAndTime.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DEFAULT_SHOWING_DURATION } from '~brokerage/constants/showings'
import { selectDate, selectTime, selectDuration } from '~brokerage/actions/newShowing'
import { fetchPreferencesIfNeeded } from '~brokerage/actions/myAccount'
import { preparePayloadForNewShowing } from '~brokerage/libs/helpers/ShowingHelper'
import { datetimeToObject, roundTime, timeToRemoteWithTimeZone } from '~brokerage/libs/helpers/TimeHelper'
import { Row, Col, Label, CalendarDropdown } from '~brokerage/components/shared/Form'
import Button from '~brokerage/components/shared/Button'
import ButtonSet from '~brokerage/components/shared/ButtonSet'
import Durationpicker from './Durationpicker'
import Header from '../Header'
import Loader from '~brokerage/components/shared/Loader'
import Timepicker from './Timepicker'
import Timeline from './Timeline'

const SEND = 'Send'
const CREATE = 'Create'
class SelectDateAndTime extends Component {
  static propTypes = {
    date: PropTypes.string,
    time: PropTypes.object,
    duration: PropTypes.number,
    userPreferencesDuration: PropTypes.number,
    conflicts: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.string]),
    selectedListing: PropTypes.object,
    selectedAgent: PropTypes.object,
    isPosting: PropTypes.bool,
    dispatch: PropTypes.func,
    onCreateAndExitClick: PropTypes.func,
    onCreateAndViewClick: PropTypes.func,
    showing: PropTypes.object
  }

  componentWillMount() {
    this.props.dispatch(fetchPreferencesIfNeeded())
    const { showing } = this.props

    if (showing && showing.startTime) {
      const startDateTime = roundTime(
        timeToRemoteWithTimeZone(showing.startTime),
        'minutes',
        5
      )
      this.handleTimeChange(startDateTime)
      this.handleCalendarChange(startDateTime.startOf('day').format())
    } else {
      this.setDefaultDate()
    }

    if (showing && showing.duration) {
      this.handleDurationChange(showing.duration)
    } else {
      this.setDefaultDuration()
    }
  }

  get payload() {
    const { duration, selectedListing, selectedAgent, date, time } = this.props
    return preparePayloadForNewShowing(selectedListing, selectedAgent, date, time, duration)
  }

  setDefaultDate() {
    const date = timeToRemoteWithTimeZone().startOf('day').format()
    this.props.dispatch(selectDate(date, this.props.showing))
  }

  setDefaultDuration() {
    const duration = this.props.duration || this.props.userPreferencesDuration || DEFAULT_SHOWING_DURATION
    this.props.dispatch(selectDuration(duration))
  }

  handleCalendarChange = (date) => {
    if (date !== this.props.date) {
      this.props.dispatch(selectDate(date, this.props.showing))
    }
  }

  handleTimeChange = (changedDatetime) => {
    const currentTime = this.props.time.clone()
    currentTime.hours(changedDatetime.hours()).minutes(changedDatetime.minutes())
    this.props.dispatch(selectTime(currentTime))
  }

  handleDurationChange = (duration) => {
    this.props.dispatch(selectDuration(duration))
  }

  handleTimelineChange = (datetime) => {
    this.props.dispatch(selectTime(datetime))
  }

  handleCreateAndExitClick = () => {
    this.props.onCreateAndExitClick(this.payload)
  }

  handleCreateAndViewClick = () => {
    this.props.onCreateAndViewClick(this.payload)
  }

  get action() {
    return this.props.showing ? SEND : CREATE
  }

  isSubmitButtonDisabled() {
    const { conflicts, isPosting }  = this.props
    return Boolean(conflicts) || isPosting
  }

  render() {
    const { date, time, duration, selectedListing } = this.props

    return (
      <div>
        <Header title="Select Date And Time"/>

        {(() => {
          if (typeof selectedListing.id === 'undefined') {
            return <Loader active/>
          }

          return (
            <div className={css.body}>
              <Row offset="0">
                <Col>
                  <Label variant="vertical">Showing Date</Label>
                  <CalendarDropdown date={date} opened onChange={this.handleCalendarChange}/>
                </Col>
                <Col modifier="offset20">
                  <Label variant="vertical">Time</Label>
                  <Timepicker {...datetimeToObject(time)} onChange={this.handleTimeChange}/>
                </Col>
                <Col modifier="offset20">
                  <Label variant="vertical">Duration</Label>
                  <Durationpicker duration={duration} onChange={this.handleDurationChange}/>
                </Col>
              </Row>
              <div className={css.timeline}>
                <Timeline time={time} showing={this.props.showing} onChange={this.handleTimelineChange}/>
              </div>
              <Row>
                <ButtonSet>
                  <Button
                    variant="outline"
                    disabled={this.isSubmitButtonDisabled()}
                    onClick={this.handleCreateAndExitClick}
                  >
                    {this.action} and Exit
                  </Button>
                  <Button
                    variant="primary"
                    disabled={this.isSubmitButtonDisabled()}
                    onClick={this.handleCreateAndViewClick}
                  >
                    {this.action} and View Showing
                  </Button>
                </ButtonSet>
              </Row>
            </div>
          )
        })()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { date, time, duration, conflicts, selectedAgent } = state.showings.new
  const { entity: selectedListing } = state.showings.new.selectedListing
  const { isPosting } = state.showings.new.save
  const { defaultShowingDuration: userPreferencesDuration } = state.myAccount.preferences

  return {
    date,
    time,
    duration,
    userPreferencesDuration,
    conflicts,
    selectedListing,
    selectedAgent,
    isPosting
  }
}
export default connect(mapStateToProps)(SelectDateAndTime)
