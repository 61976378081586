const LOCAL_STORAGE_DISMISSED_KEY = "pushNofificationsDismissedAt";
export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export function notificationsDismissedAt() {
  const time = localStorage[LOCAL_STORAGE_DISMISSED_KEY];
  return time ? new Date(time) : null;
}

export function saveDismissedState(date) {
  localStorage[LOCAL_STORAGE_DISMISSED_KEY] = date;
}

export function clearDismissedState() {
  localStorage.removeItem(LOCAL_STORAGE_DISMISSED_KEY);
}

export function daysFromDismissed() {
  let days = (new Date() - notificationsDismissedAt()) / MILLISECONDS_IN_DAY;

  if (window.location.host.endsWith("herokuapp.com")) {
    // Count minutes instead of days for review apps
    days = days * 24 * 60;
  }
  return days;
}

// ttl is timetolive in milliseconds
export function setWithExpiry(key, value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
