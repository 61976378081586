import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

class OldInterfaceListingShowings extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  handleClick = (event) => {
    const link = closestTag(event.target, 'a')

    if (!link) {
      return false
    }

    const url = link.getAttribute('href')

    if (url && this.isBackLink(url)) {
      event.preventDefault()
      event.stopPropagation()
      this.handleBackLinkClick(url)
      return true
    }
    return false
  }

  isBackLink(url, caption) {
    return url === `/my_listings/${this.props.match.params.listingId}/showing_settings`
  }

  handleBackLinkClick(url) {
    const { match, history } = this.props

    history.push({
      pathname: `/listings/${match.params.listingId}`
    })
  }

  render() {
    const url = `my_listings/${this.props.match.params.listingId}/showings`

    return <OldInterfaceIframe url={url} onClick={this.handleClick} location={this.props.location}/>
  }
}
export default connect()(OldInterfaceListingShowings)
