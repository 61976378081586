import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchPriceAnalysis(filter) {
  return api.reports.fetchPriceAnalysis(filter, [
    types.PRICE_ANALYSIS_REQUEST,
    types.PRICE_ANALYSIS_SUCCESS,
    types.PRICE_ANALYSIS_FAILURE
  ]);
}

export function updateAnalysisFilters(updatedFilters) {
  return {
    type: types.UPDATE_ANALYSIS_FILTERS,
    updatedFilters
  };
}
