import css from './Categories.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sum from 'lodash/sum'
import values from 'lodash/values'
import Button from '~brokerage/components/shared/Button'

const BADGE_MAX_ALLOWED_MESSAGES_COUNT = 99,
      TOO_MANY_MESSAGES_BADGE          = '99+'

export default class Categories extends React.PureComponent {
  static propTypes = {
    counts: PropTypes.object,
    location: PropTypes.object
  }

  allCount() {
    return sum(values(this.props.counts))
  }

  displayCount(c) {
    if (c > BADGE_MAX_ALLOWED_MESSAGES_COUNT) {
      return TOO_MANY_MESSAGES_BADGE
    } else {
      return c
    }
  }

  isActiveFor(queryType) {
    const { type } = this.props.location.query
    return type === queryType
  }

  renderItem(label, count, type) {
    const { location } = this.props

    return (
      <li className={css.li}>
        <Button
          to={{
            pathname: location.pathname,
            query: { ...location.query, type }
          }}
          variant="header2"
          active={this.isActiveFor(type)}
        >
          {label} <span className={css.countBadge}>({this.displayCount(count)})</span>
        </Button>
      </li>
    )
  }

  render() {
    const { counts } = this.props

    return (
      <ul className={css.base} >
        {this.renderItem('All', this.allCount())}
        {this.renderItem('Messages', counts.direct, 'direct')}
        {this.renderItem('Showings', counts.showing, 'showing')}
      </ul>
    )
  }
}
