import { EMAIL_REGEX, FORMATTED_PHONE_REGEX } from '~brokerage/constants/fieldsValuePatterns'

export const plural = (count, single = '', plural = 's') => {
  return count === 1 ? single : plural
}

export const formatPrice = (num, formatOptions = {}) => {
  const { showZeroPrice = false } = formatOptions; 
  if (num === 0) {
    return showZeroPrice ? "$0" : "";
  } 
  const number = parseFloat(num);
  if (isNaN(number) || number < 0.01) {
    return ''
  }

  const numParts = num.toString().split(".");
  let decimalPlaces = 0;

  if(numParts.length === 2 && numParts[1] > 0) {
    decimalPlaces = numParts[1].length == 1 ? 2 : numParts[1].length;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(num)

}

export const formatNumberUsingCommas = (num, decimalPlaces = 0 ) => {
  if (isNaN(num) || num === null || num === undefined || num === '' || num === 0 || num.length === 0) {
    return ''
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  return formatter.format(num)
}


export const formatNumber = number => {
  number = String(number)
  let parts = number.split('.')
  const offset = parts[0].length % 3

  parts[0] = parts[0].replace(/.{3}/g, (match, i) => {
    if (i || offset) {
      return match.slice(0, offset) + ',' + match.slice(offset)
    }

    return match
  })

  return parts.join('.')
}

export const formatPhone = phone => {
  if (!phone) return ''
  return phone.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3')
}

export const formatDate = _date => {
  const date = new Date(_date)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export const formatClipboardData = data => {
  return removeEmailsFromString(removePhonesFromString(data)).replace(/\s+/g, ' ').trim()
}

const removeEmailsFromString = str => {
  return str.replace(EMAIL_REGEX, '')
}

const removePhonesFromString = str => {
  return str.replace(FORMATTED_PHONE_REGEX, '')
}
