import React, { useState, useEffect, useCallback, useRef } from "react";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { setSearchTerm, setSearchBarFocus } from "~brokerage/actions/messages";
import { connect } from "react-redux";
import Button from "brokerage/app/components/shared/Button";
import Icon from "brokerage/app/components/shared/Icon";
import debounce from "lodash/debounce";

import css from "./SearchBar.sass";

const SearchBar = props => {
  const { isSearching, messages, history, location, dispatch, searchTerm } =
    props;

  const [inputText, setInputText] = useState(
    typeof value !== "undefined" ? value : ""
  );

  const [
    shouldRedirectToFirstConversation,
    setShouldRedirectToFirstConversation
  ] = useState(false);

  useEffect(() => {
    if (isSearching && messages.length === 0) {
      redirectToEmptyMessage();
    }
  }, [isSearching, messages]);

  useEffect(() => {
    if (shouldRedirectToFirstConversation && messages.length > 0) {
      redirectToFirstConversation();
    }
  }, [messages]);

  const debouncedSearch = useCallback(
    debounce(searchTerm => {
      if (searchTerm.length > 2) {
        dispatch(setSearchTerm(searchTerm));
      } else {
        dispatch(setSearchTerm(""));
      }
    }, 500),
    []
  );

  const onSearchTermChange = event => {
    const searchTerm = event.target.value;
    setInputText(searchTerm);
    debouncedSearch(searchTerm);
    setShouldRedirectToFirstConversation(true);
  };

  const resetSearchTerm = () => {
    dispatch(setSearchTerm(""));
    setInputText("");
    setShouldRedirectToFirstConversation(false);
  };

  const redirectToFirstConversation = () => {
    const { type, id, forRole: role } = messages[0];
    history.replace({
      ...location,
      pathname: `/messages/${type}/${id}`,
      query: { ...location.query, role }
    });
    setShouldRedirectToFirstConversation(false);
  };

  const redirectToEmptyMessage = () => {
    history.replace({
      ...location,
      pathname: `/messages/`
    });
  };

  return (
    <div className={css.container}>
      <input
        type="text"
        placeholder="Search Messages"
        onChange={onSearchTermChange}
        autoComplete="off"
        className={css.base}
        value={inputText || searchTerm}
        onFocus={() => dispatch(setSearchBarFocus(true))}
        onBlur={() => dispatch(setSearchBarFocus(false))}
      />
      <div className={css.btnSearch}>
        <Button type="submit" variant={"searchDark"} onClick={resetSearchTerm}>
          <div className={css.btnContent}>
            {inputText.length > 0 || isSearching ? (
              <CloseCircleFillIcon size={18} />
            ) : (
              <Icon name="search" />
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    entities: messages,
    isSearching,
    searchTerm
  } = state.messages.list.messages;

  return {
    messages,
    isSearching,
    searchTerm
  };
}
export default connect(mapStateToProps)(SearchBar);
