import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useImperativeHandle,
  forwardRef
} from "react";
import ActiveShowing from "../ActiveShowing";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import {
  setInitialState,
  updateShowings,
  scrollToId,
  determineConflicts,
  getNextDate,
  getTravelTimeMatrix,
  createShowingRefs
} from "./util";

const ActiveAppointmentList = (
  {
    showings = [],
    container,
    activeShowing,
    setActiveShowing,
    restrictions,
    mapboxkey,
    updateForTravelTime,
    updateOnlyLastItem,
    appointmentReason,
    isListingOwnedByCurrentUserOrOfficeTeam
  },
  ref
) => {
  const showingRefs = useRef([]);
  const { times, setTimes, showingDate, setShowingDate } =
    useContext(ShowingContext);
  const [travelTimeMatrix, setTravelTimeMatrix] = useState([]);
  const [initDone, setInitDone] = useState(false);

  useImperativeHandle(ref, () => ({
    changeActivePos: ({ pos, id }) => {
      handleChange({ pos, id });
    }
  }));

  useEffect(() => {
    if (updateForTravelTime && travelTimeMatrix.length) {
      let prevEndTime = times[0]?.time
        .clone()
        .add(parseInt(times[0].duration), "minutes");
      const update = times.map((s, index) => {
        if (index > 0) {
          if (updateOnlyLastItem) {
            if (index !== times.length - 1) return s;
            else
              prevEndTime = times[index - 1]?.time
                .clone()
                .add(parseInt(times[index - 1].duration), "minutes");
          }
          const travelTime = parseInt(
            travelTimeMatrix[index - 1].durations[index],
            10
          );
          const newStartTime = prevEndTime
            .clone()
            .add(parseInt(travelTime, 10), "minutes");

          prevEndTime = newStartTime.clone().add(s.duration, "minutes");
          return {
            ...s,
            time: newStartTime,
            travelTime
          };
        } else {
          return s;
        }
      });
      setTimes(applyConflicts(update));
    }
  }, [travelTimeMatrix]);

  useEffect(() => {
    if (showings.length) {
      getTravelTimeMatrix(showings, setTravelTimeMatrix, mapboxkey);
      setInitialState(showings, restrictions, setTimes);
      setInitDone(true);
      createShowingRefs(showingRefs, showings);
    }
  }, []);

  useEffect(() => {
    if (initDone) setTimes(applyConflicts(times));
  }, [restrictions, initDone]);

  const applyConflicts = timesToUpdate => {
    if (!timesToUpdate.length || Object.keys(restrictions).length === 0)
      return timesToUpdate;
    const update = [...timesToUpdate].map(t => {
      const { listingKey } = showings.find(s => s.id === t.id);
      const { unavailability = [], allowOverlap = false } =
        restrictions[listingKey] || {};
      return {
        ...t,
        conflicts: determineConflicts({
          unavailability,
          nextDate: t.time,
          duration: t.duration,
          showingId: t.id,
          allowOverlap,
          isListingOwnedByCurrentUserOrOfficeTeam
        })
      };
    });
    return update;
  };

  useEffect(() => {
    if (showingDate) {
      const { id, nextDate } = showingDate;
      const { requestedTimeRaw: origDate, listingKey } = showings.find(
        s => s.id === id
      );
      const { unavailability = [], allowOverlap = false } =
        restrictions[listingKey] ?? {};
      const _update = {
        time: nextDate,
        modified: !nextDate.isSame(origDate),
        conflicts: determineConflicts({
          unavailability,
          nextDate,
          duration: times.find(t => t.id === id).duration,
          showingId: id,
          allowOverlap,
          isListingOwnedByCurrentUserOrOfficeTeam
        })
      };
      const _times = updateShowings({
        activeId: id,
        update: _update,
        data: times,
        travelTimeMatrix
      });
      setTimes(_times);
    }
  }, [showingDate]);

  useEffect(() => {
    if (times && showingDate) {
      const { id, nextDate } = showingDate;
      scrollToId(id, nextDate, showingRefs, container);
    }
  }, [times]);

  const handleChange = ({ pos, id }) => {
    const time = times.find(t => t.id === id).time;
    const maxDate = time.clone().endOf("day").subtract(30, "minutes");
    const minDate = time.clone().startOf("day");
    let nextDate = getNextDate(container, pos, minDate);

    if (nextDate < minDate) {
      nextDate = minDate;
    } else if (nextDate > maxDate) {
      nextDate = maxDate;
    }
    setShowingDate({ id, nextDate });
  };

  if (!times) return <div />;

  return (
    <>
      {showings.map(showing => {
        const showingState = times.find(t => t.id === showing.id);
        return (
          showingState &&
          showingState.time && (
            <ActiveShowing
              isActive={activeShowing === showing.listingKey}
              ref={showingRefs.current[showing.id]}
              zIndexOffset={showingState.position}
              key={showing.id}
              id={showing.id}
              status={showing.status}
              listingKey={showing.listingKey}
              onChange={handleChange}
              time={showingState.time}
              duration={showingState.duration}
              travelTime={showingState.travelTime}
              conflicts={showingState.conflicts}
              setActiveShowing={setActiveShowing}
              address={showing.address}
              restrictions={restrictions[showing.listingKey]}
              appointmentReason={appointmentReason}
              isListingOwnedByCurrentUserOrOfficeTeam={
                isListingOwnedByCurrentUserOrOfficeTeam
              }
            />
          )
        );
      })}
    </>
  );
};

export default forwardRef(ActiveAppointmentList);
