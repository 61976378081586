import css from "./ActiveShowing.sass";
import React, { forwardRef, useEffect } from "react";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { datetimeToPixels } from "~brokerage/libs/helpers/TimeHelper";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import Showing from "./Showing";
import {
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/app/constants/showings/statuses";

const ActiveShowing = (
  {
    id,
    isActive,
    listingKey,
    address,
    duration,
    status,
    time,
    travelTime,
    onChange,
    conflicts,
    setActiveShowing,
    zIndexOffset,
    restrictions,
    appointmentReason,
    isListingOwnedByCurrentUserOrOfficeTeam = false
  },
  ref
) => {
  let pointerOffset;
  const MAX_INDEX = 12;
  const disabled = [STATUS_CANCELLED, STATUS_DECLINED].includes(status);
  const isTouch = "ontouchstart" in window;
  const mouseClickEvent = isTouch ? "touchstart" : "mousedown";
  const mouseUpEvent = isTouch ? "touchend" : "mouseup";
  const mouseMoveEvent = isTouch ? "touchmove" : "mousemove";

  useEffect(() => {
    if (!ref || !ref.current) return;
    ref.current.scrollIntoView({ block: "center" });

    ref.current.addEventListener(mouseClickEvent, onMouseDown);
    return () => {
      ref.current &&
        ref.current.removeEventListener(mouseClickEvent, onMouseDown);
    };
  }, [ref]);

  const onMouseDown = event => {
    event.stopPropagation();
    if (isTouch) {
      const paths = event.composedPath();
      let shouldIgnore = false;
      paths.every(path => {
        if (path.dataset && path.dataset.ignoretouch) {
          shouldIgnore = true;
          return false;
        }
        return true;
      });

      if (shouldIgnore) return;
    }

    event.preventDefault();
    setActiveShowing(listingKey);
    if (disabled) {
      return;
    }
    const pageY = event.pageY || event.touches[0].pageY;
    pointerOffset = pageY - $(ref.current).offset().top;
    document.addEventListener(mouseMoveEvent, handleDrag);
    document.addEventListener(mouseUpEvent, handleDragStop);
  };

  const handleDrag = event => {
    const pageY = event.pageY || event.touches[0].pageY;
    onChange({ pos: pageY - pointerOffset, id });
  };

  const handleDragStop = event => {
    document.removeEventListener(mouseMoveEvent, handleDrag);
    document.removeEventListener(mouseUpEvent, handleDragStop);
  };

  const top = datetimeToPixels(time);
  const displayTime = inUsersTimezone(time).format("h:mma");
  const zIndex = isActive
    ? MAX_INDEX + 1
    : disabled
    ? 1
    : MAX_INDEX - zIndexOffset;
  const modifier = isActive ? "active" : disabled && "disabled";

  return (
    <div
      ref={ref}
      className={composeClassName(css, "base", modifier)}
      style={{ top }}
    >
      <Showing
        id={id}
        address={address}
        status={status}
        duration={duration}
        travelTime={travelTime}
        displayTime={displayTime}
        conflicts={conflicts}
        restrictions={restrictions}
        zIndex={zIndex}
        appointmentReason={appointmentReason}
        isListingOwnedByCurrentUserOrOfficeTeam={
          isListingOwnedByCurrentUserOrOfficeTeam
        }
      />
    </div>
  );
};
export default forwardRef(ActiveShowing);
