import css from './RoleFilter.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '~brokerage/components/shared/Button'

export default class RoleFilter extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    currentRole: PropTypes.string,
    availableRoles: PropTypes.array
  }

  render() {
    const { availableRoles, currentRole, location } = this.props

    return (
      <ul className={css.base}>
        {availableRoles && availableRoles.map(({ label, value: role }, index) =>
          <li key={index} className={css.li}>
            <Button
              to={{
                pathname: location.pathname,
                query: { ...location.query, role }
              }}
              variant="header2"
              active={currentRole === role}
            >
              {label}
            </Button>
          </li>
        )}
      </ul>
    )
  }
}
