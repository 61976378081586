import css from "./index.sass";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";

const InternalInstructions = ({ message, expandable }) => {
  const [expanded, toggleExpanded] = useState(false);
  const isLongMessage = message && message.length > 200;

  let messageDisplay = message;
  if (!expanded && isLongMessage) {
    messageDisplay = message.substring(0, 200);
  }

  const showExpandButton = expandable && isLongMessage;

  return (
    <ul className={css.ul}>
      {messageDisplay && (
        <li className={css.li}>
          <i className={css.liIcon}>
            <Icon name="info" />
          </i>{" "}
          {messageDisplay}
        </li>
      )}
      {showExpandButton && (
        <Button onClick={() => toggleExpanded(!expanded)}>
          {expanded ? "Show Less" : "Show More"}
        </Button>
      )}
    </ul>
  );
};

InternalInstructions.propTypes = {
  message: PropTypes.string,
  expandable: PropTypes.bool
};

export default InternalInstructions;
