import css from './SelectListing.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  fetchListings,
  selectListing,
  fetchListingStatuses
} from '~brokerage/actions/newShowing'
import { plural } from '~brokerage/libs/helpers/FormatHelper'
import { Form, Row, Dropdown, TextField } from '~brokerage/components/shared/Form'
import Header from '../Header'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import List from '~brokerage/components/shared/List'
import Listing from './Listing'
import Loader from '~brokerage/components/shared/Loader'
class SelectListing extends Component {
  static propTypes = {
    listings: PropTypes.array,
    listingStatuses: PropTypes.array,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func
  }

  state = {
    statusSelected: 'Active',
    activeInput: false,
    activeSearch: false
  }

  componentDidMount() {
    this.props.dispatch(fetchListingStatuses())
  }

  get statusTitle() {
    const status = this.statusOptions.find(x => x.value === this.state.statusSelected).label
    return `Status: ${status}`
  }

  get statusOptions() {
    return this.props.listingStatuses.map(status => {
      return { value: status.filterParams.property_statuses, label: status.name }
    })
  }

  handleStatusChange = (option) => {
    this.setState({
      statusSelected: option['filter[property_statuses]']
    })
  }

  handleSubmit = (event, values) => {
    event.preventDefault()
    this.props.dispatch(fetchListings(values))
    this.setState({ activeSearch: true })
  }

  handleListingClick = (listingId) => {
    this.props.dispatch(selectListing(listingId))
  }

  handleOnChange = (event) => {
    const hasSeachValue = event.target.value
    this.setState({ activeInput: hasSeachValue })
    if(!hasSeachValue) this.setState({ activeSearch: false })
  }

  renderListings() {
    const { listings, isFetching } = this.props
    const { activeInput, activeSearch } = this.state

    if (isFetching) return <Loader active />
    if (!listings.length && !activeSearch) return
    if (!listings.length && activeInput && activeSearch) {
      return <div className={css.noListingsText}>No listings found</div>
    }

    return (
      <div className={css.results}>
        <div className={css.resultsHeading}>
          {listings.length} Search Result{plural(listings.length)}. Click a listing to select
        </div>
        <List variant="bordered">
          {listings.map(listing => this.renderListing(listing))}
        </List>
      </div>
    )
  }

  renderListing(listing) {
    return(
      <Listing
        {...listing}
        key={listing.id}
        onClick={this.handleListingClick.bind(this, listing.id)}
      />
    )
  }

  render() {
    return (
      <div>
        <Header title="Select Listing"/>
        <div className={css.body}>
          <Row offset="0">
            <Form onSubmit={this.handleSubmit}>
              <div className={css.statusDropdown}>
                <Dropdown
                  name="filter[property_statuses]"
                  variant="outline2"
                  title={this.statusTitle}
                  options={this.statusOptions}
                  selected={this.state.statusSelected}
                  onChange={this.handleStatusChange}
                />
              </div>
              <div className={css.textFieldAndBtnSearch}>
                <TextField
                  name="query"
                  type="search"
                  placeholder="Search My Team Listing by MLS#, Address, Agent, or Seller"
                  autoComplete="off"
                  onChange={this.handleOnChange}
                  block
                  focused
                />
                <div className={css.btnSearch}>
                  <Button type="submit" variant={this.state.activeInput ? 'searchBlue' : 'searchDark'}>
                    <Icon name="search"/>
                  </Button>
                </div>
              </div>
            </Form>
          </Row>
          <div className={this.state.activeInput ? css.helpText : css.hidden}>
            Press Enter to Search
          </div>
          {this.renderListings()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { entities: listings, isFetching: listingsAreFetching } = state.showings.new.listings
  const { entities: listingStatuses, isFetching: statusesAreFetching } = state.showings.new.listingStatuses
  const isFetching = listingsAreFetching || statusesAreFetching

  return {
    listings,
    listingStatuses,
    isFetching
  }
}
export default connect(mapStateToProps)(SelectListing)
