import {
  MY_ACCOUNT_SET_INITIAL_PREFERENCES,
  MY_ACCOUNT_PREFERENCES_REQUEST,
  MY_ACCOUNT_PREFERENCES_SUCCESS,
  MY_ACCOUNT_PREFERENCES_FAILURE,
  MY_ACCOUNT_UPDATE_PREFERENCES,
  MY_ACCOUNT_UPDATE_PREFERENCES_REQUEST,
  MY_ACCOUNT_UPDATE_PREFERENCES_SUCCESS,
  MY_ACCOUNT_UPDATE_PREFERENCES_FAILURE,
  MY_ACCOUNT_HIDE_WELCOME_SCREEN_REQUEST
} from '~brokerage/constants/actionTypes'

const initialState = {
  defaultShowingDuration: 30,
  canAddMultiplePeopleToChats: false,
  canAddTeammatesToChatThread: false,
  canLogACall: false,
  canAddFollowersToListing: false,
  canScheduleShowingsForOtherUsers: false,
  canEnableShowingCoordinatorForListing: false,
  canInviteNonMlsUsers: false,
  hasAccessToTransactionsChat: true,
  welcomeScreenHidden: true
}

export default function preferences(state = initialState, action) {
  switch (action.type) {
    case MY_ACCOUNT_SET_INITIAL_PREFERENCES:
      return { ...state, ...action.preferences }
    case MY_ACCOUNT_PREFERENCES_REQUEST:
      return { ...state, isFetching: true }
    case MY_ACCOUNT_PREFERENCES_SUCCESS:
      return { ...state, ...action.data.preferences, isFetching: false, fetched: true }
    case MY_ACCOUNT_PREFERENCES_FAILURE:
      return { ...state, isFetching: false, errors: action.errors }
    case MY_ACCOUNT_UPDATE_PREFERENCES:
      return { ...state,  ...action.preferences }
    case MY_ACCOUNT_UPDATE_PREFERENCES_REQUEST:
      return { ...state, isFetching: true }
    case MY_ACCOUNT_UPDATE_PREFERENCES_SUCCESS:
      return { ...state, ...action.data.preferences, isFetching: false, fetched: true }
    case MY_ACCOUNT_UPDATE_PREFERENCES_FAILURE:
      return { ...state, isFetching: false, errors: action.errors }
    case MY_ACCOUNT_HIDE_WELCOME_SCREEN_REQUEST:
      return { ...state, welcomeScreenHidden: true }
    default:
      return state
  }
}
