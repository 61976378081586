import { combineReducers } from 'redux'

import listReducer from './list'
import singleReducer from './single'
import newReducer from './new'

export default combineReducers({
  list: listReducer,
  single: singleReducer,
  new: newReducer
})
