const printPopup = () => {
  const popup = window.open("", "_blank", "width=1,height=1");
  popup.addEventListener("afterprint", event => {
    popup.close();
  });
  const content = document.getElementById("print-target");
  popup.document.head.innerHTML = document.head.innerHTML;
  popup.document.body.innerHTML = content.innerHTML;
  setTimeout(() => {
    popup.print();
    setTimeout(() => {
      popup.close();
    });
  });
};

export default printPopup;
