import {
  CUSTOMER_LISTINGS_REQUEST,
  CUSTOMER_LISTINGS_SUCCESS,
  CUSTOMER_LISTINGS_FAILURE
} from "~brokerage/constants/actionTypes";

const initialState = {
  entities: [],
  isFetching: false
};

export default function listings(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_LISTINGS_REQUEST:
      return { ...state, isFetching: true };
    case CUSTOMER_LISTINGS_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.listings };
    case CUSTOMER_LISTINGS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    default:
      return state;
  }
}
