import css from './Body.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

const Body = ({ variant = 'base', children }) => {
  return (
    <div className={composeClassName(css, variant)}>
      {children}
    </div>
  )
}

Body.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
}
export default Body
