import React from "react";
import { Popover } from "react-tiny-popover";

import css from "./index.sass";
import { MonthEvent } from "../../Events";
import moment from "moment";

const ShowMorePopup = ({
  info: {
    data: { date, events },
    meta: { open, targetRect }
  },
  dispatch
}) => {
  if (!open) {
    return <div />;
  }
  const { x, y, height, width } = targetRect;
  return (
    <Popover
      isOpen={open}
      onClickOutside={() => {
        dispatch({
          action: "close"
        });
      }}
      content={popoverState => (
        <div className={css.MContainer}>
          <div className={css.header}>
            {moment(date).format("dddd, MMMM Do")}
          </div>
          {events.map((event, index) => (
            <div
              className={css.eventContainer}
              key={`calendar-show-more-event-${index}`}
            >
              <MonthEvent event={event} />
            </div>
          ))}
        </div>
      )}
      positions={["bottom", "right", "right", "left"]}
      align="start"
      containerStyle={{
        zIndex: 98,
        boxShadow: "5px 5px 2px 0px rgba(0, 0, 0, .2)"
      }}
      padding={0}
      boundaryInset={25}
    >
      <div
        style={{
          height: height,
          width: width,
          top: y,
          left: x,
          position: "fixed"
        }}
      />
    </Popover>
  );
};

export default ShowMorePopup;
