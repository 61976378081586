import moment from "moment";

const applyAppointmentTypeFilters = (
  appointmentType,
  isListingAgent,
  isShowingAgent
) =>
  (appointmentType === "Showing Agent" && isShowingAgent) ||
  (appointmentType === "Listing Agent" && isListingAgent);

const applyAppointmentReasonFilter = (reason, reasonFilter) =>
  reasonFilter === "all" || reason === reasonFilter;

const applyAppointmentStatusFilters = (
  status,
  requestedTime,
  cancelled,
  approved,
  declined,
  pending,
  expired,
  stop
) =>
  (status === "pending" &&
    (moment(requestedTime).isAfter() ? pending : expired)) ||
  (status === "approved" && approved) ||
  (status === "declined" && declined) ||
  (status === "cancelled" && cancelled) ||
  (status === "stop" && stop);

const applySearchTermFilter = (searchString, searchTerm) =>
  searchString.includes(searchTerm.toLowerCase());

const applyBuyerFilter = (buyerIds, buyer) =>
  buyer ? buyerIds && buyerIds.includes(buyer.id) : true;

const applyFilter = (showing, filters) => {
  const {
    addressSearchTerm,
    agentSearchTerm,
    isListingAgent,
    isShowingAgent,
    showCancelled,
    showApproved,
    showDeclined,
    showPending,
    showExpired,
    showOthers,
    appointmentReasonFilter,
    buyer
  } = filters;

  const {
    appointment_type: appointmentType,
    status,
    street,
    city,
    postal_code: postalCode,
    agent_search_string: agentSearchString,
    requested_time: requestedTime,
    mls_number: mlsNumber,
    appointment_reason: appointmentReason,
    buyer_ids: buyerIds
  } = showing;

  const addressSearchString = [street, city, postalCode, mlsNumber]
    .join(" ")
    .toLowerCase();

  const appliedFilterStatus = applyAppointmentStatusFilters(
    status,
    requestedTime,
    showCancelled,
    showApproved,
    showDeclined,
    showPending,
    showExpired,
    showOthers
  );

  if (status === "stop") return appliedFilterStatus;

  return (
    appliedFilterStatus &&
    applyAppointmentReasonFilter(appointmentReason, appointmentReasonFilter) &&
    applyAppointmentTypeFilters(
      appointmentType,
      isListingAgent,
      isShowingAgent
    ) &&
    applySearchTermFilter(addressSearchString, addressSearchTerm) &&
    applySearchTermFilter(agentSearchString, agentSearchTerm) &&
    applyBuyerFilter(buyerIds, buyer)
  );
};

export default applyFilter;
