import css from "./ShowingItem.sass";
import React from "react";
import Link from "~brokerage/components/shared/Link";
import { withRouter } from "react-router-dom";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil";
import Icon from "~brokerage/components/shared/Icon";
import moment from "moment";

const ListingItem = props => {
  const { street, city, price, mls_number } = props.listing;
  return (
    <div className={css.base}>
      <PropertyThumbnail image={null} className={css.photo} />
      <div className={css.addressContainer}>
        <div className={css.address}>
          <h1 className={css.h1}>{street}</h1>
        </div>
      </div>
      <div>
        <ul className={css.ul}>
          <li className={css.li}>
            <div className={css.listIcon}>
              <Icon name="info" />
              {`MLS# ${mls_number}`}
            </div>
          </li>
          <li className={css.li}>
            <div className={css.listIcon}>
              <Icon name="dollar" />
              {price}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default withRouter(ListingItem);
