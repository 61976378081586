import css from "./ShortInfoContacts.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import { format as formatPhone } from "~brokerage/libs/helpers/PhonesHelper";

export default class ShortInfoContacts extends React.PureComponent {
  static propTypes = {
    person: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
      brokerageName: PropTypes.string
    })
  };

  renderContact(value, iconName) {
    if (value) {
      return (
        <div className={css[`${iconName}`]}>
          <li className={css.li}>
            <div className={css.icon}>
              <Icon name={iconName} />
            </div>
            {value}
          </li>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { brokerageName, email, phone } = this.props.person;

    return (
      <ul className={css.base}>
        {this.renderContact(brokerageName, "building")}
        {this.renderContact(email, "mail")}
        {this.renderContact(phone ? formatPhone(phone) : null, "phone")}
      </ul>
    );
  }
}
