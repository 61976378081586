// function to validate the email address only check for formatting errors not if it is a valid email address
export const validateEmail = email => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// function to validate the phone number only check for formatting errors not if it is a valid phone number
export const maskPhoneValue = (phone, unmask = false) => {
  const hasNumbers = /\d/;

  // if the phone number is empty or has no numbers return an empty string
  if (!phone || !hasNumbers.test(phone)) {
    return "";
  }

  let output = phone.replace(/[^0-9]/g, "");

  if (unmask) {
    return output;
  }

  let size = output.length;
  if (size > 0) {
    output = "(" + output;
  }
  if (size > 3) {
    output = output.slice(0, 4) + ") " + output.slice(4, 11);
  }
  if (size > 6) {
    output = output.slice(0, 9) + "-" + output.slice(9);
  }
  return output;
};
