export const CATEGORIES = {
  all_agents: { iconName: "userMultiple" },
  brokerage: { iconName: "building" },
  my_team: { iconName: "userMultiple" },
  customers: { iconName: "userMultiple" },
  my_sellers: { iconName: "userMultiple" },
  my_accounts: { iconName: "userMultiple" }
};

export const CATEGORY_TYPES = {
  customers: "customers",
  seller_tenant: "seller_tenant",
  buyer: "buyer",
  all_agents: "all_agents"
};

export const MODAL_ACTIONS = {
  create_customer: "create_customer",
  export_contacts: "export_contacts"
};

export const CATEGORIES_SEARCH_PARAMS = ["category", "search", "is_archive"];
export const ASSISTANTS_PAGE_ROUTE = "/people/assistants";
