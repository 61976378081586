import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Main from './Main'
import Legend from './Legend'

export default class Timeline extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    showing: PropTypes.object
  }

  render() {
    return (
      <div className={css.base}>
        <Main showing={this.props.showing} onChange={this.props.onChange}/>
        <Legend/>
      </div>
    )
  }
}
