import moment from "moment";
import { DEFAULT_SHOWING_DURATION } from "~brokerage/constants/showings";

export const toMinutes = hours => {
  const hoursDotMinutes = hours;
  const fieldArray = hoursDotMinutes.split(":");
  const minutes = parseInt(fieldArray[0]) * 60 + parseInt(fieldArray[1]);
  return minutes;
};

export const fromMinutes = minutes => {
  const m = minutes % 60;
  const h = (minutes - m) / 60;
  const HHMM = h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
  return HHMM;
};

export const getTimes = showings =>
  showings.map(s => {
    const start = moment(s.requestedTimeRaw).format("HH:mm");
    const end = moment(s.requestedTimeRaw)
      .add(parseInt(s.duration, 10), "minutes")
      .format("HH:mm");
    return [start, end];
  });

export const getOpenSlots = schedule => {
  const openSlots = [];
  const fistAppt = schedule[0] && schedule[0][0];
  const lastAppt =
    schedule[schedule.length - 1] && schedule[schedule.length - 1][1];

  if (!schedule.length) {
    return ["00:00", "23:00"];
  }

  if (fistAppt !== "00:00") {
    openSlots.push(["00:00", fistAppt]);
  }

  schedule.forEach((curr, i) => {
    const prev = schedule[i - 1];
    const currStart = curr[0] && parseInt(curr[0].split(":").join(""), 10);
    const prevEnd =
      prev && prev[1] && parseInt(prev[1].split(":").join(""), 10);

    const isOverlap = currStart && currStart < prevEnd;

    if (isOverlap) {
      openSlots[openSlots.length - 1][1] = prev[0];
      return;
    }

    const isTimeBetween = prev && prev[1] !== curr[0];

    if (i > 0 && isTimeBetween) {
      openSlots.push([prev[1], curr[0]]);
    }
  });

  if (lastAppt !== "23:00") {
    openSlots.push([lastAppt, "23:00"]);
  }

  return openSlots.filter((v, i, a) => a.indexOf(v) === i);
};

export const findIdealSlot = (openSlots, preferredSlot, date) => {
  // if today, trim open slots to 1hr from now
  const now = moment();
  let slotsInMinutes = openSlots.map(slot => {
    return [toMinutes(slot[0]), toMinutes(slot[1])];
  });

  if (moment(date).isSame(now, "day")) {
    const minimumSlot = now
      .set("hour", now.hour())
      .add(1, "hour")
      .startOf("hour")
      .format("HH:mm");
    const minMinutes = toMinutes(minimumSlot);

    slotsInMinutes = slotsInMinutes.filter(slot => {
      // return only slots after min
      const currStart = slot[0];
      const currEnd = slot[1];
      const slotAfterMin = currStart > minMinutes || currEnd > minMinutes;
      return slotAfterMin;
    });
    // if the start time of the first filtered slot is before the min, use the min
    if (slotsInMinutes[0][0] < minMinutes) {
      slotsInMinutes[0][0] = minMinutes;
    }
  }

  const preferredStart = toMinutes(preferredSlot[0]);
  const preferredEnd = toMinutes(preferredSlot[1]);

  const preferredTimeAvailable = slotsInMinutes.find(slot => {
    return preferredStart >= slot[0] && preferredEnd <= slot[1];
  });

  if (preferredTimeAvailable) {
    const [hours, minutes] = preferredSlot[0].split(":").map(Number);
    const updatedDate = moment(date)
      .set({ hour: hours, minute: minutes })
      .format();
    return updatedDate;
  } else {
    const closest = slotsInMinutes.reduce((prev, curr) => {
      const prevStart = prev[0];
      const prevEnd = prev[1];
      const currStart = curr[0];
      const currEnd = curr[1];

      // Evaluate the start of the slot
      // If false, evaluate the end of the slot
      const currSlotIsAfterPreferred = currStart > preferredStart;
      const prevSlotIsAfterPreferred = prevStart > preferredEnd;

      const slotHasTheSpace = currEnd - currStart > 15;

      if (!slotHasTheSpace) {
        return prev;
      }

      if (currSlotIsAfterPreferred) {
        // current slot is after the preferred start time, use the beginning of the slot
        if (prevSlotIsAfterPreferred) {
          const diffCurrStartFromPref = Math.abs(currStart - preferredStart);
          const diffPrevStartFromPref = Math.abs(prevStart - currStart);
          return diffCurrStartFromPref < diffPrevStartFromPref ? curr : prev;
        } else {
          const diffCurrStartFromPref = Math.abs(currStart - preferredStart);
          const diffPrevEndFromPref = Math.abs(
            prevEnd - preferredStart - DEFAULT_SHOWING_DURATION
          );

          return diffCurrStartFromPref < diffPrevEndFromPref ? curr : prev;
        }
      } else {
        // current slot is before the preferred start time, use the end of the slot
        if (prevSlotIsAfterPreferred) {
          const diffCurrEndFromPref = Math.abs(
            currEnd - preferredStart - DEFAULT_SHOWING_DURATION
          );
          const diffPrevEndFromPref = Math.abs(
            prevEnd - currEnd - DEFAULT_SHOWING_DURATION
          );
          return diffCurrEndFromPref < diffPrevEndFromPref ? curr : prev;
        } else {
          const diffCurrEndFromPref = Math.abs(
            currEnd - preferredStart - DEFAULT_SHOWING_DURATION
          );
          const diffPrevEndFromPref = Math.abs(
            prevEnd - currEnd - DEFAULT_SHOWING_DURATION
          );
          return diffCurrEndFromPref < diffPrevEndFromPref ? curr : prev;
        }
      }
    });

    let slot = moment(date).hours(10);

    if (closest) {
      if (closest[0] < preferredStart) {
        // end - duration
        const HHMM = fromMinutes(closest[1] - DEFAULT_SHOWING_DURATION);
        slot.hour(HHMM.split(":")[0]).minutes(HHMM.split(":")[1]);
      } else {
        //use start
        const HHMM = fromMinutes(closest[0]);
        slot.hour(HHMM.split(":")[0]).minutes(HHMM.split(":")[1]);
      }
    }
    return slot.format();
  }
};
