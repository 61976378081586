import { CALL_API } from "~brokerage/middlewares/api";

export function fetchShowingRoutes(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "routes",
      params
    }
  };
}

export function fetchShowingRoute(id, persistedValues, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `routes/${id}`,
      actionParams: {
        persistedValues
      }
    }
  };
}

export function createRoute(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "routes",
      method: "post",
      data: {
        ...params
      }
    }
  };
}

export function addAppointments(id, payload, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `routes/${id}/add_listings`,
      method: "post",
      data: {
        ...payload
      }
    }
  };
}
