import React from "react";

import * as reasons from "~brokerage/constants/showings/noShowReasons";
import { Radio } from "~brokerage/components/shared/Form";
import List from "~brokerage/components/shared/List";

const ROWS = [
  {
    text: "Client Availability Changed",
    value: reasons.CLIENT_AVAILABILITY_CHANGED
  },
  {
    text: "Showing Team Availability Changed",
    value: reasons.MY_AVAILABILITY_CHANGED
  },
  {
    text: "Client No Longer Interested",
    value: reasons.CLIENT_NOT_INTERESTED
  },
  {
    text: "Scheduled in Error",
    value: reasons.SCHEDULED_IN_ERROR
  }
];

const ShowingExplanation = ({ details, changeDetails }) => {
  return (
    <List variant="bordered" modifier="striped">
      {ROWS.map((row, index) => (
        <List.Item key={`explanation-row-${index}`}>
          <Radio
            variant="listItem"
            modifier="rightAligned"
            value={row.value}
            checked={details.explanation === row.value}
            onChange={() => changeDetails({ explanation: row.value })}
          >
            {row.text}
          </Radio>
        </List.Item>
      ))}
    </List>
  );
};

export default ShowingExplanation;
