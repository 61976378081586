import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchBrokerageUsers() {
  return api.settings.fetchBrokerageUsers([
    types.BROKERAGE_SETTINGS_USERS_REQUEST,
    types.BROKERAGE_SETTINGS_USERS_SUCCESS,
    types.BROKERAGE_SETTINGS_USERS_FAILURE
  ]);
}

export function inviteBrokerageUser(user) {
  return api.settings.inviteBrokerageUser(user, [
    types.BROKERAGE_SETTINGS_INVITE_USER_REQUEST,
    types.BROKERAGE_SETTINGS_INVITE_USER_SUCCESS,
    types.BROKERAGE_SETTINGS_INVITE_USER_FAILURE
  ]);
}

export function inviteBrokerageAgent(user) {
  return api.settings.inviteBrokerageUser(user, [
    types.BROKERAGE_SETTINGS_INVITE_AGENT_REQUEST,
    types.BROKERAGE_SETTINGS_INVITE_AGENT_SUCCESS,
    types.BROKERAGE_SETTINGS_INVITE_AGENT_FAILURE
  ]);
}

export function updateInvitedBrokerageUser(userId, user) {
  return api.settings.updateInvitedBrokerageUser(userId, user, [
    types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_REQUEST,
    types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_SUCCESS,
    types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_FAILURE
  ]);
}

export function removeInvitedBrokerageUser(userId) {
  return api.settings.removeInvitedBrokerageUser(userId, [
    types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_REQUEST,
    types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_SUCCESS,
    types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_FAILURE
  ]);
}

export function fetchShowingCoordinators() {
  return api.settings.fetchShowingCoordinators([
    types.BROKERAGE_SETTINGS_SHOWING_COORDINATORS_REQUEST,
    types.BROKERAGE_SETTINGS_SHOWING_COORDINATORS_SUCCESS,
    types.BROKERAGE_SETTINGS_SHOWING_COORDINATORS_FAILURE
  ]);
}

export function updateShowingCoordinatorEnabled(enabled) {
  return api.settings.updateShowingCoordinatorEnabled(enabled, [
    types.BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST,
    types.BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS,
    types.BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE
  ]);
}

export function fetchAssistants() {
  return api.settings.fetchAssistants([
    types.BROKERAGE_SETTINGS_ASSISTANTS_REQUEST,
    types.BROKERAGE_SETTINGS_ASSISTANTS_SUCCESS,
    types.BROKERAGE_SETTINGS_ASSISTANTS_FAILURE
  ]);
}

export function clearErrors() {
  return { type: types.BROKERAGE_SETTINGS_CLEAR_ERRORS };
}

export function fetchSystemSettings() {
  return api.settings.fetchSystemSettings([
    types.SYSTEM_SETTINGS_REQUEST,
    types.SYSTEM_SETTINGS_SUCCESS,
    types.SYSTEM_SETTINGS_FAILURE
  ]);
}

export function addOfficeInvolvementTeamMember(member) {
  return {
    type: types.SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_ADD_MEMBER,
    member
  };
}

export function updateOfficeInvolvementTeamMember(member) {
  return {
    type: types.SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_MEMBER,
    member
  };
}

export function removeOfficeInvolvementTeamMember(member) {
  return {
    type: types.SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_REMOVE_MEMBER,
    member
  };
}

export function updateTeamsInstructions(teamInstructions) {
  return {
    type: types.SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_TEAM_INSTRUCTIONS,
    teamInstructions
  };
}

export function updateAcceptShowingRequest(acceptShowingRequest) {
  return {
    type: types.SYSTEM_SETTINGS_UPDATE_ACCEPT_SHOWING_REQUEST,
    acceptShowingRequest
  };
}

export function updateDefaultSystemSettings(defaultSystemSettings) {
  return {
    type: types.SYSTEM_SETTINGS_UPDATE_DEFAULT_SYSTEM_SETTINGS,
    defaultSystemSettings
  };
}

export function clearMemberDiff() {
  return {
    type: types.SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_CLEAR_MEMBER_DIFF
  };
}

export function fetchDefaultRestrictions() {
  return api.settings.fetchRestrictions([
    types.FETCH_DEFAULT_RESTRICTION_REQUEST,
    types.FETCH_DEFAULT_RESTRICTION_SUCCESS,
    types.FETCH_DEFAULT_RESTRICTION_FAILURE
  ]);
}

export function addDefaultRestriction(restriction) {
  return api.settings.addRestriction(restriction, [
    types.ADD_DEFAULT_RESTRICTION_REQUEST,
    types.ADD_DEFAULT_RESTRICTION_SUCCESS,
    types.ADD_DEFAULT_RESTRICTION_FAILURE
  ]);
}

export function updateDefaultRestriction(
  restrictionId,
  restriction
) {
  return api.settings.updateRestriction(restrictionId, restriction, [
    types.UPDATE_DEFAULT_RESTRICTION_REQUEST,
    types.UPDATE_DEFAULT_RESTRICTION_SUCCESS,
    types.UPDATE_DEFAULT_RESTRICTION_FAILURE
  ]);
}

export function removeDefaultRestriction(restrictionId) {
  return api.settings.removeRestriction(restrictionId, [
    types.REMOVE_DEFAULT_RESTRICTION_REQUEST,
    types.REMOVE_DEFAULT_RESTRICTION_SUCCESS,
    types.REMOVE_DEFAULT_RESTRICTION_FAILURE
  ]);
}

export function fetchDefaultShowingSetting() {
  return api.settings.fetchDefaultShowingSetting([
    types.FETCH_DEFAULT_SHOWING_SETTING_REQUEST,
    types.FETCH_DEFAULT_SHOWING_SETTING_SUCCESS,
    types.FETCH_DEFAULT_SHOWING_SETTING_FAILURE
  ]);
}

export function updateDefaultShowingSettingChanges(changes) {
  return {
    type: types.DEFAULT_SHOWING_SETTING_CHANGES,
    changes
  };
}

export function saveDefaultShowingSettingChanges(changes) {
  return api.settings.saveDefaultShowingSettingChanges(changes, [
    types.SAVE_DEFAULT_SHOWING_SETTING_CHANGES_REQUEST,
    types.SAVE_DEFAULT_SHOWING_SETTING_CHANGES_SUCCESS,
    types.SAVE_DEFAULT_SHOWING_SETTING_CHANGES_FAILURE
  ]);
}

export function saveInitialDefaultAdvanceNoticeDuration(duration) {
  return {
    type: types.SAVE_INITIAL_DEFAULT_ADVANCE_NOTICE_DURATION,
    duration
  };
}
