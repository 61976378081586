import { CALL_API } from "~brokerage/middlewares/api";

export function fetchPriceAnalysis(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "reports/price_analysis",
      params
    }
  };
}
