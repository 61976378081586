import css from './Sidebar.sass'
import React from 'react'
import Header from './Header'
import Steps from './Steps'

const Sidebar = (props) => {
  return (
    <div className={css.base}>
      <Header title="New Showing" withClose/>
      <Steps/>
    </div>
  )
}
export default Sidebar
