import { CALL_API } from "~brokerage/middlewares/api";

export function fetchConversations(filter, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "messaging/conversations",
      params: filter
    }
  };
}

export function searchMessages(filter, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "messaging/search_messages",
      params: filter
    }
  };
}

export function fetchConversationMessages(
  conversationType,
  conversationId,
  filter,
  types
) {
  return {
    [CALL_API]: {
      types,
      endpoint: `messaging/conversations/${conversationType}/${conversationId}`,
      params: filter
    }
  };
}

export function updateUnreadMessagesCounters(counts, type) {
  return { counts, type };
}

export function createDirectConversation(
  agentIDs,
  conversationName,
  text,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "post",
      endpoint: "messaging/conversations/direct",
      data: {
        agent_ids: agentIDs,
        name: conversationName,
        message: text
      }
    }
  };
}

export function updateDirectConversationName(conversationId, name, types) {
  return {
    [CALL_API]: {
      types,
      method: "patch",
      endpoint: `direct_conversations/${conversationId}`,
      data: {
        name: name
      }
    }
  };
}

export function markConversationAsRead(
  conversationId,
  conversationType,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "patch",
      endpoint: `messaging/conversations/${conversationType}/${conversationId}`
    }
  };
}

export function fetchUnreadMessagesCount(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "messaging/conversations/counts"
    }
  };
}

export function createMessage(
  conversationType,
  conversationId,
  message,
  attachments,
  filter,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "post",
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/messages`,
      params: filter,
      data: {
        message: {
          msg: message,
          attachment_ids: attachments.attachmentIds,
          listing_id: attachments.listingId
        }
      }
    }
  };
}

export function createNoteMessage(
  conversationType,
  conversationId,
  message,
  attachments,
  filter,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "post",
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/note_messages`,
      params: filter,
      data: {
        message: {
          msg: message,
          attachment_ids: attachments.attachmentIds,
          listing_id: attachments.listingId
        }
      }
    }
  };
}

export function fetchPubnubData(
  conversationType,
  conversationId,
  filter,
  types
) {
  return {
    [CALL_API]: {
      types,
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/pubnub_data`,
      params: filter
    }
  };
}

export function fetchConversationParticipants(
  conversationType,
  conversationId,
  filter,
  types
) {
  return {
    [CALL_API]: {
      types,
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/participants`,
      params: filter
    }
  };
}

export function addAgentToTeam(
  conversationType,
  conversationId,
  agentId,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "post",
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/team_members`,
      data: {
        agent_id: agentId
      }
    }
  };
}

export function removeAgentFromTeam(
  conversationType,
  conversationId,
  agentId,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "delete",
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/team_members/${agentId}`
    }
  };
}

export function addAgentToShowingTeam(
  conversationId,
  teamType,
  agentId,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "post",
      endpoint: `messaging/conversations/showing/${conversationId}/team_members`,
      data: {
        team: teamType,
        agent_id: agentId
      }
    }
  };
}

export function removeAgentFromShowingTeam(
  conversationId,
  teamType,
  agentId,
  types
) {
  return {
    [CALL_API]: {
      types,
      method: "delete",
      endpoint: `messaging/conversations/showing/${conversationId}/team_members/${agentId}`,
      data: {
        team: teamType
      }
    }
  };
}

export function fetchAvailableParticipants(
  conversationType,
  conversationId,
  name,
  types
) {
  return {
    [CALL_API]: {
      types,
      endpoint: `messaging/conversations/${conversationType}/${conversationId}/participants/search`,
      params: { name }
    }
  };
}
