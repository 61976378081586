import React from "react";
import css from "./index.sass";
import {
  Row,
  Label,
  Controls,
  Dropdown
} from "~brokerage/components/shared/Form";

const NoShowingReason = ({ noShowReason, options, handleChange }) => (
  <Row>
    <div className={css.label}>
      <Label valign="dropdown">Why can’t the property be shown?</Label>
    </div>
    <Controls>
      <Dropdown
        name="noShowReason"
        selected={noShowReason}
        onChange={handleChange}
        placeholder={"Select Reason"}
        options={options}
        variant="outline2"
      />
    </Controls>
  </Row>
);

export default NoShowingReason;
