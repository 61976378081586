import css from "./SubHeading.sass";
import React, { useContext } from "react";
import Switch from "~brokerage/components/shared/Switch";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import moment from "moment";

const SubHeading = ({
  setShowListingRestrictions,
  showListingRestrictions
}) => {
  const { times } = useContext(ShowingContext);

  let startTime;
  let endTime;
  let routeTime;
  let duration;

  const formatRouteDuration = duration => {
    if (duration < 3600000) {
      return moment.utc(duration).format("mm [minutes]");
    }
    return moment.utc(duration).format("H [hours] mm [minutes]");
  };

  if (times.length) {
    const lastShowing = times[times.length - 1];
    startTime = times[0].time;
    endTime = lastShowing.time.clone().add(lastShowing.duration, "minutes");
    duration = endTime.diff(startTime);
    routeTime = `Total Route Time: ${startTime.format("LT")} - ${endTime.format(
      "LT"
    )} (${formatRouteDuration(duration)})`;
  }

  const handleRestrictionSwitch = e => {
    setShowListingRestrictions(!showListingRestrictions);
  };

  return (
    <div className={css.header}>
      <div className={css.row}>
        <div className={css.left}>
          <div className={css.heading}>
            {inUsersTimezone(startTime).format("dddd, MMMM DD")}
          </div>
        </div>
        <div className={css.right}>
          <div className={css.label}>Show Listing Restrictions:</div>
          <Switch
            name="showRestrictions"
            checked={showListingRestrictions}
            onChange={handleRestrictionSwitch}
          />
        </div>
      </div>
      <div className={css.routeTime}>{routeTime}</div>
    </div>
  );
};

export default SubHeading;
