import { externalApi } from "brokerage/middlewares/api";
import { MAPBOX_PLACES_URL } from "brokerage/app/constants/apiUrls";
import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";

const mapboxPlaceSearch = async (searchTerm, showings, mapboxkey) => {
  const search = encodeURIComponent(searchTerm);
  const params = {
    limit: 5,
    access_token: mapboxkey,
    types: "place,postcode,address,poi",
    country: "us",
    language: "en"
  };
  const proximityGeo = getProximityGeo(showings);
  if (!!proximityGeo)
    params.proximity = `${proximityGeo.long},${proximityGeo.lat}`;
  const { data } = await externalApi(
    `${MAPBOX_PLACES_URL}${search}.json`,
    params,
    {},
    "get"
  );
  const places = data.features?.map(feature => ({
    coordinates: { long: feature.center[0], lat: feature.center[1] },
    placeName: feature.place_name
  }));
  return !!places.length ? places : [];
};

const getProximityGeo = showings => {
  const initialShowingWithGeo = showings.find(s =>
    validateLatLng(s.coordinates?.lat, s.coordinates?.long)
  );
  if (!!initialShowingWithGeo)
    return {
      lat: initialShowingWithGeo.coordinates.lat,
      long: initialShowingWithGeo.coordinates.long
    };
  else return null;
};

export default mapboxPlaceSearch;
