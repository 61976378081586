import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import css from "./index.sass";
import Timeline from "~brokerage/components/shared/Timeline";
import moment from "moment";
import { callApi } from "~brokerage/middlewares/api";
import { useLocation } from "react-router-dom";
import ShowingProvider from "~brokerage/components/shared/Timeline/ShowingProvider";
import Actions from "./Actions";
import Switch from "~brokerage/components/shared/Switch";
import { toast } from "react-toastify";
import { STATUS_DECLINED } from "~brokerage/constants/showings/statuses";

const ModalEditAppointmentTime = ({ unsavedChanges }) => {
  const [route, setCurrentRoute] = useState();
  const [staticAppointments, setStaticAppointments] = useState([]);
  const [activeAppointments, setActiveAppointments] = useState([]);
  const [showRestrictions, toggleRestrictions] = useState(false);
  const location = useLocation();
  const { query } = location;

  useEffect(() => {
    getAllTheThings();
  }, []);

  const getAllTheThings = async () => {
    const dayArgs = await fetchTheRoute();
    fetchTheDay(dayArgs);
  };

  const fetchTheRoute = async () => {
    const { routeId } = query;
    try {
      const { data } = await callApi(`routes/${routeId}`, {}, {}, "get");
      const route = data.data.route;
      setCurrentRoute(route);
      const showingIds = route.showings.map(s => s.unique_id);
      return { date: route.date, showingIds, stops: route.stops };
    } catch (error) {
      toast.error("Failed to get selected route.");
    }
  };

  const fetchTheDay = async ({ date, showingIds, stops }) => {
    try {
      const { data } = await callApi(
        "showings",
        {
          date,
          limit: 100
        },
        {},
        "get"
      );
      let staticShowings = [];
      let activeShowings = [];

      data.showings.forEach(s => {
        if (showingIds.includes(s.id) && s.status !== STATUS_DECLINED) {
          let changes = {};
          if (unsavedChanges) {
            changes = unsavedChanges.find(i => i.id === s.id) || {};
          }
          activeShowings.push({ ...s, ...changes });
        } else {
          s.status !== STATUS_DECLINED && staticShowings.push(s);
        }
      });
      stops.forEach(stop => {
        let changes = {};
        if (unsavedChanges) {
          changes = unsavedChanges.find(i => i.id === stop.id) || {};
        }
        if (!changes.requestedTimeRaw)
          changes.requestedTimeRaw = stop.start_time;
        if (!changes.travelTime) changes.travelTime = stop.travel_time;
        activeShowings.push({
          ...stop,
          ...changes,
          listingKey: stop.id,
          photoUrl: null
        });
      });
      const addedStops = unsavedChanges
        ? unsavedChanges
            .filter(s => s.type === "stop" && s.id < 0)
            .map(stop => ({ ...stop, listingKey: stop.id, photoUrl: null }))
        : [];
      activeShowings.push(...addedStops);
      setStaticAppointments(staticShowings);
      setActiveAppointments(activeShowings);
    } catch (error) {
      toast.error("Failed to get route appointments.");
    }
  };

  const handleRestrictionSwitch = e => {
    toggleRestrictions(!showRestrictions);
  };

  if (!route) return <div />;

  return (
    <div className={css.base}>
      <div className={css.header}>
        <div className={css.left}>
          <h1 className={css.h1}>Edit Appointment Time</h1>
          <h3>{moment(route.date).format("dddd, MMMM DD")}</h3>
        </div>
        <div className={css.right}>
          <div className={css.label}>Show Listing Restrictions:</div>
          <Switch
            name="showRestrictions"
            checked={showRestrictions}
            onChange={handleRestrictionSwitch}
          />
        </div>
      </div>
      <ShowingProvider>
        <Timeline
          showRestrictions={showRestrictions}
          staticAppointments={staticAppointments}
          activeAppointments={activeAppointments}
        />
        <Actions
          showings={activeAppointments}
          unsavedChanges={unsavedChanges}
        />
      </ShowingProvider>
    </div>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity } = routes.single.route || {};
  const { unsavedChanges } = entity || {};
  return {
    unsavedChanges
  };
};

export default connect(mapStateToProps)(ModalEditAppointmentTime);
