import React from "react";
import moment from "moment";

import {
  Pending,
  Approved,
  Preapproved,
  Declined,
  Cancelled,
  InstructionChange,
  TimeChange,
  RequestACall,
  EventText
} from "./MessageTypes";
import EventIcon from "./EventIcon";
import css from "./index.sass";

const messageTemplates = {
  preapproved: Preapproved,
  approved: Approved,
  instant_approved: Approved,
  declined: Declined,
  instant_declined: Declined,
  pending: Pending,
  instant_pending: Pending,
  cancelled: Cancelled,
  instructions: InstructionChange,
  request_a_call: RequestACall,
  time_changed: TimeChange,
  event: EventText
};

const HistoryEvent = ({ message }) => {
  const { messageType, instructions, createdAt, msg } = message;
  const MessageText = messageTemplates[messageType];

  //Check if render is required (Remember! Listing has been eliminated)
  const instructionsEmpty =
    MessageText === InstructionChange &&
    Object.values(instructions).every(instruction => !instruction);
  const messageTextIsEmpty = !MessageText || instructionsEmpty;

  if (messageTextIsEmpty) {
    return null;
  }

  return (
    <div className={css.historyElement}>
      <div className={css.icon}>
        <EventIcon status={messageType} text={msg} />{" "}
      </div>
      <div className={css.messageContainer}>
        <div className={css.timeStamp}>
          {moment(createdAt).format("h:mm a, dddd, MMMM Do")}
        </div>
        <div className={css.messageWrapper}>
          <MessageText message={message} />
        </div>
      </div>
    </div>
  );
};

export default HistoryEvent;
