export const IMAGE_CONTENT_TYPES = ["image/png", "image/jpeg"];
export const DOCUMENT_CONTENT_TYPES = ["application/pdf"];

export const FILE_PICKER_ACCEPTED_TYPES = [...DOCUMENT_CONTENT_TYPES];
export const MAX_NO_OF_FILE_UPLOAD_AT_A_TIME = 5;

export const MAX_FILE_SIZE_MB = 5;
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export const HUMANIZED_ACCEPTED_FILE_TYPES = {
  "application/pdf": "PDF",
  "image/png": "png",
  "image/jpeg": "jpeg"
};

export const TIMESTAMP_REGEX = /_\d{2}\d{2}\d{4}/;
