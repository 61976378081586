import css from './Dashboard.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { hideWelcomeScreen } from '~brokerage/actions/myAccount'
import config from '~brokerage/config/config'
import Button from '~brokerage/components/shared/Button'
class Dashboard extends Component {
  static propTypes = {
    location: PropTypes.object,
    onHideWelcomeScreenClick: PropTypes.func,
    hasAccessToTransactionsChat: PropTypes.bool,
    dispatch: PropTypes.func
  }

  hideWelcomeScreen() {
    this.props.dispatch(hideWelcomeScreen())
  }

  modalUrl(modal) {
    const { location } = this.props

    return {
      pathname: location.pathname,
      query: {
        ...location.query,
        modal
      }
    }
  }

  render() {
    const { hasAccessToTransactionsChat } = this.props
    return (
      <div className={css.base}>
        <div className={css.table}>
          <div className={css.tableCell}>
            <div className={css.btnHide}>
              <Button variant="outline" onClick={this.props.onHideWelcomeScreenClick}>
                Hide
              </Button>
            </div>
            <div className={css.logo}/>

            <div className={css.intro}>
              <p className={css.introPLead}>The fastest way to chat & schedule</p>
              <p className={css.introP}>Always know the agent, listing, & context to negotiate securely</p>
            </div>

            <div className={css.actions}>
              <Link className={css.action} to='/people'>
                <div className={css.actionIconMessage}/>
                <p className={css.actionH}>Message Any Agent</p>
                <p className={css.actionP}>Directory & chat with any agent in your MLS or brokerage</p>
                <div className={css.actionButton}>Message</div>
              </Link>

              {hasAccessToTransactionsChat &&
              <Link className={css.action} to={this.modalUrl('new_showing')}>
                <div className={css.actionIconSchedule}/>
                <p className={css.actionH}>Schedule Any Listing</p>
                <p className={css.actionP}>Schedule a showing for any MLS listing</p>
                <div className={css.actionButton}>Schedule</div>
              </Link>
              }

              {hasAccessToTransactionsChat &&
              <Link className={css.action} to='/routes'>
                <div className={css.actionIconRoute}/>
                <p className={css.actionH}>Route Multiple Listings</p>
                <p className={css.actionP}>Add different listings & schedule with Smart Routes</p>
                <div className={css.actionButton}>Route</div>
              </Link>
              }
            </div>

            {hasAccessToTransactionsChat &&
            <div className={css.requireSetup}>Setup showing instructions for your listings <Link to='/listings'>here</Link><br/>(instant approvals, lockbox information, sellers, restrictions, etc.)</div>
            }

            <div className={css.footer}>
              <p>Get Aligned Showings for <a href={config.ios_store_link} target='_blank'>iOS</a> & <a href={config.android_store_link} target='_blank'>Android</a>.</p>

              <p>
                Work with a showing coordinator, team, or big brokerage?<br/>
                Aligned Showings is built to power teams. For more information, contact us <Link data-intercom-launcher='' to=''>here</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(Dashboard)
