import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import {
  changeShowingListFilter,
  fetchShowingsCounts,
  fetchAvailableRoles,
  fetchShowings,
  fetchAgents
} from '~brokerage/actions/showings'
import Content from '~brokerage/components/shared/Content'
import ShowingsHeader from '~brokerage/components/showings/ShowingsHeader'
import Showing from './show'

class Showings extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    history: PropTypes.object,
    filter: PropTypes.object,
    isFetching: PropTypes.bool,
    didInvalidate: PropTypes.bool,
    showings: PropTypes.array,
    match: PropTypes.object,
    dispatch: PropTypes.func
  }

  componentWillMount() {
    const { status, agent: agentId, role } = this.props.location.query

    this.props.dispatch(fetchAvailableRoles())
    this.props.dispatch(changeShowingListFilter({ status, agentId, role }))
    this.props.dispatch(fetchAgents())
    this.props.dispatch(fetchShowingsCounts({ role, agentId }))
    this.props.dispatch(fetchShowings({ status, agent: agentId, role }))

    this.startsWithSingle = !isNil(this.props.match.params.showingId)
  }

  componentWillReceiveProps(nextProps) {
    const { isFetching, didInvalidate } = this.props
    const { didInvalidate: nextDidInvalidate, isFetching: nextIsFetching, showings: nextShowings } = nextProps
    const { showingId: nextShowingId } = nextProps.match.params
    const { status, role, agent: agentId } = this.props.location.query
    const { status: nextStatus, role: nextRole, agent: nextAgentId } = nextProps.location.query

    const hasChanges = status !== nextStatus || role !== nextRole || agentId !== nextAgentId
    const gotInvalidated = !didInvalidate && nextDidInvalidate

    if (hasChanges) {
      this.props.dispatch(changeShowingListFilter({ status: nextStatus, role: nextRole, agentId: nextAgentId }))
    }

    if (role !== nextRole || agentId !== nextAgentId || gotInvalidated) {
      this.props.dispatch(fetchShowingsCounts({ role: nextRole, agentId: nextAgentId }))
    }

    if (gotInvalidated || hasChanges) {
      this.props.dispatch(fetchShowings({ status: nextStatus, agent: nextAgentId, role: nextRole }))
    }

    if (isFetching && !nextIsFetching) {
      if (!didInvalidate && nextShowings.length && !this.startsWithSingle && nextShowings[0].id !== nextShowingId) {
        this.redirectToFirstShowing(nextProps)
      }
      this.startsWithSingle = false
    }
  }

  redirectToFirstShowing(props) {
    const { history } = props
    const { role } = props.filter
    const { location, showings } = props

    history.replace({
      ...location,
      pathname: `/showings/${showings[0].id}`,
      query: { ...location.query, role }
    })
  }

  render() {
    return (
      <div>
        <ShowingsHeader location={this.props.location}/>
        <Content header="single">
          <Showing params={this.props.match.params} location={this.props.location} />
        </Content>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { filter, isFetching, didInvalidate, entities: showings } = state.showings.list.showings

  return {
    filter,
    isFetching,
    didInvalidate,
    showings
  }
}
export default connect(mapStateToProps)(Showings)
