import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SettingsSideBar from "~brokerage/components/settings/SettingsSideBar";
import SettingsPane from "~brokerage/components/settings/SettingsPane";
import SettingsContent from "~brokerage/components/settings/SettingsContent";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import { callApi } from "~brokerage/middlewares/api";
import Loader from "~brokerage/components/shared/Loader";
import { toast } from "react-toastify";
import css from "./index.sass";
import prepareFormData from "../util/prepareFormData";
import NavigationPrompt from "~brokerage/components/shared/NavigationPrompt";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import OfficeInvolvement from "../OfficeInvolvement";
import DefaultTimeDateRestrictions from "~brokerage/components/settings/components/DefaultTimeDateRestrictions";
import {
  fetchSystemSettings,
  clearMemberDiff,
  updateDefaultSystemSettings
} from "~brokerage/actions/settings";

import { Submit, ShowingRequests } from "../sections";

const Preferences = props => {
  const { dispatchPageState } = useContext(SettingsContext);
  const [showDialog, setShowDialog] = useState();
  const [confirmMessage, setConfirmMessage] = useState({
    heading: "Confirm",
    body: ""
  });
  const history = useHistory();

  // instantiate react hook form
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = methods;

  useEffect(() => {
    props.dispatch(fetchSystemSettings(true));
  }, []);

  // function to handle form submit errors
  const onError = error => {
    try {
      for (let key in error) {
        toast.error(error[key].message);
      }
    } catch (error) {
      toast.error("There was an error submitting your form. Please try again.");
    }
  };

  const onSubmit = data => {
    // prevent the form from submitting twice by disabling the submit button
    dispatchPageState({ type: "submit" });
    updateSystemPreference();
    props.dispatch(clearMemberDiff());
  };

  const updateSystemPreference = () => {
    setShowDialog(false);
    let fData = getValues();
    let officeInvolvementData = props.system;
    var systemFormData = { ...fData, officeInvolvementData };
    const formData = prepareFormData(systemFormData);

    // call the api to update the user profile
    callApi("system_preferences", {}, formData, "patch")
      .then(response => {
        toast.success("System preferences saved");
        dispatchPageState({ type: "submit_complete" });
      })
      .catch(error => {
        console.log(error);
        let errorMessage = "An error occurred";
        if (error.response.data.hasOwnProperty("errors")) {
          let errors = error.response.data.errors;
          errorMessage = Array.isArray(errors) ? errors.join(" ") : errors;
        }
        toast.error(`Error submitting your changes: ${errorMessage}`);
        dispatchPageState({ type: "submit_complete" });
      });
  };

  // set the page state to dirty to confirm the user wants to leave the page
  const onChange = () => {
    dispatchPageState({ type: "working" });
  };

  const onReset = () => {
    props.dispatch(updateDefaultSystemSettings(props.defaultSystemPreference));
    dispatchPageState({ type: "working_complete" });
  };

  const onCancel = () => {
    dispatchPageState({ type: "submit_complete" });
  };

  const promptButtons = [
    {
      label: "Cancel",
      variant: "outline",
      onClick: onCancel
    },
    {
      label: "OK",
      variant: "primary",
      onClick: updateSystemPreference
    }
  ];

  const isSelectedPreferenceTeamSettings = props.location.pathname.split('/').pop() === "team_settings";

  const {
    systemSetting: {
      acceptShowingRequest,
      listingCount,
      teamInstructions,
      officeInvolvementTeamMembers,
      systemOptOutManagedByMls
    }
  } = props;

  return (
    <>
      <NavigationPrompt
        open={showDialog}
        message={confirmMessage}
        buttons={promptButtons}
      />
      <FormProvider {...methods}>
        <Button
          className={css.backButton}
          variant="link"
          onClick={() => history.replace("/settings/system")}
        >
          <Icon name="arrowLeft" modifier="offset5 valignTextDefault" />
          Back
        </Button>
        <h3 className={css.formHeader}>
          {isSelectedPreferenceTeamSettings ? "Team Settings" : "Global Showings Opt-Out"}
        </h3>
        <form
          className={css.profileSettings}
          onSubmit={handleSubmit(onSubmit, onError)}
          onChange={onChange}
        >
          {isSelectedPreferenceTeamSettings ? (
            <OfficeInvolvement
              people={officeInvolvementTeamMembers}
              teamInstructions={teamInstructions}
            />
          ) : (
            <ShowingRequests
              hasListings={listingCount > 0}
              currentlyOptedOut={!acceptShowingRequest}
              setValue={setValue}
              systemOptOutManagedByMls={systemOptOutManagedByMls}
            />
          )}
          <Submit onReset={onReset} />
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = state => {
  const {
    systemPreference: systemSetting,
    defaultSystemPreference,
    isFetching
  } = state.settings.system;
  const { system } = state.settings;

  return {
    systemSetting,
    system,
    isFetching,
    defaultSystemPreference
  };
};

export default connect(mapStateToProps)(Preferences);
