import { CALL_API } from "~brokerage/middlewares/api";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper.js";

export function fetchBrokerageUsers(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "settings/brokerage_users"
    }
  };
}

export function inviteBrokerageUser(user, types) {
  return {
    [CALL_API]: {
      types,
      data: { user: deepObjectKeysToSnakeCase(user) },
      endpoint: "settings/brokerage_users",
      method: "post"
    }
  };
}

export function updateInvitedBrokerageUser(userId, user, types) {
  return {
    [CALL_API]: {
      types,
      data: { user: deepObjectKeysToSnakeCase(user) },
      endpoint: `settings/brokerage_users/${userId}`,
      method: "patch"
    }
  };
}

export function removeInvitedBrokerageUser(userId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `settings/brokerage_users/${userId}`,
      method: "delete",
      actionParams: { userId }
    }
  };
}

export function fetchShowingCoordinators(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "settings/showing_coordinators"
    }
  };
}

export function updateShowingCoordinatorEnabled(enabled, types) {
  return {
    [CALL_API]: {
      types,
      method: "patch",
      endpoint: "update_showing_coordinator_enabled",
      data: {
        enabled: enabled
      }
    }
  };
}

export function fetchAssistants(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "settings/assistants"
    }
  };
}

export function fetchSystemSettings(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "system_preferences"
    }
  };
}

export function fetchRestrictions(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "default_restrictions"
    }
  }
}

export function addRestriction(restriction, types) {
  return {
    [CALL_API]: {
      types,
      method: 'POST',
      endpoint: `default_restrictions`,
      data: {
        default_restriction: restriction
      }
    }
  }
}

export function updateRestriction(restrictionId, restriction, types) {
  return {
    [CALL_API]: {
      types,
      method: 'PUT',
      endpoint: `default_restrictions/${restrictionId}`,
      data: {
        default_restriction: restriction
      }
    }
  }
}

export function removeRestriction(restrictionId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'DELETE',
      endpoint: `default_restrictions/${restrictionId}`,
      actionParams: { restrictionId }
    }
  }
}

export function fetchDefaultShowingSetting(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "default_showing_settings"
    }
  };
}

export function saveDefaultShowingSettingChanges(data, types) {
  return {
    [CALL_API]: {
      types,
      method: "PUT",
      endpoint: "default_showing_settings",
      data: { default_showing_setting: data }
    }
  };
}
