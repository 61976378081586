import moment from "moment";

export const formatTime = (requestedTime, duration) => {
  const startTime = moment(requestedTime).format("h:mm a");
  const endTime = moment(requestedTime).add(duration, "m").format("h:mm a");
  return `${startTime} - ${endTime}`;
};

export const formatDate = requestedTime => {
  return moment(requestedTime).format("ddd MMM Do, h:mm a");
};

export const formatString = string =>
  string ? string.replaceAll("_", " ") : "";

export const formatPhone = phoneNumber =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");

export const removeNull = value => ((value === null || value === undefined)  ? "" : value.toString());

export const boolToYesNo = bool => (bool ? "Yes" : "No");
