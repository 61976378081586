import React from "react";
import moment from "moment";
import agendaCSS from "./AgendaEvent.sass";
import dayCSS from "./DayEvent.sass";
import monthCSS from "./MonthEvent.sass";
import EventBase from "./EventBase";

export const AgendaEvent = ({ event }) => {
  const { street, contact_user, status, address } = event.showingData;
  const content = (status === "stop" ? address : street) || "";
  return (
    <EventBase
      css={agendaCSS}
      content={content}
      event={event}
      contactDetail={contact_user}
    />
  );
};

export const DayEvent = ({ event }) => {
  const {
    start,
    showingData: { street, status, address }
  } = event;
  const content = (status === "stop" ? address : street) || "";
  return (
    <EventBase
      css={dayCSS}
      content={`${moment(start).format("h:mm a")}\xa0\xa0${content}`}
      event={event}
    />
  );
};

export const MonthEvent = ({ event }) => {
  const {
    start,
    showingData: { street, status, address }
  } = event;
  const content = (status === "stop" ? address : street) || "";
  return (
    <EventBase
      css={monthCSS}
      content={`${moment(start).format("h:mm a")}\xa0\xa0${content}`}
      event={event}
    />
  );
};
