import React from "react";
import { Popover } from "react-tiny-popover";

import PopoverContent from "./Content";

const MiniDetailsPopup = ({
  info: {
    data,
    meta: { open, targetRect }
  },
  dispatch,
  popoverPositions,
  openModal,
  updateCallback = () => {}
}) => {
  if (!open) {
    return <div />;
  }

  const { x, y, height, width } = targetRect;
  const openShowingModal = () => openModal(data.id);

  const closePopover = () => {
    dispatch({
      action: "close",
      newData: { id: data.id }
    });
  };

  return (
    <Popover
      isOpen={open}
      onClickOutside={closePopover}
      content={popoverState =>
        PopoverContent({
          data,
          openShowingModal,
          updateCallback,
          closePopover
        })
      }
      positions={popoverPositions}
      align="start"
      containerStyle={{
        zIndex: 99,
        boxShadow: "5px 5px 2px 0px rgba(0, 0, 0, .2)"
      }}
      padding={0}
    >
      <div
        style={{
          height: height,
          width: width,
          top: y,
          left: x,
          position: "fixed"
        }}
      />
    </Popover>
  );
};

export default MiniDetailsPopup;
