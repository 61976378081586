import css from "./Grid.sass";
import React from "react";
import { dayInHalfHours } from "~brokerage/libs/helpers/TimeHelper";

const Grid = () => (
  <div className={css.base}>
    {dayInHalfHours.map((time, index) => (
      <div key={index} className={css[index % 2 ? "halfhour" : "hour"]}>
        <div className={css.time}>{time}</div>
      </div>
    ))}
  </div>
);

export default Grid;
