import React, { useState, useReducer, useEffect } from "react";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { callApi } from "~brokerage/middlewares/api";
import Loader from "~brokerage/components/shared/Loader";
import AppointmentDetails from "~brokerage/components/shared/AppointmentDetailsModal";

import "./CalendarOverride.css";
import css from "./index.sass";
import MiniDetailsPopup from "./Popups/MiniDetailsPopup";
import ShowMorePopup from "./Popups/ShowMorePopup";

import {
  convertShowingsToEvents,
  basePath,
  calendarFormats,
  defaultTime,
  allPopoverPositions,
  processStopsFromApi
} from "./Utils/misc";
import {
  detailsPopupInitialState,
  showMorePopupInitialState,
  detailsPopupReducer,
  showMorePopupReducer
} from "./Utils/popupReducer";
import applyFilter from "./Utils/applyFilter";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { AgendaEvent, DayEvent, MonthEvent } from "./Events";
import Toolbar from "./Toolbar";

const localizer = momentLocalizer(moment);
const DAYS_IN_WEEK = 7;

const CalendarSection = ({
  loading,
  setLoading,
  showings,
  setShowings,
  filters
}) => {
  const [modalId, setModalId] = useState(null);
  const [timeRangeStart, setTimeRangeStart] = useState(null);
  const [timeRangeEnd, setTimeRangeEnd] = useState(null);
  const [length, setLength] = useState(DAYS_IN_WEEK);
  const [isCustomView, setCustomView] = useState(false);
  const [calDate, setCalDate] = useState(() => {
    const queryTime = location?.query?.timestamp;
    return queryTime ? moment(queryTime).toDate() : defaultTime;
  });
  const [calView, setCalView] = useState(
    window.innerWidth > 992 ? "month" : "agenda"
  );
  const location = useLocation();
  const history = useHistory();
  let match = useRouteMatch("/calendar/:view");

  const populateRangeEnd = time => {
    const next = time.endOf("month").add(2, "days");
    if (next.isAfter(timeRangeEnd)) {
      loadShowings({ time: next });
      setTimeRangeEnd(next.endOf("month"));
    }
  };

  const populateRangeStart = time => {
    const prev = time.startOf("month").add(-2, "days");
    if (prev.isBefore(timeRangeStart)) {
      loadShowings({ time: prev });
      setTimeRangeStart(prev.startOf("month"));
    }
  };

  useEffect(() => {
    if (match?.params?.view) {
      calView !== match.params.view && setCalView(match.params.view);
    }
  }, [match]);

  useEffect(() => {
    if (isCustomView) return;
    const timestamp = location?.query?.timestamp;
    if (timestamp) {
      const time = moment(timestamp);
      setCalDate(time.toDate());
      if (!showings.length) {
        loadFreshRange(time);
      } else {
        populateRangeEnd(time.clone());
        populateRangeStart(time.clone());
      }
    } else {
      // on mount with no timestamp load todays showings
      loadFreshRange();
    }
  }, [location?.query?.timestamp, location?.query?.endTimestamp]);

  const [detailsPopupState, detailsPopupDispatch] = useReducer(
    detailsPopupReducer,
    detailsPopupInitialState
  );
  const [showMorePopupState, showMorePopupDispatch] = useReducer(
    showMorePopupReducer,
    showMorePopupInitialState
  );

  const loadShowings = async ({ time, size = 1 }) => {
    const timestamp = (time || moment()).toISOString().slice(0, -5) + "Z";
    const updatedShowings = [...showings];

    const { data: showingsData } = await callApi(
      "calendar/showings",
      { timestamp, size: size },
      {},
      "get"
    );
    updatedShowings.push(...showingsData);
    const { data: stopsData } = await callApi(
      "calendar/stops",
      { timestamp, size: size },
      {},
      "get"
    );
    const processedStops = processStopsFromApi(stopsData);
    updatedShowings.push(...processedStops);
    setShowings(updatedShowings);
    setLoading(false);
  };

  const loadFreshRange = newTime => {
    // setLoading(true); //Create a mini loader for the showings alone and link it here
    const time = moment(newTime || calDate);
    loadShowings({ time, size: 3 });
    setTimeRangeStart(time.clone().add(-1, "M").startOf("month"));
    setTimeRangeEnd(time.clone().add(1, "M").endOf("month"));
  };

  const updateShowMorePopupEvents = () => {
    showMorePopupDispatch({
      action: "set selected",
      newSelectedId: detailsPopupState.data.id
    });
  };

  useEffect(updateShowMorePopupEvents, [detailsPopupState]);

  if (loading) {
    return <Loader active />;
  }

  //Filter and convert appointments to calendar events
  const filteredShowings = showings.filter(showing =>
    applyFilter(showing, filters)
  );
  const uniqueShowings = [
    ...new Map(filteredShowings.map(item => [item.id, item])).values()
  ];
  const events = convertShowingsToEvents(
    uniqueShowings,
    detailsPopupDispatch,
    detailsPopupState.data.id
  );

  const popoverPositions = allPopoverPositions[calView];

  const showMoreButton = extra => (
    <div
      className={css.showMoreButton}
      onClick={e => {
        showMorePopupDispatch({
          action: "open",
          newRect: e.currentTarget.getBoundingClientRect()
        });
      }}
    >
      {`+${extra} more`}
    </div>
  );

  //Modifying URL on view/time shift without a refresh
  const setUrl = (newView, newTime, view, toTime = null) => {
    const viewRoute = newView || view;
    const queryTime =
      moment(newTime || calDate)
        .toISOString()
        .slice(0, -5) + "Z";
    let calendarUrl = `${basePath}/${viewRoute}?timestamp=${queryTime}`;
    if (toTime) {
      const endTime = moment(toTime).toISOString().slice(0, -5) + "Z";
      calendarUrl += `&endTimestamp=${endTime}`;
    }
    history.push(calendarUrl);
  };

  const navigationHandler = newTime => {
    const time = moment(newTime);
    if (calView === Views.AGENDA) {
      setUrl(null, time.startOf("day").toDate(), calView);
    } else {
      setUrl(null, time.toDate(), calView);
    }
  };

  const viewHandler = newView => {
    if (isCustomView) {
      setCustomView(false);
      setLength(DAYS_IN_WEEK);
      setShowings([]);
    }
    if (newView === Views.AGENDA) {
      if (newView === calView && !isCustomView) return;
      const newTime = moment(calDate).startOf("day").toDate();
      setUrl(newView, newTime, calView);
    } else {
      if (newView === calView) return;
      setUrl(newView, null, calView);
    }
    setCalView(newView);
  };

  const applyCustomDates = (initialDate, finalDate) => {
    const finalMoment = moment(finalDate).startOf("day");
    const initialMoment = moment(initialDate).startOf("day");
    const newLength = finalMoment.diff(initialMoment, "days");
    setCustomView(true);
    setLength(newLength);
    if (calView !== Views.AGENDA) setCalView(Views.AGENDA);
    setUrl(Views.AGENDA, initialMoment.toDate(), calView, finalMoment.toDate());
    setCalDate(initialMoment.toDate());
    loadCustomRange(initialMoment, finalMoment);
  };

  const loadCustomRange = (initialMoment, finalMoment) => {
    callApi(
      "calendar/showings",
      {
        timestamp: initialMoment.toISOString().slice(0, -5) + "Z",
        size: 1,
        endTimestamp: finalMoment.toISOString().slice(0, -5) + "Z"
      },
      {},
      "get"
    )
      .then(response => {
        setShowings(response.data);
      })
      .then(() => setLoading(false));
  };

  const onCallback = () => {
    if (isCustomView) {
      const initialMoment = moment(calDate);
      const finalMoment = initialMoment.clone().add(length, "d");
      loadCustomRange(initialMoment, finalMoment);
    } else {
      loadFreshRange();
    }
  };

  return (
    <div>
      <AppointmentDetails
        info={detailsPopupState}
        shownDetailsId={modalId}
        setShownDetailsId={setModalId}
        updateCallback={onCallback}
      />
      <ShowMorePopup
        info={showMorePopupState}
        dispatch={showMorePopupDispatch}
      />
      <MiniDetailsPopup
        info={detailsPopupState}
        dispatch={detailsPopupDispatch}
        popoverPositions={popoverPositions}
        openModal={setModalId}
        updateCallback={loadFreshRange}
      />
      <Calendar
        localizer={localizer}
        date={calDate}
        defaultDate={calDate}
        view={calView}
        onView={() => {}}
        onNavigate={navigationHandler}
        onShowMore={(events, date) =>
          showMorePopupDispatch({
            action: "set data",
            newData: { events, date }
          })
        }
        doShowMoreDrillDown={false}
        scrollToTime={calDate}
        events={events}
        className={css.calendar}
        views={[Views.MONTH, Views.AGENDA, Views.DAY]}
        messages={{
          previous: "◄",
          next: "►",
          showMore: showMoreButton,
          agenda: "Week"
        }}
        length={length}
        step={15} //minute increments in day and week view
        timeslots={4}
        components={{
          month: { event: MonthEvent },
          agenda: { event: AgendaEvent },
          day: { event: DayEvent },
          toolbar: props => (
            <Toolbar
              date={calDate}
              length={length}
              isCustomView={isCustomView}
              onViewClick={viewHandler}
              onCustomDatesApplied={applyCustomDates}
              {...props}
            />
          )
        }}
        eventPropGetter={(event, start, end, isSelected) => ({
          className: moment().isSame(start, "day")
            ? css.todayEvent
            : css.regularEvent
        })}
        onDoubleClickEvent={e => {
          const { id, status } = e.showingData;
          if (status !== "stop") setModalId(id);
        }}
        formats={calendarFormats}
      />
    </div>
  );
};

export default CalendarSection;
