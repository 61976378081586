import React from "react";
import Button from "~brokerage/components/shared/Button";

const RouteDetailsButton = ({ route }) => (
  <div>
    <Button
      variant="primary"
      modifier="flexCenter"
      to={`/my_showings/${route.id}/schedule`}
    >
      Route Details
    </Button>
  </div>
);

export default RouteDetailsButton;
