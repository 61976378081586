import css from './BigRadio.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from '~brokerage/components/shared/Form'
import Icon from '~brokerage/components/shared/Icon'
export default class BigRadio extends Component {
  static propTypes = {
    icon: PropTypes.string,
    heading: PropTypes.string,
    body: PropTypes.string
  }

  render () {
    const { icon, heading, body, ...rest } = this.props

    return (
      <Radio variant="bigradio" {...rest}>
        <div className={css.icon}>
          <Icon name={icon} size="32" shade="gray4"/>
        </div>
        <p className={css.heading}><strong>{heading}</strong></p>
        <p className={css.p}>{body}</p>
      </Radio>
    )
  }
}
