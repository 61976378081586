import React, { useState } from "react";
import css from "./AnalysisFilters.sass";
import Button from "~brokerage/components/shared/Button";
import {
  CalendarDropdown,
  Dropdown,
  TextField
} from "~brokerage/components/shared/Form";
import { formatTip } from "./slider";
import {
  DAY_IN_MILLI_SECS,
  BED_BATH_OPTIONS
} from "~brokerage/constants/reports/priceAnalysis";

const Slider = require("rc-slider");
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export default function AnalysisFilters({ currentFilters, onSubmit }) {
  const [filters, setFilters] = useState(currentFilters);

  const { startDate, endDate, beds, zipCode, baths, priceRange } = filters;
  const minStartDate = new Date(Date.now() - 365 * DAY_IN_MILLI_SECS);

  const updateFilters = update => setFilters({ ...filters, ...update });

  const updateDate = (key, newDate) => {
    const updatedDate = new Date(newDate);
    if (key === "startDate" && updatedDate > endDate)
      updateFilters({
        startDate: updatedDate,
        endDate: new Date(updatedDate.getTime() + DAY_IN_MILLI_SECS)
      });
    else updateFilters({ [key]: updatedDate });
  };

  const updatePriceRange = newValues => {
    const [low, high] = newValues;
    if (low < high) updateFilters({ priceRange: newValues });
  };

  return (
    <div className={css.filterContainer}>
      <h1 className={css.title}>Filters</h1>
      <div className={css.row}>
        <p className={css.label}>Start Date:</p>
        <CalendarDropdown
          date={startDate.toUTCString()}
          opened={false}
          onChange={newDate => updateDate("startDate", newDate)}
          minDate={minStartDate}
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>End Date:</p>
        <CalendarDropdown
          date={endDate.toUTCString()}
          opened={false}
          onChange={newDate => updateDate("endDate", newDate)}
          minDate={startDate}
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>Beds:</p>
        <Dropdown
          name="beds"
          options={BED_BATH_OPTIONS}
          selected={beds}
          onChange={({ beds }) => updateFilters({ beds })}
          variant="outline2"
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>Baths:</p>
        <Dropdown
          name="baths"
          options={BED_BATH_OPTIONS}
          selected={baths}
          onChange={({ baths }) => updateFilters({ baths })}
          variant="outline2"
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>Zip Code:</p>
        <TextField
          placeholder="Enter zip code"
          name="zipCode"
          value={zipCode}
          onChange={e => updateFilters({ zipCode: e.target.value })}
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>Sold Price:</p>
        <Range
          min={0}
          max={28}
          step={1}
          value={priceRange}
          onChange={updatePriceRange}
          marks={{ 0: "$0", 28: "$10M+" }}
          tipFormatter={formatTip}
        />
      </div>
      <div className={css.save}>
        <Button
          variant="primary"
          modifier="bigger fullWidth"
          style={{ textAlign: "center" }}
          onClick={() => onSubmit(filters)}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
}
