import React from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import StatusPill from "~brokerage/app/components/shared/StatusPill.jsx";
import { Dropdown } from "~brokerage/components/shared/Form";
import {
  downloadIcs,
  addEventToGoogleCalendar,
  buildCalendarEvent
} from "~brokerage/utils";
import css from "./Header.sass";

const Header = ({
  uniqueId,
  closeModal,
  details,
  showingStatus,
  futureShowing
}) => {
  const { address, showing_status } = details;

  const options = [
    {
      label: "Google Calendar",
      value: "google"
    },
    {
      label: "Outlook Calendar (ics)",
      value: "download"
    }
  ];

  const handleChange = ({ value }) => {
    const {
      agent_contacts: { users },
      local_details: { duration, requested_time }
    } = details || {};

    const event = buildCalendarEvent({
      time: requested_time,
      duration,
      address,
      users,
      uniqueId
    });

    value === "google" ? addEventToGoogleCalendar(event) : downloadIcs(event);
  };

  return (
    <div className={css.mainHeader}>
      <div className={css.row}>
        <div className={css.statusHeader}>Appointment Details</div>
        <StatusPill
          showingStatus={showingStatus}
          futureShowing={futureShowing}
        />
        <CloseLineIcon
          onClick={closeModal}
          size="1.4em"
          className={css.closeIcon}
        />
      </div>
      <div className={css.row}>
        <div className={css.address}>{address}</div>
        <div className={css.showingActionsWrapper}></div>
        {showing_status === "approved" && (
          <div className={css.export}>
            <Dropdown
              title={"Add to Calendar"}
              options={options}
              onChange={handleChange}
              variant="outline2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
