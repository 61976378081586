import css from './ListingsCounter.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class ListingsCounter extends React.PureComponent {
  static propTypes = {
    totalListings: PropTypes.number
  }

  listingsLabel() {
    const { totalListings } = this.props
    if (totalListings) {
      const listingsForm = totalListings === 1 ? 'listing' : 'listings'
      return `${totalListings} ${listingsForm}`
    } else {
      return null
    }
  }

  render() {
    return (
      <div className={css.base}>
        {this.listingsLabel()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const totalListings = state.people.single.listings.totalListings

  return { totalListings }
}
export default connect(mapStateToProps)(ListingsCounter)
