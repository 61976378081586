import React from "react";
import {
  Row,
  Label,
  Controls,
  Textarea
} from "~brokerage/components/shared/Form";
import css from "./ShowingInstructions.sass";

const AdditionalInstructions = ({ message, handleChange }) => (
  <Row>
    <div className={css.label}>
      <Label>
        Additional instructions <small>Sent after APPROVAL.</small>
      </Label>
    </div>

    <div className={css.textAreaControls}>
      <Controls>
        <Textarea
          name="message"
          value={message}
          placeholder="Enter Additional instructions for Showing Agents e.g. turn off lights after, etc"
          onChange={handleChange}
        />
      </Controls>
    </div>
  </Row>
);

export default AdditionalInstructions;
