import {
  APPOINTMENT_STATUSES,
  PAST_APPOINTMENT_STATUSES,
  PAST_APPOINTMENT_STATUSES_MAP,
  DISABLED_STATUSES
} from "./constants";

const isOptionDisabled = key => Object.keys(DISABLED_STATUSES).includes(key);

export const getAppointmentStatusOptions = () => {
  return Object.keys(APPOINTMENT_STATUSES).map(key => ({
    value: APPOINTMENT_STATUSES[key],
    label:
      APPOINTMENT_STATUSES[key].charAt(0).toUpperCase() +
      APPOINTMENT_STATUSES[key].slice(1),
    isDisabled: isOptionDisabled(key)
  }));
};

export const getStatus = status =>
  status === APPOINTMENT_STATUSES.expired
    ? PAST_APPOINTMENT_STATUSES[status]
    : status;

export const buildPayload = status => {
  const updatedStatus = getStatus(status);
  return {
    update_status: { status: updatedStatus }
  };
};

export const getInitialStatus = showingStatus =>
  APPOINTMENT_STATUSES[showingStatus] ||
  PAST_APPOINTMENT_STATUSES_MAP[showingStatus] ||
  APPOINTMENT_STATUSES.approved;
