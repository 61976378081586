import React from "react";
import css from "./Buyer.sass";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

export default function Buyer({ name, onRemoveClick }) {
  return (
    <div className={css.buyer}>
      <p className={css.name}>{name}</p>
      <CloseLineIcon className={css.closeIcon} onClick={onRemoveClick} />
    </div>
  );
}
