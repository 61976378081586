import { useState, useEffect } from "react";
import { DirectUpload } from "@rails/activestorage";
import { isEmpty } from "lodash";
import { getFileNameWithoutExtension } from "~brokerage/components/shared/FileUpload/utils";
import { getDirectUploadUrl } from "./utils";
import { TIMESTAMP_REGEX } from "./constants";

const useFileUpload = ({ multiple, uploadSource, addTimeStamp = false }) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadError, setUploadError] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const directUploadUrl = getDirectUploadUrl(uploadSource, addTimeStamp);

  useEffect(() => {
    const uploadCompleted = Object.values(uploadProgress).every(
      progress => progress === 100
    );

    if (uploadCompleted) {
      setIsUploading(false);
    }
  }, [uploadProgress]);

  const onFilesAccepted = async acceptedFiles => {
    if (!Array.isArray(acceptedFiles)) {
      acceptedFiles = Array.from(acceptedFiles);
    }

    if (isEmpty(acceptedFiles)) return;
    setIsUploading(true);

    const newFiles = await new Promise(resolve => {
      const files = [];

      acceptedFiles?.forEach(file => {
        const fileId = getFileNameWithoutExtension(file);
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [fileId]: 0
        }));

        const upload = new DirectUpload(file, directUploadUrl, {
          directUploadWillStoreFileWithXHR: xhr => {
            xhr.upload.addEventListener("progress", event => {
              const percentComplete = Math.round(
                (event.loaded / event.total) * 100
              );
              setUploadProgress(prevProgress => ({
                ...prevProgress,
                [fileId]: percentComplete
              }));
            });
            xhr.upload.addEventListener("load", () => {
              setUploadProgress(prevProgress => ({
                ...prevProgress,
                [fileId]: 100
              }));
            });
          }
        });

        upload.create((error, blob) => {
          if (error) {
            console.log({ error });
            return setUploadError(error);
          }

          files.push({
            url: blob.blob_url,
            name: blob.filename,
            signedId: blob.signed_id,
            contentType: blob.content_type
          });

          const isUploadCompleted = multiple
            ? acceptedFiles.length === files.length
            : files.length === 1;

          if (isUploadCompleted) {
            return resolve(files);
          }

          return "";
        });
      });

      return files;
    });

    setNewFiles(newFiles);
  };

  const removeFile = fileName => {
    if (isUploading) return;

    setNewFiles(prevFiles =>
      prevFiles.filter(
        file => file.name.replace(TIMESTAMP_REGEX, "") != fileName
      )
    );

    setUploadProgress(prevProgress => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileName.split(".")[0]];
      return newProgress;
    });
  };

  const resetFiles = () => setNewFiles([]);

  return {
    files: newFiles,
    uploadProgress: uploadProgress,
    uploadError: uploadError,
    setNewFiles: setNewFiles,
    onFilesAccepted: onFilesAccepted,
    removeFile: removeFile,
    isUploading: isUploading,
    resetFiles: resetFiles
  };
};

export default useFileUpload;
