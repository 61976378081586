import css from './SideControl.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

export default class SideControl extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func
  }

  componentDidMount() {
    this.$el = $(ReactDOM.findDOMNode(this))
    this.$document = $(document)

    this.$el.on('mousedown.SideControl', event => {
      event.preventDefault()
      this.handleDrag(event)
      this.$document.on('mousemove.SideControl', this.handleDrag)
      this.$document.on('mouseup.SideControl', this.handleDragStop)
    })
  }

  handleDrag = event => {
    this.props.onChange(event.pageY)
  }

  handleDragStop = event => {
    this.$document.off('mousemove.SideControl mouseup.SideControl')
  }

  render() {
    return (
      <div className={css.base}></div>
    )
  }
}
