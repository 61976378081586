import React, { useState } from "react";
import css from "./CustomDatePicker.sass";
import { CalendarDropdown } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";

const DAY_IN_MILLI_SECS = 24 * 60 * 60 * 1000;

export default function CustomDatePicker({ date, length, onApply }) {
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(
    new Date(date.getTime() + length * DAY_IN_MILLI_SECS)
  );
  const minStartDate = new Date(Date.now() - 180 * DAY_IN_MILLI_SECS); //setting minimum selectable 'from' date to 6 months ago

  const updateStartDate = newDate => {
    const updatedDate = new Date(newDate);
    setStartDate(updatedDate);
    if (updatedDate > endDate)
      setEndDate(new Date(updatedDate.getTime() + DAY_IN_MILLI_SECS));
  };

  return (
    <div className={css.datePickerWrapper}>
      <div className={css.pickerRow}>
        <div className={css.dropDownContainer}>
          <p className={css.label}>From</p>
          <CalendarDropdown
            date={startDate.toUTCString()}
            opened={false}
            minDate={minStartDate}
            onChange={updateStartDate}
          />
        </div>
        <div className={css.dropDownContainer}>
          <p className={css.label}>To</p>
          <CalendarDropdown
            date={endDate.toUTCString()}
            opened={false}
            onChange={newDate => setEndDate(new Date(newDate))}
            minDate={startDate}
            calendarAlign="right"
          />
        </div>
      </div>
      <div className={css.buttonWrapper}>
        <Button variant="primary" onClick={() => onApply(startDate, endDate)}>
          Apply
        </Button>
      </div>
    </div>
  );
}
