import { REQUIRE_APPROVAL, INSTANT_PENDING, INSTANT_APPROVED } from "~brokerage/components/shared/EditShowingInstructions/constants";

export const buildInstructionsPayload = ({ mlsBuyerRequiredEnabled, defaultShowingSetting }) => {
  const {
    accompanyAgent,
    accompanySeller,
    callbackRequest,
    requestHandling,
    message,
    initialMessage,
    allowShowingOverlap,
    maxAppointmentDuration,
    maxOverlappingShowingsCount,
    buyerNameRequired,
    access,
    combo,
    brand
  } = defaultShowingSetting;

  return {
    listing_agent_will_accompany: accompanyAgent,
    tenant_will_accompany: accompanySeller,
    request_a_call: callbackRequest,
    status: requestHandling === REQUIRE_APPROVAL ? INSTANT_PENDING : INSTANT_APPROVED,
    message,
    initial_message: initialMessage,
    allow_showing_overlap: allowShowingOverlap,
    max_appointment_duration: maxAppointmentDuration,
    max_overlapping_showings_count: maxOverlappingShowingsCount,
    lockbox_code: combo,
    lockbox_kind: access,
    lockbox_brand: brand,
    ...(mlsBuyerRequiredEnabled
      ? { buyer_name_required: buyerNameRequired }
      : {}),
    default_advance_notice_attributes: buildDefaultAdvanceNoticePayload(defaultShowingSetting)
  };
};

const buildDefaultAdvanceNoticePayload = (defaultShowingSetting) => {
  const {
    advanceNoticeDuration,
    advanceNoticeDisableSameDayRequests,
    advanceNoticeId
  } = defaultShowingSetting;

  let attributes = {
    duration: advanceNoticeDuration,
    disable_same_day_requests: advanceNoticeDisableSameDayRequests
  }
  if(advanceNoticeId) attributes.id = advanceNoticeId;
  return attributes;
}
