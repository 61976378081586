import React, { Component } from "react";
import PropTypes from 'prop-types'
import { SELLER_TENANT_INVOLVEMENT_SHOWING_INVOLVEMENTS } from "~brokerage/constants/sellerTenantInvolvement";
import Icon from "~brokerage/components/shared/Icon";
import css from "./index.sass";

export default class SellerTenantInvolvement extends React.PureComponent {
  static propTypes = {
    sellerTenantInvolvement: PropTypes.array
  }

  renderSellerShowingInvolvement(person) {
    const showingInvolvement = SELLER_TENANT_INVOLVEMENT_SHOWING_INVOLVEMENTS[person.showingInvolvement]
    const listKey = `showing-involvement-${person.uuid}`

    return (
      <li key={listKey} className={css.li}>
        <i className={css.liIcon}><Icon name="notify" /></i>
        {person.firstName} {person.lastName} - {showingInvolvement}
      </li>
    )
  }

  render() {
    const { sellerTenantInvolvement } = this.props

    const showingInvolvement = sellerTenantInvolvement.map(p => this.renderSellerShowingInvolvement(p))

    return (
      <ul className={css.ul}>
        {showingInvolvement}
      </ul>
    )
  }
}
