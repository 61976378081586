import {
  SYSTEM_SETTINGS_REQUEST,
  SYSTEM_SETTINGS_SUCCESS,
  SYSTEM_SETTINGS_FAILURE,
  SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_ADD_MEMBER,
  SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_MEMBER,
  SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_TEAM_INSTRUCTIONS,
  SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_REMOVE_MEMBER,
  SYSTEM_SETTINGS_UPDATE_ACCEPT_SHOWING_REQUEST,
  SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_CLEAR_MEMBER_DIFF,
  SYSTEM_SETTINGS_UPDATE_DEFAULT_SYSTEM_SETTINGS
} from "~brokerage/constants/actionTypes";
import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";

const defaultState = {
  isFetching: false,
  systemPreference: {},
  diff: {
    create: [],
    destroy: [],
    update: []
  }
};

function addMember(member, entities, diff) {
  let newEntities, newDiff;

  newEntities = [...entities, member];
  newDiff = { ...diff, create: [...diff.create, member] };
  return { officeInvolvementTeamMembers: newEntities, diff: newDiff };
}

function updateMember(member, entities, diff) {
  let newEntities, newCreate, newUpdate;

  const memberInEntitiesIndex = findIndex(
    entities,
    e => e.agentId === member.agentId
  );
  if (
    memberInEntitiesIndex !== -1 &&
    !isEqual(entities[memberInEntitiesIndex], member)
  ) {
    newEntities = [
      ...entities.slice(0, memberInEntitiesIndex),
      member,
      ...entities.slice(memberInEntitiesIndex + 1)
    ];
  } else {
    newEntities = entities;
  }

  const memberInCreateDiffIndex = findIndex(
    diff.create,
    e => e.agentId === member.agentId
  );
  if (
    memberInCreateDiffIndex !== -1 &&
    !isEqual(diff.create[memberInCreateDiffIndex], member)
  ) {
    newCreate = [
      ...diff.create.slice(0, memberInCreateDiffIndex),
      member,
      ...diff.create.slice(memberInCreateDiffIndex + 1)
    ];
  } else {
    newCreate = diff.create;
  }

  const memberInUpdateDiffIndex = findIndex(
    diff.update,
    e => e.agentId === member.agentId
  );
  if (memberInUpdateDiffIndex === -1 && memberInCreateDiffIndex === -1) {
    newUpdate = [...diff.update, member];
  } else if (
    memberInUpdateDiffIndex !== -1 &&
    !isEqual(diff.update[memberInUpdateDiffIndex], member)
  ) {
    newUpdate = [
      ...diff.update.slice(0, memberInUpdateDiffIndex),
      member,
      ...diff.update.slice(memberInUpdateDiffIndex + 1)
    ];
  } else {
    newUpdate = diff.update;
  }

  return {
    officeInvolvementTeamMembers: newEntities,
    diff: { ...diff, create: newCreate, update: newUpdate }
  };
}

function removeMember(member, entities, diff) {
  let newEntities, newDiff;

  let memberInCreateDiffIndex = findIndex(diff.create, e => isEqual(e, member));

  if (memberInCreateDiffIndex === -1) {
    newDiff = { ...diff, destroy: [...diff.destroy, member] };
  } else {
    newDiff = {
      ...diff,
      create: [
        ...diff.create.slice(0, memberInCreateDiffIndex),
        ...diff.create.slice(memberInCreateDiffIndex + 1)
      ]
    };
  }

  newEntities = entities.filter(e => !isEqual(e, member));

  return { officeInvolvementTeamMembers: newEntities, diff: newDiff };
}

export default function systemPreferences(state = defaultState, action) {
  switch (action.type) {
    case SYSTEM_SETTINGS_REQUEST:
      return { ...state, isFetching: true };
    case SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        systemPreference: action.data.systemPreference,
        defaultSystemPreference: action.data.systemPreference,
        isFetching: false
      };
    case SYSTEM_SETTINGS_FAILURE:
      return { ...state, errors: action.errors };
    case SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_ADD_MEMBER: {
      const updated = addMember(
        action.member,
        state.systemPreference.officeInvolvementTeamMembers,
        state.diff
      );

      return {
        ...state,
        systemPreference: {
          ...state.systemPreference,
          officeInvolvementTeamMembers: updated.officeInvolvementTeamMembers
        },
        diff: updated.diff
      };
    }
    case SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_REMOVE_MEMBER: {
      const updated = removeMember(
        action.member,
        state.systemPreference.officeInvolvementTeamMembers,
        state.diff
      );

      return {
        ...state,
        systemPreference: {
          ...state.systemPreference,
          officeInvolvementTeamMembers: updated.officeInvolvementTeamMembers
        },
        diff: updated.diff
      };
    }
    case SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_MEMBER: {
      const updated = updateMember(
        action.member,
        state.systemPreference.officeInvolvementTeamMembers,
        state.diff
      );

      return {
        ...state,
        systemPreference: {
          ...state.systemPreference,
          officeInvolvementTeamMembers: updated.officeInvolvementTeamMembers
        },
        diff: updated.diff
      };
    }
    case SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_TEAM_INSTRUCTIONS: {
      return {
        ...state,
        systemPreference: {
          ...state.systemPreference,
          teamInstructions: action.teamInstructions
        }
      };
    }
    case SYSTEM_SETTINGS_UPDATE_ACCEPT_SHOWING_REQUEST:
      return {
        ...state,
        systemPreference: {
          ...state.systemPreference,
          acceptShowingRequest: action.acceptShowingRequest
        }
      };
    case SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_CLEAR_MEMBER_DIFF:
      return { ...state, diff: defaultState.diff };
    case SYSTEM_SETTINGS_UPDATE_DEFAULT_SYSTEM_SETTINGS:
      return {
        ...state,
        systemPreference: action.defaultSystemSettings
      };
    default:
      return state;
  }
}
