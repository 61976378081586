import React, { useEffect, useState } from "react";
import css from "./Reorder.sass";
import Timeline from "~brokerage/components/shared/Timeline";
import moment from "moment";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import { STATUS_DECLINED } from "~brokerage/constants/showings/statuses";
import SubHeading from "./SubHeading";

const Reorder = ({ selected, time }) => {
  const [staticAppointments, setStaticAppointments] = useState([]);
  const [showListingRestrictions, setShowListingRestrictions] = useState(true);

  useEffect(() => {
    fetchTheDay(time);
  }, []);

  const fetchTheDay = async () => {
    try {
      const date = time.format();
      const { data } = await callApi(
        "showings",
        {
          date,
          limit: 100
        },
        {},
        "get"
      );
      let staticShowings = [];
      data.showings.forEach(
        s => s.status !== STATUS_DECLINED && staticShowings.push(s)
      );
      setStaticAppointments(staticShowings);
    } catch (error) {
      toast.error("Failed to get route appointments.");
    }
  };

  const duration = 30;
  const activeAppointments = selected.map((l, index) => ({
    id: l.id,
    position: 1,
    listingKey: l.id,
    requestedTimeRaw: moment(time)
      .add(duration * index, "minutes")
      .format(),
    address: l.address,
    photoUrl: l.photoUrl,
    duration,
    coordinates: {
      lat: l.latitude,
      long: l.longitude
    }
  }));

  return (
    <div className={css.base}>
      <SubHeading
        setShowListingRestrictions={setShowListingRestrictions}
        showListingRestrictions={showListingRestrictions}
      />
      <Timeline
        initiallyActiveListing={selected[0].id}
        showRestrictions={showListingRestrictions}
        staticAppointments={staticAppointments}
        activeAppointments={activeAppointments}
        updateForTravelTime={true}
      />
    </div>
  );
};

export default Reorder;
