import React from "react";
import PropTypes from "prop-types";
import css from "./NoRoutesAvailablePage.sass";

const NoRoutesAvailablePage = ({ message, past }) => {
  const messageDisplay = message || "No Upcoming Showing Routes Available";

  return (
    <div className={css.container}>
      <div className={css.message}>
        <p>{messageDisplay}</p>
        {!past && (
          <div className={css.directions}>
            <p className={css.title}>How to Make a Route?</p>
            <div className={css.steps}>
              <div className={css.step}>
                <div className={css.number}>1</div>
                Select multiple listings
              </div>
              <div className={css.step}>
                <div className={css.number}>2</div>
                Add a name and date to the route
              </div>
              <div className={css.step}>
                <div className={css.number}>3</div>
                Set the order to your liking and click "Request Appointments"
              </div>
            </div>
            <p>
              That's it. Appointment requests go out at once to all the listings
              on your route.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

NoRoutesAvailablePage.propTypes = {
  message: PropTypes.string
};

export default NoRoutesAvailablePage;
