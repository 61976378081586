import { combineReducers } from "redux";

import {
  ROUTES_REQUEST,
  ROUTES_SUCCESS,
  ROUTES_FAILURE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  entities: []
};

function routes(state = defaultState, action) {
  switch (action.type) {
    case ROUTES_REQUEST:
      return { ...state, isFetching: true };
    case ROUTES_SUCCESS: {
      return {
        ...state,
        entities: action.data.data.routes,
        isFetching: false
      };
    }
    case ROUTES_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

export default combineReducers({
  routes
});
