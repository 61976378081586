import css from "./MessagesHeader.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Categories from "./Categories";
import NewMessageButton from "./NewMessageButton";
import { fetchUnreadMessagesCount } from "~brokerage/actions/messages";
import SearchBar from "./SearchBar";

class MessagesHeader extends React.PureComponent {
  static contextTypes = {
    location: PropTypes.object
  };

  static propTypes = {
    isCountsFetching: PropTypes.bool,
    dispatch: PropTypes.func,
    counts: PropTypes.object
  };

  componentWillMount() {
    if (!this.props.isCountsFetching)
      this.props.dispatch(fetchUnreadMessagesCount());
  }

  render() {
    return (
      <div className={css.layout}>
        <Categories
          location={this.context.location}
          counts={this.props.counts}
        />
        <SearchBar
          location={this.context.location}
          history={this.props.history}
        />
        <NewMessageButton location={this.context.location} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { counts, isCountsFetching } = state.messages.list.messages;

  return {
    isCountsFetching,
    counts
  };
}
export default connect(mapStateToProps)(MessagesHeader);
