import React from 'react'
import css from './InputLabel.sass'

const InputLabel = ({ children, className, placeholder, required = false, ...rest }) => (
  <div className={`${css.InputLabel} ${required ? css.required : ""} ${className}`} {...rest}>
    {
      children !== null ? <>{children}</> : <>{placeholder}</>
    }
  </div>
)

export default InputLabel;
