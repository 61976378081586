import React, { useState } from "react";
import { Label, Controls } from "~brokerage/components/shared/Form";
import Restrictions from "./Restrictions";
import css from "./index.sass";

const DefaultTimeDateRestrictions = () => {
  const [isAdding, setIsAdding] = useState(false);

  const enableAddMode = () => setIsAdding(true);

  const disableAddMode = () => setIsAdding(false);

  return (
    <div className={css.restrictionsControls}>
      <span className={css.timeDateDescription}>
        Set specific time and/or date restrictions for when your listings cannot be shown.
        These default settings will automatically be applied to new listings when you are the listing agent.
        You can change this at any time or modify individual listings in MY LISTINGS.
      </span>
      <Controls>
        <Restrictions
          isAdding={isAdding}
          onEnableAddMode={enableAddMode}
          onDisableAddMode={disableAddMode}
        />
      </Controls>
    </div>
  );
};
export default DefaultTimeDateRestrictions;
