import React, { useState, useEffect } from "react";
import { callApi } from "~brokerage/middlewares/api";

import ModalContents from "./ModalContents";
import { toast } from "react-toastify";

const defaultDetails = {
  status: null,
  lockboxKind: null,
  lockboxCode: null,
  lockboxBrand: null,
  message: null,
  accompanyAgent: null,
  accompanySeller: null,
  requestACall: null
};

const AppointmentApprove = ({
  showingId,
  closeModal,
  refreshCallback,
  mode,
  getInstructions,
  altPush,
  seller,
  isInstructionsEditable
}) => {
  const [loading, setLoading] = useState(true);
  const [originalDetails, setOriginalDetails] = useState(defaultDetails);
  const [details, setDetails] = useState(defaultDetails);

  const toastMessages = {
    pending: "Updating Appointment",
    success: mode === "edit" ? "Appointment Updated" : "Appointment Approved",
    error: "Sorry, Something went wrong"
  };

  useEffect(() => {
    getInstructions().then(response => {
      setOriginalDetails(response.data);
      setDetails(response.data);
      setLoading(false);
    });
  }, []);

  const pushData = {
    status: "approved",
    showing_response: {
      lockbox_code: details.lockboxCode || "",
      lockbox_kind: details.lockboxKind || "",
      lockbox_brand: details.lockboxBrand || "",
      listing_agent_will_accompany: details.accompanyAgent || false,
      tenant_will_accompany: details.accompanySeller || false,
      request_a_call: details.requestACall || false,
      message: details.message || ""
    }
  };

  const pushDetails = () => {
    setLoading(true);
    let req;
    if (altPush !== null) {
      req = altPush({ showingId, pushData });
    } else {
      if (mode === "edit") {
        req = callApi(
          `showings/${showingId}/showing_instructions?role=la`,
          {},
          { instructions: { ...pushData.showing_response } },
          "patch"
        );
      } else {
        req = callApi(`showings/${showingId}`, {}, pushData, "put");
      }
    }

    toast
      .promise(req, toastMessages)
      .then(() => {
        refreshCallback();
      }) //onSuccess
      .catch() //onFailure
      .then(() => {
        setLoading(false);
        closeModal(true);
      }); //onComplete
  };

  const changeDetails = update_params => {
    setDetails({ ...details, ...update_params });
  };

  const resetDetails = () => {
    setDetails(originalDetails);
  };

  return (
    <ModalContents
      loading={loading}
      details={details}
      changeDetails={changeDetails}
      pushDetails={pushDetails}
      resetDetails={resetDetails}
      closeModal={() => closeModal(false)}
      mode={mode}
      seller={seller}
      isInstructionsEditable={isInstructionsEditable}
    />
  );
};

export default AppointmentApprove;
