import React from "react";

import css from "./ListingPicture.sass";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil.jsx";

const ListingPicture = ({ photoDetails }) => {
  if (photoDetails) {
    const { photo_url: photoUrl, listing_status: listingStatus } = photoDetails;
    return (
      <div className={css.Picture}>
        <div className={css.listingStatus}>{listingStatus}</div>
        <PropertyThumbnail image={photoUrl} className={css.pictureContent} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default ListingPicture;
