import css from './show.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { SHOWING_CONVERSATION_TYPE } from '~brokerage/constants/messages'
import ShowingScreen from '~brokerage/components/shared/ShowingScreen'
import ConversationScreen from '~brokerage/components/shared/ConversationScreen'

export default class Show extends React.PureComponent {
  static propTypes = {
    params: PropTypes.object
  }

  get isShowing() {
    return this.props.params.conversationType === SHOWING_CONVERSATION_TYPE
  }

  render() {
    const { conversationType: type, id } = this.props.params
    const { showOptionsPanel } = this.props;

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
          transitionName={{
            enter: css.enter,
            enterActive: css.enterActive,
            leave: css.leave,
            leaveActive: css.leaveActive
          }}
        >
          {this.isShowing ? <ShowingScreen showingId={id} showOptionsPanel={showOptionsPanel}/> : <ConversationScreen type={type} id={id} showOptionsPanel={showOptionsPanel} />}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}
