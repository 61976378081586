import css from './Toggle.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CustomPropTypes from '~brokerage/libs/CustomPropTypes'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Icon from '~brokerage/components/shared/Icon'
export default class DropdownToggle extends Component {
  static propTypes = {
    variant: PropTypes.string,
    modifier: CustomPropTypes.modifier,
    children: PropTypes.node
  }

  render() {
    const { variant, children, modifier, ...rest } = this.props

    return (
      <div
        className={composeClassName(css, variant, modifier)}
        {...rest}
      >
        {children}
        <i className={css.iconDropdown}><Icon name="dropdown"/></i>
      </div>
    )
  }
}
