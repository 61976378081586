import css from './Modal.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

const Modal = ({ modifier, children }) => {
  return (
    <div className={composeClassName(css, 'base', modifier)}>
      {children}
    </div>
  )
}

Modal.propTypes = {
  modifier: PropTypes.string,
  children: PropTypes.node
}
export default Modal
