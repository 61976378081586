import {
  LISTING_STATUSES_REQUEST,
  LISTING_STATUSES_SUCCESS,
  LISTING_STATUSES_FAILURE,
  LISTING_STATUSES_SELECT
} from "~brokerage/constants/actionTypes";

const activeStatus = {
  id: "active",
  name: "Active",
  filterParams: { property_statuses: "Active" }
};
const initialStatusesState = {
  entities: [activeStatus],
  current: activeStatus,
  isFetching: false
};
function statuses(state = initialStatusesState, action) {
  switch (action.type) {
    case LISTING_STATUSES_REQUEST:
      return { ...state, isFetching: true };
    case LISTING_STATUSES_SUCCESS:
      return { ...state, entities: action.data.statuses, isFetching: false };
    case LISTING_STATUSES_FAILURE:
      return { ...state, isFetching: false }; // TODO error handling
    case LISTING_STATUSES_SELECT:
      return { ...state, current: action.nextStatus };
    default:
      return state;
  }
}

export default statuses;
