import React from "react";
import appIcon from "~brokerage/assets/images/app-logo-mark.svg";
import css from "./SplashScreen.sass";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";

const SplashScreen = () => {
  return (
    <div className={css.logoWrapper}>
      <div className={css.logo}>
        <img src={appIcon} height="100" width="80" />
        <div className={css.linkWrapper}>
          <a
            href="https://www.alignedshowings.net/"
            target="_blank"
            className={css.link}
          >
            Learn more about Aligned Showings
          </a>
          <ArrowRightSLineIcon size={18} color="#fff" />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
