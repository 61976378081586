import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { REQUIRED_IS_EMPTY, EMAIL_INVALID, TEL_INVALID } from '~brokerage/constants/validation'
export default class ErrorTag extends Component {
  static propTypes = {
    type: PropTypes.string,
    descriptions: PropTypes.object
  }

  render() {
    const mapping = {
      [REQUIRED_IS_EMPTY]: 'This field is required',
      [EMAIL_INVALID]: 'Email is invalid',
      [TEL_INVALID]: 'Phone is invalid',
      ...(this.props.descriptions || {})
    }

    return (
      <p>{mapping[this.props.type]}</p>
    )
  }
}
