import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import { GOOGLE_MAPS_SEARCH_URL } from "~brokerage/app/constants/apiUrls";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { showingsToDisplay } from "./helper";

export const parseRouteShareMessage = route => {
  const { name, date_display, day } = route;
  const showings = showingsToDisplay(route);
  if (!name) return "";
  let message = `Route name: ${name}\n`;
  message += `Date: ${day}, ${date_display}\n`;
  message += `Showings:\n\n`;
  showings.forEach((showing, index) => {
    const isStop = showing.type === "stop";
    const {
      address,
      coordinates: { lat, long },
      start_time,
      duration,
      listing_id,
      price
    } = showing;
    const timing = getTiming(start_time, duration);
    message += `${index + 1}) ${timing}\n`;
    if (isStop) message += `Non-MLS Stop\n`;
    else {
      message += `MLS#: ${listing_id}\n`;
      message += `Price: ${formatPrice(price)}\n`;
    }
    message += `Address: ${address}\n`;
    const hasValidGeo = validateLatLng(lat, long);
    if (hasValidGeo) message += `Map: ${getMapLink({ lat, long })}\n`;
    else message += "Map: Location unavailable\n";
    message += "\n";
  });
  return message;
};

export const getTiming = (startTime, duration) =>
  `${inUsersTimezone(startTime).format("h:mm a")} - ${inUsersTimezone(startTime)
    .add(duration, "minutes")
    .format("h:mm a")} `;

export const getMapLink = ({ lat, long }) => {
  const latLng = `${lat},${long}`;
  return `${GOOGLE_MAPS_SEARCH_URL}${latLng}`;
};

export const printShowings = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    const popup = window.open("", "_blank", "width=1,height=1");
    popup.addEventListener("afterprint", event => {
      popup.close();
    });
    const content = document.getElementById("print-target");
    popup.document.head.innerHTML = document.head.innerHTML;
    popup.document.body.innerHTML = content.innerHTML;
    setTimeout(() => {
      popup.print();
      setTimeout(() => {
        popup.close();
      });
    });
  } else {
    setTimeout(window.print, 500);
  }
};
