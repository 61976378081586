import { OPTIONS_TYPE } from "./constants";

export const processQuestionsForDisplay = questions => {
  const activeQuestions = [];
  questions.forEach((question, qIndex) => {
    if (!question.remove) {
      const filteredQuestion = { ...question, qIndex };
      const filteredOptions = [];
      filteredQuestion.options.forEach((option, optIndex) => {
        if (!option.remove) filteredOptions.push({ ...option, optIndex });
      });
      filteredQuestion.options = filteredOptions;
      activeQuestions.push(filteredQuestion);
    }
  });
  return activeQuestions;
};

export const convertSurveyToRequestBody = (survey, saveAsNew) => {
  const { name, questions } = survey;
  const sanitizedName = saveAsNew ? name.replace(/\(\d+\)$/, "") : name;
  const processedQuestions = saveAsNew
    ? removeIdsFromQuestions(questions)
    : questions;
  const qAttributes = processedQuestions.map((q, qIndex) => {
    const {
      id: questionId,
      question,
      options,
      answer_type: answerType,
      remove: qRemove
    } = q;
    const qOptAttributes =
      answerType === OPTIONS_TYPE
        ? options.map(opt => {
            const { id: optionId, option, remove: optRemove } = opt;
            const optAttribute = { option_text: option };
            if (optionId) optAttribute.id = optionId;
            if (optRemove) optAttribute._destroy = true;
            return optAttribute;
          })
        : [];
    const qAttribute = { question, position: qIndex, answer_type: answerType };
    if (questionId) qAttribute.id = questionId;
    if (qRemove) qAttribute._destroy = true;
    else qAttribute.question_options_attributes = qOptAttributes;
    return qAttribute;
  });
  return {
    name: sanitizedName,
    questions_attributes: qAttributes
  };
};

const removeIdsFromQuestions = questions => {
  const updatedQuestions = questions.map(
    ({ question, remove, options, answer_type }) => ({
      question,
      remove,
      answer_type,
      options: options.map(({ option, remove }) => ({ option, remove }))
    })
  );
  return updatedQuestions;
};

export const isSurveyIncomplete = survey => {
  if (!survey) return "Loading survey!";
  const { name, questions } = survey;
  if (!name) return "Survey name missing.";
  const incomplete = questions
    .filter(qn => !qn.remove)
    .some(
      q =>
        !q.question ||
        (q.answer_type === OPTIONS_TYPE &&
          q.options.some(opt => !opt.option && !opt.remove))
    );
  return incomplete && "Survey contains empty fields.";
};

const emptyQuestion = {
  question: "",
  answer_type: OPTIONS_TYPE,
  options: [{ option: "" }, { option: "" }]
};
export const emptySurvey = {
  name: "",
  newSurvey: true,
  questions: [emptyQuestion]
};
