import css from './NewShowing.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import { compareProps } from '~brokerage/libs/helpers/CompareHelper'
import { datetimeToPixels } from '~brokerage/libs/helpers/TimeHelper'
import { TIME_PASSED } from '~brokerage/constants/showings/newShowingSections'
import { DEFAULT_SHOWING_DURATION } from '~brokerage/constants/showings'
import Showing from './Showing'
import DragTip from './DragTip'
class NewShowing extends Component {
  static propTypes = {
    conflicts: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.string]),
    overlappingShowings: PropTypes.array,
    time: PropTypes.object,
    duration: PropTypes.number,
    containerOffsetTop: PropTypes.number,
    containerScrollTop: PropTypes.number,
    onChange: PropTypes.func
  }

  componentDidMount() {
    this.$el = $(ReactDOM.findDOMNode(this))
    this.$document = $(document)

    this.$el.on('mousedown.NewShowing', event => {
      event.preventDefault()
      this.pointerOffset = event.pageY - this.$el.offset().top
      this.$document.on('mousemove.NewShowing', this.handleDrag)
      this.$document.on('mouseup.NewShowing', this.handleDragStop)
    })
  }

  shouldComponentUpdate(nextProps) {
    return compareProps(this, nextProps, ['time', 'duration', 'conflicts'])
  }

  componentWillUnmount() {
    this.$el.off('mousedown.NewShowing')
    this.$document.off('mousemove.NewShowing mouseup.NewShowing')
  }

  handleDrag = event => {
    this.props.onChange(event.pageY - this.pointerOffset)
  }

  handleDragStop = event => {
    this.$document.off('mousemove.NewShowing mouseup.NewShowing')
  }

  hasOverlappingShowings() {
    const { overlappingShowings } = this.props
    return overlappingShowings && overlappingShowings.length > 0
  }

  showingPassed() {
    return this.props.conflicts === TIME_PASSED
  }

  conflictName() {
    return this.showingPassed() ? TIME_PASSED : 'Conflicting showing'
  }

  render() {
    const hasConflict = Boolean(this.props.conflicts)
    const variant = hasConflict || this.hasOverlappingShowings() ? 'conflict' : 'base'
    const modifier = this.showingPassed() ? 'passed' : ''
    const top = datetimeToPixels(this.props.time)
    const showing = {
      listingAgent: hasConflict ? this.conflictName() : 'New Showing',
      duration: this.props.duration,
      status: hasConflict ? 'conflict' : 'new'
    }

    return (
      <div className={composeClassName(css, variant, modifier)} style={{ top }}>
        <DragTip/>
        <Showing showing={showing}/>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { duration: showingDuration, time, conflicts, overlappingShowings } = state.showings.new
  const { defaultShowingDuration: userPreferencesDuration } = state.myAccount.preferences
  const duration = showingDuration || userPreferencesDuration || DEFAULT_SHOWING_DURATION

  return {
    duration,
    time,
    conflicts,
    overlappingShowings
  }
}
export default connect(mapStateToProps)(NewShowing)
