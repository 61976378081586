import css from './Links.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Links extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { variant = 'base', children } = this.props

    return (
      <div className={css[variant]}>
        <ul className={css.ul}>
          {React.Children.map(children, (child, childIndex) =>
            <li className={css.li} key={childIndex}>
              {child}
            </li>
          )}
        </ul>
      </div>
    )
  }
}
