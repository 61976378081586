import { CALL_API } from '~brokerage/middlewares/api'

export function fetchUnreadNotificationsCount(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'my_account/unread_notifications'
    }
  }
}

export function fetchPreferences(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'my_account/preferences'
    }
  }
}

export function hideWelcomeScreen(types) {
  return {
    [CALL_API]: {
      types,
      method: 'PUT',
      endpoint: 'my_account/preferences',
      data: {
        welcome_screen_hidden: true
      }
    }
  }
}

export function updatePreferences(preferences, type) {
  return { preferences, type }
}

export function updateUnreadNotifications(count, type) {
  return { count, type }
}

export function updatePremiumBetaSubscription(types, enabled) {
  return {
    [CALL_API]: {
      types,
      method: 'PUT',
      endpoint: 'my_account/preferences',
      data: {
        premium_beta_enabled: enabled
      }
    }
  }
}
