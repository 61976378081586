import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import ReportsHeader from "~brokerage/app/components/reports/ReportsHeader";
import Listings from "~brokerage/app/components/reports/Listings";
import Content from "~brokerage/components/shared/Content";
import PriceAnalysis from "~brokerage/components/reports/PriceAnalysis";

const Reports = ({ match }) => {
  const [printEnabled, enablePrint] = useState(false);
  return (
    <div>
      <ReportsHeader printEnabled={printEnabled} />
      <Content header="single">
        <Switch>
          <Route path={`${match.path}/price_analysis`} exact>
            <PriceAnalysis enablePrint={enablePrint} />
          </Route>
          <Route path={`${match.path}`} exact>
            <Listings enablePrint={enablePrint} />
          </Route>
        </Switch>
      </Content>
    </div>
  );
};

export default Reports;
