import {
  setWithExpiry,
  MILLISECONDS_IN_DAY,
  getWithExpiry
} from "~brokerage/libs/helpers/localStorageHelpers";
import {} from "../../../../libs/helpers/localStorageHelpers";

export const isAndroid = () => window.navigator.userAgent.match(/Android/i);

export const isIos = () => window.navigator.userAgent.match(/iPhone|iPad|ios/i);

export const isMobileBrowser = () => isIos() || isAndroid();

export const disableBanner = () => {
  setWithExpiry("smartBannerDisabled", true, 2 * MILLISECONDS_IN_DAY); //set expiry for 2 days
};

export const isBannerDisabled = () => !!getWithExpiry("smartBannerDisabled");
