import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SEARCH_AUTOSUBMIT_TIMEOUT } from "~brokerage/constants/listings/search";
import { Form, TextField } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import AgentFilterDropdown from "./AgentFilterDropdown";
import css from "./index.sass";

class ListingsHeader extends PureComponent {
  static contextTypes = {
    currentUser: PropTypes.object
  };

  static propTypes = {
    currentView: PropTypes.number,
    currentListing: PropTypes.object,
    location: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: props.location.query["query"]
    };
  }

  handleViewChange = index => {
    if (this.props.currentView === index) return;

    let url = "/listings";

    if (index === 1) {
      url = `/listings/${this.props.currentListing.id}`;
    }

    this.props.history.push(url);
  };

  handleSearchChange = event => {
    if (this.state.searchUpdateTimeout) {
      clearTimeout(this.state.searchUpdateTimeout);
    }
    this.setState({
      searchUpdateTimeout: setTimeout(
        this.handleSearchAutosubmitProc,
        SEARCH_AUTOSUBMIT_TIMEOUT
      ),
      searchValue: event.target.value
    });
  };

  handleSearchSubmit = (event, values) => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      query: { ...this.props.location.query, ...values }
    });
  };

  handleSearchAutosubmitProc = () => {
    ReactDOM.findDOMNode(this.refs.searchForm).dispatchEvent(
      new Event("submit")
    );
  };

  renderAgentsFilter() {
    const { isShowingCoordinator, hasEnabledShowingCoordinatorInBrokerage } =
      this.context.currentUser;
    if (
      !isShowingCoordinator ||
      (isShowingCoordinator && hasEnabledShowingCoordinatorInBrokerage)
    ) {
      return (
        <div className={css.section}>
          <AgentFilterDropdown location={this.props.location} />
        </div>
      );
    }
  }

  renderSearchForm() {
    const showSearchForm = this.props.location.pathname === "/listings";

    if (showSearchForm) {
      return (
        <Form onSubmit={this.handleSearchSubmit} ref="searchForm">
          <div className={css.search}>
            <TextField
              variant="headerSearch"
              name="query"
              type="search"
              placeholder="Search"
              autoComplete="off"
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
            />
            <Button variant="search" type="submit">
              <Icon name="search" />
            </Button>
          </div>
        </Form>
      );
    }
  }

  render() {
    return (
      <div className={css.base}>
        {this.renderAgentsFilter()}

        {this.renderSearchForm()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { entity: currentListing } = state.listings.single.listing;

  let currentView = -1;
  if (currentListing) {
    currentView = Number(ownProps.location.pathname !== "/listings");
  }

  return {
    currentListing,
    currentView
  };
}
export default withRouter(connect(mapStateToProps)(ListingsHeader));
