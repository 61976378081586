import React from "react";
import { noop } from "lodash";

import css from "./index.sass";
import ActionsDropdown from "~brokerage/app/components/shared/ActionsDropdown";

const ShowingActions = ({
  showingStatus,
  openStatusModal,
  isShowingTeam,
  isListingTeam,
  futureShowing,
  setIsListingAgentPreapproving,
  openDateTimeModal = noop
}) => {
  const isFinalState =
    !futureShowing ||
    showingStatus === "declined" ||
    showingStatus === "cancelled";

  const onListingAgentPreapproving = () => {
    setIsListingAgentPreapproving(true);
    openDateTimeModal();
  };

  if (isFinalState) {
    return <div />; //If past appointment / declined / cancelled
  }

  const handleButtonClick = (e, status) => {
    e.stopPropagation();
    openStatusModal(status);
  };

  if (isListingTeam) {
    if (showingStatus === "approved") {
      return (
        <div className={css.showingActions}>
          {isShowingTeam && (
            <div
              onClick={e => handleButtonClick(e, "cancelled")}
              className={css.cancelButtonHalf}
            >
              Cancel
            </div>
          )}
          <div
            onClick={e => handleButtonClick(e, "declined")}
            className={
              isShowingTeam ? css.declineButtonHalf : css.declineButtonFull
            }
          >
            {isShowingTeam ? "Decline" : "Decline Appointment"}
          </div>
          <ActionsDropdown
            showReschedule
            onPreapprove={onListingAgentPreapproving}
          />
        </div>
      );
    } else {
      return (
        <div className={css.showingActions}>
          <div
            onClick={e => handleButtonClick(e, "approved")}
            className={css.approveButtonHalf}
          >
            Approve
          </div>
          <div
            onClick={e => handleButtonClick(e, "declined")}
            className={css.declineButtonHalf}
          >
            Decline
          </div>
          <ActionsDropdown
            showCancelAppointment={isShowingTeam}
            showReschedule={isListingTeam}
            onCancelAppointment={() => openStatusModal("cancelled")}
            onPreapprove={onListingAgentPreapproving}
          />
        </div>
      );
    }
  }

  if (isShowingTeam) {
    return (
      <div className={css.showingActions}>
        <div
          onClick={e => handleButtonClick(e, "cancelled")}
          className={css.cancelButtonHalf}
        >
          Cancel
        </div>
        <div onClick={openDateTimeModal} className={css.rescheduleButtonHalf}>
          Reschedule
        </div>
      </div>
    );
  }

  return <div />;
};

export default ShowingActions;
