import css from "./details.sass";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchShowingDetails,
  fetchListingTeamMembers,
  fetchSellerTenantInvolvement
} from "~brokerage/actions/listings";
import {
  LOCKBOX_TYPES,
  LOCKBOX_TYPE_NONE,
  LOCKBOX_TYPE_COMBO
} from "~brokerage/constants/lockboxTypes";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Link from "~brokerage/components/shared/Link";
import Loader from "~brokerage/components/shared/Loader";
import {
  SHOWING_INSTRUCTION_FOR_SHOWING_AGENTS_STEP,
  OFFICE_INVOLVEMENT_STEP,
  SELLER_TENANT_INVOLVEMENT_STEP,
  TIME_DATE_RESTRICTION_STEP,
  FEEDBACK_SURVEY_STEP
} from "~brokerage/constants/listings/sections";
import {
  sortRestrictions,
  renderTimeRangesFor,
  renderDates,
  renderDays
} from "~brokerage/libs/helpers/RestrictionsHelper";

const Details = props => {
  useEffect(() => {
    const { listingId } = props.match.params;

    if (!props.isFetching) {
      props.dispatch(fetchShowingDetails(listingId));
      props.dispatch(fetchListingTeamMembers(listingId));
      props.dispatch(fetchSellerTenantInvolvement(listingId));
    }
  }, []);

  const handling = key => {
    const mapping = {
      instant_approved: "Instant Approval",
      instant_declined: "Not accepting showing requests",
      require_approval: "Require Approval"
    };

    return mapping[key];
  };

  const arrangement = (seller, agent) => {
    const result = [];
    seller && result.push("Seller will accompany");
    agent && result.push("Agent will accompany");
    return result.join(", ");
  };

  const RenderNoShowReason = () => {
    const { shown, noShowReason, noShowReasonOptions } = props.showingDetails;
    if (!noShowReasonOptions) return <></>;

    const reason = noShowReasonOptions.find(
      reason => reason.value === noShowReason
    );
    return (
      <>
        {!shown && reason && (
          <li>
            <strong>Decline reason:</strong>
            {reason.label}
          </li>
        )}
      </>
    );
  };

  const RenderArrangement = () => {
    const { accompanySeller, accompanyAgent, shown } = props.showingDetails;

    return (
      <>
        {shown && (accompanyAgent || accompanySeller) && (
          <li>
            <strong>Showing arrangement:</strong>
            {arrangement(accompanySeller, accompanyAgent)}
          </li>
        )}
      </>
    );
  };

  const lockboxLabel = () => {
    const { access, combo } = props.showingDetails;
    if (access === LOCKBOX_TYPE_NONE) return;
    const lockboxType = LOCKBOX_TYPES.find(type => type.value === access);
    if (!lockboxType) return;

    const typeLabel = `${lockboxType.label} Lockbox`;
    if (lockboxType.value === LOCKBOX_TYPE_COMBO) {
      return `${typeLabel}, ${combo}`;
    }
    return typeLabel;
  };

  const RenderShowingAccess = () => {
    const { shown } = props.showingDetails;
    const label = lockboxLabel();

    return (
      <>
        {!shown && label && (
          <li>
            <strong>Showing access:</strong>
            {label}
          </li>
        )}
      </>
    );
  };

  const RenderInstantMessage = () => {
    const { requestHandling, initialMessage } = props.showingDetails;

    return (
      <>
        {requestHandling === "require_approval" && initialMessage && (
          <li>
            <strong>Instant message:</strong>
            {initialMessage}
          </li>
        )}
      </>
    );
  };

  const RenderAdditionalInstructions = () => {
    const { message } = props.showingDetails;
    return (
      <>
        {message && (
          <li>
            <strong>Additional instructions:</strong>
            {message}
          </li>
        )}
      </>
    );
  };

  const RenderTimeDateRestrictions = () => {
    const sortedRestrictions = sortRestrictions(
      props.showingDetails.restrictions
    );

    return (
      <ul className={css.ul}>
        {sortedRestrictions.map(r => (
          <li key={r.id}>
            {[renderPeriod(r), renderTimeRangesFor(r)].join(
              restrictionSeparator(r)
            )}
          </li>
        ))}
      </ul>
    );
  };

  const restrictionSeparator = restriction => {
    return typeof restriction.timeRanges !== "undefined" &&
      restriction.timeRanges.length
      ? " at "
      : " ";
  };

  const renderPeriod = restriction => {
    return restriction.repetitionType === "weekly"
      ? renderDays(restriction)
      : renderDates(restriction);
  };

  const RenderOfficeInvolvement = () => (
    <>
      {props.isFetching == false && (
        <ul className={css.ul}>
          <li>
            <strong>Team</strong>
            {props.teamMembers.map(person => person.name).join(", ")}
          </li>
        </ul>
      )}
    </>
  );

  const sellerTenantInvolvementLabel = key => {
    const mapping = {
      none_involvement: "None",
      observe_mode: "Notifications Only",
      can_approve: "Can Approve Showings and Send Comments",
      can_approve_no_comment: "Can Approve Showings with No Comments"
    };

    return mapping[key];
  };

  const urlForEditStep = step => {
    return `${props.match.url}/edit?step=${step}`;
  };

  const RenderSellerTenantInvolvement = () => {
    const { sellerTenantInvolvement } = props;

    return sellerTenantInvolvement.map((person, personIndex) => (
      <ul className={css.ul} key={personIndex}>
        <li>
          <strong>Seller/Tenant:</strong> {person.firstName} {person.lastName}
        </li>
        <li>
          <strong>Involvement:</strong>{" "}
          {sellerTenantInvolvementLabel(person.showingInvolvement)}
        </li>
      </ul>
    ));
  };

  const RenderFeedbackButton = () => (
    <>
      {props.listing.isOwnedByCurrentUser && (
        <div className={css.button}>
          <Button
            variant="outlineWithWhiteBackground"
            to={`/listings/${props.match.params.listingId}/feedback`}
          >
            View Feedback
          </Button>
        </div>
      )}
    </>
  );

  const RenderShowingsButton = () => (
    <>
      {props.listing.isOwnedByCurrentUser && (
        <div className={css.button}>
          <Button
            variant="outlineWithWhiteBackground"
            to={`/?address=${props.listing.address.split(",")[0]}&return=${
              props.match.url
            }`}
          >
            View Showings
          </Button>
        </div>
      )}
    </>
  );

  const RenderBottomButtons = () => (
    <div className={css.bottomButtons}>
      {/* <RenderFeedbackButton /> <- Commented out due to it pointing to a rails view, will bring it back once complete post MVP*/}
      <RenderShowingsButton />
    </div>
  );

  const RenderFeedbackSurvey = () => {
    const { allowFeedback, useDefaultSurvey } = props.showingDetails;
    return (
      <ul className={css.ul}>
        <li>
          <strong>Allow Feedback?:</strong>
          {allowFeedback ? " Yes" : " No"}
        </li>
        {allowFeedback && (
          <li>
            <strong>Survey:</strong>
            {useDefaultSurvey
              ? " Default MLS feedback survey"
              : " Custom survey"}
          </li>
        )}
      </ul>
    );
  };

  const { match, showingDetails, isFetching, errors } = props;

  return (
    <div className={css.base}>
      <div className={css.header}>
        <h1>Listing Setup</h1>
        <div className={css.buttons}>
          <div className={css.button}>
            <Button
              variant="outlineWithWhiteBackground"
              to={`${match.url}/edit`}
            >
              <div className={css.iconButton}>
                <Icon name="edit" />
              </div>
              Edit Details
            </Button>
          </div>
        </div>
      </div>

      {(() => {
        if (!showingDetails) {
          return <Loader active={isFetching} />;
        }

        if (errors) {
          return (
            <div className={css.well}>{errors && "Something went wrong"}</div>
          );
        }

        return (
          <div className={css.well}>
            <div className={css.column}>
              <div className={css.row}>
                <div className={css.showingInstructionHeading}>
                  <h2>SHOWING INSTRUCTIONS FOR SHOWING AGENT</h2>
                  <span className={css.linkEdit}>
                    <Link
                      to={urlForEditStep(
                        SHOWING_INSTRUCTION_FOR_SHOWING_AGENTS_STEP
                      )}
                    >
                      <div className={css.iconEdit}>
                        <Icon name="edit" />
                      </div>{" "}
                      Edit
                    </Link>
                  </span>
                </div>
                <ul className={css.ul}>
                  <li>
                    <strong>Allow Property to be shown?</strong>{" "}
                    {showingDetails.shown ? "Yes" : "No"}
                  </li>
                  <li>
                    <strong>How are requests handled?</strong>{" "}
                    {handling(showingDetails.requestHandling)}
                  </li>
                  <RenderNoShowReason />
                  <RenderArrangement />
                  <RenderShowingAccess />
                  <RenderInstantMessage />
                  <RenderAdditionalInstructions />
                </ul>
              </div>
              <div className={css.row}>
                <div className={css.heading}>
                  <h2>TIME\DATE RESTRICTIONS</h2>
                  <span className={css.linkEdit}>
                    <Link to={urlForEditStep(TIME_DATE_RESTRICTION_STEP)}>
                      <div className={css.iconEdit}>
                        <Icon name="edit" />
                      </div>{" "}
                      Edit
                    </Link>
                  </span>
                </div>
                <RenderTimeDateRestrictions />
              </div>
            </div>
            <div className={css.column}>
              <div className={css.row}>
                <div className={css.heading}>
                  <h2>OFFICE INVOLVEMENT</h2>
                  <span className={css.linkEdit}>
                    <Link to={urlForEditStep(OFFICE_INVOLVEMENT_STEP)}>
                      <div className={css.iconEdit}>
                        <Icon name="edit" />
                      </div>{" "}
                      Edit
                    </Link>
                  </span>
                </div>
                <RenderOfficeInvolvement />
              </div>
              <div className={css.row}>
                <div className={css.heading}>
                  <h2>SELLER/TENANT INVOLVEMENT</h2>
                  <span className={css.linkEdit}>
                    <Link to={urlForEditStep(SELLER_TENANT_INVOLVEMENT_STEP)}>
                      <div className={css.iconEdit}>
                        <Icon name="edit" />
                      </div>{" "}
                      Edit
                    </Link>
                  </span>
                </div>
                <RenderSellerTenantInvolvement />
              </div>
              <div className={css.row}>
                <div className={css.heading}>
                  <h2>FEEDBACK SURVEY</h2>
                  <span className={css.linkEdit}>
                    <Link to={urlForEditStep(FEEDBACK_SURVEY_STEP)}>
                      <div className={css.iconEdit}>
                        <Icon name="edit" />
                      </div>{" "}
                      Edit
                    </Link>
                  </span>
                </div>
                <RenderFeedbackSurvey />
              </div>
            </div>
          </div>
        );
      })()}
      <RenderBottomButtons />
    </div>
  );
};

Details.propTypes = {
  listing: PropTypes.object,
  showingDetails: PropTypes.object,
  teamMembers: PropTypes.array,
  isFetching: PropTypes.bool,
  errors: PropTypes.bool,
  match: PropTypes.object,
  sellerTenantInvolvement: PropTypes.array,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { entity: listing } = state.listings.single.listing;
  const { entities: teamMembers } = state.listings.single.teamMembers;
  const {
    entity: showingDetails,
    isFetching: showingDetailsIsFetching,
    errors
  } = state.listings.single.showingDetails;
  const {
    entities: sellerTenantInvolvement,
    isFetching: sellerTenantInvolvementIsFetching
  } = state.listings.single.sellerTenantInvolvement;
  const isFetching =
    showingDetailsIsFetching || sellerTenantInvolvementIsFetching;

  return {
    listing,
    sellerTenantInvolvement,
    showingDetails,
    teamMembers,
    isFetching,
    errors
  };
}
export default connect(mapStateToProps)(Details);
