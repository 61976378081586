import { combineReducers } from 'redux'
import * as types from '~brokerage/constants/actionTypes'

function shouldBeFocused(state = false, action) {
  switch (action.type) {
  case types.MESSAGE_FIELD_FOCUS:
    return true
  case types.MESSAGE_FIELD_RECEIVED_FOCUS:
  case types.MESSAGE_FIELD_RESET:
    return false
  default:
    return state
  }
}

function isFocused(state = false, action) {
  switch (action.type) {
  case types.MESSAGE_FIELD_RECEIVED_FOCUS:
    return true
  case types.MESSAGE_FIELD_LOST_FOCUS:
  case types.MESSAGE_FIELD_RESET:
    return false
  default:
    return state
  }
}

function text(state = '', action) {
  switch (action.type) {
  case types.MESSAGE_FIELD_TEXT_CHANGE:
    return action.text
  case types.MESSAGE_FIELD_RESET:
    return ''
  default:
    return state
  }
}

export default combineReducers({
  shouldBeFocused,
  isFocused,
  text
})
