import React, { useRef, useContext } from "react";
import css from "./UserImageRow.sass";
import { useFormContext } from "react-hook-form";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import ProfilePicture from "~brokerage/app/components/settings/components/ProfilePicture.jsx";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import moment from "moment";
import RemoveUserImage from "./RemoveUserImage";

const UserImageRow = ({
  showImagePreview,
  hasPhoto,
  photoUrl,
  userInitials,
  accountCreationDate,
  imageUrlFailed,
  setImageUrlFailed,
  setUserProfile
}) => {
  const { dispatchPageState } = useContext(SettingsContext);
  const inputFileRef = useRef(null);

  const updatePreviewImageFromStreamRef = stream => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      showImagePreview(reader.result);
    });
    reader.readAsDataURL(stream);
  };

  const validateImage = async file => {
    const img = new Image();
    const maximumSize = 10 * 1024 * 1024;
    const minimumDimensions = { width: 70, height: 70 };
    const response = { valid: true, message: "" };
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;

      if (
        width < minimumDimensions.width ||
        height < minimumDimensions.height
      ) {
        response.valid = false;
        response.message = `Image must be at least ${minimumDimensions.height} X ${minimumDimensions.width} px`;
      } else if (file.size > maximumSize) {
        response.valid = false;
        response.message = "Image must be less than 10MB";
      }
    };

    await img.decode();

    return response;
  };

  const onFilechange = async e => {
    const file = e.target.files[0];
    const validation = await validateImage(file);

    if (validation.valid) {
      dispatchPageState({ type: "working" });
      updatePreviewImageFromStreamRef(file);
    } else {
      alert(`File Size Not Accepted: ${validation.message}`);
    }
  };

  const { register } = useFormContext();

  const { ref, ...rest } = register("userPicture", {});

  const photoUrlType = () => {
    if (
      photoUrl.startsWith("data:image/png;base64") ||
      photoUrl.startsWith("data:image/jpeg;base64")
    ) {
      return "uploaded_image";
    } else {
      return "aws_image";
    }
  };

  return (
    <Row>
      <Column className={css.pictureColumn}>
        <ProfilePicture
          hasPhoto={hasPhoto}
          photoUrl={photoUrl}
          userInitials={userInitials}
          imageUrlFailed={imageUrlFailed}
          setImageUrlFailed={setImageUrlFailed}
        />
      </Column>
      <Column className={css.addPhotoColumn}>
        <Row className={css.addPhotoButtonColumn}>
          <label>
            <Button variant="outlineBlue">
              <Icon name="edit" /> Update Picture
            </Button>
            <input
              {...rest}
              className={css.hiddenInput}
              type="file"
              accept=".png, .jpg, .jpeg"
              name="userPicture"
              onChange={onFilechange}
              ref={e => {
                ref(e);
                inputFileRef.current = e;
              }}
            />
          </label>
          {photoUrl && photoUrlType() === "aws_image" && (
            <label className={css.removeUserImage}>
              <RemoveUserImage setUserProfile={setUserProfile} />
            </label>
          )}
        </Row>
        <p className={css.createdDate}>
          This account was created on{" "}
          {moment(accountCreationDate).format("dddd, MMMM D, YYYY [at] h:mmA")}
        </p>
      </Column>
    </Row>
  );
};

export default UserImageRow;
