import css from "./Restrictions.sass";
import React from "react";
import {
  datetimeToPixels,
  minutesToPixels
} from "~brokerage/libs/helpers/TimeHelper";

const Restrictions = ({
  activeShowing,
  showingId = "",
  restrictions = {},
  isListingOwnedByCurrentUserOrOfficeTeam
}) => {
  const renderRestriction = (restriction, index, allowOverlap) => {
    if (showingId === restriction.showingId) {
      return <></>;
    }

    const top = datetimeToPixels(restriction.start);
    const diff =
      (new Date(restriction.end) - new Date(restriction.start)) / 1000 / 60;
    const height = minutesToPixels(diff);
    const text =
      (restriction.showingId && allowOverlap) ||
      isListingOwnedByCurrentUserOrOfficeTeam
        ? "Existing Showing"
        : "Unavailable";

    return (
      <div
        key={`${restriction.start}_${index}`}
        className={css.restriction}
        style={{ top, height }}
      >
        {text}
      </div>
    );
  };

  const activeRestrictions = restrictions[activeShowing]?.unavailability || [];
  const allowOverlap = restrictions[activeShowing]?.allowOverlap || false;

  return (
    <div className={css.base}>
      {activeRestrictions.map((restriction, index) =>
        renderRestriction(restriction, index, allowOverlap)
      )}
    </div>
  );
};

export default Restrictions;
