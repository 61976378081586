/* eslint react/no-danger: 0 */
import css from "./index.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { timeAgo, inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { formatDescription } from "~brokerage/libs/helpers/AttachmentsHelper";
import {
  APPROVED_TYPE,
  PREAPPROVED_TYPE,
  INSTANT_APPROVED_TYPE,
  DECLINED_TYPE,
  INSTANT_DECLINED_TYPE,
  EVENT_TYPE,
  FEEDBACK_RECEIVED_TYPE,
  FEEDBACK_REQUESTED_TYPE,
  INSTANT_PENDING_TYPE,
  NOTE_TYPE,
  REQUEST_A_CALL_TYPE,
  SHOWING_INSTRUCTIONS_TYPE
} from "~brokerage/constants/messages";
import Link from "../../Link";
import Icon from "../../Icon";
import Tooltip from "~brokerage/components/shared/Tooltip";
import EventMessage from "./EventMessage";
import Approved from "./Approved";
import Preapproved from "./Preapproved";
import Bubble from "./Bubble";
import Cancelled from "./Cancelled";
import Declined from "./Declined";
import Inner from "./Inner";
import Instructions from "./Instructions";
import Pending from "./Pending";
import InstantPending from "./InstantPending";
import RequestACall from "./RequestACall";
import TimeChanged from "./TimeChanged";
import Listing from "./Listing";
import CheckDoubleFillIcon from "remixicon-react/CheckDoubleFillIcon";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import he from "he";
import { generateFeedbackText } from "./utils";
import Attachments from "./Attachments";

class Message extends React.PureComponent {
  static propTypes = {
    message: PropTypes.object,
    hasRead: PropTypes.bool,
    firstForDirection: PropTypes.bool,
    lastForDirection: PropTypes.bool,
    isNotesMode: PropTypes.bool,
    showTime: PropTypes.bool,
    searchTerm: PropTypes.string
  };

  messageTemplates = {
    preapproved: Preapproved,
    approved: Approved,
    instant_approved: Approved,
    declined: Declined,
    instant_declined: Declined,
    instructions_changed: Instructions,
    pending: Pending,
    instant_pending: InstantPending,
    request_a_call: RequestACall,
    cancelled: Cancelled,
    time_changed: TimeChanged,
    user: Listing,
    note: Listing
  };

  formatCreatedAt(createdAt) {
    // Example of format: Sunday, March 11, 2018 at 3:37 pm
    return inUsersTimezone(createdAt).format("dddd, MMMM DD, YYYY [at] h:mm a");
  }

  renderSentAt() {
    const { createdAt } = this.props.message;
    const time = timeAgo(createdAt, " ago");
    const timeText = time === "0s ago" ? "just now" : time;

    return (
      <div className={css.tooltip}>
        <Tooltip text={this.formatCreatedAt(createdAt)}>
          <div className={css.createdAt}>Sent {timeText}</div>
        </Tooltip>
      </div>
    );
  }

  shouldRenderMessage() {
    const { messageType, msg, attachments } = this.props.message;
    const messageLessTypes = [
      REQUEST_A_CALL_TYPE,
      INSTANT_PENDING_TYPE,
      SHOWING_INSTRUCTIONS_TYPE,
      APPROVED_TYPE,
      INSTANT_APPROVED_TYPE,
      DECLINED_TYPE,
      INSTANT_DECLINED_TYPE
    ];
    return (
      (msg && !messageLessTypes.includes(messageType)) ||
      (attachments && attachments.length) ||
      messageType == FEEDBACK_RECEIVED_TYPE
    );
  }

  unsafeHTMLWrapper(html) {
    return { __html: html };
  }

  bubbleVariant(message) {
    return message.messageType === NOTE_TYPE ? "yellow" : "";
  }

  messageDirection() {
    const { message } = this.props;

    return message.isFromMe ? "sent" : "received";
  }

  readStatus() {
    const { message } = this.props;
    const readReceipt = message.isFromMe && message.hasRead;
    const checkDoubleFillIconColor = readReceipt ? "#117CC9" : "#6E8593";
    const toolTipText = readReceipt ? "Read" : "Delivered";
    return (
      message.isFromMe && (
        <div className={css.tooltip}>
          <Tooltip text={toolTipText}>
            <div className={css.readReceipt}>
              <CheckDoubleFillIcon color={checkDoubleFillIconColor} size={14} />
            </div>
          </Tooltip>
        </div>
      )
    );
  }

  renderMessageExtra() {
    const { message, lastForDirection, showBuyersInApprovalMsg, buyers } =
      this.props;
    const MessageExtra = this.messageTemplates[message.messageType];
    const instructionsEmpty =
      MessageExtra === Instructions &&
      Object.values(message.instructions).every(instruction => !instruction);
    const listingEmpty = MessageExtra === Listing && !message.listing;
    const messageExtraIsEmpty =
      !MessageExtra || instructionsEmpty || listingEmpty;

    if (messageExtraIsEmpty) {
      return null;
    }

    const bubbleOptions = {
      direction: this.messageDirection(),
      hasCorner: this.props.lastForDirection,
      semitransparent: this.props.isNotesMode
    };

    return (
      <Inner
        displayAvatar={lastForDirection && !this.shouldRenderMessage()}
        photoUrl={message.photoUrl}
        senderName={message.senderAvatarName}
      >
        <MessageExtra
          bubbleOptions={bubbleOptions}
          message={message}
          showBuyersInApprovalMsg={showBuyersInApprovalMsg}
          buyers={buyers}
        />
      </Inner>
    );
  }

  renderSenderName() {
    const { message, firstForDirection } = this.props;

    if (firstForDirection) {
      return (
        <div className={css.name}>
          <Highlighter
            searchWords={[this.props.searchTerm]}
            autoEscape={true}
            textToHighlight={message.senderName}
          />
        </div>
      );
    }
  }

  renderShowingFeedback() {
    const feedbackText = generateFeedbackText(
      this.props.message,
      this.props.showingTeamAgentIds
    );

    return (
      <p
        className={css.messageText}
        dangerouslySetInnerHTML={this.unsafeHTMLWrapper(feedbackText)}
      />
    );
  }

  renderMessageText() {
    const { msg, messageType } = this.props.message;
    if (messageType === FEEDBACK_RECEIVED_TYPE) {
      return this.renderShowingFeedback();
    }

    if (msg) {
      return (
        <Highlighter
          searchWords={[this.props.searchTerm]}
          autoEscape={true}
          textToHighlight={he.decode(msg)}
        />
      );
    }
  }

  renderMessageBody() {
    const { message, lastForDirection, isNotesMode } = this.props;
    const { attachments } = message;

    if (this.shouldRenderMessage()) {
      return (
        <Inner
          displayAvatar={lastForDirection}
          photoUrl={message.photoUrl}
          senderName={message.senderAvatarName}
        >
          <Bubble
            variant={this.bubbleVariant(message)}
            direction={this.messageDirection()}
            hasCorner={lastForDirection}
            semitransparent={message.messageType !== NOTE_TYPE && isNotesMode}
            hasAttachments={attachments.length > 0}
          >
            {this.renderMessageText()}
            <Attachments attachments={attachments} />
          </Bubble>
        </Inner>
      );
    }
  }

  render() {
    const { message, showTime } = this.props;
    const { messageType } = message;

    if (messageType === EVENT_TYPE || messageType === FEEDBACK_REQUESTED_TYPE) {
      return (
        <article className={css["base"]}>
          <EventMessage message={message} />
        </article>
      );
    }

    return (
      <article className={css[this.messageDirection()]}>
        {this.renderSenderName()}
        {this.renderMessageExtra()}
        {this.renderMessageBody()}
        {showTime && this.renderSentAt()}
        {showTime && this.readStatus()}
      </article>
    );
  }
}
function mapStateToProps(state) {
  const { searchTerm } = state.messages.list.messages;
  const { mlsBuyerRequiredEnabled } = state.currentUser;
  const { buyerNameRequired, showingAgents } =
    state.showings.single.showing.entity;
  const { buyers } = state.showings.single.sellerTenantInvolvement;

  const showBuyersInApprovalMsg = mlsBuyerRequiredEnabled && buyerNameRequired;
  const showingTeamAgentIds = showingAgents.map(agent => agent.userId);

  return {
    showBuyersInApprovalMsg,
    searchTerm,
    buyers,
    showingTeamAgentIds
  };
}

export default connect(mapStateToProps)(Message);
