import css from './show.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchShowingsCounts } from '~brokerage/actions/showings'
import Categories from '~brokerage/components/showings/Categories'
import List from '~brokerage/components/showings/List'
import ShowingScreen from '~brokerage/components/shared/ShowingScreen'

class Showing extends PureComponent {
  static propTypes = {
    params: PropTypes.object,
    dispatch: PropTypes.func,
    filter: PropTypes.object,
    location: PropTypes.object,
    showing: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      listingKey: PropTypes.string
    }),
    isShowingEditInstructionsSubmitting: PropTypes.bool,
    isShowingChanging: PropTypes.bool
  }

  state = {
    needFetchShowingsCounts: null
  }

  componentWillReceiveProps(nextProps) {
    const { showing } = this.props
    const {
      showing: nextShowing,
      nextIsShowingEditInstructionsSubmitting,
      nextIsShowingChanging,
      filter: {
        role: nextRole,
        agent: nextAgent
      }
    } = nextProps

    if (showing && showing.id === nextShowing.id && showing.status !== nextShowing.status) {
      if (nextIsShowingEditInstructionsSubmitting || nextIsShowingChanging) {
        this.setState({ needFetchShowingsCounts: true })
      } else {
        this.props.dispatch(fetchShowingsCounts({ role: nextRole, agentId: nextAgent }))
      }
    } else if (this.state.needFetchShowingsCounts &&
               (!nextIsShowingEditInstructionsSubmitting || !nextIsShowingChanging)) {
      this.setState({ needFetchShowingsCounts: false })
      this.props.dispatch(fetchShowingsCounts({ role: nextRole, agentId: nextAgent }))
    }
  }

  render() {
    const { showingId } = this.props.params

    return (
      <div>
        <div className={css.sidebar}>
          <Categories location={this.props.location}/>
          <div className={css.list}>
            <List status={this.props.filter.status} currentShowingId={showingId}/>
          </div>
        </div>
        {showingId != null && <ShowingScreen showingId={showingId}/>}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { filter } = state.showings.list.showings
  const { entity: showing,
          isEditInstructionsSubmitting: isShowingEditInstructionsSubmitting,
          isChanging: isShowingChanging } = state.showings.single.showing

  return {
    filter,
    showing,
    isShowingEditInstructionsSubmitting,
    isShowingChanging
  }
}
export default connect(mapStateToProps)(Showing)
