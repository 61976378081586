import css from './RadioSet.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class Radio extends Component {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { variant, children } = this.props

    return (
      <div className={css[variant] || css.base}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            variant: child.props.variant || variant
          })
        )}
      </div>
    )
  }
}
