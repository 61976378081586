import { useEffect } from "react";
import PropTypes from "prop-types";

const ListItemLoader = ({ needLoadMore, loadMore, children }) => {
  useEffect(() => needLoadMore && loadMore(), [needLoadMore]);
  return children;
};

ListItemLoader.propTypes = {
  needLoadMore: PropTypes.bool,
  loadMore: PropTypes.func,
  children: PropTypes.node
};
export default ListItemLoader;
