import { combineReducers } from "redux";

import {
  SET_MAPBOX_KEY,
  SET_CURRENT_USER,
  SHOW_LANDING_FOOTER,
  HIDE_LANDING_FOOTER
} from "~brokerage/constants/actionTypes";

import showingsReducer from "./showings";
import listingsReducer from "./listings";
import teamsReducer from "./teams";
import myOrganizationReducer from "./myOrganization";
import peopleReducer from "./people";
import messagesReducer from "./messages";
import notificationsReducer from "./notifications";
import myAccountReducer from "./myAccount";
import messageFieldReducer from "./messageField";
import attachmentsReducer from "./attachments";
import settingsReducer from "./settings";
import propertySearchReducer from "./propertySearch";
import routesReducer from "./routes";
import surveysReducer from "./surveys";
import reportsReducer from "./reports";

function currentUser(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.currentUser;
    default:
      return state;
  }
}

function keys(state = {}, action) {
  switch (action.type) {
    case SET_MAPBOX_KEY:
      return {
        ...state,
        mapboxkey: action.apiKey
      };
    default:
      return state;
  }
}

function isLandingFooterShown(state = false, action) {
  switch (action.type) {
    case SHOW_LANDING_FOOTER:
      return true;
    case HIDE_LANDING_FOOTER:
      return false;
    default:
      return state;
  }
}

const reducers = combineReducers({
  currentUser,
  keys,
  isLandingFooterShown,
  showings: showingsReducer,
  listings: listingsReducer,
  teams: teamsReducer,
  myOrganization: myOrganizationReducer,
  people: peopleReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  myAccount: myAccountReducer,
  messageField: messageFieldReducer,
  attachments: attachmentsReducer,
  settings: settingsReducer,
  propertySearch: propertySearchReducer,
  routes: routesReducer,
  surveys: surveysReducer,
  reports: reportsReducer
});

const rootReducer = (state, action) => {
  if (action.type.includes("MESSAGE_FIELD_")) {
    return {
      ...state,
      messageField: messageFieldReducer(state.messageField, action)
    };
  }

  return reducers(state, action);
};

export default rootReducer;
