import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import {
  Form,
  Row,
  Col,
  TextField,
  Textarea,
  PhoneNumber,
  Checkbox
} from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";
import { fetchPeople, fetchPeopleCounts } from "~brokerage/actions/people";
import usePrevious from "~brokerage/app/hooks/usePrevious";
import { callApi } from "~brokerage/middlewares/api";
import css from "~brokerage/components/modals/ModalUpdateContact.sass";
import { buildContactDetails } from "./utils";

const ModalUpdateContact = props => {
  const history = useHistory();
  const {
    dispatch,
    location,
    location: {
      query: { contactId, category }
    },
    contacts
  } = props;
  const selectedContact =
    contacts.filter(contact => contact.id.toString() === contactId)[0] || {};
  const initialDetails = buildContactDetails(selectedContact);
  const [archived, setArchived] = useState(initialDetails.isArchived);
  const previousArchived = usePrevious(archived);

  const handleSubmit = async (e, values) => {
    let data = {};
    Object.keys(values).map(key => {
      if (values[key] !== initialDetails[key]) {
        data[key] = values[key];
      }
    });

    if (isEmpty(data)) {
      toast.info("No changes were found to update.");
      return;
    }

    callApi(
      `contacts/${contactId}`,
      {},
      deepObjectKeysToSnakeCase(data),
      "patch"
    )
      .then(() => {
        toast.success(
          `Contact has been ${archived ? "archived" : "updated"} successfully.`
        );
        closeModal();
        dispatch(fetchPeople(location.query));
        if (archived !== previousArchived) {
          dispatch(fetchPeopleCounts(location.query));
        }
      })
      .catch(error => {
        toast.error(error.response.data.message);
      });
  };

  const closeModal = () => {
    history.push({
      pathname: `/people`,
      query: { ...omit(location.query, "modal", "contactId") }
    });
  };

  const { firstName, lastName, primaryPhone, secondaryPhone, email, notes } =
    initialDetails;

  const isBuyer = category === "buyer";
  const contactType = isBuyer ? "Buyer" : "Seller/Tenant";

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title={`Edit ${contactType}`} onClose={closeModal} />

        <Body>
          <Row offset="15">
            <Col variant="liquid" modifier="half">
              <TextField
                name="firstName"
                defaultValue={firstName}
                block
                placeholder="First Name"
                required
                focused
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <TextField
                name="lastName"
                defaultValue={lastName}
                block
                placeholder="Last Name"
                required
              />
            </Col>
          </Row>
          <Row offset="10">
            <Col variant="liquid" modifier="half">
              <PhoneNumber
                name="primaryPhone"
                defaultValue={primaryPhone}
                block
                placeholder="Primary Phone Number"
                required={!isBuyer}
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <PhoneNumber
                name="secondaryPhone"
                defaultValue={secondaryPhone}
                block
                placeholder="Secondary Number"
              />
            </Col>
          </Row>
          <Row offset="10">
            <Col variant="liquid" modifier="half">
              <TextField
                name="email"
                defaultValue={email}
                type="email"
                block
                placeholder="Email Address"
                required={!isBuyer}
              />
            </Col>
          </Row>
          <Row offset="10">
            <Textarea
              name="notes"
              defaultValue={notes}
              placeholder={`Enter notes about ${contactType.toLowerCase()} (optional).`}
              maxLength={1000}
            />
          </Row>
          <Row offset="10">
            <Col>
              <Checkbox
                modifier="rightAligned"
                name="isArchived"
                checked={archived}
                onChange={() => setArchived(!archived)}
              >
                {`Mark the ${contactType.toLowerCase()} as archived`}
              </Checkbox>
            </Col>
          </Row>
        </Body>

        <Footer>
          <Button type="submit" float="right" variant="primary">
            Update
          </Button>
          <Button type="button" variant="newMessageCancel" onClick={closeModal}>
            Cancel
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

ModalUpdateContact.propTypes = {
  location: PropTypes.object,
  dispatch: PropTypes.func,
  contacts: PropTypes.array
};

function mapStateToProps(state) {
  const { entities: contacts } = state.people.list.people;
  return {
    contacts
  };
}

export default connect(mapStateToProps)(ModalUpdateContact);
