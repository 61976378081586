import css from './DragTip.sass'
import React, { Component } from 'react'
import Icon from '~brokerage/components/shared/Icon'

export default class DragTip extends React.PureComponent {
  render() {
    return (
      <div className={css.base}>
        <div className={css.icon}><Icon name="info"/></div>
        Drag showing below to change time
      </div>
    )
  }
}
