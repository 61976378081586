// TODO: investigate caching mapbox requests
import { externalApi } from "brokerage/middlewares/api";
import { MAPBOX_DIRECTIONS_URL } from "brokerage/app/constants/apiUrls";

const mapboxDirections = async ({ date, coordinates }, mapboxkey) => {
  const { data } = await externalApi(
    `${MAPBOX_DIRECTIONS_URL}${coordinates}`,
    {
      overview: "full",
      depart_at: date,
      access_token: mapboxkey
    },
    {},
    "get"
  );
  const { geometry: map_geometry, legs } = data.routes[0];
  const { duration: roundtrip_duration } = legs[legs.length - 1];
  return { map_geometry, roundtrip_duration };
};

export default mapboxDirections;
