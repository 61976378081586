import css from './Tabs.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Links from './Links'
import Link from './Link'

export default class Tabs extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node,
    selected: PropTypes.number,
    onChange: PropTypes.func
  }

  state = {
    currentIndex: 0
  }

  goTo(index) {
    this.setState({
      currentIndex: index
    })
    if (this.props.onChange) {
      this.props.onChange(index)
    }
  }

  render() {
    const { variant = 'base' } = this.props

    return (
      <div>
        <div className={css.links}>
          <Links>
            {React.Children.map(this.props.children, (pane, paneIndex) => {
              <Link
                variant={variant}
                active={this.state.currentIndex === paneIndex}
                label={pane.props.label}
                info={pane.props.info}
                onClick={this.goTo.bind(this, paneIndex)}
              />
            })}
          </Links>
        </div>

        <div>
          {React.Children.map(this.props.children, (pane, paneIndex) => {
            const { active: isActive } = pane.props
            return (
              React.cloneElement(pane, {
                active: typeof isActive === 'boolean' ? isActive : this.state.currentIndex === paneIndex,
                key: paneIndex
              })
            )
          })}
        </div>
      </div>
    )
  }
}
