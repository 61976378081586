import css from "./index.sass";
import React, { useEffect, useRef, useState, useContext } from "react";
import Grid from "./Grid";
import ActiveAppointmentList from "./ActiveAppointmentList";
import StaticAppointmentList from "./StaticAppointmentList";
import { callApi } from "~brokerage/middlewares/api";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import moment from "moment";
import { connect } from "react-redux";
import Restrictions from "./Restrictions";
import ActiveIndicator from "./ActiveIndicator";

const TimelineMain = ({
  initiallyActiveListing,
  activeAppointments,
  staticAppointments,
  showRestrictions,
  maxHeight,
  updateForTravelTime,
  updateOnlyLastItem,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam,
  mapboxkey
}) => {
  const { restrictions, setRestrictions } = useContext(ShowingContext);
  const [activeShowing, setActiveShowing] = useState(initiallyActiveListing);
  const containerRef = useRef(null);
  const listRef = useRef();
  let container = containerRef.current;

  useEffect(() => {
    container = containerRef.current;
  }, [containerRef.current]);

  useEffect(() => {
    activeAppointments.length && fetchListingRestrictions();
  }, [activeAppointments.length]);

  // Need to change this when too much API call's become a bottle neck.
  useEffect(() => {
    const currentMinute = moment().minutes();
    let delay = 5 - (currentMinute % 5);
    if (delay === 5) delay = 0;
    const timer = setTimeout(() => {
      fetchListingRestrictions();
      const interval = setInterval(fetchListingRestrictions, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }, delay * 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onMouseDown = ({ pageY }) => {
    if (activeShowing)
      listRef.current.changeActivePos({
        pos: pageY - 10,
        id: activeAppointment.id
      });
  };

  const fetchListingRestrictions = async () => {
    try {
      const listings = activeAppointments
        .filter(s => s.type !== "stop")
        .map(s => s.listingKey)
        .join(",");
      const date = moment(activeAppointments[0].requestedTimeRaw).format(
        "YYYY-MM-DD"
      );
      const { data } = await callApi(
        "listings/find_availability",
        { listings, date },
        {},
        "get"
      );
      setRestrictions(data.listings);
    } catch (error) {
      console.log(error);
    }
  };

  const preventPropagation = event => {
    event.preventDefault();
    event.stopPropagation();
    return true;
  };

  const activeAppointment = activeShowing
    ? activeAppointments.find(s => s.listingKey === activeShowing)
    : {};

  const { id: showingId, address, photoUrl } = activeAppointment;

  return (
    <div ref={containerRef} className={css.base} style={{ maxHeight }}>
      <div className={css.inner} onMouseDown={onMouseDown}>
        <Grid />
        <div className={css.columns}>
          <div className={css.left}>
            <StaticAppointmentList showings={staticAppointments} />
            {container && activeAppointments.length && (
              <ActiveAppointmentList
                ref={listRef}
                mapboxkey={mapboxkey}
                restrictions={restrictions}
                activeShowing={activeShowing}
                setActiveShowing={setActiveShowing}
                container={container}
                showings={activeAppointments}
                updateForTravelTime={updateForTravelTime}
                updateOnlyLastItem={updateOnlyLastItem}
                appointmentReason={appointmentReason}
                isListingOwnedByCurrentUserOrOfficeTeam={
                  isListingOwnedByCurrentUserOrOfficeTeam
                }
              />
            )}
          </div>
          {showRestrictions && (
            <div className={css.right} onMouseDown={preventPropagation}>
              {activeShowing && (
                <ActiveIndicator address={address} photoUrl={photoUrl} />
              )}
              <Restrictions
                restrictions={restrictions}
                activeShowing={activeShowing}
                showingId={showingId}
                isListingOwnedByCurrentUserOrOfficeTeam={
                  isListingOwnedByCurrentUserOrOfficeTeam
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ keys }) => {
  const { mapboxkey } = keys;
  return {
    mapboxkey
  };
};

export default connect(mapStateToProps)(TimelineMain);
