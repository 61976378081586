import css from './ButtonSet.sass'
import React from 'react'
import PropTypes from 'prop-types'

export default class ButtonSet extends React.PureComponent {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { align, children } = this.props

    return (
      <div className={css[align === 'right' ? 'baseRight' : 'base']}>
        {React.Children.map(children, child =>
          <div className={css[align === 'right' ? 'childRight' : 'child']}>
            {child}
          </div>
        )}
      </div>
    )
  }
}
