import { DIRECT_UPLOAD_URL } from "./constants";

// useFileUpload
export const getDirectUploadUrl = (uploadSource, addTimeStamp) => {
  const params = new URLSearchParams();

  if (uploadSource) params.append("upload_source", uploadSource);
  if (addTimeStamp) params.append("add_time_stamp", addTimeStamp);

  const queryString = params.toString();
  return queryString
    ? `${DIRECT_UPLOAD_URL}?${queryString}`
    : DIRECT_UPLOAD_URL;
};
