import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import Pages from "~brokerage/app/pages";
import { Router, Route } from "react-router-dom";
import HomePage from "~brokerage/app/pages/login/HomePage";
import SmartAppBanner from "../components/shared/SmartAppBanner";

export default class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { store, history, userId, invalidLogin } = this.props;

    return (
      <Provider store={store}>
        <div>
          <Router history={history}>
            <div>
              <Route
                path="/"
                component={
                  isNaN(userId) || userId === null || userId.length === 0
                    ? () => <HomePage invalidLogin={invalidLogin} />
                    : Pages
                }
              />
            </div>
          </Router>
          <SmartAppBanner />
        </div>
      </Provider>
    );
  }
}
