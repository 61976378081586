import css from './Textarea.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import omit from 'lodash/omit'
import { REQUIRED_IS_EMPTY } from '~brokerage/constants/validation'
import Errors from './Errors'
import { withFormBinding } from './Form'
export class Textarea extends Component {
  static propTypes = {
    variant: PropTypes.string,
    name: PropTypes.string,
    focused: PropTypes.bool,
    errorVariant: PropTypes.string,
    errorDescriptions: PropTypes.object,
    elRef: PropTypes.func,
    divStyle: PropTypes.object,
  }

  state = {
    errors: null
  }

  componentDidMount() {
    if (this.props.focused) {
      this.input.focus()
    }
  }

  validate = () => {
    if (this.input.required) {
      if (this.input.value.length) {
        this.setState({ errors: null })
      } else {
        this.setState({ errors: [ REQUIRED_IS_EMPTY ] })
        return false
      }
    }

    return true
  }

  value = () => {
    return {
      [this.props.name]: this.input.value
    }
  }

  handleFocus = () => {
    if (this.state.errors) {
      this.setState({
        errors: null
      })
    }

    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  render() {
    const { variant = 'base', elRef, name, errorVariant, errorDescriptions, divStyle = {} } = this.props
    const { errors } = this.state

    return (
      <div style={divStyle}>
        <textarea
          className={composeClassName(css, variant)}
          name={name}
          ref={el => { this.input = el; elRef && elRef(el) }}
          {...omit(this.props, Object.keys(Textarea.propTypes))}
          onFocus={this.handleFocus}
        />

        {errors && <Errors name={name} errors={errors} variant={errorVariant} descriptions={errorDescriptions}/>}
      </div>
    )
  }
}
export default withFormBinding(Textarea)
