import React, { useState } from "react";
import { connect } from "react-redux";

import {
  BigRadio,
  Row,
  Label,
  Controls,
  Checkbox,
  Dropdown
} from "~brokerage/components/shared/Form";
import Switch from "~brokerage/components/shared/Switch";
import Button from "~brokerage/components/shared/Button";
import {
  updateShowingInstructionsChanges,
  saveShowingInstructionsChanges
} from "~brokerage/actions/listings";
import SurveyTypeSelect from "~brokerage/components/shared/Survey/SurveyTypeSelect";
import Tooltip from "~brokerage/components/shared/Tooltip";
import EditSurveyModal from "~brokerage/components/shared/EditSurveyModal";
import { fetchUserSurvey } from "~brokerage/actions/surveys";
import css from "./index.sass";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import {
  SHOW_FEEDBACK_ANALYSIS_TOOLTIP,
  FEEDBACK_ATTEMPT_OPTIONS,
  DEFAULT_FEEDBACK_ATTEMPT_OPTION,
  FEEDBACK_ATTEMPT_TOOLTIP
} from "../constants";
import InformationLineIcon from "remixicon-react/InformationLineIcon";

const FeedbackSurvey = ({
  showingInstructions,
  listingId,
  onNextClick,
  dispatch
}) => {
  const {
    allowFeedback,
    useDefaultSurvey,
    listingSurveyId,
    listingSurveyName,
    showFeedbackAnalysis,
    feedbackAttemptCount
  } = showingInstructions;

  const [settingChanged, setSettingChanged] = useState(false);
  const [editSurvey, setEditSurvey] = useState(null);
  const [applyDefault, setApplyDefault] = useState(false);

  const handleSettingUpdate = event => {
    const input = event.target;
    const payload = { [input.name]: input.checked };
    dispatch(updateShowingInstructionsChanges(payload));
    setSettingChanged(true);
  };

  const handleDefaultToggle = event => {
    const checked = event.target.value === "default";
    dispatch(updateShowingInstructionsChanges({ useDefaultSurvey: checked }));
    setSettingChanged(true);
  };

  const handleSurveyChange = ({ id, name }) => {
    if (id === "new") setEditSurvey("new");
    else {
      dispatch(
        updateShowingInstructionsChanges({
          listingSurveyId: id,
          listingSurveyName: name
        })
      );
      setSettingChanged(true);
    }
  };

  const handleFeedbackAttemptChange = selected => {
    dispatch(updateShowingInstructionsChanges(selected));
    setSettingChanged(true);
  };

  const handleSave = () => {
    if (settingChanged) {
      const payload = {
        allow_feedback: allowFeedback,
        use_default_survey: useDefaultSurvey,
        listing_survey_id: listingSurveyId,
        show_feedback_analysis: showFeedbackAnalysis,
        feedback_attempt_count: feedbackAttemptCount
      };
      if (applyDefault) payload.apply_as_default = true;
      dispatch(saveShowingInstructionsChanges(listingId, payload));
      setSettingChanged(false);
    }
  };

  const openSurvey = surveyId => {
    dispatch(fetchUserSurvey(surveyId));
    setEditSurvey(surveyId);
  };

  const handleDone = () => {
    handleSave();
    onNextClick();
  };

  const disableDone =
    !useDefaultSurvey && !listingSurveyId ? "Attach a survey" : false;

  return (
    <>
      <Row className={css.rowStyle}>
        <Label valign="switch">Allow feedback?</Label>
        <Controls>
          <Switch
            name="allowFeedback"
            checked={allowFeedback}
            onChange={handleSettingUpdate}
          />
        </Controls>
      </Row>
      {allowFeedback && (
        <Row className={css.rowStyle}>
          <Label valign="dropdown">
            <span>
              <Tooltip
                className={css.feedbackReminderLabel}
                text={FEEDBACK_ATTEMPT_TOOLTIP}
                style={{ display: "flex", alignItems: "center" }}
              >
                Send feedback reminder
                <InformationLineIcon
                  color="#999"
                  size={14}
                  className={css.feedbackReminderIcon}
                />
              </Tooltip>
            </span>
          </Label>
          <Controls>
            <Dropdown
              name="feedbackAttemptCount"
              options={FEEDBACK_ATTEMPT_OPTIONS}
              selected={
                feedbackAttemptCount || DEFAULT_FEEDBACK_ATTEMPT_OPTION.value
              }
              onChange={handleFeedbackAttemptChange}
              variant="outline2"
            />
          </Controls>
        </Row>
      )}
      <Row className={css.rowStyle}>
        <Label valign="switch">
          <Tooltip text={SHOW_FEEDBACK_ANALYSIS_TOOLTIP}>
            <div style={{ display: "flex", alignItems: "center" }}>
              Show feedback analysis?
              <InformationLineIcon
                color="#999"
                size={14}
                style={{ marginLeft: "5px" }}
              />
            </div>
          </Tooltip>
        </Label>
        <Controls>
          <Switch
            name="showFeedbackAnalysis"
            checked={showFeedbackAnalysis}
            onChange={handleSettingUpdate}
          />
        </Controls>
      </Row>
      {allowFeedback && (
        <>
          <Row className={css.rowStyle}>
            <Label valign="switch">Use MLS default feedback survey?</Label>
            <Controls>
              <BigRadio
                icon="templateDotted"
                heading="Use Default Survey"
                value="default"
                checked={useDefaultSurvey}
                onChange={handleDefaultToggle}
              />
              <BigRadio
                icon="templateEdit"
                heading="Use Custom Survey"
                value="custom"
                checked={!useDefaultSurvey}
                onChange={handleDefaultToggle}
              />
            </Controls>
          </Row>
          <Row className={css.rowStyle}>
            <Label variant="hidden">Custom Survey</Label>
            <Controls>
              {useDefaultSurvey ? (
                <Button
                  variant="outline"
                  onClick={() => {
                    openSurvey("default");
                  }}
                >
                  <div className={css.buttonContent}>
                    <EyeLineIcon size={16} className={css.icon} />
                    View Default Survey
                  </div>
                </Button>
              ) : (
                <SurveyTypeSelect
                  listingSurveyId={listingSurveyId}
                  listingSurveyName={listingSurveyName}
                  onSelectSurvey={({ id, label }) =>
                    handleSurveyChange({ id, name: label })
                  }
                  onEditSurvey={() => {
                    openSurvey(listingSurveyId);
                  }}
                />
              )}
            </Controls>
          </Row>
        </>
      )}
      <div className={css.checkRow}>
        <Checkbox
          checked={applyDefault}
          onChange={() => {
            setSettingChanged(true);
            setApplyDefault(!applyDefault);
          }}
        >
          Apply as default settings for newly created listings
        </Checkbox>
        <Tooltip
          disabled={!disableDone}
          text={disableDone || ""}
          direction="up"
          variant="error"
        >
          <Button
            variant="primary"
            onClick={handleDone}
            disabled={!!disableDone}
          >
            Done
          </Button>
        </Tooltip>
      </div>
      {!!editSurvey && (
        <EditSurveyModal
          surveyId={editSurvey}
          closeModal={() => setEditSurvey(null)}
          onUpdate={handleSurveyChange}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { entity: showingInstructions } =
    state.listings.single.showingInstructions;

  return {
    showingInstructions
  };
}

export default connect(mapStateToProps)(FeedbackSurvey);
