import React from "react";
import appLogoWhite from "~brokerage/assets/images/logos/app-logo-white.svg";
import appLogoBlack from "~brokerage/assets/images/logos/app-logo-black.svg";

const LOGO_VARIANTS = {
  white: appLogoWhite,
  black: appLogoBlack
};

const AppLogo = ({
  height = "46",
  width = "110",
  variant = "white",
  scale = 1,
  ...props
}) => (
  <img
    src={LOGO_VARIANTS[variant]}
    width={width}
    height={height}
    style={{ scale: scale }}
    alt="App Logo"
    {...props}
  />
);

export default AppLogo;
