import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '~brokerage/components/shared/Avatar'

export default class Recipient extends React.PureComponent {
  static propTypes = {
    recipient: PropTypes.shape({
      name: PropTypes.string,
      photoUrl: PropTypes.string
    })
  }

  render() {
    const { recipient } = this.props

    return (
      <div>
        <div className={css.avatar}>
          <Avatar size="20" src={recipient.photoUrl} name={[recipient.firstName, recipient.lastName].join(" ")}/>
        </div>
        {recipient.name}
      </div>
    )
  }
}
