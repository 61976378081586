import { formatTime } from "~brokerage/libs/helpers/TimeHelper";

const parseTime = time => time.split(":").map(e => parseInt(e, 10));

const getTimeList = (disabledFunc, comparison) => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 59; minute += 30) {
      const val = `${hour}:${minute < 10 ? `0${minute}` : minute}`;
      const formattedTime = formatTime.apply(null, val.split(":"));

      times.push({
        value: val,
        isDisabled: disabledFunc(hour, minute),
        label: formattedTime === "12:00" ? "NOON" : formattedTime
      });
    }
  }

  if (comparison !== "start") {
    times.push({
      isDisabled: false,
      label: "11:59 PM",
      value: "23:59"
    });
  }

  return times;
};

export const getTimeOptions = (time, comparison) => {
  const [h, m] = parseTime(time);

  return getTimeList((hour, minute) => {
    if (comparison === "start") {
      return hour > h || (hour === h && minute >= m);
    } else {
      return hour < h || (hour === h && minute <= m);
    }
  }, comparison);
};
