import polyline from "@mapbox/polyline";
import { ROUTE_COLOR } from "~brokerage/constants/routes/colors";

const plotGeometry = (map, geometry) => {
  const decoded = geometry ? polyline.toGeoJSON(geometry) : null;
  const routeSource = map.getSource("route");

  if (routeSource) {
    if (decoded) routeSource.setData(decoded);
    else {
      map.removeLayer("route");
      map.removeSource("route");
    }
  } else if (decoded) {
    map.addSource("route", {
      type: "geojson",
      data: decoded
    });
    map.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round"
      },
      paint: {
        "line-color": ROUTE_COLOR,
        "line-width": 4
      }
    });
  }
};
export default plotGeometry;
