import { connect } from 'react-redux'
import ShowingChat from './ShowingChat'

function mapStateToProps(state) {
  const {
    entities: messages,
    isFetching,
    isChatScrollFetching,
    hasMoreMessages
  } = state.showings.single.messages
  const { role } = state.showings.list.showings.filter
  const { entity: showing, roleForLastShowing: currentShowingRole } = state.showings.single.showing

  const currentUserName = `${state.currentUser.firstName} ${state.currentUser.lastName}`

  return {
    role,
    messages,
    isFetching,
    currentUserName,
    showing,
    currentShowingRole,
    isChatScrollFetching,
    hasMoreMessages
  }
}
export default connect(mapStateToProps)(ShowingChat)
