export const EVENT_TYPE = "event";
export const SYSTEM_TYPE = "system";
export const REQUEST_A_CALL_TYPE = "request_a_call";
export const INSTANT_PENDING_TYPE = "instant_pending";
export const INSTANT_APPROVED_TYPE = "instant_approved";
export const APPROVED_TYPE = "approved";
export const INSTANT_DECLINED_TYPE = "instant_declined";
export const DECLINED_TYPE = "declined";
export const FEEDBACK_RECEIVED_TYPE = "feedback_received";
export const FEEDBACK_REQUESTED_TYPE = "feedback_requested";
export const NOTE_TYPE = "note";
export const SHOWING_INSTRUCTIONS_TYPE = "instructions_changed";

export const SHOWING_CONVERSATION_TYPE = "showing";
export const DIRECT_CONVERSATION_TYPE = "direct";
export const BROADCAST_CONVERSATION_TYPE = "broadcast";
export const HELPDESK_CONVERSATION_TYPE = "helpdesk";
