import {
  FETCH_DEFAULT_RESTRICTION_REQUEST,
  FETCH_DEFAULT_RESTRICTION_SUCCESS,
  FETCH_DEFAULT_RESTRICTION_FAILURE,
  ADD_DEFAULT_RESTRICTION_REQUEST,
  ADD_DEFAULT_RESTRICTION_SUCCESS,
  ADD_DEFAULT_RESTRICTION_FAILURE,
  UPDATE_DEFAULT_RESTRICTION_REQUEST,
  UPDATE_DEFAULT_RESTRICTION_SUCCESS,
  UPDATE_DEFAULT_RESTRICTION_FAILURE,
  REMOVE_DEFAULT_RESTRICTION_REQUEST,
  REMOVE_DEFAULT_RESTRICTION_SUCCESS,
  REMOVE_DEFAULT_RESTRICTION_FAILURE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  isFetching: false,
  restrictions: []
}

export default function defaultRestrictionsReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DEFAULT_RESTRICTION_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_DEFAULT_RESTRICTION_SUCCESS:
      return {
        ...state,
        restrictions: action.data.restrictions,
        isFetching: false
      }
    case FETCH_DEFAULT_RESTRICTION_FAILURE:
      return { ...state, errors: true, isFetching: false }
    case ADD_DEFAULT_RESTRICTION_REQUEST:
      return { ...state, isFetching: true }
    case ADD_DEFAULT_RESTRICTION_SUCCESS:
      return {
        ...state,
        restrictions: state.restrictions.concat([
            action.data.restriction
        ]),
        isFetching: false
      }
    case ADD_DEFAULT_RESTRICTION_FAILURE:
      return { ...state, errors: true, isFetching: false }
    case UPDATE_DEFAULT_RESTRICTION_REQUEST:
      return { ...state, isFetching: true }
    case UPDATE_DEFAULT_RESTRICTION_SUCCESS:
      const updatedRestriction = action.data.restriction;

      return {
        ...state,
        restrictions: state.restrictions.map(r => r.id === updatedRestriction.id ? updatedRestriction : r),
        isFetching: false
      }
    case UPDATE_DEFAULT_RESTRICTION_FAILURE:
      return { ...state, errors: true, isFetching: false }
    case REMOVE_DEFAULT_RESTRICTION_REQUEST:
      return { ...state, isFetching: true }
    case REMOVE_DEFAULT_RESTRICTION_SUCCESS:
      return {
        ...state,
        restrictions: state.restrictions.filter(r => r.id !== action.restrictionId),
        isFetching: false
      }
    case REMOVE_DEFAULT_RESTRICTION_FAILURE:
      return { ...state, errors: true, isFetching: false }
    default:
      return state
  }
}
