import css from '../People.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  addListingTeamMember,
  removeListingTeamMember,
  updateListingTeamMember,
  enableSaveButton
} from '~brokerage/actions/listings'
import Avatar from '~brokerage/components/shared/Avatar'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import PeopleAddForm from './PeopleAddForm'
import PremiumButtonWrapper, { PREMIUM_FEATURE_TYPES } from '~brokerage/components/shared/PremiumButtonWrapper'

export class People extends PureComponent {
  static propTypes = {
    listingId: PropTypes.string.isRequired,
    people: PropTypes.array,
    onChange: PropTypes.func,
    dispatch: PropTypes.func
  }

  state = {
    isAdding: false,
    agentToEdit: null
  }

  handleAddCancel = () => {
    this.setState({
      isAdding: false
    })
    this.props.dispatch(enableSaveButton())
  }

  handleEditCancel = () => {
    this.setState({
      agentToEdit: null
    })
    this.props.dispatch(enableSaveButton())
  }

  handleAdd = (person) => {
    this.props.dispatch(addListingTeamMember(person))
    this.props.dispatch(enableSaveButton())
    this.setState({
      isAdding: false
    })

    this.props.onChange()
  }

  handleUpdate = (person) => {
    this.props.dispatch(updateListingTeamMember(person))
    this.props.dispatch(enableSaveButton())
    this.setState({
      agentToEdit: null
    })

    this.props.onChange()
  }

  handleAddClick = () => {
    this.setState({
      isAdding: true,
      agentToEdit: null
    })
  }

  handleEditClick = (agent) => {
    this.setState({
      isAdding: false,
      agentToEdit: agent
    })
  }

  handleRemoveClick = (index) => {
    this.props.dispatch(removeListingTeamMember(this.props.people[index]))
    this.props.onChange()
  }

  renderFooter() {
    if (this.state.isAdding) {
      return (
        <PeopleAddForm
          listingId={this.props.listingId}
          onCancel={this.handleAddCancel}
          onSubmit={this.handleAdd}
        />
      )
    } else if (this.state.agentToEdit) {
      return (
        <PeopleAddForm
          listingId={this.props.listingId}
          onCancel={this.handleEditCancel}
          onSubmit={this.handleUpdate}
          person={this.state.agentToEdit}
        />
      )
    }

    return (
      <PremiumButtonWrapper
        featureType={PREMIUM_FEATURE_TYPES.ADD_FOLLOWERS_TO_LISTING}
        featureDisabledContent={
          <Button variant="outline">
            <Icon name="userAdd"/> Upgrade to add more Followers
          </Button>
        }
      >
        <Button variant="outline" onClick={this.handleAddClick}>
          <Icon name="userAdd"/> Add Team Member
        </Button>
      </PremiumButtonWrapper>
    )
  }

  render() {
    const { agentToEdit } = this.state;

    return (
      <div className={css.base}>
        <table className={css.table}>
          <tbody>
            {this.props.people.map((person, personIndex) => {
              const disablePersonBeingEdited = agentToEdit && person.id === agentToEdit.id;
              const isRemoveButtonDisabled = !person.isRemovable || disablePersonBeingEdited;

              return (
                <tr key={person.agentId}>
                  <td className={css.tdName}>
                    <div className={css.avatar}>
                      <Avatar
                        size="30"
                        src={person.photoUrl}
                        name={[person.firstName, person.lastName].join(' ')}
                      />
                    </div>
                    <div className={css.name}>
                      {person.name}
                      {person.role && <div className={css.role}>{person.role}</div>}
                    </div>
                  </td>
                  <td className={css.tdStatus}>
                    <Button variant="peopleRemove"
                      disabled={!person.isEditable}
                      onClick={this.handleEditClick.bind(this, person)}
                    >
                      <div className={css.icon}><Icon name="edit" /></div>
                      {person.notificationLevelLabel}
                    </Button>
                  </td>
                  <td className={css.tdRemove}>
                    <PremiumButtonWrapper featureType={PREMIUM_FEATURE_TYPES.REMOVE_FOLLOWERS_FROM_LISTING}>
                      <Button variant="peopleRemove"
                        disabled={isRemoveButtonDisabled}
                        onClick={this.handleRemoveClick.bind(this, personIndex)}
                      >
                        <div className={css.icon}><Icon name="close" /></div>
                        Remove
                      </Button>
                    </PremiumButtonWrapper>
                  </td>
                </tr>
              )})}
          </tbody>
        </table>
        <div className={css.footer}>
          {this.renderFooter()}
        </div>
      </div>
    )
  }
}
export default connect()(People)
