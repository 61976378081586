import React from "react";
import UserPicture from "./UserPicture";

import css from "./index.sass";

import { formatPhone } from "../utilsDetails";

const AgentContact = ({ user, n }) => {
  const {
    has_photo: hasPhoto,
    office,
    name,
    phone,
    email,
    initials,
    photo,
    id,
    relation,
  } = user || {};

  const isValidUser = () => {
    if (!user || !id || (!email && !office && !phone)) {
      return false;
    }
    return true;
  };

  if (!isValidUser()) {
    return <div />;
  }

  return (
    <>
      <div className={css[`userPicture${n}`]}>
        <UserPicture
          hasPhoto={hasPhoto}
          userInitials={initials}
          photoUrl={photo}
        />
      </div>
      <div className={css[`userDetails${n}`]}>
        <div>
          <b>{`${name} (${relation})`}</b> <br />
          {office || "No Office"} <br />
          {phone ? formatPhone(phone) : "No Phone Number"} <br />
          {email || "No Email ID"}
        </div>
      </div>
      <div className={css[`messageUser${n}`]}>Send Message Button</div>
    </>
  );
};

export default AgentContact;
