import React from "react";
import { connect } from "react-redux";
import css from "../index.sass";
import Icon from "~brokerage/components/shared/Icon";

const MAX_PAGE_BUTTONS = 5;

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

const Pagination = ({ currentPage = 1, totalPages = 1, onPageChange }) => {
  const getPages = () => {
    const leftIndex = Math.max(currentPage - 2, 1);
    const rightIndex = Math.min(currentPage + 2, totalPages);
    if (leftIndex == 1) return range(1, Math.min(MAX_PAGE_BUTTONS, totalPages));
    if (rightIndex == totalPages)
      return range(totalPages - MAX_PAGE_BUTTONS + 1, totalPages);
    return range(leftIndex, rightIndex);
  };

  return (
    <div className={css.pagination}>
      <Page
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <Icon name="chevronLeft" />
      </Page>
      {getPages().map(num => (
        <Page
          onClick={() => num !== currentPage && onPageChange(num)}
          selected={num === currentPage}
        >
          {num}
        </Page>
      ))}
      <Page
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <Icon name="chevronRight" />
      </Page>
    </div>
  );
};

const Page = ({ onClick, disabled = false, selected = false, children }) => {
  const style = disabled
    ? css.pageButtonDisabled
    : selected
    ? css.pageButtonSelected
    : css.pageButton;
  return (
    <div onClick={() => !(disabled || selected) && onClick()} className={style}>
      {children}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    page: currentPage,
    totalPages,
    isFetching
  } = state.propertySearch.list.listings;
  return {
    currentPage,
    totalPages,
    isFetching
  };
}

export default connect(mapStateToProps)(Pagination);
