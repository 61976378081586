import React from "react";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";
import PropTypes from "prop-types";

const AppointmentLabel = ({
  showings,
  css,
  toggleAppointmentsList,
  isAppointmentsListOpened
}) => {
  const numberOfAppointments = showings.length;
  const confirmedCount = showings.filter(s => s.status === "Approved").length;
  return (
    <div className={css.toggle}>
      <Button variant="link" onClick={toggleAppointmentsList}>
        <Icon
          name={!isAppointmentsListOpened ? "chevronDown" : "chevronUp"}
          modifier="offset5 valignTextDefault"
        />
        {`${numberOfAppointments} ${
          numberOfAppointments === 1 ? "Appointment" : "Appointments"
        }`}
      </Button>
      <span className={css.tag}>{`${confirmedCount} approved`}</span>
    </div>
  );
};

AppointmentLabel.propTypes = {
  showings: PropTypes.array.isRequired,
  css: PropTypes.object,
  toggleAppointmentsList: PropTypes.func.isRequired,
  isAppointmentsListOpened: PropTypes.bool.isRequired
};

export default AppointmentLabel;
