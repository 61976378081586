import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import { connect } from "react-redux";

const TimeChanged = ({ message, bubbleOptions, status }) => (
  <Bubble variant="blue" {...bubbleOptions}>
    <p className={css.p}>
      {message.senderName} <b>Changed Time</b> to{" "}
      <b className={css.nobr}>{message.requestDate}</b> at{" "}
      <b className={css.nobr}>
        {message.requestStartTime} to {message.requestEndTime}
      </b>
      {status === "pending" && ". Approval Pending."}
    </p>
  </Bubble>
);

TimeChanged.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object,
  status: PropTypes.string
};

function mapStateToProps(state) {
  const { status } = state.showings.single.showing.entity;

  return {
    status
  };
}

export default connect(mapStateToProps)(TimeChanged);
