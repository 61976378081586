import React, { useState } from "react";
import css from "brokerage/app/components/myShowings/AppointmentsList.sass";
import AppointmentDetails from "~brokerage/components/shared/AppointmentDetailsModal";
import Showing from "~brokerage/app/components/myShowings/Showing";
import StatusDisplay from "~brokerage/app/components/myShowings/StatusDisplay";
import AppointmentLabel from "~brokerage/app/components/myShowings/AppointmentLabel";
import AddAppointmentOption from "~brokerage/app/components/myShowings/AddAppointmentOption";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

const AppointmentsList = props => {
  const [isAppointmentsListOpened, setIsAppointmentsListOpened] =
    useState(false);
  const [shownDetailsId, setShownDetailsId] = useState(null);

  const getStatusDisplay = status => {
    return <StatusDisplay status={status} css={css} />;
  };

  const toggleAppointmentsList = () => {
    setIsAppointmentsListOpened(!isAppointmentsListOpened);
  };

  const RenderAppointmentLabel = () => {
    const { showings } = props.route;
    return (
      <AppointmentLabel
        showings={showings}
        css={css}
        toggleAppointmentsList={toggleAppointmentsList}
        isAppointmentsListOpened={isAppointmentsListOpened}
      />
    );
  };

  const renderShowing = showing => (
    <Showing
      getStatusDisplay={getStatusDisplay}
      setShownDetailsId={setShownDetailsId}
      css={css}
      key={uuid()}
      {...showing}
    />
  );

  const RenderAppointmentsList = () => {
    const {
      route: { showings, id: routeId },
      location
    } = props;
    return (
      <>
        {isAppointmentsListOpened && (
          <div key={`upcoming-appointment-${routeId}`}>
            <div className={css.appointmentsContainer}>
              <div className={css.listContainer}>
                {showings.map(showing => renderShowing(showing))}
              </div>
            </div>
            <AddAppointmentOption
              routeId={routeId}
              css={css}
              location={location}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <AppointmentDetails
        shownDetailsId={shownDetailsId}
        setShownDetailsId={setShownDetailsId}
      />
      <div className={css.appointmentsList}>
        <RenderAppointmentLabel />
        <RenderAppointmentsList />
      </div>
    </div>
  );
};

AppointmentsList.propTypes = {
  route: PropTypes.object.isRequired
};

export default AppointmentsList;
