import React from "react";
import { validateEmail } from "~brokerage/components/settings/utilities/utils";
import css from "./shared.sass";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import { ErrorMessage } from "@hookform/error-message";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";

const Email = ({ errors, email, register }) => {
  return (
    <Row className={composeClassName(css, "inputRow", "marginBottom")}>
      <Column
        className={composeClassName(css, "inputColumnCentered", "rowLabel")}
      >
        <InputLabel required>Email Address</InputLabel>
      </Column>
      <Column className={composeClassName(css, "inputColumn", "emailInput")}>
        <input
          type="text"
          name="email"
          placeholder="Email"
          defaultValue={email}
          aria-invalid={errors.email ? "true" : "false"}
          {...register("email", {
            required: "Email is required",
            validate: {
              email: value =>
                validateEmail(value) || "Please enter a valid email address"
            }
          })}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <p className={css.errorMessage}>{message}</p>
          )}
        />
      </Column>
    </Row>
  );
};

export default Email;
