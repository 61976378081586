import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "~brokerage/components/shared/Form";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import Switch from "~brokerage/components/shared/Switch";
import css from "./ShowingRequests.sass";
import NavigationPrompt from "~brokerage/components/shared/NavigationPrompt";
import mlsSupportData from "~brokerage/components/support/mlsSupportData";
import { updateAcceptShowingRequest } from "~brokerage/actions/settings";
import { propertySearch } from "../../../../actions/api/myOrganization";

const ShowingRequests = ({
  currentlyOptedOut,
  setValue,
  hasListings,
  systemOptOutManagedByMls,
  systemName,
  acceptShowingRequest,
  dispatch
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showListingsOption, toggleShowListingsOption] = useState(false);
  const [currentListingsShowable, updateCurrentListingsToBeShowable] =
    useState(true);

  const dialogMessage = {
    heading: "Confirm",
    body: "You have elected not to accept showing requests using Aligned Showings. This will be applied to all of your listings. Do you wish to continue?"
  };

  const handleAllowShowingsChange = e => {
    const { checked } = e.target;

    if (!checked) {
      setShowDialog(true);
    } else {
      updateValue(checked);
    }
  };

  const updateValue = checked => {
    setValue("accept_showing_request", checked);
    dispatch(updateAcceptShowingRequest(checked));

    if (!checked) {
      showListingsOption && toggleShowListingsOption(false);
    }
    // update existing showings option
    if (currentlyOptedOut && checked && hasListings) {
      toggleShowListingsOption(true);
      setValue("update_current_listings", true);
      !currentListingsShowable && updateCurrentListingsToBeShowable(true);
    }
  };

  const handleUpdateCurrentListingsChange = e => {
    const { checked } = e.target;
    setValue("update_current_listings", checked);
    updateCurrentListingsToBeShowable(checked);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const handleConfirm = () => {
    updateValue(false);
    setShowDialog(false);
  };

  const promptButtons = [
    {
      label: "Cancel",
      variant: "outline",
      onClick: onCancel
    },
    {
      label: "OK",
      variant: "primary",
      onClick: handleConfirm
    }
  ];

  const mlsData = mlsSupportData[systemName.toLowerCase()];

  return (
    <>
      <NavigationPrompt
        open={showDialog}
        message={dialogMessage}
        buttons={promptButtons}
      />
      {systemOptOutManagedByMls ? (
        <Row className={css.row}>
          <Col className={css.fullWidth}>
            <InputLabel>
              To Opt-out of using Aligned Showings, please contact{" "}
              {mlsData.name} at {mlsData.email}.
            </InputLabel>
          </Col>
        </Row>
      ) : (
        <>
          <Row className={css.row}>
            <Col className={css.fullWidth}>
              <InputLabel>
                Accept Showing Requests via Aligned Showings?
              </InputLabel>
            </Col>
            <Col>
              <Switch
                checked={acceptShowingRequest}
                onChange={handleAllowShowingsChange}
              />
            </Col>
          </Row>
          {showListingsOption && acceptShowingRequest && (
            <Row className={css.row}>
              <Col className={css.fullWidth}>
                <InputLabel>
                  Start accepting showing requests on current listings?
                </InputLabel>
              </Col>
              <Col>
                <Switch
                  checked={currentListingsShowable}
                  onChange={handleUpdateCurrentListingsChange}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { systemName } = state.currentUser;
  const { acceptShowingRequest } = state.settings.system.systemPreference;
  return {
    systemName,
    acceptShowingRequest
  };
};

export default connect(mapStateToProps)(ShowingRequests);
