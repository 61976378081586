import {
  MAPBOX_OPTIMIZE_URL,
  MAPBOX_MATRIX_URL
} from "~brokerage/app/constants/apiUrls";
import { externalApi } from "brokerage/middlewares/api";
import moment from "moment";
import { toast } from "react-toastify";
import { determineConflicts } from "~brokerage/components/shared/Timeline/Main/ActiveAppointmentList/util";

export const calcBestDestination = async (coordinates, mapboxkey) => {
  const { data } = await externalApi(
    `${MAPBOX_MATRIX_URL}${coordinates}`,
    {
      access_token: mapboxkey
    },
    {},
    "get"
  );
  const furthest = Math.max(...data.durations[0]);
  return data.durations[0].indexOf(furthest);
};

export const reorderShowings = (showings, startShowing) => {
  return showings.sort((x, y) => {
    return x.id === startShowing ? -1 : y.id === startShowing ? 1 : 0;
  });
};

export const fetchRoute = async (coordinates, mapboxkey) => {
  const toastMessages = {
    pending: "Calculating fastest route",
    success: "Fastest route calculated",
    error: "Sorry, Something went wrong"
  };
  const { data } = await toast.promise(
    externalApi(
      `${MAPBOX_OPTIMIZE_URL}${coordinates}`,
      {
        overview: "full",
        source: "first",
        destination: "last",
        roundtrip: false,
        access_token: mapboxkey
      },
      {},
      "get"
    ),
    toastMessages
  );
  return data;
};

export const updateShowingsForRoute = (
  showings,
  data,
  startTime,
  restrictions
) => {
  const { trips, waypoints } = data;
  const showingOrder = waypoints.map(w => w.waypoint_index);
  const updated = [];
  let nextShowingStartTime = moment(startTime);

  const travelTimes = trips[0].legs.map(l => l.duration);
  travelTimes.unshift(0);

  travelTimes.forEach((duration, index) => {
    const travelTime = (duration / 60).toFixed();
    const showingIndex = showingOrder[index];
    const showing = showings[showingIndex];
    if (index > 0) {
      nextShowingStartTime.add(travelTime, "minutes");
    }

    const conflicts = calculateConflict(
      showing,
      nextShowingStartTime,
      restrictions
    );

    updated.push({
      ...showing,
      travelTime,
      conflicts,
      time: nextShowingStartTime.clone(),
      requestedTimeRaw: nextShowingStartTime.format(),
      position: index
    });

    nextShowingStartTime.add(showing.duration, "minutes");
  });

  return updated;
};

const calculateConflict = (showing, newStartTime, restrictions) => {
  const { duration, id } = showing;
  const { unavailability = [], allowOverlap = false } = restrictions[id];
  return determineConflicts({
    unavailability,
    nextDate: newStartTime,
    duration,
    allowOverlap
  });
};

export const getCoordinates = showings => {
  const coordinates = showings.map(
    s => `${s.coordinates.long},${s.coordinates.lat}`
  );

  const coordinatesString = coordinates.join(";");
  return coordinatesString;
};

export const isWithinAdvanceNoticeTime = (id, selectedTime, restrictions) => {
  const unavailability = restrictions[id]?.unavailability || [];
  return unavailability.some(u => {
    if (u.advance_notice) {
      const start = moment(u.start);
      const end = moment(u.end);
      return selectedTime.isBetween(start, end);
    }
    return false;
  });
};
