import css from "./Bubble.sass";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class Bubble extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    direction: PropTypes.string.isRequired,
    children: PropTypes.node,
    hasCorner: PropTypes.bool,
    semitransparent: PropTypes.bool
  };

  componentDidMount() {
    this.$el = $(ReactDOM.findDOMNode(this));

    $(window).on("resize.Bubble", this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    $(window).off("resize.Bubble");
  }

  handleWindowResize = () => {
    const $lineSet = $("p, ul, li, h4", this.$el);

    this.$el.width("");

    if (
      !$lineSet.length ||
      ($lineSet.length === 1 && $lineSet.eq(0).height() < 20)
    ) {
      return;
    }

    let maxWidth = 0;

    $lineSet.each((pIndex, line) => {
      const $line = $(line);
      $line.css("display", "inline");
      const lineWidth = $line.outerWidth();
      maxWidth = Math.max(lineWidth, maxWidth);
      $line.css("display", "");
    });

    this.$el.width(maxWidth);
  };

  render() {
    const { variant, direction, semitransparent, hasAttachments } = this.props;

    const className = classNames(
      css[variant || "base"],
      css[direction],
      semitransparent && css.semitransparent,
      { [css.hasAttachments]: hasAttachments }
    );

    return (
      <div className={className}>
        {(() => {
          if (this.props.hasCorner) {
            return (
              <i className={css.corner}>
                <i />
              </i>
            );
          }
        })()}
        {this.props.children}
      </div>
    );
  }
}
