import { combineReducers } from "redux";

import isEqual from "lodash/isEqual";
import findIndex from "lodash/findIndex";
import reject from "lodash/reject";

import * as types from "~brokerage/constants/actionTypes";
import { toast } from "react-toastify";
import { errorsToSentence } from "~brokerage/utils"

function listing(state = {}, action) {
  switch (action.type) {
    case types.LISTING_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SUCCESS:
      return {
        ...state,
        errors: false,
        entity: action.data.listing,
        isFetching: false
      };
    case types.LISTING_FAILURE:
      return { ...state, errors: true, isFetching: false };
    default:
      return state;
  }
}

const FIRST_STEP = 0;
const SHOWING_DETAILS_INITIAL_STATE = {
  step: FIRST_STEP,
  entity: {
    restrictions: []
  },
  sellerTenantInvolvement: [],
  isSaveDisabled: false
};

function showingDetails(state = SHOWING_DETAILS_INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_LISTING_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        entity: {
          ...state.entity,
          restrictions: action.data.restrictions
        }
      };
    case types.LISTING_ADD_RESTRICTION_FAILURE:
    case types.LISTING_UPDATE_RESTRICTION_FAILURE:
      toast.error(errorsToSentence(action.errors.base))
      return state;
    case types.LISTING_SHOWING_DETAILS_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SHOWING_DETAILS_SUCCESS:
      return {
        ...state,
        errors: false,
        entity: action.data.showingDetails,
        showingCoordinatorEnabled:
          action.data.showingDetails.showingCoordinatorEnabled,
        showingCoordinatorEnabledForOrganization:
          action.data.showingDetails.showingCoordinatorEnabledForOrganization,
        isFetching: false
      };
    case types.LISTING_SHOWING_DETAILS_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_SHOWING_DETAILS_CHANGE:
      return { ...state, entity: { ...state.entity, ...action.changes } };
    case types.LISTING_SHOWING_DETAILS_CHANGE_STEP:
      return { ...state, step: action.newStep };
    case types.LISTING_SHOWING_DETAILS_DISABLE_SAVE_BUTTON:
      return { ...state, isSaveDisabled: true };
    case types.LISTING_SHOWING_DETAILS_ENABLE_SAVE_BUTTON:
      return { ...state, isSaveDisabled: false };
    case types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS:
      return {
        ...state,
        errors: false,
        showingCoordinatorEnabled: action.data.showing_coordinator_enabled,
        isFetching: false
      };
    case types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false
      };
    default:
      return state;
  }
}

function availableMembers(state = { entities: [] }, action) {
  switch (action.type) {
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_SUCCESS:
      return {
        ...state,
        errors: false,
        entities: action.data.members,
        isFetching: false
      };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_CLEAR:
      return { ...state, errors: false, entities: [], isFetching: false };
    default:
      return state;
  }
}

const teamMembersInitialState = {
  entities: [],
  diff: {
    create: [],
    destroy: [],
    update: []
  }
};

function addMember(member, entities, diff) {
  let newEntities, newDiff;

  newEntities = [...entities, member];
  newDiff = { ...diff, create: [...diff.create, member] };
  return { entities: newEntities, diff: newDiff };
}

function removeMember(member, entities, diff) {
  let newEntities, newDiff;

  let memberInCreateDiffIndex = findIndex(diff.create, e => isEqual(e, member));

  if (memberInCreateDiffIndex === -1) {
    newDiff = { ...diff, destroy: [...diff.destroy, member] };
  } else {
    newDiff = {
      ...diff,
      create: [
        ...diff.create.slice(0, memberInCreateDiffIndex),
        ...diff.create.slice(memberInCreateDiffIndex + 1)
      ]
    };
  }

  newEntities = entities.filter(e => !isEqual(e, member));

  return { entities: newEntities, diff: newDiff };
}

function updateMember(member, entities, diff) {
  let newEntities, newCreate, newUpdate;

  const memberInEntitiesIndex = findIndex(
    entities,
    e => e.agentId === member.agentId
  );
  if (
    memberInEntitiesIndex !== -1 &&
    !isEqual(entities[memberInEntitiesIndex], member)
  ) {
    newEntities = [
      ...entities.slice(0, memberInEntitiesIndex),
      member,
      ...entities.slice(memberInEntitiesIndex + 1)
    ];
  } else {
    newEntities = entities;
  }

  const memberInCreateDiffIndex = findIndex(
    diff.create,
    e => e.agentId === member.agentId
  );
  if (
    memberInCreateDiffIndex !== -1 &&
    !isEqual(diff.create[memberInCreateDiffIndex], member)
  ) {
    newCreate = [
      ...diff.create.slice(0, memberInCreateDiffIndex),
      member,
      ...diff.create.slice(memberInCreateDiffIndex + 1)
    ];
  } else {
    newCreate = diff.create;
  }

  const memberInUpdateDiffIndex = findIndex(
    diff.update,
    e => e.agentId === member.agentId
  );
  if (memberInUpdateDiffIndex === -1 && memberInCreateDiffIndex === -1) {
    newUpdate = [...diff.update, member];
  } else if (
    memberInUpdateDiffIndex !== -1 &&
    !isEqual(diff.update[memberInUpdateDiffIndex], member)
  ) {
    newUpdate = [
      ...diff.update.slice(0, memberInUpdateDiffIndex),
      member,
      ...diff.update.slice(memberInUpdateDiffIndex + 1)
    ];
  } else {
    newUpdate = diff.update;
  }

  return {
    entities: newEntities,
    diff: { ...diff, create: newCreate, update: newUpdate }
  };
}

function teamMembers(state = teamMembersInitialState, action) {
  switch (action.type) {
    case types.LISTING_TEAM_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        errors: false,
        entities: action.data.members,
        isFetching: false
      };
    case types.LISTING_TEAM_MEMBERS_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_TEAM_MEMBERS_ADD_MEMBER: {
      const updated = addMember(action.member, state.entities, state.diff);

      return { ...state, entities: updated.entities, diff: updated.diff };
    }
    case types.LISTING_TEAM_MEMBERS_REMOVE_MEMBER: {
      const updated = removeMember(action.member, state.entities, state.diff);

      return { ...state, entities: updated.entities, diff: updated.diff };
    }
    case types.LISTING_TEAM_MEMBERS_UPDATE_MEMBER: {
      const updated = updateMember(action.member, state.entities, state.diff);

      return { ...state, entities: updated.entities, diff: updated.diff };
    }
    case types.LISTING_TEAM_BATCH_UPDATE_SUCCESS:
      return { ...state, diff: { create: [], destroy: [], update: [] } };
    default:
      return state;
  }
}

function availableSellers(state = { entities: [] }, action) {
  switch (action.type) {
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_SUCCESS:
      return {
        ...state,
        errors: false,
        entities: action.data.contacts,
        isFetching: false
      };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_CLEAR:
      return { ...state, errors: false, entities: [], isFetching: false };
    default:
      return state;
  }
}

function sellerTenantInvolvement(state = { entities: [] }, action) {
  switch (action.type) {
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_REQUEST:
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_SUCCESS: {
      const entities = action.data.contacts;
      return {
        ...state,
        isFetching: false,
        entities,
        originalEntities: entities,
        errors: null
      };
    }
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_SUCCESS:
      return { ...state, isFetching: false, originalEntities: state.entities };
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_FAILURE:
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    case types.LISTING_SELLER_TENANT_ADD_PARTICIPANT:
      return { ...state, entities: [...state.entities, action.participant] };
    case types.LISTING_SELLER_TENANT_REMOVE_PARTICIPANT:
      return {
        ...state,
        entities: reject(state.entities, action.participant, isEqual)
      };
    case types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE: {
      const index = findIndex(state.entities, action.participant, isEqual);
      const participant = { ...state.entities[index], ...action.change };
      const entities = [
        ...state.entities.slice(0, index),
        participant,
        ...state.entities.slice(index + 1)
      ];
      return { ...state, entities };
    }
    default:
      return state;
  }
}

function showingInstructions(state = {}, action) {
  switch (action.type) {
    case types.LISTING_SHOWING_INSTRUCTIONS_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SHOWING_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        errors: false,
        entity: action.data.showingInstructions,
        isFetching: false
      };
    case types.LISTING_SHOWING_INSTRUCTIONS_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_SHOWING_INSTRUCTIONS_SAVE_REQUEST:
      return { ...state, isFetching: true };
    case types.LISTING_SHOWING_INSTRUCTIONS_SAVE_SUCCESS:
      return { ...state, errors: false, isFetching: false };
    case types.LISTING_SHOWING_INSTRUCTIONS_SAVE_FAILURE:
      return { ...state, errors: true, isFetching: false };
    case types.LISTING_SHOWING_INSTRUCTIONS_CHANGE:
      return { ...state, entity: { ...state.entity, ...action.changes } };
    case types.LISTING_PREVIOUS_ADVANCE_NOTICE_DURATION:
      return { ...state, previousAdvanceNoticeDuration: action.duration };
    default:
      return state;
  }
}

export default combineReducers({
  listing,
  showingDetails,
  availableMembers,
  teamMembers,
  showingInstructions,
  availableSellers,
  sellerTenantInvolvement
});
