import css from "./Person.sass";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import upperFirst from "lodash/upperFirst";
import { changeSellerTenantShowingInvolvement } from "~brokerage/actions/listings";
import { Dropdown, Row } from "~brokerage/components/shared/Form";
class PersonShowingInvolvement extends Component {
  static propTypes = {
    person: PropTypes.shape({
      showingInvolvement: PropTypes.string,
      role: PropTypes.string
    }),
    dispatch: PropTypes.func,
    onChange: PropTypes.func
  };

  get options() {
    return [
      { value: "can_approve", label: "Can Approve Showings and Send Comments" },
      {
        value: "can_approve_no_comment",
        label: "Can Approve Showings with No Comments"
      },
      { value: "observe_mode", label: "Notifications Only" },
      { value: "none_involvement", label: "None" }
    ];
  }

  handleChange = option => {
    this.props.dispatch(
      changeSellerTenantShowingInvolvement(this.props.person, option.value)
    );
    this.props.onChange();
  };

  render() {
    const { person } = this.props;

    return (
      <Row offset="10">
        <div>
          <span
            className={css.communicationInvolvementLabelText}
          >{`${upperFirst(person.role)} Involvement in Showings`}</span>
        </div>
        <div className={css.communicationInvolvementDropdown}>
          <Dropdown
            options={this.options}
            selected={person.showingInvolvement}
            onChange={this.handleChange}
            variant="outlineSellerTenantInvolvement"
          />
        </div>
      </Row>
    );
  }
}
export default connect()(PersonShowingInvolvement);
