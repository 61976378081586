import { combineReducers } from "redux";

import {
  LISTINGS_REQUEST,
  LISTINGS_SUCCESS,
  LISTINGS_FAILURE,
  LISTING_BROADCAST_MESSAGE_COUNT,
  LISTING_LIST_DISPLAY_TOGGLE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  entities: [],
  total: -1,
  page: 1
};

const initialDisplayState = {
  displayCards: true
};

function listings(state = defaultState, action) {
  switch (action.type) {
    case LISTINGS_REQUEST:
      return { ...state, isFetching: true };
    case LISTINGS_SUCCESS: {
      const newEntities =
        action.data.page === 1
          ? action.data.listings
          : [...state.entities, ...action.data.listings];

      return {
        ...state,
        entities: newEntities,
        total: action.data.listingsCount,
        page: action.data.page,
        totalPages: action.data.totalPages,
        isFetching: false
      };
    }
    case LISTINGS_FAILURE:
      return { ...state, isFetching: false };
    case LISTING_BROADCAST_MESSAGE_COUNT:
      const entity = state.entities.find(
        e => e.id === action.broadcastListingId
      );
      if (entity) {
        entity.broadcastMessageCount = action.count;
      }

      return {
        ...state,
        entities: [
          ...state.entities.map(e => e.id === action.broadcastListingId ? entity : e)
        ]
      };
    default:
      return state;
  }
}

function display(state = initialDisplayState, action) {
  switch (action.type) {
    case LISTING_LIST_DISPLAY_TOGGLE:
      return { displayCards: !state.displayCards };
    default:
      return state;
  }
}

export default combineReducers({
  listings,
  display
});
