import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import Autocomplete from "~brokerage/components/shared/Autocomplete";
import SelectedPlace from "./SelectedPlace";
import mapboxPlaceSearch from "~brokerage/components/scheduleRoute/util/mapboxPlaceSearch";
import { Label } from "~brokerage/components/shared/Form";
import { Body } from "~brokerage/components/modals/BaseModal";
import Actions from "./Actions";

const SearchPlace = ({
  mapboxkey,
  showings,
  stopPlace,
  setStopPlace,
  setCurrentStep
}) => {
  const [search, setSearch] = useState("");
  const [places, setPlaces] = useState([]);
  const [fetching, setFetching] = useState(false);

  const delayedFetch = useCallback(
    debounce(val => searchPlace(val), 500),
    [showings]
  );

  const searchPlace = async query => {
    if (query.length < 3) return setPlaces([]);
    setFetching(true);
    try {
      const placeResults = await mapboxPlaceSearch(query, showings, mapboxkey);
      setPlaces(
        placeResults.map(placeItem => ({
          value: placeItem,
          label: placeItem.placeName
        }))
      );
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching places. Please try again!"
      );
    } finally {
      setFetching(false);
    }
  };

  const handleSearchChange = event => {
    setSearch(event.target.value);
    delayedFetch(event.target.value);
  };

  const handleSelection = ({ value }) => {
    setStopPlace(value);
    setSearch("");
    setPlaces([]);
  };

  return (
    <>
      <Body>
        {stopPlace ? (
          <>
            <Label variant="vertical">Selected place:</Label>
            <SelectedPlace
              placeDetails={stopPlace}
              onClosePress={() => setStopPlace(null)}
            />
          </>
        ) : (
          <Autocomplete
            focused
            value={search}
            options={places}
            fetching={fetching}
            placeholder="Search for address, pin or points of interest"
            onSelect={handleSelection}
            onChange={handleSearchChange}
          />
        )}
      </Body>
      <Actions setCurrentStep={setCurrentStep} stopPlace={stopPlace} />
    </>
  );
};

const mapStateToProps = ({ keys }) => {
  const { mapboxkey } = keys;
  return { mapboxkey };
};

export default withRouter(connect(mapStateToProps)(SearchPlace));
