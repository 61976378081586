import React from "react";
import FileUpload from "~brokerage/components/shared/FileUpload";

const Actions = ({ setFiles }) => {
  return (
    <div>
      <FileUpload setFiles={setFiles} />
    </div>
  );
};

export default Actions;
