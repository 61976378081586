import React from "react";
import moment from "moment";
import {
  downloadIcs,
  addEventToGoogleCalendar,
  buildCalendarEvent
} from "~brokerage/utils";

import css from "./AddToCalendar.sass";

const AddToCalendar = ({
  showingDetails: { address, unique_id: uniqueId },
  appointmentDetail: { time, duration }
}) => {
  const handleClick = type => {
    const event = buildCalendarEvent({
      time: moment.parseZone(time),
      duration,
      address,
      uniqueId
    });

    type === "google" ? addEventToGoogleCalendar(event) : downloadIcs(event);
  };

  return (
    <div className={css.calendarOptionsWrapper}>
      <div className={css.options}>
        <span onClick={() => handleClick("google")}>Google Calendar</span>
        <span onClick={() => handleClick("outlook")}>
          Outlook Calendar (ics)
        </span>
      </div>
    </div>
  );
};

export default AddToCalendar;
