import * as types from '~brokerage/constants/actionTypes'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'

export const defaultState = {
  invitedUsers: {},
  unregisteredAgents: {},
  userErrors: {},
  errors: {},
  isInviteOrUpdateSuccessful: false
}

// TODO: Handle requests actions
export default function brokerageReducer(state = defaultState, action) {
  switch (action.type) {
    // case types.BROKERAGE_SETTINGS_USERS_REQUEST:
    case types.BROKERAGE_SETTINGS_USERS_FAILURE:
      return {
        ...state,
        errors: action.errors
      }
    case types.BROKERAGE_SETTINGS_USERS_SUCCESS:
      return {
        ...state,
        invitedUsers: keyBy(action.data.invitedUsers, 'id'),
        unregisteredAgents: keyBy(action.data.unregisteredAgents, 'agentId')
      }
    case types.BROKERAGE_SETTINGS_INVITE_USER_REQUEST:
    case types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_REQUEST:
      return {
        ...state,
        userErrors: {},
        isInviteOrUpdateSuccessful: false
      }
    case types.BROKERAGE_SETTINGS_INVITE_USER_SUCCESS:
    case types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_SUCCESS:
      return {
        ...state,
        userErrors: {},
        isInviteOrUpdateSuccessful: true,
        invitedUsers: {
          ...state.invitedUsers,
          [action.data.id]: action.data
        }
      }
    case types.BROKERAGE_SETTINGS_INVITE_USER_FAILURE:
    case types.BROKERAGE_SETTINGS_UPDATE_INVITED_USER_FAILURE:
      return {
        ...state,
        userErrors: action.errors,
        isInviteOrUpdateSuccessful: false
      }
    case types.BROKERAGE_SETTINGS_INVITE_AGENT_REQUEST:
      return {
        ...state,
        errors: {},
        isInviteOrUpdateSuccessful: false
      }
    case types.BROKERAGE_SETTINGS_INVITE_AGENT_SUCCESS:
      return {
        ...state,
        errors: {},
        isInviteOrUpdateSuccessful: true,
        invitedUsers: {
          ...state.invitedUsers,
          [action.data.id]: action.data
        },
        unregisteredAgents: omit(state.unregisteredAgents, action.data.agentId)
      }
    case types.BROKERAGE_SETTINGS_INVITE_AGENT_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isInviteOrUpdateSuccessful: false
      }
    // case types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_REQUEST:
    case types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_FAILURE:
      return {
        ...state,
        errors: action.errors
      }
    case types.BROKERAGE_SETTINGS_REMOVE_INVITED_USER_SUCCESS:
      return {
        ...state,
        invitedUsers: omit(state.invitedUsers, action.userId)
      }
    case types.BROKERAGE_SETTINGS_CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        userErrors: {}
      }
    default:
      return state
  }
}
