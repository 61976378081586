import Tabs from './Tabs'
import Pane from './Pane'
import Links from './Links'
import Link from './Link'

Tabs.Pane = Pane
Tabs.Links = Links
Tabs.Link = Link

export default Tabs
