import { connect } from 'react-redux'
import RoleFilter from './RoleFilter'

function mapStateToProps(state) {
  const { filter: { role: currentRole }, availableRoles } = state.showings.list.showings

  return {
    currentRole,
    availableRoles
  }
}
export default connect(mapStateToProps)(RoleFilter)
