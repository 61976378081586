import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";

const PreferenceOption = ({
  className,
  name,
  defaultChecked,
  label,
  required,
  ...restProps
}) => {
  const inputValue = useRef(null);

  // Set current form context to parent context
  const { register } = useFormContext();
  const { ref, ...rest } = register(name, { required: required });

  return (
    <div className={className} htmlFor={name}>
      <input
        {...rest}
        id={name}
        type="checkbox"
        defaultChecked={defaultChecked}
        ref={e => {
          ref(e);
          inputValue.current = e;
        }}
        {...restProps}
      />
      <span>{label}</span>
    </div>
  );
};

export default PreferenceOption;
