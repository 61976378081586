import React from "react";
import { connect } from "react-redux";

import InformationLineIcon from "remixicon-react/InformationLineIcon";

import css from "./index.sass";
import AgentContact from "./AgentContact";

const AgentContactSection = ({ users, text, currentUser }) => {

  const { id: currentUserId } = currentUser;
  const contacts = users.filter(user => user.id != currentUserId);

  return (
    <div className={css.wrapper2}>
      <div className={css.header2}>Appointment Contacts</div>
      {contacts.map((contact, index) => {
        return (
          <AgentContact user={contact} n={index + 1} />
        );
      })}
      <div className={css.footer2}>
        <InformationLineIcon
          color="#999"
          size="1.2em"
          className={css.infoIcon}
        />{" "}
        {text}
      </div>
    </div>
  );
};

const mapStateToProps = ({ currentUser }) => {
  return {
    currentUser
  };
};

export default connect(mapStateToProps)(AgentContactSection);
