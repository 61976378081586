import css from './Switch.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'
import { Checkbox } from '~brokerage/components/shared/Form'

export default class Switch extends React.PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string
  }

  render() {
    const { label } = this.props;
    const customLabelEmpty = !label;

    return (
      <Checkbox variant="switch" {...this.props}>
        <div className={css[`seeker${this.props.checked ? 'Active' : ''}`]}>
          <div className={css.onText}>
            <div className={css.icon}><Icon name="check" size="12"/></div>
            {customLabelEmpty ? "Yes" : label}
          </div>
          <div className={css.offText}>
            <div className={css.icon}><Icon name="decline2" size="12"/></div>
            {customLabelEmpty ? "No" : label}
          </div>
        </div>
      </Checkbox>
    )
  }
}
