import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import Popup from "./Popup";

export default class Tooltip extends React.PureComponent {
  static propTypes = {
    alwaysVisible: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object
  };

  state = {
    shown: false
  };

  componentDidMount() {
    if (this.props.alwaysVisible) {
      this.showPopup();
    }
  }

  hidePopup() {
    this.setState({
      shown: false
    });
  }

  showPopup() {
    this.setState({
      shown: true
    });
  }

  renderPopup() {
    const elOffset = this.$el.offset();
    const rest = omit(this.props, Object.keys(Tooltip));

    return (
      <Popup
        baseTop={elOffset.top}
        baseBottom={elOffset.top + this.$el.height()}
        baseHorizontalCenter={
          elOffset.left + Math.floor(this.$el.outerWidth() / 2)
        }
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...rest}
      />
    );
  }

  handleMouseEnter = () => {
    if (this.props.alwaysVisible || this.props.disabled) {
      return;
    }
    clearTimeout(this.timeout);
    this.showPopup();
  };

  handleMouseLeave = () => {
    if (this.props.alwaysVisible) {
      return;
    }
    this.timeout = setTimeout(() => {
      this.hidePopup();
    }, 5);
  };

  render() {
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={el => (this.$el = $(el))}
        style={this.props.style}
      >
        {this.props.children}
        {this.state.shown && this.renderPopup()}
      </div>
    );
  }
}
