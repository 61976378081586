import css from './Errors.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { REQUIRED_IS_EMPTY, EMAIL_INVALID, TEL_INVALID, CUSTOM_ERROR } from '~brokerage/constants/validation'
import Tooltip from '~brokerage/components/shared/Tooltip'
export default class Errors extends Component {
  static contextTypes = {
    __formRegisteredComponents: PropTypes.object
  }

  static propTypes = {
    variant: PropTypes.string,
    name: PropTypes.string,
    errors: PropTypes.array,
    customError: PropTypes.string,
    descriptions: PropTypes.object
  }

  componentWillMount() {
    const { errorContainers } = this.context.__formRegisteredComponents
    const errorContainer = errorContainers.filter(container => container.props.name === this.props.name)[0]
    this.setState({
      errorContainer: errorContainer ? ReactDOM.findDOMNode(errorContainer) : null
    })
  }

  componentDidMount() {
    if (this.state.errorContainer) {
      this.renderContainer = document.createElement('div')
      this.state.errorContainer.appendChild(this.renderContainer)
      ReactDOM.render(this.renderBody(), this.renderContainer)
    }
  }

  componentWillUnmount() {
    if (this.renderContainer) {
      ReactDOM.unmountComponentAtNode(this.renderContainer)
      this.state.errorContainer.removeChild(this.renderContainer)
    }
  }

  renderBody() {
    const { variant = 'base', errors, customError, descriptions } = this.props

    const mapping = {
      [REQUIRED_IS_EMPTY]: 'This field is required',
      [EMAIL_INVALID]: 'Email is invalid',
      [TEL_INVALID]: 'Phone is invalid',
      [CUSTOM_ERROR]: customError,
      ...(descriptions || {})
    }

    if (variant === 'popup') {
      return (
        <Tooltip
          variant="error"
          alwaysVisible
          additional={errors.map(error => ({ text: mapping[error] }))}
        />
      )
    }

    return (
      <div className={css[variant]}>
        {errors.map((error, errorIndex) =>
          <p key={errorIndex}>{mapping[error]}</p>
        )}
      </div>
    )
  }

  render() {
    return this.state.errorContainer ? false : this.renderBody()
  }
}
