import {
  FETCH_DEFAULT_SHOWING_SETTING_REQUEST,
  FETCH_DEFAULT_SHOWING_SETTING_SUCCESS,
  FETCH_DEFAULT_SHOWING_SETTING_FAILURE,
  DEFAULT_SHOWING_SETTING_CHANGES,
  SAVE_DEFAULT_SHOWING_SETTING_CHANGES_REQUEST,
  SAVE_DEFAULT_SHOWING_SETTING_CHANGES_SUCCESS,
  SAVE_DEFAULT_SHOWING_SETTING_CHANGES_FAILURE,
  SAVE_INITIAL_DEFAULT_ADVANCE_NOTICE_DURATION
} from "~brokerage/constants/actionTypes";

const defaultState = {
  isFetching: false,
  defaultShowingSetting: {},
  initialDefaultShowingSetting: {}
}

export default function defaultShowingSettingReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DEFAULT_SHOWING_SETTING_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_DEFAULT_SHOWING_SETTING_SUCCESS:
      return {
        ...state,
        defaultShowingSetting: action.data.defaultShowingSetting,
        initialDefaultShowingSetting: action.data.defaultShowingSetting,
        isFetching: false
      }
    case FETCH_DEFAULT_SHOWING_SETTING_FAILURE:
      return { ...state, errors: true, isFetching: false }
    case DEFAULT_SHOWING_SETTING_CHANGES:
      return { ...state, defaultShowingSetting: { ...state.defaultShowingSetting, ...action.changes } };
    case SAVE_DEFAULT_SHOWING_SETTING_CHANGES_REQUEST:
      return { ...state, isFetching: true }
    case SAVE_DEFAULT_SHOWING_SETTING_CHANGES_SUCCESS:
      return {
        ...state,
        defaultShowingSetting: action.data.defaultShowingSetting,
        isFetching: false
      }
    case SAVE_DEFAULT_SHOWING_SETTING_CHANGES_FAILURE:
      return { ...state, errors: true, isFetching: false }
    case SAVE_INITIAL_DEFAULT_ADVANCE_NOTICE_DURATION:
      return { ...state, initialAdvanceNoticeDuration: action.duration }
    default:
      return state
  }
}
