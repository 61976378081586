import css from "./SubHeading.sass";
import React, { useContext } from "react";
import Switch from "~brokerage/components/shared/Switch";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";

const SubHeading = ({
  step,
  setShowListingRestrictions,
  showListingRestrictions
}) => {
  const { times } = useContext(ShowingContext);
  const showing = times.length ? times[0] : {};
  const { duration, startTime, time } = showing;

  const handleRestrictionSwitch = e => {
    setShowListingRestrictions(!showListingRestrictions);
  };

  const start = time && inUsersTimezone(time).format("LT");
  const end =
    time && inUsersTimezone(time).add(duration, "minutes").format("LT");

  return (
    <div className={css.header}>
      <div className={css.left}>
        <h3>{inUsersTimezone(startTime).format("dddd, MMMM DD")}</h3>
      </div>
      <div className={css.right}>
        {step === "time" && (
          <>
            <div className={css.label}>Show Listing Restrictions:</div>
            <Switch
              name="showRestrictions"
              checked={showListingRestrictions}
              onChange={handleRestrictionSwitch}
            />
          </>
        )}
        {step === "send" && `${start} - ${end} (${duration} Minutes)`}
      </div>
    </div>
  );
};

export default SubHeading;
