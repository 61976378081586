import mapboxgl from "!mapbox-gl";
import GrayMarker from "~brokerage/assets/icons/mapbox-marker-icon-20px-gray.png";

const initializeMap = (mapRef, mapboxkey) => {
  const options = {
    accessToken: mapboxkey,
    crossSourceCollisions: false,
    container: mapRef.current,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [-96, 37.8], // US center
    zoom: 3
  };
  const map = mapRef.current && new mapboxgl.Map(options);

  map.on("load", () => {
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      })
    );

    map.loadImage(GrayMarker, (e, img) => {
      map.addImage("base-marker", img, { sdf: true });
    });
  });

  return map;
};
export default initializeMap;
