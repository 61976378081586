import css from './Table.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Table extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { variant, children, ...rest } = this.props

    return (
      <div className={css[variant] || css.base}>
        <table className={css.table} {...rest}>
          {React.Children.map(children, child => {
            if (!child) { return child }
            return React.cloneElement(child, { variant })
          })}
        </table>
      </div>
    )
  }
}
