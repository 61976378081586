import BaseMessageHandler, {
  MESSAGES,
  BROADCAST,
  HELPDESK
} from './BaseMessageHandler'

export default class SupportMessageHandler extends BaseMessageHandler {
  handle() {
    if (this.isHelpdeskChatOpened()) {
      this.handleMessagesPageMessage(this.path[2])
    }

    super.handle()
  }

  isHelpdeskChatOpened() {
    return this.path[1] === MESSAGES
           && (this.path[2] === HELPDESK || this.path[1] === BROADCAST)
           && this.path[3] == this.message.conversationId
  }

  isMessageRead() {
    return this.isHelpdeskChatOpened()
  }
}
