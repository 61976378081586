const FASTEST_ROUTE_DISABLED_REASONS = {
  hasStarted:
    "Cannot calculate fastest route, the routes start time has passed.",
  invalidGeo:
    "Cannot calculate fastest route, the route contains a listing without valid geo infomation.",
  optimized: "Cannot calculate fastest route, the routes is already optimized."
};

const REORDER_ROUTE_DISABLED_REASONS = {
  hasStarted: "Cannot reorder route, the routes start time has passed."
};

const ADD_STOP_DISABLED_REASONS = {
  hasStarted: "Cannot add stop, the routes start time has passed."
};

const UNSAVED_CHANGES_WARNING = {
  heading: "Are you sure you want to leave this page?",
  body: "You have unsaved changes. Any adjustments to the route will not be saved until appointments are requested.",
  buttons: "leaveStay"
};

const UNSAVED_CHANGES_REMINDER = {
  heading: "Appointments not yet updated",
  body: "Any adjustments to the route will not be saved until appointments are requested.",
  buttons: "confirm"
};

export {
  FASTEST_ROUTE_DISABLED_REASONS,
  REORDER_ROUTE_DISABLED_REASONS,
  ADD_STOP_DISABLED_REASONS,
  UNSAVED_CHANGES_WARNING,
  UNSAVED_CHANGES_REMINDER
};
