import React from "react";
import { useHistory } from "react-router-dom";
import css from "./index.sass";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";

export default function SelectPage() {
  return (
    <div className={css.container}>
      <NavLink path="manage_surveys" title="Manage Surveys" />
      <NavLink path="default_settings" title="Default Survey Settings" />
    </div>
  );
}

const NavLink = ({ path, title }) => {
  const history = useHistory();
  const navigate = () => history.push(`/settings/feedback/${path}`);
  return (
    <a onClick={navigate} className={css.navBtn}>
      {title}
      <ArrowRightSLineIcon size={20} />
    </a>
  );
};
