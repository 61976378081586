import React from "react";
import css from "./VerticalBar.sass";
import PropTypes from "prop-types";

const VerticalBar = ({ isScheduledToday }) => {
  const verticalBarStyle = isScheduledToday
    ? css.verticalBar
    : css.hiddenVerticalBar;

  return <div className={verticalBarStyle}></div>;
};

VerticalBar.propTypes = {
  isScheduledToday: PropTypes.bool.isRequired
};

export default VerticalBar;
