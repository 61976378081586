import { DEFAULT_DROPDOWN_OPTION } from "./constants";

export const sellerDropdownOptions = sellers =>
  sellers.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id
  }));

export const buildParamsForReport = ({
  selectedSeller: { value },
  reportMetadata
}) => {
  const params = new URLSearchParams();

  if (value !== DEFAULT_DROPDOWN_OPTION.value) {
    params.append("seller_id", value);
  }

  params.append("report_metadata", reportMetadata);

  return params;
};
