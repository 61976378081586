import camelCase from 'lodash/camelCase'

import { format } from './PhonesHelper'

import {
  SELLER_TENANT_INVOLVEMENT_NONE_COMMUNICATION,
  SELLER_TENANT_INVOLVEMENT_PREFER_PRIMARY_PHONE
} from '~brokerage/constants/sellerTenantInvolvement'

export function sellerTenantWithPreferredCommunication(person) {
  const { preferredCommunication: storedValue } = person
  if (storedValue === SELLER_TENANT_INVOLVEMENT_NONE_COMMUNICATION)
    return `${person.firstName} ${person.lastName}`

  const prop = camelCase(storedValue)

  let valueToDisplay
  if (storedValue === SELLER_TENANT_INVOLVEMENT_PREFER_PRIMARY_PHONE) {
    valueToDisplay = format(person[prop])
  } else {
    valueToDisplay = person[prop]
  }

  return `${person.firstName} ${person.lastName} - ${valueToDisplay}`
}
