import mapboxgl from "!mapbox-gl";

const zoomToListings = (map, data) => {
  if (!data.features.length) return;
  var bounds = new mapboxgl.LngLatBounds();

  data.features.forEach(feature => {
    bounds.extend(feature.geometry.coordinates);
  });

  map.fitBounds(bounds, { padding: 100, maxZoom: 14, animate: false });
};
export default zoomToListings;
