import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateTeamInstructions } from "~brokerage/actions/showings";
import { withRouter } from "react-router-dom";
import ModalContents from "./ModalContents";

class ModalTeamInstructions extends React.PureComponent {
  static propTypes = {
    instructions: PropTypes.string,
    showingId: PropTypes.string,
    dispatch: PropTypes.func,
    lastUpdateReceivedAt: PropTypes.number
  };

  static contextTypes = {
    currentUser: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.state = { instructions: this.props.instructions || "" };
  }

  componentWillReceiveProps(nextProps) {
    const { showingId, lastUpdateReceivedAt, instructions } = this.props;
    const {
      showingId: nextShowingId,
      lastUpdateReceivedAt: nextLastUpdateReceivedAt,
      instructions: nextInstructions
    } = nextProps;

    if (instructions !== nextInstructions) {
      this.setState({ instructions: nextInstructions || "" });
    }

    if (
      showingId === nextShowingId &&
      lastUpdateReceivedAt !== nextLastUpdateReceivedAt
    ) {
      const { modal, ...query } = this.props.location.query; // eslint-disable-line no-unused-vars
      this.props.history.push({
        pathname: this.props.location.pathname,
        query: query
      });
    }
  }

  handleTeamInstructionsSave = () => {
    const formData = this.prepareFormData();
    const { showingId } = this.props;
    this.props.dispatch(updateTeamInstructions(showingId, formData));
  };

  handleInstructionsChange = e => {
    const { value } = e.target;
    this.setState({ instructions: value });
  };

  prepareFormData() {
    return {
      instructions: this.state.instructions
    };
  }

  render() {
    return (
      <ModalContents
        value={this.state.instructions}
        onChange={this.handleInstructionsChange}
        handleSave={this.handleTeamInstructionsSave}
      />
    );
  }
}

function mapStateToProps(state) {
  const { message: instructions, receivedAt: lastUpdateReceivedAt } =
    state.showings.single.showing.entity.internalInstructions;
  const { id: showingId } = state.showings.single.showing.entity;

  return {
    instructions,
    showingId,
    lastUpdateReceivedAt
  };
}
export default withRouter(connect(mapStateToProps)(ModalTeamInstructions));
