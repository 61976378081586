import { combineReducers } from 'redux'

import { initialTimeForNewShowing, timezone, inUsersTimezone } from '~brokerage/libs/helpers/TimeHelper'
import { checkForConflicts, detectOverlappingShowings } from '~brokerage/libs/helpers/ShowingHelper'

import * as types from '~brokerage/constants/actionTypes'

function listings(state = { entities: [] }, action) {
  switch (action.type) {
  case types.NEW_SHOWING_LISTINGS_REQUEST:
    return { ...state, isFetching: true }
  case types.NEW_SHOWING_LISTINGS_SUCCESS:
    return { ...state, entities: action.data.listings, isFetching: false }
  case types.NEW_SHOWING_LISTINGS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  default:
    return state
  }
}

const selectedListingInitialState = {
  entity: {},
  showingInstructions: {},
  restrictions: [],
  sellerTenants: [],
  teamMembers: []
}
function selectedListing(state = selectedListingInitialState, action) {
  switch (action.type) {
  case types.NEW_SHOWING_SELECTED_LISTING_REQUEST:
  case types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_REQUEST:
  case types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_REQUEST:
  case types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_REQUEST:
  case types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_REQUEST:
    return { ...state, isFetching: true }
  case types.NEW_SHOWING_SELECTED_LISTING_SUCCESS:
    return { ...state, entity: action.data.listing, isFetching: false }
  case types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_SUCCESS:
    return { ...state, showingInstructions: action.data.showingInstructions, isFetching: false }
  case types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_SUCCESS:
    return { ...state, restrictions: action.data.restrictions, isFetching: false }
  case types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_SUCCESS:
    return { ...state, sellerTenants: action.data.contacts, isFetching: false }
  case types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_SUCCESS:
    return { ...state, teamMembers: action.data.members, isFetching: false }
  case types.NEW_SHOWING_SELECTED_LISTING_FAILURE:
  case types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_FAILURE:
  case types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_FAILURE:
  case types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_FAILURE:
  case types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  case types.NEW_SHOWING_CLEAR_SELECTED_LISTING:
    return { ...state, entity: {} }
  default:
    return state
  }
}

function agents(state = { entities: [] }, action) {
  switch (action.type) {
  case types.NEW_SHOWING_AGENTS_REQUEST:
    return { ...state, isFetching: true }
  case types.NEW_SHOWING_AGENTS_SUCCESS:
    return { ...state, entities: action.data.agents, total: action.data.totalAgents, isFetching: false }
  case types.NEW_SHOWING_AGENTS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  case types.NEW_SHOWING_AGENTS_CLEAR:
      return { ...state, entities: [], total: 0 }
  default:
    return state
  }
}

function selectedAgent(state = null, action) {
  switch (action.type) {
  case types.NEW_SHOWING_SELECTED_AGENT:
    return { ...action.agent }
  case types.NEW_SHOWING_CLEAR_SELECTED_AGENT:
    return null
  default:
    return state
  }
}

function showings(state = { entities: [] }, action) {
  switch (action.type) {
  case types.NEW_SHOWING_SHOWINGS_REQUEST:
    return { ...state, isFetching: true }
  case types.NEW_SHOWING_SHOWINGS_SUCCESS:
    return {
      ...state,
      entities: action.data.showings,
      isFetching: false
    }
  case types.NEW_SHOWING_SHOWINGS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  default:
    return state
  }
}

function restrictions(state = { entities: [] }, action) {
  switch (action.type) {
  case types.NEW_SHOWING_RESTRICTIONS_REQUEST:
    return { ...state, isFetching: true }
  case types.NEW_SHOWING_RESTRICTIONS_SUCCESS:
    return { ...state,
      entities: action.data.restrictedTimeRanges,
      isFetching: false
    }
  case types.NEW_SHOWING_RESTRICTIONS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  default:
    return state
  }
}

function date(state = null, action) {
  if (action.type === types.NEW_SHOWING_SELECTED_DATE) {
    return action.date
  }
  return state
}

function time(state = null, action) {
  if (action.type === types.NEW_SHOWING_SELECTED_TIME) {
    return action.time
  }
  return state
}

function duration(state = null, action) {
  if (action.type === types.NEW_SHOWING_SELECTED_DURATION) {
    return action.duration
  }
  return state
}

function step(state = 1, action) {
  if (action.type === types.NEW_SHOWING_STEP_CHANGE) {
    return action.step
  }
  return state
}

const initialStatusesState = {
  entities: [
    {
      id: 'all',
      name: 'All',
      filterParams: { property_statuses: 'All' }
    },
    {
      id: 'active',
      name: 'Active',
      filterParams: { property_statuses: 'Active' }
    },
    {
      id: 'contingent',
      name: 'Contingent',
      filterParams: { property_statuses: 'Contingent' }
    },
    {
      id: 'pending',
      name: 'Pending',
      filterParams: { property_statuses: 'Pending' }
    },
    {
      id: 'temp-off-market',
      name: 'Temp Off Market',
      filterParams: { property_statuses: 'Temp Off Market' }
    },
    {
      id: 'backup',
      name: 'Backup',
      filterParams: { property_statuses: 'Backup' }
    }
  ]
}

function listingStatuses(state = initialStatusesState, action) {
  switch (action.type) {
    case types.NEW_SHOWING_LISTING_STATUSES_REQUEST:
      return { ...state, isFetching: true }
    case types.NEW_SHOWING_LISTING_STATUSES_SUCCESS:
      return { ...state, entities: action.data.statuses, isFetching: false }
    case types.NEW_SHOWING_LISTING_STATUSES_FAILURE:
      return { ...state, isFetching: false } // TODO error handling
    default:
      return state
  }
}

function save(state = {}, action) {
  switch (action.type) {
    case types.NEW_SHOWING_SAVE_REQUEST:
      return { ...state, errors: void 0, isPosting: true }
    case types.NEW_SHOWING_SAVE_SUCCESS:
      return { ...state, newShowingId: action.data.unique_id, isPosting: false }
    case types.NEW_SHOWING_SAVE_FAILURE:
      return { ...state, errors: action.errors, isPosting: false }
    case types.NEW_SHOWING_CONVERT_REQUEST:
      return { ...state, isPosting: true }
    case types.NEW_SHOWING_CONVERT_SUCCESS:
      return { ...state, newShowingId: action.data.unique_id, isPosting: false }
    case types.NEW_SHOWING_CONVERT_FAILURE:
      return { ...state, errors: action.errors, isPosting: false }
    default:
      return state
  }
}

function preserveGlobalState(state = {shouldPreserveGlobalState: false}, action) {
  switch (action.type) {
    case types.NEW_SHOWING_PRESERVE_GLOBAL_STATE:
      return { ...state, shouldPreserveGlobalState: true }
    case types.NEW_SHOWING_CLEAR_PRESERVE_GLOBAL_STATE:
      return { ...state, shouldPreserveGlobalState: false }
    default:
      return state
  }
}

const reducer = combineReducers({
  listings,
  selectedListing,
  agents,
  selectedAgent,
  showings,
  restrictions,
  date,
  time,
  duration,
  step,
  conflicts: state => state || false,
  overlappingShowings: state => state || [],
  listingStatuses,
  preserveGlobalState,
  save
})

function shouldPreserveGlobalState(state){
  return state && state.preserveGlobalState && state.preserveGlobalState.shouldPreserveGlobalState
}

const reducerContainer = (state, action) => {
  if (
    (action.type === types.NEW_SHOWING_CLEAR_FORM && !shouldPreserveGlobalState(state)) ||
    (action.type === types.NEW_SHOWING_SAVE_SUCCESS)
  ) {
    return reducer(void 0, action)
  }

  const nextState = reducer(state, action)

  if (!state || !timezone()) {
    nextState.conflicts = false
    nextState.overlappingShowings = []
    return nextState
  }

  if (!state.time) {
    nextState.time = initialTimeForNewShowing()
  }

  const {
    restrictions: {
      entities: restrictions,
      isFetching: restrictionsAreFetching
    },
    showings: {
      entities: showings,
      isFetching: showingsAreFetching
    },
    time,
    duration,
    date: _date
  } = nextState

  if (!date) {
    nextState.conflicts = false
    nextState.overlappingShowings = []
  } else if (restrictionsAreFetching || showingsAreFetching) {
    nextState.conflicts = state.conflicts
    nextState.overlappingShowings = state.overlappingShowings
  } else {
    const date = inUsersTimezone(_date).format('YYYY-MM-DD')
    const datetime = new Date(date + time.format().slice(10))

    nextState.conflicts = checkForConflicts(restrictions, datetime, duration)
    nextState.overlappingShowings = detectOverlappingShowings(showings, datetime, duration)
  }

  return nextState
}

export default reducerContainer
