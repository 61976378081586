import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAvailableAgents,
  addAgentToMyTeam,
  removeAgentFromMyTeam,
  clearAvailableAgents
} from "~brokerage/actions/showings";
import SidebarAgents from "~brokerage/components/shared/SidebarAgents";

const MIN_TEAM_PARTICIPANTS_COUNT = 1;

const MyTeam = ({
  showingId,
  agents,
  isFetching,
  requiredAgents,
  teamName,
  availableAgents,
  availableAgentsIsFetching,
  dispatch
}) => {
  const handleDropdownOpen = () => {};
  const [prevAgents, setPrevAgents] = useState(agents);
  let fetchAvailableAgentsTimeout;

  const updateAgents = () => {
    prevAgents.length != agents.length && dispatch(clearAvailableAgents());
    setPrevAgents(agents);
  };

  const handleAgentRemove = user => {
    const agent = agents.find(a => a.id === user.id);
    dispatch(removeAgentFromMyTeam(showingId, teamName, agent.id));
  };

  const handleAgentAdd = agentId => {
    const availableAgent = availableAgents.find(a => a.userId === agentId);
    const agent = {
      id: availableAgent.userId,
      name: availableAgent.name,
      phone: availableAgent.phone
    };
    dispatch(addAgentToMyTeam(showingId, teamName, agent.id));
  };

  const handleDropdownFilter = value => {
    clearTimeout(fetchAvailableAgentsTimeout);

    if (value.length > 2) {
      fetchAvailableAgentsTimeout = setTimeout(() => {
        dispatch(fetchAvailableAgents("showing", showingId, value));
      }, 200);
    }
  };

  const options = () => {
    if (availableAgentsIsFetching) {
      return [];
    }
    return availableAgents.map(a => ({
      label: `${a.name}`,
      value: a.userId
    }));
  };

  const canRemoveAgent = () => {
    return agents.length > MIN_TEAM_PARTICIPANTS_COUNT;
  };

  useEffect(updateAgents, [agents]);

  return (
    <SidebarAgents
      heading="My Team"
      agents={agents}
      isFetching={isFetching}
      requiredAgents={requiredAgents}
      dropdownAgents={options()}
      onDropdownOpen={handleDropdownOpen}
      onRemove={canRemoveAgent() ? handleAgentRemove : null}
      onDropdownFilter={handleDropdownFilter}
      onAdd={handleAgentAdd}
    />
  );
};

MyTeam.propTypes = {
  showingId: PropTypes.string,
  agents: PropTypes.array,
  isFetching: PropTypes.bool,
  requiredAgents: PropTypes.array,
  teamName: PropTypes.string,
  availableAgents: PropTypes.array,
  availableAgentsIsFetching: PropTypes.bool,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { entities: availableAgents } = state.showings.single.availableAgents;

  return { availableAgents };
}
export default connect(mapStateToProps)(MyTeam);
