import css from './Name.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'

const MAX_NAME_LENGTH = 30

export default class DirectMessageName extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    onNameChanged: PropTypes.func
  }

  state = {
    prevName: '',
    name: '',
    isEditing: false
  }

  componentWillMount() {
    if (this.props.name) this.setState({ name: this.props.name, prevName: this.props.name })
  }

  componentWillReceiveProps(nextProps) {
    const nextName = nextProps.name || ''

    if (nextName !== this.props.name) {
      this.setState({ name: nextName, prevName: nextName })
    }
  }

  editLinkClicked = () => {
    this.setState({isEditing: true})
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.nameChanged(event.target.value.trim())
    }
  }

  handleFocusLoss = (event) => {
    this.nameChanged(event.target.value.trim())
  }

  handleOnChange = (event) => {
    this.setState({name: event.target.value})
  }

  nameChanged(newName) {
    this.setState({isEditing: false, name: newName})
    if (this.props.onNameChanged && newName !== this.state.prevName) {
      let validNewName

      if (newName.length > MAX_NAME_LENGTH) {
        validNewName = newName.substr(0, MAX_NAME_LENGTH)
      } else {
        validNewName = newName
      }

      this.props.onNameChanged(validNewName)
    }
  }

  renderEditLink(text) {
    return(
      <a className={css.editLink} onClick={this.editLinkClicked}>
        <Icon name="edit" />
        {text}
      </a>
    )
  }

  renderLink() {
    const { name } = this.state
    if (!name) {
      return (
        <div className={css.wrapper}>
          {this.renderEditLink('Set Group chat name')}
        </div>
      )
    } else {
      return (
        <div className={css.wrapper}>
          <span className={css.text}>{name}</span>
          {this.renderEditLink('Edit')}
        </div>
      )
    }
  }

  renderInlineEdit() {
    return (
      <div>
        <input
          value={this.state.name}
          className={css.inlineEdit}
          type="text"
          ref={input => input && input.focus()}
          onFocus={
            ({target, target: { value }}) => target.setSelectionRange(value.length, value.length)
          }
          onKeyPress={this.handleKeyPress}
          onChange={this.handleOnChange}
          onBlur={this.handleFocusLoss}
        />
      </div>
    )
  }

  render() {
    const { isEditing } = this.state
    if (isEditing) {
      return this.renderInlineEdit()
    } else {
      return this.renderLink()
    }
  }
}
