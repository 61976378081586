import css from "./ListingCard.sass";
import React from "react";
import { Col } from "~brokerage/components/shared/Form";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";
import ImportedDetails from "~brokerage/components/shared/AppointmentDetailsModal/MainSection/ImportedDetails";
import { reasonText } from "~brokerage/constants/appointments";

const ListingCard = ({
  photoUrl,
  price,
  mls,
  appointmentReason = null,
  listingKey = null
}) => {
  return (
    <Col modifier="offset20 full600">
      <div className={css.card}>
        <PropertyThumbnail className={css.thumbnail} image={photoUrl} />
        {listingKey === null || listingKey === undefined ? (
          <div className={css.caption}>
            {!!appointmentReason && (
              <div>Showing Type: {reasonText[appointmentReason]}</div>
            )}
            <div>{formatPrice(price)}</div>
            <div>{`MLS# ${mls}`}</div>
          </div>
        ) : (
          <div className={css.importedDetails}>
            <ImportedDetails
              listingKey={listingKey}
              mlsId={mls}
              failoverDetails={{ price: price }}
            />
          </div>
        )}
      </div>
    </Col>
  );
};

export default ListingCard;
