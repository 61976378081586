import React, { useState, useEffect } from "react";

import Loader from "~brokerage/components/shared/Loader";
import { callApi } from "~brokerage/middlewares/api";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";

import css from "./ImportedDetails.sass";

import { removeNull } from "../utilsDetails";

const ImportedDetails = ({ listingKey, mlsId, failoverDetails }) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});

  useEffect(() => {
    !loading && setLoading(true);
    callApi(`appointment_details_import/${listingKey}`, {}, {}, "get")
      .then(response => {
        setDetails(response.data);
        setLoading(false);
      })
      .catch(() => {
        setDetails(null);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={css.importedDetailsContainer} style={{ height: "180px" }}>
        <Loader active />
      </div>
    );
  }

  if (details === null || details.length === 0 || details.errors) {
    return (
      <div className={css.importedDetailsContainer}>
        <b>MLS#:</b> {mlsId} <br />
        {failoverDetails.hasOwnProperty("price") && (
          <>
            <b>Price:</b> {formatPrice(failoverDetails.price)} <br />
          </>
        )}
        <p>More listing details are not available at the moment.</p>
      </div>
    );
  }

  const {
    ListPrice,
    CrossStreet,
    Directions,
    AccessCode,
    LockBoxLocation,
    OccupantType,
    ShowingInstructions,
    PrivateRemarks
  } = details;

  return (
    <div className={css.importedDetailsContainer}>
      {mlsId && <><b>MLS#</b> {mlsId} <br /></>}
      {ListPrice && <><b>Price:</b> {formatPrice(ListPrice)} <br /></>}
      {CrossStreet && <><b>Cross Street:</b> {removeNull(CrossStreet)} <br /></>}
      {Directions && <><b>Directions:</b> {removeNull(Directions)} <br /></>}
      {AccessCode && <><b>Gate Access Code:</b> {removeNull(AccessCode)} <br /></>}
      {LockBoxLocation && <><b>Lockbox Location:</b> {removeNull(LockBoxLocation)}{" "}
      <br /></>}
      {OccupantType && <><b>Occupancy:</b> {removeNull(OccupantType)} <br /></>}
      {ShowingInstructions && <><b>Showing Instructions:</b>{" "}
      {removeNull(ShowingInstructions)} <br /></>}
      {PrivateRemarks && <><b>Private Remarks:</b> {removeNull(PrivateRemarks)}{" "}</>}
    </div>
  );
};

export default ImportedDetails;
