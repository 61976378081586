import {
  MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_REQUEST,
  MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
  MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_FAILURE,
  UPDATE_UNREAD_NOTIFICATIONS
} from '~brokerage/constants/actionTypes'

function unreadCounters(state = { value: 0 }, action) {
  switch (action.type) {
    case MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_REQUEST:
      return { ...state, isFetching: true }
    case UPDATE_UNREAD_NOTIFICATIONS:
      return { ...state, value: action.count }
    case MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
      return { ...state, value: action.data.unreadNotifications.count, isFetching: false }
    case MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_FAILURE:
      return { ...state, isFetching: false } // TODO: error handling
    default:
      return state
  }
}

export default unreadCounters

