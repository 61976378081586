import {
  SET_CURRENT_USER,
  SHOW_LANDING_FOOTER,
  HIDE_LANDING_FOOTER,
  SET_MAPBOX_KEY
} from "~brokerage/constants/actionTypes";

export function setCurrentUser(currentUser) {
  return {
    type: SET_CURRENT_USER,
    currentUser
  };
}

export function showLandingFooter() {
  return {
    type: SHOW_LANDING_FOOTER
  };
}

export function hideLandingFooter() {
  return {
    type: HIDE_LANDING_FOOTER
  };
}

export function setMapboxKey(apiKey) {
  return {
    type: SET_MAPBOX_KEY,
    apiKey
  };
}
