export const DIRECT    = 'direct'
export const MESSAGES  = 'messages'
export const BROADCAST = 'broadcast'
export const HELPDESK  = 'helpdesk'
export const SHOWINGS  = 'showings'
export const SHOWING   = 'showing'

import * as messages from '~brokerage/actions/messages'

export default class BaseMessageHandler {
  constructor(message, props) {
    this.message = message
    this.props   = props
    this.path    = props.location.pathname.split('/')
  }

  handle() {
    this.updateConversationsList()
    this.updateUnreadMessagesCounters()
  }

  handleMessagesPageMessage(messageType) {
    this.props.dispatch(
      messages.updateLastMessageForConversation(
        messageType,
        this.message
      )
    )
    this.props.dispatch(messages.addMessageFromPubnub(this.message))
    this.markConversationAsRead()
  }

  updateConversationsList() {
    this.props.dispatch(
      messages.updateConversationsList(
        this.message,
        this.message.conversationData.type,
        this.isMessageRead()
      )
    )
  }

  markConversationAsRead() {
    this.props.dispatch(
      messages.markConversationAsRead(
        this.message.conversationData.id,
        this.message.conversationData.type
      )
    )
  }

  updateUnreadMessagesCounters() {
    this.props.dispatch(messages.fetchUnreadMessagesCount())
  }
}
