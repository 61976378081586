import css from './DeliveryTip.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'

export default class DeliveryTip extends React.PureComponent {
  static propTypes = {
    elRef: PropTypes.func
  }

  render() {
    const { elRef } = this.props

    return (
      <div className={css.base} ref={el => elRef && elRef(el)}>
        <div className={css.inner}>
          <div className={css.icon}>
            <Icon name="info"/>
          </div>
          <div className={css.text}>
            If recipients do not have Aligned Showings, messages are sent to them through email.
          </div>
        </div>
      </div>
    )
  }
}
