export const buildInstructionsPayload = ({
  mlsBuyerRequiredEnabled,
  showingInstructions: {
    accompanyAgent,
    accompanySeller,
    callbackRequest,
    noShowReason,
    access,
    brand,
    combo,
    requestHandling,
    message,
    initialMessage,
    allowShowingOverlap,
    maxAppointmentDuration,
    showingEnabledByMls,
    advanceNoticeDuration,
    advanceNoticeDisableSameDayRequests,
    maxOverlappingShowingsCount,
    buyerNameRequired
  }
}) => {
  return {
    listing_agent_will_accompany: accompanyAgent,
    tenant_will_accompany: accompanySeller,
    request_a_call: callbackRequest,
    explanation: noShowReason,
    lockbox_kind: access,
    lockbox_brand: brand,
    lockbox_code: combo,
    request_handling: requestHandling,
    message,
    initial_message: initialMessage,
    allow_showing_overlap: allowShowingOverlap,
    max_appointment_duration: maxAppointmentDuration,
    showing_enabled_by_mls: showingEnabledByMls,
    advance_notice_duration: advanceNoticeDuration,
    advance_notice_disable_same_day_requests:
      advanceNoticeDisableSameDayRequests,
    max_overlapping_showings_count: maxOverlappingShowingsCount,
    ...(mlsBuyerRequiredEnabled
      ? { buyer_name_required: buyerNameRequired }
      : {})
  };
};
