const CATEGORY_LABELS = {
  my_team: "People in My Team",
  brokerage: "People in My Office",
  my_company: "People in My Company",
  all_agents: "Agents",
  customers: "Customers",
  seller_tenant: "People in My Sellers",
  my_accounts: "People in My Accounts",
  buyer: "People in My Buyers"
};
import css from "./PeopleCounter.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";

class PeopleCounter extends React.PureComponent {
  static propTypes = {
    peopleCounts: PropTypes.object,
    location: PropTypes.object
  };

  categoryLabel() {
    const category = this.currentCategory();

    return CATEGORY_LABELS[category];
  }

  currentCategory() {
    return this.props.location.query.category || "all_agents";
  }

  peopleCount() {
    const category = this.currentCategory();
    const { peopleCounts } = this.props;

    if (peopleCounts) {
      return peopleCounts[category];
    } else {
      return void 0;
    }
  }

  renderCounter() {
    const count = this.peopleCount();
    const isArchived = this.props.location.query.is_archive;

    if (count !== null && count !== void 0) {
      return `${formatNumberUsingCommas(count)} ${this.categoryLabel()} ${
        isArchived === "true" ? "(Archived)" : ""
      }`;
    } else {
      return null;
    }
  }

  render() {
    return <div className={css.base}>{this.renderCounter()}</div>;
  }
}

function mapStateToProps(state) {
  const { entity: peopleCounts } = state.people.list.counts;

  return { peopleCounts };
}
export default connect(mapStateToProps)(PeopleCounter);
