import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContactDetails from './components/ContactDetails'
import SidebarAgents from '~brokerage/components/shared/SidebarAgents'
import { HELPDESK_CONVERSATION_TYPE, BROADCAST_CONVERSATION_TYPE } from '~brokerage/constants/messages'
import {
  fetchConversationParticipants,
  fetchAvailableParticipants,
  addAgentToTeam,
  removeAgentFromTeam
} from '~brokerage/actions/messages'

const MIN_PARTICIPANTS_COUNT = 2

export default class Sidebar extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    dispatch: PropTypes.func,
    role: PropTypes.string,
    isFetching: PropTypes.bool,
    availableParticipants: PropTypes.array,
    conversationParticipants: PropTypes.array,
    conversationParticipantsAreFetching: PropTypes.bool
  }

  static contextTypes = {
    location: PropTypes.object
  }

  componentWillMount() {
    const { type, id, role } = this.props
    this.props.dispatch(fetchConversationParticipants(type, id, { role }))
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.props
    const { type: nextType, id: nextId, role: nextRole } = nextProps

    if (nextId && nextId != id)
      this.props.dispatch(fetchConversationParticipants(nextType, nextId, { role: nextRole }))
  }

  handleDropdownOpen = () => {

  }

  handleAgentRemove = (user) => {
    const { type, id } = this.props
    const agent = this.props.conversationParticipants.find((p) => p.userId == user.userId)
    this.props.dispatch(removeAgentFromTeam(type, id, agent.userId))
  }

  handleAgentAdd = (userId) => {
    const { type, id } = this.props
    this.props.dispatch(addAgentToTeam(type, id, userId))
  }

  handleDropdownFilter = (value) => {
    if (value.length > 2) {
      const { type, id } = this.props
      this.props.dispatch(fetchAvailableParticipants(type, id, value))
    }
  }

  isOnSupportConversation() {
    return [HELPDESK_CONVERSATION_TYPE, BROADCAST_CONVERSATION_TYPE].includes(this.props.type)
  }

  canRemove() {
    return !this.isOnSupportConversation() && this.props.conversationParticipants.length > MIN_PARTICIPANTS_COUNT
  }

  get options() {
    if (this.props.isFetching) {
      return []
    }

    return this.props.availableParticipants.map(a => ({
      value: a.userId,
      label: a.name
    }))
  }

  render() {
    return (
      <div>
        <div className={css.participants}>
          <SidebarAgents
            heading="Participants"
            agents={this.props.conversationParticipants}
            isFetching={this.props.conversationParticipantsAreFetching}
            dropdownAgents={this.options}
            onDropdownOpen={this.handleDropdownOpen}
            onRemove={this.canRemove() ? this.handleAgentRemove : null}
            onDropdownFilter={this.handleDropdownFilter}
            onAdd={this.isOnSupportConversation() ? null : this.handleAgentAdd}
            conversationType={this.props.type}
          />
        </div>

        <div className={css.sectionWithBorder}>
          <h4 className={css.h4}>Contact Details</h4>
          {(() => {
            return this.props.conversationParticipants.map(p => {
              return (
                <div className={css.sectionWithBottomBorder} key={p.userId}>
                  <ContactDetails id={p.userId} name={p.name} phone={p.phone} email={p.email}/>
                </div>
              )
            })
          })()}
        </div>
      </div>
    )
  }
}
