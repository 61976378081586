import { CALL_API } from "~brokerage/middlewares/api";

export function fetchPersonCounts(filter, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "person/counts",
      params: { filter }
    }
  };
}

export function fetchPersonCategories(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `person/categories`
    }
  };
}

export function fetchCustomerCategories(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `my_contacts/categories`
    }
  };
}

export function fetchCustomerShowings(contactId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `my_contacts/${contactId}/showings`
    }
  };
}

export function fetchCustomerListings(contactId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `my_contacts/${contactId}/listings`
    }
  };
}
