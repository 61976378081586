import css from './index.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearSelectedListing, clearSelectedAgent } from '~brokerage/actions/newShowing'
import {
  STEP_DONE as DONE,
  STEP_EDITING as EDITING,
  STEP_ACTIVE as ACTIVE,
  SELECT_LISTING,
  SELECT_SHOWING_AGENT,
  SELECT_DATE_AND_TIME
} from '~brokerage/constants/showings/newShowingSections'
import Agent from './Agent'
import Listing from './Listing'
import Step from './Step'

const Steps = ({ selectedListing, selectedAgent, step, dispatch }) => {
  const firstStepVariant = selectedListing.id ?
      (step === SELECT_LISTING ? EDITING : DONE) :
      (step === SELECT_LISTING ? ACTIVE : void 0)

  const secondStepVariant = selectedAgent ?
      (step === SELECT_SHOWING_AGENT ? EDITING : DONE) :
      (step === SELECT_SHOWING_AGENT ? ACTIVE : void 0)

  const thirdStepVariant = step === SELECT_DATE_AND_TIME ? ACTIVE : void 0

  return (
    <ul className={css.ul}>
      <Step
        index="1"
        doneText="Listing"
        placeholder="Select Listing"
        onHeadingDoneClick={() => { dispatch(clearSelectedListing()) }}
        variant={firstStepVariant}
      >
        {Boolean(selectedListing.id) && <Listing step={firstStepVariant}/>}
      </Step>
      <Step
        index="2"
        doneText="Showing Agent"
        placeholder="Select Showing Agent"
        variant={secondStepVariant}
        onHeadingDoneClick={() => { dispatch(clearSelectedAgent()) }}
      >
        {Boolean(selectedAgent) && <Agent step={secondStepVariant} agent={selectedAgent}/>}
      </Step>
      <Step
        index="3"
        placeholder="Select Date and Time"
        variant={thirdStepVariant}
      />
    </ul>
  )
}

Steps.propTypes = {
  step: PropTypes.number,
  selectedListing: PropTypes.object,
  isSelectedListingFetching: PropTypes.bool,
  selectedAgent: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  const { entity: selectedListing, isFetching: isSelectedListingFetching } = state.showings.new.selectedListing
  const { selectedAgent } = state.showings.new

  return {
    selectedListing,
    isSelectedListingFetching,
    selectedAgent,
    step: state.showings.new.step
  }
}
export default connect(mapStateToProps)(Steps)
