import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import differenceWith from "lodash/differenceWith";
import some from "lodash/some";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";
import {
  fetchSellerTenantInvolvement,
  sendSellerTenantInvolvementBatchUpdate
} from "~brokerage/actions/listings";
import { Row, Label, Controls } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import People from "./People";
import css from "~brokerage/components/listings/edit/SellerTenantInvolvement/index.sass";
export class SellerTenantInvolvement extends React.PureComponent {
  static propTypes = {
    showingDetails: PropTypes.object,
    entities: PropTypes.array,
    originalEntities: PropTypes.array,
    onNextClick: PropTypes.func,
    onChange: PropTypes.func,
    dispatch: PropTypes.func
  };

  state = {
    hasChanged: false
  };

  componentDidMount() {
    if (this.props.listingId) {
      this.props.dispatch(fetchSellerTenantInvolvement(this.props.listingId));
    }
  }

  updateHasChanged = () => {
    if (!this.state.hasChanged) this.setState({ hasChanged: true });
  };

  saveData = () => {
    const { listingId } = this.props.showingDetails;
    const { entities, originalEntities } = this.props;
    const payload = deepObjectKeysToSnakeCase({
      create: entities.filter(
        ({ uuid }) => !uuid || !some(originalEntities, { uuid })
      ),
      update: entities.filter(
        x =>
          !!x.uuid && some(originalEntities, y => y.uuid === x.uuid && y !== x)
      ),
      remove_ids: differenceWith(
        originalEntities,
        entities,
        (a, b) => a.uuid === b.uuid
      ).map(x => x.uuid)
    });

    this.props.dispatch(
      sendSellerTenantInvolvementBatchUpdate(listingId, payload)
    );
  };

  handleSave = (callNextClick = true) => {
    if (this.state.hasChanged) this.saveData();
    if (callNextClick) this.props.onNextClick();
  };

  render() {
    return (
      <div>
        <Row offset="0">
          <Label>
            Add a designated Seller/Tenant to be part of the appointment process
          </Label>
          <div className={css.peopleComponentControls}>
            <Controls>
              <People
                listingId={this.props.showingDetails.listingId}
                onChange={this.updateHasChanged}
              />
            </Controls>
          </div>
        </Row>
        <Row>
          <ButtonSet align="right">
            <Button variant="primary" onClick={this.handleSave}>
              Done
            </Button>
          </ButtonSet>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entities, originalEntities } =
    state.listings.single.sellerTenantInvolvement;
  const { entity: showingDetails } = state.listings.single.showingDetails;
  const { id: listingId } = state.listings.single.listing.entity;

  return {
    entities,
    originalEntities,
    showingDetails,
    listingId
  };
}
export default connect(mapStateToProps, null, null, { withRef: true })(
  SellerTenantInvolvement
);
