import { CALL_API } from "~brokerage/middlewares/api";

export function fetchPeople(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "people",
      params: params
    }
  };
}

export function fetchPersonDetails(personId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `people/${personId}`
    }
  };
}

export function fetchContactDetails(personId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `my_contacts/${personId}`
    }
  };
}

export function setQuery(q, type) {
  return {
    type,
    q
  };
}

export function fetchPersonListings(personId, page, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `people/${personId}/listings`,
      params: { page }
    }
  };
}
