import css from "./edit.sass";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  fetchShowingDetails,
  fetchListingTeamMembers,
  fetchShowingInstructions,
  updateShowingDetailsChanges,
  changeShowingsDetailsStep
} from "~brokerage/actions/listings";
import { processChange } from "~brokerage/libs/helpers/FormHelper";
import {
  SHOWING_INSTRUCTION_FOR_SHOWING_AGENTS_STEP,
  OFFICE_INVOLVEMENT_STEP,
  SELLER_TENANT_INVOLVEMENT_STEP,
  TIME_DATE_RESTRICTION_STEP,
  FEEDBACK_SURVEY_STEP
} from "~brokerage/constants/listings/sections";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Sections from "~brokerage/components/listings/edit/Sections";
import Section from "~brokerage/components/listings/edit/Section";
import TimeDateRestrictions from "~brokerage/components/listings/edit/TimeDateRestrictions";
import Instructions from "~brokerage/components/listings/edit/Instructions";
import OfficeInvolvement from "~brokerage/components/listings/edit/OfficeInvolvement";
import SellerTenantInvolvement from "~brokerage/components/listings/edit/SellerTenantInvolvement";
import FeedbackSurvey from "~brokerage/components/listings/edit/FeedbackSurvey";
import ActivityReportDownloadModal from "~brokerage/components/shared/ActivityReportDownloadModal";

class EditListing extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    showingDetails: PropTypes.object,
    teamMembers: PropTypes.array,
    showingInstructions: PropTypes.object,
    listingsStatus: PropTypes.string,
    isFetching: PropTypes.bool,
    errors: PropTypes.bool,
    children: PropTypes.node,
    match: PropTypes.object,
    step: PropTypes.number,
    dispatch: PropTypes.func
  };

  state = {
    isDownloadActivityReportModalOpen: false
  };

  componentWillMount() {
    this.props.dispatch(fetchShowingDetails(this.props.match.params.listingId));
    this.props.dispatch(
      fetchListingTeamMembers(this.props.match.params.listingId)
    );
    this.props.dispatch(
      fetchShowingInstructions(this.props.match.params.listingId)
    );
  }

  componentWillUnmount() {
    this.props.dispatch(changeShowingsDetailsStep(0));
    this.handleSaveForCurrentForm(false);
  }

  handleNextClick = () => {
    // +2 because I -1 for natural order
    this.changeStep(this.props.step + 2);
  };

  changeStep(newStep) {
    const { history, location } = this.props;
    history.push({
      pathname: location.pathname,
      query: { ...location.query, step: newStep }
    });
  }

  handleDoneClick = () => {
    const { listingsStatus, history } = this.props;
    if (listingsStatus) {
      history.push(`/listings?property_statuses=${listingsStatus}`);
    } else {
      history.push("/listings");
    }
  };

  handleChange = event => {
    const payload = processChange(event);
    this.props.dispatch(updateShowingDetailsChanges(payload));
  };

  handleSectionClick(step) {
    this.handleSaveForCurrentForm(true);
    this.changeStep(step);
  }

  handleSaveForCurrentForm(callNextClick) {
    if (this.refs.child)
      this.refs.child.getWrappedInstance().handleSave(callNextClick);
  }

  afterSaveHandler = () => {
    this.handleNextClick();
  };

  handleDownloadActivityReportModalClose = () =>
    this.setState({ isDownloadActivityReportModalOpen: false });

  render() {
    const { showingDetails, showingInstructions, sellers } = this.props;
    const listingId = this.props.match.params.listingId;

    return (
      <div className={css.base}>
        <div className={css.header}>
          <h1>Listing Setup</h1>
          <div className={css.buttons}>
            <div className={css.downloadActivityReport}>
              <Button
                variant="outline"
                onClick={() =>
                  this.setState({ isDownloadActivityReportModalOpen: true })
                }
              >
                Activity Report
              </Button>
            </div>
            <Button to={`/listings/${listingId}`} variant="outline">
              <Icon name="arrowLeftRound" /> Exit Edit Mode
            </Button>
          </div>
        </div>

        {(() => {
          if (showingInstructions.entity && showingDetails) {
            return (
              <Sections step={this.props.step}>
                <Section
                  onClick={this.handleSectionClick.bind(
                    this,
                    SHOWING_INSTRUCTION_FOR_SHOWING_AGENTS_STEP
                  )}
                  heading="Showing Instructions for Showing Agent"
                >
                  <Instructions
                    listingId={listingId}
                    afterSaveHandler={this.afterSaveHandler}
                    dispatch={this.props.dispatch}
                    ref="child"
                  />
                </Section>

                <Section
                  onClick={this.handleSectionClick.bind(
                    this,
                    OFFICE_INVOLVEMENT_STEP
                  )}
                  heading="Office Involvement"
                >
                  <OfficeInvolvement
                    people={this.props.teamMembers}
                    showingDetails={showingDetails}
                    onNextClick={this.handleNextClick}
                    ref="child"
                  />
                </Section>

                <Section
                  onClick={this.handleSectionClick.bind(
                    this,
                    SELLER_TENANT_INVOLVEMENT_STEP
                  )}
                  heading="Seller/Tenant Involvement"
                  optional
                >
                  <SellerTenantInvolvement
                    onNextClick={this.handleNextClick}
                    ref="child"
                  />
                </Section>

                <Section
                  onClick={this.handleSectionClick.bind(
                    this,
                    TIME_DATE_RESTRICTION_STEP
                  )}
                  heading="Time/Date Restrictions"
                  optional
                >
                  <TimeDateRestrictions
                    showingDetails={showingDetails}
                    onNextClick={this.handleNextClick}
                  />
                </Section>
                <Section
                  onClick={this.handleSectionClick.bind(
                    this,
                    FEEDBACK_SURVEY_STEP
                  )}
                  heading="Feedback Survey"
                  optional
                >
                  <FeedbackSurvey
                    listingId={listingId}
                    showingInstructions={showingInstructions}
                    onNextClick={this.handleDoneClick}
                  />
                </Section>
              </Sections>
            );
          }
        })()}
        <ActivityReportDownloadModal
          isOpen={this.state.isDownloadActivityReportModalOpen}
          onClose={this.handleDownloadActivityReportModalClose}
          dispatch={this.props.dispatch}
          listingId={listingId}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    entity: showingDetails,
    isFetching,
    errors,
    step
  } = state.listings.single.showingDetails;
  const { entities: teamMembers } = state.listings.single.teamMembers;
  const showingInstructions = state.listings.single.showingInstructions;
  const { name: listingsStatus } = state.listings.statuses.current;

  return {
    showingDetails,
    teamMembers,
    showingInstructions,
    listingsStatus,
    isFetching,
    errors,
    step
  };
}
export default connect(mapStateToProps)(EditListing);
