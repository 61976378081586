import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";

import css from "./index.sass";
import { Header, Footer } from "~brokerage/components/modals/BaseModal";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Button from "~brokerage/components/shared/Button";
import Tooltip from "~brokerage/components/shared/Tooltip";
import {
  convertSurveyToRequestBody,
  emptySurvey,
  isSurveyIncomplete
} from "./utils";
import { callApi } from "~brokerage/middlewares/api";
import Loader from "~brokerage/components/shared/Loader";
import SurveyForm from "./SurveyForm";

const EditSurveyModal = ({
  surveyId,
  closeModal,
  originalSurvey,
  isFetching,
  onUpdate
}) => {
  Modal.setAppElement(document.body);
  const isNewSurvey = surveyId === "new";
  const isDefaultSurvey = surveyId === "default";
  const surveyTypeTitle = isDefaultSurvey
    ? "Default"
    : isNewSurvey
    ? "Create"
    : "Edit";
  const [survey, setSurvey] = useState(
    isNewSurvey ? cloneDeep(emptySurvey) : null
  );

  const handleSurveyUpdate = updatedSurvey => {
    setSurvey(updatedSurvey);
  };

  const handleSave = (saveAsNew = false) => {
    const body = convertSurveyToRequestBody(survey, saveAsNew);
    const [api, type] =
      isNewSurvey || saveAsNew
        ? ["surveys", "post"]
        : [`surveys/${surveyId}`, "patch"];
    callApi(api, {}, body, type)
      .then(res => onUpdate(res.data))
      .catch(err =>
        toast.error(err.response.data.message || "Something went wrong.")
      );
    closeModal();
  };

  useEffect(() => {
    if (!isNewSurvey && originalSurvey) setSurvey(originalSurvey);
  }, [originalSurvey]);

  const disableDone = useMemo(() => isSurveyIncomplete(survey), [survey]);
  const disableSurveyEdit = survey?.is_editable === false;

  return (
    <Modal isOpen onRequestClose={closeModal} style={customStyles}>
      <Header title={`${surveyTypeTitle} survey`} onClose={closeModal} />
      <div className={css.surveyWrapper}>
        {!!survey && (
          <SurveyForm
            survey={survey}
            setSurvey={handleSurveyUpdate}
            isDefaultSurvey={isDefaultSurvey}
          />
        )}
        <Loader active={isFetching} />
      </div>
      {!isDefaultSurvey && (
        <Footer>
          <ButtonSet align="right">
            <Tooltip
              disabled={!disableSurveyEdit && !disableDone}
              text={
                disableSurveyEdit
                  ? "Survey belongs to a different user."
                  : disableDone || ""
              }
              direction="up"
              variant="error"
            >
              <Button
                type="button"
                variant="primary"
                disabled={disableSurveyEdit || !!disableDone}
                onClick={() => handleSave()}
              >
                {`${isNewSurvey ? "Create" : "Save"}`}
              </Button>
            </Tooltip>
            {!isNewSurvey && (
              <Tooltip
                disabled={!disableDone}
                text={disableDone || ""}
                direction="up"
                variant="error"
              >
                <Button
                  type="button"
                  variant="outlineBlue"
                  disabled={!!disableDone}
                  onClick={() => handleSave(true)}
                >
                  Save as new
                </Button>
              </Tooltip>
            )}
          </ButtonSet>
        </Footer>
      )}
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70vw"
  },
  overlay: { zIndex: 100 }
};

const mapStateToProps = state => {
  const { entity: originalSurvey, isFetching } = state.surveys.single;

  return {
    originalSurvey,
    isFetching
  };
};

export default connect(mapStateToProps)(EditSurveyModal);
