import React from "react";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil.jsx";
import PropTypes from "prop-types";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";

const Showing = ({
  unique_id,
  address,
  start_time,
  duration,
  listing_image,
  css,
  status,
  setShownDetailsId,
  getStatusDisplay
}) => {
  const startTime = inUsersTimezone(start_time).format("LT");
  const endTime = inUsersTimezone(start_time)
    .add(duration, "minutes")
    .format("LT");
  return (
    <div onClick={() => setShownDetailsId(unique_id)} className={css.listItem}>
      <PropertyThumbnail
        image={listing_image}
        className={css.appointmentImage}
      />
      <div>{address}</div>
      <div>{`${startTime} - ${endTime}`}</div>
      <div className={css.status}>{getStatusDisplay(status)}</div>
    </div>
  );
};

Showing.propTypes = {
  unique_id: PropTypes.string,
  address: PropTypes.string,
  start_time: PropTypes.string,
  duration: PropTypes.number,
  listing_image: PropTypes.string,
  setShownDetailsId: PropTypes.func,
  css: PropTypes.object
};

export default Showing;
