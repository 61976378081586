import {
  CUSTOMER_SHOWINGS_REQUEST,
  CUSTOMER_SHOWINGS_SUCCESS,
  CUSTOMER_SHOWINGS_FAILURE
} from "~brokerage/constants/actionTypes";

const initialState = {
  entities: [],
  isFetching: false
};

export default function showings(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_SHOWINGS_REQUEST:
      return { ...state, isFetching: true };
    case CUSTOMER_SHOWINGS_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.showings };
    case CUSTOMER_SHOWINGS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    default:
      return state;
  }
}
