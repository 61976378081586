import css from './index.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Item from './Item'

const List = ({ variant = 'base', modifier, heading, children }) =>
  <div className={composeClassName(css, variant, modifier)}>
    {heading &&
      <h4 className={css.heading}>{heading}</h4>
    }
    <ul className={css.ul}>
      {React.Children.map(children, (child) => {
        if (!child || child.type !== Item) {
          return child
        }

        return React.cloneElement(child, { variant: child.props.variant || variant })
      })}
    </ul>
  </div>

List.propTypes = {
  variant: PropTypes.string,
  modifier: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.node
}

List.Item = Item
export default List
