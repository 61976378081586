export const variants = {
  addAppointments: {
    modal: "add_route_appointments",
    buttonLabel: "Add to Route",
    showBadge: true
  },
  showing: {
    modal: "create_showing",
    buttonLabel: "Schedule Showing",
    showBadge: false
  },
  route: {
    modal: "create_route",
    buttonLabel: "Add to Route",
    showBadge: true
  }
};
