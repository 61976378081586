import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { formatClipboardData } from '~brokerage/libs/helpers/FormatHelper'
import List from '~brokerage/components/people/list/List'
import ListHeader from '~brokerage/components/people/list/ListHeader'

export default class PeopleList extends PureComponent {
  static propTypes = {
    location: PropTypes.object
  }

  componentWillMount() {
    window.addEventListener('copy', this.handleCopy)
  }

  componentWillUnmount() {
    window.removeEventListener('copy', this.handleCopy)
  }

  alterClipboard() {
    const selection = window.getSelection()
    const tmpDiv = document.createElement('div')
    tmpDiv.style.position = 'absolute'
    tmpDiv.style.left = '-99999px'
    document.body.appendChild(tmpDiv)
    tmpDiv.appendChild(selection.getRangeAt(0).cloneContents())

    tmpDiv.innerHTML = formatClipboardData(tmpDiv.innerHTML)

    selection.selectAllChildren(tmpDiv)

    window.setTimeout(function () {
      document.body.removeChild(tmpDiv)
    }, 200)
  }

  handleCopy = (_event) => {
    if (window.getSelection) {
      this.alterClipboard()
    }
  }

  render() {
    return (
      <div>
        <ListHeader location={this.props.location} />
        <List location={this.props.location} />
      </div>
    )
  }
}
