import { PROPERTY_SEARCH_UPDATE_SELECTED } from "~brokerage/constants/actionTypes";

const defaultState = {
  listings: []
};

function selected(state = defaultState, action) {
  switch (action.type) {
    case PROPERTY_SEARCH_UPDATE_SELECTED:
      return { ...state, listings: action.update };
    default:
      return state;
  }
}

export default selected;
