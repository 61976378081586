import React, { useState } from "react";
import { toast } from "react-toastify";

import { Dropdown } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import { callApi } from "~brokerage/middlewares/api";
import { reasonText } from "~brokerage/constants/appointments";

const EditType = ({
  appointmentReason,
  showingId,
  setEditing,
  onTypeChangeSuccess
}) => {
  const [type, setType] = useState(appointmentReason);
  const [updating, setUpdating] = useState(false);

  const appointmentReasons = Object.entries(reasonText).map(
    ([value, label]) => ({
      label,
      value
    })
  );

  const sendUpdateRequest = () => {
    setUpdating(true);
    callApi(
      `showings/${showingId}/update_reason`,
      {},
      { appointment_reason: type },
      "patch"
    )
      .then(
        () => {
          toast.success("Type Updated.");
          onTypeChangeSuccess(type);
        },
        error => {
          toast.error(error.response?.data?.error || "Error updating type.");
        }
      )
      .finally(() => {
        setEditing(false);
      });
  };

  const blockStyle = { display: "block", textAlign: "center" };
  const typeChanged = type !== appointmentReason;

  return (
    <>
      <Dropdown
        name="type"
        options={appointmentReasons}
        selected={type}
        onChange={({ type }) => setType(type)}
        variant="outline2Block"
      />
      {typeChanged && (
        <Button
          variant="outlineBlue"
          style={blockStyle}
          disabled={updating}
          onClick={sendUpdateRequest}
        >
          Save
        </Button>
      )}
    </>
  );
};

export default EditType;
