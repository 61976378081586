import React from "react";
import css from "./ShareRoute.sass";
import { getMapLink, getTiming } from "./util/shareUtil";
import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import AppLogo from "~brokerage/components/shared/AppLogo";
import { ALPHABET } from "~brokerage/constants/routes/letters";

const ShareRoute = ({ route, showings }) => {
  const { name, date_display, day } = route;
  return (
    <div className={css.container}>
      <AppLogo className={css.appLogo} variant="black" scale="2" />
      <p>
        <strong>Route Name:</strong> {name}
      </p>
      <p>
        <strong>Date:</strong> {day}, {date_display}
      </p>
      <strong>Showings:</strong>
      {showings.map((showing, index) => (
        <RouteStop data={showing} index={index} key={`showing-${index}`} />
      ))}
    </div>
  );
};

const RouteStop = ({ data, index }) => {
  const {
    address,
    coordinates: { lat, long },
    price,
    start_time,
    listing_image,
    duration,
    type,
    listing_id
  } = data;
  const isStop = type === "stop";
  const hasValidGeo = validateLatLng(lat, long);
  const mapLink = hasValidGeo ? getMapLink({ lat, long }) : "";
  const label = hasValidGeo && ALPHABET[index];
  return (
    <div className={css.row}>
      <p>{label ? label : index + 1}.</p>
      <div className={css.showing}>
        <div className={css.content}>
          <p>{getTiming(start_time, duration)}</p>
          {price && <p>Price: {formatPrice(price)}</p>}
          <strong>{isStop ? "Non MLS stop" : `MLS#: ${listing_id}`}</strong>
          <p>Address: {address}</p>
          {hasValidGeo && (
            <p>
              Map: <a href={mapLink}>{mapLink}</a>
            </p>
          )}
        </div>
        <img src={listing_image} className={css.showingImage} />
      </div>
    </div>
  );
};

export default ShareRoute;
