export const SHOW_FEEDBACK_ANALYSIS_TOOLTIP =
  "This feature will analyse the survey response and attempt to indicate if it may be FAVORABLE (green) or NON-FAVORABLE (red). Results will vary.  This is offered as an optional guide and may not be 100% based on the response given.";
export const ADVANCE_NOTICE_REQUIREMENTS_TOOLTIP =
  "This setting allows you to (1) prevent same day showing requests, or (2) require a minimum amount of advanced notice or lead time before a showing can be requested. For example, if advanced notice is set to 6 hours and it is 10:00 AM, the earliest available time slot to request a showing would be 4:00 PM";
export const FEEDBACK_ATTEMPT_TOOLTIP =
  "Feedback request notifications are sent to the showing agent one hour after the showing has concluded, with a reminder sent one day after the showing. Use this setting to send additional feedback request reminders, which will be sent once per day.";
export const REQUIRE_APPROVAL = "require_approval";
export const INSTANT_APPROVED = "instant_approved";
export const INSTANT_DECLINED = "instant_declined";

export const SLIDER_MIN_RANGE = 0;
export const SLIDER_MAX_RANGE = 144;
export const SLIDER_HANDLE_STYLE = {
  borderColor: "#007bff",
  backgroundColor: "#fff",
  zIndex: 1
};
export const SLIDER_RAIL_STYLE = { backgroundColor: "#ccc" };
export const SLIDER_COLOR_BLUE = "#007bff";
export const SLIDER_COLOR_BLACK = "#000";

export const DEFAULT_FEEDBACK_ATTEMPT_OPTION = {
  value: 2,
  label: "2 reminders"
};

export const FEEDBACK_ATTEMPT_OPTIONS = [
  DEFAULT_FEEDBACK_ATTEMPT_OPTION,
  { value: 3, label: "3 reminders" },
  { value: 4, label: "4 reminders" },
  { value: 5, label: "5 reminders" },
  { value: 6, label: "6 reminders" }
];
