import css from './ModalSavedReplies.sass'
import React, { Component } from 'react'

export default class ModalSavedReplies extends React.PureComponent {
  render() {
    return (
      <div className={css.base}>
        SAVED REPLIES PLACEHOLDER
      </div>
    )
  }
}
