import {
  TEAM_MEMBER_NOTIFICATION_LEVELS_THAT_ALLOW_CALENDAR_INVITE,
  TEAM_MEMBER_NOTIFICATION_LEVELS
} from "./constants";
import { findIndex } from "lodash";

export const buildFormData = person => {
  return {
    notificationLevel:
      person?.notificationLevel || TEAM_MEMBER_NOTIFICATION_LEVELS[0].value,
    includeCalendarEvent: person?.includeCalendarEvent ?? true,
    selectedAgent: person
  };
};

export const buildAvailableMembers = (availableMembers, teamMembers) => {
  const members = availableMembers.filter(m => {
    return (
      findIndex(teamMembers, tm => {
        return tm.agentId === m.agentId;
      }) === -1
    );
  });

  return members.map(agent => ({
    value: agent.agentId,
    label: agent.name
  }));
};

export const shouldIncludeCalendarEvent = value =>
  TEAM_MEMBER_NOTIFICATION_LEVELS_THAT_ALLOW_CALENDAR_INVITE.includes(value);

export const getNotificationLevelLabel = (
  notificationLevel,
  notificationLevels
) => {
  const notificationLevelIndex = findIndex(
    notificationLevels,
    e => e.value === notificationLevel
  );

  return notificationLevelIndex !== -1
    ? notificationLevels[notificationLevelIndex].label
    : null;
};

export const getAgentFullName = ({ firstName, lastName }) =>
  `${firstName} ${lastName}`;
