import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class Row extends Component {
  static propTypes = {
    offset: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { offset, children } = this.props

    return (
      <div className={css[`rowOffset${offset || '20'}`]} {...this.props}>
        {children}
      </div>
    )
  }
}
