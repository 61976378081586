import css from "./index.sass";
import React from "react";
import Main from "./Main";

const Timeline = ({
  staticAppointments,
  activeAppointments,
  showRestrictions,
  initiallyActiveListing,
  maxHeight,
  updateForTravelTime,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam = false,
  updateOnlyLastItem = false
}) => {
  return (
    <div className={css.base}>
      <Main
        initiallyActiveListing={initiallyActiveListing}
        showRestrictions={showRestrictions}
        staticAppointments={staticAppointments}
        activeAppointments={activeAppointments}
        maxHeight={maxHeight}
        updateForTravelTime={updateForTravelTime}
        updateOnlyLastItem={updateOnlyLastItem}
        appointmentReason={appointmentReason}
        isListingOwnedByCurrentUserOrOfficeTeam={
          isListingOwnedByCurrentUserOrOfficeTeam
        }
      />
    </div>
  );
};

export default Timeline;
