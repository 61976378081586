import css from './Search.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'

export default class Search extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  }

  render() {
    const {placeholder, onChange } = this.props

    return (
      <div className={css.base}>
        <input
          className={css.input}
          type="search"
          placeholder={placeholder || 'Search'}
          onChange={onChange}
        />
        <Button variant="search"><Icon name="search"/></Button>
      </div>
    )
  }
}
