import css from './Agent.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatPhone } from '~brokerage/libs/helpers/FormatHelper'
import List from '~brokerage/components/shared/List'


const Agent = ({ agent: { name, phone }, step, dispatch }) => {
  return (
    <div>
      <List>
        <List.Item>
          {name}
          {phone && ' - '}
          {phone && <span className={css.phone}>{formatPhone(phone)}</span>}
        </List.Item>
      </List>
    </div>
  )
}

Agent.propTypes = {
  agent: PropTypes.object,
  dispatch: PropTypes.func,
  step: PropTypes.string
}
export default connect()(Agent)
