import React from "react";
import css from "./ActiveIndicator.sass";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";

const ActiveIndicator = ({ address, photoUrl }) => (
  <div className={css.base}>
    <PropertyThumbnail className={css.thumb} image={photoUrl} />
    <div className={css.address}>{address}</div>
  </div>
);

export default ActiveIndicator;
