import css from './Content.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Content extends React.PureComponent {
  static propTypes = {
    header: PropTypes.string,
    footer: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.string,
    id: PropTypes.string
  }

  render() {
    const { header, footer, id = 'content', className } = this.props
    const style = this.props.style || 'base'

    return (
      <div id={id} className={classNames(css[style], header && css[`${header}Header`], footer && css[`${footer}Footer`], className && className)}>
        {this.props.children}
      </div>
    )
  }
}
