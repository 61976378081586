import css from "./CustomerDetails.sass";
import React from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import { format as formatPhone } from "~brokerage/libs/helpers/PhonesHelper";
import Button from "~brokerage/components/shared/Button";
import { useLocation, useHistory } from "react-router-dom";

const CustomerDetails = props => {
  const { phone, email, notes } = props.person;
  const location = useLocation();
  const history = useHistory();

  const handleDeleteClick = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "delete_customer" }
    });
  };

  const handleEditClick = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "edit_customer" }
    });
  };

  const renderContactInfo = (value, label, iconName) => {
    return (
      <li className={css.li}>
        <i className={css.liIcon}>
          <Icon name={iconName} />
        </i>
        {label}: <span className={css.contactValue}>{value}</span>
      </li>
    );
  };

  const renderPhone = (value, label) => {
    if (value) {
      return renderContactInfo(formatPhone(value), label, "phone");
    }
  };

  const renderEmail = (value, label) => {
    if (value) {
      return renderContactInfo(value, label, "mail");
    }
  };

  const renderNotes = (value, label) => {
    return renderContactInfo(value, label, "attributes");
  };

  return (
    <div className={css.base}>
      <div className={css.sectionHeader}>
        <h2>Customer Details</h2>
        <Button onClick={handleEditClick} variant="link">
          <Icon name="edit" /> Edit
        </Button>
      </div>
      <ul className={css.ul}>
        {renderPhone(phone, "Phone")}
        {renderEmail(email, "Email")}
        {renderNotes(notes, "Notes")}
      </ul>
      <div className={css.delete}>
        <Button onClick={handleDeleteClick} variant="outlineRed">
          <Icon name="decline2" /> Delete Customer
        </Button>
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {
  person: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    notes: PropTypes.string
  })
};

export default CustomerDetails;
