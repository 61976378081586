import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import some from "lodash/some";
import {
  fetchAvailableSellers,
  clearAvailableSellers
} from "~brokerage/actions/listings";
import { Row, Col } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import PersonAutocomplete from "./PersonAutocomplete";
import { withRouter } from "react-router-dom";

class PeopleAddForm extends React.PureComponent {
  static propTypes = {
    listingId: PropTypes.string,
    isFetching: PropTypes.bool,
    currentSellers: PropTypes.array,
    availableSellers: PropTypes.array,
    onCancel: PropTypes.func,
    onAdd: PropTypes.func,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };

  handleChange = value => {
    clearTimeout(this.fetchAvailableSellersTimeout);
    if (this.props.availableSellers.length) {
      this.props.dispatch(clearAvailableSellers());
    }

    if (value.length > 2) {
      this.fetchAvailableSellersTimeout = setTimeout(() => {
        this.props.dispatch(fetchAvailableSellers(this.props.listingId, value));
      }, 100);
    }
  };

  handleSelect = option => {
    const { location, history } = this.props;

    if (!option.value) {
      history.push({
        pathname: location.pathname,
        query: { ...location.query, modal: "add_seller_tenant" }
      });
      return this.props.onCancel();
    }

    for (const seller of this.props.availableSellers) {
      if (seller.uuid === option.value) {
        return this.props.onAdd(seller);
      }
    }
  };

  get availableSellersWithoutCurrent() {
    return this.props.availableSellers.filter(
      ({ uuid }) => !some(this.props.currentSellers, { uuid })
    );
  }

  render() {
    return (
      <div>
        <Row offset="0">
          <Col>
            <PersonAutocomplete
              people={this.availableSellersWithoutCurrent}
              onSelect={this.handleSelect}
              onChange={this.handleChange}
              fetching={this.props.isFetching}
            />
          </Col>
          <Col modifier="offset10">
            <Button variant="outline" onClick={this.props.onCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entities: currentSellers } =
    state.listings.single.sellerTenantInvolvement;
  const {
    entities: availableSellers,
    isFetching,
    errors
  } = state.listings.single.availableSellers;

  return {
    currentSellers,
    availableSellers,
    isFetching,
    errors
  };
}
export default withRouter(connect(mapStateToProps)(PeopleAddForm));
