import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import SettingsSideBar from "~brokerage/components/settings/SettingsSideBar";
import SettingsPane from "~brokerage/components/settings/SettingsPane";
import SettingsContent from "~brokerage/components/settings/SettingsContent";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import { callApi } from "~brokerage/middlewares/api";
import Loader from "~brokerage/components/shared/Loader";
import UserImageRow from "~brokerage/app/components/settings/components/UserImageRow";
import AgentInfo from "~brokerage/components/settings/components/AgentInfo";
import { toast } from "react-toastify";
import css from "./index.sass";
import prepareFormData from "./util/prepareFormData";
import confirmSelections from "./util/confirmSelections";
import NavigationPrompt from "~brokerage/components/shared/NavigationPrompt";

import {
  Email,
  EmailPreferences,
  Footer,
  MobilePhone,
  MobilePreferences,
  Submit,
  ShowingRequests
} from "./sections";

const Personal = () => {
  const { dispatchPageState } = useContext(SettingsContext);
  const [acceptShowingRequests, toggleOptOut] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrlFailed, setImageUrlFailed] = useState(false);
  const [showDialog, setShowDialog] = useState();
  const [confirmMessage, setConfirmMessage] = useState({
    heading: "Confirm",
    body: ""
  });

  // instantiate react hook form
  const methods = useForm({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = methods;

  useEffect(() => {
    callApi("profile/profile_preferences", {}, {}, "get")
      .then(payload => payload.data.data.profile)
      .then(profile => {
        setUserProfile(profile);
        if (profile.accept_showing_request === false) {
          toggleOptOut(false);
        }
        setIsLoading(false);
      });
    dispatchPageState({ type: "setActivePane", payload: "profile" });
  }, []);

  const showImagePreview = stream => {
    setUserProfile({
      ...userProfile,
      has_photo: true,
      photo_url: stream
    });
    setImageUrlFailed(false);
  };

  // function to handle form submit errors
  const onError = error => {
    try {
      for (let key in error) {
        toast.error(error[key].message);
      }
    } catch (error) {
      toast.error("There was an error submitting your form. Please try again.");
    }
  };

  const onSubmit = data => {
    // prevent the form from submitting twice by disabling the submit button
    dispatchPageState({ type: "submit" });
    const msg = confirmSelections(data);
    if (msg) {
      setConfirmMessage({ heading: "Confirm", body: msg });
      setShowDialog(true);
    } else {
      updateProfile();
    }
  };

  const onCancel = () => {
    dispatchPageState({ type: "submit_complete" });
  };

  const updateProfile = () => {
    setShowDialog(false);
    let fData = getValues();
    const formData = prepareFormData(fData);

    // call the api to update the user profile
    callApi("profile", {}, formData, "patch")
      .then(response => {
        toast.success("Profile saved");
        dispatchPageState({ type: "submit_complete" });
        setUserProfile(response.data.data.profile);
      })
      .catch(error => {
        let errorMessage = "An error occurred";
        if (error.response.data.hasOwnProperty("errors")) {
          let errors = error.response.data.errors;
          errorMessage = Array.isArray(errors) ? errors.join(" ") : errors;
        }
        toast.error(`Error submitting your changes: ${errorMessage}`);
        dispatchPageState({ type: "submit_complete" });
      });
  };

  // set the page state to dirty to confirm the user wants to leave the page
  const onChange = () => {
    dispatchPageState({ type: "working" });
  };

  const onReset = () => {
    const { allow_showing_request = true } = userProfile;
    toggleOptOut(allow_showing_request);
    dispatchPageState({ type: "working_complete" });
  };

  const {
    has_photo: hasPhoto,
    photo_url: photoUrl,
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    buying_email_notification_enabled,
    buying_text_notification_enabled,
    listing_email_notification_enabled,
    listing_text_notification_enabled,
    daily_summary_emails_enabled,
    broker_office_name: brokerOfficeName,
    broker_office_address: brokerOfficeAddress,
    account_creation_date: accountCreationDate,
    listing_count: listingCount,
    system_opt_out_managed_by_mls: systemOptOutManagedByMls
  } = userProfile;

  // Generate the user initials for the image placeholder
  const userInitials =
    ((firstName || "") && firstName[0]) + ((lastName || "") && lastName[0]);

  const promptButtons = [
    {
      label: "Cancel",
      variant: "outline",
      onClick: onCancel
    },
    {
      label: "OK",
      variant: "primary",
      onClick: updateProfile
    }
  ];

  return (
    <>
      <NavigationPrompt
        open={showDialog}
        message={confirmMessage}
        buttons={promptButtons}
      />
      <SettingsPane>
        <SettingsSideBar />
        <SettingsContent>
          {isLoading ? (
            <Loader active />
          ) : (
            <FormProvider {...methods}>
              <h3 className={css.formHeader}>Edit Profile</h3>
              <form
                className={css.profileSettings}
                onSubmit={handleSubmit(onSubmit, onError)}
                onChange={onChange}
              >
                <UserImageRow
                  accountCreationDate={accountCreationDate}
                  showImagePreview={showImagePreview}
                  imageUrlFailed={imageUrlFailed}
                  setImageUrlFailed={setImageUrlFailed}
                  setUserProfile={setUserProfile}
                  hasPhoto={hasPhoto}
                  photoUrl={photoUrl}
                  userInitials={userInitials}
                />
                <AgentInfo
                  firstName={firstName}
                  lastName={lastName}
                  brokerOfficeAddress={brokerOfficeAddress}
                  brokerOfficeName={brokerOfficeName}
                />
                <div className={css.divider}></div>
                <h4 className={css.subTitle}>
                  External Notification Preferences
                </h4>
                <Email errors={errors} email={email} register={register} />
                <MobilePhone errors={errors} phone={phone} />
                <EmailPreferences
                  disableListingOptions={!acceptShowingRequests}
                  baChecked={buying_email_notification_enabled}
                  laChecked={listing_email_notification_enabled}
                />
                <MobilePreferences
                  disableListingOptions={!acceptShowingRequests}
                  laChecked={listing_text_notification_enabled}
                  baChecked={buying_text_notification_enabled}
                  dailySummaryEmailsEnabled={daily_summary_emails_enabled}
                />
                <Footer />
                <Submit onReset={onReset} />
              </form>
            </FormProvider>
          )}
        </SettingsContent>
      </SettingsPane>
    </>
  );
};

export default Personal;
