import moment from "moment";
import { toast } from "react-toastify";
import mapboxDirections from "./mapboxDirections";
import { updateShowingRoute } from "~brokerage/actions/routes";
import getCoordinates from "./getCoordinates";

const fetchRoutingInfo = async (date, showings, dispatch, mapboxkey) => {
  const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm");
  const coordinatesString = getCoordinates(showings);

  if (!coordinatesString) return;

  try {
    const { map_geometry } = await mapboxDirections(
      {
        date: formattedDate,
        coordinates: coordinatesString
      },
      mapboxkey
    );
    dispatch(updateShowingRoute({ map_geometry }));
  } catch (e) {
    toast("Failed to calculate route.", { type: "error" });
  }
};

export default fetchRoutingInfo;
