import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import List from './List'
import ListingsCounter from './ListingsCounter'


export default class ListingsList extends React.PureComponent {
  static propTypes = {
    personId: PropTypes.string
  }

  render() {
    return (
      <div className={css.base}>
        <ListingsCounter />
        <List personId={this.props.personId} />
      </div>
    )
  }
}
