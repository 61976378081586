import {
  FETCH_FILTER_AGENTS_SUCCESS,
  LISTING_AGENT_SELECT
} from "~brokerage/constants/actionTypes";

export const defaultState = {
  currentAgent: null,
  agents: []
};

function agents(state = defaultState, action) {
  switch (action.type) {
    case FETCH_FILTER_AGENTS_SUCCESS:
      return { ...state, agents: action.data.agents };
    case LISTING_AGENT_SELECT:
      return { ...state, currentAgent: action.agent };
    default:
      return state;
  }
}

export default agents;
