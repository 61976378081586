import React, { Component } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
export default class Form extends Component {
  static childContextTypes = {
    __formRegisterComponent: PropTypes.func,
    __formUnregisterComponent: PropTypes.func,
    __formRegisteredComponents: PropTypes.object
  }

  static propTypes = {
    validate: PropTypes.bool,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    onValidate: PropTypes.func
  }

  constructor() {
    super()
    this.registeredComponents = {
      inputs: [],
      errorContainers: []
    }
  }

  getChildContext() {
    return {
      __formRegisterComponent: this.registerComponent,
      __formUnregisterComponent: this.unregisterComponent,
      __formRegisteredComponents: this.registeredComponents
    }
  }

  registerComponent = (component, type) => {
    if (!this.registeredComponents[type]) {
      this.registeredComponents[type] = []
    }
    this.registeredComponents[type].push(component)
  }

  unregisterComponent = (component, type) => {
    this.registeredComponents[type].splice(this.registeredComponents[type].indexOf(component), 1)
  }

  validate() {
    let isValid = true

    this.registeredComponents.inputs.forEach(component => {
      if (!component.validate()) {
        isValid = false
      }
    })

    if (this.props.onValidate) {
      if (!this.props.onValidate()) {
        isValid = false
      }
    }

    return isValid
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.props.validate) {
      if (this.validate()) {
        this.props.onSubmit(event, this.values())
      }
    } else {
      if(typeof this.props.onSubmit === "function"){
        this.props.onSubmit(event, this.values())
      }

    }
  }

  values() {
    const values = {}

    for (const component of this.registeredComponents.inputs) {
      Object.assign(values, component.value())
    }

    return values
  }

  render() {
    const rest = omit(this.props, Object.keys(Form.propTypes))

    return (
      <form onSubmit={this.handleSubmit} noValidate {...rest}>
        {this.props.children}
      </form>
    )
  }
}

export const withFormBinding = (ComposedComponent, type = 'inputs') => {
  class WithFormBinding extends Component {
    static displayName = `WithFormBinding_${ComposedComponent.displayName || ComposedComponent.name || 'Component'}`;

    static contextTypes = {
      __formRegisterComponent: PropTypes.func,
      __formUnregisterComponent: PropTypes.func,
      __formRegisteredComponents: PropTypes.object
    }

    componentDidMount() {
      this.context.__formRegisterComponent && this.context.__formRegisterComponent(this.component, type)
    }

    componentWillUnmount() {
      this.context.__formUnregisterComponent && this.context.__formUnregisterComponent(this.component, type)
    }

    render() {
      return (
        <ComposedComponent
          ref={c => this.component = c}
          {...this.props}
        />
      )
    }
  }

  return WithFormBinding
}
