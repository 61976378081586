import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchPreferencesIfNeeded } from "~brokerage/actions/myAccount";
import { withRouter } from "react-router-dom";

export const PREMIUM_FEATURE_TYPES = {
  MULTIPLE_PEOPLE: 0,
  LOG_A_CALL: 1,
  ADD_TEAMMATES_TO_CHAT: 2,
  REMOVE_TEAMMATES_FROM_CHAT: 3,
  ADD_FOLLOWERS_TO_LISTING: 4,
  REMOVE_FOLLOWERS_FROM_LISTING: 5,
  SCHEDULE_SHOWING_FOR_OTHER_USERS: 6,
  ENABLE_SHOWING_COORDINATOR_FOR_LISTING: 7,
  INVITE_NON_MLS_USERS: 8
};

class PremiumButtonWrapper extends React.PureComponent {
  static propTypes = {
    canAddMultiplePeopleToChats: PropTypes.bool,
    canLogACall: PropTypes.bool,
    canAddTeammatesToChatThread: PropTypes.bool,
    canAddFollowersToListing: PropTypes.bool,
    canScheduleShowingsForOtherUsers: PropTypes.bool,
    canInviteNonMlsUsers: PropTypes.bool,
    isPreferencesFetching: PropTypes.bool,
    skipPreferencesFetching: PropTypes.bool,
    haveBeenPreferencesFetched: PropTypes.bool,
    canEnableShowingCoordinatorForListing: PropTypes.bool,
    featureType: PropTypes.number,
    featureDisabledContent: PropTypes.node,
    children: PropTypes.node,
    beforeOnClick: PropTypes.func,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };

  componentDidMount() {
    if (!this.props.skipPreferencesFetching) {
      this.props.dispatch(fetchPreferencesIfNeeded());
    }
  }

  premiumFeatureEnabled() {
    const {
      featureType,
      canAddMultiplePeopleToChats,
      canLogACall,
      canAddTeammatesToChatThread,
      canAddFollowersToListing,
      canScheduleShowingsForOtherUsers,
      canEnableShowingCoordinatorForListing,
      canInviteNonMlsUsers
    } = this.props;

    switch (featureType) {
      case PREMIUM_FEATURE_TYPES.MULTIPLE_PEOPLE:
        return canAddMultiplePeopleToChats;
      case PREMIUM_FEATURE_TYPES.LOG_A_CALL:
        return canLogACall;
      case PREMIUM_FEATURE_TYPES.ADD_TEAMMATES_TO_CHAT:
        return canAddTeammatesToChatThread;
      case PREMIUM_FEATURE_TYPES.REMOVE_TEAMMATES_FROM_CHAT:
        return canAddTeammatesToChatThread;
      case PREMIUM_FEATURE_TYPES.ADD_FOLLOWERS_TO_LISTING:
        return canAddFollowersToListing;
      case PREMIUM_FEATURE_TYPES.REMOVE_FOLLOWERS_FROM_LISTING:
        return canAddFollowersToListing;
      case PREMIUM_FEATURE_TYPES.SCHEDULE_SHOWING_FOR_OTHER_USERS:
        return canScheduleShowingsForOtherUsers;
      case PREMIUM_FEATURE_TYPES.ENABLE_SHOWING_COORDINATOR_FOR_LISTING:
        return canEnableShowingCoordinatorForListing;
      case PREMIUM_FEATURE_TYPES.INVITE_NON_MLS_USERS:
        return canInviteNonMlsUsers;
    }
  }

  displayModalWindow = e => {
    if (this.props.beforeOnClick) {
      this.props.beforeOnClick();
    }
    e.stopPropagation();
    e.preventDefault();
    const { location, history } = this.props;
    const backPath = location.pathname + location.search;
    history.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        modal: "upgrade_to_premium",
        back_path: backPath
      }
    });
  };

  render() {
    if (this.premiumFeatureEnabled()) {
      return this.props.children;
    } else {
      return (
        <span onClickCapture={this.displayModalWindow}>
          {this.props.featureDisabledContent || this.props.children}
        </span>
      );
    }
  }
}

function mapStateToProps(state) {
  const {
    canAddMultiplePeopleToChats,
    canLogACall,
    canAddTeammatesToChatThread,
    canAddFollowersToListing,
    canScheduleShowingsForOtherUsers,
    canInviteNonMlsUsers,
    isFetching: isPreferencesFetching,
    fetched: haveBeenPreferencesFetched,
    canEnableShowingCoordinatorForListing
  } = state.myAccount.preferences;

  return {
    canAddMultiplePeopleToChats,
    canLogACall,
    canAddTeammatesToChatThread,
    canAddFollowersToListing,
    canScheduleShowingsForOtherUsers,
    canInviteNonMlsUsers,
    isPreferencesFetching,
    haveBeenPreferencesFetched,
    canEnableShowingCoordinatorForListing
  };
}

export default withRouter(connect(mapStateToProps)(PremiumButtonWrapper));
