import { combineReducers } from "redux";

import categoriesReducer from "./categories";
import showingsReducer from "./showings";
import listingsReducer from "./listings";

export default combineReducers({
  categories: categoriesReducer,
  showings: showingsReducer,
  listings: listingsReducer
});
