import css from './Link.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Icon from '~brokerage/components/shared/Icon'
import Tooltip from '~brokerage/components/shared/Tooltip'

export default class Link extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string,
    info: PropTypes.string,
    onClick: PropTypes.func
  }

  render() {
    const { variant = 'base', active, label, info, onClick } = this.props

    return (
      <a className={composeClassName(css, variant, { active })} onClick={onClick}>
        {label}
        {info &&
          <div className={css.tooltip}>
            <Tooltip text={info}><Icon name="info"/></Tooltip>
          </div>
        }
      </a>
    )
  }
}
