import css from './Label.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
export default class Label extends Component {
  static propTypes = {
    variant: PropTypes.string,
    valign: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { valign, children, variant = 'base', ...rest } = this.props

    return (
      <div className={classNames(css[variant], valign && css[`${valign}Valign`], )} {...rest}>
        {children}
      </div>
    )
  }
}
