import React from "react";
import SmartModalToggle from "~brokerage/components/modals/SmartModalToggle";
import ModalConfirmation from "~brokerage/components/modals/ModalConfirmation";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const RemoveUserImage = ({ setUserProfile }) => {
  let history = useHistory();
  let location = useLocation();

  const updateProfileData = () => {
    callApi("profile/profile_preferences", {}, {}, "get")
      .then(payload => payload.data.data.profile)
      .then(profile => {
        setUserProfile(profile);
      });
  };

  const removeUserProfile = () => {
    callApi("profile/picture", {}, {}, "delete")
      .then(response => {
        history.push({
          pathname: "/settings",
          query: { ...location.query, modal: void 0 }
        });
        updateProfileData();
        toast.success("Successfully removed");
      })
      .catch(error => {
        toast.error(
          error?.response?.data?.error ||
            "Something went wrong removing the user profile."
        );
      });
  };

  return (
    <div>
      <SmartModalToggle
        variant="outlineRed"
        modal={
          <ModalConfirmation
            heading="Remove Profile Picture"
            text="Are you sure?"
            confirmText="Yes, Remove Picture"
            confirmVariant="outlineRed"
            onConfirm={removeUserProfile}
            cancelText="No, Keep Picture"
            cancelVariant="outlineBlue"
          />
        }
        name="remove_user_profile_picture"
      >
        Remove Picture
      </SmartModalToggle>
    </div>
  );
};

export default RemoveUserImage;
