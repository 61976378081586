import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Button from "~brokerage/components/shared/Button";
import Link from "~brokerage/components/shared/Link";
import { useLocation } from "react-router-dom";

const FeedbackReceived = ({ message }) => {
  const location = useLocation();
  const { senderUserId, senderName, leftFeedbackDate, listingKey } = message;

  const paramsForAgentDetailsModal = () => ({
    ...location,
    query: {
      ...location.query,
      modal: "agent_details",
      agent_id: senderUserId
    }
  });

  const paramsForFeedbackLinkToAttribute = () => ({
    pathname: `/listings/${listingKey}/feedback`,
    query: { backToPath: location.pathname }
  });

  return (
    <div className={css.event}>
      <p>
        <Button variant="link" to={paramsForAgentDetailsModal()}>
          {senderName}
        </Button>{" "}
        left
        <Link to={paramsForFeedbackLinkToAttribute()}> feedback</Link> on{" "}
        {leftFeedbackDate}
      </p>
    </div>
  );
};

FeedbackReceived.propTypes = {
  message: PropTypes.object,
  location: PropTypes.object
};

export default FeedbackReceived;
