import { timeToRemoteWithTimeZone } from "~brokerage/libs/helpers/TimeHelper";
import determineConflicts from "./determinConflicts";
import moment from "moment";

const setInitialState = (showings, restrictions, setTimes) => {
  const initialState = showings.map((s, index) => {
    const { unavailability = [] } = restrictions[s.listingKey] || {};
    const sDate = moment(s.requestedTimeRaw);

    return {
      id: s.id,
      position: index,
      time: timeToRemoteWithTimeZone(s.requestedTimeRaw),
      coordinates: s.coordinates,
      conflicts: determineConflicts({
        unavailability,
        nextDate: sDate,
        duration: s.duration,
        showingId: s.id
      }),
      startTime: s.requestedTimeRaw,
      duration: s.duration,
      travelTime: s.travelTime,
      status: s.status,
      modified: false,
      address: s.address
    };
  });
  setTimes(initialState);
};

export default setInitialState;
