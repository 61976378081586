import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactList from "react-list";
import {
  scrollConversations,
  scrollConversationsSearchMessages
} from "~brokerage/actions/messages";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import Loader from "~brokerage/components/shared/Loader";
import ListItem from "./ListItem";
import ListItemLoader from "~brokerage/components/shared/ListItemLoader";
import { withRouter } from "react-router-dom";

export class List extends React.PureComponent {
  static propTypes = {
    messages: PropTypes.array,
    isFetching: PropTypes.bool,
    filter: PropTypes.object,
    currentConversationType: PropTypes.string,
    currentConversationId: PropTypes.string,
    isScrollFetching: PropTypes.bool,
    hasMoreMessages: PropTypes.bool,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };

  renderReactList() {
    return (
      <ReactList
        itemsRenderer={::this.renderList}
        itemRenderer={::this.renderMessage}
        length={this.props.messages.length}
        type="uniform"
      />
    );
  }

  renderList(items, ref) {
    return <div ref={ref}>{items}</div>;
  }

  loadMoreMessages() {
    const { type, page } = this.props.filter;
    const { searchTerm } = this.props;
    if (searchTerm?.length > 2) {
      this.props.dispatch(
        scrollConversationsSearchMessages({
          query: searchTerm,
          type: type,
          page: page
        })
      );
    } else {
      this.props.dispatch(scrollConversations(this.props.filter));
    }
  }

  needLoadMoreMessages(index) {
    return (
      Number(this.props.messages.length - index) === 1 &&
      !this.props.isScrollFetching &&
      this.props.hasMoreMessages
    );
  }

  renderMessage(index, key) {
    const {
      searchTerm,
      isSearching,
      currentConversationType,
      currentConversationId,
      messages
    } = this.props;
    const message = messages[index];

    return (
      <ListItemLoader
        key={key}
        needLoadMore={this.needLoadMoreMessages(index)}
        loadMore={::this.loadMoreMessages}
        uniqueId={message.id}
      >
        <ListItem
          message={message}
          currentType={this.props.location.query.type}
          active={
            message.type === currentConversationType &&
            message.id == currentConversationId
          }
          isSearching={isSearching}
          searchTerm={searchTerm}
        />
      </ListItemLoader>
    );
  }

  render() {
    const { messages, isFetching } = this.props;
    if (isFetching) {
      return <Loader active />;
    } else if (!messages.length) {
      return <EmptyMessage>Nothing to show.</EmptyMessage>;
    } else {
      return this.renderReactList();
    }
  }
}

function mapStateToProps(state) {
  const {
    entities: messages,
    isFetching,
    isSearching,
    filter,
    isScrollFetching,
    hasMoreMessages,
    searchTerm
  } = state.messages.list.messages;

  return {
    messages,
    isFetching,
    isSearching,
    filter,
    hasMoreMessages,
    isScrollFetching,
    searchTerm
  };
}
export default withRouter(connect(mapStateToProps)(List));
