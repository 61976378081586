import css from './InstructionsList.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class InstructionsList extends React.PureComponent {
  static propTypes = {
    instructions: PropTypes.object
  }

  renderListingAgentAccompanyChange() {
    const { hasListingAgentAccompanyChanged, willListingAgentAccompany } = this.props.instructions

    if (hasListingAgentAccompanyChanged) {
      return (
        <li className={css.li}>
          {
            willListingAgentAccompany ?
              <span>The <b>Listing Agent</b> will accompany your Showing</span> :
              <s>The <b>Listing Agent</b> will accompany your Showing</s>
          }
        </li>
      )
    }
  }

  renderTenantAccompanyChange() {
    const { hasTenantAccompanyChanged, willTenantAccompany } = this.props.instructions

    if (hasTenantAccompanyChanged) {
      return (
        <li className={css.li}>
          {
            willTenantAccompany ?
              <span><b>Tenant / Seller</b> will accompany your Showing</span> :
              <s><b>Tenant / Seller</b> will accompany your Showing</s>
          }
        </li>
      )
    }
  }

  renderLockBoxKindChange() {
    const { instructions: { hasLockboxKindChanged, lockboxKind, lockboxCode, lockboxBrand } } = this.props;

    if (hasLockboxKindChanged) {
      if (lockboxKind === "combo") {
        return (
          <li className={css.li}>
            Lockbox: <b>{lockboxCode}</b>
          </li>
        )
      } else if (lockboxKind === "electronic") {
        return (
          <li className={css.li}>
            Electronic Lockbox{lockboxBrand ? ":" : ""} <b>{lockboxBrand}</b>
          </li>
        )
      }
    }
  }

  renderLockBoxKindChangedFrom() {
    const { hasLockboxKindChanged, lockboxKindChangedFrom } = this.props.instructions

    if (hasLockboxKindChanged) {
      if (lockboxKindChangedFrom === 'electronic') {
        return (
          <li className={css.li}>
            <s>Electronic Lockbox</s>
          </li>
        )
      } else if (lockboxKindChangedFrom === 'combo') {
        return (
          <li className={css.li}>
            <s>Combination Lockbox</s>
          </li>
        )
      }
    }
  }

  renderAdditionalInstructionsChange() {
    const { hasMessageChanged, message } = this.props.instructions

    if (hasMessageChanged) {
      return (
        <li className={css.li}>
          <b>Additional Instructions:</b> {message}
        </li>
      )
    }
  }

  hasAnythingChanged() {
    const { instructions } = this.props
    return (
      instructions.hasMessageChanged ||
      instructions.hasListingAgentAccompanyChanged ||
      instructions.hasLockboxKindChanged ||
      instructions.hasTenantAccompanyChanged
    )
  }

  render() {
    if (!this.props.instructions) {
      return false
    }

    if (!this.hasAnythingChanged()) {
      return false
    }

    return (
      <div className={css.base}>
        <h4 className={css.h4}>Showing Instructions:</h4>

        <ul className={css.ul}>
          {this.renderListingAgentAccompanyChange()}
          {this.renderTenantAccompanyChange()}
          {this.renderLockBoxKindChange()}
          {this.renderLockBoxKindChangedFrom()}
          {this.renderAdditionalInstructionsChange()}
        </ul>
      </div>
    )
  }
}
