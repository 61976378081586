import {
  DEFAULT_SURVEY_SETTING_REQUEST,
  DEFAULT_SURVEY_SETTING_SUCCESS,
  DEFAULT_SURVEY_SETTING_FAILURE,
  DEFAULT_SURVEY_SETTING_UPDATE_REQUEST,
  DEFAULT_SURVEY_SETTING_UPDATE_SUCCESS,
  DEFAULT_SURVEY_SETTING_UPDATE_FAILURE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  isFetching: false,
  settings: null
};

export default function settingsReducer(state = defaultState, action) {
  switch (action.type) {
    case DEFAULT_SURVEY_SETTING_REQUEST:
      return { settings: null, isFetching: true };
    case DEFAULT_SURVEY_SETTING_SUCCESS:
      return {
        settings: action.data.data.settings,
        isFetching: false
      };
    case DEFAULT_SURVEY_SETTING_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false
      };
    case DEFAULT_SURVEY_SETTING_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case DEFAULT_SURVEY_SETTING_UPDATE_SUCCESS:
      return {
        settings: action.data.data.settings,
        isFetching: false
      };
    case DEFAULT_SURVEY_SETTING_UPDATE_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false
      };
    default:
      return state;
  }
}
