import css from "./People.sass";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addOfficeInvolvementTeamMember,
  removeOfficeInvolvementTeamMember,
  updateOfficeInvolvementTeamMember
} from "~brokerage/actions/settings";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import PeopleAddForm from "./PeopleAddForm";

export class People extends PureComponent {
  static propTypes = {
    people: PropTypes.array,
    dispatch: PropTypes.func
  };

  state = {
    isAdding: false,
    agentToEdit: null
  };

  handleAddCancel = () => {
    this.setState({
      isAdding: false
    });
  };

  handleEditCancel = () => {
    this.setState({
      agentToEdit: null
    });
  };

  handleAdd = person => {
    this.props.dispatch(addOfficeInvolvementTeamMember(person));
    this.setState({
      isAdding: false
    });
  };

  handleUpdate = person => {
    this.props.dispatch(updateOfficeInvolvementTeamMember(person));
    this.setState({
      agentToEdit: null
    });
  };

  handleAddClick = () => {
    this.setState({
      isAdding: true,
      agentToEdit: null
    });
  };

  handleEditClick = agent => {
    this.setState({
      isAdding: false,
      agentToEdit: agent
    });
  };

  handleRemoveClick = index => {
    this.props.dispatch(
      removeOfficeInvolvementTeamMember(this.props.people[index])
    );
  };

  renderFooter() {
    if (this.state.isAdding) {
      return (
        <PeopleAddForm
          onCancel={this.handleAddCancel}
          onSubmit={this.handleAdd}
        />
      );
    } else if (this.state.agentToEdit) {
      return (
        <PeopleAddForm
          onCancel={this.handleEditCancel}
          onSubmit={this.handleUpdate}
          person={this.state.agentToEdit}
        />
      );
    }

    return (
      <Button variant="outline" onClick={this.handleAddClick}>
        <Icon name="userAdd" /> Add Team Member
      </Button>
    );
  }

  render() {
    const { agentToEdit } = this.state;

    return (
      <div className={css.base}>
        <table className={css.table}>
          <tbody>
            {this.props.people?.map((person, personIndex) => {
              const disablePersonBeingEdited = agentToEdit && person.id === agentToEdit.id;
              const isRemoveButtonDisabled = !person.isRemovable || disablePersonBeingEdited;

              return (
                <tr key={person.agentId}>
                  <td className={css.tdName}>
                    <div className={css.avatar}>
                      <Avatar
                        size="30"
                        src={person.photoUrl}
                        name={person.name}
                      />
                    </div>
                    <div className={css.name}>
                      {person.name}
                      {person.role && (
                        <div className={css.role}>{person.role}</div>
                      )}
                    </div>
                  </td>
                  <td className={css.tdStatus}>
                    <Button
                      variant="peopleRemove"
                      disabled={!person.isEditable}
                      onClick={this.handleEditClick.bind(this, person)}
                    >
                      <div className={css.icon}>
                        <Icon name="edit" />
                      </div>
                      {person.notificationLevelLabel}
                    </Button>
                  </td>
                  <td className={css.tdRemove}>
                    <Button
                      variant="peopleRemove"
                      disabled={isRemoveButtonDisabled}
                      onClick={this.handleRemoveClick.bind(this, personIndex)}
                    >
                      <div className={css.icon}>
                        <Icon name="close" />
                      </div>
                      Remove
                    </Button>
                  </td>
                </tr>
              )})}
          </tbody>
        </table>
        <div className={css.footer}>{this.renderFooter()}</div>
      </div>
    );
  }
}

export default connect()(People);
