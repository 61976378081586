import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { callApi } from "~brokerage/middlewares/api";
import ModalConfirmation from "./ModalConfirmation";
import { DEFAULT_LANDING_PAGE_URL } from "~brokerage/app/constants/apiUrls";

const ModalImpersonateUser = props => {
  const {
    people,
    location,
    location: { query: { personId } }
  } = props;

  const person = people.find(person => person.id === personId);

  const handleImpersonate = () => {
    callApi(`people/${person.userId}/impersonate`, {}, {}, "post")
      .then(() => { window.location = DEFAULT_LANDING_PAGE_URL; })
      .catch(error => {
        toast.error(error.response.data.message || "Something went wrong.");
      });
  };

  return (
    <ModalConfirmation
      heading={`Work as ${person.name}`}
      text="Are you sure ?"
      confirmText="Yes, Work As."
      confirmVariant="outlineBlue"
      onConfirm={handleImpersonate}
      cancelText="No, Cancel."
      location={location}
    />
  );
};

function mapStateToProps(state, ownProps) {
  const { entities: people } = state.people.list.people;

  return {
    people
  };
}

export default connect(mapStateToProps)(ModalImpersonateUser);
