import React from "react";

import Loader from "~brokerage/components/shared/Loader";
import { Form, Row, Section } from "~brokerage/components/shared/Form";

import ShowingExplanation from "./ShowingExplanation";
import Header from "../Header";
import AdditionalDetails from "../AdditionalDetails";
import Button from "~brokerage/components/shared/Button";
import { connect } from "react-redux";

const ModalContents = ({
  loading,
  details,
  changeDetails,
  pushDetails,
  resetDetails,
  closeModal,
  fromRoute,
  unsavedChanges
}) => {
  if (loading) {
    return <Loader active />;
  }
  const message = details.message || "";
  let warnMessage =
    "Canceled appointments cannot be reversed and a new appointment will need to be requested.";

  if (fromRoute && !!unsavedChanges?.length)
    warnMessage += " Cancel will also clear unsaved changes from route.";

  return (
    <Form>
      <Header headerText="Add Explanation" closeModal={closeModal} />

      <Section heading="Why is the appointment being cancelled?">
        <ShowingExplanation details={details} changeDetails={changeDetails} />
      </Section>

      <Section heading="Optional Message">
        <AdditionalDetails
          message={message}
          changeDetails={changeDetails}
          placeholder="Enter Optional Message for Listing Team"
        />
      </Section>

      <div style={{ marginTop: "17px", color: "#97291d" }}>{warnMessage}</div>

      <Row>
        <Button
          onClick={resetDetails}
          float="left"
          variant="outline"
          modifier="bigger"
        >
          Clear
        </Button>
        <Button
          onClick={pushDetails}
          float="right"
          variant="green"
          modifier="bigger"
        >
          Cancel and Send
        </Button>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity } = routes.single.route || {};
  const { unsavedChanges } = entity || [];
  return {
    unsavedChanges
  };
};

export default connect(mapStateToProps)(ModalContents);
