const validateLatLng = (lat, lng) => {
  const LAT = /^[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/;
  const LNG = /^[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)$/;
  const ZERO = /^0(\.0+)?/;

  if (ZERO.test(lat) && ZERO.test(lng)) return false; //invalid if both are zeros

  return LAT.test(lat) && LNG.test(lng);
};

export { validateLatLng };
