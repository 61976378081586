import React from "react";
import { connect } from "react-redux";

import { callApi } from "brokerage/middlewares/api";
import { useHistory, useLocation } from "react-router-dom";
import ModalConfirmation from "./ModalConfirmation";
import { fetchUserSurveys } from "~brokerage/actions/surveys";

const ModalDeleteSurveyConfirm = props => {
  const { dispatch } = props;
  const history = useHistory();
  const location = useLocation();

  const handleDelete = () => {
    callApi(`surveys/${location.query.survey_id}`, {}, {}, "delete").then(
      response => {
        if (response.status === 200) {
          history.push({
            pathname: `/settings/feedback/manage_surveys`,
            query: { ...location.query, survey_id: void 0, modal: void 0 }
          });
        }
        dispatch(fetchUserSurveys());
      }
    );
  };

  return (
    <ModalConfirmation
      heading="Delete Survey"
      text="Are you sure?"
      confirmText="Yes, Delete"
      confirmVariant="red"
      onConfirm={handleDelete}
      cancelText="Cancel"
      cancelVariant="outline"
    />
  );
};

export default connect()(ModalDeleteSurveyConfirm);
