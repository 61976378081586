import css from './Autocomplete.sass'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import upperFirst from 'lodash/upperFirst'
import { TextField } from '~brokerage/components/shared/Form'
import AutocompleteList from './AutocompleteList'
import Loader from '~brokerage/components/shared/Loader'

export default class Autocomplete extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    fetching: PropTypes.bool,
    focused: PropTypes.bool,
    onSelect: PropTypes.func,
    variant: PropTypes.string,
    height: PropTypes.number,
    scrollable: PropTypes.bool
  }

  state = {
    shown: false,
    focused: false
  }

  componentDidMount() {
    this.listContainer = document.createElement('div')
    this.$toggle = $('input', ReactDOM.findDOMNode(this))

    if (this.props.focused && this.props.options) {
      this.showList()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value.length >= 3 && this.state.focused && this.props.options !== prevProps.options) {
      this.showList()
    }
    if (this.props.value.length < 3) {
      this.hideList()
    }
  }

  showList = () => {
    if (!this.state.shown) {
      document.body.appendChild(this.listContainer)
      this.setState({
        shown: true
      })
    }

    ReactDOM.render(this.renderList(), this.listContainer)
  }

  hideList = () => {
    if (!this.state.shown) { return }

    ReactDOM.unmountComponentAtNode(this.listContainer)
    document.body.removeChild(this.listContainer)
    this.setState({
      shown: false
    })
  }

  handleOutsideClick = (event) => {
    if (!$(event.target).closest(this.$toggle).length) {
      this.hideList()
    }
  }

  handleOptionClick = (option) => {
    this.hideList()
    this.props.onSelect(option)
  }

  renderList() {
    return (
      <AutocompleteList
        options={this.props.options}
        toggleWidth={this.$toggle.outerWidth()}
        top={this.$toggle.offset().top + this.$toggle.outerHeight()}
        left={this.$toggle.offset().left}
        onOptionClick={this.handleOptionClick}
        onOutsideClick={this.handleOutsideClick}
        variant={this.props.variant}
        height={this.props.height}
        scrollable={this.props.scrollable}
      />
    )
  }

  handleFocus = () => {
    this.setState({
      focused: true
    })

    if (this.listContainer) {
      this.showList()
    }
  }

  handleBlur = () => {
    this.setState({
      focused: false
    })
  }

  render() {
    const { variant, fetching, value, focused } = this.props

    return (
      <div className={css[`autocomplete${variant ? upperFirst(variant) : ''}`]}>
        <Loader variant="input" active={fetching}/>
        <TextField
          variant={this.state.shown ? 'autocompleteActive' : ''}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={value}
          focused={focused}
          {...omit(this.props, Object.keys(Autocomplete.propTypes))}
        />
      </div>
    )
  }
}
