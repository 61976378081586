import css from "./PeopleHeader.sass";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Categories from "./Categories";
import { withRouter } from "react-router-dom";

class PeopleHeader extends PureComponent {
  static propTypes = {
    location: PropTypes.object
  };

  render() {
    return (
      <div className={css.base}>
        <Categories location={this.props.location} />
      </div>
    );
  }
}
export default withRouter(connect()(PeopleHeader));
