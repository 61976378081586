import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Map from "./Map";
import PropertyList from "./PropertyList";
import css from "./index.sass";
import { MAX_SELECTIONS } from "~brokerage/constants/propertySearch/userSelections";
import { setSelectedProperties } from "~brokerage/actions/propertySearch";

const PropertySearch = props => {
  const {
    isFetching,
    selected,
    listings,
    location,
    mapboxkey,
    variant,
    history,
    dispatch
  } = props;

  const [hightlightedListing, setHighlightedListing] = useState();

  useEffect(() => {
    if (hightlightedListing) {
      const listing = listings.find(
        ({ mlsNumber }) => mlsNumber === hightlightedListing
      );
      if (listing) updateSelected(listing);
    }
  }, [hightlightedListing]);

  const data = {
    isFetching,
    selected,
    listings
  };

  const updateSelected = listing => {
    if (variant === "showing") {
      dispatch(
        setSelectedProperties(
          selected.some(s => s.id === listing.id) ? [] : [listing]
        )
      );
      return;
    }
    let update = [...selected];
    if (selected.findIndex(s => s.id === listing.id) !== -1) {
      update = update.filter(s => s.id !== listing.id);
    } else {
      if (selected.length >= MAX_SELECTIONS) {
        history.push({
          pathname: location.pathname,
          query: {
            ...location.query,
            modal: "max_selected"
          }
        });
        return;
      }
      update = [...update, listing];
    }
    dispatch(setSelectedProperties(update));
  };

  return (
    <>
      <Header location={location} variant={variant} />
      <div className={css.root}>
        <Map
          mapboxkey={mapboxkey}
          {...data}
          setHighlightedListing={setHighlightedListing}
        />
        <PropertyList
          {...data}
          variant={variant}
          updateSelected={updateSelected}
          hightlightedListing={hightlightedListing}
          setHighlightedListing={setHighlightedListing}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    entities: listings,
    page: currentPage,
    totalPages,
    isFetching
  } = state.propertySearch.list.listings;
  const { listings: selected } = state.propertySearch.selected;
  const { mapboxkey } = state.keys;
  return {
    listings,
    currentPage,
    totalPages,
    isFetching,
    selected,
    mapboxkey
  };
}
export default connect(mapStateToProps)(PropertySearch);
