import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchPeople(filter, page = 1) {
  return api.people.fetchPeople({ filter, page }, [
    types.PEOPLE_REQUEST,
    types.PEOPLE_SUCCESS,
    types.PEOPLE_FAILURE
  ]);
}

export function setPeopleQuery(query) {
  return api.people.setQuery(query, types.PEOPLE_SET_Q);
}

export function fetchPeopleCounts(filter) {
  return api.person.fetchPersonCounts(filter, [
    types.PEOPLE_COUNTS_REQUEST,
    types.PEOPLE_COUNTS_SUCCESS,
    types.PEOPLE_COUNTS_FAILURE
  ]);
}

export function fetchPersonDetails(personId) {
  return api.people.fetchPersonDetails(personId, [
    types.PERSON_REQUEST,
    types.PERSON_SUCCESS,
    types.PERSON_FAILURE
  ]);
}

export function fetchContactDetails(personId) {
  return api.people.fetchContactDetails(personId, [
    types.PERSON_REQUEST,
    types.PERSON_SUCCESS,
    types.PERSON_FAILURE
  ]);
}

export function updatePersonDetails(person) {
  return {
    type: types.PERSON_UPDATE,
    person
  };
}

export function fetchPersonListings(personId, page = 1) {
  return api.people.fetchPersonListings(personId, page, [
    types.PERSON_LISTINGS_REQUEST,
    types.PERSON_LISTINGS_SUCCESS,
    types.PERSON_LISTINGS_FAILURE
  ]);
}

export function fetchPeopleCategories() {
  return api.person.fetchPersonCategories([
    types.PEOPLE_CATEGORIES_REQUEST,
    types.PEOPLE_CATEGORIES_SUCCESS,
    types.PEOPLE_CATEGORIES_FAILURE
  ]);
}

export function fetchCustomerCategories() {
  return api.person.fetchCustomerCategories([
    types.CUSTOMER_CATEGORIES_REQUEST,
    types.CUSTOMER_CATEGORIES_SUCCESS,
    types.CUSTOMER_CATEGORIES_FAILURE
  ]);
}

export function fetchCustomerShowings(contactId) {
  return api.person.fetchCustomerShowings(contactId, [
    types.CUSTOMER_SHOWINGS_REQUEST,
    types.CUSTOMER_SHOWINGS_SUCCESS,
    types.CUSTOMER_SHOWINGS_FAILURE
  ]);
}

export function fetchCustomerListings(contactId) {
  return api.person.fetchCustomerListings(contactId, [
    types.CUSTOMER_LISTINGS_REQUEST,
    types.CUSTOMER_LISTINGS_SUCCESS,
    types.CUSTOMER_LISTINGS_FAILURE
  ]);
}
