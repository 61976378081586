import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchUserSurveys() {
  return api.surveys.fetchUserSurveys([
    types.FEEDBACK_SURVEYS_REQUEST,
    types.FEEDBACK_SURVEYS_SUCCESS,
    types.FEEDBACK_SURVEYS_FAILURE
  ]);
}

export function fetchUserSurvey(id) {
  return api.surveys.fetchUserSurvey(id, [
    types.FEEDBACK_SURVEY_REQUEST,
    types.FEEDBACK_SURVEY_SUCCESS,
    types.FEEDBACK_SURVEY_FAILURE
  ]);
}

export function fetchDefaultSurveySettings() {
  return api.surveys.fetchDefaultSurveySettings([
    types.DEFAULT_SURVEY_SETTING_REQUEST,
    types.DEFAULT_SURVEY_SETTING_SUCCESS,
    types.DEFAULT_SURVEY_SETTING_FAILURE
  ]);
}

export function updateDefaultSurveySettings(payload) {
  return api.surveys.updateDefaultSurveySettings(payload, [
    types.DEFAULT_SURVEY_SETTING_UPDATE_REQUEST,
    types.DEFAULT_SURVEY_SETTING_UPDATE_SUCCESS,
    types.DEFAULT_SURVEY_SETTING_UPDATE_FAILURE
  ]);
}
