import { connect } from 'react-redux'
import Sidebar from './Sidebar'

function mapStateToProps(state) {
  const { entities: availableAgents,
          isFetching: availableAgentsIsFetching } = state.showings.single.availableAgents
  const { entity: showing,
          isFetching,
          isChanging,
          hasCancelShowingSubmitted,
          roleForLastShowing: currentShowingRole } = state.showings.single.showing
  const { entities: listingTeamMembers,
          isFetching: listingTeamMembersAreFetching } = state.showings.single.listingTeamMembers
  const { entities: buyerTeamMembers,
          isFetching: buyerTeamMembersAreFetching} = state.showings.single.buyerTeamMembers
  const { entities: sellers, buyers } = state.showings.single.sellerTenantInvolvement
  const { hasUncompletedSurveyQuestions } = state.showings.single.feedback

  return {
    filter: state.showings.list.showings.filter,
    availableAgents,
    availableAgentsIsFetching,
    showing,
    isFetching,
    isChanging,
    hasCancelShowingSubmitted,
    hasUncompletedSurveyQuestions,
    currentShowingRole,
    listingTeamMembers,
    listingTeamMembersAreFetching,
    buyerTeamMembers,
    buyerTeamMembersAreFetching,
    sellers,
    buyers
  }
}
export default connect(mapStateToProps)(Sidebar)
