import css from "./Person.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import PersonPreferredCommunication from "./PersonPreferredCommunication";
import PersonCanViewFeedback from "./PersonCanViewFeedback";
import PersonShowingInvolvement from "./PersonShowingInvolvement";

export default class Person extends React.PureComponent {
  static propTypes = {
    person: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.string,
      primaryPhone: PropTypes.string,
      email: PropTypes.string,
      preferredCommunication: PropTypes.string,
      showingInvolvement: PropTypes.string
    }),
    onRemoveClick: PropTypes.func,
    onChange: PropTypes.func,
    size: PropTypes.string
  };

  render() {
    const { person, size, onChange } = this.props;
    const { firstName, lastName, role, showingInvolvement } = person;

    return (
      <div>
        <div>
          <div className={css.buttons}>
            <Button variant="peopleRemove" onClick={this.props.onRemoveClick}>
              <div className={css.icon}>
                <Icon name="close" />
              </div>
              Remove
            </Button>
          </div>
          <div>
            <div className={css.avatar}>
              <Avatar size={size} name={`${firstName} ${lastName}`} />
            </div>

            <div className={css.name}>
              {`${firstName} ${lastName}`}
              {role && <div className={css.role}>{role}</div>}
            </div>
          </div>
        </div>
        <div>
          <PersonShowingInvolvement person={person} onChange={onChange} />
          {showingInvolvement !== "none_involvement" && (
            <>
              <PersonPreferredCommunication
                person={person}
                onChange={onChange}
              />
              <PersonCanViewFeedback person={person} onChange={onChange} />
            </>
          )}
        </div>
      </div>
    );
  }
}
