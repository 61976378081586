import React, { useState } from "react";
import css from "../index.sass";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import BuyerAutocomplete from "./BuyerAutocomplete";

export default function AddBuyer({ buyers, onBuyerAdd }) {
  const [isAdding, setIsAdding] = useState(false);

  const handleBuyerAdd = buyer => {
    setIsAdding(false);
    onBuyerAdd(buyer);
  };

  if (isAdding)
    return (
      <div className={css.buyerRow}>
        <BuyerAutocomplete buyers={buyers} onBuyerAdd={handleBuyerAdd} />
        <Button variant="outline" onClick={() => setIsAdding(false)}>
          Cancel
        </Button>
      </div>
    );
  else
    return (
      <div className={css.buyerRow}>
        <Button variant="outline" onClick={() => setIsAdding(true)}>
          <Icon name="userAdd" /> Add Buyer
        </Button>
      </div>
    );
}
