import css from "./PropertyThumbnail.sass";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import noPhoto from "~brokerage/assets/images/No-Photo-Placeholder.svg";

const PropertyThumbnail = ({ image, className, errorClassName }) => {
  const imageRef = useRef();

  const setImageToFallBackSRC = () => {
    imageRef.current.src = noPhoto;
    imageRef.current.className = "errorClassName";
  };

  const imageUrl = image ? image : noPhoto;

  return (
    <img
      onError={setImageToFallBackSRC}
      className={
        image
          ? `${css.propertyThumbBase} ${className}`
          : `${css.propertyThumbBase} ${className} ${errorClassName}`
      }
      src={imageUrl}
      ref={imageRef}
    />
  );
};

PropertyThumbnail.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string
};

export default PropertyThumbnail;
