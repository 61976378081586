import classNames from 'classnames'

export const composeClassName = (css, variant, _modifier) => {
  let names = [css[variant] || css.base]

  const modifier = classNames(_modifier)
  if (modifier) {
    names = names.concat(modifier.split(/\s+/).map(modifier => css[modifier]))
  }

  return classNames(names)
}

export const addClass = function (el, className) {
  if (el.classList) {
    el.classList.add(className)
  } else {
    el.className += ' ' + className
  }
}

export const removeClass = function (el, className) {
  if (el.classList) {
    el.classList.remove(className)
  } else {
    el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }
}
