export const processChange = (event) => {
  let payload
  const input = event.target

  if (!input) {
    payload = event
  } else {
    let value

    if (input.type === 'checkbox') {
      value = input.checked
    } else {
      value = input.value
    }
    payload = { [input.name]: value }
  }

  return payload
}

export const eventToState = ({ target }) => {
  switch (target.type) {
  case 'checkbox':
    return { [target.name]: target.checked }
  case 'radio':
    return { [target.name]: checkedRadioValueByName(target.name) }
  default:
    return { [target.name]: target.value }
  }
}

function checkedRadioValueByName(name) {
  const radios = document.querySelectorAll(`[name=${name}]`)
  for (const radio of radios) {
    if (radio.checked) {
      return radio.value === 'on' ? void 0 : radio.value
    }
  }
}
