import React from 'react';
import css from './SettingsPane.sass';

const SettingsPane = ({ children, className, ...rest }) => (
  <div className={`${css.settingsPane} ${(typeof className !== "undefined") ? className : ""}`} {...rest}>
    {children}
  </div>
)

export default SettingsPane;
