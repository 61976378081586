import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  TextField,
  Group,
  Radio,
  PhoneNumber,
  Textarea
} from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { addSellerOrTenantParticipant } from "~brokerage/actions/listings";
import css from "~brokerage/components/modals/ModalAddSellerTenant.sass";

class ModalAddSellerTenant extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    dispatch: PropTypes.func
  };

  state = {
    selectedPersonType: "seller"
  };

  handleSubmit = (_, values) => {
    this.props.dispatch(addSellerOrTenantParticipant(values));
    this.props.history.push({
      pathname: this.props.location.pathname,
      query: { ...this.props.location.query, modal: void 0 }
    });
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ selectedPersonType: value });
  };

  render() {
    return (
      <div className={css.base}>
        <Form onSubmit={this.handleSubmit} validate>
          <Header title="Add Seller/Tenant" />
          <Body>
            <Row className={css.radio} offset="0">
              <Group modifier="leftFloatedChildren">
                <Radio
                  className={css.seller}
                  name="role"
                  value="seller"
                  onChange={this.handleChange}
                  defaultChecked
                >
                  Seller
                </Radio>
                <Radio name="role" value="tenant" onChange={this.handleChange}>
                  Tenant
                </Radio>
              </Group>
            </Row>
            <Row offset="15">
              <Col variant="liquid" modifier="half">
                <TextField
                  name="firstName"
                  block
                  placeholder="First Name"
                  required
                  focused
                />
              </Col>
              <Col variant="liquid" modifier="half">
                <TextField
                  name="lastName"
                  block
                  placeholder="Last Name"
                  required
                />
              </Col>
            </Row>
            <Row offset="10">
              <Col variant="liquid" modifier="half">
                <PhoneNumber
                  name="primaryPhone"
                  block
                  placeholder="Primary Phone Number"
                  required
                />
              </Col>
              <Col variant="liquid" modifier="half">
                <PhoneNumber
                  name="secondaryPhone"
                  block
                  placeholder="Secondary Number"
                />
              </Col>
            </Row>
            <Row offset="10">
              <TextField
                name="email"
                type="email"
                block
                placeholder="Email Address"
                required
              />
            </Row>
            <Row offset="10">
              <Textarea
                maxLength={1000}
                name="notes"
                placeholder={`Enter notes about ${this.state.selectedPersonType} (optional).`}
              />
            </Row>
          </Body>

          <Footer>
            <Button type="submit" float="right" variant="primary">
              Add Person
            </Button>
          </Footer>
        </Form>
      </div>
    );
  }
}
export default withRouter(connect()(ModalAddSellerTenant));
