import {
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/constants/showings/statuses";

const getCoordinates = showings => {
  const activeShowings = showings.filter(
    s => ![STATUS_DECLINED, STATUS_CANCELLED].includes(s.status.toLowerCase())
  );
  const coordinates = activeShowings.map(
    s => `${s.coordinates.long},${s.coordinates.lat}`
  );

  const coordinatesString = coordinates.join(";");
  return coordinatesString;
};

export default getCoordinates;
