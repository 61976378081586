import findIndex from 'lodash/findIndex'

import {
  TEAMS_OPTIONS_REQUEST,
  TEAMS_OPTIONS_SUCCESS,
  TEAMS_OPTIONS_FAILURE,
  TEAM_INSTRUCTIONS_UPDATE
} from '~brokerage/constants/actionTypes'

function updateTeamInstructionsByListingId(entities, listingId, instructions) {
  const index = findIndex(entities, (e) => e.listingId === listingId)
  const newTeam = { ...entities[index], instructions: instructions }
  const newEntities = [...entities.slice(0, index), newTeam, ...entities.slice(index + 1)]

  return newEntities
}

function options(state = { entities: [], isFetching: false }, action) {
  switch (action.type) {
    case TEAMS_OPTIONS_REQUEST:
      return { ...state, isFetching: true }
    case TEAMS_OPTIONS_SUCCESS:
      return { ...state, entities: action.data.teams, isFetching: false }
    case TEAMS_OPTIONS_FAILURE:
      return { ...state, isFetching: false } // TODO error handling
    case TEAM_INSTRUCTIONS_UPDATE:
      return { ...state,
               entities: updateTeamInstructionsByListingId(state.entities,
                                                           action.listingId,
                                                           action.value)
             }
    default:
      return state
  }
}

export default options
