import css from './ShowingStatusIcon.sass'
import React from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import Icon from '~brokerage/components/shared/Icon'

const statusIconMapping = {
  declined: 'close',
  approved: 'checkCircle',
  pending: 'more2',
  not_sent: 'draft',
  cancelled: 'decline'
}

const ShowingStatusIcon = (props) => {
  return (
    <div className={css[camelCase(props.status)]}>
      <Icon name={statusIconMapping[props.status]}/>
    </div>
  )
}

ShowingStatusIcon.propTypes = {
  status: PropTypes.string
}
export default ShowingStatusIcon
