import * as types from '~brokerage/constants/actionTypes'

export function focus() {
  return {
    type: types.MESSAGE_FIELD_FOCUS
  }
}

export function receivedFocus() {
  return {
    type: types.MESSAGE_FIELD_RECEIVED_FOCUS
  }
}

export function lostFocus() {
  return {
    type: types.MESSAGE_FIELD_LOST_FOCUS
  }
}

export function changeText(text) {
  return {
    type: types.MESSAGE_FIELD_TEXT_CHANGE,
    text
  }
}

export function reset() {
  return {
    type: types.MESSAGE_FIELD_RESET
  }
}
