import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveTimeDateRestrictions } from "~brokerage/actions/listings";
import { Row, Label, Controls } from "~brokerage/components/shared/Form";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Button from "~brokerage/components/shared/Button";
import Restrictions from "./Restrictions";
import css from "~brokerage/components/listings/edit/TimeDateRestrictions/index.sass";

class TimeDateRestrictions extends React.PureComponent {
  static propTypes = {
    showingDetails: PropTypes.object,
    onNextClick: PropTypes.func,
    dispatch: PropTypes.func
  };

  state = {
    isAdding: false
  };

  enableAddMode = () => {
    this.setState({
      isAdding: true
    });
  };

  disableAddMode = () => {
    this.setState({
      isAdding: false
    });
  };

  handleChange = payload => {
    this.props.dispatch(saveTimeDateRestrictions(payload));
  };

  render() {
    return (
      <div>
        <Row offset="0">
          <Label>Showing Time/Date Restrictions</Label>
          <div className={css.restrictionsControls}>
            <Controls>
              <Restrictions
                restrictions={this.props.showingDetails.restrictions}
                isAdding={this.state.isAdding}
                onEnableAddMode={this.enableAddMode}
                onDisableAddMode={this.disableAddMode}
                onChange={this.handleChange}
              />
            </Controls>
          </div>
        </Row>
        <Row>
          <ButtonSet align="right">
            <Button
              variant="primary"
              onClick={this.props.onNextClick}
              disabled={this.state.isAdding}
            >
              Done
            </Button>
          </ButtonSet>
        </Row>
      </div>
    );
  }
}
export default connect()(TimeDateRestrictions);
