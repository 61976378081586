import css from "./SubHeading.sass";
import React, { useContext, useState, useRef } from "react";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";

import PencilFillIcon from "remixicon-react/PencilFillIcon";
import TimeSelector from "./TimeSelector";
import {
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/app/constants/showings/statuses";

const SubHeading = ({
  step,
  listingId,
  isEditDateTimeOpen,
  setIsEditDateTimeOpen,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam
}) => {
  const { times } = useContext(ShowingContext);
  const timeSelectorRef = useRef(null);

  const showing = times.length ? times[0] : {};
  const { duration, startTime, time, status } = showing;

  let displayTime;
  if (step === "date") {
    displayTime = startTime;
  } else {
    displayTime = time;
  }
  const disableTimeSelector = [STATUS_CANCELLED, STATUS_DECLINED].includes(
    status
  );

  const handleTimeSelector = () => {
    if (!disableTimeSelector)
      setIsEditDateTimeOpen(isEditDateTimeOpen => !isEditDateTimeOpen);
  };

  const start = displayTime && inUsersTimezone(displayTime).format("h:mma");
  const end =
    displayTime &&
    inUsersTimezone(displayTime).add(duration, "minutes").format("h:mma");

  const showTime = step === "time" && start && end;

  return (
    <div className={css.header}>
      <div className={css.date}>
        {inUsersTimezone(displayTime).format("MMMM Do")}
        <span className={css.day}>
          {inUsersTimezone(displayTime).format("ddd")}
        </span>
      </div>
      {showTime && (
        <div className={css.timeWrapper} ref={timeSelectorRef}>
          <div className={css.timeAndEditIcon} onClick={handleTimeSelector}>
            <div className={css.time}>{`${start} - ${end}`}</div>
            <PencilFillIcon size={17} />
          </div>
          {isEditDateTimeOpen && (
            <TimeSelector
              isEditDateTimeOpen={isEditDateTimeOpen}
              setIsEditDateTimeOpen={setIsEditDateTimeOpen}
              listingId={listingId}
              appointmentReason={appointmentReason}
              isListingOwnedByCurrentUserOrOfficeTeam={
                isListingOwnedByCurrentUserOrOfficeTeam
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SubHeading;
