import React from "react";
import Modal from "react-modal";
import { callApi } from "~brokerage/middlewares/api";

import AppointmentApprove from "./AppointmentApprove";
import AppointmentDecline from "./AppointmentDecline";
import AppointmentCancel from "./AppointmentCancel";

const AppointmentStatusModal = ({
  mode,
  showingId,
  setShowingId,
  setDisableModal = () => {},
  newStatus = "pending",
  refreshCallback = () => {},
  closeModal = () => {
    setShowingId(null);
  },
  altCall = null,
  altPush = null,
  fromRoute = false,
  seller = false,
  isInstructionsEditable = true
}) => {
  Modal.setAppElement(document.body);

  const InnerContents = {
    approved: AppointmentApprove,
    declined: AppointmentDecline,
    cancelled: AppointmentCancel
  }[newStatus];

  const getInstructions = async () => {
    if (altCall !== null) {
      return altCall(showingId);
    } else {
      return callApi(
        `showings/${showingId}/showing_instructions`,
        {},
        {},
        "get"
      );
    }
  };

  return (
    <Modal
      isOpen={showingId !== null}
      onRequestClose={() => closeModal(false)}
      style={customStyles}
    >
      {InnerContents && (
        <InnerContents
          mode={mode}
          showingId={showingId}
          closeModal={closeModal}
          refreshCallback={refreshCallback}
          setDisableModal={setDisableModal}
          getInstructions={getInstructions}
          altPush={altPush}
          fromRoute={fromRoute}
          seller={seller}
          isInstructionsEditable={isInstructionsEditable}
        />
      )}
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px",
    maxWidth: "600px"
  },
  overlay: { zIndex: 101 } //The AppointmentDetails modal is at 100
};

export default AppointmentStatusModal;
