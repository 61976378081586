import css from './index.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as steps from '~brokerage/constants/showings/newShowingSections'
import SelectListing from './SelectListing'
import SelectShowingAgent from './SelectShowingAgent'
import SelectDateAndTime from './SelectDateAndTime'

const Content = (props) => {
  return (
    <div className={css.base}>
      {(() => {
        switch (props.step) {
        case steps.SELECT_LISTING:
          return <SelectListing/>
        case steps.SELECT_SHOWING_AGENT:
          return <SelectShowingAgent/>
        case steps.SELECT_DATE_AND_TIME:
          return <SelectDateAndTime
            onCreateAndExitClick={props.onCreateAndExitClick}
            onCreateAndViewClick={props.onCreateAndViewClick}
            showing={props.showing}
          />
        }
      })()}
    </div>
  )
}

Content.propTypes = {
  step: PropTypes.number,
  onCreateAndExitClick: PropTypes.func,
  onCreateAndViewClick: PropTypes.func,
  showing: PropTypes.object
}

const mapStateToProps = state => ({ step: state.showings.new.step })
export default connect(mapStateToProps)(Content)
