import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import FilePreview from "~brokerage/components/shared/FileUpload/FilePreview";

const FileUploadComponent = ({
  setFilePreviews,
  removeFile,
  filePreviews,
  progress,
  uploadError,
  isUploading,
  isNotesMode
}) => {
  useEffect(() => {
    if (!isEmpty(uploadError)) {
      toast.error("Something went wrong. Please try again!");
      setFilePreviews([]);
    }
  }, [uploadError]);

  const handleRemoveFile = fileName => {
    setFilePreviews(prevPreviews =>
      prevPreviews.filter(preview => preview.file.name !== fileName)
    );
    removeFile(fileName);
  };

  return (
    <FilePreview
      files={filePreviews}
      handleRemoveFile={handleRemoveFile}
      progress={progress}
      isUploading={isUploading}
      isNotesMode={isNotesMode}
    />
  );
};

export default FileUploadComponent;
