import React from "react";
import css from "./SettingsNavCard.sass";
import { Link } from "react-router-dom";

const SettingsNavCard = ({ Icon, children, active, title, to }) => {
  const linkClass = active ? css.active : css.card;
  return (
    <Link className={linkClass} to={to}>
      <Icon size={24} className={css.icon} />
      <div className={css.details}>
        <h4 className={css.cardHeading}>{title}</h4>
        <div className={css.break}></div>
        <div className={css.cardSubText}>{children}</div>
      </div>
    </Link>
  );
};

export default SettingsNavCard;
