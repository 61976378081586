import React from "react";
import css from "./index.sass";
import { callApi } from "~brokerage/middlewares/api";
import SmartModalToggle from "~brokerage/components/modals/SmartModalToggle";
import ModalConfirmation from "~brokerage/components/modals/ModalConfirmation";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Footer = ({ id, handleCancel }) => {
  const cancelRoute = () => {
    callApi(`routes/${id}/cancel`, {}, {}, "post")
      .then(() => {
        toast.success("Route cancelled successfully.");
        handleCancel();
      })
      .catch(error => {
        console.log(error);
        toast.error(
          error?.response?.data?.error ||
            "Something went wrong cancelling the route."
        );
      });
  };

  return (
    <div className={css.footer}>
      <SmartModalToggle
        variant="link"
        modifier="underline colorGray"
        modal={
          <ModalConfirmation
            heading="Cancel Route"
            text="Are you sure?"
            confirmText="Yes, Cancel Route"
            confirmVariant="outlineRed"
            onConfirm={cancelRoute}
            cancelText="No, Keep Route"
            cancelVariant="outlineBlue"
          />
        }
        name="cancel_route"
      >
        Cancel Route
      </SmartModalToggle>
    </div>
  );
};

export default Footer;
