import css from "./Header.sass";
import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import CustomPropTypes from "~brokerage/libs/CustomPropTypes";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";

const Header = (
  { title, variant = "base", modifier, children, cancelPath, onClose },
  { location }
) => {
  const to = onClose
    ? null
    : cancelPath
    ? cancelPath
    : { ...location, query: omit(location.query, "modal") };

  return (
    <div className={composeClassName(css, variant, modifier)}>
      {variant === "custom" ? children : <h1 className={css.h1}>{title}</h1>}
      <div className={css.btnCancel}>
        <Button
          onClick={onClose}
          to={to}
          variant="cancel"
          modifier={variant === "custom" ? "size44" : ""}
        >
          <Icon name="decline2" />
        </Button>
      </div>
    </div>
  );
};

Header.contextTypes = {
  location: PropTypes.object
};

Header.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  cancelPath: PropTypes.string,
  modifier: CustomPropTypes.modifier
};
export default Header;
