import React, { useContext, useRef, useState } from "react";
import { usePrompt } from "~brokerage/app/helpers/usePrompt";
import { Switch, Route } from "react-router-dom";
import { SettingsContext } from "./context/SettingsContext";
import Content from "~brokerage/components/shared/Content";
import SettingsHeader from "~brokerage/components/settings/SettingsHeader";
import Personal from "./personal";
import System from "./system";
import Terms from "./terms";
import Brokerage from "./brokerage";
import NavigationPrompt from "~brokerage/components/shared/NavigationPrompt";
import Feedback from "./feedback";

const SettingsApp = ({ location, match, history }) => {
  const { pageState } = useContext(SettingsContext);
  const [showDialog, setShowDialog] = useState(false);
  const promptRef = useRef(null);
  const navUrl = useRef(null);

  usePrompt({
    ref: promptRef,
    when: pageState.inProgress,
    then: handleNavigation,
    message: {
      heading: "Confirm",
      body: "You have unsaved changes, are you sure you want to leave?"
    }
  });

  function handleNavigation(nav) {
    setShowDialog(true);
    navUrl.current = nav.pathname;
  }

  function handleConfirm() {
    promptRef.current();
    history.push(navUrl.current);
    setShowDialog(false);
  }

  function handleCancel() {
    setShowDialog(false);
  }

  const promptButtons = [
    {
      label: "Stay",
      variant: "outline",
      onClick: handleCancel
    },
    {
      label: "Leave",
      variant: "red",
      onClick: handleConfirm
    }
  ];

  return (
    <>
      <NavigationPrompt
        open={showDialog}
        message={{
          heading: "Confirm",
          body: "You have unsaved changes, are you sure you want to leave?"
        }}
        buttons={promptButtons}
      />
      <SettingsHeader location={location} />
      <Content header="single" style="noPadding">
        <Switch>
          <Route path={`${match.path}/brokerage`} component={Brokerage} />
          <Route path={`${match.path}`} exact={true} component={Personal} />
          <Route path={`${match.path}/system`} component={System} />
          <Route path={`${match.path}/terms`} exact={true} component={Terms} />
          <Route path={`${match.path}/feedback`} component={Feedback} />
        </Switch>
      </Content>
    </>
  );
};

export default SettingsApp;
