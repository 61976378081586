import React from "react";
import Modal from "react-modal";
import {
  Form,
  Row,
  Col,
  TextField,
  PhoneNumber,
  Textarea
} from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";

export default function NewBuyerModal({ isOpen, closeModal, onAddBuyer }) {
  const handleSubmit = (event, values) => {
    const { firstName, lastName } = values;
    onAddBuyer({ ...values, name: `${firstName} ${lastName}` });
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
    >
      <Form onSubmit={handleSubmit} validate>
        <Header title="Add Buyer" onClose={closeModal} />
        <Body>
          <Row offset="15">
            <Col variant="liquid" modifier="half">
              <TextField
                name="firstName"
                block
                placeholder="First Name"
                required
                focused
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <TextField
                name="lastName"
                block
                placeholder="Last Name"
                required
              />
            </Col>
          </Row>
          <Row offset="10">
            <Col variant="liquid" modifier="half">
              <PhoneNumber
                name="primaryPhone"
                block
                placeholder="Primary Phone Number"
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <PhoneNumber
                name="secondaryPhone"
                block
                placeholder="Secondary Number"
              />
            </Col>
          </Row>
          <Row offset="10">
            <TextField
              name="email"
              type="email"
              block
              placeholder="Email Address"
            />
          </Row>
          <Row offset="10">
            <Textarea
              name="notes"
              placeholder="Enter notes about buyer (optional)."
            />
          </Row>
        </Body>
        <Footer>
          <Button type="submit" float="right" variant="primary">
            Add Person
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
}

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    minWidth: "300px",
    maxWidth: "480px",
    padding: "0px"
  },
  overlay: { zIndex: 1001 }
};
