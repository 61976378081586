import css from '~brokerage/components/modals/ModalLogCall.sass'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logCall } from '~brokerage/actions/showings'
import { removeFromArray } from '~brokerage/libs/helpers/ArrayHelper'
import { Header, Body, Footer } from '~brokerage/components/modals/BaseModal'
import Avatar from '~brokerage/components/shared/Avatar'
import Button from '~brokerage/components/shared/Button'
import { withRouter } from 'react-router-dom'

class ModalLogCall extends React.PureComponent {
  static contextTypes = {
    currentUser: PropTypes.object,
  }

  static propTypes = {
    showingAgents: PropTypes.array,
    listingAgents: PropTypes.array,
    sellers: PropTypes.array,
    dispatch: PropTypes.func,
    showingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  state = {
    activeOutcomeIndex: -1,
    activePeople: [],
    hasErrors: false
  }

  outcomes = [
    'Appointment Confirmed',
    'Request Approved',
    'Changed Appointment',
    'Left a Voicemail',
    'Left a Message',
    'Scheduled Callback',
    'Line Busy',
    'No Answer',
    'Declined'
  ]

  handleOutcomeClick = (outcomeIndex) => {
    this.setState({ activeOutcomeIndex: outcomeIndex })
  }

  handlePersonClick = (person) => {
    let nextActivePeople = null
    const personIndex = this.state.activePeople.indexOf(person)

    if (personIndex === -1) {
      nextActivePeople = this.state.activePeople.concat(person)
    } else {
      nextActivePeople = removeFromArray(this.state.activePeople, personIndex)
    }

    this.setState({ activePeople: nextActivePeople })
  }

  handleNotesChange = (event) => {
    this.setState({ note: event.target.value })
  }

  handleSubmit = () => {
    if (this.state.activeOutcomeIndex === -1 || !this.state.activePeople.length) {
      this.setState({ hasErrors: true })
      return
    }

    const note = this.state.note ? `"${this.state.note}" — ` : ''
    const people = this.state.activePeople.map((person) => person.name).join(' and ')
    const outcome = this.outcomes[this.state.activeOutcomeIndex]
    this.props.dispatch(logCall(this.props.showingId, `${note}${outcome} by ${people} — call log`))

    this.props.history.push(this.props.location.pathname)
  }

  renderErrorSection = () => {
    return (
      <div className={css.section}>
        <div className={css.error}>People involved and Call outcome are required</div>
      </div>
    )
  }

  renderPeople(people, role) {
    return people.map((person) => {
      const isActive = this.state.activePeople.indexOf(person) !== -1
      const id = person.id || person.uuid
      const name = person.name || `${person.firstName} ${person.lastName}`

      return (
        <li className={css.li} key={id}>
          <Button variant="person" active={isActive} onClick={this.handlePersonClick.bind(this, person)}>
            <div className={css.personAvatar}>
              <Avatar size="30" src={person.photoUrl} name={name}/>
            </div>
            <h3 className={css.personH3}>{name}</h3>
            <div>{role}</div>
          </Button>
        </li>
      )
    })
  }

  render() {
    const { hasErrors, activeOutcomeIndex } = this.state
    const { listingAgents, showingAgents, sellers } = this.props

    return (
      <div className={css.base}>
        <Header title="Log Call"/>

        <Body>
          {hasErrors && this.renderErrorSection()}

          <div className={css.section}>
            <h2 className={css.h2}>Select people involved</h2>
            <ul className={css.ul}>
              {this.renderPeople(listingAgents, 'Listing Agent')}
              {this.renderPeople(showingAgents, 'Showing Agent')}
              {this.renderPeople(sellers, 'Seller')}
            </ul>
          </div>

          <div className={css.section}>
            <h2 className={css.h2}>Select call Outcome</h2>
            <ul className={css.ul}>
              {this.outcomes.map((outcome, outcomeIndex) => {
                const isActive = activeOutcomeIndex === outcomeIndex
                return (
                  <li className={css.li} key={outcomeIndex}>
                    <Button
                      variant="outcome"
                      active={isActive}
                      onClick={this.handleOutcomeClick.bind(this, outcomeIndex)}
                    >
                      {outcome}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </div>

          <div>
            <h2 className={css.h2}>Call notes <em className={css.h2Em}>(optional)</em></h2>
            <textarea
              className={css.textarea}
              placeholder="Enter details about the call"
              onChange={this.handleNotesChange}
            />
          </div>
        </Body>

        <Footer>
          <p className={css.footerP}>Logged call will be saved as an internal note</p>
          <div className={css.footerBtn}>
            <Button variant="primary" onClick={this.handleSubmit}>Log Call</Button>
          </div>
        </Footer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { entity } = state.showings.single.showing
  const { entities: sellers } = state.showings.single.sellerTenantInvolvement

  return {
    showingId: state.showings.single.showing.entity.id,
    showingAgents: entity ? entity.showingAgents : [],
    listingAgents: entity ? entity.listingAgents : [],
    sellers
  }
}
export default withRouter(connect(mapStateToProps)(ModalLogCall));
