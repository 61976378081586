import css from './assistants.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  fetchBrokerageUsers,
  inviteBrokerageAgent,
  removeInvitedBrokerageUser,
  clearErrors
} from '~brokerage/actions/settings'
import { USER_ROLE_OPTIONS } from '~brokerage/constants/settings/userRoleOptions'
import { labelFromValue } from '~brokerage/libs/helpers/SelectOptionsHelper'
import { filterUnregisteredAgents } from '~brokerage/libs/helpers/inviteUserHelper'
import PremiumButtonWrapper, { PREMIUM_FEATURE_TYPES } from '~brokerage/components/shared/PremiumButtonWrapper'
import Content from '~brokerage/components/shared/Content'
import InvitedUser from '~brokerage/components/shared/InvitedUser'
import Button from '~brokerage/components/shared/Button'
import Link from '~brokerage/components/shared/Link'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Search from '~brokerage/components/shared/Search'
import ModalError from '~brokerage/components/modals/ModalError'
import PeopleHeader from '~brokerage/components/people/PeopleHeader'
import UnregisteredAgent from '~brokerage/components/people/assistants/UnregisteredAgent'
import filter from 'lodash/filter'
import values from 'lodash/values'

class Assistants extends PureComponent {
  AGENT_ROLE = 'agent'

  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    errors: PropTypes.object,
    dispatch: PropTypes.func,
    invitedUsers: PropTypes.object,
    unregisteredAgents: PropTypes.object
  }

  constructor() {
    super()
    this.state = {filter: ''}
  }

  componentWillMount() {
    this.props.dispatch(fetchBrokerageUsers())
  }

  inviteAgent = (agentId) => {
    this.props.dispatch(inviteBrokerageAgent({ agentId: agentId }))
  }

  editUser = (id) => {
    const { location, history } = this.props
    history.push({...location, query: { modal: 'invite_user', user_id: id } })
  }

  removeUser = (id) => {
    this.props.dispatch(removeInvitedBrokerageUser(id)).then(
      () => this.props.dispatch(fetchBrokerageUsers())
    )
  }

  handleFilterChange = (event) => this.setState({filter: event.target.value})

  handleErrorsModalClose = () => this.props.dispatch(clearErrors())

  filteredUnregisteredAgents() {
    return filterUnregisteredAgents(this.props.unregisteredAgents, this.state.filter)
  }

  invitedAgentUsers() {
    return filter(this.props.invitedUsers, (user) => user.role === this.AGENT_ROLE)
  }

  invitedStaffUsers() {
    return filter(this.props.invitedUsers, (user) => user.role !== this.AGENT_ROLE)
  }

  renderInvitedAgentUsersSection() {
    const users = this.invitedAgentUsers()
    if (!users.length) return

    return (
      <section className={css.section}>
        <h2 className={css.h2}>Invited Agents</h2>
        {users.map(user => {
          return <InvitedUser id={user.id}
                              key={user.id}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              description={user.city}
                              onRemove={this.removeUser}/>
        })}
      </section>
    )
  }

  renderInvitedStaffUsersSection() {
    const users = this.invitedStaffUsers()
    return <section className={css.section}>
      <h2 className={css.h2}>Invite Non-MLS User: Assistant, Broker, or Showing Coordinator</h2>
      {users.map(user => {
        return <InvitedUser id={user.id}
                            key={user.id}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            description={labelFromValue(USER_ROLE_OPTIONS, user.role)}
                            onRemove={this.removeUser}
                            onEdit={this.editUser}/>
      })}
      <div className={css.borderedBlock}>
        <div className={css.inner}>
          <PremiumButtonWrapper featureType={PREMIUM_FEATURE_TYPES.INVITE_NON_MLS_USERS}>
            <Button variant="outline"
                    to={{ ...this.props.location, query: { modal: 'invite_user' } }}>
              <IconedLabel icon="userAdd" label="Invite Non-MLS User"/>
            </Button>
          </PremiumButtonWrapper>
        </div>
      </div>
    </section>
  }

  renderUnregisteredAgentsSection() {
    return <section className={css.section}>
      <div>
        <div className={css.pullRight}>
          Missing Agents? <Link data-intercom-launcher="" href="#">Contact Us</Link>
        </div>
        <h2 className={css.h2}>
          {Object.keys(this.props.unregisteredAgents).length} Unregistered Agents in Your Brokerage
        </h2>
      </div>

      <div className={css.borderedBlock}>
        <div className={css.inner}>
          <div className={css.search}>
            <Search placeholder="Filter by Name or Office Location"
                    onChange={this.handleFilterChange}/>
          </div>
        </div>
      </div>
      {
        values(this.filteredUnregisteredAgents()).map(agent => {
          return <UnregisteredAgent id={agent.agentId}
                                    key={agent.agentId}
                                    firstName={agent.firstName || '-'}
                                    lastName={agent.lastName || ''}
                                    city={agent.city || '-'}
                                    onInvite={this.inviteAgent}/>
        })}
    </section>
  }

  render() {
    const { errors } = this.props
    const haveErrors = Object.keys(errors).length

    return (
      <div>
        <PeopleHeader location={this.props.location} />
        <Content header="single">
          <div className={css.base}>
            {this.renderInvitedStaffUsersSection()}
            {this.renderInvitedAgentUsersSection()}
            {this.renderUnregisteredAgentsSection()}
          </div>
        </Content>
        { haveErrors && <ModalError errors={errors}
                                   title="An error happened"
                                   onClose={this.handleErrorsModalClose}/>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { invitedUsers, unregisteredAgents, errors } = state.settings.brokerage

  return {
    invitedUsers,
    unregisteredAgents,
    errors
  }
}
export default connect(mapStateToProps)(Assistants)
