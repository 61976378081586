import React, { useEffect, useRef, useState } from "react";
import Tooltip from "~brokerage/components/shared/Tooltip";
import css from "./TruncatedCell.sass";

const TruncatedCell = ({ content, id }) => {
  const cellRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (cellRef.current) {
      setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth);
    }
  }, [content]);

  return (
    <td className={css.td}>
      <Tooltip text={content} direction="up" disabled={!isTruncated}>
        <div ref={cellRef} className={css.truncate} id={id}>
          {content}
        </div>
      </Tooltip>
    </td>
  );
};

export default TruncatedCell;
