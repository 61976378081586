import React from "react";

import Loader from "~brokerage/components/shared/Loader";
import { Form, Section, Row } from "~brokerage/components/shared/Form";

import SelectLockbox from "./SelectLockbox";
import ShowingOptions from "./ShowingOptions";
import Header from "../Header";
import AdditionalDetails from "../AdditionalDetails";
import Button from "~brokerage/components/shared/Button";

const ModalContents = ({
  loading,
  details,
  changeDetails,
  pushDetails,
  resetDetails,
  closeModal,
  mode,
  seller,
  isInstructionsEditable
}) => {
  if (loading) {
    return <Loader active />;
  }

  const lockboxKind = details.lockboxKind || "";
  const lockboxBrand = details.lockboxBrand || "";
  const lockboxCode = details.lockboxCode || "";
  const message = details.message || "";
  const accompanyAgent = details.accompanyAgent || false;
  const accompanySeller = details.accompanySeller || false;
  const requestACall = details.requestACall || false;
  const saveLabel =
    mode === "edit" ? "Save Instructions" : "Approve and Send Instructions";

  return (
    <Form onSubmit={pushDetails} validate>
      <Header headerText="Showing Access" closeModal={closeModal} />

      {!seller && (
        <>
          <Section>
            <SelectLockbox
              lockboxKind={lockboxKind}
              lockboxBrand={lockboxBrand}
              lockboxCode={lockboxCode}
              changeDetails={changeDetails}
            />
          </Section>

          <Section>
            <ShowingOptions
              accompanyAgent={accompanyAgent}
              accompanySeller={accompanySeller}
              requestACall={requestACall}
              changeDetails={changeDetails}
            />
          </Section>
        </>
      )}

      <Section heading="Additional Instructions">
        <AdditionalDetails
          message={message}
          changeDetails={changeDetails}
          placeholder="Enter showing instructions"
          isEditable={isInstructionsEditable}
        />
      </Section>

      <Row>
        {isInstructionsEditable && (
          <Button
            onClick={resetDetails}
            float="left"
            variant="outline"
            modifier="bigger"
          >
            Clear
          </Button>
        )}
        <Button type="submit" float="right" variant="green" modifier="bigger">
          {saveLabel}
        </Button>
      </Row>
    </Form>
  );
};

export default ModalContents;
