import {
  PEOPLE_CATEGORIES_REQUEST,
  PEOPLE_CATEGORIES_SUCCESS,
  PEOPLE_CATEGORIES_FAILURE
  } from '~brokerage/constants/actionTypes'

const initialState = {
  entities: [],
  isFetching: false
}

export default function categories(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_CATEGORIES_REQUEST:
      return { ...state, isFetching: true }
    case PEOPLE_CATEGORIES_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.categories }
    case PEOPLE_CATEGORIES_FAILURE:
      return { ...state, isFetching: false, errors: action.errors }
    default:
      return state
  }
}
