import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { callApi } from "brokerage/middlewares/api";
import css from "./AgentInfo.sass";

const AgentInfo = ({ agentId }) => {
  const [agent, setAgent] = useState(null);

  useEffect(async () => {
    if (agentId) {
      await callApi(`people/${agentId}`, {}, {}, "get")
        .then(response => {
          if (response.data && response.data.person) {
            setAgent(response.data.person);
          } else {
            toast.error("Invalid API response for user data fetch.");
          }
        })
        .catch(err => {
          toast.error(err);
        });
    }
  }, [agentId]);

  return (
    <>
      {agent && (
        <div className={css.agentInfoArea}>
          <span className={css.highlightedText}>Showing Agent: </span>
          {agent.name}
          {agent.brokerageName && ` of ${agent.brokerageName}`}
          <br/>
          <span className={css.highlightedText}>Email: </span> {agent.email}
          <br/>
          <span className={css.highlightedText}>Phone number: </span>{agent.phone}
        </div>
      )}
    </>
  );
};

export default AgentInfo;
