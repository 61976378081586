import css from './UnregisteredAgent.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '~brokerage/components/shared/Button'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Avatar from '~brokerage/components/shared/Avatar'
export default class UnregisteredAgent extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,

    onInvite: PropTypes.func
  }

  handleInvite = () => {
    const { id, onInvite } = this.props
    if (onInvite) onInvite(id)
  }

  render() {
    const { firstName, lastName, city } = this.props

    const fullName = `${firstName} ${lastName}`

    return (
      <div className={css.borderedBlock}>
        <div className={css.inline}>
          <Avatar size="30" name={fullName}/>
        </div>
        {fullName}

        <div className={css.rightInnerBordered}>
          <Button variant="grayText" onClick={this.handleInvite}>
            <IconedLabel icon="userAdd" label="Invite"/>
          </Button>
        </div>
        <IconedLabel className={css.rightInnerBordered + ' ' + css.column150}
                     icon="location"
                     label={city}/>
      </div>
    )
  }
}
