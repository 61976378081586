import React from "react";
import FilePdfFillIcon from "remixicon-react/FilePdfFillIcon";
import { formatSize } from "~brokerage/components/shared/FileUpload/utils";
import Link from "~brokerage/components/shared/Link";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { TIMESTAMP_REGEX } from "./constants";
import css from "./index.sass";

const File = ({ file: { name, size, url, createdAt } }) => {
  const createdTime = inUsersTimezone(createdAt).format(
    "ddd, MMMM DD, [at] h:mm a"
  );

  const fileName = name.replace(TIMESTAMP_REGEX, "");
  return (
    <div className={css.fileCard}>
      <div className={css.fileIcon}>
        <FilePdfFillIcon size="32" />
      </div>
      <div className={css.fileInfo}>
        <Link href={url} external={true}>
          <div className={css.fileName}>{fileName}</div>
        </Link>
        <div className={css.fileMeta}>
          <span>{formatSize(size)}</span>
          <span>{createdTime}</span>
        </div>
      </div>
    </div>
  );
};

export default File;
