import css from './Initials.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import take from 'lodash/take'

export default class Initials extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    size: PropTypes.string,
    name: PropTypes.string
  }

  nameToInitials() {
    const letters = take(this.props.name.replace(/\(\d+\)|[^a-z0-9\s]/ig, '').split(' '), 2)
    return letters.map((s) => s.charAt(0).toUpperCase()).join('')
  }

  render() {
    const size = this.props.size || 30

    const style = {
      width: `${size}px`,
      height: `${size}px`,
      lineHeight: `${size}px`,
      fontSize: `${Math.floor(size / 2.3)}px`
    }

    return (
      <div className={css.base} style={style}>
        {this.nameToInitials()}
      </div>
    )
  }
}
