import React, { useState } from "react";
import { connect } from "react-redux";
import Content from "~brokerage/components/shared/Content";
import css from "./index.sass";
import CalendarHeaderNav from "~brokerage/components/calendar/External/CalendarHeaderNav";
import CalendarSection from "~brokerage/components/calendar/CalendarSection";
import FilterSection from "~brokerage/components/calendar/FilterSection";
import { useLocation } from "react-router-dom";

const DEFAULT_REASON_FILTER = "all";

const Calendar = props => {
  const location = useLocation();

  const { query } = location;
  const [loading, setLoading] = useState(true);
  const [showings, setShowings] = useState([]);
  const [filters, setFilters] = useState({
    addressSearchTerm:
      typeof query?.address !== "undefined" ? query?.address : "",
    agentSearchTerm: "",
    isListingAgent: true,
    isShowingAgent: true,
    showPending: true,
    showApproved: true,
    showCancelled: true,
    showDeclined: true,
    showExpired: true,
    showOthers: true,
    appointmentReasonFilter: DEFAULT_REASON_FILTER,
    buyer: null
  });

  const { isImpersonating, name } = props;

  const setFilter = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const flipFilterSwitch = filter => {
    setFilters({ ...filters, [filter]: !filters[filter] });
  };

  const renderImpersonateHeader = () => {
    return (
      <div className={css.section}>{`Logged in on behalf of "${name}"`}</div>
    );
  };

  return (
    <div>
      {query.return && (
        <CalendarHeaderNav location={location} returnUrl={query.return} />
      )}
      <div className={css.base}>
        {isImpersonating && renderImpersonateHeader()}
      </div>
      <Content header="single">
        <div className={css.sidebar}>
          <FilterSection
            loading={loading}
            filters={filters}
            setFilter={setFilter}
            flipFilterSwitch={flipFilterSwitch}
          />
        </div>
        <div className={css.calendarBody}>
          <CalendarSection
            loading={loading}
            setLoading={setLoading}
            showings={showings}
            setShowings={setShowings}
            filters={filters}
          />
        </div>
      </Content>
    </div>
  );
};

const mapStateToProps = state => {
  const { isImpersonating, name } = state.currentUser;

  return {
    isImpersonating,
    name
  };
};

export default connect(mapStateToProps)(Calendar);
