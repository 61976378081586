import React, { useState } from "react";
import { connect } from "react-redux";

import css from "./PriceAnalysis.sass";
import AnalysisFilters from "./AnalysisFilters";
import AnalysisReport from "./AnalysisReport";
import {
  updateAnalysisFilters,
  fetchPriceAnalysis
} from "~brokerage/actions/reports";
import Loader from "~brokerage/components/shared/Loader";
import FilterToggle from "./FilterToggle";
import { convertFiltersToApiParams } from "./helpers";

const PriceAnalysis = ({
  enablePrint,
  currentFilters,
  dispatch,
  data,
  isFetching,
  error
}) => {
  const [showFilterPanel, setShowFilterPanel] = useState(true);

  const emptyMessage =
    error || `'Generate report' to apply filters and create report.`;

  const filterStyle = showFilterPanel ? css.showSidebar : "";

  return (
    <>
      <div className={`${css.sidebar} ${filterStyle}`}>
        <FilterToggle
          showPanel={showFilterPanel}
          onToggle={() => setShowFilterPanel(!showFilterPanel)}
        />
        <AnalysisFilters
          currentFilters={currentFilters}
          onSubmit={newFilters => {
            setShowFilterPanel(false);
            enablePrint(true);
            const apiParams = convertFiltersToApiParams(newFilters);
            dispatch(fetchPriceAnalysis(apiParams));
            dispatch(updateAnalysisFilters(newFilters));
          }}
        />
      </div>
      <div className={css.graphBody}>
        <FilterToggle
          showPanel={showFilterPanel}
          onToggle={() => setShowFilterPanel(!showFilterPanel)}
        />
        {isFetching ? (
          <Loader active />
        ) : data ? (
          <AnalysisReport analysisData={data} />
        ) : (
          <div className={css.emptyComponent}>
            <p className={css.emptyMessage}>{emptyMessage}</p>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const filters = state.reports.analysis.filters;
  const { data, isFetching, error } = state.reports.analysis.analysisData;
  return { currentFilters: filters, data, isFetching, error };
};

export default connect(mapStateToProps)(PriceAnalysis);
