import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchPersonDetails, fetchPersonListings } from '~brokerage/actions/people'
import Loader from '~brokerage/components/shared/Loader'
import NoMatch from '~brokerage/components/shared/NoMatch'
import ShortInfo from '~brokerage/components/people/show/ShortInfo'
import Contacts from '~brokerage/components/people/show/Contacts'
import ListingsList from '~brokerage/components/people/show/ListingsList'

class Show extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    person: PropTypes.object,
    isFetching: PropTypes.bool,
    errors: PropTypes.bool,
    match: PropTypes.object,
    dispatch: PropTypes.func
  }

  static defaultProps = {
    isFetching: true
  }

  componentWillMount() {
    this.props.dispatch(fetchPersonDetails(this.props.match.params.personId))
    this.props.dispatch(fetchPersonListings(this.props.match.params.personId))
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.personId !== nextProps.match.params.personId) {
      this.props.dispatch(fetchPersonDetails(nextProps.match.params.personId))
      this.props.dispatch(fetchPersonListings(nextProps.match.params.personId))
    }
  }

  render() {
    const { person, isFetching, errors } = this.props

    if (isFetching) return <Loader active />
    if (errors) return <NoMatch notify={false} />

    return (
      <div>
        <ShortInfo person={person} />
        <Contacts person={person} />
        <ListingsList personId={this.props.match.params.personId} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { entity: person, isFetching, errors } = state.people.single.person

  return {
    person,
    isFetching,
    errors
  }
}
export default connect(mapStateToProps)(Show)
