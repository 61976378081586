import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import css from "./Actions.sass";
import { Footer } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { useHistory, useLocation } from "react-router-dom";
import omit from "lodash/omit";
import { updateShowingRoute } from "~brokerage/actions/routes";
import { isForbidden } from "~brokerage/app/helpers/showingConflicts.js";

const Actions = ({ showings, dispatch, unsavedChanges = [] }) => {
  const { times } = useContext(ShowingContext);
  const [disabled, toggleDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (times) {
      const hasConflicts = times.some(t => isForbidden(t));
      const isModified = times.some(t => t.modified);
      const isDisabled = hasConflicts || !isModified;
      toggleDisabled(isDisabled);
    }
  }, [times]);

  const onSave = () => {
    const update = [...unsavedChanges];
    times.forEach(showing => {
      const origShowing = showings.find(s => s.id === showing.id);
      const reqTime =
        showing.time && showing.time.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (
        (reqTime && origShowing.requestedTimeRaw !== reqTime) ||
        showing.duration !== origShowing.duration ||
        showing.travelTime.toString() !== origShowing.travelTime.toString()
      ) {
        const change = {
          id: showing.id,
          requestedTimeRaw: reqTime,
          type: origShowing.type,
          duration: showing.duration,
          travelTime: showing.travelTime,
          position: showing.position
        };
        if (origShowing.type === "stop" && origShowing.id < 0) {
          change.address = origShowing.address;
          change.coordinates = origShowing.coordinates;
        }
        const updateIndex = update.findIndex(c => c.id === change.id);
        if (updateIndex !== -1) {
          update[updateIndex] = change;
        } else {
          update.push(change);
        }
      }
    });
    dispatch(updateShowingRoute({ unsavedChanges: update, optimized: false }));
    history.replace({
      ...location,
      query: omit(location.query, ["modal", "routeId"])
    });
  };

  return (
    <Footer>
      <div className={css.footerBtn}>
        <Button
          variant="outline"
          to={{
            ...location,
            query: omit(location.query, ["modal", "routeId"])
          }}
        >
          Cancel
        </Button>
        <Button disabled={disabled} variant="primary" onClick={onSave}>
          Apply
        </Button>
      </div>
    </Footer>
  );
};

export default connect()(Actions);
