import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { callApi } from "~brokerage/middlewares/api";
import { SHOWINGS_TOS_URL } from "~brokerage/constants/links";
import Footer from "./Footer";
import css from "./index.sass";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "30%",
    maxWidth: "600px",
    minWidth: "500px",
    minHeight: "380px",
    padding: "0px"
  },
  overlay: { zIndex: 9999999 }
};

const TermsAndConditions = ({ alreadyAccepted }) => {
  const [loading, setLoading] = useState(false);
  const [userAccepted, setUserAccepted] = useState(false);

  const modalOpen = alreadyAccepted == false && userAccepted == false;

  const acceptTerms = async () => {
    setLoading(true);
    await callApi(
      `terms_and_conditions`,
      {},
      { user: { terms_accepted: true } },
      "patch"
    )
      .then(response => {
        setUserAccepted(response.data.terms_accepted);
        toast.success("Terms & Conditions Accepted");
        setLoading(false);
      })
      .catch(() => toast.error("Something went wrong, please try again"));
  };

  Modal.setAppElement(document.body);

  return (
    <Modal isOpen={modalOpen} onRequestClose={() => {}} style={customStyles}>
      <div className={css.baseContainer}>
        <div className={css.mainHeader}>Terms & Conditions</div>
        <div className={css.central}>
          <a
            href={SHOWINGS_TOS_URL}
            target="_blank" //Open in new tab
            rel="noreferrer noopener" //Optimization for link
          >
            <button className={css.linkButton}>View End-User Agreement</button>
          </a>
        </div>
        <Footer changeDetails={() => !loading && acceptTerms()} />
      </div>
    </Modal>
  );
};

export default TermsAndConditions;
