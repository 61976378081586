import { CALL_API } from '~brokerage/middlewares/api'

export function fetchListingCategories(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listing/categories`
    }
  }
}

export function fetchListingStatuses(params, types) {
  return {
    [CALL_API]: {
      types,
      params,
      endpoint: 'listing/statuses'
    }
  }
}

export function fetchAgents(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listing/agents`
    }
  }
}
