import React from "react";
import { Row, Label, Controls } from "~brokerage/components/shared/Form";
import Switch from "~brokerage/components/shared/Switch";
import css from "./ShowingInstructions.sass";

const BuyerNameRequired = ({
  mlsBuyerRequiredEnabled,
  buyerNameRequired,
  handleChange
}) => {
  if (!mlsBuyerRequiredEnabled) return null;

  return (
    <Row>
      <Label valign="switch">Buyer Name Required</Label>
      <div className={css.switchControls}>
        <Controls>
          <Switch
            name="buyerNameRequired"
            checked={buyerNameRequired}
            onChange={handleChange}
          />
        </Controls>
      </div>
    </Row>
  );
};

export default BuyerNameRequired;
