import css from './showing-coordinator.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchShowingCoordinators, updateShowingCoordinatorEnabled, removeInvitedBrokerageUser } from '~brokerage/actions/settings'
import Checkbox from '~brokerage/components/shared/Form/Checkbox'
import Avatar from '~brokerage/components/shared/Avatar'
import Button from '~brokerage/components/shared/Button'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Icon from '~brokerage/components/shared/Icon'
import Loader from '~brokerage/components/shared/Loader'
import { format as formatPhone } from '~brokerage/libs/helpers/PhonesHelper'
import { plural } from '~brokerage/libs/helpers/FormatHelper'
import _values from 'lodash/values'

export class ShowingCoordinator extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
    coordinators: PropTypes.object,
    showingCoordinatorEnabled: PropTypes.bool
  }

  componentWillMount() {
    this.props.dispatch(fetchShowingCoordinators())
  }

  handleUpdateEnabled = (event) => {
    this.props.dispatch(updateShowingCoordinatorEnabled(event.target.checked))
  }

  renderActionsCell(coordinator) {
    if(coordinator.isCurrentUser) {
      return (
        <td width="1%" className={css.td}></td>
      )
    } else {
      return (
        <td width="1%" className={css.td}>
          <Icon name="message"/>
        </td>
      )
    }
  }

  editCoordinator = (id) => {
    const { location, history } = this.props
    history.push({...location, query: { modal: 'invite_user', user_id: id } })
  }

  removeCoordinator = (id) => {
    this.props.dispatch(removeInvitedBrokerageUser(id))
      .then(() => this.props.dispatch(fetchShowingCoordinators()))
  }

  renderShowingCoordinatorsSection() {
    let coordinators = _values(this.props.coordinators)
    let coordinatorsLength = coordinators.length

    if(!coordinatorsLength) return

    return(
      <div className={css.tableWrapper}>
        <div className={css.tableHeader}>
          { coordinatorsLength } Showing Coordinator{plural(coordinatorsLength)}
        </div>
        <table className={css.table}>
          <tbody>
            {
              coordinators.map(coordinator => {
                const coordinatorName = [coordinator.firstName, coordinator.lastName].join(' ')
                return(
                  <tr key={coordinator.id} className={css.tr}>
                    <td className={css.td}>
                      <div className={css.avatar}>
                        <Avatar size="30" src={coordinator.photoUrl} name={coordinatorName}/>
                      </div>
                      <div className={css.name}>{coordinatorName}</div>
                    </td>
                    {this.renderActionsCell(coordinator)}
                    <td className={css.td}>{coordinator.email}</td>
                    <td className={css.td}>{coordinator.phone ? formatPhone(coordinator.phone) : '-'}</td>
                    <td className={css.td} width="95px">
                      <Button variant="grayText" onClick={() => this.editCoordinator(coordinator.id)}>
                        <IconedLabel icon="edit" label="Edit"/>
                      </Button>
                    </td>
                    <td className={css.td} width="120px">
                      <Button variant="grayText" onClick={() => this.removeCoordinator(coordinator.id)}>
                        <IconedLabel icon="close" label="Remove"/>
                      </Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    if(this.props.isFetching) return <Loader active/>

    return (
      <div className={css.base}>
        <div className={css.title}>
          Enable Showing Coordinator for Brokerage
        </div>
        <div className={css.checkbox}>
          <Checkbox name="showing_coordinator"
                    onChange={this.handleUpdateEnabled}
                    defaultChecked={this.props.showingCoordinatorEnabled}>
            Enable
          </Checkbox>
        </div>
        <div className={css.description}>
          Agents will be able to enable Showing Coordinator for their listings.
        </div>
        { this.renderShowingCoordinatorsSection() }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isFetching, coordinators, showingCoordinatorEnabled } = state.settings.showingCoordinators

  return { isFetching, coordinators, showingCoordinatorEnabled }
}
export default connect(mapStateToProps)(ShowingCoordinator)
