import css from './Step.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  STEP_DONE as DONE,
  STEP_EDITING as EDITING
} from '~brokerage/constants/showings/newShowingSections'
import StepHeading from './StepHeading'

export default class Step extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    index: PropTypes.string,
    doneText: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.node,
    onHeadingDoneClick: PropTypes.func
  }

  render() {
    const { variant = 'base', index, doneText, placeholder, onHeadingDoneClick, children } = this.props
    const isDone = this.props.variant === DONE
    const isEditing = this.props.variant === EDITING

    return (
      <li className={css[variant]}>
        <StepHeading
          index={index}
          doneText={doneText}
          variant={variant}
          onDoneClick={onHeadingDoneClick}
        />
        <div className={css.body}>
          {(() => {
            if (isDone || isEditing) {
              return <div className={css.content}>{children}</div>
            }

            return <p className={css.placeholder}>{placeholder}</p>
          })()}
        </div>
      </li>
    )
  }
}
