import css from './PhoneNumber.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'
import { REQUIRED_IS_EMPTY, TEL_INVALID } from '~brokerage/constants/validation'
import { PHONE_REGEX } from '~brokerage/constants/fieldsValuePatterns'
import ErrorTag from './ErrorTag'
import { withFormBinding } from './Form'
class PhoneNumber extends Component {
  static propTypes = {
    focused: PropTypes.bool,
    block: PropTypes.bool,
    variant: PropTypes.string,
    name: PropTypes.string
  }

  state = {
    errors: false,
    value: this.props.defaultValue || ""
  }

  componentDidMount() {
    if (this.props.focused) {
      ReactDOM.findDOMNode(this).focus()
    }
  }

  validate = () => {
    const errors = []
    const value = this.phoneNumberValue()

    if (this.el.required && !value.length) {
      errors.push(REQUIRED_IS_EMPTY)
    }

    if (value && !PHONE_REGEX.test(value)) {
      errors.push(TEL_INVALID)
    }

    if (!errors.length) {
      this.setState({ errors: false })
      return true
    }

    this.setState({ errors })
    return false
  }

  handleErrors = (errors) => {
    this.setState({
      errors
    })
  }

  value = () => {
    return {
      [this.props.name]: this.phoneNumberValue()
    }
  }

  phoneNumberValue() {
    return this.state.value
  }

  cleanValue(value) {
    if (value) {
      return value.replace(/\D/g, '').substring(0, 10)
    } else {
      return ''
    }
  }

  phoneNumberDisplayValue() {
    return this.phoneNumberValue()
  }

  handleFocus = () => {
    if (this.state.errors) {
      this.setState({
        errors: false
      })
    }
  }

  onChange = () => {
    this.setState({
      value: this.cleanValue(this.el.value)
    })
  }

  renderErrors() {
    const { errors } = this.state

    if (!errors) {
      return false
    }

    return (
      <div className={css.textFieldErrors}>
        {errors.map((error, errorIndex) =>
          <ErrorTag type={error} key={errorIndex}/>
        )}
      </div>
    )
  }

  render() {
    const { variant, block, ...rest } = this.props
    const { errors } = this.state
    delete rest.focused
    delete rest.defaultValue

    const inputClassNames = classNames([
      css[`textField${variant ? upperFirst(variant) : ''}`],
      block && css.textFieldBlock,
      errors && css.textFieldError
    ])

    return (
      <div>
        <input
          type="text"
          className={inputClassNames}
          ref={(el) => this.el = el}
          value={this.phoneNumberDisplayValue()}
          onChange={this.onChange}
          onFocus={this.handleFocus}
          {...rest}
        />
        {this.renderErrors()}
      </div>
    )
  }
}
export default withFormBinding(PhoneNumber)
