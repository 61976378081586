import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { matchPath } from "react-router";

export const usePrompt = ({ when, then, ref, message, exceptions = [] }) => {
  const history = useHistory();

  const onWindowOrTabClose = event => {
    if (!when) {
      return;
    }

    if (typeof event == "undefined") {
      event = window.event;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  const isException = e => {
    let match = false;
    exceptions.forEach(path => {
      const isMatch = matchPath(e.pathname, {
        path,
        exact: false,
        strict: false
      });
      if (isMatch) {
        match = true;
        return;
      }
    });
    return match;
  };

  useEffect(() => {
    if (when) {
      ref.current = history.block(tx => {
        if (!isException(tx)) {
          then(tx);
          return false;
        }
        return true;
      });
    } else {
      ref.current = null;
    }

    window.addEventListener("beforeunload", onWindowOrTabClose);

    return () => {
      if (ref.current) {
        ref.current();
        ref.current = null;
      }

      window.removeEventListener("beforeunload", onWindowOrTabClose);
    };
  }, [when, then]);
};
