import css from './Dot.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Dot extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string.isRequired,
    size: PropTypes.string
  }

  render() {
    const { size = '8', variant } = this.props

    return (
      <i className={classNames(css[variant], css[`size${size}`])}/>
    )
  }
}
