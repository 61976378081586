import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";

const explanations = {
  client_availability_changed: "Client Availability Changed",
  my_availability_changed: "My Availability Changed",
  client_not_interested: "Client No Longer Interested",
  scheduled_in_error: "Scheduled In Error"
};

const Cancelled = ({ message, bubbleOptions }) => (
  <Bubble variant="red" {...bubbleOptions}>
    <p className={css.p}>
      {message.senderName} <b>Cancelled</b> the showing request on{" "}
      <b className={css.nobr}>{message.requestDate}</b> from{" "}
      <b className={css.nobr}>
        {message.requestStartTime} to {message.requestEndTime}
      </b>
      .
    </p>
    {message.explanation && (
      <p className={css.p}>{explanations[message.explanation]}</p>
    )}
  </Bubble>
);

Cancelled.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Cancelled;
