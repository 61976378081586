const AVAILABLE_APPOINTMENT_STATUS = {
  approved: "approved",
  declined: "declined",
  cancelled: "cancelled",
  expired: "expired"
};

// HACK:This will act as a placeholder text
export const DISABLED_STATUSES = {
  selectAnAppointmentStatus: "Select an Appointment Status"
};

export const APPOINTMENT_STATUSES = {
  ...DISABLED_STATUSES,
  ...AVAILABLE_APPOINTMENT_STATUS
};

export const PAST_APPOINTMENT_STATUSES = {
  expired: "pending"
};

export const PAST_APPOINTMENT_STATUSES_MAP = {
  pending: AVAILABLE_APPOINTMENT_STATUS.expired
};
