import css from './Section.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'

export default class Section extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    step: PropTypes.number,
    steps: PropTypes.number,
    active: PropTypes.bool,
    done: PropTypes.bool,
    optional: PropTypes.bool,
    summary: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node
  }

  render() {
    const { active, step, heading, steps, optional, done, summary, children, onClick } = this.props

    const headingWithOptional = (
      <h2 className={css.h2}>
        {heading}
        {(() => {
          if (optional) {
            return <em className={css.optional}>OPTIONAL</em>
          }
        })()}
      </h2>
    )

    if (active) {
      return (
        <div className={css.active}>
          <div className={css.header}>
            <div className={css.stepNr}>{step}</div>
            <div className={css.stepTotal}>{`Step ${step} of ${steps}`}</div>
            {headingWithOptional}
          </div>
          <div className={css.content}>
            {children}
          </div>
        </div>
      )
    }

    if (done) {
      return (
        <div onClick={onClick} className={css.done}>
          <div className={css.summary}>{summary}</div>
          <div className={css.stepNr}><Icon name="check"/></div>
          {headingWithOptional}
        </div>
      )
    }

    return (
      <div onClick={onClick} className={css.base}>
        <div className={css.stepNr}>{step}</div>
        {headingWithOptional}
      </div>
    )
  }
}
