import css from './Link.sass'
import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { browserHistory } from '~brokerage/app/helpers/history'
import { withRouter } from 'react-router-dom'

class Link extends React.PureComponent {
  static propTypes = {
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    href: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node,
    external: PropTypes.bool
  }

  render() {
    const { to, href, variant = 'base', children, external, staticContext, ...rest } = this.props

    if (to) {
      if (this.props.history) {
        return (
          <RouterLink className={css[variant]} to={to} {...rest}>{children}</RouterLink>
        )
      } else {
        return (
          <a
            className={css[variant]}
            {...rest}
            onClick={(...args) => { rest.onClick && rest.onClick(...args); browserHistory.push(to) }}
          >
            {children}
          </a>
        )
      }
    } else {
      if (external)
        return (
          <a className={css[variant]} href={href} target="blank" rel="noreferrer noopener" {...rest}>
            {children}
          </a>
        )
      else
        return (
          <a className={css[variant]} href={href} {...rest}>
            {children}
          </a>
        )
    }
  }
}

export default withRouter(Link)
