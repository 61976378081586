import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import Button from "~brokerage/components/shared/Button";
import { useLocation } from "react-router-dom";

const RequestACall = ({ message, bubbleOptions }) => {
  const { senderUserId, senderName } = message;
  const location = useLocation();

  const AgentDetailsModalToggle = () => (
    <Button
      variant="link"
      to={{
        ...location,
        query: {
          ...location.query,
          modal: "agent_details",
          agent_id: senderUserId
        }
      }}
    >
      Contact {senderName}
    </Button>
  );

  return (
    <Bubble {...bubbleOptions}>
      <p className={css.p}>
        {message.senderName} has requested you call first at{" "}
        <b className={css.nobr}>{message.phone}</b>
      </p>
      <p className={css.p}>
        <AgentDetailsModalToggle />
      </p>
    </Bubble>
  );
};

RequestACall.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

export default RequestACall;
