import mapboxgl from "!mapbox-gl";

const zoomToListings = (map, data) => {
  var bounds = new mapboxgl.LngLatBounds();
  if (data.features.length) {
    data.features.forEach(feature => {
      bounds.extend(feature.geometry.coordinates);
    });

    map.fitBounds(bounds, {
      padding: { top: 100, bottom: 200, left: 100, right: 100 },
      maxZoom: 14,
      animate: false
    });
  }
};
export default zoomToListings;
