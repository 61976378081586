import css from "./index.sass";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { initialTimeForNewShowing } from "~brokerage/libs/helpers/TimeHelper";
import { Header, Body } from "~brokerage/components/modals/BaseModal";
import SelectDate from "./steps/SelectDate";
import Reorder from "./steps/Reorder";
import ShowingProvider from "~brokerage/components/shared/Timeline/ShowingProvider";
import Actions from "./Actions";
import { callApi } from "~brokerage/middlewares/api";
import NewBuyerModal from "~brokerage/components/modals/ModalCreateShowing/NewBuyerModal";
import { hasListingThatRequireBuyers } from "../utils";
import { isEmpty } from "lodash";
import Loader from "~brokerage/components/shared/Loader";

const ModalCreateRoute = props => {
  const {
    selected,
    dispatch,
    isFetching,
    message,
    errors,
    routeId,
    routeOptions,
    mlsBuyerRequiredEnabled
  } = props;

  const [mode, setMode] = useState("new");
  const [step, setStep] = useState("date");
  const [name, setName] = useState("");
  const [selectedRoute, setSelectedRoute] = useState({ routeId: null });
  const [time, setTime] = useState(initialTimeForNewShowing());
  const [buyers, setBuyers] = useState([]);
  const [addingNewBuyer, setAddingNewBuyer] = useState(false);
  const [restrictions, setRestrictions] = useState({});
  const [currentFastestRouteShowing, setCurrentFastestRouteShowing] =
    useState();
  const [nextDisabled, toggleNextDisabled] = useState(false);
  const [unavailableListings, setUnavailableListings] = useState([]);
  const [advanceNotices, setAdvanceNotices] = useState([]);
  const [detailedListings, setDetailedListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const StepComponent = step === "date" ? SelectDate : Reorder;
  const nextLabel = step === "date" ? "Next" : "Create & Schedule Route";
  const hasAdvanceNotice = Object.values(advanceNotices).includes(true);
  const buyersRequired =
    isEmpty(buyers) &&
    hasListingThatRequireBuyers(detailedListings, mlsBuyerRequiredEnabled);

  const fetchListingRestrictions = async date => {
    try {
      const listings = selected.map(s => s.id).join(",");
      const { data } = await callApi(
        "listings/find_availability",
        { listings, date },
        {},
        "get"
      );
      setRestrictions(data.listings);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const disallowProceed = hasAdvanceNotice || buyersRequired;
    if (mode === "new") {
      if (
        step === "date" &&
        (!name.length || unavailableListings.length || disallowProceed)
      ) {
        toggleNextDisabled(true);
      } else {
        nextDisabled && toggleNextDisabled(false);
      }
    }
    if (mode === "existing") {
      if (!selectedRoute || !selectedRoute.routeId || disallowProceed) {
        toggleNextDisabled(true);
      } else {
        nextDisabled && toggleNextDisabled(false);
      }
    }
  }, [mode, step, name, selectedRoute, unavailableListings, selected, buyers]);

  useEffect(async () => {
    const listingIds = selected.map(s => s.id);
    try {
      setIsLoading(true);
      const { data } = await callApi(
        "listings/detailed_listings",
        { listing_keys: listingIds },
        {},
        "get"
      );
      setDetailedListings(data.listings);
    } catch (error) {
      toast.error(
        "Could not create route for listing. Selected listing not found."
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loader active />;
  }

  return (
    <ShowingProvider>
      <div className={css.base}>
        <Header title="Create Route" />
        <Body>
          <StepComponent
            selected={detailedListings}
            dispatch={dispatch}
            isFetching={isFetching}
            message={message}
            errors={errors}
            routeId={routeId}
            routeOptions={routeOptions}
            name={name}
            setName={setName}
            time={time}
            setTime={setTime}
            mode={mode}
            setMode={setMode}
            selectedRoute={selectedRoute}
            setSelectedRoute={setSelectedRoute}
            unavailableListings={unavailableListings}
            setUnavailableListings={setUnavailableListings}
            restrictions={restrictions}
            setRestrictions={setRestrictions}
            fetchListingRestrictions={fetchListingRestrictions}
            setAdvanceNotices={setAdvanceNotices}
            advanceNotices={advanceNotices}
            buyers={buyers}
            setBuyers={setBuyers}
            onNewBuyer={() => setAddingNewBuyer(true)}
            setDetailedListings={setDetailedListings}
          />
        </Body>
        <Actions
          nextLabel={nextLabel}
          currentFastestRouteShowing={currentFastestRouteShowing}
          setCurrentFastestRouteShowing={setCurrentFastestRouteShowing}
          step={step}
          setStep={setStep}
          nextDisabled={nextDisabled}
          time={time}
          name={name}
          mode={mode}
          selectedRoute={selectedRoute}
          selected={detailedListings}
          restrictions={restrictions}
          buyers={buyers}
        />
      </div>
      <NewBuyerModal
        isOpen={addingNewBuyer}
        closeModal={() => setAddingNewBuyer(false)}
        onAddBuyer={newBuyer => setBuyers([...buyers, newBuyer])}
      />
    </ShowingProvider>
  );
};

function mapStateToProps({
  propertySearch,
  routes,
  currentUser: { mlsBuyerRequiredEnabled }
}) {
  const { listings: selected } = propertySearch.selected;
  const { isFetching, message, errors, updatedRouteId } = routes.single.route;
  const { entities } = routes.list.routes;
  const routeOptions = entities.map(r => ({
    label: r.name,
    value: r.id
  }));
  return {
    selected,
    isFetching,
    message,
    errors,
    routeId: updatedRouteId,
    routeOptions,
    mlsBuyerRequiredEnabled
  };
}
export default connect(mapStateToProps)(ModalCreateRoute);
