import moment from "moment";

export const updateRequestedTimeApi = showingId =>
  `showings/${showingId}/update_requested_time`;
export const preapproveShowingApi = showingId =>
  `showings/${showingId}/preapprove`;

export const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "530px",
    minHeight: "500px",
    padding: "0px"
  },
  overlay: { zIndex: 101 } //The AppointmentDetails modal is at 100
};

export const steps = {
  date: {
    index: 0,
    label: "Select Date",
    disabled: _ => false,
    footerButtonLabel: _ => "Next",
    heading: rescheduleAndApproveShowing =>
      rescheduleAndApproveShowing
        ? "Reschedule Showing Date"
        : "Select Showing Date"
  },
  time: {
    index: 1,
    label: "Select Time",
    disabled: _ => false,
    footerButtonLabel: rescheduleAndApproveShowing =>
      rescheduleAndApproveShowing ? "Next" : "Send",
    heading: _ => "Select Time"
  },
  approval: {
    index: 2,
    label: "Showing Access",
    disabled: rescheduleAndApproveShowing => !rescheduleAndApproveShowing,
    footerButtonLabel: _ => "Preapprove",
    heading: _ => "Showing Access"
  }
};

export const preapprovePayload = (
  { duration, time, showingMessage },
  {
    lockboxCode,
    lockboxKind,
    message,
    requestACall,
    explanation,
    lockboxBrand,
    accompanySeller,
    accompanyAgent
  }
) => {
  return {
    showing_request: {
      duration: duration,
      preapproved_time: time.format()
    },
    message: showingMessage,
    showing_response: {
      lockbox_code: lockboxCode,
      lockbox_kind: lockboxKind,
      request_a_call: requestACall,
      lockbox_brand: lockboxBrand,
      tenant_will_accompany: accompanySeller,
      listing_agent_will_accompany: accompanyAgent,
      message,
      explanation
    }
  };
};

export const updateRequestTimePayload = ({
  duration,
  time,
  showingMessage
}) => {
  return {
    duration,
    requested_time: time.format(),
    message: showingMessage
  };
};

export const handleChangeAfterFetchShowing = ({
  data: {
    local_details: { requested_time: requestedTime, duration },
    unique_id: uniqueId
  },
  handleChange
}) => {
  handleChange({
    date: moment(requestedTime).valueOf(),
    duration,
    id: uniqueId
  });
};
