import React from "react";

import Content from "~brokerage/components/shared/Content";
import css from "./index.sass";
import NotificationsList from "./NotificationsList";

const Notifications = () => {
  return (
    <div>
      <div className={css.base} />
      <Content header="single">
        <NotificationsList />
      </Content>
    </div>
  );
};

export default Notifications;
