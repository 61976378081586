import css from './Th.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Th extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { children, variant, ...rest } = this.props

    return (
      <th className={css[variant] || css.base} {...rest}>
        {children}
      </th>
    )
  }
}
