import React, { useState, createContext, useReducer } from 'react';
import useToggle from '~brokerage/app/hooks/useToggle';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {

  const initialPageState = {
    inProgress: false,
    savingProfile: false,
    activePane: "profile",
  }

  const setPageState = (state, action) => {
    switch (action.type) {
      case "working":
        return { ...state, inProgress: true }
      case "working_complete":
        return { ...state, inProgress: false }
      case "submit":
        return { ...state, savingProfile: true, inProgress: true }
      case "submit_complete":
        return { ...state, savingProfile: false, inProgress: false  }
      case "setActivePane":
        return { ...state, activePane: action.payload, inProgress: false  }
      default:
        throw new Error();
    }
  }

  const [pageState, dispatchPageState] = useReducer(setPageState, initialPageState);
  const [isLegacySettingsModalOpen, setisLegacySettingsModalOpen] = useToggle();
  const settingsContext = {
    isLegacySettingsModalOpen,
    setisLegacySettingsModalOpen,
    pageState,
    dispatchPageState,
  };

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider;
