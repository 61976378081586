import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import ModalNewShowing from "~brokerage/components/modals/ModalNewShowing";
import SmartModalToggle from "~brokerage/components/modals/SmartModalToggle";
import Avatar from "~brokerage/components/shared/Avatar";
import Link from "~brokerage/components/shared/Link";
import Loader from "~brokerage/components/shared/Loader";
import Icon from "~brokerage/components/shared/Icon";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { platformUrl } from "~brokerage/constants/externalLinks";
import { plural } from "~brokerage/libs/helpers/FormatHelper";
import BroadcastOptOutToggle from "./BroadcastOptOutToggle";
import css from "./Summary.sass";

const Summary = props => {
  const {
    showingIsFetching,
    searchTerm,
    showing,
    showing: {
      id,
      listingAgents,
      showingAgents,
      address,
      photoUrl,
      listingKey,
      isLa,
      mlsId,
      platformName,
      status
    },
    broadcastEnabled,
    onBroadcastSettingChange,
    isBroadcastSettingLoading
  } = props;

  const externalMLSLink = platformUrl(mlsId, platformName, listingKey);

  const renderCreateShowingButton = () => {
    if (status !== "not_sent") {
      return false;
    }

    return (
      <div className={css.btnCreateShowing}>
        <SmartModalToggle
          name="new_showing_convert"
          modal={
            <ModalNewShowing listingId={listingKey} showing={showing} />
          }
          modalVariant="nearMenu"
          variant="outline"
          block
        >
          <i className={css.iconPlus}>
            <Icon name="plus" />
          </i>
          Send Request
        </SmartModalToggle>
      </div>
    );
  };

  const renderMoreInterlocutorsTooltip = (otherInterlocutors) => {
    const othersCount = otherInterlocutors.length;
    const text = `+ ${othersCount} more participant${plural(othersCount)}`;

    if (othersCount < 1) return;

    return (
      <span className={css.otherInterlocutors}>
        <Tooltip
          className={css.tooltip}
          text={otherInterlocutors.map(person => person.name).join(", ")}
        >
          {text}
        </Tooltip>
      </span>
    );
  };

  const renderInterlocutors = () => {
    const interlocutors = isLa ? showingAgents : listingAgents;
    const [firstInterlocutor, ...otherInterlocutors] = interlocutors;

    const firstInterlocutorName = firstInterlocutor
      ? firstInterlocutor.name
      : "Unknown";

    return (
      <div className={css.agents}>
        <Highlighter
          searchWords={[searchTerm]}
          autoEscape={true}
          textToHighlight={firstInterlocutorName}
        />
        {renderMoreInterlocutorsTooltip(otherInterlocutors)}
      </div>
    );
  };

  if (id == null || showingIsFetching || isBroadcastSettingLoading) {
    return (
      <div className={css.base}>
        <Loader modifier="size20" active />
      </div>
    );
  }

  return (
    <div className={css.base}>
      {renderCreateShowingButton()}

      <div>
        <div className={css.photo}>
          <Avatar src={photoUrl} size="32" />
        </div>
        {renderInterlocutors()}
        <div className={css.addressContainer}>
          <div className={css.address}>
            <Link
              className={css.addressLink}
              href={externalMLSLink}
              external={true}
            >
              View Listing
            </Link>
            <div className={css.addressInner}>
              <Highlighter
                highlightClassName="Summary"
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={address}
              />
            </div>
          </div>
        </div>
      </div>
      {!isLa && <BroadcastOptOutToggle
        broadcastEnabled={broadcastEnabled}
        onChange={onBroadcastSettingChange}
      />}
    </div>
  );
}

Summary.propTypes = {
  showingIsFetching: PropTypes.bool,
  searchTerm: PropTypes.string,
  showing: PropTypes.object,
  showing: PropTypes.shape({
    id: PropTypes.string,
    listingAgents: PropTypes.array,
    showingAgents: PropTypes.array,
    address: PropTypes.string,
    photoUrl: PropTypes.string,
    listingKey: PropTypes.string,
    isLa: PropTypes.bool,
    mlsId: PropTypes.string,
    platformName: PropTypes.string,
    status: PropTypes.string
  })
};

const mapStateToProps = state => {
  const { searchTerm } = state.messages.list.messages;

  return { searchTerm };
};

export default withRouter(connect(mapStateToProps)(Summary));
