import React from "react";
import {
  Checkbox,
  Row,
  Label,
  Controls
} from "~brokerage/components/shared/Form";
import css from "./ShowingInstructions.sass";

const RequestCallback = ({ callbackRequest, handleChange }) => (
  <Row>
    <div className={css.label}>
      <Label>Request callback?</Label>
    </div>
    <div className={css.checkBox}>
      <Controls>
        <Checkbox
          name="callbackRequest"
          checked={callbackRequest}
          onChange={handleChange}
        >
          Request that all showing agents call the listing agent
        </Checkbox>
      </Controls>
    </div>
  </Row>
);

export default RequestCallback;
