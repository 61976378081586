import moment from "moment";

export const formatPrice = price => {
  if (typeof price !== "undefined" && price && price.length > 0) {
    const [fraction, power] = parseInt(price)
      .toExponential()
      .split("e")
      .map(item => Number(item));
    const pricePrefix = (fraction.toFixed(2) * 10 ** (power % 3)).toFixed(
      2 - (power % 3)
    );
    const priceSuffix = ["", "K", "M", "B", "T"][Math.floor(power / 3)];

    return `${pricePrefix}${priceSuffix}`;
  } else {
    return "";
  }
};

export const formatTime = (requestedTime, duration) => {
  const startTime = moment(requestedTime).format("h:mm a");
  const endTime = moment(requestedTime).add(duration, "m").format("h:mm a");

  return `${startTime} - ${endTime}`;
};

export const formatDate = requestedTime =>
  moment(requestedTime).format("dddd, MMMM Do");

export const formatAddress = (city, stateOrProvince, postalCode) =>
  [city, [stateOrProvince, postalCode].filter(x => x).join(" ")]
    .filter(x => x)
    .join(", ") || "";

export const formatApproval = status =>
  ({
    instant_pending: "Approval Required",
    instant_approved: "Instant Approval",
    instant_declined: "Instant Decline"
  }[status] || "Approval Required");

export const getGoogleMapsLink = (street, address) =>
  `http://maps.google.com/maps?q=${encodeURIComponent(`${street}${address}`)}`;
