import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Thead extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { children, variant, ...rest } = this.props

    return (
      <tbody {...rest}>
        {React.Children.map(children, (child, childIndex) =>
          React.cloneElement(child, {
            variant: child.props.variant || variant,
            even: Boolean(childIndex % 2),
            firstRow: !childIndex
          })
        )}
      </tbody>
    )
  }
}
