import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  fetchShowingMessages,
  addMessage,
  addNoteMessage,
  scrollShowingChatThread,
  markShowingAsRead
} from '~brokerage/actions/showings'
import Chat from '~brokerage/components/shared/Chat'
import Loader from '~brokerage/components/shared/Loader'

export default class ShowingChat extends React.PureComponent {
  static propTypes = {
    showingId: PropTypes.string,
    showingIsFetching: PropTypes.bool,
    hasActions: PropTypes.bool,
    role: PropTypes.string,
    showing: PropTypes.object,
    currentShowingRole: PropTypes.string,
    currentUserName: PropTypes.string,
    messages: PropTypes.array,
    isFetching: PropTypes.bool,
    isChatScrollFetching: PropTypes.bool,
    hasMoreMessages: PropTypes.bool,
    dispatch: PropTypes.func
  }

  static contextTypes = {
    currentUser: PropTypes.object
  }

  state = {
    isNotesMode: false,
    isSubmitButtonDisabled: true
  }

  componentDidMount() {
    const { showingId } = this.props
    if (!showingId) {
      return
    }
    this.props.dispatch(fetchShowingMessages(showingId, this.props.role))
    this.props.dispatch(markShowingAsRead(showingId))

  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.showingId) {
      return
    }
    if (this.props.showingId !== nextProps.showingId) {
      this.props.dispatch(fetchShowingMessages(nextProps.showingId, nextProps.role))
    }
  }

  componentDidUpdate(prevProps) {
    const { showingId } = this.props

    if (showingId !== prevProps.showingId) {
      this.props.dispatch(markShowingAsRead(showingId))
    }
  }

  handleMessageSubmit = (message, attachmentIds) => {
    const { showingId, currentShowingRole } = this.props
    this.props.dispatch(addMessage(showingId, message, attachmentIds, currentShowingRole))
  }

  handleNoteMessageSubmit = (message, attachmentIds) => {
    const { showingId, currentShowingRole } = this.props
    this.props.dispatch(addNoteMessage(showingId, message, attachmentIds, currentShowingRole))
  }

  handleNewMessage = (message, messageType) => {
    this.props.dispatch(addMessage({
      photoUrl: this.context.currentUser.photoUrl,
      senderName: `${this.context.currentUser.firstName} ${this.context.currentUser.lastName}`,
      msg: message,
      isFromMe: true,
      createdAt: new Date().toISOString(),
      messageType: messageType || 'user'
    }))
  }

  handleNewNote = (message) => {
    this.handleNewMessage(message, 'note')
  }

  handlePastMessageRequest = () => {
    const { messages, role, showingId } = this.props
    this.props.dispatch(scrollShowingChatThread(showingId, { last_id: messages[0].id, role }))
  }

  render() {
    const { showingId, showingIsFetching, hasActions, showing, currentShowingRole, messages,
            isFetching, isChatScrollFetching, hasMoreMessages } = this.props

    if (isFetching || showingIsFetching) {
      return <Loader active/>
    }

    return (
      <Chat
        id={showingId}
        hasActions={hasActions}
        showing={showing}
        currentShowingRole={currentShowingRole}
        messages={messages}
        isChatScrollFetching={isChatScrollFetching}
        hasMoreMessages={hasMoreMessages}
        onMessageSubmit={this.handleMessageSubmit}
        onNoteMessageSubmit={this.handleNoteMessageSubmit}
        onNewMessage={this.handleNewMessage}
        onNewNote={this.handleNewNote}
        onPastMessagesRequest={this.handlePastMessageRequest}
        isShowingChat
        logACallEnabled
        teamNoteEnabled
      />
    )
  }
}
