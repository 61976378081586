import BaseMessageHandler, {
  DIRECT,
  MESSAGES
} from './BaseMessageHandler'

export default class DirectMessageHandler extends BaseMessageHandler {
  handle() {
    if (this.isDirectChatOpened()) {
      this.handleMessagesPageMessage(DIRECT)
    }

    super.handle()
  }

  isDirectChatOpened() {
    return this.path[1] === MESSAGES && this.path[2] === DIRECT && this.path[3] == this.message.conversationId
  }

  isMessageRead() {
    return this.isDirectChatOpened()
  }
}
