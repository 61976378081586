import { combineReducers } from "redux";

import brokerageReducer from "./brokerage";
import showingCoordinatorsReducer from "./showingCoordinators";
import assistantsReducer from "./assistants";
import pushNotifications from "./pushNotifications";
import systemPreferences from "./systemPreferences";
import defaultRestrictionsReducer from "./defaultRestrictions";
import defaultShowingSettingReducer from "./defaultShowingSetting";

export default combineReducers({
  brokerage: brokerageReducer,
  showingCoordinators: showingCoordinatorsReducer,
  assistants: assistantsReducer,
  pushNotifications,
  system: systemPreferences,
  defaultRestrictions: defaultRestrictionsReducer,
  defaultShowingSetting: defaultShowingSettingReducer
});
