import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import InstructionsList from "../InstructionsList";
import { buildNames } from "./utils";
import { plural } from "~brokerage/libs/helpers/FormatHelper";

const Approved = ({
  message,
  bubbleOptions,
  showBuyersInApprovalMsg,
  buyers = []
}) => {
  const buyerNames = buildNames(buyers).join(", ");
  const renderBuyerNames = buyers.length > 0 && showBuyersInApprovalMsg;

  return (
    <Bubble variant="green" {...bubbleOptions}>
      <p className={css.p}>
        {(() => {
          if (message.isInstant) {
            return [<b>Instant Approve:</b>, <br />];
          }
        })()}
        {message.senderName} <b>Approved</b> the showing request on{" "}
        <b className={css.nobr}>{message.requestDate}</b> from{" "}
        <b className={css.nobr}>
          {message.requestStartTime} to {message.requestEndTime}
        </b>
        .
      </p>

      {(() => {
        if (message.explanation) {
          return <p className={css.p}>{message.explanation}</p>;
        }
      })()}
      {renderBuyerNames && (
        <div className={css.buyerNames}>
          <span>
            <b>{`Buyer Name${plural(buyers.length)}:`}</b>
          </span>

          <span className={css.names}>{buyerNames}</span>
        </div>
      )}
      <InstructionsList instructions={message.instructions} />
    </Bubble>
  );
};

Approved.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Approved;
