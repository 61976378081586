import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Dropdown } from "~brokerage/components/shared/Form";
import { fetchAgents, setCurrentAgent } from "~brokerage/actions/listings";
import css from "./index.sass";

const AgentFilterDropdown = (props, context) => {
  const { filterAgents, dispatch, currentAgent } = props;
  const { currentUser } = context;
  const { platformId } = currentUser;

  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    dispatch(fetchAgents());
  }, []);

  const agentInList = platformId => filterAgents.find(a => a.id === platformId);

  useEffect(() => {
    const { query } = location;

    if (agentInList(platformId) && !query.agent) {
      handleChange({ value: platformId });
    }
  }, [filterAgents]);

  useEffect(() => {
    history.replace({
      query: { ...location.query, agent: currentAgent || void 0 }
    });
  }, [currentAgent, location.search]);

  const generateAgentOptions = () => {
    return filterAgents.reduce(
      (acc, a) => acc.concat({ label: agentNameLabel(a), value: a.id }),
      []
    );
  };

  const agentNameLabel = ({ name, memberMlsId, state }) => {
    return `Agent: ${name}${memberMlsId ? ` (${memberMlsId})` : ""}${state ? ` (${state})` : ""}`;
  };

  const handleChange = agent => {
    dispatch(setCurrentAgent(agent.value || null));
  };

  const getSelectedAgent = () => {
    return filterAgents.find(({ id }) => id === currentAgent);
  };

  const getSelectedAgentId = () => {
    const selectedAgent = getSelectedAgent();

    if (selectedAgent) {
      return selectedAgent.id;
    } else {
      return null;
    }
  };

  const getSelectedAgentName = () => {
    const selectedAgent = getSelectedAgent();

    if (selectedAgent) {
      return agentNameLabel(selectedAgent);
    } else {
      return "Select an Agent";
    }
  };

  const options = generateAgentOptions();

  if (!options.length) {
    return null;
  }

  return (
    <Dropdown
      title={getSelectedAgentName()}
      options={options}
      selected={getSelectedAgentId()}
      toggleClassName={css.dropdownToggle}
      variant="header2"
      minWidth={155}
      onChange={handleChange}
    />
  );
};

function mapStateToProps(state) {
  const { currentAgent, agents } = state.listings.agents;
  return {
    currentAgent,
    filterAgents: agents
  };
}

AgentFilterDropdown.contextTypes = {
  currentUser: PropTypes.object
};

AgentFilterDropdown.propTypes = {
  filterAgents: PropTypes.array,
  currentUser: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object
};
export default connect(mapStateToProps)(AgentFilterDropdown);
