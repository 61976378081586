export const LOCKBOX_BRAND_OTHER = "";
export const LOCKBOX_BRAND_HUD = "HUD Key";
export const LOCKBOX_BRAND_MASTER = "Master Lock";
export const LOCKBOX_BRAND_SENTRI = "SentriLock";
export const LOCKBOX_BRAND_SUPRA = "Supra";
export const DEFAULT_LOCKBOX_BRAND = { value: LOCKBOX_BRAND_OTHER, label: "Other" };
export const LOCKBOX_BRANDS = [
  DEFAULT_LOCKBOX_BRAND,
  { value: LOCKBOX_BRAND_HUD, label: "HUD Key" },
  { value: LOCKBOX_BRAND_MASTER, label: "Master Lock" },
  { value: LOCKBOX_BRAND_SENTRI, label: "SentriLock" },
  { value: LOCKBOX_BRAND_SUPRA, label: "Supra" }
];
