import { combineReducers } from "redux";

import listReducer from "./list";
import singleReducer from "./single";
import categoriesReducer from "./categories";
import customerReducer from "./customers";

export default combineReducers({
  list: listReducer,
  single: singleReducer,
  categories: categoriesReducer,
  customer: customerReducer
});
