import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  fetchListingStatuses,
  setCurrentListingStatus
} from "~brokerage/actions/listings";
import StatusDropdown from "./StatusDropdown";

const ListingStatus = props => {
  let history = useHistory();
  let location = useLocation();

  const [isReady, setReady] = useState(false);
  const { dispatch, statuses, isFetching, currentStatus } = props;
  const { filterParams } = currentStatus;

  useEffect(() => {
    dispatch(fetchListingStatuses());
    setReady(true);
  }, []);

  useEffect(() => {
    if (!isReady || isFetching) return;
    updateQuery();
  }, [statuses, isFetching, location]);

  const updateQuery = () => {
    const activeStatus =
      location.query.property_statuses || filterParams.property_statuses;
    const selection =
      activeStatus && statuses.find(s => s.name === activeStatus);
    if (selection) {
      dispatch(setCurrentListingStatus(selection));
      if (location.query.property_statuses !== activeStatus) {
        history.push({
          pathname: location.pathname,
          query: { ...location.query, property_statuses: activeStatus }
        });
      }
    }
  };
  const handleChange = item => {
    const { value, label } = item;
    const selection = statuses.find(s => s.id === value);
    history.push({
      pathname: location.pathname,
      query: { ...location.query, property_statuses: label }
    });
    dispatch(setCurrentListingStatus(selection));
  };

  return !isFetching ? (
    <StatusDropdown
      statuses={statuses}
      location={location}
      handleChange={handleChange}
    />
  ) : (
    <div />
  );
};

ListingStatus.propTypes = {
  statuses: PropTypes.array,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool
};

function mapStateToProps(state) {
  const { entities: statuses, isFetching, current } = state.listings.statuses;

  return {
    statuses,
    isFetching,
    currentStatus: current
  };
}
export default connect(mapStateToProps)(ListingStatus);
