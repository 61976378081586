import {
  titleAllKeys,
  statusColors
} from "~brokerage/app/constants/appointments.js";

export const MARKER_COLORS = titleAllKeys(statusColors);

export const DEFAULT_COLOR = "#e6194b";

export const ROUTE_COLOR = "#117CC9";
