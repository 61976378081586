import css from './Calendar.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Generator } from 'js-calendar'
import chunk from 'lodash/chunk'
import find from 'lodash/find'
import { inUsersTimezone, startOfDay, timeToRemoteWithTimeZone } from '~brokerage/libs/helpers/TimeHelper'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'

const US_WEEK_START = 0,
      NEXT_MONTH = 'nextMonth',
      PREV_MONTH = 'prevMonth',
      CURRENT_MONTH = 'monthDay'

export default class Calendar extends React.PureComponent {
  static propTypes = {
    modifier: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    initialDate: PropTypes.string,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    minDate: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.generator = new Generator({onlyDays: true, weekStart: US_WEEK_START, lang: 'en'})
    this.selectedTimestamps = this.datesToTimestamps(props.selected)
    this.state = this.dateToState(startOfDay(props.initialDate))
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.selectedTimestamps = this.datesToTimestamps(nextProps.selected)
    }
  }

  datesToTimestamps(_dates) {
    if (!_dates) {
      return []
    }
    const dates = Array.isArray(_dates) ? _dates : [_dates]
    return dates.map(time => Number(timeToRemoteWithTimeZone(time).startOf('day')))
  }

  dateToState(date) {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]

    return {
      currentDate: date,
      month: date.getMonth(),
      monthText: months[date.getMonth()],
      year: date.getFullYear()
    }
  }

  dates() {
    const minDay = timeToRemoteWithTimeZone(this.props.minDate).startOf("day")
    const minTimeStamp = Number(minDay)
    const date = new Date(this.state.currentDate)
    const month = this.generator(date.getFullYear(), date.getMonth())
    const formattedMonth = this.sliceLastSevenDays(month.cells).map(day => {
      const m = moment(day.date).utc()
      const timestamp = Number(inUsersTimezone(m.format('YYYY-MM-DD')))
      return {
        timestamp,
        text: day.desc,
        isPrevious: day.type == PREV_MONTH,
        isNext: day.type == NEXT_MONTH,
        isSelected: this.selectedTimestamps.indexOf(timestamp) !== -1,
        isSelectable: timestamp >= minTimeStamp
      };
    })

    return chunk(formattedMonth, 7)
  }

  sliceLastSevenDays(dates) {
    if (!find(dates.slice(-7), { type: CURRENT_MONTH })) {
      return dates.slice(0, 35) // First 5 weeks
    }

    return dates
  }

  changeMonth(change) {
    const _date = new Date(this.state.currentDate)
    _date.setMonth(_date.getMonth() + change)
    this.setState(this.dateToState(_date))
  }

  handlePrevClick = () => {
    this.changeMonth(-1)
  }

  handleNextClick = () => {
    this.changeMonth(1)
  }

  handleDateClick = (timestamp) => {
    const date = inUsersTimezone(timestamp).format();
    if (!this.props.multiple) {
      return this.props.onChange(this.props.name ? { [this.props.name]: date } : date)
    }
    let dates
    const dateIndex = this.selectedTimestamps.indexOf(timestamp)
    if (dateIndex === -1) {
      dates = (this.props.selected || []).concat(date)
    } else {
      dates = [...this.props.selected.slice(0, dateIndex), ...this.props.selected.slice(dateIndex + 1)]
    }
    this.props.onChange(this.props.name ? { [this.props.name]: dates } : dates)
  }

  render() {
    const dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

    return (
      <div className={composeClassName(css, 'base', this.props.modifier)}>
        <div className={css.monthYear}>
          {this.state.monthText} {this.state.year}
        </div>
        <div className={css.btnPrev}>
          <Button variant="icon44" onClick={this.handlePrevClick}><Icon name="chevronLeft"/></Button>
        </div>
        <div className={css.btnNext}>
          <Button variant="icon44" onClick={this.handleNextClick}><Icon name="chevronRight"/></Button>
        </div>
        <table className={css.table}>
          <thead>
            <tr>
              {dayNames.map((day, index) =>
                <th className={css.th} key={index}>{day}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.dates().map((week, weekIndex) =>
              <tr className={css.tr} key={weekIndex}>
                {week.map(date => {
                  return (
                    <td
                      className={composeClassName(css, 'td', {
                        previous: date.isPrevious,
                        next: date.isNext
                      })}
                      key={date.timestamp}
                      onClick={date.isSelectable ? this.handleDateClick.bind(this, date.timestamp) : null}
                    >
                      <div className={composeClassName(css, 'date', {
                        notSelectable: !date.isSelectable,
                        selected: date.isSelected
                      })}>{date.text}</div>
                    </td>
                  )
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
