import { combineReducers } from "redux";
import omit from "lodash/omit";

import {
  ROUTE_REQUEST,
  ROUTE_SUCCESS,
  ROUTE_FAILURE,
  ROUTE_CREATE_REQUEST,
  ROUTE_CREATE_SUCCESS,
  ROUTE_CREATE_FAILURE,
  ROUTE_ADD_APPT_REQUEST,
  ROUTE_ADD_APPT_SUCCESS,
  ROUTE_ADD_APPT_FAILURE,
  ROUTE_CLEAR_REQUEST,
  ROUTE_UPDATE,
  ROUTE_REMOVE_UNSAVED
} from "~brokerage/constants/actionTypes";

const ROUTE_INITIAL_STATE = {};

const getDupsMessage = listings => {
  if (!listings) return;
  const multiple = listings.length > 1;
  if (listings.length) {
    return `Listing${multiple && "s"} ${listings.join(
      ", "
    )} already existed in this route, and ${
      multiple ? "were" : "was"
    } not added a second time.`;
  }
};

function route(state = ROUTE_INITIAL_STATE, action) {
  switch (action.type) {
    case ROUTE_REQUEST:
      return { ...state, isFetching: true };
    case ROUTE_SUCCESS:
      return {
        ...state,
        entity: {
          ...action.data.data.route,
          ...action.persistedValues
        },
        isFetching: false
      };
    case ROUTE_FAILURE:
      return { ...state, isFetching: false };
    case ROUTE_CREATE_REQUEST:
    case ROUTE_ADD_APPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        message: null,
        errors: null
      };
    case ROUTE_CREATE_SUCCESS:
    case ROUTE_ADD_APPT_SUCCESS:
      const duplicateListingsMsg = getDupsMessage(
        action.data?.listings_not_added
      );
      const message =
        action.type === ROUTE_CREATE_SUCCESS
          ? "Route created successfully."
          : "Appointments added.";

      return {
        ...state,
        updatedRouteId: action.data?.data?.route?.id,
        isFetching: false,
        message,
        errors: duplicateListingsMsg,
        entity: null
      };
    case ROUTE_CREATE_FAILURE:
    case ROUTE_ADD_APPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: null,
        errors: action.errors
      };
    case ROUTE_CLEAR_REQUEST:
      return {
        ...state,
        isFetching: false,
        message: null,
        errors: null,
        updatedRouteId: null
      };
    case ROUTE_UPDATE:
      return {
        ...state,
        entity: {
          ...state.entity,
          ...action.data
        }
      };
    case ROUTE_REMOVE_UNSAVED:
      const updatedEntity = omit(state.entity, ["unsavedChanges"]);
      return {
        ...state,
        entity: updatedEntity
      };
    default:
      return state;
  }
}

export default combineReducers({
  route
});
