import React from "react";
import css from "./SettingsNavCard.sass";

const SettingsNavLink = ({ Icon, children, title, to }) => (
  <a className={css.card} href={to} target="_blank" rel="noreferrer noopener">
    <Icon size={24} className={css.icon} />
    <div className={css.details}>
      <h4 className={css.cardHeading}>{title}</h4>
      <div className={css.break}></div>
      <div className={css.cardSubText}>{children}</div>
    </div>
  </a>
);

export default SettingsNavLink;
