import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import css from "./shared.sass";
import PreferenceOption from "~brokerage/app/components/settings/components/PreferenceOption";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import Switch from "~brokerage/components/shared/Switch";
import { Controls } from "~brokerage/components/shared/Form";

const MobilePreferences = ({
  laChecked,
  baChecked,
  disableListingOptions,
  dailySummaryEmailsEnabled
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue("daily_summary_emails_enabled", dailySummaryEmailsEnabled);
  }, [dailySummaryEmailsEnabled]);

  return (
    <>
      <Row className={composeClassName(css, "inputRow", "marginBottom")}>
        <Column
          className={composeClassName(css, "inputColumnCentered", "rowLabel")}
        >
          <InputLabel>Receive Text Messages</InputLabel>
        </Column>
        <Column className={css.prefsColumn}>
          <PreferenceOption
            disabled={disableListingOptions}
            className={css.checkBoxLabel}
            name="listing_text_notification_enabled"
            label="As a Listing Agent"
            defaultChecked={laChecked}
          />

          <PreferenceOption
            className={css.checkBoxLabel}
            name="buying_text_notification_enabled"
            label="As a Showing Agent"
            defaultChecked={baChecked}
          />
        </Column>
      </Row>
      <Row className={composeClassName(css, "inputRow", "marginBottom")}>
        <Column
          className={composeClassName(css, "inputColumnCentered", "rowLabel")}
        >
          <InputLabel>Receive Daily Summary Emails</InputLabel>
        </Column>
        <Column className={css.prefsColumn}>
          <Controls>
            <Controller
              name="daily_summary_emails_enabled"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              )}
            />
          </Controls>
        </Column>
      </Row>
      <div className={css.note}>
        *When selecting Text Messages, you agree to receive text messages from
        Aligned Showings. Message and data rates may apply. Message frequency
        varies. You can Opt-out via replying STOP but changing the setting here
        is preferred.
      </div>
      <div className={css.note}>
        *Direct Messages sent from Aligned Showings users will follow the
        external notification path set for <b>Listing Agent</b>
      </div>
    </>
  );
};

export default MobilePreferences;
