import React from "react";
import { useHistory } from "react-router-dom";

import css from "./index.sass";
import ListingPicture from "./ListingPicture";
import LocalDetails from "./LocalDetails";
import ImportedDetails from "./ImportedDetails";
import DetailLinks from "./DetailLinks";

const MainSection = ({
  details: {
    unique_id: uniqueId,
    listing_key: listingKey,
    mls_id: mlsId,
    platform_name: platformName,
    listing_photo: listingPhoto,
    local_details: localDetails,
    route
  },
  updateDetails,
  changeDetails,
  updateLocalDetails,
  showingStatus,
  isShowingTeam,
  isListingTeam,
  failoverDetails,
  openStatusModal,
  openDateTimeModal,
  openEditDetailModal,
  openEditTeamInstructionsModal,
  closeModal,
  futureShowing,
  setIsListingAgentPreapproving
}) => {
  const history = useHistory();

  return (
    <div className={css.wrapper1}>
      <div className={css.listingPhoto}>
        <ListingPicture listingPhoto={listingPhoto} />
      </div>
      <div className={css.detailLinks}>
        <DetailLinks
          listingKey={listingKey}
          timestamp={localDetails.requested_time}
          mlsId={mlsId}
          platformName={platformName}
          uniqueId={uniqueId}
          showingStatus={showingStatus}
          openStatusModal={openStatusModal}
          isShowingTeam={isShowingTeam}
          isListingTeam={isListingTeam}
          futureShowing={futureShowing}
          route={route}
          history={history}
          closeModal={closeModal}
          openDateTimeModal={openDateTimeModal}
          setIsListingAgentPreapproving={setIsListingAgentPreapproving}
        />
      </div>
      <div className={css.apiCallDetails}>
        <ImportedDetails
          listingKey={listingKey}
          mlsId={mlsId}
          failoverDetails={failoverDetails}
        />
      </div>
      <div className={css.localDetails}>
        <LocalDetails
          localDetails={localDetails}
          updateDetails={updateDetails}
          uniqueId={uniqueId}
          isListingTeam={isListingTeam}
          isShowingTeam={isShowingTeam}
          showingStatus={showingStatus}
          changeDetails={changeDetails}
          updateLocalDetails={updateLocalDetails}
          futureShowing={futureShowing}
          openDateTimeModal={openDateTimeModal}
          openEditDetailModal={openEditDetailModal}
          openEditTeamInstructionsModal={openEditTeamInstructionsModal}
        />
      </div>
    </div>
  );
};

export default MainSection;
