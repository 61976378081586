import { connect } from 'react-redux'
import Sidebar from './Sidebar'

function mapStateToProps(state) {
  const { participants: conversationParticipants,
          participantsAreFetching: conversationParticipantsAreFetching,
          availableParticipants } = state.messages.single.conversationMessages

  const { role } = state.messages.list.messages

  return {
    conversationParticipants,
    conversationParticipantsAreFetching,
    availableParticipants,
    role
  }
}
export default connect(mapStateToProps)(Sidebar)
