import css from './Footer.sass'
import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => {
  return (
    <div className={css.base}>
      {props.children}
    </div>
  )
}

Footer.propTypes = {
  children: PropTypes.node
}
export default Footer
