import css from "./ModalCreateCustomer.sass";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Dropdown,
  TextField,
  Textarea,
  PhoneNumber
} from "~brokerage/components/shared/Form";

import {
  fetchCustomerCategories,
  updatePersonDetails
} from "~brokerage/actions/people";
import { toast } from "react-toastify";

import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { callApi } from "brokerage/middlewares/api";
import { useHistory } from "react-router-dom";

const ModalEditCustomer = props => {
  const { categories, dispatch, person } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(
    person?.role ? { value: person.role } : ""
  );

  useEffect(() => {
    dispatch(fetchCustomerCategories());
  }, []);

  const handleSubmit = (e, values) => {
    callApi(
      `my_contacts/${person.id}`,
      {},
      { ...values, category: category.value },
      "patch"
    ).then(
      response => {
        setLoading(false);
        closeModalWindow();
        toast.success("Customer updated");
        const person = response.data.person;
        if (person) dispatch(updatePersonDetails(person));
      },
      err => {
        setLoading(false);
        toast.error(
          err.response?.data?.error ||
            "Unknown error while updating customer. Please try again!"
        );
      }
    );
  };

  const closeModalWindow = () => {
    history.push({
      pathname: props.location.pathname,
      query: { ...props.location.query, modal: void 0 }
    });
  };

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title="Edit customer" />
        <Body>
          <Row>
            <Col variant="liquid" modifier="half">
              <TextField
                name="first_name"
                block
                placeholder="First Name"
                required
                defaultValue={person?.first_name}
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <TextField
                name="last_name"
                block
                placeholder="Last Name"
                required
                defaultValue={person?.last_name}
              />
            </Col>
          </Row>
          <Row>
            <TextField
              name="email"
              block
              type="email"
              placeholder="Email"
              required
              defaultValue={person?.email}
            />
          </Row>
          <Row>
            <Col variant="liquid" modifier="half">
              <PhoneNumber
                name="phone"
                block
                placeholder="Phone"
                required
                defaultValue={person?.phone}
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <Dropdown
                title={category === "" ? "Select category" : category.label}
                options={categories}
                onChange={setCategory}
                variant="outline2Block"
                selected={category && category.value}
              />
            </Col>
          </Row>
          <Row>
            <Textarea
              name="notes"
              placeholder="Enter notes for this contact"
              defaultValue={person?.notes}
            />
          </Row>
        </Body>
        <Footer>
          <Button
            type="submit"
            float="right"
            variant="primary"
            disabled={loading || !category}
          >
            Apply
          </Button>
          <Button
            type="button"
            variant="newMessageCancel"
            onClick={closeModalWindow}
          >
            Cancel
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    entities: categories,
    isFetching: isLoadingCategories,
    errors: categoryErrors
  } = state.people.customer.categories;
  const { entity: person } = state.people.single.person;

  return {
    categories,
    isLoadingCategories,
    categoryErrors,
    person
  };
}

export default connect(mapStateToProps)(ModalEditCustomer);
