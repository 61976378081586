import { updateShowingMessageReadReceipt } from "~brokerage/actions/showings";

export default class ShowingMessageHasReadHandler {
  constructor(message, props) {
    this.message = message;
    this.props = props;
  }

  handle() {
    this.props.dispatch(updateShowingMessageReadReceipt(this.message));
  }
}
