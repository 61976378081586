const CONTACTS_PATH = '/contacts'
const CUSTOMERS_PATH = '/contacts/customers'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

class Customers extends PureComponent {
  static propTypes = {
    q: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  onClick = (event) => {
    const link = closestTag(event.target, 'a')

    if (!link) {
      return false
    }

    const url = link.getAttribute('href')

    if (url && url.includes(CONTACTS_PATH) && !url.includes(CUSTOMERS_PATH)) {
      this.props.history.push({
        pathname: '/people'
      })
      return true
    }
    return false
  }

  render() {
    const contactsUrl = `${CUSTOMERS_PATH}?q=${this.props.q}`

    return <OldInterfaceIframe url={contactsUrl} onClick={this.onClick} location={this.props.location}/>
  }
}

const mapStateToProps = (state) => {
  return { q: state.people.list.people.q }
}
export default connect(mapStateToProps)(Customers)
