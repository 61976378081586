import css from "./Legend.sass";
import React from "react";

const Legend = () => {
  return (
    <div className={css.base}>
      <div className={css.heading}>Legend:</div>
      <ul className={css.ul}>
        <li className={css.li}>
          <i className={css.sampleNew} /> New Showing
        </li>
        <li className={css.li}>
          <i className={css.sampleApproved} /> Approved
        </li>
        <li className={css.li}>
          <i className={css.samplePending} /> Requested
        </li>
        <li className={css.li}>
          <i className={css.sampleUnavailable} /> Unavailable Time
        </li>
      </ul>
    </div>
  );
};
export default Legend;
