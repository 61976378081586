import React from "react";
import css from "./Footer.sass";
import InformationFillIcon from "remixicon-react/InformationFillIcon";

const Footer = () => {
  return (
    <>
      <div className={css.base}>
        Download the Aligned Showings Mobile Apps to opt-in for Push Notifications
        on your mobile device.
      </div>
      <div className={css.imageRow}>
        <a href="https://apps.apple.com/us/app/aligned-showings/id1583099613" target="_blank">
          <img src="./img/apple-store.jpeg" className={css.image}/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.mlsaligned.alignedshowings&pli=1" target="_blank">
          <img src="./img/google-play.jpeg" className={css.image}/>
        </a>
      </div>
    </>
  );
};

export default Footer;
