import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AppointmentStatusModal from "~brokerage/app/components/shared/AppointmentStatusModal";
import fetch from "~brokerage/libs/fetch.js";
import Loader from "~brokerage/app/components/shared/Loader";
import AppLogo from "~brokerage/components/shared/AppLogo";

import css from "./index.sass";
import DetailsSection from "./DetailsSection";
import ListingPicture from "./ListingPicture";
import ShowingActions from "./ShowingActions";
import SurveyFeedback from "./SurveyFeedback";

const SellerLandingPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uniqueId = queryParams.get("showing");
  const sellerAuthToken = queryParams.get("token");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [showingId, setShowingId] = useState(null);
  const [details, setDetails] = useState({});

  const {
    status,
    first_name: firstName,
    requested_time: requestedTime,
    listing_photo: photoDetails,
    showing_involvement: showingInvolvement,
    can_view_feedback: canViewFeedback
  } = details;

  const openStatusModal = status => {
    setNewStatus(status);
    setShowingId(uniqueId);
  };

  const getDetails = () => {
    !loading && setLoading(true);
    fetch(
      `/api/v2/seller_landing/${uniqueId}`,
      {
        method: "get",
        headers: { "Seller-Authorization": sellerAuthToken }
      },
      false
    )
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => {
        setError(error.message.split(" ").pop());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sellerTenantCall = async showingId =>
    fetch(
      `/api/v2/seller_landing/${showingId}/showing_instructions`,
      {
        method: "get",
        headers: { "Seller-Authorization": sellerAuthToken }
      },
      false
    );

  const sellerTenantPush = async ({ showingId, pushData }) =>
    fetch(
      `/api/v2/seller_landing/${showingId}`,
      {
        data: pushData,
        method: "patch",
        headers: { "Seller-Authorization": sellerAuthToken }
      },
      false
    );

  useEffect(getDetails, []);

  const displayContents = status && error === "";
  const isApproveInstructionsEditable =
    details?.showing_involvement !== "can_approve_no_comment";

  return (
    <div className={css.outerContainer}>
      <AppLogo className={css.appLogo} variant="black" scale="1.75" />
      {!loading && displayContents && (
        <div className={css.welcomeMessage}>Welcome, {firstName}</div>
      )}
      <div className={css.contentWrapper}>
        <div className={css.innerContainer}>
          {loading ? (
            <Loader active />
          ) : displayContents ? (
            <>
              <div className={css.controlSection}>
                <div className={css.pictureContainer}>
                  <ListingPicture photoDetails={photoDetails} />
                </div>
                {canViewFeedback && (
                  <div className={css.surveyFeedbackContainer}>
                    <SurveyFeedback details={details} />
                  </div>
                )}
                <div className={css.buttonsContainer}>
                  <ShowingActions
                    showingStatus={status}
                    requestedTime={requestedTime}
                    openStatusModal={openStatusModal}
                    showingInvolvement={showingInvolvement}
                  />
                </div>
              </div>
              <div className={css.detailsContainer}>
                <DetailsSection showingDetails={details} />
              </div>
            </>
          ) : (
            <div className={css.linkBrokenText}>
              {error === "404"
                ? "Could not find showing"
                : "This link seems to be broken"}
              , please contact your listing agent.
            </div>
          )}
        </div>
        <AppointmentStatusModal
          showingId={showingId}
          setShowingId={setShowingId}
          newStatus={newStatus}
          refreshCallback={getDetails}
          altCall={sellerTenantCall}
          altPush={sellerTenantPush}
          seller={true}
          isInstructionsEditable={isApproveInstructionsEditable}
        />
      </div>
      <div className={css.notificationSettingLink}>
        <Link to="/unsubscribe">Manage your notification settings</Link>
      </div>
    </div>
  );
};

export default SellerLandingPage;
