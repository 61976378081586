import React from "react";
import PropTypes from "prop-types";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import {
  EVENT_TYPE,
  FEEDBACK_RECEIVED_TYPE,
  FEEDBACK_REQUESTED_TYPE
} from "~brokerage/constants/messages";
import Event from "./Event";
import FeedbackReceived from "./FeedbackReceived";
import he from "he";

const EventMessage = ({ message }) => {
  if (message.messageType === EVENT_TYPE) {
    return <Event body={he.decode(message.msg)} />;
  } else if (message.messageType === FEEDBACK_REQUESTED_TYPE) {
    return (
      <Event
        body={he.decode(
          `Showing feedback requested on ${inUsersTimezone(
            message.createdAt
          ).format("ddd, MMMM DD, [at] h:mm a")}`
        )}
      />
    );
  } else if (message.messageType === FEEDBACK_RECEIVED_TYPE) {
    return <FeedbackReceived message={message} />;
  } else {
    return <></>;
  }
};

EventMessage.propTypes = {
  message: PropTypes.object
};

export default EventMessage;
