const platforms = {
  "ARMLS": "armls",
  "Metro MLS": "met"
};

export const platformUrl = (mlsId, platformName, listingKey) => {
  switch (platformName) {
    case "UtahRealEstate.com":
      return `https://utahrealestate.com/${listingKey}`;
    case "MLSL":
      return `https://www.mlslistings.com/Property/${listingKey}`;
    case "Regional Multiple Listing Service, Inc.":
      const url_prefix = process.env.NODE_ENV === "production" ? "www" : "staging";
      return `https://${url_prefix}.rmlsweb.com/V2/engine/reportgen.asp?MLSID=${listingKey}`;
    case "New Mexico":
      return `https://newmexico.paragonrels.com/ParagonLS/Reports/Report.mvc?viewID=22&listingIDs=${listingKey}`;
    case "REALTORS Association of South Central Wisconsin":
      return `https://scwmls.paragonrels.com/ParagonLS/Reports/Report.mvc?viewID=c156&listingIDs=${listingKey}`;
    default:
      return `https://www.flexmls.com/html/crs/linkback.html?listnum=${mlsId}&subdomain=${
        platforms[platformName] || "armls"
      }`;
  }
};
