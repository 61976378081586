import css from "./Loader.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import loading from "~brokerage/assets/images/loading.gif";

export default class Loader extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    modifier: PropTypes.string,
    active: PropTypes.bool
  };

  render() {
    const { variant = "full", active, modifier } = this.props;

    if (active && variant === "full")
      return (
        <div className={composeClassName(css, "logoWrapper", modifier)}>
          <img src={loading} alt="loading..." />
        </div>
      );

    return (
      <ReactCSSTransitionGroup
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
        transitionName={{
          enter: css.enter,
          enterActive: css.enterActive,
          leave: css.leave,
          leaveActive: css.leaveActive
        }}
      >
        {(() => {
          if (active) {
            return <div className={composeClassName(css, variant, modifier)} />;
          }
        })()}
      </ReactCSSTransitionGroup>
    );
  }
}
