import { REQUIRED_IS_EMPTY, EMAIL_INVALID, TEL_INVALID } from '~brokerage/constants/validation'
import { ONLY_EMAIL_REGEX, PHONE_REGEX } from '~brokerage/constants/fieldsValuePatterns'

export const basicValidation = (input, trimValue = false) => {
  if (!input) return []

  const errors = []
  let value = input.value

  if (trimValue) {
    value = value.trim()
  }

  if (input.required) {
    if (!value.length) errors.push(REQUIRED_IS_EMPTY);
  } else {
    if (!value.length) return errors;
  }

  switch (input.type) {
  case 'email':
    if (!ONLY_EMAIL_REGEX.test(value)) {
      errors.push(EMAIL_INVALID)
    }
    break
  case 'tel':
    if (!PHONE_REGEX.test(value)) {
      errors.push(TEL_INVALID)
    }
    break
  }

  return errors
}
