import React from "react";
import css from "~brokerage/app/components/login/AppLogo.sass";
import { default as Logo } from "~brokerage/components/shared/AppLogo";

const AppLogo = () => (
  <div className={css.logoWrapper}>
    <Logo className={css.appLogo} variant="black" scale="3.2" />
  </div>
);

export default AppLogo;
