import css from "./CustomerShowings.sass";
import React, { useEffect } from "react";
import ReactList from "react-list";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import Loader from "~brokerage/components/shared/Loader";
import ShowingItem from "./ShowingItem";
import { connect } from "react-redux";
import {
  fetchCustomerShowings,
  fetchCustomerListings
} from "~brokerage/actions/people";
import ListingItem from "./ListingItem";

const CustomerShowings = props => {
  const {
    showings,
    listings,
    dispatch,
    person,
    isFetchingShowings,
    isFetchingListings
  } = props;
  const emptyShowingsMessage = <EmptyMessage>No Showings.</EmptyMessage>;
  const emptyListingsMessage = <EmptyMessage>No Listings.</EmptyMessage>;
  const anyShowing = showings.length > 0;
  const anyListing = listings.length > 0;

  useEffect(() => {
    dispatch(fetchCustomerShowings(person.id));
    dispatch(fetchCustomerListings(person.id));
  }, []);

  const renderTable = (items, ref) => {
    return (
      <div className={css.tableBase}>
        <table className={css.table}>
          <tbody ref={ref}>{items}</tbody>
        </table>
      </div>
    );
  };
  const renderShowing = (index, key) => {
    return (
      <tr className={css.tr} key={showings[index].id}>
        <td className={css.td}>
          <ShowingItem showing={showings[index]} />
        </td>
      </tr>
    );
  };
  const renderListing = (index, key) => {
    return (
      <tr className={css.tr} key={listings[index].id}>
        <td className={css.td}>
          <ListingItem listing={listings[index]} />
        </td>
      </tr>
    );
  };

  const renderShowingList = () => (
    <ReactList
      itemsRenderer={renderTable}
      itemRenderer={renderShowing}
      length={showings.length}
      type="simple"
    />
  );

  const renderListingList = () => (
    <ReactList
      itemsRenderer={renderTable}
      itemRenderer={renderListing}
      length={listings.length}
      type="simple"
    />
  );
  return (
    <div className={css.base}>
      <div className={css.counter}>{showings.length} Showings</div>
      {isFetchingShowings && !anyShowing ? (
        <Loader active />
      ) : anyShowing ? (
        renderShowingList()
      ) : (
        emptyShowingsMessage
      )}
      <div className={css.counterListings}>{listings.length} Listings</div>
      {isFetchingListings && !anyListing ? (
        <Loader active />
      ) : anyListing ? (
        renderListingList()
      ) : (
        emptyListingsMessage
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { entities: showings, isFetchingShowings } =
    state.people.customer.showings;
  const { entities: listings, isFetchingListings } =
    state.people.customer.listings;
  return { showings, isFetchingShowings, listings, isFetchingListings };
};

export default connect(mapStateToProps)(CustomerShowings);
