import { MAX_PAGE_BUTTONS } from "./constants";

const range = (start, end) =>
  Array.from({ length: end - start + 1 }, (_, idx) => start + idx);

export const getPages = (currentPage, totalPages) => {
  const leftIndex = Math.max(currentPage - 2, 1);
  const rightIndex = Math.min(currentPage + 2, totalPages);
  if (leftIndex === 1) return range(1, Math.min(MAX_PAGE_BUTTONS, totalPages));

  if (rightIndex === totalPages)
    return range(totalPages - MAX_PAGE_BUTTONS + 1, totalPages);

  return range(leftIndex, rightIndex);
};

export const getFormattedDate = submittedDate =>
  new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  }).format(new Date(submittedDate));
