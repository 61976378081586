import React from "react";
import css from "./AnalysisTable.sass";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import Tooltip from "~brokerage/components/shared/Tooltip";

const headerColumns = [
  { label: "Sold Price", desc: "Sold price range of approved showings." },
  { label: "Total Appointments", desc: "Total approved showings." },
  {
    label: "Percentage of Showings",
    desc: "Percentage of total approved showings."
  },
  { label: "Monthly average", desc: "Average approved showings per month." },
  { label: "Weekly average", desc: "Average approved showings per week." },
  {
    label: "Appointments per Listing",
    desc: "Average number of showings made per listing."
  }
];

export default function AnalysisTable({ analysisData }) {
  const { ranges, numDays, totalShowings } = analysisData;
  return (
    <div className={css.tableContainer}>
      <table className={css.table}>
        <thead className={css.header}>
          <tr>
            {headerColumns.map(({ label, desc }) => (
              <TableHeaderColumn label={label} tooltipText={desc} />
            ))}
          </tr>
        </thead>
        <tbody>
          {ranges.map((range, index) => (
            <Row
              range={range}
              numDays={numDays}
              totalShowings={totalShowings}
              key={`range-${index}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

const TableHeaderColumn = ({ label, tooltipText }) => (
  <th>
    <Tooltip text={tooltipText} direction="up">
      {label}
    </Tooltip>
  </th>
);

const Row = ({ range, numDays, totalShowings }) => {
  const { rangeStart, rangeEnd, showingCount, listingCount } = range;
  const percentOfTotal = ((showingCount / totalShowings) * 100).toFixed(2);
  const dayAvg = showingCount / numDays;
  const monthAvg = (dayAvg * 30).toFixed(2);
  const weekAvg = (dayAvg * 7).toFixed(2);

  return (
    <tr>
      <td>
        {formatPrice(rangeStart, { showZeroPrice: true })}
        {" - "}
        {formatPrice(rangeEnd, { showZeroPrice: true })}
      </td>
      <td>{showingCount}</td>
      <td>{percentOfTotal}</td>
      <td>{monthAvg}</td>
      <td>{weekAvg}</td>
      <td>{(showingCount / listingCount).toFixed(2)}</td>
    </tr>
  );
};
