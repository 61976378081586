const SEARCH_THROTTLE = 200;
import css from "./PeopleSearch.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPeopleQuery } from "~brokerage/actions/people";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import { withRouter } from "react-router-dom";

class PeopleSearch extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };

  searchValue() {
    if (this.searchInput) {
      const value = this.searchInput.value.replace(/[\s]{2,}/g, " ").trim();
      return value.length > 0 ? value : void 0;
    } else {
      return void 0;
    }
  }

  handleSearch = () => {
    const { history, location } = this.props;
    const newSearchValue = this.searchValue();

    if (newSearchValue !== location.query.search) {
      history.push({
        pathname: location.pathname,
        query: { ...location.query, search: this.searchValue() }
      });
    }
    return false;
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleSearch();
    }
  };

  handleOnChange = () => {
    if (this._throttleTimeout) {
      clearTimeout(this._throttleTimeout);
    }
    this.props.dispatch(setPeopleQuery(this.searchValue()));
    this._throttleTimeout = setTimeout(
      () => this.handleSearch(),
      SEARCH_THROTTLE
    );
  };

  render() {
    const { search: currentSearchValue } = this.props.location.query;

    return (
      <div className={css.base}>
        <input
          className={css.input}
          type="search"
          placeholder="Filter by Name, Phone, Address, Brokerage"
          ref={ref => (this.searchInput = ref)}
          defaultValue={currentSearchValue}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleOnChange}
          autoFocus
        />
        <Button variant="search" onClick={this.handleSearch}>
          <div className={css.searchIcon}>
            <Icon shade="gray4" name="search" />
          </div>
        </Button>
      </div>
    );
  }
}
export default withRouter(connect()(PeopleSearch));
