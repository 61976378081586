import React from "react";
import MapPinFillIcon from "remixicon-react/MapPinFillIcon";
import DeleteBin4LineIcon from "remixicon-react/DeleteBin4LineIcon";
import css from "../index.sass";

export default function SelectedPlace({ placeDetails, onClosePress }) {
  return (
    <div className={css.selectedPlace}>
      <div>
        <MapPinFillIcon size={20} className={css.icon} />
      </div>
      <p>{placeDetails.placeName}</p>
      <div className={css.closeBtn} onClick={onClosePress}>
        <DeleteBin4LineIcon size={20} className={css.closeIcon} />
      </div>
    </div>
  );
}
