import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Notifications from "brokerage/app/components/notifications";
import uniqueId from "lodash/uniqueId";

class NotificationRoutes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path={`${this.props.match.path}`} component={Notifications} key={uniqueId("Notifications")} />
        </Switch>
      </div>
    )
  }
}
export default NotificationRoutes;
