import React from "react";
import Modal from "react-modal";

import { formatPhone } from "~brokerage/libs/helpers/FormatHelper";

import Avatar from "~brokerage/components/shared/Avatar";
import List from "~brokerage/components/shared/List";
import { Header } from "~brokerage/components/modals/BaseModal";
import Loader from "~brokerage/components/shared/Loader";
import Button from "~brokerage/components/shared/Button";
import { toast } from "react-toastify";

import css from "./ModalContactDetails.sass";

const ModalContactDetails = ({ contact, closeModal, isModalOpen }) => {
  const Item = ({ title, value, urlScheme, format }) =>
    value ? (
      <div className={css.listItem}>
        <a
          className={css.listItemLink}
          href={urlScheme ? urlScheme + value : "javascript:void(0)"}
        >
          <div className={css.title}>{title}</div>
          <div className={css.value}>{format ? format(value) : value}</div>
        </a>
      </div>
    ) : (
      <></>
    );

  const {
    firstName,
    lastName,
    primaryPhone,
    secondaryPhone,
    email,
    role,
    sellerLink,
    notes
  } = contact;
  const contactName = `${firstName} ${lastName}`;
  const contactPhoto = "";

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      minWidth: "300px",
      maxWidth: "480px",
      padding: "0px"
    },
    overlay: { zIndex: 101 } //The AppointmentDetails modal is at 100
  };

  const onCopyClick = () => {
    toast.info("Link Copied");
    navigator.clipboard.writeText(sellerLink);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customModalStyles}
    >
      {contact ? (
        <div>
          <Header variant="custom" onClose={closeModal}>
            <div className={css.header}>
              <div className={css.avatar}>
                <Avatar size="48" src={contactPhoto} name={contactName} />
              </div>
              <div className={css.headerText}>
                <h1 className={css.h1}>{contactName}</h1>
                <p className={css.p}>{role}</p>
              </div>
            </div>
          </Header>

          <div className={css.body}>
            <List variant="bordered" modifier="overflowHidden">
              <Item
                title="Phone 1"
                value={primaryPhone}
                urlScheme="tel:"
                format={formatPhone}
              />
              <Item
                title="Phone 2"
                value={secondaryPhone}
                urlScheme="tel:"
                format={formatPhone}
              />
              <Item title="Email" value={email} urlScheme="mailto:" />
              <Item title="Notes" value={notes} />
            </List>
          </div>
          {sellerLink && (
            <div className={css.footer}>
              <Button onClick={onCopyClick} variant="outline">
                Copy Seller Appointment Link
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={css.loader}>
          <Loader variant="inline" active />
        </div>
      )}
    </Modal>
  );
};

export default ModalContactDetails;
