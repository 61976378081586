import {
  FEEDBACK_SURVEY_REQUEST,
  FEEDBACK_SURVEY_SUCCESS,
  FEEDBACK_SURVEY_FAILURE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  isFetching: false,
  entity: null
};

export default function surveyReducer(state = defaultState, action) {
  switch (action.type) {
    case FEEDBACK_SURVEY_REQUEST:
      return { entity: null, isFetching: true };
    case FEEDBACK_SURVEY_SUCCESS:
      return {
        entity: action.data,
        isFetching: false
      };
    case FEEDBACK_SURVEY_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false
      };
    default:
      return state;
  }
}
