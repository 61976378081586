import { toast } from "react-toastify";
import { externalApi } from "~brokerage/middlewares/api";
import { MAPBOX_MATRIX_URL } from "~brokerage/app/constants/apiUrls";
import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";

const getTravelTimeMatrix = async (
  showings,
  setTravelTimeMatrix,
  mapboxkey
) => {
  if (showings.length < 2) return;
  
  const hasInvalidGeo = showings.some(
    s => !validateLatLng(s.coordinates?.lat, s.coordinates?.long)
  );
  if (hasInvalidGeo) {
    toast.error(
      "Travel times cannot be calculated as one or more properties can't be located."
    );
    return;
  }

  const coordinates = showings
    .map(t => `${t.coordinates.long},${t.coordinates.lat}`)
    .join(";");
  try {
    const { data } = await externalApi(
      `${MAPBOX_MATRIX_URL}${coordinates}`,
      {
        access_token: mapboxkey
      },
      {},
      "get"
    );
    const travelTimes = data.durations.map((d, index) => ({
      id: showings[index].id,
      durations: d.map(duration => (duration / 60).toFixed(0))
    }));
    setTravelTimeMatrix(travelTimes);
  } catch (err) {
    console.log(err);
  }
};

export default getTravelTimeMatrix;
