import React, { useState } from "react";
import { connect } from "react-redux";

import css from "./index.sass";
import SearchPlace from "./SearchPlace";
import OrderStop from "./OrderStop";
import { steps } from "./constants";
import { Header, Body } from "~brokerage/components/modals/BaseModal";

const AddStopModal = ({ route }) => {
  const [currentStep, setCurrentStep] = useState(steps.SEARCH_STEP);
  const [stopPlace, setStopPlace] = useState(null);

  return (
    <div className={css.base}>
      <Header title="Add Custom Stop" />
      {currentStep === steps.SEARCH_STEP ? (
        <SearchPlace
          showings={route.showings}
          stopPlace={stopPlace}
          setStopPlace={setStopPlace}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <OrderStop
          stopPlace={stopPlace}
          setCurrentStep={setCurrentStep}
          clearStop={() => setStopPlace(null)}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity: route } = routes.single.route || {};
  return {
    route
  };
};

export default connect(mapStateToProps)(AddStopModal);
