import css from './TextField.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { CUSTOM_ERROR } from '~brokerage/constants/validation'
import CustomPropTypes from '~brokerage/libs/CustomPropTypes'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import { basicValidation } from '~brokerage/libs/helpers/TextFieldHelper'
import Errors from './Errors'
import { withFormBinding } from './Form'
import isEqual from 'lodash/isEqual'
export class TextField extends Component {
  static propTypes = {
    focused: PropTypes.bool,
    block: PropTypes.bool,
    trimValue: PropTypes.bool,
    variant: PropTypes.string,
    modifier: CustomPropTypes.modifier,
    name: PropTypes.string,
    errorVariant: PropTypes.string,
    errorDescriptions: PropTypes.object,
    serverErrors: PropTypes.array,
    onFocus: PropTypes.func,
    elRef: PropTypes.func
  }

  static defaultProps = {
    serverErrors: []
  }

  state = {
    errors: [],
    serverError: ''
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.serverErrors, nextProps.serverErrors)) {
      this.setState({
        serverError: this.getServerError(nextProps.serverErrors)
      }, () => this.validate())
    }
  }

  componentDidMount() {
    if (this.props.focused) {
      this.input.focus()
    }
  }

  getServerError(serverErrors) {
    return serverErrors ? serverErrors.join("\n") : ''
  }

  validate = () => {
    const errors = basicValidation(this.input, this.props.trimValue)
    const serverError = this.state.serverError

    if (serverError) errors.push(CUSTOM_ERROR)

    this.setState({ errors: errors })

    return !errors.length
  }

  value = () => {
    return {
      [this.props.name]: this.input.value
    }
  }

  handleFocus = () => {
    if (this.state.errors) {
      this.setState({
        errors: [],
        serverError: ''
      })
    }

    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  render() {
    const { variant, block, name, errorDescriptions, errorVariant, modifier, elRef } = this.props
    const { serverError, errors } = this.state
    const hasErrors = Boolean(errors.length || serverError)

    return (
      <div>
        <input
          type="text"
          className={composeClassName(css, variant, [modifier, {
            block: block,
            error: hasErrors
          }])}
          name={name}
          ref={el => { elRef && elRef(el); this.input = el }}
          onFocus={this.handleFocus}
          {...omit(this.props, Object.keys(TextField.propTypes))}
        />

        {hasErrors &&
          <Errors
            name={name}
            errors={errors}
            variant={errorVariant}
            descriptions={errorDescriptions}
            customError={serverError}
          />
        }
      </div>
    )
  }
}
export default withFormBinding(TextField)
