import css from './ShowingsHeader.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import AgentFilter from './AgentFilter'
import RoleFilter from './RoleFilterContainer'
export default class ShowingsHeader extends PureComponent {
  static propTypes = {
    location: PropTypes.object
  }

  render() {
    return (
      <div className={css.base}>
        <div className={css.sectionWithArrow}>
          <AgentFilter/>
        </div>
        <div className={css.section}>
          <RoleFilter location={this.props.location}/>
        </div>
      </div>
    )
  }
}
