import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import {
  receivedFocus,
  lostFocus,
  changeText,
  reset,
  focus
} from "~brokerage/actions/messageField";
import { Textarea } from "~brokerage/components/shared/Form";
import { on, off, trigger } from "~brokerage/app/helpers/events.js";

const ENTER_KEY = 13;
const MAX_TEXTAREA_HEIGHT = 325;

export default class MessageTextarea extends React.PureComponent {
  static propTypes = {
    isNotesMode: PropTypes.bool,
    shouldBeFocused: PropTypes.bool,
    text: PropTypes.string,
    onSubmit: PropTypes.func,
    elRef: PropTypes.func,
    onHeightChange: PropTypes.func,
    dispatch: PropTypes.func,
    isSearchBarFocused: PropTypes.bool
  };

  componentDidMount() {
    this.lastScrollHeight = this.textarea.scrollHeight;
    this.lastClientHeight = this.textarea.clientHeight;

    on("requestReview", ({ detail: { message } }) => {
      this.handleRequestReview(message);
    });

    window.addEventListener("resize", this.handleWindowResize);
  }

  handleRequestReview = message => {
    if (this.props.isNotesMode) {
      trigger("close:notes");
    }

    if (this.textarea.value && this.textarea.value !== message) {
      let confirmClearingMessage = confirm(
        "This action will clear the message you have started. Would you like to continue?"
      );

      if (!confirmClearingMessage) {
        return;
      }
    }

    this.props.dispatch(changeText(message));
    this.props.dispatch(focus());
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldBeFocused && this.props.shouldBeFocused) {
      this.textarea.focus();
    }

    if (prevProps.text !== this.props.text) {
      this.changeTextareaHeightIfNeeded();
    }
  }

  componentWillUnmount() {
    off("requestReview");
    window.removeEventListener("resize", this.handleWindowResize);
    this.props.dispatch(reset());
  }

  changeTextareaHeightIfNeeded() {
    this.textarea.style.height = "";

    let { scrollHeight } = this.textarea;

    if (scrollHeight > MAX_TEXTAREA_HEIGHT) {
      scrollHeight = MAX_TEXTAREA_HEIGHT;
    }

    this.textarea.style.height = `${scrollHeight}px`;

    if (scrollHeight !== this.lastScrollHeight) {
      this.lastScrollHeight = scrollHeight;
      this.lastClientHeight = this.textarea.clientHeight;
      this.props.onHeightChange();
    }
  }

  handleWindowResize = () => {
    const { clientHeight } = this.textarea;

    if (clientHeight !== this.lastClientHeight) {
      this.lastClientHeight = clientHeight;
      this.props.onHeightChange();
    }
  };

  handleKeyDown = event => {
    if (event.which === ENTER_KEY) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.props.onSubmit();
      }
    }
  };

  handleFocus = () => {
    this.props.dispatch(receivedFocus());
  };

  handleBlur = () => {
    this.props.dispatch(lostFocus());
  };

  handleChange = event => {
    this.props.dispatch(changeText(event.target.value));
  };

  render() {
    const { isNotesMode, text } = this.props;

    return (
      <Textarea
        variant={isNotesMode ? "note" : "message"}
        name="message"
        placeholder={`Tap to create a ${isNotesMode ? "note" : "message"}...`}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        focused={!this.props.isSearchBarFocused}
        value={text}
        elRef={el => {
          this.textarea = el;
        }}
        {...omit(this.props, Object.keys(MessageTextarea.propTypes))}
      />
    );
  }
}
