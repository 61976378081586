import React from "react";

import Avatar from "~brokerage/components/shared/Avatar";
import Tooltip from "~brokerage/components/shared/Tooltip";
import Button from "~brokerage/components/shared/Button";

import css from "./Contact.sass";

const Contact = ({ contact, openModal }) => {
  const renderContactBtn = () => (
    <Button onClick={openModal} variant="outline" modifier="colorWhite">
      Contact
    </Button>
  );

  let tooltipText = `${contact.firstName} ${contact.lastName}`;
  if (contact.role) {
    tooltipText += ` (${contact.role})`;
  }

  return (
    <div className={css.base}>
      <Tooltip text={tooltipText} footer={renderContactBtn()}>
        <div className={css.inner}>
          <Avatar
            size="30"
            src={""}
            name={`${contact.firstName} ${contact.lastName}`}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default Contact;
