import React from "react";
import PropTypes from "prop-types";
import ReactList from "react-list";

import ListItemLoader from "~brokerage/components/shared/ListItemLoader";
import Card from "./Card";
import css from "./index.sass";

const itemsRenderer = (items, ref) => {
  return (
    <div className={css.container} ref={ref}>
      {items}
    </div>
  );
};

const CardView = ({
  agentId,
  listings,
  needLoadMoreListings,
  loadMoreListings,
  dispatch,
  activeListingStatuses
}) => {
  const itemRenderer = (index, key) => {
    if (index >= listings.length) {
      return null;
    }

    const listing = listings[index];

    return (
      <ListItemLoader
        key={key}
        needLoadMore={needLoadMoreListings(index)}
        loadMore={loadMoreListings}
        uniqueId={listing.id}
      >
        <Card
          listing={listing}
          agentId={agentId}
          dispatch={dispatch}
          activeListingStatuses={activeListingStatuses}
        />
      </ListItemLoader>
    );
  };

  return (
    <ReactList
      itemsRenderer={itemsRenderer}
      itemRenderer={itemRenderer}
      length={listings.length}
      type="uniform"
    />
  );
};

export default CardView;
