if (['staging', 'production'].includes(process.env.NODE_ENV)) {
  const Bugsnag = require('bugsnag-js')

  const $bugsnagSpan = $('[data-for~=bugsnag]')

  if ($bugsnagSpan.length) {
    Bugsnag.apiKey = $bugsnagSpan.data('api-key')
    Bugsnag.releaseStage = $bugsnagSpan.data('environment')
    Bugsnag.notifyReleaseStages = ['production', 'staging']
    Bugsnag.metaData = { environment: { mobile: $bugsnagSpan.data('mobile-application') != null } }
  }
} else {
  window.Bugsnag = {
    notify() {},
    notifyException() {}
  }
}
