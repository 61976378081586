import css from './Badge.sass'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Badge extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    shape: PropTypes.string,
    children: PropTypes.node
  }

  static defaultProps = {
    variant: 'base',
    shape: 'round'
  }

  render() {
    return (
      <div className={classNames(css[this.props.variant], css[this.props.shape])}>
        {this.props.children}
      </div>
    )
  }
}
