import css from '../index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'
import Link from '~brokerage/components/shared/Link'
import { format } from '~brokerage/libs/helpers/PhonesHelper'

export default class ContactDetails extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }

  renderPhone() {
    if (!this.props.phone) return

    return (
      <div>
        <Icon name="phone"/> {format(this.props.phone)}
      </div>
    )
  }

  renderPersonLink() {
    const { id, name } = this.props
    if (!id) return name

    return (
      <div>
        <Link to={`/people/${id}`}>{name}</Link>
      </div>
    )
  }

  render() {
    const { email } = this.props

    return (
      <ul className={css.ul}>
        <li className={css.li}>
          {this.renderPersonLink()}
          {this.renderPhone()}
          <div>
            <Icon name="mail"/> {email}
          </div>
        </li>
      </ul>
    )
  }
}
