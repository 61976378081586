import React, { useContext } from "react";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import Button from "~brokerage/components/shared/Button";

const SubmitSettings = ({ ...rest }) => {
  const { pageState } = useContext(SettingsContext);
  const disabled = pageState.savingProfile;

  return (
    <Button variant="primary" type="submit" disabled={disabled} {...rest}>
      Save
    </Button>
  );
};

export default SubmitSettings;
