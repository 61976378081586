import React from "react";
import moment from "moment";

import css from "./ShowingActions.sass";

const ShowingActions = ({
  showingStatus,
  requestedTime,
  openStatusModal,
  showingInvolvement
}) => {
  const futureShowing = moment(requestedTime).isAfter();
  const isEditableBySeller =
    futureShowing &&
    ["can_approve", "can_approve_no_comment"].includes(showingInvolvement) &&
    (showingStatus === "pending" || showingStatus === "approved");

  if (!isEditableBySeller) {
    return <div />; //If past appointment / declined / cancelled
  }

  return (
    <div className={css.showingActions}>
      {showingStatus === "pending" ? (
        <>
          {" "}
          <div
            onClick={() => openStatusModal("approved")}
            className={css.approveButtonHalf}
          >
            Approve
          </div>
          <div
            onClick={() => openStatusModal("declined")}
            className={css.declineButtonHalf}
          >
            Decline
          </div>
        </>
      ) : (
        <div
          onClick={() => openStatusModal("declined")}
          className={css.declineButtonFull}
        >
          Decline
        </div>
      )}
    </div>
  );
};

export default ShowingActions;
