import React from "react";
import {
  Row,
  Label,
  Controls,
  Col,
  Dropdown
} from "~brokerage/components/shared/Form";
import Switch from "~brokerage/components/shared/Switch";
import { buildMaximumOverlapLimitOptions } from "./utils";
import css from "./ShowingInstructions.sass";

const AllowShowingOverlap = ({
  allowShowingOverlap,
  maxOverlappingShowingsCount,
  handleChange
}) => {
  return (
    <>
      <Row>
        <Label valign="switch">Allow overlapping appointments?</Label>
        <div className={css.switchControls}>
          <Controls>
            <Switch
              name="allowShowingOverlap"
              checked={allowShowingOverlap}
              onChange={handleChange}
            />
          </Controls>
        </div>
      </Row>
      {allowShowingOverlap && (
        <Row>
          <div className={css.label}>
            <Label valign="dropdown">Maximum overlap limit</Label>
          </div>
          <Controls>
            <Col>
              <Dropdown
                name="maxOverlappingShowingsCount"
                options={buildMaximumOverlapLimitOptions()}
                selected={maxOverlappingShowingsCount}
                onChange={handleChange}
                variant="outline2"
              />
            </Col>
          </Controls>
        </Row>
      )}
    </>
  );
};

export default AllowShowingOverlap;
