import config from '~brokerage/config/config'
import { formatTime, inLocalTimeZoneWithoutConversion } from './TimeHelper'
import { formatDate } from './DateHelper'
import moment from 'moment'

export function sortRestrictions(restrictions) {
  return restrictions.sort((a, b) => {
    if (a.repetitionType === 'weekly' && b.repetitionType === 'one_time') {
      return -1
    }
    if (a.repetitionType === 'one_time' && b.repetitionType === 'weekly') {
      return 1
    }
    return 0
  })
}

export function renderTimeRangesFor(restriction) {
  if (!restriction.timeRanges) {
    return 'All Day'
  }

  const renderTimeRange = (timeRange) => {
    const startAt = formatTime(inLocalTimeZoneWithoutConversion(timeRange.startAt))
    const endAt = formatTime(inLocalTimeZoneWithoutConversion(timeRange.endAt))
    return `${startAt}–${endAt}`
  }

  return ' ' + restriction.timeRanges.map(renderTimeRange).join(', ')
}

export function renderDays(restriction) {
  return restriction.days.map(day => config.daysShort[day]).join(', ')
}

export function renderDates(restriction) {
  return restriction.dates
    .map(date => formatDate(date, { inUsersTz: true }))
    .join(", ");
}

export function convertDaysIndicesToDays(daysIndices) {
  if (!daysIndices) return []

  return daysIndices.map(di => config.daysFull[di])
}

export function convertTimeRangesToStrings(timeRanges) {
  if (!timeRanges) return []

  return timeRanges.map(tr => ({
    startAt: moment(tr.startAt).format("H:mm"),
    endAt: moment(tr.endAt).format("H:mm")
  }))
}
