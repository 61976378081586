import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import css from "../index.sass";
import moment from "moment";

import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import Timeline from "~brokerage/components/shared/Timeline";
import ShowingProvider from "~brokerage/components/shared/Timeline/ShowingProvider";
import { STATUS_DECLINED } from "~brokerage/constants/showings/statuses";
import Actions from "./Actions";
import { Body } from "~brokerage/components/modals/BaseModal";

const OrderStop = ({
  stopPlace,
  route,
  unsavedChanges,
  setCurrentStep,
  clearStop
}) => {
  const [staticAppointments, setStaticAppointments] = useState([]);
  const [activeAppointments, setActiveAppointments] = useState([]);

  useEffect(() => {
    fetchTheDay();
  }, []);

  const timeForStop = showings => {
    if (showings.length) {
      const lastShowing = showings[showings.length - 1];
      return moment(lastShowing.requestedTimeRaw)
        .add(lastShowing.duration, "minutes")
        .format();
    } else return moment(route.date).format();
  };

  const fetchTheDay = async () => {
    const { date, stops } = route;
    const showingIds = route.showings.map(s => s.unique_id);
    try {
      const { data } = await callApi(
        "showings",
        {
          date,
          limit: 100
        },
        {},
        "get"
      );
      let staticShowings = [];
      let activeShowings = [];

      data.showings.forEach(s => {
        if (showingIds.includes(s.id) && s.status !== STATUS_DECLINED) {
          let changes = {};
          if (unsavedChanges) {
            changes = unsavedChanges.find(i => i.id === s.id) || {};
          }
          activeShowings.push({ ...s, ...changes });
        } else {
          s.status !== STATUS_DECLINED && staticShowings.push(s);
        }
      });
      stops.forEach(stop => {
        let changes = {};
        if (unsavedChanges) {
          changes = unsavedChanges.find(i => i.id === stop.id);
        }
        if (!changes.requestedTimeRaw)
          changes.requestedTimeRaw = stop.start_time;
        if (!changes.travelTime) changes.travelTime = stop.travel_time;
        activeShowings.push({
          ...stop,
          ...changes,
          listingKey: stop.id,
          photoUrl: null
        });
      });
      //add new stops from unsaved changes
      const addedStops = unsavedChanges
        ? unsavedChanges
            .filter(s => s.type === "stop" && s.id < 0)
            .map(stop => ({ ...stop, listingKey: stop.id, photoUrl: null }))
        : [];
      if (addedStops.length) activeShowings.push(...addedStops);
      activeShowings.sort(
        (a, b) => new Date(a.requestedTimeRaw) - new Date(b.requestedTimeRaw)
      );

      //add newest stop from previous step
      const idForNewStop = addedStops.length
        ? Math.min(...addedStops.map(stop => stop.id)) - 1
        : -1;
      const stopStartTime = timeForStop(activeShowings);
      activeShowings.push({
        id: idForNewStop,
        type: "stop",
        listingKey: idForNewStop,
        requestedTimeRaw: stopStartTime,
        address: stopPlace.placeName,
        photoUrl: null,
        duration: 15,
        coordinates: stopPlace.coordinates
      });

      setStaticAppointments(staticShowings);
      setActiveAppointments(activeShowings);
    } catch (error) {
      console.log(error);
      toast.error("Failed to get route appointments.");
    }
  };
  return (
    <ShowingProvider>
      <Body>
        <div className={css.orderBase}>
          <Timeline
            showRestrictions={false}
            staticAppointments={staticAppointments}
            activeAppointments={activeAppointments}
            updateForTravelTime
            updateOnlyLastItem
          />
        </div>
      </Body>
      <Actions
        showings={activeAppointments}
        unsavedChanges={unsavedChanges}
        setCurrentStep={setCurrentStep}
        clearStop={clearStop}
      />
    </ShowingProvider>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity: route } = routes.single.route || {};
  const { unsavedChanges } = route || {};
  return {
    route,
    unsavedChanges
  };
};

export default connect(mapStateToProps)(OrderStop);
