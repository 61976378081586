import React from "react";
import css from "./index.sass";
import { Chart } from "react-google-charts";
import AnalysisTable from "./AnalysisTable";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";

const options = {
  width: "100%",
  title: "Analysis chart",
  legend: { position: "none" },
  fontName: "Helvetica Neue",
  bars: "horizontal",
  axes: {
    x: {
      0: { side: "top", label: "Number of showings" } // Top x-axis.
    }
  },
  colors: ["#076CAD"]
};

export default function AnalysisChart({ analysisData }) {
  const { ranges } = analysisData;
  const data = [
    ["Price Range", "Number of Showings"],
    ...ranges.map(({ rangeStart, rangeEnd, showingCount }) => [
      `${formatPrice(rangeStart, { showZeroPrice: true })} - ${formatPrice(
        rangeEnd,
        { showZeroPrice: true }
      )}`,
      showingCount
    ])
  ];
  return (
    <div className={css.chartView} id="print-target">
      <p className={css.title}>Analysis Chart</p>
      <p className={css.subtitle}>
        Approved showing price-ranges by number of showings
      </p>
      <div className={css.displayChart}>
        <Chart chartType="Bar" height="400px" data={data} options={options} />
      </div>
      <div className={css.printChart}>
        <Chart
          chartType="Bar"
          height="400px"
          data={data}
          options={{ ...options, width: "675px" }}
        />
      </div>
      <p className={css.title}>Analysis Details</p>
      <AnalysisTable analysisData={analysisData} />
    </div>
  );
}
