const LISTING_FEEDBACK_URL_REGEX = /\/my_listings\/[0-9]+\/survey/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

export default class ListRoute extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
  }

  handleClick = (event) => {
    const link = closestTag(event.target, 'a')

    if (!link) {
      return false
    }

    const url = link.getAttribute('href')

    if (url && this.isListingFeedbackLink(url)) {
      this.handleListingFeedbackClick(url)
      return true
    }
    return false
  }

  isListingFeedbackLink(url) {
    return LISTING_FEEDBACK_URL_REGEX.test(url)
  }

  listingId(url) {
    const match = url.match(/\/my_listings\/([0-9]+)\/survey/)
    return match ? match[1] : void 0
  }

  handleListingFeedbackClick(url) {
    history.push({
      pathname: `/listings/${this.listingId(url)}/feedback`,
      query: { backToPath: '/alerts' }
    })
  }

  render() {
    return <OldInterfaceIframe
      url="/notifications?dd"
      onClick={this.handleClick}
      location={this.props.location}
    />
  }
}
