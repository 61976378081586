import React from "react";

import css from "./StatusPill.sass";

import {
  formatStatusText,
  statusColor,
  statusIcon
} from "~brokerage/app/constants/appointments.js";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

const StatusPill = ({ showingStatus, futureShowing = true, style = {} }) => {
  const pillText = formatStatusText(showingStatus, !futureShowing);
  const pillColor = statusColor(showingStatus, !futureShowing);
  const PillIcon = statusIcon(showingStatus);

  return (
    <div
      className={css.PillContainer}
      style={{ backgroundColor: pillColor, ...style }}
    >
      <PillIcon color="#fff" size={17} />{" "}
      <div className={css.finalText}>{pillText}</div>
    </div>
  );
};

export default StatusPill;
