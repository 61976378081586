import React from "react";
import List from "~brokerage/components/shared/List";

const PropertySpecItem = ({
  data,
  icon,
  formatFunction = data => data,
  suffix = "",
  additionalModifier = "",
  renderWhenZero = false
}) => {
  if (data === undefined || (!data && !renderWhenZero)) return null;

  const formattedData = renderWhenZero && !data ? 0 : formatFunction(data);
  const formattedSuffix = suffix ? ` ${suffix}` : "";

  return (
    <List.Item
      icon={icon}
      modifier={`pullLeft rpMarginBottom ${additionalModifier}`}
    >
      {`${formattedData}${formattedSuffix}`}
    </List.Item>
  );
};

export default PropertySpecItem;
