import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchDefaultShowingSetting,
  updateDefaultShowingSettingChanges,
  saveDefaultShowingSettingChanges,
  saveInitialDefaultAdvanceNoticeDuration
} from "~brokerage/actions/settings";
import {
  Form,
  Row,
  Label
} from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Loader from "~brokerage/components/shared/Loader";
import AllowShowingOverlap from "~brokerage/components/shared/EditShowingInstructions/AllowShowingOverlap";
import MaxAppointmentLength from "~brokerage/components/shared/EditShowingInstructions/MaxAppointmentLength";
import BuyerNameRequired from "~brokerage/components/shared/EditShowingInstructions/BuyerNameRequired";
import AdvanceNotice from "~brokerage/components/shared/EditShowingInstructions/AdvanceNotice";
import RequestHandling from "~brokerage/components/shared/EditShowingInstructions/RequestHandling";
import ShowingArrangement from "~brokerage/components/shared/EditShowingInstructions/ShowingArrangement";
import InitialMessage from "~brokerage/components/shared/EditShowingInstructions/InitialMessage";
import RequestCallback from "~brokerage/components/shared/EditShowingInstructions/RequestCallback";
import AdditionalInstructions from "~brokerage/components/shared/EditShowingInstructions/AdditionalInstructions";
import ShowingAccess from "~brokerage/components/shared/EditShowingInstructions/ShowingAccess";
import { buildInstructionsPayload } from "./utils";
import css from "./index.sass";

const DefaultShowingSettings = props => {
  const {
    mlsBuyerRequiredEnabled,
    defaultShowingSetting,
    initialDefaultShowingSetting,
    initialAdvanceNoticeDuration,
    isFetching,
    dispatch,
    defaultShowingSetting: {
      buyerNameRequired,
      allowShowingOverlap,
      maxOverlappingShowingsCount,
      maxAppointmentDuration,
      requestHandling,
      accompanyAgent,
      accompanySeller,
      initialMessage,
      callbackRequest,
      message,
      access,
      combo,
      brand
    }
  } = props;

  const [hasChanged, setHasChanged] = useState(false);
  const history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    dispatch(fetchDefaultShowingSetting());
  }, []);

  const updateHasChanged = () => {
    if (!hasChanged) setHasChanged(true);
  }

  const changePayload = event => {
    const input = event.target;
    let payload;

    if (!input) {
      payload = event;
    } else {
      if (input.type === "checkbox") {
        payload = { [input.name]: input.checked };
      } else {
        payload = { [input.name]: input.value };
      }
    }

    return payload;
  };

  const handleChange = event => {
    const payload = changePayload(event);
    dispatch(updateDefaultShowingSettingChanges(payload));
    updateHasChanged();
  };

  const saveData = () => {
    const payload = buildInstructionsPayload({ mlsBuyerRequiredEnabled, defaultShowingSetting });

    dispatch(saveDefaultShowingSettingChanges(payload));
    toast.success("Showing instructions updated successfully!");
    history.replace("/settings/system");
  };

  const isFormValid = () => {
    return formRef.current.props.validate;
  }

  const handleSave = () => {
    if (hasChanged && isFormValid()) saveData();
  };

  const handleCancel = () => {
    dispatch(updateDefaultShowingSettingChanges(initialDefaultShowingSetting));
    dispatch(setInitialAdvanceNoticeDuration(initialDefaultShowingSetting.advanceNoticeDuration));
  };

  if (isFetching) {
    return <Loader active />;
  }

  return (
    <>
      <Button
        className={css.backButton}
        variant="link"
        onClick={() => history.replace("/settings/system")}
      >
        <Icon name="arrowLeft" modifier="offset5 valignTextDefault" />
        Back
      </Button>
      <Form onSubmit={handleSave} validate ref={formRef}>
        <Label className ={css.timeDateHeading}> Default Listing Setup </Label>
        <div className={css.showingSettingsForm}>
          <AllowShowingOverlap
            allowShowingOverlap={allowShowingOverlap}
            maxOverlappingShowingsCount={maxOverlappingShowingsCount}
            handleChange={handleChange}
          />
          <MaxAppointmentLength
            handleChange={handleChange}
            maxAppointmentDuration={maxAppointmentDuration}
          />
          <RequestHandling
            handleChange={handleChange}
            requestHandling={requestHandling}
          />
          <InitialMessage
            requestHandling={requestHandling}
            initialMessage={initialMessage}
            handleChange={handleChange}
          />
          <ShowingAccess
            access={access}
            combo={combo}
            brand={brand}
            handleChange={handleChange}
          />
          <ShowingArrangement
            accompanyAgent={accompanyAgent}
            accompanySeller={accompanySeller}
            handleChange={handleChange}
          />
          <RequestCallback
            callbackRequest={callbackRequest}
            handleChange={handleChange}
          />
          <AdditionalInstructions
            message={message}
            handleChange={handleChange}
          />
          <BuyerNameRequired
            mlsBuyerRequiredEnabled={mlsBuyerRequiredEnabled}
            buyerNameRequired={buyerNameRequired}
            handleChange={handleChange}
          />
          <AdvanceNotice
            showingInstructions={defaultShowingSetting}
            handleChange={handleChange}
            previousAdvanceNoticeDuration={initialAdvanceNoticeDuration}
            setInitialAdvanceNoticeDuration={saveInitialDefaultAdvanceNoticeDuration}
            dispatch={dispatch}
          />
          <Row>
            <ButtonSet align="right">
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="outline" type="reset" onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonSet>
          </Row>
        </div>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  const {
    settings: { defaultShowingSetting: { defaultShowingSetting, isFetching, initialDefaultShowingSetting, initialAdvanceNoticeDuration } },
    currentUser: { mlsBuyerRequiredEnabled }
  } = state;

  return {
    defaultShowingSetting,
    initialDefaultShowingSetting,
    mlsBuyerRequiredEnabled,
    initialAdvanceNoticeDuration,
    isFetching
  };
}

DefaultShowingSettings.propTypes = {
  mlsBuyerRequiredEnabled: PropTypes.bool,
  defaultShowingSetting: PropTypes.object,
  initialDefaultShowingSetting: PropTypes.object,
  initialAdvanceNoticeDuration: PropTypes.number,
  isFetching: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(DefaultShowingSettings);
