import css from './Grid.sass'
import React, { Component } from 'react'
import { formatTime } from '~brokerage/libs/helpers/TimeHelper'

export default class Grid extends React.PureComponent {
  get timeList() {
    const result = []

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 59; minute += 30) {
        result.push(formatTime(hour, minute))
      }
    }

    return result
  }

  render() {
    return (
      <div className={css.base}>
        {this.timeList.map((time, index) =>
          <div key={index} className={css[index % 2 ? 'halfhour' : 'hour']}>
            <div className={css.time}>{time}</div>
          </div>
        )}
        <div className={css.halfhour}/>
        <div className={css.hour}/>
      </div>
    )
  }
}
