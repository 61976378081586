import { CALL_API } from '~brokerage/middlewares/api'

export function fetchListings(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'new_showing/listings',
      params
    }
  }
}

export function fetchListing(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}`
    }
  }
}

export function addRestriction(listingId, restriction, types) {
  return {
    [CALL_API]: {
      types,
      method: 'POST',
      endpoint: `listings/${listingId}/restrictions`,
      data: {
        listing_restriction: restriction
      }
    }
  }
}

export function updateRestriction(listingId, restrictionId, restriction, types) {
  return {
    [CALL_API]: {
      types,
      method: 'PUT',
      endpoint: `listings/${listingId}/restrictions/${restrictionId}`,
      data: {
        listing_restriction: restriction
      }
    }
  }
}

export function removeRestriction(listingId, restrictionId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'DELETE',
      endpoint: `listings/${listingId}/restrictions/${restrictionId}`
    }
  }
}

export function fetchShowingDetails(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/showing_details`
    }
  }
}

export function fetchShowingInstructions(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/showing_instructions_settings`
    }
  }
}

export function updateShowingInstructions(id, payload, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/showing_instructions_settings`,
      method: 'put',
      data: { instructions: payload }
    }
  }
}

export function fetchListingTeamMembers(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/team_members`
    }
  }
}

export function updateListingTeamMember(id, payload, types) {
  return {
    [CALL_API]: {
      types,
      data: { team: payload },
      endpoint: `listings/${id}/team`,
      method: 'patch'
    }
  }
}

export function fetchSellerTenantInvolvement(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/contacts`
    }
  }
}

export function updateSellerTenantInvolvement(id, payload, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/seller_tenant_involvement`,
      method: 'patch',
      data: { seller_tenant_involvement: payload }
    }
  }
}

export function fetchRestrictions(id, date, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/restricted_time_ranges`,
      params: { date }
    }
  }
}

export function fetchListingRestrictions(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `listings/${id}/restrictions`
    }
  }
}

export function updateShowingCoordinatorEnabled(id, enabled, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      endpoint: `listings/${id}/showing_details`,
      params: {
        showing_setting: {
          showing_coordinator_enabled: enabled
        }
      }
    }
  }
}
