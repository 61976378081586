import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

export default class OldInterfaceRoutes extends PureComponent {
  static propTypes = {
    location: PropTypes.object
  }

  render() {
    return <OldInterfaceIframe url="/routes" location={this.props.location}/>
  }
}
