import css from './ModalError.sass'
import flatMap from 'lodash/flatMap'
import values from 'lodash/values'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Body, Footer } from '~brokerage/components/modals/BaseModal'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'

export default class ModalError extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onClose: PropTypes.func
  }

  render() {
    const { title, errors, onClose } = this.props

    return (
      <div className={css.overlay}>
        <div className={css.base}>
          <div className={css.header}>
            <h1 className={css.h1}>{title}</h1>
            <div className={css.btnCancel}>
              <Button onClick={onClose} variant="cancel">
                <Icon name="decline2"/>
              </Button>
            </div>
          </div>
          <Body>
            {(() => {
              if (typeof errors === 'object') {
                return flatMap(values(errors)).map(error =>
                  <p>{error}</p>
                )
              } else {
                <p>{errors}</p>
              }
            })()}
          </Body>
          <Footer>
            <div className={css.footerBtn}>
              <Button variant="primary" onClick={onClose}>OK</Button>
            </div>
          </Footer>
        </div>
      </div>
    )
  }
}
