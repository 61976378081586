import React, { useState } from "react";
import Modal from "react-modal";
import ModalContents from "~brokerage/components/modals/ModalTeamInstructions/ModalContents";
import css from "./index.sass";

const AppointmentEditTeamInstructionsModal = ({
  handleSave,
  message,
  closeModal,
  showingId
}) => {
  Modal.setAppElement(document.body);
  const [value, setValue] = useState(message || "");

  const handleInstructionsChange = e => {
    const { value } = e.target;
    setValue(value);
  };
  const handleTeamInstructionsSave = () => {
    handleSave({ team: { instructions: value } }).then(closeModal);
  };

  return (
    <Modal
      className={css.modalContent}
      overlayClassName={css.modalOverlay}
      isOpen={showingId !== null}
      onRequestClose={closeModal}
    >
      <ModalContents
        value={value}
        onChange={handleInstructionsChange}
        handleSave={handleTeamInstructionsSave}
        onClose={closeModal}
      />
    </Modal>
  );
};

export default AppointmentEditTeamInstructionsModal;
