import {
  MY_ORGANIZATION_MEMBERS_REQUEST,
  MY_ORGANIZATION_MEMBERS_SUCCESS,
  MY_ORGANIZATION_MEMBERS_FAILURE
} from '~brokerage/constants/actionTypes'

export default function agents(state = { entities: [], isFetching: false }, action) {
  switch (action.type) {
    case MY_ORGANIZATION_MEMBERS_REQUEST:
      return { ...state, isFetching: true }
    case MY_ORGANIZATION_MEMBERS_SUCCESS:
      return { ...state, entities: action.data.members, isFetching: false }
    case MY_ORGANIZATION_MEMBERS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors }
    default:
      return state
  }
}
