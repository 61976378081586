import React from "react";
import Modal from "react-modal";
import Button from "~brokerage/components/shared/Button";
import css from "./NavigationPrompt.sass";

const NavigationPrompt = ({ message, open, buttons = [] }) => {
  Modal.setAppElement(document.body);
  return (
    <Modal isOpen={open} className={css.content} overlayClassName={css.overlay}>
      <div className={css.header}>{message.heading}</div>
      <div className={css.body}>{message.body}</div>
      <div className={css.footer}>
        {buttons.map(button => (
          <Button
            key={button.label}
            variant={button.variant}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        ))}
      </div>
    </Modal>
  );
};

export default NavigationPrompt;
