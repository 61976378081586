 import { inUsersTimezone } from './TimeHelper'
 import moment from 'moment'

 export function formatDate(date, options = {}) {
  date = moment(date);

  if(options.inUsersTz){
    date = inUsersTimezone(date)
  }
  return `${date.month() + 1}/${date.date()}/${date.year()}`;
}
