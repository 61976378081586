import css from './SelectShowingAgent.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchAgents, selectAgent, preserveGlobalState } from '~brokerage/actions/newShowing'
import { fetchPreferencesIfNeeded } from '~brokerage/actions/myAccount'
import { plural } from '~brokerage/libs/helpers/FormatHelper'
import { Form, Row, TextField } from '~brokerage/components/shared/Form'
import Agent from './Agent'
import Header from '../Header'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import List from '~brokerage/components/shared/List'
import PremiumButtonWrapper, { PREMIUM_FEATURE_TYPES } from '~brokerage/components/shared/PremiumButtonWrapper'
import Loader from '~brokerage/components/shared/Loader'
export class SelectShowingAgent extends Component {
  static propTypes = {
    agents: PropTypes.array,
    totalAgents: PropTypes.number,
    listingId: PropTypes.string,
    listingOwnedByCurrentUser: PropTypes.bool,
    canScheduleShowingsForOtherUsers: PropTypes.bool,
    isFetching: PropTypes.bool,
    selectedListingIsFetching: PropTypes.bool,
    dispatch: PropTypes.func
  }

  state = {
    activeInput: false,
    activeSearch: false,
    agentsFetched: null,
    agentsFetchedOnMount: null
  }

  componentWillMount() {
    const { selectedListingIsFetching, listingOwnedByCurrentUser, listingId } = this.props

    this.setState({agentsFetched: false})

    this.props.dispatch(fetchPreferencesIfNeeded())

    if (!selectedListingIsFetching && !listingOwnedByCurrentUser && listingId) {
      this.props.dispatch(fetchAgents({
        name: '',
        listing_id: listingId
      })).then(this.setState({agentsFetched: true, agentsFetchedOnMount: true}))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { listingId: oldListingId, canScheduleShowingsForOtherUsers: oldPreferences } = this.props
    const { canScheduleShowingsForOtherUsers: preferences, listingOwnedByCurrentUser, listingId } = nextProps

    if (!listingId ||
        this.state.agentsFetchedOnMount ||
        listingOwnedByCurrentUser ||
        oldListingId === listingId && oldPreferences === preferences && this.state.agentsFetched) return

    this.props.dispatch(fetchAgents({
      name: '',
      listing_id: listingId
    })).then(this.setState({agentsFetched: true, agentsFetchedOnMount: false}))
  }

  handleSubmit = (event, values) => {
    this.props.dispatch(fetchAgents({ ...values, listing_id: this.props.listingId }))
    this.setState({ activeSearch: true })
  }

  handleAgentClick = agent => {
    this.props.dispatch(selectAgent(agent))
  }

  handleOnChange = (event) => {
    const hasSeachValue = event.target.value
    this.setState({ activeInput: hasSeachValue })
    if(!hasSeachValue) this.setState({ activeSearch: false })
  }

  renderAgentList = agents => {
    return agents.map(agent => {
      return (
        <Agent
          key={agent.id}
          {...agent}
          onClick={this.handleAgentClick.bind(this, agent)}
        />
      )
    })
  }

  preserveState = () => {
    this.props.dispatch(preserveGlobalState())
  }

  renderUpgradeBanner() {
    const { listingOwnedByCurrentUser, canScheduleShowingsForOtherUsers } = this.props

    const premiumRequired = !(listingOwnedByCurrentUser || canScheduleShowingsForOtherUsers)

    if (premiumRequired) {
      return (
        <List.Item modifier="padding15" variant="bordered">
          <div className={css.premiumBanner}>
            <PremiumButtonWrapper
              featureType={PREMIUM_FEATURE_TYPES.SCHEDULE_SHOWING_FOR_OTHER_USERS}
              beforeOnClick={this.preserveState}
              skipPremiumPreferencesFetching={true}
            >
              <Button variant='link'>Upgrade to schedule</Button>
            </PremiumButtonWrapper>
            <span className={css.offsetLeft5}>on behalf of another agent</span>
          </div>
        </List.Item>
      )
    }
  }

  renderSearchResultTitle() {
    const { agents, totalAgents } = this.props

    if (totalAgents && totalAgents > 0) {
      return `First ${agents.length} of ${totalAgents} agent${plural(totalAgents)}`
    }

    return `${agents.length} Search Result${plural(agents.length)}`
  }

  renderAgents() {
    const { agents, isFetching } = this.props
    const { activeInput, activeSearch } = this.state

    if (isFetching) return <Loader active />
    if (!agents.length && !activeSearch) return
    if (!agents.length && activeInput && activeSearch) {
      return <div className={css.noAgentsText}>No agents found</div>
    }

    return (
      <div className={css.results}>
        <div className={css.resultsHeading}>
          {this.renderSearchResultTitle()}
        </div>
        <List variant="bordered">
          {this.renderAgentList(agents)}
          {this.renderUpgradeBanner()}
        </List>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Header title="Select Agent"/>
        <div className={css.body}>
          <Row offset="0">
            <Form onSubmit={this.handleSubmit}>
              <div className={css.textFieldAndBtnSearch}>
                <TextField
                  name="name"
                  type="search"
                  placeholder="Search Showing Agent by Name or Brokerage"
                  autoComplete="off"
                  onChange={this.handleOnChange}
                  focused
                  block
                />
                <div className={css.btnSearch}>
                  <Button type="submit" variant={this.state.activeInput ? 'searchBlue' : 'searchDark'}>
                    <Icon name="search"/>
                  </Button>
                </div>
              </div>
            </Form>
          </Row>
          <div className={this.state.activeInput ? css.helpText : css.hidden}>
            Press Enter to Search
          </div>
          {this.renderAgents()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { entities: agents, total: totalAgents, isFetching } = state.showings.new.agents
  const { isFetching: selectedListingIsFetching } = state.showings.new.selectedListing
  const {
    id: listingId,
    isOwnedByCurrentUser: listingOwnedByCurrentUser
  } = state.showings.new.selectedListing.entity
  const { canScheduleShowingsForOtherUsers } = state.myAccount.preferences

  return {
    agents,
    totalAgents,
    listingId,
    listingOwnedByCurrentUser,
    canScheduleShowingsForOtherUsers,
    isFetching,
    selectedListingIsFetching
  }
}
export default connect(mapStateToProps)(SelectShowingAgent)
