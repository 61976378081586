import React from "react";
import PropTypes from "prop-types";
import css from "./index.sass";
import Header from "./Header";
import Loader from "~brokerage/components/shared/Loader";
import Showing from "./Showing";
import Footer from "./Footer";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import moment from "moment";
import Stop from "./Stop";

const Route = ({
  fetchingRoute,
  fetchingFastest,
  id,
  date,
  status,
  showings = [],
  originalShowings,
  optimized,
  hasInvalidGeo,
  handleCancel
}) => {
  const isFetching = fetchingRoute || fetchingFastest;
  const cancellable = status === "active";
  const routeHasStarted = moment(date).isBefore();

  const disableFastestRoute = hasInvalidGeo
    ? "invalidGeo"
    : routeHasStarted
    ? "hasStarted"
    : false;

  const disableReorder = routeHasStarted ? "hasStarted" : false;

  return (
    <div className={css.container}>
      <Header
        routeId={id}
        startTime={date}
        optimized={optimized}
        showings={showings}
        disableFastestRoute={disableFastestRoute}
        disableReorder={disableReorder}
      />
      <div
        className={composeClassName(css, "list", {
          withFooter: cancellable
        })}
      >
        {isFetching ? (
          <Loader active />
        ) : (
          showings.map((s, index) =>
            s.type === "stop" ? (
              <Stop
                details={s}
                index={index}
                key={`stop-${s.id}`}
                routeId={id}
              />
            ) : (
              <Showing
                routeId={id}
                index={index}
                key={s.id}
                showing={s}
                originalShowing={originalShowings.find(
                  og => og.unique_id === s.unique_id
                )}
              />
            )
          )
        )}
        {!showings.length && (
          <div className={css.noShowings}>No Showings found.</div>
        )}
      </div>
      {cancellable && (
        <Footer status={status} id={id} handleCancel={handleCancel} />
      )}
    </div>
  );
};

Route.prototypes = {
  fetchingRoute: PropTypes.bool,
  fetchingFastest: PropTypes.bool
};

export default Route;
