import React from "react";
import {
  BigRadio,
  Row,
  Label,
  Controls
} from "~brokerage/components/shared/Form";
import { REQUIRE_APPROVAL, INSTANT_APPROVED } from "./constants";
import css from "./ShowingInstructions.sass";

const RequestHandling = ({ requestHandling, handleChange }) => {
  return (
    <Row class={css.handleRequests}>
      <div className={css.label}>
        <Label valign="bigradio">How are requests handled?</Label>
      </div>
      <Controls>
        <div style={{ overflow: "hidden" }}>
          <BigRadio
            icon="approveInstant"
            heading="Instant Approval"
            body="Showing instructions are instantly sent after each request"
            name="requestHandling"
            value={INSTANT_APPROVED}
            checked={requestHandling === INSTANT_APPROVED}
            onChange={handleChange}
          />
          <BigRadio
            icon="approvalRequest"
            heading="Require Approval"
            body="Requests must be approved by the listing agent, assistant, or seller"
            name="requestHandling"
            value={REQUIRE_APPROVAL}
            checked={requestHandling === REQUIRE_APPROVAL}
            onChange={handleChange}
          />
        </div>
      </Controls>
    </Row>
  );
};

export default RequestHandling;
