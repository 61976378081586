export default {
  pixelsPerMinute: 1,
  pixelsPerHour: 60,
  durationStep: 15,
  minDuration: 15,
  maxDuration: 120,
  timelineCols: 3,
  defaultTime: '9:00 AM',
  daysFull: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  defaultTimeRangeStartHour: 9,
  defaultTimeRangeEndHour: 10,
  timeListMinuteOffset: 30,
  ios_store_link: 'https://itunes.apple.com/us/app/hello-show/id938410567',
  android_store_link: 'https://play.google.com/store/apps/details?id=com.helloshow.helloshow',
  university_link: 'https://university.agentinbox.com'
}
