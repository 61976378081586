import css from './LandingFooter.sass'
import React, { Component } from 'react'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import SmartModalToggle from '~brokerage/components/modals/SmartModalToggle'
import ModalDownloadApp from '~brokerage/components/modals/ModalDownloadApp'

export default class LandingFooter extends React.PureComponent {
  state = {
    transparent: false,
    overlayHidden: false
  }

  componentDidMount() {
    setTimeout(() => {
      const $overlay = $(`.${css.overlay}`)
      $overlay.on('webkitTransitionEnd transitionend', () => {
        $overlay.off('webkitTransitionEnd transitionend')
        this.setState({
          overlayHidden: true
        })
      })
      this.setState({
        transparent: true
      })
    }, 2000)
  }

  handleCloseClick = () => {
    window.close()
  }

  render() {
    const { transparent, overlayHidden } = this.state

    return (
      <div className={composeClassName(css, null, { transparent })}>
        {!overlayHidden && <div className={css.overlay}/>}
        <div className={css.footer}>
          <div className={css.text}>
            <p className={css.p}>Welcome to Agent Inbox!</p>
            <p className={css.p}>Track your showings inside of the Agent Inbox platform!</p>
          </div>

          {Boolean(window.opener) &&
            <div className={css.btnBack}>
              <Button variant="primary" onClick={this.handleCloseClick}>
                <div className={css.iconInBtn}>
                  <Icon name="chevronLeft"/>
                </div>
                Return to MLS Search
              </Button>
            </div>
          }

          <div className={css.btnDownload}>
            <SmartModalToggle variant="outline" name="download_app" modal={<ModalDownloadApp/>}>
              Download App
            </SmartModalToggle>
          </div>
        </div>
      </div>
    )
  }
}
