import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";

const Pending = ({ message, bubbleOptions }) => (
  <Bubble variant="blue" {...bubbleOptions}>
    <p className={css.p}>
      {message.buyerAgentName} has requested to see the property on{" "}
      <b className={css.nobr}>{message.requestDate}</b> from{" "}
      <b className={css.nobr}>
        {message.requestStartTime} to {message.requestEndTime}
      </b>
      .
    </p>
  </Bubble>
);

Pending.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Pending;
