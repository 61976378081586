import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppointmentStatusModal from "~brokerage/app/components/shared/AppointmentStatusModal";
import AppointmentEditTimeModal from "~brokerage/app/components/shared/AppointmentEditTimeModal";
import ShowingActions from "~brokerage/app/components/shared/ShowingActions";
import StatusPill from "~brokerage/app/components/shared/StatusPill.jsx";
import {
  fetchShowingMessages,
  fetchShowing
} from "~brokerage/actions/showings";
import css from "./StatusInlineList.sass";

const StatusInlineList = ({
  status,
  // disabled,
  showingId,
  isLA,
  isBA,
  isPassed,
  dispatch,
  hasRoute,
  refreshCallback
}) => {
  const [subModalId, setSubModalId] = useState(null);
  const [newStatus, setNewStatus] = useState("approved");
  const [disableModal, setDisableModal] = useState(false);
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);
  const [isListingAgentPreapproving, setIsListingAgentPreapproving] =
    useState(false);

  const openStatusModal = newStatus => {
    if (!disableModal) {
      setNewStatus(newStatus);
      setSubModalId(showingId);
    }
  };

  const onStatusModalClose = refresh => {
    setSubModalId(null);
    if (refresh) {
      dispatch(fetchShowingMessages(showingId));
      dispatch(fetchShowing(showingId));
    }
  };

  return (
    <>
      <div className={css.pillHolder}>
        <StatusPill
          showingStatus={status}
          futureShowing={!isPassed}
          style={{ float: "left" }}
        />
      </div>
      <div className={css.showingActionsHolder}>
        <ShowingActions
          showingStatus={status}
          openStatusModal={openStatusModal}
          isShowingTeam={isBA}
          isListingTeam={isLA}
          futureShowing={!isPassed}
          setIsListingAgentPreapproving={setIsListingAgentPreapproving}
          openDateTimeModal={() => setIsDateTimeModalOpen(true)}
        />
      </div>
      <AppointmentStatusModal
        showingId={subModalId}
        setShowingId={setSubModalId}
        newStatus={newStatus}
        closeModal={onStatusModalClose}
        disableModal={disableModal}
        setDisableModal={setDisableModal}
      />
      {isDateTimeModalOpen && (
        <AppointmentEditTimeModal
          showingId={showingId}
          closeModal={() => setIsDateTimeModalOpen(false)}
          page={hasRoute ? "time" : "date"}
          canEditDate={!hasRoute}
          refreshCallback={refreshCallback}
          rescheduleAndApproveShowing={isListingAgentPreapproving}
        />
      )}
    </>
  );
};

StatusInlineList.propTypes = {
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isChanging: PropTypes.bool,
  showingId: PropTypes.string
};

export default connect()(StatusInlineList);
