import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Recipient from './Recipient'

export default class Recipients extends React.PureComponent {
  static propTypes = {
    recipients: PropTypes.array
  }

  render() {
    const { recipients } = this.props

    return (
      <span>
        {recipients.map(recipient => {
          return (
            <span key={recipient.userId} className={css.recipientContainer}>
              <Recipient recipient={recipient} />
            </span>
          )
        })}
      </span>
    )
  }
}
