import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchShowingRoute } from "~brokerage/actions/routes";
import Header from "./Header";
import Map from "./Map";
import RouteInfo from "./RouteInfo";
import Route from "./Route";
import css from "./index.sass";

import {
  STATUS_CANCELLED,
  STATUS_NOT_SENT
} from "~brokerage/constants/showings/statuses";
import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";
import { useHistory } from "react-router";
import NavigationPrompt from "~brokerage/components/shared/NavigationPrompt";
import { usePrompt } from "~brokerage/app/helpers/usePrompt";
import fetchRoutingInfo from "./util/fetchRoutingInfo";
import {
  UNSAVED_CHANGES_WARNING,
  UNSAVED_CHANGES_REMINDER
} from "~brokerage/constants/routes/userMessages";
import moment from "moment";
import { showingsToDisplay } from "./util/helper";
import ShareRoute from "./ShareRoute";

const ScheduleRoute = ({
  isFetching,
  route,
  location,
  dispatch,
  match,
  mapboxkey
}) => {
  const [hasInvalidGeo, setHasInvalidGeo] = useState(false);
  const [showings, setShowings] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(UNSAVED_CHANGES_WARNING);
  const [justCancelled, setJustCancelled] = useState(false);
  const navUrl = useRef(null);
  const promptRef = useRef(null);
  const history = useHistory();
  const { unsavedChanges, optimized } = route;
  const displayShowings = useMemo(
    () => showingsToDisplay(route),
    [route.showings, route.stops]
  );

  useEffect(() => {
    if (isFetching) {
      setShowDialog(false);
    } else {
      if (unsavedChanges) {
        mergeChanges();
        if (route.showings.some(s => s.status.toLowerCase() !== "not_sent")) {
          setDialogMessage(UNSAVED_CHANGES_REMINDER);
          setShowDialog(true);
        }
      } else {
        setShowings(displayShowings);
      }
    }
  }, [unsavedChanges, route.showings, route.stops, isFetching]);

  useEffect(() => {
    dispatch(fetchShowingRoute(match.params.id));
  }, [match.params.id]);

  useEffect(() => {
    const _hasInvalidGeo = checkForInvalidGeo(showings);
    const optimized =
      route.optimized && route.optimized === showings[0]?.unique_id;
    if (!_hasInvalidGeo && showings.length > 1 && !optimized) {
      fetchRoutingInfo(route.date, showings, dispatch, mapboxkey);
    }
    setHasInvalidGeo(_hasInvalidGeo);
  }, [showings]);

  const mergeChanges = () => {
    const update = displayShowings.map(showing => {
      const changes = unsavedChanges.find(
        s => s.id === (showing.type === "stop" ? showing.id : showing.unique_id)
      );
      if (changes && changes.requestedTimeRaw && showing.start_time) {
        const hasChanged =
          changes.type !== "stop" &&
          (!timesEqual(changes.requestedTimeRaw, showing.start_time) ||
            changes.duration !== showing.duration);
        return {
          ...showing,
          start_time: changes.requestedTimeRaw,
          travel_time: changes.travelTime,
          duration: changes.duration,
          position: changes.position,
          conflict: changes.conflict,
          status: hasChanged ? "Not_sent" : showing.status
        };
      } else {
        return showing;
      }
    });
    const addedStops = unsavedChanges
      .filter(s => s.type === "stop" && s.id < 0)
      .map(stop => ({
        id: stop.id,
        unique_id: `stop-${stop.id}`,
        start_time: stop.requestedTimeRaw,
        travel_time: stop.travelTime,
        duration: stop.duration,
        position: stop.position,
        status: "stop",
        type: "stop",
        coordinates: stop.coordinates,
        address: stop.address
      }));
    update.push(...addedStops);
    update.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    setShowings(update);
  };

  const timesEqual = (first, second) => {
    if (!first || !second) return false;
    const diffInMinutes = moment(first).diff(moment(second), "minutes");
    return Math.abs(diffInMinutes) < 1;
  };
  const checkForInvalidGeo = showings =>
    showings
      .filter(s => s.status !== STATUS_CANCELLED)
      .some(s => !validateLatLng(s.coordinates?.lat, s.coordinates?.long));

  function onCancel() {
    setShowDialog(false);
  }

  const handleCancel = () => {
    setJustCancelled(true);
  };

  useEffect(() => {
    if (justCancelled) {
      history.push("/my_showings");
    }
  }, [justCancelled]);

  function handleConfirm() {
    promptRef.current();
    history.push(navUrl.current);
  }

  const handleNavigation = useCallback(
    nav => {
      if (nav.pathname.includes("add-appointments") && !unsavedChanges) {
        navUrl.current = nav.pathname;
        handleConfirm();
      } else {
        setShowDialog(true);
        setDialogMessage(UNSAVED_CHANGES_WARNING);
        navUrl.current = nav.pathname;
      }
    },
    [unsavedChanges]
  );

  const showingIsUnsaved = useCallback(() => {
    if (justCancelled) {
      return false;
    }
    if (route.status === STATUS_CANCELLED) {
      return false;
    }
    if (route.unsavedChanges) {
      return true;
    }
    if (
      route.showings &&
      route.showings.some(s => s.status.toLowerCase() === STATUS_NOT_SENT)
    ) {
      return true;
    }
    return false;
  }, [route, justCancelled]);

  usePrompt({
    ref: promptRef,
    when: showingIsUnsaved(),
    then: handleNavigation,
    message: dialogMessage,
    exceptions: ["/my_showings/:id/schedule"]
  });

  const promptButtons =
    dialogMessage.buttons === "leaveStay"
      ? [
          {
            label: "Stay",
            variant: "outline",
            onClick: onCancel
          },
          {
            label: "Leave",
            variant: "red",
            onClick: handleConfirm
          }
        ]
      : [
          {
            label: "OK",
            variant: "outline",
            onClick: onCancel
          }
        ];

  return (
    <>
      <NavigationPrompt
        open={showDialog}
        message={dialogMessage}
        buttons={promptButtons}
      />
      <Header location={location} route={route} timesEqual={timesEqual} />
      <div className={css.root}>
        <div className={css.left} id="print-target">
          <ShareRoute route={route} showings={displayShowings} />
          <RouteInfo
            isFetching={isFetching}
            route={route}
            showings={showings}
          />
          <Map
            mapboxkey={mapboxkey}
            showings={showings}
            map_geometry={route.map_geometry}
          />
        </div>
        <Route
          id={route.id}
          date={route.date}
          status={route.status}
          isFetching={isFetching}
          showings={showings}
          originalShowings={route.showings || []}
          optimized={optimized}
          hasInvalidGeo={hasInvalidGeo}
          unsavedChanges={unsavedChanges}
          handleCancel={handleCancel}
        />
      </div>
    </>
  );
};

ScheduleRoute.propTypes = {
  isFetching: PropTypes.bool,
  route: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  location: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

function mapStateToProps({ routes, keys: { mapboxkey } }, ownProps) {
  const { entity, isFetching } = routes.single.route;
  const { params } = ownProps.match;
  const route = entity?.id === parseInt(params.id) && entity;

  return {
    route,
    isFetching,
    mapboxkey
  };
}

export default connect(mapStateToProps)(ScheduleRoute);
