import React, {  useState } from "react";
import { connect } from "react-redux";
import { updateTeamsInstructions } from "~brokerage/actions/settings";
import {
  Textarea,
  Row,
  Label,
  Controls
} from "~brokerage/components/shared/Form";
import People from "./People";
import css from "./index.sass";

const OfficeInvolvement = props => {
  const { people, teamInstructions } = props;
  const [hasChanged, setHasChanged] = useState(false);

  function updateHasChanged() {
    if (!hasChanged) {
      setHasChanged(true);
    }
  }

  function handleTextAreaChange(instructions) {
    props.dispatch(updateTeamsInstructions(instructions));
  }

  return (
    <>
      <div className={css.divider}></div>
      <h4 className={css.subTitle}>People involved in the listings</h4>
      <div className={css.divider}></div>
      <Row>
        <People
          name="officeInvolvement"
          people={people}
          onChange={updateHasChanged()}
        />
      </Row>
      <Row className={css.teamInstructions}>
        <Label>
          <div className={css.divider}></div>
          <h4 className={css.teamInstructionsSubTitle}>Team Instructions</h4>
          <small>
            Instructions for your team members to schedule a showing or know
            more about the listing. Only your team members will see these
            instructions
          </small>
        </Label>
        <Controls>
          <Textarea
            name="teamInstructions"
            defaultValue={teamInstructions}
            placeholder="Enter Team Instructions"
            onChange={e => handleTextAreaChange(e.target.value)}
          />
        </Controls>
      </Row>
    </>
  );
};

export default connect()(OfficeInvolvement);
