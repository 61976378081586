export const ADVANCE_NOTICE_REQUIREMENTS_TOOLTIP =
  "This setting allows you to (1) prevent same day showing requests, or (2) require a minimum amount of advanced notice or lead time before a showing can be requested. For example, if advanced notice is set to 6 hours and it is 10:00 AM, the \earliest available time slot to request a showing would be 4:00 PM";

export const REQUIRE_APPROVAL = "require_approval";
export const INSTANT_APPROVED = "instant_approved";
export const INSTANT_PENDING = "instant_pending";

export const SLIDER_MIN_RANGE = 0;
export const SLIDER_MAX_RANGE = 144;
export const SLIDER_HANDLE_STYLE = {
  borderColor: "#007bff",
  backgroundColor: "#fff",
  zIndex: 1
};
export const SLIDER_RAIL_STYLE = { backgroundColor: "#ccc" };
export const SLIDER_COLOR_BLUE = "#007bff";
export const SLIDER_COLOR_BLACK = "#000";
