import {
  CUSTOMER_CATEGORIES_REQUEST,
  CUSTOMER_CATEGORIES_SUCCESS,
  CUSTOMER_CATEGORIES_FAILURE
} from "~brokerage/constants/actionTypes";

const initialState = {
  entities: [],
  isFetching: false
};

export default function categories(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_CATEGORIES_REQUEST:
      return { ...state, isFetching: true };
    case CUSTOMER_CATEGORIES_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.categories };
    case CUSTOMER_CATEGORIES_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    default:
      return state;
  }
}
