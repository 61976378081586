export function closestTag(_el, _tagName) {
  // IE9 crashes when using jQuery.closest or jQuery.parents in an iframe (╯°□°）╯︵ ┻━┻

  let el = _el
  const tagName = _tagName.toLowerCase()

  while (el && el.tagName) {
    if (el.tagName.toLowerCase() === tagName) {
      return el
    }
    el = el.parentNode
  }
}
