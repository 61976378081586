import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomDatePicker from "./CustomDatePicker";
import Icon from "~brokerage/components/shared/Icon";

const Toolbar = ({
  localizer,
  label,
  date,
  length,
  isCustomView,
  onNavigate,
  onViewClick,
  views,
  view,
  onCustomDatesApplied
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { messages } = localizer;

  const handleCustomClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const renderViewNamesGroup = messages => {
    if (views.length > 1) {
      return views.map(name => {
        const isActive = !isCustomView && view === name;
        return (
          <button
            type="button"
            key={name}
            className={isActive ? "rbc-active" : ""}
            onClick={() => onViewClick(name)}
          >
            {messages[name]}
          </button>
        );
      });
    }
  };

  const onCustomApplied = (...args) => {
    setShowDatePicker(false);
    onCustomDatesApplied(...args);
  };

  return (
    <div className="rbc-toolbar">
      {!isCustomView && (
        <span className="rbc-btn-group">
          <button type="button" onClick={() => onNavigate("TODAY")}>
            {messages.today}
          </button>
          <button type="button" onClick={() => onNavigate("PREV")}>
            {messages.previous}
          </button>
          <button type="button" onClick={() => onNavigate("NEXT")}>
            {messages.next}
          </button>
        </span>
      )}

      <span className="rbc-toolbar-label">{label}</span>

      <span className="rbc-btn-group">
        {renderViewNamesGroup(messages)}
        <div>
          <button
            type="button"
            key="custom"
            className={isCustomView ? "rbc-active" : ""}
            onClick={handleCustomClick}
          >
            Custom <Icon name="dropdown" />
          </button>
          {showDatePicker && (
            <CustomDatePicker
              date={date}
              length={length}
              onApply={onCustomApplied}
            />
          )}
        </div>
      </span>
    </div>
  );
};

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  isCustomView: PropTypes.bool.isRequired
};

export default Toolbar;
