import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Tfoot extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { children, variant, ...rest } = this.props

    return (
      <tfoot {...rest}>
        {React.Children.map(children, child =>
          React.cloneElement(child, { variant: child.props.variant || variant })
        )}
      </tfoot>
    )
  }
}
