import qhistory from "qhistory"
import { stringify, parse } from 'qs'
import { createHashHistory, createBrowserHistory } from 'history'

export const hashHistory = qhistory(
  createHashHistory(),
  stringify,
  parse,
)

export const browserHistory = qhistory(
  createBrowserHistory(),
  stringify,
  parse,
)
