import { Chart } from "react-google-charts";
import React, { useEffect, useState } from "react";
import moment from "moment";
import css from "./ShowingsChart.sass";

const ShowingsChart = ({ showings = [], dateString }) => {
  const [data, setData] = useState(["Date", "Showings"]);

  useEffect(() => {
    if (showings && showings.length) {
      const firstDate = showings[0].requested_time;
      const lastDate = showings[showings.length - 1].requested_time;
      const dateArray = genDateArray(firstDate, lastDate);
      const update = dateArray.map(date => {
        const numberOfShowings = showings.filter(s =>
          datesAreOnSameDay(new Date(s.requested_time), new Date(date))
        ).length;
        return [moment(date).format("M/DD/YY"), numberOfShowings || 0];
      });
      setData([["Date", "Showings"], ...update]);
    }
  }, [showings]);

  const genDateArray = (start, end) => {
    const _start = new Date(new Date(start).setHours(0, 0, 0, 0));
    const _end = new Date(new Date(end).setHours(23, 59, 59, 59));
    const arr = [];
    while (_start <= _end) {
      arr.push(new Date(_start));
      _start.setDate(_start.getDate() + 1);
    }
    return arr;
  };

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const options = {
    width: "100%",
    curveType: "function",
    legend: "none",
    backgroundColor: "none",
    hAxis: {
      title: "Showing Date",
      slantedText: true,
      slantedTextAngle: 45
    },
    vAxis: {
      title: "Number of Approved Showings",
      viewWindow: { min: -2, max: 15 }
    }
  };

  if (!showings.length) {
    return <></>;
  }
  return (
    <>
      <h3 className={css.title}>
        Showings by Date: <small>{dateString}</small>
      </h3>
      <div className={css.chart}>
        <Chart
          chartType="LineChart"
          height="400px"
          data={data}
          options={options}
        />
      </div>
      <div className={css.chartPrint}>
        <Chart
          chartType="LineChart"
          height="400px"
          data={data}
          options={{ ...options, width: "720px" }}
        />
      </div>
    </>
  );
};

export default ShowingsChart;
