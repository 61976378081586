import React from "react";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil.jsx";
import css from "./ListingPicture.sass";

const ListingPicture = ({
  listingPhoto: { listing_status: listingStatus, photo_url: photoUrl, contingency }
}) => (
  <div className={css.Picture}>
    <div className={css.listingStatus}>{`${listingStatus} ${contingency ?? ''}`}</div>
    <PropertyThumbnail
      image={photoUrl}
      className={css.pictureContent}
      errorClassName={`${css.pictureContent} ${css.photoError}`}
    />
  </div>
);

export default ListingPicture;
