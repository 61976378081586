import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Group,
  Checkbox,
  Row,
  Controls
} from "~brokerage/components/shared/Form";
import {
  changeSellerTenantNotifyEmail,
  changeSellerTenantNotifyPhone
} from "~brokerage/actions/listings";
import css from "./Person.sass";

class PersonPreferredCommunication extends Component {
  static propTypes = {
    person: PropTypes.shape({
      primaryPhone: PropTypes.string,
      email: PropTypes.string,
      preferredCommunication: PropTypes.string
    }),
    dispatch: PropTypes.func,
    onChange: PropTypes.func
  };

  handleChangeNotifyEmail = value => {
    this.props.dispatch(
      changeSellerTenantNotifyEmail(this.props.person, value)
    );
    this.props.onChange();
  };

  handleChangeNotifyPhone = value => {
    this.props.dispatch(
      changeSellerTenantNotifyPhone(this.props.person, value)
    );
    this.props.onChange();
  };

  render() {
    const { notifyEmail, notifyPhone } = this.props.person;

    return (
      <Row offset="10">
        <span className={css.communicationInvolvementLabelText}>
          Alert Preferences
        </span>
        <div className={css.preferredCommunicationContainer}>
          <Controls>
            <Group modifier="leftFloatedChildren">
              <Checkbox
                name="notifyEmail"
                checked={notifyEmail}
                onChange={() => {
                  this.handleChangeNotifyEmail(!notifyEmail);
                }}
              >
                Email
              </Checkbox>
              <Checkbox
                name="notifyPhone"
                checked={notifyPhone}
                onChange={() => {
                  this.handleChangeNotifyPhone(!notifyPhone);
                }}
              >
                Text Messages
              </Checkbox>
            </Group>
          </Controls>
        </div>
      </Row>
    );
  }
}
export default connect()(PersonPreferredCommunication);
