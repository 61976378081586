import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchContactDetails } from "~brokerage/actions/people";
import Loader from "~brokerage/components/shared/Loader";
import NoMatch from "~brokerage/components/shared/NoMatch";
import ShortInfoCustomer from "~brokerage/components/people/myContacts/ShortInfoCustomer";
import CustomerDetails from "~brokerage/components/people/myContacts/CustomerDetails";
import CustomerShowings from "~brokerage/components/people/myContacts/CustomerShowings";

class ShowContact extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    person: PropTypes.object,
    isFetching: PropTypes.bool,
    errors: PropTypes.bool,
    match: PropTypes.object,
    dispatch: PropTypes.func
  };

  static defaultProps = {
    isFetching: true
  };

  componentWillMount() {
    this.props.dispatch(fetchContactDetails(this.props.match.params.personId));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.personId !== nextProps.match.params.personId) {
      this.props.dispatch(fetchContactDetails(nextProps.match.params.personId));
    }
  }

  render() {
    const { person, isFetching, errors } = this.props;

    if (isFetching) return <Loader active />;
    if (errors) return <NoMatch notify={false} />;

    return (
      <div>
        <ShortInfoCustomer person={person} />
        <CustomerDetails person={person} />
        <CustomerShowings person={person} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entity: person, isFetching, errors } = state.people.single.person;

  return {
    person,
    isFetching,
    errors
  };
}
export default connect(mapStateToProps)(ShowContact);
