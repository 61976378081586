import {
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS
} from "~brokerage/constants/actionTypes";

const initialState = {
  entities: [],
  page: 1,
  isFetching: false
};

export default function notificationsList(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_NOTIFICATIONS_SUCCESS: {
      const isFirstPage = action.data.meta.current_page === 1;
      const newEntities = isFirstPage
        ? action.data.data.notifications
        : [...state.entities, ...action.data.data.notifications];

      return {
        ...state,
        entities: newEntities,
        page: action.data.meta.current_page,
        totalPages: action.data.meta.total_pages,
        isFetching: false
      };
    }
    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, errors: action.errors, isFetching: false };
    default:
      return state;
  }
}
