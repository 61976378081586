import { CALL_API } from '~brokerage/middlewares/api'

export function fetchAgents(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `agents`,
      params
    }
  }
}
