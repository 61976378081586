import css from "./GuestNavigation.sass";
import React from "react";

import Badge from "~brokerage/components/shared/Badge";
import MainNavigationLink from "~brokerage/components/shared/MainNavigationLink";
import { navigationIcon } from "~brokerage/components/shared/MainNavigationIcons";

const GuestNavigation = props => {
  const RenderCounter = ({ counter }) => (
    <>
      {counter > 0 && (
        <div className={css.unreadCounter}>
          <Badge variant="orange">{counter > 99 ? "99+" : counter}</Badge>
        </div>
      )}
    </>
  );

  return (
    <>
      <MainNavigationLink
        to="/search"
        icon={navigationIcon("newShowing")}
      >
        Add a Showing
      </MainNavigationLink>
      <MainNavigationLink to="/messages" icon={navigationIcon("messages")}>
        Messages
      <RenderCounter counter={props.unreadMessages} />
      </MainNavigationLink>
      <a href="/users/sign_out" className={css.logoutBtn}>
        Logout
      </a>
    </>
  );
};

export default GuestNavigation;
