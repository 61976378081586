import React from "react";
import {
  Row,
  Label,
  Controls,
  Group,
  Checkbox
} from "~brokerage/components/shared/Form";
import css from "./ShowingInstructions.sass";

const ShowingArrangement = ({
  accompanyAgent,
  accompanySeller,
  handleChange
}) => (
  <Row>
    <Label>Showing arrangement</Label>
    <div className={css.checkBox}>
      <Controls>
        <Group modifier="leftFloatedChildren">
          <div className={css.accompanyAgent}>
            <Checkbox
              name="accompanyAgent"
              checked={accompanyAgent}
              onChange={handleChange}
            >
              Agent will accompany
            </Checkbox>
          </div>
          <Checkbox
            name="accompanySeller"
            checked={accompanySeller}
            onChange={handleChange}
          >
            Seller will accompany
          </Checkbox>
        </Group>
      </Controls>
    </div>
  </Row>
);

export default ShowingArrangement;
