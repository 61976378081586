import React from "react";
import Tooltip from "~brokerage/components/shared/Tooltip";
import Badge from "~brokerage/components/shared/Badge";

import css from "./index.sass";

const RestrictedBadge = ({ tooltipText, badgeVariant, badgeLabel }) => (
  <div className={css.restrictedBadge}>
    <Tooltip text={tooltipText} style={{ display: "inline-block" }}>
      <Badge variant={badgeVariant}>{badgeLabel}</Badge>
    </Tooltip>
  </div>
);

export default RestrictedBadge;
