import css from "./ModalCreateCustomer.sass";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Dropdown,
  TextField,
  Textarea,
  PhoneNumber
} from "~brokerage/components/shared/Form";
import { fetchPeopleCounts } from "~brokerage/actions/people";
import { toast } from "react-toastify";

import { fetchCustomerCategories } from "~brokerage/actions/people";

import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { callApi } from "brokerage/middlewares/api";
import { useHistory } from "react-router-dom";

const ModalCreateCustomer = props => {
  const { categories, isLoadingCategories, dispatch } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(fetchCustomerCategories());
  }, []);

  const handleSubmit = (e, values) => {
    callApi(
      "my_contacts",
      {},
      { ...values, category: category.value },
      "post"
    ).then(
      response => {
        setLoading(false);
        const contactId = response.data?.person?.id;
        if (contactId)
          history.push({
            pathname: `/people/contact/${contactId}`,
            query: { ...props.location.query, modal: void 0 }
          });
        dispatch(fetchPeopleCounts({}));
      },
      err => {
        setLoading(false);
        toast.error(
          err.response?.data?.error ||
            "Unknown error while creating customer. Please try again!"
        );
      }
    );
  };

  const closeModalWindow = () => {
    history.push({
      pathname: props.location.pathname,
      query: { ...props.location.query, modal: void 0 }
    });
  };

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title="Create customer" />
        <Body>
          <Row>
            <Col variant="liquid" modifier="half">
              <TextField
                name="first_name"
                block
                placeholder="First Name"
                required
              />
            </Col>
            <Col variant="liquid" modifier="half">
              <TextField
                name="last_name"
                block
                placeholder="Last Name"
                required
              />
            </Col>
          </Row>
          <Row>
            <TextField
              name="email"
              block
              type="email"
              placeholder="Email"
              required
            />
          </Row>
          <Row>
            <Col variant="liquid" modifier="half">
              <PhoneNumber name="phone" block placeholder="Phone" required />
            </Col>
            <Col variant="liquid" modifier="half">
              <Dropdown
                title={category === "" ? "Select category" : category.label}
                options={categories}
                onChange={setCategory}
                variant="outline2Block"
                selected={category && category.value}
              />
            </Col>
          </Row>
          <Row>
            <Textarea name="notes" placeholder="Enter notes for this contact" />
          </Row>
        </Body>
        <Footer>
          <Button
            type="submit"
            float="right"
            variant="primary"
            disabled={loading || !category}
          >
            Create
          </Button>
          <Button
            type="button"
            variant="newMessageCancel"
            onClick={closeModalWindow}
          >
            Cancel
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    entities: categories,
    isFetching: isLoadingCategories,
    errors: categoryErrors
  } = state.people.customer.categories;

  return {
    categories,
    isLoadingCategories,
    categoryErrors
  };
}

export default connect(mapStateToProps)(ModalCreateCustomer);
