import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

class OldInterfaceEditRequestedTime extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  handleClick = (event) => {
    const link = closestTag(event.target, 'a')

    if (link && link.className.includes('btn-back')) {
      this.goBack()
      return true
    }
    return false
  }

  goBack = () => {
    const url = this.backUrl()
    if (url) {
      this.props.history.push(url)
    } else {
      this.props.history.goBack()
    }
  }

  alterIframeDocument = (iframeDocument) => {
    const backLink = iframeDocument.querySelector('header div.media.header-inner div.pull-left a')

    if (backLink) {
      backLink.className += ' btn-back'
      const textNode = backLink.childNodes[0]
      textNode.nodeValue = 'Back'
    }
  }

  redirectToShowingPage = (iframeLocation) => {
    if (iframeLocation.search.includes('iframe_redirect_to_showing=1')) {
      this.goBack()
      return true
    } else {
      return false
    }
  }

  backUrl() {
    return this.props.location.query.backToPath
  }

  render() {
    const path = `/showings/${this.props.match.params.showingId}/edit_requested_time`
    const params = 'opened_from_iframe=1'
    const showingEditRequestedTimeUrl = `${path}?${params}`

    return (
      <OldInterfaceIframe
        url={showingEditRequestedTimeUrl}
        onClick={this.handleClick}
        alterIframeDocument={this.alterIframeDocument}
        redirectToOtherPage={this.redirectToShowingPage}
        location={this.props.location}
      />
    )
  }
}
export default connect()(OldInterfaceEditRequestedTime)
