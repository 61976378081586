import React from "react";
import PropTypes from "prop-types";
import config from "~brokerage/config/config";
import { Checkbox } from "~brokerage/components/shared/Form";
import { Table, Tbody, Tr, Td } from "~brokerage/components/shared/Table";

const Days = props => {
  const {
    days,
    onDayToggle
  } = props;

  const handleTrClick = (day, addOrRemove, event) => {
    const tagname = event.target.tagName.toLowerCase();
    if (tagname !== 'label' && tagname !== 'i') {
      onDayToggle(day, addOrRemove);
    }
  };

  return (
    <div>
      <Table variant="striped">
        <Tbody>
          {config.daysFull.map((day, dayIndex) => {
            const isChecked = days.indexOf(day) !== -1;

            return (
              <Tr key={dayIndex} onClick={(e) => handleTrClick(day, !isChecked, e)}>
                <Td width="1%">
                  <Checkbox
                    checked={isChecked}
                    onChange={() => { return false }}
                  />
                </Td>
                <Td>
                  {day}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </div>
  )
};

Days.propTypes = {
  days: PropTypes.array,
  onDayToggle: PropTypes.func
};

export default Days;
