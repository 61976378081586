import css from "./ModalEditRoute.sass";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  TextField,
  Label,
  CalendarDropdown,
  Timepicker
} from "~brokerage/components/shared/Form";
import { datetimeToObject } from "~brokerage/libs/helpers/TimeHelper";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import moment from "moment";
import { callApi } from "brokerage/middlewares/api";
import { useHistory, useLocation } from "react-router-dom";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { toast } from "react-toastify";
import { fetchShowingRoute } from "~brokerage/actions/routes";

const ModalEditRoute = props => {
  const { route } = props;
  if (!route) return <></>;

  const [time, setTime] = useState(inUsersTimezone(route.date));
  const [name, setName] = useState(route.name);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = () => {
    const reqData = {
      name: name,
      start_at: time,
      reschedule: !time.isSame(moment(route.date))
    };
    setLoading(true);
    callApi(`routes/${route.id}`, {}, reqData, "patch").then(
      () => {
        props.dispatch(fetchShowingRoute(route.id));
        setLoading(false);
        closeModalWindow();
      },
      error => {
        toast.error(
          error.response?.data?.error ||
            "Something went wrong editing the route. Please try again."
        );
        setLoading(false);
      }
    );
  };

  const handleTimeChange = changedDatetime => {
    const currentTime = time.clone();
    currentTime
      .hours(changedDatetime.hours())
      .minutes(changedDatetime.minutes());
    setTime(currentTime);
  };

  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handleCalendarChange = e => {
    const currentTime = time.clone();
    const currentDate = inUsersTimezone(e);
    currentTime
      .month(currentDate.month())
      .date(currentDate.date())
      .year(currentDate.year());
    setTime(currentTime);
  };

  const closeModalWindow = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: void 0 }
    });
  };

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title="Edit Route" cancelPath={`${location.pathname}`} />
        <Body>
          <Row offset="10">
            <TextField
              value={name}
              name="name"
              block
              placeholder="Route Name"
              onChange={handleNameChange}
              required
              focused
            />
          </Row>
          <Row>
            <Col>
              <Label variant="vertical">Showing Date</Label>
              <CalendarDropdown
                date={time.toDate().toUTCString()}
                opened={false}
                onChange={handleCalendarChange}
              />
            </Col>
            <Col modifier="offset20">
              <Label variant="vertical">Start Time</Label>
              <Timepicker
                {...datetimeToObject(time)}
                onChange={handleTimeChange}
              />
            </Col>
          </Row>
        </Body>
        <Footer>
          <Button
            type="submit"
            float="right"
            variant="primary"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="newMessageCancel"
            onClick={closeModalWindow}
          >
            Cancel
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { entity: route, isFetching } = state.routes.single.route;
  return {
    route,
    isFetching
  };
}

export default connect(mapStateToProps)(ModalEditRoute);
