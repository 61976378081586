/* eslint react/no-danger: 0 */
import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";

const Event = ({ body }) => (
  <div className={css.event}>
    <p
      dangerouslySetInnerHTML={{
        __html: body
      }}
    />
  </div>
);

Event.propTypes = {
  body: PropTypes.string
};

export default Event;
