import setInitialState from "./setInitialState";
import updateShowings from "./updateShowings";
import scrollToId from "./scrollToId";
import determineConflicts from "./determinConflicts";
import getNextDate from "./getNextDate";
import getTravelTimeMatrix from "./getTravelTimeMatrix";
import createShowingRefs from "./createShowingRefs";
import { SHOWING_DURATIONS, EXTENDED_SHOWING_DURATIONS, NON_SHOWING_APPOINTMENT_REASONS } from "~brokerage/constants/showings";

const getUpdatedTimes = ({
  duration,
  restrictions,
  times,
  listingId,
  isListingOwnedByCurrentUserOrOfficeTeam = false
}) => {
  const { unavailability = [], allowOverlap = false } =
    restrictions?.[listingId] ?? {};

  const updatedTimes = [...times].map(s => {
    return s.id === listingId
      ? {
          ...s,
          duration: duration,
          conflicts: determineConflicts({
            unavailability,
            nextDate: s.time,
            duration: duration,
            showingId: listingId,
            allowOverlap,
            isListingOwnedByCurrentUserOrOfficeTeam
          }),
          modified: true
        }
      : s;
  });

  return updatedTimes;
};

const filteredShowingDurations = (restrictions, appointmentReason) => {
  if (NON_SHOWING_APPOINTMENT_REASONS.includes(appointmentReason)) {
    return EXTENDED_SHOWING_DURATIONS;
  } else if (restrictions && restrictions.maxDuration) {
    return SHOWING_DURATIONS.filter(d => d <= restrictions.maxDuration);
  } else {
    return SHOWING_DURATIONS;
  }
};

export {
  setInitialState,
  updateShowings,
  scrollToId,
  determineConflicts,
  getNextDate,
  getTravelTimeMatrix,
  createShowingRefs,
  getUpdatedTimes,
  filteredShowingDurations
};
