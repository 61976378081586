import React from "react";
import css from "./LocalDetails.sass";
import ShowingDetails from "~brokerage/app/components/shared/ShowingScreen/Sidebar/ShowingDetails";
import Link from "~brokerage/components/shared/Link";
import Icon from "~brokerage/components/shared/Icon";
import InternalInstructions from "~brokerage/app/components/shared/ShowingScreen/Sidebar/InternalInstructions";
import classNames from "classnames";
import { isNotEmpty } from "~brokerage/libs/helpers/ObjectHelper";
import { formatDate } from "../utilsDetails.js";
import ShowingContacts from "./ShowingContacs";
import ShowingType from "./ShowingType";
import AppointmentStatus from "./AppointmentStatus";

const LocalDetails = ({
  uniqueId,
  updateDetails,
  localDetails,
  isListingTeam,
  updateLocalDetails,
  isShowingTeam,
  showingStatus,
  futureShowing,
  openDateTimeModal,
  openEditDetailModal,
  openEditTeamInstructionsModal
}) => {
  const {
    requested_time: requestedTime,
    team_message: teamMessage,
    appointment_reason: appointmentReason,
    duration,
    status
  } = localDetails;

  const recordsEditable =
    futureShowing &&
    isListingTeam &&
    (showingStatus === "pending" || showingStatus === "approved");

  const timeEditable =
    futureShowing &&
    isShowingTeam &&
    (showingStatus === "pending" || showingStatus === "approved");

  const instructionsEditable =
    showingStatus === "pending" || showingStatus === "approved";

  const renderDateTime = () => {
    const editLink = (
      <span className={css.btnEdit}>
        <Link onClick={openDateTimeModal}>
          <Icon name="edit" /> Reschedule
        </Link>
      </span>
    );
    const startTime = formatDate(requestedTime);
    return (
      <div className={css.section}>
        <h3 className={css.h3}>
          SHOWING DATE & TIME {timeEditable && editLink}
        </h3>
        <p className={css.text}>{`${startTime} (${duration} min)`}</p>
      </div>
    );
  };

  const renderShowingDetails = () => {
    const editLink = (
      <span className={css.btnEdit}>
        <Link onClick={openEditDetailModal}>
          <Icon name="edit" /> Edit
        </Link>
      </span>
    );
    // Show Showing Details only for approved showings in the future
    return (
      futureShowing &&
      status === "approved" && (
        <div className={css.sectionWithBorder}>
          <h3 className={css.h3}>
            Showing Details {recordsEditable && editLink}
          </h3>
          <ShowingDetails showingDetails={{ ...localDetails }} />
        </div>
      )
    );
  };

  const renderTeamInstructions = () => {
    const editLink = (
      <span className={css.btnEdit}>
        <Link onClick={openEditTeamInstructionsModal}>
          <Icon name="edit" /> Edit
        </Link>
      </span>
    );
    return (
      <div
        className={classNames(css.sectionWithBorder, {
          noprint: !isNotEmpty(teamMessage)
        })}
      >
        <h3 className={css.h3}>
          Team Instructions {instructionsEditable && editLink}
        </h3>
        <InternalInstructions message={teamMessage} expandable />
      </div>
    );
  };

  const renderAppointmentStatusEdit = isListingTeam && !futureShowing;

  return (
    <div className={css.localDetailsContainer}>
      <ShowingType
        appointmentReason={appointmentReason}
        editable={timeEditable}
        showingId={uniqueId}
        updateLocalDetails={updateLocalDetails}
      />
      {renderDateTime()}
      {renderShowingDetails()}
      {renderAppointmentStatusEdit && (
        <AppointmentStatus
          showingStatus={showingStatus}
          updateDetails={updateDetails}
          updateLocalDetails={updateLocalDetails}
          showingId={uniqueId}
        />
      )}
      {renderTeamInstructions()}
      <ShowingContacts showingId={uniqueId} />
    </div>
  );
};

export default LocalDetails;
