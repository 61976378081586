import React from "react";

import { hashHistory } from "~brokerage/app/helpers/history";
import BuyerRoutes from "~brokerage/app/pages/buyer/BuyerRoutes.jsx";

const history = hashHistory;

const BuyerLanding = ({ localDetails }) => (
  <BuyerRoutes history={history} localDetails={localDetails} />
);

export default BuyerLanding;
