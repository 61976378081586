import React from "react";
import css from "./FilterToggle.sass";
import FilterLineIcon from "remixicon-react/FilterLineIcon";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";

const FilterToggle = ({ showPanel, onToggle }) => (
  <div className={css.filterToggle}>
    <button className={css.filterButton} onClick={onToggle}>
      <FilterLineIcon size={20} color="white" />
      {showPanel ? (
        <ArrowLeftSLineIcon size={20} color="white" />
      ) : (
        <ArrowRightSLineIcon size={20} color="white" />
      )}
    </button>
  </div>
);

export default FilterToggle;
