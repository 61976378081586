import css from "./ShowingActions.sass";
import { connect } from "react-redux";
import React, { useState } from "react";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";
import {
  fetchShowingMessages,
  fetchShowing
} from "~brokerage/actions/showings";
import AppointmentStatusModal from "~brokerage/app/components/shared/AppointmentStatusModal/index.jsx";
import AppointmentEditTimeModal from "~brokerage/app/components/shared/AppointmentEditTimeModal";

import ActionsDropdown from "~brokerage/app/components/shared/ActionsDropdown";

const ShowingActions = ({ showingId, dispatch }) => {
  const [showApprovedModal, setShowApprovedModal] = useState(false);
  const [showDeclinedModal, setShowDeclinedModal] = useState(false);
  const [isListingAgentPreapproving, setIsListingAgentPreapproving] =
    useState(false);
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);

  const closeModal = refresh => {
    showDeclinedModal && setShowDeclinedModal(false);
    showApprovedModal && setShowApprovedModal(false);
    if (refresh === true) {
      dispatch(fetchShowingMessages(showingId));
      dispatch(fetchShowing(showingId));
    }
  };

  const onListingAgentPreapproving = () => {
    setIsListingAgentPreapproving(true);
    setIsDateTimeModalOpen(true);
  };

  const onAppointmentEditTimeModalClose = () => {
    setIsDateTimeModalOpen(false);
    setIsListingAgentPreapproving(false);
  };

  return (
    <div className={css.base}>
      <div className={css.cell}>
        <Button
          name="approve_showing"
          block
          variant="approveShowingAction"
          onClick={() => setShowApprovedModal(true)}
        >
          <span className={css.icon}>
            <Icon name="check" />
          </span>
          Approve
          <small>with instructions</small>
        </Button>
      </div>
      <div className={css.cell}>
        <Button
          name="decline_showing"
          block
          variant="declineShowingAction"
          onClick={() => setShowDeclinedModal(true)}
        >
          <span className={css.icon}>
            <Icon name="decline2" />
          </span>
          Decline
          <small>with comments</small>
        </Button>
      </div>
      <div className={css.actionsDropdown}>
        <ActionsDropdown
          showReschedule
          menuButtonClass={css.menuButton}
          onPreapprove={onListingAgentPreapproving}
        />
      </div>

      {showApprovedModal && (
        <AppointmentStatusModal
          showingId={showingId}
          newStatus="approved"
          closeModal={closeModal}
        />
      )}
      {showDeclinedModal && (
        <AppointmentStatusModal
          showingId={showingId}
          newStatus="declined"
          closeModal={closeModal}
        />
      )}
      <div onClick={e => e.stopPropagation()}>
        {isDateTimeModalOpen && (
          <AppointmentEditTimeModal
            showingId={showingId}
            closeModal={onAppointmentEditTimeModalClose}
            refreshCallback={() => closeModal(true)}
            page={"date"}
            canEditDate={true}
            rescheduleAndApproveShowing={isListingAgentPreapproving}
          />
        )}
      </div>
    </div>
  );
};

export default connect()(ShowingActions);
