import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  fetchConversationMessages,
  addMessage,
  addNoteMessage,
  scrollChatThread,
  markConversationAsRead
} from '~brokerage/actions/messages'
import Chat from '~brokerage/components/shared/Chat'
import Loader from '~brokerage/components/shared/Loader'


export default class ConversationChat extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    role: PropTypes.string,
    currentUserName: PropTypes.string,
    messages: PropTypes.array,
    isFetching: PropTypes.bool,
    isChatScrollFetching: PropTypes.bool,
    hasMoreMessages: PropTypes.bool,
    dispatch: PropTypes.func
  }

  state = {
    isSubmitButtonDisabled: true
  }

  componentWillMount() {
    const { type, id, isFetching } = this.props

    if (!isFetching) {
      this.props.dispatch(fetchConversationMessages(type, id))
    }
  }

  componentDidMount() {
    this.props.dispatch(markConversationAsRead(this.props.id, this.props.type))
  }

  fetchConversationMessages({ id, type }) {
    this.props.dispatch(fetchConversationMessages(type, id))
  }

  componentWillReceiveProps(nextProps) {
    const isConversationChanged = this.props.id !== nextProps.id

    if (isConversationChanged) {
      this.fetchConversationMessages(nextProps)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type || this.props.id !== prevProps.id) {
      this.props.dispatch(markConversationAsRead(this.props.id, this.props.type))
    }
  }

  handleMessageSubmit = (message, attachmentIds) => {
    const { type, id, role } = this.props
    this.props.dispatch(addMessage(type, id, message, attachmentIds, { role }))
    this.setState({ isSubmitButtonDisabled: true })
  }

  handleNoteMessageSubmit = (message, attachmentIds) => {
    const { type, id, role } = this.props
    this.props.dispatch(addNoteMessage(type, id, message, attachmentIds, { role }))
    this.setState({ isSubmitButtonDisabled: true })
  }

  handleNewMessage = (message, messageType) => {
    this.props.dispatch(addMessage({
      photoUrl: this.context.currentUser.photoUrl,
      senderName: this.props.currentUserName,
      msg: message,
      isFromMe: true,
      createdAt: (new Date()).toISOString(),
      messageType: messageType || 'user'
    }))
  }

  handlePastMessageRequest = () => {
    const { type, id, messages } = this.props
    this.props.dispatch(scrollChatThread(type, id, { last_id: messages[0].id }))
  }

  render() {
    const { id, messages, isFetching, isChatScrollFetching, hasMoreMessages } = this.props

    if (isFetching) {
      return <Loader active/>
    }

    return (
      <Chat
        id={id}
        messages={messages}
        isFetching={isFetching}
        isChatScrollFetching={isChatScrollFetching}
        hasMoreMessages={hasMoreMessages}
        onMessageSubmit={this.handleMessageSubmit}
        onNoteMessageSubmit={this.handleNoteMessageSubmit}
        onNewMessage={this.handleNewMessage}
        onNewNote={this.handleNewNote}
        onPastMessagesRequest={this.handlePastMessageRequest}
      />
    )
  }
}
