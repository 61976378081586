import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Sections extends React.PureComponent {
  static propTypes = {
    step: PropTypes.number,
    children: PropTypes.array
  }

  render() {
    const { children, step } = this.props

    const filteredChildren = children.filter(child => Boolean(child))

    return (
      <div>
        {filteredChildren.map((child, childIndex) => {
          return React.cloneElement(child, {
            steps: filteredChildren.length,
            step: childIndex + 1,
            active: childIndex === step,
            done: childIndex < step,
            key: childIndex
          })
        })}
      </div>
    )
  }
}
