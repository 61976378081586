import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchDefaultRestrictions,
  addDefaultRestriction,
  removeDefaultRestriction,
  updateDefaultRestriction
} from "~brokerage/actions/settings";
import {
  Table,
  Tbody,
  Tfoot,
  Tr,
  Td
} from "~brokerage/components/shared/Table";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Restriction from "./Restriction";
import RestrictionsAddForm from "~brokerage/components/shared/TimeDateRestrictions/RestrictionsAddForm";
import { sortRestrictions } from "~brokerage/libs/helpers/RestrictionsHelper";
import css from "./Restrictions.sass";

const Restrictions = props => {
  const {
    isAdding,
    onEnableAddMode,
    onDisableAddMode,
    dispatch,
    restrictions
  } = props;

  const [currentRestriction, setcurrentRestriction] = useState(null);

  const clearCurrentRestriction = () => {
    setcurrentRestriction(null);
  };

  const handleAdd = restriction => {
    const restrictionData = {
      repetition_type: restriction.repetitionType,
      weekdays: restriction.days,
      all_day: restriction.allDay,
      time_ranges: restriction.timeRanges,
      restriction_dates: restriction.dates
    };

    onDisableAddMode();
    if (restriction.id) {
      dispatch(
        updateDefaultRestriction(
          restriction.id,
          restrictionData
        )
      );
      toast.success("Restriction updated successfully!");
    } else {
      dispatch(addDefaultRestriction(restrictionData));
      toast.success("Restriction added successfully!");
    }
    clearCurrentRestriction();
  };

  const handleRemove = id => {
    dispatch(removeDefaultRestriction(id));
    clearCurrentRestriction();
  };

  const handleRestrictionClick = restriction => {
    setcurrentRestriction(restriction);
    onEnableAddMode();
  };

  const handleCancelClick = () => {
    clearCurrentRestriction();
    onDisableAddMode();
  };

  const sortedRestrictions = sortRestrictions(restrictions);

  useEffect(() => {
    dispatch(fetchDefaultRestrictions());
  }, []);

  return (
    <div>
      {isAdding ? (
        <RestrictionsAddForm
          restrictions={restrictions}
          onAdd={handleAdd}
          onCancel={handleCancelClick}
          currentRestriction={currentRestriction}
        />
      ) : (
        <Table className={css.restriction}>
          <Tbody>
            {sortedRestrictions.map((restriction, restrictionIndex) => {
              const isFirstOfType =
                !restrictionIndex ||
                restriction.repetitionType !==
                  sortedRestrictions[restrictionIndex - 1].repetitionType;
              return (
                <Restriction
                  key={restrictionIndex}
                  restriction={restriction}
                  firstOfType={isFirstOfType}
                  onRemove={handleRemove}
                  onClick={() => { handleRestrictionClick(restriction) }}
                />
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td
                colSpan="3"
                modifier={sortedRestrictions.length ? "" : "noBorderTop"}
              >
                <Button
                  variant="outline"
                  onClick={onEnableAddMode}
                >
                  <Icon
                    name="showingRestricted"
                    modifier="offset5 valignTextDefault"
                  />
                  Add Time/Date Restriction
                </Button>
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      )}
    </div>
  );
};

Restrictions.propTypes = {
  isAdding: PropTypes.bool,
  onEnableAddMode: PropTypes.func,
  onDisableAddMode: PropTypes.func,
  dispatch: PropTypes.func,
  restrictions: PropTypes.array
};

function mapStateToProps(state) {
  return {
    restrictions: state.settings.defaultRestrictions.restrictions
  };
};

export default connect(mapStateToProps)(Restrictions);
