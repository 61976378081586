import React from "react";
import css from "./index.sass";
import AddBuyer from "./AddBuyer";
import Buyer from "./Buyer";

export default function BuyerSection({ buyers, setBuyers, createBuyer }) {
  const handleAddBuyer = ({ value, label }) => {
    if (!value) createBuyer();
    else {
      const updatedBuyers = [...buyers, { uuid: value, name: label }];
      setBuyers(updatedBuyers);
    }
  };

  const handleRemoveBuyer = uuid => {
    const updatedBuyers = buyers.filter(buyer => buyer.uuid !== uuid);
    setBuyers(updatedBuyers);
  };

  return (
    <>
      {!!buyers.length && (
        <div className={css.buyers}>
          {buyers.map(buyer => (
            <Buyer
              buyer={buyer}
              onRemoveClick={handleRemoveBuyer}
              key={buyer.uuid || buyer.name}
            />
          ))}
        </div>
      )}
      <AddBuyer buyers={buyers} onBuyerAdd={handleAddBuyer} />
    </>
  );
}
