import React from 'react'
import css from './Column.sass'

const Column = ({ children, className, ...rest }) => (
  <div className={`${css.column} ${(typeof className !== "undefined") ? className : ""}`} {...rest}>
    {children}
  </div>
)

export default Column;
