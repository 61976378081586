import React, { useState, useCallback } from "react";
import debounce from "lodash.debounce";

import Autocomplete from "~brokerage/components/shared/Autocomplete";
import { callApi } from "brokerage/middlewares/api";

export default function BuyerAutocomplete({
  buyers,
  onBuyerAdd,
  newBuyerOption = true
}) {
  const [currentValue, setCurrentValue] = useState("");
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchBuyers = async name => {
    if (name && name.length < 3) return;
    try {
      setFetching(true);
      const {
        data: { contacts }
      } = await callApi("contacts", { name, type: "buyer" }, {}, "get");
      const newOptions = contacts.map(({ id, uuid, firstName, lastName }) => ({
        id,
        value: uuid,
        label: `${firstName} ${lastName}`
      }));
      if (newBuyerOption) newOptions.push({ label: "Create Buyer" });
      setOptions(newOptions);
    } finally {
      setFetching(false);
    }
  };

  const delayedFetch = useCallback(debounce(fetchBuyers, 500), []);

  const handleChange = ({ target: { value } }) => {
    setCurrentValue(value);
    setOptions([]);
    delayedFetch(value);
  };

  const buyerIds = buyers.map(b => b.uuid);
  const displayOptions = options.filter(
    ({ value }) => !(value && buyerIds.includes(value))
  );

  return (
    <Autocomplete
      value={currentValue}
      options={displayOptions}
      onChange={handleChange}
      placeholder="Search buyer"
      variant="person"
      onSelect={onBuyerAdd}
      fetching={fetching}
    />
  );
}
