import css from './Section.sass'
import React from 'react'
import PropTypes from 'prop-types'

const Section = ({ heading, children, ...rest }) => {
  return (
    <div className={css.base} {...rest}>
      {(() => {
        if (heading) {
          return <h2 className={css.heading}>{heading}</h2>
        }
      })()}
      {children}
    </div>
  )
}

Section.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node
}
export default Section
