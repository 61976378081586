const applyUpdate = ({ activeId, update, data, travelTimeMatrix }) => {
  let times = data
    .map(t => {
      return t.id === activeId ? { ...t, ...update } : t;
    })
    .sort((a, b) => a.time.unix() - b.time.unix())
    .map((showing, index, showings) => {
      let travelTime = 0;
      if (index > 0) {
        const travelTimesForPrevShowing = travelTimeMatrix.find(
          t => t.id === showings[index - 1].id
        );
        if (travelTimesForPrevShowing) {
          const indexForCurrShowing = travelTimeMatrix.findIndex(
            t => t.id === showing.id
          );
          travelTime =
            travelTimesForPrevShowing.durations[indexForCurrShowing] || 0;
        }
      }
      return {
        ...showing,
        travelTime,
        position: index
      };
    });
  return times;
};

export default applyUpdate;
