import React, { useEffect } from "react";
import css from "./index.sass";
import { connect } from "react-redux";

import { fetchDefaultSurveySettings } from "~brokerage/actions/surveys";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Loader from "~brokerage/components/shared/Loader";
import FeedbackSurvey from "./FeedbackSurvey";

const SurveySettings = ({ dispatch, history, settings, isFetching }) => {
  useEffect(() => {
    dispatch(fetchDefaultSurveySettings());
  }, []);
  return (
    <div className={css.header}>
      <Button
        variant="link"
        onClick={() => history.replace("/settings/feedback")}
      >
        <Icon name="arrowLeft" modifier="offset5 valignTextDefault" />
        Back
      </Button>
      <h3 className={css.title}>Default Survey Settings</h3>
      <div className={css.settingsContainer}>
        {!!settings && <FeedbackSurvey initialSettings={settings} />}
      </div>
      <Loader active={isFetching} />
    </div>
  );
};

const mapStateToProps = state => {
  const { settings, isFetching } = state.surveys.defaultSettings;

  return {
    settings,
    isFetching
  };
};

export default connect(mapStateToProps)(SurveySettings);
