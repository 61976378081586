import css from './NavigationLink.sass'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'

export default class NavigationLink extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    index: PropTypes.bool,
    parent: PropTypes.bool,
    nested: PropTypes.bool
  }

  render() {
    const { to, icon, label, nested, parent } = this.props
    const itemClass = nested ? css.nestedItem : css.item
    const activeItemClass = parent ? css.parentActive : css.active
    return (
      <NavLink className={itemClass} to={to} activeClassName={activeItemClass} exact={true}>
        <i className={css.i}><Icon name={icon}/></i>
        <span className={css.span}>{label}</span>
      </NavLink>
    )
  }
}
