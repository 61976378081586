import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import css from "./ProfilePicture.sass";

const ProfilePicture = ({ hasPhoto, photoUrl, userInitials, variant, imageUrlFailed, setImageUrlFailed }) => {

  const imageRef = useRef();

  const setImageToFallBackSRC = () => {
    setImageUrlFailed(true);
  };

  return hasPhoto && photoUrl && imageUrlFailed != true ? (
    <img className={`${variant ? css[`${variant}Picture`] : css.picture }`} src={photoUrl} alt="" ref={imageRef} onError={setImageToFallBackSRC} />
  ) : (
    <div className={`${variant ? css[`${variant}Picture`] : css.picture }`}>
      <div className={`${variant ? css[`${variant}Placeholder`] : css.placeholder }`}>{userInitials}</div>
    </div>
  );
}

ProfilePicture.propTypes = {
  hasPhoto: PropTypes.bool,
  photoUrl: PropTypes.string,
  userInitials: PropTypes.string
};

export default ProfilePicture;
