import { createEvent } from "ics";
import { toast } from "react-toastify";
import moment from "moment";

import { formatPhone } from "~brokerage/app/components/shared/AppointmentDetailsModal/utilsDetails";

export const buildCalendarEvent = ({
  time,
  duration,
  uniqueId,
  address,
  users = []
}) => {
  const startTime = moment(time);
  const endTime = startTime.clone().add(duration, "minutes");
  const showingAgent = users.find(agent => agent.relation === "Showing Agent");
  const listingAgent = users.find(agent => agent.relation === "Listing Agent");
  const summary = `Showing: ${address} - via Aligned Showings`;

  const formattedTime = startTime.format("dddd, MMM D [at] h:mm a");

  const showingAgentInfo = showingAgent
    ? `Showing Agent\n${showingAgent.name} of ${showingAgent.office}\n${
        showingAgent.phone ? formatPhone(showingAgent.phone) : ""
      }\n\n`
    : "";
  const listingAgentInfo = listingAgent
    ? `Listing Agent\n${listingAgent.name} of ${listingAgent.office}\n${
        listingAgent.phone ? formatPhone(listingAgent.phone) : ""
      }`
    : "";

  const description = `${formattedTime}\n\nScheduled using Aligned Showings${showingAgentInfo}${listingAgentInfo}`;

  return {
    startTime,
    duration,
    endTime,
    summary,
    description,
    showingAgent,
    listingAgent,
    showingId: uniqueId
  };
};

export const downloadIcs = ({
  startTime,
  duration,
  summary,
  description,
  address,
  showingId
}) => {
  const icsData = {
    productId: "-//AlignedShowings//AlignedShowings Events v1.0//EN",
    uid: `showings/${showingId}`,
    start: startTime
      .format("YYYY-M-D-H-m")
      .split("-")
      .map(val => parseInt(val)),
    startInputType: "local",
    duration: { minutes: duration },
    title: summary,
    description,
    location: address,
    status: "CONFIRMED",
    organizer: {
      name: "Aligned Showings",
      email: "noreply@alignedshowings.com"
    }
  };

  createEvent(icsData, (error, value) => {
    if (error) {
      console.log(error);
      toast.error(
        "There was an error exporting this appointment, please try again later."
      );
      return;
    }
    const blob = new Blob([value], { type: "text/calendar" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "invite.ics");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success("Appointment exported.");
  });
};

export const addEventToGoogleCalendar = ({
  startTime,
  endTime,
  summary,
  description,
  address
}) => {
  const dates = `${startTime.format("YYYYMMDD[T]HHmmss")}/${endTime.format(
    "YYYYMMDD[T]HHmmss"
  )}`;

  const url = "http://www.google.com/calendar/event?action=TEMPLATE";

  window.open(
    `${url}&dates=${encodeURIComponent(dates)}&text=${encodeURIComponent(
      summary
    )}&location=${encodeURIComponent(address)}&details=${encodeURIComponent(
      description
    )}`
  );
  toast.success("Appointment exported.");
};
