import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { changeShowingsDetailsStep } from '~brokerage/actions/listings'
import Content from '~brokerage/components/shared/Content'
import ListingsHeader from '~brokerage/components/listings/ListingsHeader'
import List from './list'
import Single from './single'
import Showings from './single/showings'
import SelectRoute from "./single/select-route"
import OldDetails from "./single/old-details"
import Feedback from './single/feedback'

class FeedbackSubpage extends Feedback {}

class Listings extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    match: PropTypes.object,
    dispatch: PropTypes.func
  }

  componentWillMount() {
    this.setStep(this.props.location.query.step)
  }

  componentWillReceiveProps(nextProps) {
    this.setStep(nextProps.location.query.step)
  }

  setStep(step) {
    const parsedStep = parseInt(step, 10)

    if (!isNaN(parsedStep))
      this.props.dispatch(changeShowingsDetailsStep(parsedStep - 1))
  }

  render() {
    const { location, match} = this.props

    return (
      <div>
        <ListingsHeader location={location} />
        <Content header="single" style="noPadding">
          <Switch>
            <Route path={`${match.path}/:listingId/showings`} exact={true} component={Showings} />
            <Route path={`${match.path}/:listingId/select_route`} exact={true} component={SelectRoute} />
            <Route path={`${match.path}/:listingId/feedback`} exact={true} component={Feedback} />
            <Route path={`${match.path}/:listingId/feedback/subpage`} exact={true} component={FeedbackSubpage} />
            <Route path={`${match.path}/:listingId/details`} exact={true} component={OldDetails} />
            <Route path={`${match.path}/:listingId`} component={Single} />
            <Route path={`${match.path}`} component={List} />
          </Switch>
        </Content>
      </div>
    )
  }
}
export default connect()(Listings)
