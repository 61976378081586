import { updateDirectMessageReadReceipt } from "~brokerage/actions/messages";

export default class DirectMessageHasReadHandler {
  constructor(message, props) {
    this.message = message;
    this.props = props;
  }

  handle() {
    this.props.dispatch(updateDirectMessageReadReceipt(this.message));
  }
}
