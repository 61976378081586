import * as types from '~brokerage/constants/actionTypes'
import api from './api'
import * as steps from '~brokerage/constants/showings/newShowingSections'
import { inUsersTimezone } from '~brokerage/libs/helpers/TimeHelper'

function changeStep(step) {
  return {
    type: types.NEW_SHOWING_STEP_CHANGE,
    step
  }
}

export function fetchListings(params) {
  return api.listings.fetchListings(params, [
    types.NEW_SHOWING_LISTINGS_REQUEST,
    types.NEW_SHOWING_LISTINGS_SUCCESS,
    types.NEW_SHOWING_LISTINGS_FAILURE
  ])
}

export function clearForm() {
  return api.newShowingForm.clearForm()
}

export function selectListing(id) {
  return (dispatch, getState) => {
    const { selectedAgent } = getState().showings.new
    dispatch(changeStep(selectedAgent ? steps.SELECT_DATE_AND_TIME : steps.SELECT_SHOWING_AGENT))
    dispatch(fetchListing(id))
    dispatch(fetchShowingInstructions(id))
    dispatch(fetchListingRestrictions(id))
    dispatch(fetchSellerTenants(id))
    dispatch(fetchTeamMembers(id))
  }
}

function fetchListing(id) {
  return api.listings.fetchListing(id, [
    types.NEW_SHOWING_SELECTED_LISTING_REQUEST,
    types.NEW_SHOWING_SELECTED_LISTING_SUCCESS,
    types.NEW_SHOWING_SELECTED_LISTING_FAILURE
  ])
}

function fetchShowingInstructions(listingId) {
  return api.listings.fetchShowingInstructions(listingId, [
    types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_REQUEST,
    types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_SUCCESS,
    types.NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_FAILURE
  ])
}

function fetchListingRestrictions(listingId) {
  return api.listings.fetchListingRestrictions(listingId, [
    types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_REQUEST,
    types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_SUCCESS,
    types.NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_FAILURE
  ])
}

function fetchSellerTenants(listingId) {
  return api.listings.fetchSellerTenantInvolvement(listingId, [
    types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_REQUEST,
    types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_SUCCESS,
    types.NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_FAILURE
  ])
}

function fetchTeamMembers(listingId) {
  return api.listings.fetchListingTeamMembers(listingId, [
    types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_REQUEST,
    types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_SUCCESS,
    types.NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_FAILURE
  ])
}

export function clearSelectedListing () {
  return dispatch => {
    dispatch(changeStep(steps.SELECT_LISTING))
    dispatch({
      type: types.NEW_SHOWING_CLEAR_SELECTED_LISTING
    })
  }
}

export function fetchAgents(params) {
  return api.newShowingForm.fetchAgents(params, [
    types.NEW_SHOWING_AGENTS_REQUEST,
    types.NEW_SHOWING_AGENTS_SUCCESS,
    types.NEW_SHOWING_AGENTS_FAILURE
  ])
}

export function selectAgent(agent) {
  return dispatch => {
    dispatch(changeStep(steps.SELECT_DATE_AND_TIME))
    dispatch({
      type: types.NEW_SHOWING_SELECTED_AGENT,
      agent
    })
  }
}

export function clearSelectedAgent() {
  return dispatch => {
    dispatch(changeStep(steps.SELECT_SHOWING_AGENT))
    dispatch({
      type: types.NEW_SHOWING_CLEAR_SELECTED_AGENT
    })
  }
}

export function selectDate(date, currentShowing) {
  return (dispatch, getState) => {
    dispatch({
      type: types.NEW_SHOWING_SELECTED_DATE,
      date
    })
    dispatch(fetchShowings(date, currentShowing))
  }
}

export function selectTime(time) {
  return dispatch => {
    dispatch({
      type: types.NEW_SHOWING_SELECTED_TIME,
      time
    })
  }
}

export function selectDuration(duration) {
  return dispatch => {
    dispatch({
      type: types.NEW_SHOWING_SELECTED_DURATION,
      duration
    })
  }
}

export function fetchShowings(_date, currentShowing) {
  const date = inUsersTimezone(_date).format('YYYY-MM-DD')

  const params = {
    current_showing_id: currentShowing && currentShowing.id,
    date
  }
  return api.showings.fetchShowings(params, [
    types.NEW_SHOWING_SHOWINGS_REQUEST,
    types.NEW_SHOWING_SHOWINGS_SUCCESS,
    types.NEW_SHOWING_SHOWINGS_FAILURE
  ])
}

export function fetchRestrictions(listingId, _date) {
  const date = inUsersTimezone(_date).format('YYYY-MM-DD')

  return api.listings.fetchRestrictions(listingId, date, [
    types.NEW_SHOWING_RESTRICTIONS_REQUEST,
    types.NEW_SHOWING_RESTRICTIONS_SUCCESS,
    types.NEW_SHOWING_RESTRICTIONS_FAILURE
  ])
}

export function save(payload) {
  return api.showings.createShowing(payload, [
    types.NEW_SHOWING_SAVE_REQUEST,
    types.NEW_SHOWING_SAVE_SUCCESS,
    types.NEW_SHOWING_SAVE_FAILURE
  ])
}

export function send(showingId, payload) {
  return api.showings.sendRequest(showingId, payload, [
    types.NEW_SHOWING_CONVERT_REQUEST,
    types.NEW_SHOWING_CONVERT_SUCCESS,
    types.NEW_SHOWING_CONVERT_FAILURE
  ])
}

export function convertToShowing(payload) {
  return api.showings.updateShowing(payload.listing_id, payload, [
    types.NEW_SHOWING_CONVERT_REQUEST,
    types.NEW_SHOWING_CONVERT_SUCCESS,
    types.NEW_SHOWING_CONVERT_FAILURE
  ])
}

export function fetchListingStatuses() {
  return api.listing.fetchListingStatuses(null, [
    types.NEW_SHOWING_LISTING_STATUSES_REQUEST,
    types.NEW_SHOWING_LISTING_STATUSES_SUCCESS,
    types.NEW_SHOWING_LISTING_STATUSES_FAILURE
  ])
}

export function closeModal() {
  return {
    type: types.NEW_SHOWING_CLOSE_MODAL
  }
}

export function preserveGlobalState() {
  return {
    type: types.NEW_SHOWING_PRESERVE_GLOBAL_STATE
  }
}

export function clearPreserveGlobalState() {
  return {
    type: types.NEW_SHOWING_CLEAR_PRESERVE_GLOBAL_STATE
  }
}

export function clearAgents(){
  return {
    type: types.NEW_SHOWING_AGENTS_CLEAR
  }
}
