import css from './Radio.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { v4 } from 'uuid'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import { withFormBinding } from './Form'
class Radio extends Component {
  static propTypes = {
    variant: PropTypes.string,
    modifier: PropTypes.string,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    children: PropTypes.node
  }

  state = {
    checked: this.props.defaultChecked
  }

  componentDidMount() {
    if (this.props.checked === void 0) {
      this.el = ReactDOM.findDOMNode(this)
      this.input = this.el.querySelector('input')
      this.$input = $(this.input)

      this.$input.on('change.Radio', () => {
        $(`input[type="radio"][name="${this.input.name}"]`).trigger('redraw')
      })

      this.$input.on('redraw.Radio', () => {
        this.setState({
          checked: this.input.checked
        })
      })
    }
  }

  componentWillUnmount() {
    if (this.props.checked === void 0) {
      this.$input.off('change.Radio redraw.Radio')
    }
  }

  validate = () => {
    return []
  }

  handleErrors = () => {
    return
  }

  value = () => {
    if (!this.input.checked) {
      return {}
    }

    return {
      [this.input.name]: this.input.value
    }
  }

  render() {
    const { children, variant = 'base', modifier, ...rest } = this.props
    const uuid = v4()

    const checked = this.props.checked || this.state.checked

    return (
      <div className={composeClassName(css, variant, modifier)}>
        <input id={`radio-${uuid}`} type="radio" ref={(el) => this.input = el} {...rest}/>
        <label className={css[`${variant}Label${checked ? 'Active' : ''}`]} htmlFor={`radio-${uuid}`}>
          {children}
        </label>
      </div>
    )
  }
}
export default withFormBinding(Radio)
