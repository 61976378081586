import React from "react";
import {
  Row,
  Label,
  Controls,
  Textarea
} from "~brokerage/components/shared/Form";
import { REQUIRE_APPROVAL } from "./constants";
import css from "./ShowingInstructions.sass";

const InitialMessage = ({ initialMessage, requestHandling, handleChange }) => {
  if (requestHandling !== REQUIRE_APPROVAL) return null;

  return (
    <Row>
      <div className={css.label}>
        <Label>
          Instant message to agent
          <small>
            A message instantly sent to the agent before you respond.
          </small>
        </Label>
      </div>
      <div className={css.initialMessageTextAreaControls}>
        <Controls>
          <Textarea
            name="initialMessage"
            value={initialMessage}
            placeholder="Enter Instant Message for Showing Agents"
            onChange={handleChange}
          />
        </Controls>
      </div>
    </Row>
  );
};

export default InitialMessage;
