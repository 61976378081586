import css from './StepHeading.sass'
import React from 'react'
import PropTypes from 'prop-types'
import {
  STEP_DONE as DONE,
  STEP_EDITING as EDITING,
  STEP_ACTIVE as ACTIVE
} from '~brokerage/constants/showings/newShowingSections'
import Icon from '~brokerage/components/shared/Icon'

const StepHeading = ({ index, doneText, variant, onDoneClick }) => {
  const isDone = variant === DONE

  return (
    <div className={css[variant]} onClick={isDone ? onDoneClick : null}>
      <div className={css.index}>
        {(() => {
          switch (variant) {
          case EDITING:
            return <Icon name="edit"/>
          case ACTIVE:
            return <Icon name="chevronRight"/>
          case DONE:
            return <Icon name="check"/>
          default:
            return index
          }
        })()}
      </div>
      <h2 className={css.text}>
        {isDone ? doneText : `Step ${index}`}
      </h2>
      {isDone &&
        <div className={css.btnEdit}>
          <div className={css.btnEditIcon}><Icon name="edit"/></div> Edit
        </div>
      }
    </div>
  )
}

StepHeading.propTypes = {
  index: PropTypes.string,
  doneText: PropTypes.string,
  variant: PropTypes.string,
  onDoneClick: PropTypes.func
}
export default StepHeading
