import React, { useState, useEffect } from "react";
import RouteListingImagesCollage from "brokerage/app/components/myShowings/RouteListingImagesCollage";
import NoRoutesAvailablePage from "brokerage/app/components/myShowings/NoRoutesAvailablePage";
import RouteNameAndDate from "./partials/RouteNameAndDate";
import RouteDetailsButton from "./partials/RouteDetailsButton";
import css from "brokerage/app/components/myShowings/RouteList.sass";
import VerticalBar from "./partials/VerticalBar";
import PropTypes from "prop-types";
import { callApi } from "brokerage/middlewares/api";
import Loader from "brokerage/app/components/shared/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const RouteList = ({ past }) => {
  const [showingRoutes, setShowingRoutes] = useState([]);
  const [meta, setMeta] = useState({ currentPage: 1, totalPages: 0 });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoutes(meta.currentPage);
  }, []);

  const getRoutes = page => {
    setLoading(true);
    const url = past ? "routes/past" : "routes";

    callApi(url, { page }, {}, "get").then(({ data }) => {
      setShowingRoutes([...showingRoutes, ...data.data.routes]);
      setMeta({
        currentPage: data.meta?.current_page,
        totalPages: data.meta?.total_pages
      });
      setLoading(false);
    });
  };

  if (loading && showingRoutes.length === 0) {
    return <Loader active />;
  }

  const { currentPage, totalPages } = meta;

  return (
    <div className={past ? css.pastShowingRoutesContainer : ""}>
      {showingRoutes.length === 0 ? (
        <NoRoutesAvailablePage
          past={past}
          message={`You have no ${past ? "past" : ""} routes to display.`}
        />
      ) : (
        <InfiniteScroll
          scrollableTarget="routes"
          dataLength={showingRoutes.length}
          next={() => getRoutes(currentPage + 1)}
          hasMore={currentPage < totalPages}
        >
          {showingRoutes.map(route => (
            <div key={route.id} className={css.listItem}>
              <VerticalBar isScheduledToday={route.is_scheduled_today} />
              <RouteListingImagesCollage route={route} />
              <RouteNameAndDate route={route} pastAppointment={past} />
              {!past && <RouteDetailsButton route={route} />}
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

RouteList.propTypes = {
  past: PropTypes.bool
};

export default RouteList;
