import React from "react";
import css from "./Buyer.sass";
import Avatar from "~brokerage/components/shared/Avatar";
import Icon from "~brokerage/components/shared/Icon";
import Tooltip from "~brokerage/components/shared/Tooltip";
import classNames from "classnames";

export default function Buyer({
  buyer: { name, uuid, isExisting },
  onRemoveClick,
  focused = false
}) {
  return (
    <div className={css.base}>
      <Tooltip text={name}>
        <div
          // TODO::AI-1626: Remove disabling the removal of existing buyers
          // once support for such is enabled.
          className={classNames({
            [css.innerFocused]: focused,
            [css.inner]: !focused && !isExisting,
            [css.innerNoAfter]: !focused && isExisting
          })}
        >
          <Avatar size="30" name={name} />
          {!isExisting && (
            <a
              className={focused ? css.removeFocused : css.remove}
              onClick={() => onRemoveClick(uuid)}
            >
              <Icon name="close" />
            </a>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
