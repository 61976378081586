import css from './ListItem.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import omit from 'lodash/omit'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import { daysAgo } from '~brokerage/libs/helpers/TimeHelper'
import { extractStreetFromFullAddress, composeStatusChangedMessage } from '~brokerage/libs/helpers/ShowingHelper'
import { EVENT_TYPE, SYSTEM_TYPE } from '~brokerage/constants/messages'
import { STATUS_PENDING, STATUS_FEEDBACK_RECEIVED } from '~brokerage/constants/messages/showingStatuses'
import Dot from '~brokerage/components/shared/Dot'
import ShowingStatusIcon from '~brokerage/components/shared/ShowingStatusIcon'

export default class ListItem extends React.PureComponent {
  static propTypes = {
    showing: PropTypes.object,
    active: PropTypes.bool,
    listFilter: PropTypes.object
  }

  renderAddress() {
    const streetAddress = extractStreetFromFullAddress(this.props.showing.address)

    return (
      <div className={css.address}>
        {streetAddress}
      </div>
    )
  }

  renderOtherSideAgent() {
    const { isBuyerAgentMe, showingAgent, listingAgent } = this.props.showing
    const otherSideAgent = isBuyerAgentMe ? listingAgent : showingAgent

    return (
      <div className={css.name}>
        <b>{otherSideAgent}</b>
      </div>
    )
  }

  renderReadUnread() {
    return (
      <div className={css.readUnread}>
        {(() => {
          if (this.props.showing.isUnread) {
            return <Dot variant="unread"/>
          }

          return <Dot variant="read"/>
        })()}
      </div>
    )
  }

  requestedTime() {
    const { showing } = this.props
    return (
      <div className={css.requestedTime}>
        <div className={css.status}>
          <ShowingStatusIcon status={showing.status}/>
        </div>
        {showing.requestedShortDatetime} <span className={css.duration}>({showing.duration}m)</span>
      </div>
    )
  }

  renderSellers() {
    const { seller, isBuyerAgentMe } = this.props.showing

    if (isBuyerAgentMe || !seller) {
      return null
    }

    return (
      <div className={css.sellers}>
        <span className={css.narrow}>Seller:</span> {seller}
      </div>
    )
  }


  renderListingAgents() {
    return (
      <div className={css.listingAgents}>
        <span className={css.narrow}>Team:</span> {this.props.showing.listingAgent}
      </div>
    )
  }

  composeMessageForSystemType() {
    const { lastMessage, status, showingAgent } = this.props.showing
    const { senderName, type } = lastMessage

    const senderNameForBuildStatusMessage = status === STATUS_PENDING ? showingAgent : senderName
    const messageStatus = type === STATUS_FEEDBACK_RECEIVED ? STATUS_FEEDBACK_RECEIVED : status

    return composeStatusChangedMessage(messageStatus, senderNameForBuildStatusMessage)
  }

  composedMessage() {
    const { lastMessage } = this.props.showing
    const { isFromMe, senderName, messageType, type } = lastMessage
    let { msg } = lastMessage

    if (messageType === EVENT_TYPE) return `Event: ${msg}`

    if (!msg && messageType === SYSTEM_TYPE) {
      msg = this.composeMessageForSystemType()
    }

    if (type === STATUS_FEEDBACK_RECEIVED) return `Event: ${msg}`

    return isFromMe ? `Me: ${msg}` : `${senderName}: ${msg}`
  }

  renderLastMessage() {
    const { lastMessage } = this.props.showing

    if (!lastMessage) {
      return
    }

    return (
      <div className={css.lastMessage}>
        <p className={css.lastMessageP}>{this.composedMessage()}</p>
      </div>
    )
  }

  render() {
    const { showing, listFilter, active } = this.props

    return (
      <Link
        to={{
          pathname: `/showings/${showing.id}`,
          query: omit(listFilter, ['page'])
        }}
        className={composeClassName(css, 'base', {
          active,
          read: !showing.isUnread
        })}
      >
        <div className={css.updatedAt}>{daysAgo(showing.lastActivityAt)}</div>
        {this.renderReadUnread()}
        {showing.duration && this.requestedTime()}
        <div className={css.photo} style={{ backgroundImage: `url("${showing.photoUrl}")`}}/>
        <div className={css.text}>
          <ul className={css.ul}>
            <li className={css.li}>{this.renderOtherSideAgent()}</li>
            <li className={css.li}>{this.renderAddress()}</li>
            <li className={css.li}>{this.renderListingAgents()}</li>
            <li className={css.li}>{this.renderSellers()}</li>
          </ul>
          {this.renderLastMessage()}
        </div>
      </Link>
    )
  }
}
