import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import omit from "lodash/omit";
import { connect } from "react-redux";

import Button from "~brokerage/components/shared/Button";
import { steps } from "../constants";
import css from "../index.sass";
import { Footer } from "~brokerage/components/modals/BaseModal";
import { updateShowingRoute } from "~brokerage/actions/routes";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { isForbidden } from "~brokerage/app/helpers/showingConflicts.js";

const Actions = ({
  setCurrentStep,
  clearStop,
  unsavedChanges,
  showings,
  dispatch
}) => {
  const { times } = useContext(ShowingContext);
  const [disabled, toggleDisabled] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (times) {
      const hasConflicts = times.some(time => isForbidden(time));
      toggleDisabled(hasConflicts);
    }
  }, [times]);

  const onSave = () => {
    const update = unsavedChanges ? [...unsavedChanges] : [];
    times.forEach(showing => {
      const origShowing = showings.find(s => s.id === showing.id);
      const reqTime =
        showing.time && showing.time.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (
        (reqTime && origShowing.requestedTimeRaw !== reqTime) ||
        showing.duration !== origShowing.duration ||
        showing.travelTime.toString() !== origShowing.travelTime.toString()
      ) {
        const change = {
          id: showing.id,
          requestedTimeRaw: reqTime,
          type: origShowing.type,
          duration: showing.duration,
          travelTime: showing.travelTime,
          position: showing.position
        };

        if (origShowing.type === "stop" && origShowing.id < 0) {
          change.address = origShowing.address;
          change.coordinates = origShowing.coordinates;
        }
        const updateIndex = update.findIndex(c => c.id === change.id);
        if (updateIndex !== -1) {
          update[updateIndex] = change;
        } else {
          update.push(change);
        }
      }
    });
    dispatch(updateShowingRoute({ unsavedChanges: update, optimized: false }));
    history.replace({
      ...location,
      query: omit(location.query, ["modal"])
    });
  };

  return (
    <Footer>
      <div className={css.buttonContainer}>
        <Button
          variant="outline"
          onClick={() => {
            setCurrentStep(steps.SEARCH_STEP);
            clearStop();
          }}
        >
          Change stop
        </Button>
        <Button variant="primary" disabled={disabled} onClick={onSave}>
          Add Stop
        </Button>
      </div>
    </Footer>
  );
};

export default connect()(Actions);
