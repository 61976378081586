import { connect } from 'react-redux'
import css from './Agent.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '~brokerage/components/shared/Avatar'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import List from '~brokerage/components/shared/List'
class Agent extends Component {
  static propTypes = {
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    brokerage: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    onClick: PropTypes.func,
    wrapButtonWithPremiumWrapper: PropTypes.bool,
    skipPremiumPreferencesFetching: PropTypes.bool,
    dispatch: PropTypes.func
  }

  renderPhone() {
    if (!this.props.phone) return

    return <List.Item icon="phone" modifier="pullLeft paddingTop3">{this.props.phone}</List.Item>
  }

  renderEmail() {
    if (!this.props.email) return

    return <List.Item icon="mail" modifier="pullLeft paddingTop3">{this.props.email}</List.Item>
  }

  renderBrokerage() {
    if (!this.props.brokerage) return

    return <List.Item icon="building" modifier="pullLeft paddingTop3">{this.props.brokerage}</List.Item>
  }

  renderSelectAgentButton(text) {
    return (
      <div className={css.btnSelect}>
        <Button variant="outline" onClick={this.props.onClick}>
          <Icon name="plus"/> {text || "Select Agent"}
        </Button>
      </div>
    )
  }

  renderAvatar() {
    return (
      <div className={css.photo}>
        <Avatar size="30" name={this.props.name} src={this.props.photoUrl}/>
      </div>
    )
  }

  render () {
    return (
      <List.Item modifier="padding15" variant="bordered">
        {this.renderSelectAgentButton()}

        {this.renderAvatar()}
        <div className={css.info}>
          <div className={css.name}>{this.props.name}</div>
          <List variant="small" modifier="pullLeft">
            {this.renderPhone()}
            {this.renderEmail()}
            {this.renderBrokerage()}
          </List>
        </div>
      </List.Item>
    )
  }
}
export default connect()(Agent)
