import css from "../People.sass";
import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Person from "./Person";
import PeopleAddForm from "./PeopleAddForm";
import {
  removeSellerOrTenantParticipant,
  addSellerOrTenantParticipant
} from "~brokerage/actions/listings";

export const People = ({ people, listingId, onChange, dispatch }) => {
  const [isAdding, setIsAdding] = useState(false);

  const handleAddClick = () => {
    setIsAdding(true);
    onChange();
  };

  const handleRemoveClick = index => {
    dispatch(removeSellerOrTenantParticipant(people[index]));
    onChange();
  };

  const handleAddCancel = () => {
    setIsAdding(false);
  };

  const handleAdd = person => {
    dispatch(addSellerOrTenantParticipant(person));
    setIsAdding(false);
  };

  return (
    <div className={css.base}>
      <table className={css.table}>
        <tbody>
          {people.map((person, personIndex) => {
            return (
              <tr key={personIndex}>
                <td className={css.tdName}>
                  <Person
                    person={person}
                    size="30"
                    onRemoveClick={() => handleRemoveClick(personIndex)}
                    onChange={onChange}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={css.footer}>
        {isAdding ? (
          <PeopleAddForm
            listingId={listingId}
            onCancel={handleAddCancel}
            onAdd={handleAdd}
          />
        ) : (
          <Button variant="outline" onClick={handleAddClick}>
            <Icon name="userAdd" /> Add Seller/Tenant
          </Button>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { entities: people } = state.listings.single.sellerTenantInvolvement;

  return {
    people
  };
}
export default connect(mapStateToProps)(People);
