import {
  ATTACHMENT_LISTING_CREATE_REQUEST,
  ATTACHMENT_LISTING_CREATE_SUCCESS,
  ATTACHMENT_LISTING_CREATE_FAILURE,
  ATTACHMENT_LISTING_DESTROY,
  ATTACHMENT_LISTING_CLEAR_ERRORS
} from '~brokerage/constants/actionTypes'

export const defaultState = {
  entity: {},
  errors: {},
  isCreating: false
}

export default function attachmentsReducer(state = defaultState, action) {
  switch (action.type) {
    case ATTACHMENT_LISTING_CREATE_REQUEST:
      return {
        ...state,
        isCreating: true
      }
    case ATTACHMENT_LISTING_CREATE_SUCCESS:
      return {
        ...state,
        errors: {},
        isCreating: false,
        entity: action.data.listing
      }
    case ATTACHMENT_LISTING_CREATE_FAILURE:
      return {
        ...state,
        isCreating: false,
        errors: {
          ...state.errors,
          ...action.error
        }
      }
    case ATTACHMENT_LISTING_DESTROY:
      return defaultState
    case ATTACHMENT_LISTING_CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      }

    default:
      return state
  }
}
