import React from "react";
import Icon from "~brokerage/components/shared/Icon";
import { getPages } from "./utils";
import css from "./index.sass";

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const pages = getPages(currentPage, totalPages);

  return (
    <div className={css.pagination}>
      <div
        onClick={() => handlePageChange(currentPage - 1, totalPages)}
        className={currentPage === 1 ? css.pageButtonDisabled : css.pageButton}
      >
        <Icon name="chevronLeft" />
      </div>
      {pages.map(num => (
        <div
          onClick={() =>
            num !== currentPage && handlePageChange(num, totalPages)
          }
          className={
            num === currentPage ? css.pageButtonSelected : css.pageButton
          }
          key={num}
        >
          {num}
        </div>
      ))}
      <div
        onClick={() => handlePageChange(currentPage + 1, totalPages)}
        className={
          currentPage === totalPages ? css.pageButtonDisabled : css.pageButton
        }
      >
        <Icon name="chevronRight" />
      </div>
    </div>
  );
};

export default Pagination;
