import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from "prop-types";

const InputMask = ({ maskingFunction, name, placeholder, defaultValue, type, required, pattern, ...restProps }) => {
  const inputValue = useRef(null);

  // Onchange apply masking function
  const handleChange = (e) => {
    const itemValue = inputValue.current.value;
    const maskedValue = maskingFunction(itemValue);
    inputValue.current.value = maskedValue;
  };

  // Set current form context to parent context
  const { register } = useFormContext();

  const { ref, ...rest } = register(name, { required: required, pattern: pattern });

  return (
    <input {...rest} type={type} name={name} placeholder={placeholder} defaultValue={defaultValue} onChange={handleChange} ref={(e) => {
        ref(e)
        inputValue.current = e
      }} {...restProps} />
  );
};

export default InputMask;

InputMask.propTypes = {
  maskingFunction: PropTypes.func.isRequired
}
