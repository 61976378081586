import css from './MainNavigationLink.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
export default class MainNavigationLink extends Component {
  static propTypes = {
    icon: PropTypes.object.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { icon: Icon, color, label, to, href, children, ...rest} = this.props

    const iconComponent = (
      <i className={css.navLinkIcon}>
        <Icon size={16} className={css.icon}/>
      </i>
    )
    const content = label || children
    let link
    if (to) {
      link =
        <NavLink className={css.navLink} to={to} activeClassName={css.active} {...rest}>
          { iconComponent }
          { content }
        </NavLink>
    } else {
      link =
        <a className={css.navLink} href={href || "#"} {...rest}>
          { iconComponent }
          { content }
        </a>
    }

    return (
      <li className={css.li}>
        { link }
      </li>
    )
  }
}
