import React from 'react'
import css from './ReadOnlyInput.sass'

const ReadOnlyInput = ({ children, className, placeholder, ...rest }) => (
  <div className={`${css.ReadOnlyInput} ${className}`} {...rest}>
    {
      children !== null ? <>{children}</> : <>{placeholder}</>
    }
  </div>
)

export default ReadOnlyInput;
