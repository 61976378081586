import css from "./SelectLockbox.sass";
import React from "react";
import {
  LOCKBOX_TYPES,
  DEFAULT_LOCKBOX_TYPE,
  COMBO_MAX_LENGTH
} from "~brokerage/constants/lockboxTypes";
import {
  LOCKBOX_BRANDS,
  DEFAULT_LOCKBOX_BRAND
} from "~brokerage/constants/lockboxBrands";
import { REQUIRED_IS_EMPTY } from "~brokerage/constants/validation";
import {
  Row,
  Label,
  Controls,
  Group,
  Radio,
  TextField,
  ErrorContainer,
  Dropdown,
  Col
} from "~brokerage/components/shared/Form";
import Icon from "~brokerage/components/shared/Icon";

const SelectLockbox = ({
  lockboxKind = DEFAULT_LOCKBOX_TYPE.value,
  lockboxBrand = DEFAULT_LOCKBOX_BRAND.value,
  lockboxCode,
  changeDetails
}) => {
  const iconMapping = {
    combo: "combo",
    electronic: "lockbox"
  };

  return (
    <div className={css.base}>
      <div className={css.types}>
        <Group
          variant="well"
          modifier={[
            "centeredInlineChildren",
            { noBottomBorder: lockboxKind === "combo" }
          ]}
        >
          {LOCKBOX_TYPES.map((type, typeIndex) => (
            <Radio
              key={typeIndex}
              variant="lockboxKind"
              name="lockboxKind"
              value={type.value}
              checked={lockboxKind === type.value}
              onChange={e => {
                changeDetails({ [e.target.name]: e.target.value });
              }}
            >
              <div className={css.icon}>
                <Icon name={iconMapping[type.value] || "decline"} />
              </div>
              {type.label}
            </Radio>
          ))}
        </Group>
      </div>
      {lockboxKind === "combo" && (
        <div className={css.combination}>
          <ErrorContainer name="lockboxCode">
            <Row offset="0">
              <Label variant="combination">Combination</Label>
              <Controls>
                <TextField
                  variant="combination"
                  placeholder="Type Combo"
                  name="lockboxCode"
                  value={lockboxCode}
                  autoComplete="off"
                  focused
                  maxLength={COMBO_MAX_LENGTH}
                  block
                  required
                  trimValue
                  errorDescriptions={{
                    [REQUIRED_IS_EMPTY]: "Sorry, a combination code is needed."
                  }}
                  onChange={e => changeDetails({ lockboxCode: e.target.value })}
                />
              </Controls>
            </Row>
          </ErrorContainer>
        </div>
      )}
      {lockboxKind === "electronic" && (
        <div className={css.brand}>
          <ErrorContainer name="lockboxBrand">
            <Row offset="0" className={css.brandContainer}>
              <Label className={css.brandLabel}>Lockbox Brand</Label>
              <Controls>
                <Col>
                  <Dropdown
                    name="brand"
                    options={LOCKBOX_BRANDS}
                    selected={lockboxBrand}
                    onChange={event => changeDetails({ lockboxBrand: event.brand })}
                    variant="modalShowingDetailsLockboxBrand"
                  />
                </Col>
              </Controls>
            </Row>
          </ErrorContainer>
        </div>
      )}
    </div>
  );
};

export default SelectLockbox;
