import React from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Label
} from "~brokerage/components/shared/Form";
import css from "./index.sass";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import DefaultTimeDateRestrictions from "~brokerage/components/settings/components/DefaultTimeDateRestrictions";

const DefaultDateAndTimeRestrictions = props => {
  const history = useHistory();

  return (
    <>
      <Button
        className={css.backButton}
        variant="link"
        onClick={() => history.replace("/settings/system")}
      >
        <Icon name="arrowLeft" modifier="offset5 valignTextDefault" />
        Back
      </Button>
      <Label className ={css.timeDateHeading}> Default Time/Date Restrictions </Label>
      <DefaultTimeDateRestrictions />
    </>
  );
};

export default DefaultDateAndTimeRestrictions;
