import React from "react";
import css from "./index.sass";
import { buildTableTitles } from "../utils";

const TableHeader = ({
  location: { query },
  hideEmail,
  hidePhone,
  isImpersonating,
  isBrokerageTabOpenedByBroker
}) => {
  const titles = buildTableTitles({
    query,
    isImpersonating,
    isBrokerageTabOpenedByBroker,
    hideEmail,
    hidePhone
  });
  return (
    <thead>
      <tr className={css.header}>
        {titles.map(title => (
          <th>{title}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
