import css from './PagesList.sass'
import React, { Component } from 'react'
import NavigationLink from './NavigationLink'
export default class PagesList extends Component {
  render() {
    return (
      <div className={css.base}>
        {/*<NavigationLink to="/settings/brokerage"*/}
                        {/*icon="building"*/}
                        {/*label="Brokerage Settings"*/}
                        {/*index={true}/>*/}

        <NavigationLink to="/settings/brokerage/users"
                        icon="userMultiple"
                        label="Users"
                        parent={true}/>

        <NavigationLink to="/settings/brokerage/users/add"
                        icon="userAdd"
                        label="Add Users"
                        nested={true}/>

        {/*<NavigationLink to="/settings/brokerage/users/administrators"*/}
                        {/*icon="user"*/}
                        {/*label="Administrators"*/}
                        {/*nested={true}/>*/}

        {/*<NavigationLink to="/settings/brokerage/users/agents"*/}
                        {/*icon="user"*/}
                        {/*label="Agents"*/}
                        {/*nested={true}/>*/}

        <NavigationLink to="/settings/brokerage/users/assistants"
                        icon="user"
                        label="Assistants"
                        nested={true}/>

        {/*<NavigationLink to="/settings/brokerage/users/showingCoordinator"*/}
                        {/*icon="user"*/}
                        {/*label="Showing Coordinator"*/}
                        {/*nested={true}/>*/}

        {/*<NavigationLink to="/settings/brokerage/billing"*/}
                        {/*icon="dollar"*/}
                        {/*label="Billing"/>*/}
      </div>
    )
  }
}
