import css from "./index.sass";
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-modal";

import ShowingProvider from "~brokerage/components/shared/Timeline/ShowingProvider";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { callApi } from "~brokerage/middlewares/api";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";

import Body from "./Body";
import SubHeading from "./SubHeading";
import Footer from "./Footer";

import {
  customModalStyles,
  steps,
  handleChangeAfterFetchShowing
} from "./utils";

import { DEFAULT_STEP } from "./constants";

const AppointmentEditTimeModalBody = ({
  showingId,
  closeModal,
  page = DEFAULT_STEP,
  canEditDate = true,
  refreshCallback = () => {},
  rescheduleAndApproveShowing = false
}) => {
  const defaultPage = ["date", "time"].includes(page) && page;

  const [currentStep, setCurrentStep] = useState(defaultPage || DEFAULT_STEP);
  const [showListingRestrictions, setShowListingRestrictions] = useState(true);
  const [showingData, setShowingData] = useState({});
  const [loadingShowing, setLoadingShowing] = useState(true);
  const [loadingRestrictions, setLoadingRestrictions] = useState(true);
  const [staticAppointments, setStaticAppointments] = useState([]);
  const [showingAccess, setShowingAccess] = useState({});

  const { times, setTimes } = useContext(ShowingContext);
  const { appointment_reason: appointmentReason } = showingData;

  useEffect(() => fetchShowing(), []);

  const fetchLocalShowings = async date => {
    const {
      data: { showings }
    } = await callApi(
      "showings",
      {
        date: moment(date).format("DD-MM-YYYY"),
        limit: 100
      },
      {},
      "get"
    );
    return showings;
  };

  const handleChange = async ({
    date,
    duration,
    id = showingData.unique_id
  }) => {
    const update = [...times];
    const hasDayChanged =
      update[0] && !moment(update[0].startTime).isSame(date, "day");
    //if times[0] doesn't exist, it's being created right now and date isSame as date
    const dateInitorUpdated = !update[0] || hasDayChanged;

    // Set initial time to 10am when date changes
    const newDate = inUsersTimezone(date);
    if (hasDayChanged) {
      newDate.set({ hour: 10 });
    }
    update[0] = {
      ...update[0],
      id,
      position: 0
    };
    if (dateInitorUpdated) update[0].startTime = newDate.format();
    if (duration) update[0].duration = duration;
    setTimes(update);
    if (dateInitorUpdated) {
      fetchLocalShowings(update[0].startTime)
        .then(showings => {
          setStaticAppointments(showings.filter(s => s.id !== showingId));
        })
        .catch(() => {
          toast.error("Failed to get appointments.");
        })
        .finally(() => {
          setLoadingRestrictions(false);
        });
    }
  };

  const fetchShowing = async () => {
    try {
      const { data } = await callApi(
        `appointment_details/${showingId}`,
        {},
        {},
        "get"
      );
      setShowingData(data);
      setShowingAccess(data.local_details);
      handleChangeAfterFetchShowing({ data, handleChange });
    } catch (error) {
      toast.error(
        `Could not find a showing ${showingId} where you are listing/co-listing agent.`
      );
      closeModal();
    } finally {
      setLoadingShowing(false);
    }
  };

  if (loadingShowing) {
    return <></>;
  }

  return (
    <Modal
      isOpen={showingId !== null}
      onRequestClose={closeModal}
      style={customModalStyles}
    >
      <div className={css.heading}>
        {steps[currentStep].heading(rescheduleAndApproveShowing)}
      </div>
      <SubHeading
        step={currentStep}
        showListingRestrictions={showListingRestrictions}
        setShowListingRestrictions={setShowListingRestrictions}
      />
      <Body
        currentStep={currentStep}
        showingData={showingData}
        times={times}
        showListingRestrictions={showListingRestrictions}
        staticAppointments={staticAppointments}
        loadingRestrictions={loadingRestrictions}
        handleChange={handleChange}
        closeModal={closeModal}
        rescheduleAndApproveShowing={rescheduleAndApproveShowing}
        setShowingAccess={setShowingAccess}
        showingAccess={showingAccess}
        appointmentReason={appointmentReason}
      />

      <Footer
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        showingId={showingId}
        closeModal={closeModal}
        canEditDate={canEditDate}
        refreshCallback={refreshCallback}
        rescheduleAndApproveShowing={rescheduleAndApproveShowing}
        showingAccess={showingAccess}
      />
    </Modal>
  );
};

const AppointmentEditTimeModal = props => {
  return (
    //We need the provider for the Timeline component on the edit time page
    <ShowingProvider>
      <AppointmentEditTimeModalBody {...props} />
    </ShowingProvider>
  );
};

export default AppointmentEditTimeModal;
