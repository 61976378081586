import css from './Td.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

export default class Td extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    modifier: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { children, variant = 'base', modifier, ...rest } = this.props

    return (
      <td className={composeClassName(css, variant, modifier)} {...rest}>
        {children}
      </td>
    )
  }
}
