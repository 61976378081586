import { CALL_API } from "~brokerage/middlewares/api";

export function fetchUserSurveys(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "surveys"
    }
  };
}

export function fetchUserSurvey(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `surveys/${id}`
    }
  };
}
export function fetchDefaultSurveySettings(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "survey_settings"
    }
  };
}

export function updateDefaultSurveySettings(payload, types) {
  return {
    [CALL_API]: {
      types,
      method: "patch",
      endpoint: "survey_settings",
      data: payload
    }
  };
}
