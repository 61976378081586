import React, { useState } from "react";
import Modal from "react-modal";
import { callApi } from "~brokerage/middlewares/api";
import AppointmentStatusModal from "../AppointmentStatusModal";
import AppointmentEditTimeModal from "../AppointmentEditTimeModal";
import AppointmentEditTeamInstructionsModal from "../AppointmentEditTeamInstructionsModal";

import ModalContents from "./ModalContents";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "600px",
    minWidth: "500px",
    maxHeight: "80%",
    minHeight: "50%",
    padding: "0px",
    overflow: "hidden"
  },
  overlay: { zIndex: 100 } //The popover is at 99
};

const AppointmentDetails = ({
  info,
  shownDetailsId,
  setShownDetailsId,
  updateCallback = () => {}
}) => {
  const data = info === undefined ? {} : info.data;

  const [loading, setLoading] = useState(true);

  const [editTimeModalOpen, setEditTimeModalOpen] = useState(false);

  const [newStatus, setNewStatus] = useState("approved");

  const [editStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [editDetailModalOpen, setEditDetailModalOpen] = useState(false);
  const [editTeamInstructionsModalOpen, setEditTeamInstructionsModalOpen] =
    useState(false);
  const [isListingAgentPreapproving, setIsListingAgentPreapproving] =
    useState(false);

  const [details, setDetails] = useState({});

  const getDetails = () => {
    !loading && setLoading(true);
    callApi(`appointment_details/${shownDetailsId}`, {}, {}, "get").then(
      response => {
        setDetails(response.data);
        setLoading(false);
      }
    );
  };

  const closeModal = () => {
    setShownDetailsId(null);
    setEditStatusModalOpen(false);
    setEditTimeModalOpen(false);
    setEditDetailModalOpen(false);
    setEditTeamInstructionsModalOpen(false);
  };

  const changeDetails = async update_params => {
    await callApi(
      `appointment_details/${shownDetailsId}`,
      {},
      { update_params: update_params },
      "put"
    ).then(response => {
      setDetails(response.data);
    });
  };

  const updateLocalDetails = changes => {
    setDetails({
      ...details,
      local_details: { ...details.local_details, ...changes }
    });
    updateCallback();
  };

  const updateDetails = changes => {
    setDetails({
      ...details,
      ...changes
    });
  };

  const openStatusModal = newStatus => {
    setNewStatus(newStatus);
    setEditStatusModalOpen(true);
  };

  const openDateTimeModal = () => {
    setEditTimeModalOpen(true);
  };

  const openEditDetailModal = () => {
    setNewStatus("approved");
    setEditDetailModalOpen(true);
  };

  const openEditTeamInstructionsModal = () => {
    setEditTeamInstructionsModalOpen(true);
  };

  Modal.setAppElement(document.body);

  const refreshCallback = () => {
    getDetails();
    updateCallback();
  };

  return (
    <Modal
      isOpen={shownDetailsId !== null}
      onAfterOpen={getDetails}
      onRequestClose={closeModal}
      style={customStyles}
    >
      {editStatusModalOpen && (
        <AppointmentStatusModal
          showingId={details.unique_id}
          newStatus={newStatus}
          closeModal={() => setEditStatusModalOpen(false)}
          refreshCallback={refreshCallback}
        />
      )}
      {editTimeModalOpen && (
        <AppointmentEditTimeModal
          showingId={details.unique_id}
          closeModal={() => setEditTimeModalOpen(false)}
          refreshCallback={refreshCallback}
          page={details.route?.route_id ? "time" : "date"}
          canEditDate={!details.route?.route_id}
          rescheduleAndApproveShowing={isListingAgentPreapproving}
        />
      )}
      {editDetailModalOpen && (
        <AppointmentStatusModal
          showingId={details.unique_id}
          newStatus={newStatus}
          closeModal={() => setEditDetailModalOpen(false)}
          refreshCallback={refreshCallback}
          mode="edit"
        />
      )}
      {editTeamInstructionsModalOpen && (
        <AppointmentEditTeamInstructionsModal
          showingId={details.unique_id}
          handleSave={changeDetails}
          message={details.local_details?.team_message}
          closeModal={() => setEditTeamInstructionsModalOpen(false)}
        />
      )}
      <ModalContents
        loading={loading}
        details={details}
        updateDetails={updateDetails}
        failoverDetails={data}
        changeDetails={changeDetails}
        openStatusModal={openStatusModal}
        openDateTimeModal={openDateTimeModal}
        openEditDetailModal={openEditDetailModal}
        openEditTeamInstructionsModal={openEditTeamInstructionsModal}
        closeModal={closeModal}
        updateLocalDetails={updateLocalDetails}
        setIsListingAgentPreapproving={setIsListingAgentPreapproving}
      />
    </Modal>
  );
};

export default AppointmentDetails;
