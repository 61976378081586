export const showingsToDisplay = route => {
  if (route.showings) {
    const allShowings = route.showings.concat(route.stops);
    allShowings.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    return allShowings;
  } else return [];
};

export const reorderShowings = (showings, startShowing) => {
  return [...showings].sort((x, y) => {
    return x.unique_id === startShowing
      ? -1
      : y.unique_id === startShowing
      ? 1
      : 0;
  });
};

export const sortShowingsBasedOnWaypoints = (showings, showingOrder, legs) => {
  const travelTimes = [0, ...legs.map(l => (l.duration / 60).toFixed())];
  const orderedShowings = new Array(showingOrder.length);

  showings.forEach((showing, index) => {
    const pos = showingOrder[index];

    showing.travelTime = travelTimes[pos];
    orderedShowings[pos] = showing;
  });

  return orderedShowings;
};

export const buildShowingChanges = ({
  showing: { id, unique_id, duration, travelTime },
  isStop,
  nextShowingStartTime,
  index,
  conflict
}) => {
  return {
    id: isStop ? id : unique_id,
    duration,
    travelTime,
    requestedTimeRaw: nextShowingStartTime.format(),
    position: index,
    conflict
  };
};
