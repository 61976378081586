import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pick } from "lodash";

import Switch from "~brokerage/components/shared/Switch";
import css from "./ListHeader.sass";

const ArchiveToggle = ({ location }) => {
  const history = useHistory();
  const { category } = pick(location.query, ["category"]);

  const [showArchive, setShowArchive] = useState(false);
  const showToggle = ["seller_tenant", "buyer"].includes(category);

  useEffect(() => {
    const newQuery = { ...location.query };
    if (showToggle) {
      newQuery.is_archive = showArchive;
    } else {
      delete newQuery.is_archive;
    }

    history.push({
      pathname: location.pathname,
      query: newQuery
    });
  }, [showArchive, category]);

  useEffect(() => {
    setShowArchive(false);
  }, [category]);

  if (!showToggle) return null;

  return (
    <div className={css.archiveToggle}>
      <span className={css.label}>Show Archived</span>
      <Switch
        name="showArchive"
        checked={showArchive}
        onChange={() => setShowArchive(!showArchive)}
      />
    </div>
  );
};

export default ArchiveToggle;
