import css from './IconedLabel.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'

export default class IconedLabel extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const { icon, label, children, ...rest } = this.props

    return (
      <div {...rest} >
        <i className={css.i}><Icon name={icon}/></i>
        {label || children}
      </div>
    )
  }
}
