import css from "./ListItem.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { timeAgo } from "~brokerage/libs/helpers/TimeHelper";
import {
  extractStreetFromFullAddress,
  composeStatusChangedMessage
} from "~brokerage/libs/helpers/ShowingHelper";
import { EVENT_TYPE, SYSTEM_TYPE } from "~brokerage/constants/messages";
import {
  STATUS_FEEDBACK_RECEIVED,
  STATUS_FEEDBACK_REQUESTED
} from "~brokerage/constants/messages/showingStatuses";
import Avatar from "~brokerage/components/shared/Avatar";
import Dot from "~brokerage/components/shared/Dot";
import Highlighter from "react-highlight-words";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";

export default class ListItem extends React.PureComponent {
  static propTypes = {
    message: PropTypes.shape({
      forRole: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      updatedAt: PropTypes.string,
      type: PropTypes.string,
      hasRead: PropTypes.bool,
      isLastMessageFromMe: PropTypes.bool,
      body: PropTypes.string,
      address: PropTypes.string,
      interlocutorName: PropTypes.string,
      interlocutorAvatarName: PropTypes.string,
      senderName: PropTypes.string,
      interlocutorPhotoUrl: PropTypes.string,
      messageType: PropTypes.string,
      status: PropTypes.string
    }),
    currentType: PropTypes.string,
    active: PropTypes.bool,
    isSearching: PropTypes.bool,
    searchTerm: PropTypes.string
  };

  address() {
    const { message, isSearching, searchTerm } = this.props;

    if (message.address) {
      const streetAddress = extractStreetFromFullAddress(message.address);
      return (
        <div className={css.address}>
          {isSearching && searchTerm.length > 2 ? (
            <Highlighter
              highlightClassName="ListItem"
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={streetAddress}
            />
          ) : (
            streetAddress
          )}
        </div>
      );
    } else {
      return <div className={css.address}></div>;
    }
  }

  readUnread() {
    return (
      <div className={css.readUnread}>
        {(() => {
          if (this.props.message.hasRead) {
            return <Dot variant="read" />;
          }

          return <Dot variant="unread" />;
        })()}
      </div>
    );
  }

  agent() {
    const { message, isSearching, searchTerm } = this.props;

    return (
      <div className={message.address ? css.agent : css.agentExpand}>
        {isSearching && searchTerm.length > 2 ? (
          <Highlighter
            highlightClassName="ListItem"
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={message.interlocutorName}
          />
        ) : (
          message.interlocutorName
        )}
      </div>
    );
  }

  messageType() {
    return <span className={css.messageType}>{this.props.message.type}</span>;
  }

  updatedAt() {
    return (
      <div className={css.updatedAt}>
        {timeAgo(this.props.message.updatedAt)}
      </div>
    );
  }

  body() {
    const { message } = this.props;
    const {
      isLastMessageFromMe: isFromMe,
      messageType,
      status,
      senderName
    } = message;
    let { body } = message;

    if (messageType === EVENT_TYPE) return `Event: ${body}`;

    if (messageType === SYSTEM_TYPE && status)
      body = composeStatusChangedMessage(status, senderName);

    if (
      status === STATUS_FEEDBACK_RECEIVED ||
      status === STATUS_FEEDBACK_REQUESTED
    ) {
      return `Event: ${body}`;
    }

    const sender = isFromMe ? "Me" : senderName;
    return `${sender}: ${body}`;
  }

  render() {
    const { message, currentType, active } = this.props;
    const {
      forRole: role,
      type,
      interlocutorPhotoUrl: photoUrl,
      interlocutorAvatarName,
      id
    } = message;

    return (
      <Link
        to={{
          pathname: `/messages/${type}/${id}`,
          query: { type: currentType, role }
        }}
        className={active ? css.linkActive : css.link}
      >
        {this.updatedAt()}
        {this.readUnread()}
        <div className={css.conversationDetails}>
          <div className={css.avatar}>
            <Avatar src={photoUrl} name={interlocutorAvatarName} size="30" />
          </div>
          {this.agent()}
          {this.address()}
        </div>
        <div className={css.lastMessage}>
          <p className={css.lastMessageP}>{this.body()}</p>
        </div>
      </Link>
    );
  }
}
