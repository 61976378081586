import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Avatar from "~brokerage/components/shared/Avatar";

const MessageInner = ({ photoUrl, displayAvatar, senderName, children }) => (
  <div className={css.inner}>
    {displayAvatar && (
      <div className={css.avatar}>
        <Avatar size="30" src={photoUrl} name={senderName} />
      </div>
    )}
    {children}
  </div>
);

MessageInner.propTypes = {
  photoUrl: PropTypes.string,
  displayAvatar: PropTypes.bool,
  senderName: PropTypes.string,
  children: PropTypes.node
};

export default MessageInner;
