import css from './Tr.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Tr extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    even: PropTypes.bool,
    firstRow: PropTypes.bool,
    children: PropTypes.node
  }

  render() {
    const { children, variant, even, firstRow, ...rest } = this.props

    return (
      <tr className={css[variant] || css.base} {...rest}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            variant: child.props.variant || variant,
            modifier: `${child.props.modifier || ''} ${even ? 'even' : ''} ${firstRow ? 'firstRow' : ''}`
          })
        )}
      </tr>
    )
  }
}
