import css from "./ModalAddAppointments.sass";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Row, Label } from "~brokerage/components/shared/Form";
import { setSelectedProperties } from "~brokerage/actions/propertySearch";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import BuyerSection from "~brokerage/components/modals/ModalCreateShowing/steps/SendRequest/BuyerSection";
import NewBuyerModal from "~brokerage/components/modals/ModalCreateShowing/NewBuyerModal.jsx";
import BuyerBrokerAgreement from "~brokerage/components/shared/BuyerBrokerAgreement";
import {
  buildAddAppointmentsPayload,
  buildBuyerOptions,
  hasListingThatRequireBuyers
} from "./utils";
import {
  addAppointments,
  clearRequest,
  fetchShowingRoute
} from "~brokerage/actions/routes";
import { isEmpty } from "lodash";
import moment from "moment";
import { callApi } from "~brokerage/middlewares/api";
import { getUnavailableListings } from "./ModalCreateRoute/steps/util";
import { isWithinAdvanceNoticeTime } from "./ModalCreateRoute/util";
import AppointmentList from "./ModalCreateRoute/AppointmentList";

const ModalAddAppointments = ({
  isFetching,
  message,
  errors,
  route,
  routeId,
  selected,
  dispatch,
  location,
  mlsBuyerRequiredEnabled,
  mlsBuyerBrokerAgreementEnabled
}) => {
  const history = useHistory();
  let { params } = useRouteMatch("/my_showings/:id");
  const date =
    route?.date ?? undefined
      ? moment(route.date).format("YYYY-MM-DD")
      : undefined;

  const [buyers, setBuyers] = useState([]);
  const [unavailableListings, setUnavailableListings] = useState([]);
  const [advanceNotices, setAdvanceNotices] = useState([]);
  const [addingNewBuyer, setAddingNewBuyer] = useState(false);

  const fetchListingRestrictions = async date => {
    try {
      const listings = selected.map(s => s.id).join(",");
      const { data } = await callApi(
        "listings/find_availability",
        { listings, date },
        {},
        "get"
      );

      if (data.listings) {
        handleRestrictions(data.listings);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRestrictions = restrictions => {
    const selectedTime = moment(route.date);
    const unavailableListings = getUnavailableListings(selected, restrictions);
    setUnavailableListings(unavailableListings);

    const notices = selected.reduce((acc, s) => {
      acc[s.id] = isWithinAdvanceNoticeTime(s.id, selectedTime, restrictions);
      return acc;
    }, {});

    setAdvanceNotices(notices);
  };

  useEffect(() => {
    if (date) fetchListingRestrictions(date);
  }, [date]);

  useEffect(() => {
    if (!route || route.id !== params.id)
      dispatch(fetchShowingRoute(params.id));
    return () => dispatch(clearRequest());
  }, []);

  useEffect(() => {
    !isFetching && errors && toast.error(errors);
  }, [isFetching, errors]);

  useEffect(() => {
    if (!isFetching && message) {
      toast.success(message);
      dispatch(setSelectedProperties([]));
      history.push({
        pathname: `/my_showings/${routeId}/schedule`
      });
    }
  }, [isFetching, message]);

  useEffect(() => {
    if (route?.buyer_contacts)
      setBuyers(buildBuyerOptions(route.buyer_contacts));
  }, [route?.buyer_contacts]);

  const handleSubmit = () => {
    const payload = buildAddAppointmentsPayload(selected, buyers);
    dispatch(addAppointments(params.id, payload));
  };

  const closeModalWindow = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: void 0 }
    });
  };

  const handleRemoveListing = id => {
    const updateState = (state, filterFn) => state.filter(filterFn);

    dispatch(setSelectedProperties(updateState(selected, l => l.id !== id)));
    setAdvanceNotices(prev => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });

    setUnavailableListings(prev => updateState(prev, listId => listId !== id));
  };

  if (!route) return <div />;

  const requireBuyers =
    isEmpty(buyers) &&
    hasListingThatRequireBuyers(selected, mlsBuyerRequiredEnabled);
  const noAppointmentsSelected = isEmpty(selected);
  const hasUnavailableListings = !isEmpty(unavailableListings);
  const hasAdvanceNotice = Object.values(advanceNotices).includes(true);

  const disableAddAppointmentsButton =
    requireBuyers ||
    isFetching ||
    noAppointmentsSelected ||
    hasUnavailableListings ||
    hasAdvanceNotice;

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title="Add Appointments" />
        <Body>
          <Row offset="10">
            <Label variant="vertical">Route: {route.name}</Label>
            <Label variant="vertical">
              Date: {inUsersTimezone(route.date).format("dddd, MMMM D, YYYY")}
            </Label>
            <Label variant="vertical">
              Start: {inUsersTimezone(route.date).format("LT")}
            </Label>
          </Row>
          <Row>
            <BuyerSection
              buyers={buyers}
              setBuyers={setBuyers}
              createBuyer={() => setAddingNewBuyer(true)}
            />
          </Row>
          <Row>
            <AppointmentList
              appointments={selected}
              unavailableListings={unavailableListings}
              advanceNotices={advanceNotices}
              mlsBuyerRequiredEnabled={mlsBuyerRequiredEnabled}
              buyers={buyers}
              handleRemoveListing={handleRemoveListing}
            />
          </Row>
          {mlsBuyerBrokerAgreementEnabled && (
            <BuyerBrokerAgreement actionText="Add" path="route" />
          )}
        </Body>
        <Footer>
          <Button
            type="submit"
            float="right"
            variant="primary"
            disabled={disableAddAppointmentsButton}
          >
            Add
          </Button>
          <Button
            type="button"
            variant="newMessageCancel"
            onClick={closeModalWindow}
          >
            Cancel
          </Button>
        </Footer>
      </Form>
      <NewBuyerModal
        isOpen={addingNewBuyer}
        closeModal={() => setAddingNewBuyer(false)}
        onAddBuyer={newBuyer => setBuyers([...buyers, newBuyer])}
      />
    </div>
  );
};

function mapStateToProps({
  propertySearch,
  routes,
  currentUser: { mlsBuyerRequiredEnabled, mlsBuyerBrokerAgreementEnabled }
}) {
  const { listings: selected } = propertySearch.selected;
  const {
    isFetching,
    message,
    errors,
    entity: route,
    updatedRouteId
  } = routes.single.route;
  return {
    selected,
    isFetching,
    message,
    errors,
    route,
    routeId: updatedRouteId,
    mlsBuyerRequiredEnabled,
    mlsBuyerBrokerAgreementEnabled
  };
}
export default withRouter(connect(mapStateToProps)(ModalAddAppointments));
