import moment from "moment";

const CONFLICT_TYPES = {
  unavailable: "unavailable",
  warnOverlap: "warnOverlap",
  warnLaOverlap: "warnLaOverlap"
};

const getConflictType = ({
  isListingOwnedByCurrentUserOrOfficeTeam,
  allowOverlap = false
}) => {
  if (isListingOwnedByCurrentUserOrOfficeTeam)
    return CONFLICT_TYPES.warnLaOverlap;
  if (allowOverlap) return CONFLICT_TYPES.warnOverlap;
  return CONFLICT_TYPES.unavailable;
};

const determineConflicts = ({
  unavailability,
  nextDate,
  duration,
  showingId,
  allowOverlap,
  isListingOwnedByCurrentUserOrOfficeTeam
}) => {
  let conflict = null;
  const newStart = nextDate.clone().add(1, "minute");
  const newEnd = nextDate.clone().add(duration, "minutes");

  if (newStart.isBefore()) {
    return "passed";
  }

  unavailability.forEach(t => {
    if (
      newStart.isBetween(t.start, t.end) ||
      newEnd.isBetween(t.start, t.end) ||
      moment(t.start).isBetween(newStart, newEnd)
    ) {
      if (t.showingId) {
        if (t.showingId !== showingId) {
          conflict = getConflictType({
            allowOverlap,
            isListingOwnedByCurrentUserOrOfficeTeam
          });
          return;
        }
      } else {
        conflict = getConflictType({ isListingOwnedByCurrentUserOrOfficeTeam });
        return;
      }
    }
  });

  return conflict;
};

export default determineConflicts;
