import React, { useState, useEffect } from "react";
import css from "../index.sass";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import Modal from "react-modal";

import Button from "~brokerage/components/shared/Button";
import { Dropdown, Label } from "~brokerage/components/shared/Form";
import calculateFastestRoute from "../../util/calculateFastestRoute";
import { callApi } from "~brokerage/middlewares/api";

const CalculateRouteModal = ({
  isOpen,
  setIsOpen,
  selected,
  setSelected,
  selectableShowings,
  loading,
  setLoading,
  showings,
  optimized,
  startTime,
  mapboxkey,
  dispatch
}) => {
  const [restrictions, setRestrictions] = useState({});

  useEffect(() => {
    if (isOpen) fetchListingRestrictions();
  }, [isOpen]);

  const fetchListingRestrictions = async () => {
    if (selectableShowings.length) {
      try {
        const listings = selectableShowings
          .filter(showing => showing.type !== "stop")
          .map(s => s.listing_key)
          .join(",");
        const date = moment(startTime).format("YYYY-MM-DD");
        const { data } = await callApi(
          "listings/find_availability",
          { listings, date },
          {},
          "get"
        );
        setRestrictions(data.listings);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCalculate = () => {
    !loading &&
      calculateFastestRoute({
        showings: selectableShowings,
        startShowing: selected.unique_id,
        startTime,
        mapboxkey,
        dispatch,
        restrictions
      }).then(() => {
        setLoading(false);
        setIsOpen(false);
      });
  };

  const handleStartSelect = e => {
    const selShowing = showings.find(s => s.unique_id === e.value);
    setSelected(selShowing);
  };

  const alreadyOptimized = selected && optimized === selected.unique_id;

  return (
    <Modal
      isOpen={isOpen}
      className={css.modalContent}
      overlayClassName={css.modalOverlay}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className={css.modalHeader}>Calculate Fastest Route</div>
      <div className={css.modalBody}>
        <Label variant="vertical">
          Select the listing you wish to show first:
        </Label>
        <Dropdown
          title={selected ? selected.address : "Select a showing."}
          onChange={handleStartSelect}
          variant={"outline2Block"}
          selected={selected && selected.unique_id}
          options={selectableShowings.map(s => ({
            label: s.address,
            value: s.unique_id
          }))}
        />
        {alreadyOptimized && (
          <div className={css.modalError}>
            Route already optimized for this starting point.
          </div>
        )}
      </div>
      <div className={css.modalFooter}>
        <Button variant="outline" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={loading || alreadyOptimized}
          variant="primary"
          onClick={handleCalculate}
        >
          Calculate Route
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ keys }) => {
  const { mapboxkey } = keys;
  return { mapboxkey };
};

export default withRouter(connect(mapStateToProps)(CalculateRouteModal));
