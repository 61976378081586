import { CALL_API } from '~brokerage/middlewares/api'

export function fetchShowings(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'showings',
      params: params
    }
  }
}

export function updateTeamInstructions(showingId, data, types) {
  return {
    [CALL_API]: {
      types,
      method: 'put',
      endpoint: `showings/${showingId}/team`,
      data
    }
  }
}

export function fetchAgents(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/agents_filter`
    }
  }
}

export function scrollShowingChatThread(id, filter, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}/messages`,
      params: filter
    }
  }
}

export function fetchShowing(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}`
    }
  }
}

export function changeShowing(id, changes, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      data: changes,
      endpoint: `showings/${id}`
    }
  }
}

export function fetchShowingMessages(id, role, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}/messages?role=${role}`
    }
  }
}

export function createMessage(showing_id, message, attachments, role, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showing_id}/messages?role=${role}`,
      data: {
        showing_message: {
          msg: message,
          attachment_ids: attachments.attachmentIds,
          listing_id: attachments.listingId
        }
      }
    }
  }
}

export function createNoteMessage(showing_id, message, attachments, role, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showing_id}/note_messages?role=${role}`,
      data: {
        showing_message: {
          msg: message,
          attachment_ids: attachments.attachmentIds,
          listing_id: attachments.listingId
        }
      }
    }
  }
}

export function logCall(showing_id, message, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showing_id}/log_calls`,
      data: {
        showing_message: {
          msg: message
        }
      }
    }
  }
}

export function fetchPubnubData(id, role, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}/pubnub`,
      params: {
        role
      }
    }
  }
}

export function fetchShowingListingTeamMembers(showingId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${showingId}/team_members`,
      params: { team_type: 'listing_team' }
    }
  }
}

export function fetchShowingBuyerTeamMembers(showingId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${showingId}/team_members`,
      params: { team_type: 'buyer_team' }
    }
  }
}

export function updateShowingInstructions(showingId, role, payload, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      endpoint: `showings/${showingId}/showing_instructions`,
      params: { role },
      data: { instructions: payload }
    }
  }
}

export function sendRequest(showingId, payload, types) {
  return {
    [CALL_API]: {
      types,
      method: 'put',
      endpoint: `showings/${showingId}/send_request`,
      data: payload
    }
  }
}

export function createShowing(payload, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'showings',
      method: 'post',
      data: payload
    }
  }
}

export function updateShowing(id, payload, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}`,
      method: 'put',
      data: payload
    }
  }
}

export function fetchSellerTenantInvolvement(id, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${id}/contacts`
    }
  }
}
