import BaseMessageHandler, {
  MESSAGES,
  SHOWINGS,
  SHOWING
} from './BaseMessageHandler'
import { showingChangedByMessage, addMessageFromPubnub } from '~brokerage/actions/showings'


export default class ShowingMessageHandler extends BaseMessageHandler {
  handle() {
    if (this.isShowingPageOpened()) {
      this.handleShowingsPageMessage()
    }

    this.props.dispatch(showingChangedByMessage(this.message))

    if (this.isShowingChatOpened()) {
      this.handleMessagesPageMessage(SHOWING)
    }

    super.handle()
  }

  isMessageRead() {
    return this.isShowingChatOpened() || this.isShowingPageOpened()
  }

  isShowingPageOpened() {
    return this.path[1] === SHOWINGS && this.path[2] == this.message.conversationId
  }

  isShowingChatOpened() {
    return this.path[1] === MESSAGES && this.path[2] === SHOWING && this.path[3] == this.message.conversationId
  }

  handleShowingsPageMessage() {
    this.props.dispatch(addMessageFromPubnub(this.message))
    this.markConversationAsRead()
  }
}
