import React, { Component } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import Autocomplete from '~brokerage/components/shared/Autocomplete'

export default class RecipientAutocomplete extends React.PureComponent {
  static propTypes = {
    people: PropTypes.array,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    enabled: PropTypes.bool
  }

  state = {
    currentValue: '',
    options: this.options()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.people !== nextProps.people) {
      this.setState({
        options: this.options(nextProps.people)
      })
    }
  }

  handleChange = (event) => {
    const value = this.props.enabled ? event.target.value : ''

    this.setState({
      currentValue: value
    })

    this.props.onChange(value)
  }

  handleSelect = (option) => {
    this.props.onSelect(option)

    this.setState({
      currentValue: ''
    })
  }

  options(people) {
    if (people && people.length) {
      return people.map(({userId, name}) => ({value: userId, label: name}))
    } else {
      return []
    }
  }

  render() {
    const { currentValue, options } = this.state

    return (
      <div>
        <Autocomplete
          value={currentValue}
          options={options}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          style={{ border: 'none', height: 'auto', padding: '0', width: 'auto' }}
          variant="inModalWindow"
          height={150}
          scrollable
          {...omit(this.props, Object.keys(RecipientAutocomplete.propTypes))}
        />
      </div>
    )
  }
}
