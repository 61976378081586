import css from './Item.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Avatar from '~brokerage/components/shared/Avatar'
import Icon from '~brokerage/components/shared/Icon'
import Tooltip from '~brokerage/components/shared/Tooltip'
import Button from '~brokerage/components/shared/Button'
import PremiumButtonWrapper, { PREMIUM_FEATURE_TYPES } from '~brokerage/components/shared/PremiumButtonWrapper'
import { withRouter } from 'react-router-dom'

class Item extends React.PureComponent {
  static propTypes = {
    agent: PropTypes.object,
    conversationType: PropTypes.string,
    onRemoveClick: PropTypes.func,
    location: PropTypes.object
  };

  renderContactBtn(agent) {
    const { conversationType, location } = this.props;

    return (
      <Button
        to={{
          ...location,
          query: {
            ...location.query,
            modal: "agent_details",
            agent_id: agent.userId,
            conversation_type: conversationType
          }
        }}
        variant="outline"
        modifier="colorWhite"
      >
        Contact
      </Button>
    );
  }

  renderRemoveAgentButton() {
    const { onRemoveClick } = this.props;

    if (onRemoveClick) {
      return (
        <PremiumButtonWrapper
          featureType={PREMIUM_FEATURE_TYPES.REMOVE_TEAMMATES_FROM_CHAT}
        >
          <a className={css.remove} onClick={onRemoveClick}>
            <Icon name="close" />
          </a>
        </PremiumButtonWrapper>
      );
    }
  }

  render() {
    const { onRemoveClick, agent, isAgentRemovable } = this.props;

    let tooltipText = `${agent.name}`;
    if (agent.role) {
      tooltipText += ` — ${agent.role}`;
    }

    let tooltipAdditional;
    if (agent.phone) {
      tooltipAdditional = [
        { icon: "phone", url: `tel:${agent.phone}`, text: agent.phone }
      ];
    }

    return (
      <div className={classNames(css.base, (onRemoveClick && isAgentRemovable) && css.hasRemove)}>
        <Tooltip
          text={tooltipText}
          additional={tooltipAdditional}
          footer={this.renderContactBtn(agent)}
        >
          <div className={css.inner}>
            <Avatar
              size="30"
              src={agent.photoUrl}
              name={[agent.firstName, agent.lastName].join(" ")}
            />
            {isAgentRemovable && this.renderRemoveAgentButton()}
          </div>
        </Tooltip>
      </div>
    );
  }
}
export default withRouter(Item);