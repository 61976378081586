import React, { useState } from "react";

import Link from "~brokerage/components/shared/Link";
import Icon from "~brokerage/components/shared/Icon";
import AppointmentEditTimeModal from "~brokerage/app/components/shared/AppointmentEditTimeModal";

export const ShowingEditRequestedTimeLink = ({
  showingId,
  onChange,
  hasRoute
}) => {
  const [editTimeModalOpen, setEditTimeModalOpen] = useState(false);

  return (
    <>
      {editTimeModalOpen && (
        <AppointmentEditTimeModal
          showingId={showingId}
          closeModal={() => setEditTimeModalOpen(false)}
          page={hasRoute ? "time" : "date"}
          canEditDate={!hasRoute}
          refreshCallback={onChange}
        />
      )}
      <Link onClick={() => setEditTimeModalOpen(true)}>
        <Icon name="edit" /> Reschedule
      </Link>
    </>
  );
};
