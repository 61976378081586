import css from './add.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchBrokerageUsers, inviteBrokerageUser, removeInvitedBrokerageUser } from '~brokerage/actions/settings'
import { USER_ROLE_OPTIONS } from '~brokerage/constants/settings/userRoleOptions'
import { labelFromValue } from '~brokerage/libs/helpers/SelectOptionsHelper'
import { filterUnregisteredAgents } from '~brokerage/libs/helpers/inviteUserHelper'
import InvitedUser from '~brokerage/components/shared/InvitedUser'
import UnregisteredAgent from '~brokerage/components/settings/brokerage/UnregisteredAgent'
import Button from '~brokerage/components/shared/Button'
import Link from '~brokerage/components/shared/Link'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Search from '~brokerage/components/shared/Search'
import filter from 'lodash/filter'
import values from 'lodash/values'

class Add extends React.PureComponent {
  AGENT_ROLE = 'agent'

  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    invitedUsers: PropTypes.object,
    unregisteredAgents: PropTypes.object
  }

  constructor() {
    super()
    this.state = {filter: ''}
  }

  componentWillMount() {
    this.props.dispatch(fetchBrokerageUsers())
  }

  inviteAgent = (agentId) => {
    this.props.dispatch(inviteBrokerageUser({ agentId: agentId }))
  }

  editUser = (id) => {
    const { location, history } = this.props
    history.push({...location, query: { modal: 'invite_user', user_id: id } })
  }

  removeUser = (id) => {
    this.props.dispatch(removeInvitedBrokerageUser(id)).then(
      () => this.props.dispatch(fetchBrokerageUsers())
    )
  }

  handleFilterChange = (event) => this.setState({filter: event.target.value})

  filteredUnregisteredAgents() {
    return filterUnregisteredAgents(this.props.unregisteredAgents, this.state.filter)
  }

  invitedAgentUsers() {
    return filter(this.props.invitedUsers, (user) => user.role === this.AGENT_ROLE)
  }

  invitedStaffUsers() {
    return filter(this.props.invitedUsers, (user) => user.role !== this.AGENT_ROLE)
  }

  renderInvitedAgentUsersSection() {
    const users = this.invitedAgentUsers()
    return users.length ? (
      <section className={css.section}>
        <h2 className={css.h2}>Invited Agents</h2>
        {users.map(user => {
          return <InvitedUser id={user.id}
                              key={user.id}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              description={user.city}
                              onRemove={this.removeUser}/>
        })}
      </section>
    ) : null
  }

  renderInvitedStaffUsersSection() {
    const users = this.invitedStaffUsers()
    return <section className={css.section}>
      <h2 className={css.h2}>Invite Non-MLS User: Assistant, Broker, or Showing Coordinator</h2>
      {users.map(user => {
        return <InvitedUser id={user.id}
                            key={user.id}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            description={labelFromValue(USER_ROLE_OPTIONS, user.role)}
                            onRemove={this.removeUser}
                            onEdit={this.editUser}/>
      })}
      <div className={css.borderedBlock}>
        <div className={css.inner}>
          <Button variant="outline"
                  to={{ ...this.props.location, query: { modal: 'invite_user' } }}>
            <IconedLabel icon="userAdd" label="Invite Non-MLS User"/>
          </Button>
        </div>
      </div>
    </section>
  }

  renderUnregisteredAgentsSection() {
    return <section className={css.section}>
      <div>
        <div className={css.pullRight}>
          Missing Agents? <Link data-intercom-launcher="" href="#">Contact Us</Link>
        </div>
        <h2 className={css.h2}>
          {Object.keys(this.props.unregisteredAgents).length} Unregistered Agents in Your Brokerage
        </h2>
      </div>

      <div className={css.borderedBlock}>
        <div className={css.inner}>
          <div className={css.search}>
            <Search placeholder="Filter by Name or Office Location"
                    onChange={this.handleFilterChange}/>
          </div>
        </div>
      </div>
      {
        values(this.filteredUnregisteredAgents()).map(agent => {
          return <UnregisteredAgent id={agent.agentId}
                                    key={agent.agentId}
                                    firstName={agent.firstName || '-'}
                                    lastName={agent.lastName || ''}
                                    city={agent.city || '-'}
                                    onInvite={this.inviteAgent}/>
        })}
    </section>
  }

  render() {
    return (
      <div className={css.base}>
        {this.renderInvitedStaffUsersSection()}
        {this.renderInvitedAgentUsersSection()}
        {this.renderUnregisteredAgentsSection()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { invitedUsers, unregisteredAgents } = state.settings.brokerage

  return {
    invitedUsers,
    unregisteredAgents
  }
}
export default connect(mapStateToProps)(Add)
