import moment from "moment";
import { isEmpty } from "lodash";

export const getOpenSlots = schedule => {
  const openSlots = [];
  const fistAppt = schedule[0] && schedule[0][0];
  const lastAppt =
    schedule[schedule.length - 1] && schedule[schedule.length - 1][1];

  if (!schedule.length) {
    return ["00:00", "23:59"];
  }

  if (fistAppt !== "00:00") {
    openSlots.push(["00:00", fistAppt]);
  }

  schedule.forEach((curr, i) => {
    const prev = schedule[i - 1];
    const currStart = curr[0] && parseInt(curr[0].split(":").join(""), 10);
    const prevEnd =
      prev && prev[1] && parseInt(prev[1].split(":").join(""), 10);

    const isOverlap = currStart && currStart < prevEnd;

    if (isOverlap) {
      if(!isEmpty(openSlots)) {
        openSlots[openSlots.length - 1][1] = prev[0];
      }
      return;
    }

    const isTimeBetween = prev && prev[1] !== curr[0];

    if (i > 0 && isTimeBetween) {
      openSlots.push([prev[1], curr[0]]);
    }
  });

  if (lastAppt !== "23:59") {
    openSlots.push([lastAppt, "23:59"]);
  }

  return openSlots.filter((v, i, a) => a.indexOf(v) === i);
};

export const getUnavailableListings = (selected, restrictions = []) => {
  const unavailable = [];
  selected.forEach(l => {
    const key = l.id;
    let hasFullDayRestriction = false;
    if (restrictions[key]) {
      const { allowOverlap, unavailability } = restrictions[key];
      const times = [];
      const sorted = unavailability.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      sorted.forEach(r => {
        if (allowOverlap && r.showingId) return;
        const start = moment(r.start);
        const end = moment(r.end);
        const mins = end.diff(start, "minutes");
        if (mins / 60 > 23.9) {
          hasFullDayRestriction = true;
          return;
        } else {
          times.push([start.format("HH:mm"), end.format("HH:mm")]);
        }
      });

      if (hasFullDayRestriction) {
        unavailable.push(l.id);
      } else if (times.length) {
        const openSlots = getOpenSlots(times);
        let hasEligibleSlot = false;
        openSlots.forEach(slot => {
          const start = parseInt(slot[0].split(":").join(""), 10);
          const end = parseInt(slot[1].split(":").join(""), 10);
          const slotDuration = end - start;
          if (slotDuration >= 10) {
            hasEligibleSlot = true;
            return;
          }
        });
        if (!hasEligibleSlot) {
          unavailable.push(l.id);
        }
      }
    }
  });
  return unavailable;
};
