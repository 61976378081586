import css from "./ListItem.sass";
import React from "react";
import PropTypes from "prop-types";
import ListingSpecs from "./ListingSpecs";
import { withRouter } from "react-router-dom";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";
class ListItem extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    listing: PropTypes.shape({
      id: PropTypes.string,
      platformName: PropTypes.string,
      address: PropTypes.string,
      price: PropTypes.string,
      status: PropTypes.string,
      photoUrl: PropTypes.string,
      mlsNumber: PropTypes.string,
      specs: PropTypes.shape({
        beds: PropTypes.number,
        baths: PropTypes.number,
        sqft: PropTypes.number
      })
    })
  };

  render() {
    const { address, status, photoUrl, contingencyString } = this.props.listing;
    const photoClassName = photoUrl ? css.photo : css.noPhoto;

    return (
      <div className={css.base}>
        <PropertyThumbnail className={photoClassName} image={photoUrl} />
        <div>
          <div className={css.addressContainer}>
            <div className={css.address}>
              <span className={css.addressStatus}>{`${status}${contingencyString}`}</span>
              <h1 className={css.h1}>{address}</h1>
            </div>
          </div>
          <ListingSpecs listing={this.props.listing} />
        </div>
      </div>
    );
  }
}
export default withRouter(ListItem);
