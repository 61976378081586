import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closestTag } from '~brokerage/libs/helpers/DOMHelper'
import OldInterfaceIframe from '~brokerage/components/shared/OldInterfaceIframe'

class SelectRoute extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  onClick = (event) => {
    const link = closestTag(event.target, 'a')
    const url = link.getAttribute('href')
    const { history, match } = this.props

    if (url && url.includes(`/listings/${match.params.listingId}`)) {
      history.push({
        pathname: `/listings/${match.params.listingId}`
      })
      return true
    }
    return false
  }

  render() {
    const url = `/listings/${this.props.match.params.listingId}/select_route`

    return <OldInterfaceIframe url={url} onClick={this.onClick} location={this.props.location}/>
  }
}
export default connect()(SelectRoute)
