import React from "react";

import { Textarea, Row } from "~brokerage/components/shared/Form";

const AdditionalDetails = ({
  message,
  changeDetails,
  placeholder,
  isEditable = true
}) => {
  return (
    <Row offset="0">
      <Textarea
        disabled={!isEditable}
        variant="bigger"
        value={message || ""}
        placeholder={placeholder}
        onChange={e => {
          changeDetails({ message: e.target.value });
        }}
      />
    </Row>
  );
};

export default AdditionalDetails;
