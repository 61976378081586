import React from "react";
import moment from "moment";
import AppointmentsList from "brokerage/app/components/myShowings/AppointmentsList";
import PastAppointmentsList from "brokerage/app/components/myShowings/PastAppointmentsList";
import PropTypes from "prop-types";
import css from "./RouteNameAndDate.sass";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";

const RouteNameAndDate = ({ route, location, pastAppointment = false }) => (
  <div className={css.routeNameAndDateSection}>
    <label className={css.routeName}>{route.name}</label>
    <label className={css.dayLabel}>
      {inUsersTimezone(route.date).format("LLL")}
    </label>
    {pastAppointment ? (
      <PastAppointmentsList route={route} />
    ) : (
      <AppointmentsList route={route} location={location} />
    )}
  </div>
);

RouteNameAndDate.propTypes = {
  route: PropTypes.object.isRequired,
  pastAppointment: PropTypes.bool
};

export default RouteNameAndDate;
