export const DEFAULT_DROPDOWN_OPTION = {
  label: "All",
  value: "all"
};

export const ACTIVITY_REPORT_META_DATA_OPTIONS = {
  NAME_AND_BROKERAGE: "name_and_brokerage",
  NAME_AND_BROKERAGE_WITH_PHONE: "name_and_brokerage_with_phone",
  NO_DETAILS: "no_details"
};
