import { useState } from "react";
import { toast } from "react-toastify";
import { callApi } from "~brokerage/middlewares/api";
import { APPOINTMENT_STATUSES } from "./constants";
import { buildPayload } from "./utils";

const useAppointmentStatus = (initialStatus, showingId, callback) => {
  const [status, setStatus] = useState(initialStatus);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = ({ status }) => setStatus(APPOINTMENT_STATUSES[status]);

  const handleSave = () => {
    setIsUpdating(true);
    const payload = buildPayload(status);

    callApi(`showings/${showingId}/update_status`, {}, payload, "patch")
      .then(
        () => {
          toast.success("Status Updated.");
          callback();
        },
        error => {
          toast.error(error.response?.data?.error || "Error updating type.");
        }
      )
      .finally(() => setIsUpdating(false));
  };

  return { status, isUpdating, handleChange, handleSave };
};

export default useAppointmentStatus;
