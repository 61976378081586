import React from "react";
import css from "./shared.sass";
import PreferenceOption from "~brokerage/app/components/settings/components/PreferenceOption";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";

const EmailPreferences = ({ baChecked, laChecked, disableListingOptions }) => {
  return (
    <Row className={css.inputRow}>
      <Column
        className={composeClassName(css, "inputColumnCentered", "rowLabel")}
      >
        <InputLabel>Receive Emails</InputLabel>
      </Column>
      <Column className={css.prefsColumn}>
        <PreferenceOption
          disabled={disableListingOptions}
          className={css.checkBoxLabel}
          htmlFor="listing_email_notification_enabled"
          name="listing_email_notification_enabled"
          label="As a Listing Agent"
          defaultChecked={laChecked}
        />

        <PreferenceOption
          className={css.checkBoxLabel}
          name="buying_email_notification_enabled"
          label="As a Showing Agent"
          defaultChecked={baChecked}
        />
      </Column>
    </Row>
  );
};

export default EmailPreferences;
