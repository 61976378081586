import React from "react";
import {
  Row,
  Col,
  Label,
  Controls,
  Dropdown
} from "~brokerage/components/shared/Form";
import { buildMaxAppointmentLengthOptions } from "./utils";
import css from "./ShowingInstructions.sass";

const MaxAppointmentLength = ({ maxAppointmentDuration, handleChange }) => {
  const options = buildMaxAppointmentLengthOptions();

  return (
    <Row class={css.maximumAppointmentLength}>
      <div className={css.label}>
        <Label valign="dropdown">Maximum appointment length</Label>
      </div>
      <Controls>
        <Col>
          <Dropdown
            name="maxAppointmentDuration"
            options={options}
            selected={maxAppointmentDuration}
            onChange={handleChange}
            variant="outline2"
          />
        </Col>
      </Controls>
    </Row>
  );
};
export default MaxAppointmentLength;
