import css from './index.sass'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BROADCAST_CONVERSATION_TYPE } from '~brokerage/constants/messages'
import ConversationChat from './ConversationChatContainer'
import ErrorMessage from '~brokerage/components/shared/ErrorMessage'
import Sidebar from './SidebarContainer'
import Summary from './SummaryContainer'

export class ConversationScreen extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    errors: PropTypes.string
  }

  get isBroadcast() {
    return this.props.type === BROADCAST_CONVERSATION_TYPE
  }

  render() {
    const { type, id, errors, showOptionsPanel } = this.props
    const optionsPanelClassName = showOptionsPanel ? css.showOptions : css.sidebar

    if (errors) {
      return (
        <article className={css.base}>
          <ErrorMessage message={errors}/>
        </article>
      )
    }

    return (
      <article className={css.base}>
        <div className={this.isBroadcast ? '' : css.conversationWrapper}>
          <Summary id={id}/>
          <ConversationChat type={type} id={id}/>
        </div>
        {!this.isBroadcast &&
          <div className={optionsPanelClassName}>
            <Sidebar type={type} id={id}/>
          </div>
        }
      </article>
    )
  }
}

function mapStateToProps(state) {
  const { errors } = state.messages.single.conversationMessages

  return {
    errors
  }
}
export default connect(mapStateToProps)(ConversationScreen)
