import React, { useState, useEffect } from "react";
import { callApi } from "~brokerage/middlewares/api";

import ModalContents from "./ModalContents";
import { toast } from "react-toastify";

const defaultDetails = {
  message: null,
  requestACall: null,
  explanation: null
};

const toastMessages = {
  pending: "Updating Appointment",
  success: "Appointment Cancelled",
  error: "Sorry, Something went wrong"
};

const AppointmentCancel = ({
  showingId,
  closeModal,
  refreshCallback,
  setDisableModal,
  getInstructions,
  fromRoute
}) => {
  const [loading, setLoading] = useState(true);
  const [originalDetails, setOriginalDetails] = useState(defaultDetails);
  const [details, setDetails] = useState(defaultDetails);

  const pushData = {
    status: "cancelled",
    showing_response: {
      message: details.message || "",
      explanation: details.explanation
    }
  };

  const pushDetails = () => {
    setLoading(true);
    setDisableModal(true);
    toast
      .promise(
        callApi(`showings/${showingId}`, {}, pushData, "put"),
        toastMessages
      )
      .then(() => {
        refreshCallback();
      }) //onSuccess
      .catch(() => {
        setDisableModal(false);
      }) //onFailure
      .then(() => {
        setLoading(false);
        closeModal(true);
        setDisableModal(false);
      }); //onComplete
  };

  const changeDetails = update_params => {
    setDetails({ ...details, ...update_params });
  };

  const resetDetails = () => {
    setDetails(originalDetails);
  };

  useEffect(() => {
    getInstructions().then(response => {
      const { message: _, ...details } = response.data;
      setOriginalDetails(details);
      setDetails(details);
      setLoading(false);
    });
  }, []);

  return (
    <ModalContents
      loading={loading}
      details={details}
      changeDetails={changeDetails}
      pushDetails={pushDetails}
      resetDetails={resetDetails}
      closeModal={() => {
        closeModal(false);
      }}
      fromRoute={fromRoute}
    />
  );
};

export default AppointmentCancel;
