import React from "react";
import { Dropdown } from "~brokerage/components/shared/Form";
import { DROPDOWN_STATUSES } from "~brokerage/constants/showings/statuses";

const ShowingStatusDropdown = props => {
  const { currentStatus, handleChange } = props;

  const selectedStatus = DROPDOWN_STATUSES.find(
    status => status.value === currentStatus
  );

  const title = `${ selectedStatus ? selectedStatus.label : "Approved" } Appointments`;

  const selected = selectedStatus && selectedStatus.value;

  return (
    <Dropdown
      title={title}
      options={DROPDOWN_STATUSES}
      onChange={handleChange}
      variant={"sellerTenantAppointmentsList"}
      selected={selected}
    />
  );
};

export default ShowingStatusDropdown;
