import React from "react";

import css from "./index.sass";
import AppointmentHistory from "./AppointmentHistory";

const FooterSection = ({ id }) => {
  return (
    <div className={css.wrapper3}>
      <div className={css.header3}>Appointment History</div>
      <div className={css.historyContent}>
        <AppointmentHistory id={id} />
      </div>
    </div>
  );
};

export default FooterSection;
