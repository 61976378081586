import { combineReducers } from "redux";

import {
  PROPERTY_SEARCH_REQUEST,
  PROPERTY_SEARCH_SUCCESS,
  PROPERTY_SEARCH_FAILURE,
  PROPERTY_TOGGLE_SHOW_ALLOWED
} from "~brokerage/constants/actionTypes";

const defaultState = {
  entities: [],
  allowedOnly: true,
  total: 0,
  page: 1
};

function listings(state = defaultState, action) {
  switch (action.type) {
    case PROPERTY_SEARCH_REQUEST:
      return { ...state, isFetching: true };
    case PROPERTY_SEARCH_SUCCESS: {
      return {
        ...state,
        entities: action.data.listings,
        total: action.data.listingsCount,
        page: action.data.page,
        totalPages: action.data.totalPages,
        isFetching: false
      };
    }
    case PROPERTY_SEARCH_FAILURE:
      return { ...state, isFetching: false };
    case PROPERTY_TOGGLE_SHOW_ALLOWED:
      return {
        ...state,
        allowedOnly: !state.allowedOnly
      };
    default:
      return state;
  }
}

export default combineReducers({
  listings
});
