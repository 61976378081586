export const SELLER_TENANT_INVOLVEMENT_SHOWING_INVOLVEMENTS = {
  can_approve: "Can Approve Showings and Send Comments",
  observe_mode: "Notifications Only",
  none_involvement: "None",
  can_approve_no_comment: "Can Approve Showings with No Comments"
};
export const SELLER_TENANT_INVOLVEMENT_PREFER_PRIMARY_PHONE = "primary_phone";
export const SELLER_TENANT_INVOLVEMENT_PREFER_EMAIL = "email";
export const SELLER_TENANT_INVOLVEMENT_NONE_COMMUNICATION =
  "none_communication";
