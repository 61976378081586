import React from "react";
import { connect } from "react-redux";

import { callApi } from "brokerage/middlewares/api";
import { useHistory, useLocation } from "react-router-dom";
import ModalConfirmation from "./ModalConfirmation";
import { fetchPeopleCounts } from "~brokerage/actions/people";

const ModalDeleteCustomer = props => {
  const { person, dispatch } = props;
  const history = useHistory();
  const location = useLocation();

  const handleDelete = () => {
    callApi(`my_contacts/${person.id}`, {}, {}, "delete").then(response => {
      if (response.status === 200) {
        history.push({
          pathname: `/people`,
          query: { ...location.query, modal: void 0 }
        });
      }
      dispatch(fetchPeopleCounts({}));
    });
  };

  return (
    <ModalConfirmation
      heading="Delete Customer"
      text="Are you sure?"
      confirmText="Yes, Delete"
      confirmVariant="red"
      onConfirm={handleDelete}
      cancelText="Cancel"
      cancelVariant="outline"
    />
  );
};

function mapStateToProps(state) {
  const { entity: person } = state.people.single.person;

  return {
    person
  };
}

export default connect(mapStateToProps)(ModalDeleteCustomer);
