import React from "react";
import PropTypes from "prop-types";
import ListHeader from "~brokerage/components/listings/ListHeader";
import Listings from "~brokerage/components/listings/index";
import css from "./list.sass";

const ListRoute = ({ location }) => (
  <div className={css.listPage}>
    <ListHeader location={location} />
    <Listings location={location} />
  </div>
);

ListRoute.propTypes = {
  location: PropTypes.object
};

export default ListRoute;
