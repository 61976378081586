import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import PropertySpecs from "~brokerage/components/shared/PropertySpecs";
import Link from "~brokerage/components/shared/Link";

import { platformUrl } from "~brokerage/constants/externalLinks";

import css from "./Summary.sass";

class Summary extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    address: PropTypes.string,
    price: PropTypes.number,
    specs: PropTypes.object,
    mls: PropTypes.string,
    status: PropTypes.string,
    photoUrl: PropTypes.string,
    listingsStatus: PropTypes.string,
    platformName: PropTypes.string,
    location: PropTypes.object
  };

  backToListingsUrl = () => {
    const { listingsStatus } = this.props;
    if (listingsStatus) {
      return `/listings?property_statuses=${listingsStatus}`;
    } else {
      return "/listings";
    }
  };

  render() {
    const { id, address, price, specs, mls, status, photoUrl, platformName, contingencyString } =
      this.props;
    const externalMLSLink = platformUrl(mls, platformName, id);

    return (
      <div className={css.base}>
        <div className={css.back}>
          <Button to={this.backToListingsUrl()} variant="outline">
            <i className={css.backIcon}>
              <Icon name="arrowLeftRound" />
            </i>
            <span className={css.backButtonText}>Back to Listings</span>
          </Button>
        </div>
        {photoUrl && (
          <div
            className={css.photo}
            style={{ backgroundImage: `url("${photoUrl}")` }}
          />
        )}
        <div className={css.addressContainer}>
          <div className={css.address}>
            <span className={css.addressStatus}>{`${status}${contingencyString}`}</span>
            <Link
              className={css.addressLink}
              href={externalMLSLink}
              external={true}
            >
              {address}
            </Link>
          </div>
        </div>
        <div className={css.specs}>
          <PropertySpecs
            price={price}
            mls={mls}
            beds={specs.beds}
            baths={specs.baths}
            sqft={specs.sqft}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(Summary);
