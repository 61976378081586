import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import some from 'lodash/some'
import Icon from '~brokerage/components/shared/Icon'
import Item from './Item'
import { Dropdown } from '~brokerage/components/shared/Form'
import PremiumButtonWrapper, { PREMIUM_FEATURE_TYPES } from '~brokerage/components/shared/PremiumButtonWrapper'
import Loader from '~brokerage/components/shared/Loader'


export default class SidebarAgents extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    agents: PropTypes.array,
    isFetching: PropTypes.bool,
    requiredAgents: PropTypes.array,
    dropdownAgents: PropTypes.array,
    conversationType: PropTypes.string,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    onDropdownOpen: PropTypes.func,
    onDropdownFilter: PropTypes.func
  }

  static contextTypes = {
    currentUser: PropTypes.object
  }

  isAgentRequired(agent) {
    return some(this.props.requiredAgents, (a) => {
      return (a.id && a.id === agent.id) || (a.userId && a.userId === agent.userId)
    })
  }

  isCurrentAgent(agent) {
    return agent.userId === this.context.currentUser.id
  }

  isAgentRemovable(agent) {
    return !(this.isCurrentAgent(agent) || this.isAgentRequired(agent) || this.props.onRemove === undefined)
  }

  handleAdd = (option) => {
    this.props.onAdd(option.value)
  }

  handleRemove = (agent) => {
    if (this.isAgentRemovable(agent) && this.props.onRemove) {
      return this.props.onRemove(agent)
    }
  }

  render() {
    const {
      isFetching, agents, conversationType, dropdownAgents, onDropdownOpen, onDropdownFilter, onAdd
    } = this.props

    return (
      <div className={css.base}>
        <h3 className={css.h3}>{this.props.heading}</h3>
        <div className={css.items}>
          {(() => {
            if (isFetching) {
              return <div className={css.loader}><Loader variant="inline" active/></div>
            } else {
              return (
                <div>
                  {agents.map(agent => {
                    let isAgentRemovable = this.isAgentRemovable(agent)
                    return (
                        <Item
                          key={agent.id}
                          agent={agent}
                          isAgentRemovable={isAgentRemovable}
                          conversationType={conversationType}
                          onRemoveClick={() => this.handleRemove(agent)}
                        />
                      )
                    }
                  )}

                  {onAdd &&
                    <PremiumButtonWrapper featureType={PREMIUM_FEATURE_TYPES.ADD_TEAMMATES_TO_CHAT}>
                      <Dropdown
                        title={<Icon name="userAdd"/>}
                        options={dropdownAgents}
                        filter
                        optionsPostfix={<div><Icon name="plus"/> Add</div>}
                        variant="addAgent"
                        modifier="block"
                        onOpen={onDropdownOpen}
                        onFilter={onDropdownFilter}
                        onChange={this.handleAdd}
                      />
                    </PremiumButtonWrapper>
                  }
                </div>
              )
            }
          })()}
        </div>
      </div>
    )
  }
}
