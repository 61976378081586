import React from "react";

import { hashHistory } from "~brokerage/app/helpers/history";
import LandingRoutes from "~brokerage/app/pages/seller/LandingRoutes.jsx";

const history = hashHistory;

const SellerLanding = ({ localDetails }) => (
  <LandingRoutes history={history} localDetails={localDetails} />
);

export default SellerLanding;
