import React, { useEffect } from "react";
import css from "../index.sass";
import { connect } from "react-redux";
import {
  setSelectedProperties,
  propertySearch,
  toggleShowAllowedProperties
} from "~brokerage/actions/propertySearch";
import Loader from "~brokerage/components/shared/Loader";
import Property from "./Property";
import { withRouter } from "react-router-dom";
import Pagination from "./Pagination";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";

const PropertyList = ({
  selected = [],
  listings = [],
  isFetching,
  dispatch,
  updateSelected,
  location,
  variant = "route",
  total,
  allowedOnly,
  hightlightedListing,
  setHighlightedListing,
  activeListingStatuses
}) => {
  useEffect(() => dispatch(setSelectedProperties([])), []);

  const onMarketStatuses = activeListingStatuses?.toString();

  const handlePageChange = pageNum => {
    dispatch(
      propertySearch(
        {
          property_statuses: onMarketStatuses
        },
        pageNum,
        location.query["query"] || "",
        allowedOnly
      )
    );
  };

  if (isFetching) {
    return (
      <div className={css.container}>
        <Loader active />
      </div>
    );
  }
  return (
    <div className={css.container}>
      <div className={css.header}>
        {!!total
          ? `${formatNumberUsingCommas(total)} listings found`
          : "No listings found"}
        <div>
          <input
            type="checkbox"
            id="show-available"
            checked={allowedOnly}
            onChange={() => {
              dispatch(toggleShowAllowedProperties());
              dispatch(
                propertySearch(
                  {
                    property_statuses: onMarketStatuses
                  },
                  1,
                  location.query["query"] || "",
                  !allowedOnly
                )
              );
            }}
          />
          Available for showings only
        </div>
      </div>
      <div className={css.list} id="listings">
        {listings.map(l => (
          <Property
            key={l.id}
            updateSelected={updateSelected}
            listing={l}
            selected={selected}
            variant={variant}
            highlight={hightlightedListing === l.mlsNumber}
            setHighlightedListing={setHighlightedListing}
          />
        ))}
      </div>
      <Pagination onPageChange={handlePageChange} />
    </div>
  );
};

function mapStateToProps(state) {
  const { total, allowedOnly } = state.propertySearch.list.listings;
  const { activeListingStatuses } = state.currentUser;
  return {
    total,
    allowedOnly,
    activeListingStatuses
  };
}

export default withRouter(connect(mapStateToProps)(PropertyList));
