import moment from "moment";

export const convertShowingsToEvents = (showings, popupDispatch, selectedId) =>
  showings.map(showing => {
    const { id, requested_time, duration } = showing;
    const endTime = moment(requested_time).add(duration, "minutes");

    return {
      start: moment(requested_time).toDate(),
      end: endTime.toDate(),
      isPassed: endTime.isBefore(),
      isSelected: id === selectedId,
      showingData: showing,
      popupDispatch
    };
  });

export const processStopsFromApi = stops =>
  stops.map(stop => ({
    ...stop,
    status: "stop",
    id: `stop-${stop.id}`
  }));

export const basePath = "/calendar";

//Setting URL time default on load/refresh
const urlParams = new URLSearchParams(window.location.search);
const timestamp = urlParams.get("timestamp");
export const defaultTime = timestamp ? moment(timestamp).toDate() : new Date();

export const calendarFormats = {
  // timeGutterFormat: "h a"
  timeGutterFormat: date => moment(date).format("h[\n]A"),
  agendaHeaderFormat: ({ start, end }) =>
    moment(start).format("MMM D, YYYY") +
    " — " +
    moment(end).format("MMM D, YYYY"),
  dayHeaderFormat: date => moment(date).format("dddd, MMMM D, YYYY")
};

export const allPopoverPositions = {
  month: ["right", "left", "bottom"],
  day: ["bottom", "top", "right"],
  agenda: ["bottom", "top", "right"]
};
