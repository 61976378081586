import css from './Col.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

const Col = ({ variant = 'base', modifier, children, ...rest }) => {
  return (
    <div className={composeClassName(css, variant, modifier)} {...rest}>
      {children}
    </div>
  )
}

Col.propTypes = {
  variant: PropTypes.string,
  modifier: PropTypes.string,
  children: PropTypes.node
}
export default Col
