import { getFileNameWithoutExtension } from "~brokerage/components/shared/FileUpload/utils";

export const getMessage = (text, isAttachmentsEmpty) =>
  text || (isAttachmentsEmpty ? "" : "Please see the attached files");

export const getAttachmentIds = files => files.map(file => file.signedId);

export const buildFilePreviews = files =>
  Array.from(files).map(file => ({
    file,
    preview: URL.createObjectURL(file),
    uniqueId: getFileNameWithoutExtension(file)
  }));
