import css from './ErrorMessage.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ErrorMessage extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string
  }
  render() {
    if (this.props.message) {
      return (
        <div className={css.base}>
          <h2 className={css.h2}>{this.props.message}</h2>
        </div>
      )
    }

    return (
      <div className={css.base}>
        <h2 className={css.h2}>Something went wrong.</h2>
        <p className={css.p}>Agent Inbox team is already notified of this.</p>
      </div>
    )
  }
}
