import css from './SettingsPage.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class SettingsPage extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  render() {
    return (
      <div className={css.base}>
        {this.props.children}
      </div>
    )
  }
}
