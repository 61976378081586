import React from "react";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import ReadOnlyInput from "~brokerage/components/settings/components/ReadOnlyInput";
import css from "~brokerage/app/pages/settings/personal/sections/shared.sass";

const AgentInfo = ({
  firstName,
  lastName,
  brokerOfficeName,
  brokerOfficeAddress
}) => (
  <>
    <Row className={css.inputRow}>
      <Column className={css.inputColumnCentered} style={{ maxWidth: "120px" }}>
        <InputLabel>First Name</InputLabel>
      </Column>
      <Column
        className={css.inputColumn}
        style={{ paddingLeft: ".6rem", maxWidth: "100%" }}
      >
        <ReadOnlyInput placeholder="Name">{firstName}</ReadOnlyInput>
      </Column>
    </Row>
    <Row className={css.inputRow}>
      <Column className={css.inputColumnCentered} style={{ maxWidth: "120px" }}>
        <InputLabel>Last Name</InputLabel>
      </Column>
      <Column
        className={css.inputColumn}
        style={{ paddingLeft: ".6rem", maxWidth: "100%" }}
      >
        <ReadOnlyInput placeholder="Name">{lastName}</ReadOnlyInput>
      </Column>
    </Row>
    <Row className={css.inputRow}>
      <Column className={css.inputColumnCentered} style={{ maxWidth: "120px" }}>
        <InputLabel>Office Name</InputLabel>
      </Column>
      <Column
        className={css.inputColumn}
        style={{ paddingLeft: ".6rem", maxWidth: "100%" }}
      >
        <ReadOnlyInput>{brokerOfficeName}</ReadOnlyInput>
      </Column>
    </Row>
    <Row className={css.inputRow}>
      <Column
        className={css.inputColumnOffsetTop}
        style={{ maxWidth: "120px" }}
      >
        <InputLabel>Office Address</InputLabel>
      </Column>
      <Column
        className={css.inputColumn}
        style={{ paddingLeft: ".6rem", maxWidth: "100%" }}
      >
        <ReadOnlyInput style={{ marginBottom: "1.3rem", height: "100px" }}>
          {brokerOfficeAddress}
        </ReadOnlyInput>
      </Column>
    </Row>
  </>
);

export default AgentInfo;
