import css from "./index.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { EVENT_TYPE, SYSTEM_TYPE } from "~brokerage/constants/messages";
import * as SHOWING_STATUSES from "~brokerage/constants/showings/statuses";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import Loader from "~brokerage/components/shared/Loader";
import Form from "./Form";
import ChatMessage from "./Message";
import DeliveryTip from "./DeliveryTip";
import NoReplyMessage from "./NoReplyMessage";
import { on, off } from "~brokerage/app/helpers/events.js";

const MESSAGES_PER_PAGE = 10;

export default class Chat extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    hasActions: PropTypes.bool,
    showing: PropTypes.object,
    currentShowingRole: PropTypes.string,
    messages: PropTypes.array,
    isChatScrollFetching: PropTypes.bool,
    isFetching: PropTypes.bool,
    hasMoreMessages: PropTypes.bool,
    isShowingChat: PropTypes.bool,
    logACallEnabled: PropTypes.bool,
    teamNoteEnabled: PropTypes.bool,
    onPastMessagesRequest: PropTypes.func,
    onMessageSubmit: PropTypes.func,
    onNoteMessageSubmit: PropTypes.func,
    onNewMessage: PropTypes.func,
    onNewNote: PropTypes.func,
    dispatch: PropTypes.func
  };

  static contextTypes = {
    currentUser: PropTypes.object
  };

  state = {
    isNotesMode: false,
    files: []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.messages !== this.props.messages) {
      this.getNewMessageContainerProps();
    }
  }

  componentDidMount() {
    on("close:notes", () => this.handleFormTabChange(0));
    this.$messagesContainer.on("scroll.Chat", this.handleChatScroll);
    $(window).resize(this.handleWindowResize);
    this.handleFormHeightChange();
    this.considerDeliveryTip();
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const {
      messages: prevMessages,
      hasActions: prevHasActions,
      showing: prevShowing
    } = prevProps;
    const { messages, hasActions, showing, isShowingChat } = this.props;

    const areMessagesEqual = messages === prevMessages;

    if (!areMessagesEqual || hasActions !== prevHasActions) {
      this.handleFormHeightChange();
      this.considerDeliveryTip();
    }

    let shouldBeScrolledToBottom = false;

    if (messages.length && !areMessagesEqual) {
      let messagesWerePrepended = false;
      let messagesWereAppended = false;
      const lastMessage = messages[messages.length - 1];

      if (prevMessages.length) {
        const areFirstMessagesEqual = messages[0] === prevMessages[0];
        const areLastMessagesEqual =
          lastMessage === prevMessages[prevMessages.length - 1];
        messagesWerePrepended = !areFirstMessagesEqual && areLastMessagesEqual;
        messagesWereAppended = areFirstMessagesEqual && !areLastMessagesEqual;
      }

      if (messagesWerePrepended) {
        const totalHeight = this.$messagesContainerInner.outerHeight();
        const totalHeightDiff = totalHeight - this.prevTotalHeight;
        this.$messagesContainer.scrollTop(this.prevScrollTop + totalHeightDiff);
      } else if (
        messagesWereAppended &&
        (this.wasScrolledToBottom || lastMessage.isFromMe)
      ) {
        shouldBeScrolledToBottom = true;
      }
    }

    if (
      shouldBeScrolledToBottom ||
      (isShowingChat && showing.id !== prevShowing.id)
    ) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    off("close:notes");
    this.$messagesContainer.off("scroll.Chat");
  }

  getNewMessageContainerProps() {
    this.prevTotalHeight = this.$messagesContainerInner.outerHeight();
    this.prevScrollTop = this.$messagesContainer.scrollTop();
    this.wasScrolledToBottom =
      this.$messagesContainer.height() + this.prevScrollTop >=
      this.prevTotalHeight;
  }

  scrollToBottom() {
    this.$messagesContainer.scrollTop(
      this.$messagesContainer.prop("scrollHeight")
    );
  }

  stickMessagesToBottom = () => {
    const height = this.$messagesContainer.height();
    const innerHeight = this.$messagesContainerInner.outerHeight();
    if (innerHeight < height) {
      this.$messagesContainerInner.css("top", height - innerHeight);
    } else {
      this.$messagesContainerInner.css("top", "");
    }
  };

  needLoadMore() {
    return !this.props.isChatScrollFetching && this.props.hasMoreMessages;
  }

  considerDeliveryTip() {
    if (this.$deliveryTip && this.$deliveryTip.length) {
      const deliveryTipHeight = this.$deliveryTip.outerHeight();

      if (this.oldDeliveryTipHeight !== deliveryTipHeight) {
        this.oldDeliveryTipHeight = deliveryTipHeight;
        this.$inner.css("paddingTop", deliveryTipHeight);
      }
    } else {
      this.$inner.css("paddingTop", "");
    }
  }

  get shouldRenderDeliveryTip() {
    const { messages } = this.props;
    return (
      messages.length < MESSAGES_PER_PAGE && !messages.find(m => !m.isFromMe)
    );
  }

  handleWindowResize = () => {
    this.considerDeliveryTip();
    this.stickMessagesToBottom();
  };

  handleFormTabChange = index => {
    const isNotesMode = index === 1;
    if (isNotesMode !== this.state.isNotesMode) {
      this.setState({
        isNotesMode: isNotesMode
      });
    }
  };

  handleChatScroll = () => {
    if (this.needLoadMore() && this.$messagesContainer.scrollTop() <= 0) {
      this.props.onPastMessagesRequest();
    }
  };

  handleFormHeightChange = () => {
    const nextPaddingBottom =
      this.$form.height() + parseInt(this.$form.css("bottom"), 10);
    const paddingBottom = parseInt(this.$inner.css("paddingBottom"), 10);
    const diff = nextPaddingBottom - paddingBottom;

    this.$inner.css("paddingBottom", nextPaddingBottom);
    if (diff > 0) {
      this.$messagesContainer.scrollTop(
        this.$messagesContainer.scrollTop() + diff
      );
    }
    this.stickMessagesToBottom();
  };

  renderMessage(message, messageIndex) {
    const prevMessage = this.props.messages[messageIndex - 1];
    const nextMessage = this.props.messages[messageIndex + 1];
    const prevMessageIsSystem =
      prevMessage && prevMessage.messageType === SYSTEM_TYPE;
    const prevMessageIsEvent =
      prevMessage && prevMessage.messageType === EVENT_TYPE;
    const nextMessageIsEvent =
      nextMessage && nextMessage.messageType === EVENT_TYPE;
    const messageIsEvent = message.messageType === EVENT_TYPE;
    const firstForDirection =
      !prevMessage ||
      message.senderId !== prevMessage.senderId ||
      prevMessageIsEvent ||
      prevMessageIsSystem;

    const lastForDirection =
      !nextMessage ||
      message.senderId !== nextMessage.senderId ||
      nextMessageIsEvent ||
      prevMessageIsSystem;

    const showTime = !messageIsEvent;

    return (
      <ChatMessage
        message={message}
        key={message.id}
        hasRead={message.hasRead}
        firstForDirection={firstForDirection}
        lastForDirection={lastForDirection}
        isNotesMode={this.state.isNotesMode}
        showTime={showTime}
      />
    );
  }

  renderMessages() {
    const { messages } = this.props;

    if (this.props.isFetching) {
      return <Loader active={true} type="inline" />;
    } else if (!messages.length) {
      return <EmptyMessage>Nothing to show.</EmptyMessage>;
    } else {
      return messages.map(this.renderMessage.bind(this));
    }
  }

  renderNoReplyMessage() {
    const { isShowingChat, messages, showing, currentShowingRole } = this.props;
    const { currentUser } = this.context;

    if (isShowingChat && showing.id && messages.length) {
      const isCurrentUserLa = showing.isLa;
      const isCurrentUserBa = showing.showingAgents.find(
        a => a.id === currentUser.platformId
      );
      const isCurrentUserLaAndBa = isCurrentUserLa && isCurrentUserBa;
      const lastMessage = messages[messages.length - 1];
      const timeDiff = new Date() - new Date(lastMessage.createdAt);
      const twoHours = 2 * 60 * 60 * 1000;
      const isLastMessageOlderThanTwoHours = timeDiff > twoHours;
      const isLastMessagePending =
        lastMessage.messageType === SHOWING_STATUSES.STATUS_PENDING;
      const isShowingApprovedOrDeclined = [
        SHOWING_STATUSES.STATUS_APPROVED,
        SHOWING_STATUSES.STATUS_DECLINED
      ].includes(showing.status);
      const isShowingNotSentOrCancelled = [
        SHOWING_STATUSES.STATUS_NOT_SENT,
        SHOWING_STATUSES.STATUS_CANCELLED
      ].includes(showing.status);
      const isStatusEditable = !(
        isShowingNotSentOrCancelled || showing.isPassed
      );

      // We should only show the No Reply Message when the user is the showing agent
      // and the showing is pending, the message isn't cancelled and is valid.
      // The last message is older than 2 hours, with a pending status from the user.

      if (
        isCurrentUserLaAndBa ||
        isCurrentUserLa ||
        !isLastMessageOlderThanTwoHours ||
        isShowingApprovedOrDeclined ||
        !lastMessage.isFromMe ||
        !isLastMessagePending ||
        !isStatusEditable
      ) {
        return null;
      }

      return (
        <NoReplyMessage
          showing={showing}
          role={currentShowingRole}
          isStatusEditable={isStatusEditable}
        />
      );
    }
  }

  renderScrollLoader() {
    if (this.props.isChatScrollFetching) {
      return (
        <div className={css.loader}>
          <Loader active variant="inline" />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        className={composeClassName(css, "base", {
          notesMode: this.state.isNotesMode,
          hasActions: this.props.hasActions,
          hasDeliveryTip: this.shouldRenderDeliveryTip
        })}
      >
        {this.shouldRenderDeliveryTip && (
          <DeliveryTip elRef={el => (this.$deliveryTip = $(el))} />
        )}

        <div className={css.inner} ref={el => (this.$inner = $(el))}>
          <div
            className={css.messages}
            ref={el => (this.$messagesContainer = $(el))}
          >
            {this.renderScrollLoader()}
            <div
              className={css.messagesInner}
              ref={el => (this.$messagesContainerInner = $(el))}
            >
              {this.renderMessages()}
              {this.renderNoReplyMessage()}
            </div>
          </div>

          <Form
            showingId={this.props.id}
            hasActions={this.props.hasActions}
            onTextAreaChange={this.handleTextAreaChange}
            isNotesMode={this.state.isNotesMode}
            onTabChange={this.handleFormTabChange}
            onMessageSubmit={this.props.onMessageSubmit}
            onNoteMessageSubmit={this.props.onNoteMessageSubmit}
            onNewMessage={this.props.onNewMessage}
            onNewNote={this.props.onNewNote}
            logACallEnabled={this.props.logACallEnabled}
            teamNoteEnabled={this.props.teamNoteEnabled}
            onHeightChange={this.handleFormHeightChange}
            elRef={el => el && (this.$form = $(el))}
            attachments={this.state.files}
            setAttachments={files => this.setState({ files })}
            isShowingChat={this.props.isShowingChat}
          />
        </div>
      </div>
    );
  }
}
