export const detailsPopupInitialState = {
  data: {},
  meta: { open: false, targetRect: { x: 0, y: 0, height: 0, width: 0 } }
};

export const showMorePopupInitialState = {
  data: { date: new Date(), events: [] },
  meta: { open: false, targetRect: { x: 0, y: 0, height: 0, width: 0 } }
};

export const detailsPopupReducer = (
  oldState, //state
  { action, newData, newRect } //dispatch params
) => {
  const { data: oldData, meta: oldMeta } = oldState;
  switch (action) {
    case "toggle":
      return oldMeta.open && oldData.id === newData.id
        ? detailsPopupInitialState
        : {
            data: newData,
            meta: { open: true, targetRect: newRect }
          };
    case "close":
      return oldData.id === newData.id ? detailsPopupInitialState : oldState;
    default:
      throw new Error();
  }
};

export const showMorePopupReducer = (
  { data: oldData, meta: oldMeta }, //state
  { action, newData, newRect, newSelectedId } //dispatch params
) => {
  switch (action) {
    case "open":
      return {
        data: oldData,
        meta: { open: true, targetRect: newRect }
      };
    case "set data":
      return {
        data: newData,
        meta: oldMeta
      };
    case "set selected":
      const { events: oldEvents, date: oldDate } = oldData;
      if (oldMeta.open) {
        const newEvents = oldEvents.map(e => ({
          ...e,
          isSelected: e.showingData.id === newSelectedId
        }));
        return {
          data: { events: newEvents, date: oldDate },
          meta: oldMeta
        };
      } else {
        return { data: oldData, meta: oldMeta };
      }
    case "close":
      return showMorePopupInitialState;
    default:
      throw new Error();
  }
};
