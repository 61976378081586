import React from "react";

import PeopleSearch from "./PeopleSearch";
import PeopleCounter from "./PeopleCounter";
import ArchiveToggle from "./ArchiveToggle";

import css from "./ListHeader.sass";

const ListHeader = ({ location }) => {
  return (
    <div className={css.base}>
      <PeopleSearch location={location} />
      <div className={css.peopleCounter}>
        <PeopleCounter location={location} />
        <ArchiveToggle location={location} />
      </div>
    </div>
  );
};
export default ListHeader;
