export const CUSTOM_MODAL_STYLE = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "600px",
    minWidth: "500px",
    maxHeight: "80%",
    minHeight: "50%",
    padding: "0px",
    overflow: "hidden"
  },
  overlay: { zIndex: 100 } //The popover is at 99
};
