import React from "react";
import { MARKER_COLORS } from "~brokerage/constants/routes/colors";
import PropTypes from "prop-types";
import {
  formatStatusText,
  statusColor,
} from "~brokerage/app/constants/appointments.js";

const StatusDisplay = ({ status, css, futureShowing = true }) => {
  const statusText = formatStatusText(status.toLowerCase(), !futureShowing);
  const _statusColor = statusColor(status.toLowerCase(), !futureShowing);
  const displayText = status === "Not_sent" ? "Incomplete" : statusText;
  return (
    <span
      className={css.tag}
      style={{ backgroundColor: _statusColor, color: "#fff" }}
    >
      {displayText}
    </span>
  );
};

StatusDisplay.propTypes = {
  status: PropTypes.string.isRequired,
  css: PropTypes.object
};

export default StatusDisplay;
