import React from "react";
import css from "./Listings.sass";
import moment from "moment";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";
import Button from "~brokerage/components/shared/Button";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import List from "~brokerage/components/shared/List";
import { platformUrl } from "~brokerage/constants/externalLinks";

const Listing = ({ listing, showings = [] }) => {
  const thrityDaysAgo = moment().subtract(30, "days");
  const sevenDaysAgo = moment().subtract(7, "days");
  const approvedShowings = showings.filter(s => s.status === "approved");

  const past30Days = approvedShowings.filter(s =>
    moment(s.requested_time).isAfter(thrityDaysAgo)
  );
  const past7Days = past30Days.filter(s =>
    moment(s.requested_time).isAfter(sevenDaysAgo)
  );

  const listingLink = platformUrl(
    listing.mlsNumber,
    listing.platformName,
    listing.id
  );
  const latestShowing =
    approvedShowings.length > 0 &&
    approvedShowings[approvedShowings.length - 1];

  return (
    <div className={css.listing}>
      <div className={css.heading}>
        <div className={css.thumbContainer}>
          <div className={css.status}>{`${listing.status}${listing.contingencyString}`}</div>
          <PropertyThumbnail
            className={css.thumbnail}
            image={listing.photoUrl}
          />
        </div>
        <div className={css.listContainer}>
          <List>
            <div className={css.address}>{listing.address}</div>
            <List.Item modifier="pullLeft rpMarginBottom">
              {listing.mlsNumber}
            </List.Item>
            <List.Item
              icon="dollar"
              modifier="pullLeft iconOffset1 rpMarginBottom"
            >
              {formatPrice(listing.price)}
            </List.Item>
          </List>
        </div>
      </div>
      <div className={css.listingInfo}>
        <div className={css.infoHeader}>
          <div>
            <b>Approved Showings:</b>
          </div>
          <div className={css.viewButton}>
            <Button href={listingLink} target="_blank" variant="link">
              View Listing
            </Button>
            {latestShowing && (
              <>
                <span>{`  |  `}</span>
                <Button to={`/messages/${latestShowing.id}`} variant="link">
                  View Messages
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={css.stats}>
          <div className={css.stat}>
            <span className={css.value}>{approvedShowings.length}</span>
            <span className={css.label}>Total</span>
          </div>
          <div className={css.stat}>
            <span className={css.value}>{past30Days.length}</span>
            <span className={css.label}>30 days</span>
          </div>
          <div className={css.stat}>
            <span className={css.value}>{past7Days.length}</span>
            <span className={css.label}>7 days</span>
          </div>
          <div className={css.stat}>
            <span className={css.value}>{listing.feedback.total}</span>
            <span className={css.label}>Feedback</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
