import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";
import { ALPHABET } from "~brokerage/constants/routes/letters";

const toGeoJson = showings => {
  const validListings = showings.filter(l =>
    validateLatLng(l.coordinates?.lat, l.coordinates?.long)
  );

  if (validListings.length) {
    return {
      type: "FeatureCollection",
      features: validListings.map((l, index) => ({
        id: l.unique_id,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [l.coordinates?.long, l.coordinates?.lat]
        },
        properties: {
          label: ALPHABET[showings.findIndex(s => s.unique_id === l.unique_id)],
          status: l.status
        }
      }))
    };
  }
};

export default toGeoJson;
