import { datetimeToPixels } from "~brokerage/libs/helpers/TimeHelper";

const scrollToId = (id, nextDate, showingRefs, container) => {
  const target = showingRefs.current[id];
  const nextTop = datetimeToPixels(nextDate);
  const containerHeight = container && container.offsetHeight;
  const containerScrollTop = container.scrollTop;
  if (
    nextTop > containerScrollTop + containerHeight ||
    nextTop < containerScrollTop
  ) {
    target.current && target.current.scrollIntoView({ block: "center" });
  }
};

export default scrollToId;
