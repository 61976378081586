export const PROPERTY_SOLD = "property_sold";
export const PROPERTY_UNDER_CONTRACT = "property_under_contract";

export const TIME_UNAVAILABLE = "time_unavailable";
export const UNAVAILABLE_FOR_SHOWING = "unavailable_for_showing";
export const SELLER_UNAVAILABLE = "seller_unavailable";
export const LISTING_AGENT_UNAVAILABLE = "listing_agent_unavailable";

export const CLIENT_AVAILABILITY_CHANGED = "client_availability_changed";
export const MY_AVAILABILITY_CHANGED = "my_availability_changed";
export const CLIENT_NOT_INTERESTED = "client_not_interested";
export const SCHEDULED_IN_ERROR = "scheduled_in_error";

export const unavailableMessages = {
  time_unavailable: "Requested time unavailable",
  unavailable_for_showing: "Property unavailable for showing",
  seller_unavailable: "Seller unavailable to attend",
  listing_agent_unavailable: "Listing agent unavailable to attend",
  default: "Unavailable in Aligned Showings"
};
