import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from '~brokerage/app/helpers/history'
import Root from '../brokerage/app/containers/Root'
import configureStore from '../brokerage/app/store/configureStore'

const store = configureStore()
const history = hashHistory

export default class Client extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired
  }

  static childContextTypes = {
    currentUser: PropTypes.object,
    clientData: PropTypes.object
  }

  getChildContext() {
    const { currentUser, ...clientData } = this.props

    return {
      currentUser,
      clientData
    }
  }

  render() {
    const { currentUser } = this.props;

    return <Root store={store} history={history} userId={currentUser.id}/>
  }
}
