const plotListings = (map, data) => {
  const searchResultsSource = map.getSource("search-results");
  const searchResultsLayer = map.getLayer("listings");
  const searchResultsClusterLayer = map.getLayer("clusters");
  if (searchResultsSource) {
    searchResultsSource.setData(data);
  } else {
    map.addSource("search-results", {
      type: "geojson",
      data,
      cluster: true,
      clusterMaxZoom: 12,
      clusterRadius: 100
    });
  }

  if (!searchResultsClusterLayer) {
    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "search-results",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": [
          "step",
          ["get", "point_count"],
          20,
          20,
          40,
          40,
          60,
          50,
          60
        ]
      }
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "search-results",
      filter: ["has", "point_count"],
      paint: {
        "text-color": "#ffffff"
      },
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 18
      }
    });
  }

  if (!searchResultsLayer) {
    map.addLayer({
      id: "listings",
      type: "symbol",
      source: "search-results",
      filter: ["!", ["has", "point_count"]],
      layout: {
        "icon-image": ["get", "pinIcon"],
        "icon-size": 1,
        "text-field": ["get", "mlsNumber"],
        "text-offset": [0, 1.25],
        "text-anchor": "top"
      },
      paint: {
        "text-color": "#202",
        "text-halo-color": "#fff",
        "text-halo-width": 8
      }
    });
  }
};
export default plotListings;
