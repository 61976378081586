import { map, take } from "lodash";

export const TEAM_MEMBER_NOTIFICATION_LEVELS = [
  {
    label: "All Showing Notifications",
    value: "all_notifications"
  },
  {
    label: "Approvals and Cancellations Only",
    value: "notify_approved_or_cancelled"
  },
  {
    label: "No Showing Notifications",
    value: "muted"
  }
];

export const TEAM_MEMBER_NOTIFICATION_LEVELS_THAT_ALLOW_CALENDAR_INVITE = map(
  take(TEAM_MEMBER_NOTIFICATION_LEVELS, 2),
  "value"
);

export const SEARCH_CHAR_LIMIT = 2;
