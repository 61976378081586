export const STATUS_APPROVED = "approved";
export const STATUS_INSTANT_APPROVED = "instant_approved";
export const STATUS_DECLINED = "declined";
export const STATUS_PENDING = "pending";
export const STATUS_INSTANT_PENDING = "instant_pending";
export const STATUS_CANCELLED = "cancelled";
export const STATUS_PASSED = "passed";

export const STATUS_RESCHEDULED_REQUEST = "rescheduled_request";
export const STATUS_TIME_CHANGED = "time_changed";
export const STATUS_REQUEST_A_CALL = "request_a_call";
export const STATUS_FEEDBACK_RECEIVED = "feedback_received";
export const STATUS_FEEDBACK_REQUESTED = "feedback_requested";
export const STATUS_MUTED_LISTING = "muted_listing";
export const STATUS_NEW_PARTICIPANT = "new_participant";
