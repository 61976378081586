import React from "react";
import InformationLineIcon from "remixicon-react/InformationLineIcon";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  Checkbox,
  Row,
  Label,
  Controls
} from "~brokerage/components/shared/Form";
import {
  ADVANCE_NOTICE_REQUIREMENTS_TOOLTIP,
  SLIDER_MAX_RANGE,
  SLIDER_MIN_RANGE,
  SLIDER_HANDLE_STYLE,
  SLIDER_RAIL_STYLE
} from "./constants";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { genSliderMarks } from "./utils";
import css from "./ShowingInstructions.sass";

const AdvanceNotice = ({
  showingInstructions: {
    advanceNoticeDuration,
    advanceNoticeDisableSameDayRequests: disableSlider
  },
  handleChange,
  previousAdvanceNoticeDuration: previousAdvanceDurationValue,
  dispatch,
  setInitialAdvanceNoticeDuration
}) => {
  const selectedValue = advanceNoticeDuration / 30;

  const handleAdvanceNoticeSameDayRequestChanges = event => {
    let newAdvanceNoticeDuration = event.target.checked
      ? 0
      : previousAdvanceDurationValue || 0;
    let newAdvanceNoticeDisableSameDayRequests = event.target.checked;

    if (event.target.checked && advanceNoticeDuration > 0) {
      dispatch(setInitialAdvanceNoticeDuration(advanceNoticeDuration));
    }

    handleChange({
      advanceNoticeDuration: newAdvanceNoticeDuration,
      advanceNoticeDisableSameDayRequests:
        newAdvanceNoticeDisableSameDayRequests
    });
  };

  const marks = genSliderMarks(advanceNoticeDuration);

  return (
    <Row>
      <Label>
        <span>
          <Tooltip
            className={css.sliderLabel}
            text={ADVANCE_NOTICE_REQUIREMENTS_TOOLTIP}
            style={{ display: "flex", alignItems: "center" }}
          >
            Advance notice requirements
            <InformationLineIcon
              color="#999"
              size={14}
              className={css.sliderInfoIcon}
            />
          </Tooltip>
        </span>
      </Label>
      <Controls>
        <div class={css.slider}>
          <Slider
            min={SLIDER_MIN_RANGE}
            max={SLIDER_MAX_RANGE}
            step={1}
            disabled={disableSlider}
            value={selectedValue}
            onChange={value =>
              handleChange({ advanceNoticeDuration: value * 30 })
            }
            marks={marks}
            handleStyle={SLIDER_HANDLE_STYLE}
            railStyle={SLIDER_RAIL_STYLE}
          />
        </div>
        <div className={css.sliderDuration}>
          Advance Notice: {marks[selectedValue]?.label} hrs
        </div>
        <div>
          <Checkbox
            name="advanceNoticeDisableSameDayRequests"
            checked={disableSlider}
            onChange={handleAdvanceNoticeSameDayRequestChanges}
          >
            Disable same day requests
          </Checkbox>
        </div>
      </Controls>
    </Row>
  );
};

export default AdvanceNotice;
