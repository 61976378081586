import css from "./ShortInfo.sass";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addRecipientToNewMessageRecipients,
  clearNewMessageRecipients
} from "~brokerage/actions/messages";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import ShortInfoContacts from "./ShortInfoContacts";
import { withRouter } from "react-router-dom";

class ShortInfo extends PureComponent {
  static propTypes = {
    person: PropTypes.shape({
      id: PropTypes.string,
      isCurrentUser: PropTypes.bool,
      name: PropTypes.string,
      role: PropTypes.string,
      photoUrl: PropTypes.string,
      location: PropTypes.object,
      history: PropTypes.object
    }),
    isContact: PropTypes.bool,
    dispatch: PropTypes.func
  };

  static defaultProps = {
    isContact: false
  };

  handleNewMessageButtonClick = event => {
    const { location, history } = this.props;

    this.props.dispatch(clearNewMessageRecipients());
    this.props.dispatch(addRecipientToNewMessageRecipients(this.props.person));

    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "new_message" }
    });

    event.stopPropagation();
  };

  renderCurrentUserLabel() {
    if (this.props.person.isCurrentUser) {
      return <span className={css.role}>YOU</span>;
    } else {
      return null;
    }
  }

  renderNewMessageButton() {
    if (!this.props.person.isCurrentUser && !this.props.isContact) {
      return (
        <div className={css.buttons}>
          <Button onClick={this.handleNewMessageButtonClick} variant="outline">
            <Icon name="message" /> Message
          </Button>
        </div>
      );
    }
  }

  render() {
    const { firstName, lastName, name, role, photoUrl } = this.props.person;

    return (
      <div className={css.base}>
        {this.renderNewMessageButton()}
        <div className={css.photo}>
          <Avatar
            size="37"
            src={photoUrl}
            name={[firstName, lastName].join(" ")}
          />
        </div>
        <div className={css.nameRoleContainer}>
          <div className={css.name}>
            {this.renderCurrentUserLabel()}
            <span className={css.role}>{role}</span>
            <h1 className={css.h1}>{name}</h1>
          </div>
        </div>
        <ShortInfoContacts person={this.props.person} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entity: person } = state.people.single.person;

  return { person };
}
export default withRouter(connect(mapStateToProps)(ShortInfo));
