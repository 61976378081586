import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";

const toGeoJson = (listings, selected = []) => {
  const validListings = listings.filter(l =>
    validateLatLng(l.latitude, l.longitude)
  );
  const data = {
    type: "FeatureCollection",
    features: validListings.map((l, index) => {
      const isSelected = selected.findIndex(s => s.id === l.id) !== -1;
      return {
        id: index + 1,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [l.longitude, l.latitude]
        },
        properties: {
          mlsNumber: l.mlsNumber,
          pinIcon: isSelected ? "base-selected-marker" : "base-marker"
        }
      };
    })
  };

  return data;
};

export default toGeoJson;
