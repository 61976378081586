import css from './Icon.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

export default class Icon extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    shade: PropTypes.string,
    modifier: PropTypes.string,
    size: PropTypes.string
  }

  render() {
    const { name, shade = '', size = '16', modifier = '' } = this.props
    return <i className={composeClassName(css, name, `${modifier} ${shade} size${size}`)}/>
  }
}
