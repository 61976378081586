import css from "./Restrictions.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchRestrictions } from "~brokerage/actions/newShowing";
import {
  datetimeToPixels,
  minutesToPixels
} from "~brokerage/libs/helpers/TimeHelper";

class Restrictions extends React.PureComponent {
  static propTypes = {
    listingId: PropTypes.string,
    restrictions: PropTypes.array,
    date: PropTypes.string,
    dispatch: PropTypes.func
  };

  componentDidMount() {
    if (this.props.listingId) {
      this.props.dispatch(
        fetchRestrictions(this.props.listingId, this.props.date)
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.listingId &&
      (this.props.listingId !== nextProps.listingId ||
        this.props.date !== nextProps.date)
    ) {
      this.props.dispatch(
        fetchRestrictions(nextProps.listingId, nextProps.date)
      );
    }
  }

  renderRestriction(restriction) {
    const top = datetimeToPixels(restriction.time);
    const diff =
      (new Date(restriction.timeEnd) - new Date(restriction.time)) / 1000 / 60;
    const height = minutesToPixels(diff);

    return (
      <div
        key={restriction.time}
        className={css.restriction}
        style={{ top, height }}
      />
    );
  }

  render() {
    const { restrictions } = this.props;

    return (
      <div className={css.base}>
        {restrictions &&
          restrictions.map(restriction => this.renderRestriction(restriction))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { id: listingId } = state.showings.new.selectedListing.entity;
  const { entities: restrictions, isFetching } =
    state.showings.new.restrictions;
  const { date } = state.showings.new;

  return {
    listingId,
    restrictions,
    isFetching,
    date
  };
};
export default connect(mapStateToProps)(Restrictions);
