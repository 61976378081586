import React from "react";
import FilePicker from "./FilePicker";
import Icon from "~brokerage/components/shared/Icon";
import { FILE_PICKER_ACCEPTED_TYPES } from "./constants";

const FileUpload = ({ setFiles }) => {
  return (
    <FilePicker
      types={FILE_PICKER_ACCEPTED_TYPES}
      children={<Icon name="paperclip" />}
      onChange={files => setFiles(files)}
      isMultiple
    />
  );
};
export default FileUpload;
