import React, { useState, useEffect } from "react";

import css from "./AppointmentHistory.sass";

import Loader from "~brokerage/components/shared/Loader";
import { callApi } from "~brokerage/middlewares/api";
import HistoryEvent from "./HistoryEvent";

const AppointmentHistory = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [history, setHistory] = useState({});

  useEffect(() => {
    !loading && setLoading(true);
    callApi(`showings/${id}/messages?notifications_only=true`, {}, {}, "get")
      .then(response => {
        setHistory(response.data.messages);
      })
      .catch(() => {
        setErrors(true);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className={css.emptyContainer}>
        <div className={css.historyLoader}>
          <Loader active modifier="grayBg" />
        </div>
      </div>
    );
  }

  if (history === null || history === [] || errors) {
    return (
      <div className={css.emptyContainer}>
        <b style={{ color: "#777" }}>Oops, something went wrong!</b>
      </div>
    );
  }

  return (
    <div className={css.appointmentHistoryContainer}>
      {history.map((message, index) => (
        <HistoryEvent message={message} key={index} />
      ))}
    </div>
  );
};

export default AppointmentHistory;
