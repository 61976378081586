import css from "./index.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { updateDirectConversationName } from "~brokerage/actions/messages";
import Avatar from "~brokerage/components/shared/Avatar";
import Loader from "~brokerage/components/shared/Loader";
import Name from "./Name";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";

class Summary extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    interlocutors: PropTypes.array,
    name: PropTypes.string,
    dispatch: PropTypes.func
  };

  handleNameChange = name => {
    this.props.dispatch(updateDirectConversationName(this.props.id, name));
  };

  renderConversationName(interlocutors) {
    if (interlocutors.length < 2) {
      return (
        <Highlighter
          highlightClassName="Summary"
          searchWords={[this.props.searchTerm]}
          autoEscape={true}
          textToHighlight={interlocutors[0].name}
        />
      );
    }
    return (
      <Name name={this.props.name} onNameChanged={this.handleNameChange} />
    );
  }

  renderAvatar(interlocutors) {
    if (this.props.name) {
      return <Avatar size="30" name={this.props.name} />;
    } else {
      return (
        <Avatar
          size="30"
          src={interlocutors[0].photoUrl}
          name={[interlocutors[0].firstName, interlocutors[0].lastName].join(
            " "
          )}
        />
      );
    }
  }

  render() {
    const { interlocutors, id } = this.props;

    if (!interlocutors) {
      return (
        <div className={css.base}>
          <Loader modifier="size20" active />
        </div>
      );
    }

    return (
      <div className={css.base}>
        <div className={css.uniqueId}>conversation id: {id}</div>
        <div className={css.interlocutor}>
          <div className={css.photo}>{this.renderAvatar(interlocutors)}</div>
          <div className={css.name}>
            {this.renderConversationName(interlocutors)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { searchTerm } = state.messages.list.messages;

  return {
    searchTerm
  };
}

export default connect(mapStateToProps)(Summary);
