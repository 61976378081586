import css from "./OldInterfaceIframe.sass";

const MIDDLE_BUTTON = 1;
const VIEW_MESSAGE_TITLE = "View Message";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { closestTag } from "~brokerage/libs/helpers/DOMHelper";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import Loader from "~brokerage/components/shared/Loader";
import {
  SHOWING_URL_REGEX,
  LISTING_FEEDBACK_URL_REGEX,
  ROUTES_URL_REGEX
} from "brokerage/app/constants/regex";
import { withRouter } from "react-router";

let iframe,
  iframeContainer,
  iframeTimeout,
  iframeIsLoading,
  lastSrc,
  loaderContainer,
  loadInterval;
export class OldInterfaceIframe extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    alterIframeDocument: PropTypes.func,
    redirectToOtherPage: PropTypes.func,
    isLandingFooterShown: PropTypes.bool,
    isTopBarShown: PropTypes.bool
  };

  componentDidMount() {
    if (!iframe) {
      this.renderContainer();
    }

    this.stopWaitingFrameLoad();
    clearTimeout(iframeTimeout);

    const nextSrc = this.iframeUrl(this.props.location);
    if (nextSrc !== lastSrc) {
      if (!this.updateRouteAccordingToPageUrl(nextSrc)) {
        this.redirectIframe(nextSrc);
      }
    }

    this.showIframeContainer();
    this.whenFrameLoaded(this.initIframe.bind(this));

    this.updateIframeContainerClassNames(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.action === "POP" ||
      nextProps.location.action === "PUSH"
    ) {
      const nextSrc = this.iframeUrl(nextProps.location);
      this.redirectIframe(nextSrc);
    }

    this.updateIframeContainerClassNames(nextProps);
  }

  componentWillUnmount() {
    iframeTimeout = setTimeout(() => {
      lastSrc = null;
      iframe.contentWindow.location = "about:blank";
    }, 10);

    this.stopWaitingFrameLoad();
    this.hideIframeContainer();
    this.hideLoader();
  }

  stopWaitingFrameLoad() {
    if (loadInterval) {
      clearInterval(loadInterval);
      loadInterval = null;
    }
  }

  whenFrameLoaded(callback) {
    this.stopWaitingFrameLoad();

    loadInterval = setInterval(() => {
      const body = iframe.contentDocument.body;
      const pathname = this.props.location.pathname.split("/")[1];
      if (body && body.className.includes("frame_loaded")) {
        this.stopWaitingFrameLoad();
        body.className = body.className.replace("frame_loaded", pathname);
        callback();
      }
    }, 100);
  }

  redirectIframe(url) {
    if (this.currentIframeUrl() !== url) {
      lastSrc = url;
      iframe.contentWindow.location = url;
      this.showLoader();
      this.whenFrameLoaded(this.initIframe.bind(this));
    }
  }

  updateIframeContainerClassNames(props) {
    $(iframeContainer).attr(
      "class",
      this.composeIframeContainerClassName(props)
    );
  }

  composeIframeContainerClassName(props) {
    const { isLandingFooterShown: hasLandingFooter, isTopBarShown: hasTopBar } =
      props;
    return composeClassName(css, "iframeContainer", {
      hasLandingFooter,
      hasTopBar
    });
  }

  renderContainer() {
    iframeContainer = $(
      `<div class="${this.composeIframeContainerClassName(this.props)}"/>`
    )[0];
    iframe = $('<iframe frameborder="0" width="100%" height="100%"/>')[0];
    iframe.style.display = "block";
    iframeContainer.appendChild(iframe);
    document.body.appendChild(iframeContainer);

    loaderContainer = $(`<div class="${css.loaderContainer}"/>`)[0];
    iframeContainer.appendChild(loaderContainer);
    ReactDOM.render(<Loader active />, loaderContainer);
  }

  handleClick = event => {
    const handled =
      this.props.onClick && this.props.onClick(event, this.props.history);
    if (!handled) {
      this.baseHandleClick(event);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  baseHandleClick = event => {
    const link = closestTag(event.target, "a");

    if (link) {
      this.handleLinkClick(event, link);
    }
  };

  handleLinkClick = (event, link) => {
    const url = link.getAttribute("href");
    if (!url) return;

    const caption = link.textContent;

    const isShowingLinkClicked = this.isShowingLink(url);
    const isMessageLinkClicked = this.isMessageLink(
      isShowingLinkClicked,
      caption
    );
    const isExternalLinkClicked = this.isExternalLink(url);
    const isListingFeedbackLinkClicked = this.isListingFeedbackLink(url);
    const isMyShowingsLinkClicked = this.isMyShowingsLinkClicked(url);

    if (
      isShowingLinkClicked ||
      isMessageLinkClicked ||
      isListingFeedbackLinkClicked ||
      isExternalLinkClicked
    ) {
      event.preventDefault();
    }

    if (isMessageLinkClicked) {
      this.handleMessageClick(url);
    } else if (isShowingLinkClicked) {
      this.handleShowingClick(url);
    } else if (isListingFeedbackLinkClicked) {
      this.handleListingFeedbackClick(url);
    } else if (isExternalLinkClicked) {
      this.openExternalLink(url);
    } else if (isMyShowingsLinkClicked) {
      this.handleMyShowingsClick(url);
    }

    if (this.isMailToOrTelClicked(url)) {
      this._ignoreWindowUnload = true;
    }

    if (this.isNewTabClicked(event) && !isExternalLinkClicked) {
      event.preventDefault();
      this.openBmInNewTab(url);
    }
  };

  openBmInNewTab(url) {
    const parser = document.createElement("a");
    parser.href = url;
    const hash = location.hash.split("?")[0];
    const resultUrl =
      location.origin +
      location.pathname +
      hash +
      "?url=" +
      parser.pathname +
      parser.search;
    window.open(resultUrl, "_blank");
  }

  isNewTabClicked(event) {
    return (
      event.ctrlKey ||
      event.shiftKey ||
      event.metaKey ||
      (event.button && event.button === MIDDLE_BUTTON)
    );
  }

  isShowingLink(url) {
    return SHOWING_URL_REGEX.test(url);
  }

  isMyShowingsLinkClicked(url) {
    return ROUTES_URL_REGEX.test(url);
  }

  isListingFeedbackLink(url) {
    return LISTING_FEEDBACK_URL_REGEX.test(url);
  }

  isMessageLink(isShowingLinkClicked, caption) {
    return isShowingLinkClicked && caption === VIEW_MESSAGE_TITLE;
  }

  isExternalLink(url) {
    const currentHost = document.location.host;
    const linkHostMatch = url.match(/https?:\/\/(.+?(:[0-9]+)?)\//);

    return Boolean(linkHostMatch) && linkHostMatch[1] !== currentHost;
  }

  isMailToOrTelClicked(url) {
    return /^tel:.+/.test(url) || /^mailto:.+/.test(url);
  }

  openExternalLink(url) {
    window.open(url, "_blank");
  }

  handleShowingClick(url) {
    this.props.history.push({
      pathname: `/showings/${this.showingId(url)}`,
      query: { role: this.showingRole(url) }
    });
  }

  handleListingFeedbackClick(url) {
    this.props.history.push({
      pathname: `/listings/${this.listingId(url)}/feedback`,
      query: { backToPath: this.props.location.query.backToPath }
    });
  }

  handleMessageClick(url) {
    this.props.history.push({
      pathname: `/messages/showing/${this.showingId(url)}`,
      query: { role: this.showingRole(url) }
    });
  }

  handleMyShowingsClick(url) {
    this.props.history.push({
      pathname: "/my_showings"
    });
  }

  listingId(url) {
    const match = url.match(/\/my_listings\/([0-9]+)\/survey/);
    return match ? match[1] : void 0;
  }

  showingId(url) {
    const match = url.match(/\/showings\/([a-z]{3}\-[a-z]{3})/);
    return match ? match[1] : void 0;
  }

  showingRole(url) {
    const laRoleMatch = /\/(la)\/showings\/[a-z]{3}\-[a-z]{3}/.test(url);
    const isMyListingsLink = /\/my_listings\/[0-9]+\/showings/.test(url);
    return laRoleMatch || isMyListingsLink ? "la" : "ba";
  }

  initIframe() {
    this.stopWaitingFrameLoad();
    const iframeDocument = iframe.contentDocument;

    this.hideLoader();
    if (
      !this.updateRouteAccordingToPageUrl(this.currentIframeUrl()) &&
      !this.redirectToOtherPage(iframe.contentWindow.location, iframeDocument)
    ) {
      if (this.props.alterIframeDocument) {
        this.props.alterIframeDocument(iframeDocument);
      }
      iframeDocument.addEventListener("click", event => {
        // IE9 crashes when not using anonymous function here (╯°□°）╯︵ ┻━┻
        this.handleClick(event);
      });
      iframe.contentWindow.addEventListener("beforeunload", () => {
        // and here
        if (this._ignoreWindowUnload) {
          this._ignoreWindowUnload = false;
          return;
        }
        if (!iframeIsLoading) {
          // redirect happened from outside the iframe
          this.showLoader();
          this.whenFrameLoaded(this.initIframe.bind(this));
        }
      });
    }
  }

  updateRouteAccordingToPageUrl(pageUrl) {
    const { pathname, query } = this.props.location;
    const nextPathname = this.pathnameAccordingToPageUrl(pageUrl) || pathname;
    const nextQueryUrl =
      nextPathname !== pageUrl && pageUrl !== "/listings" ? pageUrl : void 0;

    if (pathname === nextPathname && query.url === nextQueryUrl) {
      return false;
    }
    this.props.history.replace({
      pathname: nextPathname,
      query: { ...query, url: nextPathname !== pageUrl ? pageUrl : void 0 }
    });

    return (
      nextPathname.replace("/subpage", "") !== pathname.replace("/subpage", "")
    );
  }

  pathnameAccordingToPageUrl(pageUrl) {
    const pathname = this.props.location.pathname;
    const isGeneralListingsPage = /^\/listings\/?/.test(pageUrl);
    const isGeneralRoutesPage = /^\/routes\/?/.test(pageUrl);

    if (isGeneralListingsPage) {
      return pageUrl === "/listings" ? "/search" : "/search/subpage";
    } else if (isGeneralRoutesPage) {
      return pageUrl === "/routes" ? "/routes" : "/routes/subpage";
    } else if (pageUrl === "/settings") {
      return "/settings";
    } else if (/^\/listings\/[0-9]+\/feedback/.test(pathname)) {
      const isGeneralFeedbackPage = LISTING_FEEDBACK_URL_REGEX.test(pageUrl);
      const listingPath = pathname.match(/^(\/listings\/[0-9]+)\/feedback/)[1];
      if (/^\/listings\/[0-9]+\/feedback\/?$/.test(pathname)) {
        return isGeneralFeedbackPage ? null : `${listingPath}/feedback/subpage`;
      } else {
        const isSubpage = /^\/listings\/[0-9]+\/feedback\/subpage$/.test(
          pathname
        );
        return isSubpage ? null : `${listingPath}/feedback/subpage`;
      }
    }
  }

  currentIframeUrl() {
    const iframeLocation = iframe.contentWindow.location;
    return `${iframeLocation.pathname}${iframeLocation.search}`;
  }

  redirectToOtherPage(iframeLocation, iframeDocument) {
    if (this.props.redirectToOtherPage) {
      this.props.redirectToOtherPage(iframeLocation, iframeDocument);
    }
  }

  showIframeContainer() {
    iframeContainer.style.visibility = "visible";
  }

  hideIframeContainer() {
    iframeContainer.style.visibility = "hidden";
  }

  showLoader() {
    iframeIsLoading = true;
    iframe.style.opacity = "0";
    loaderContainer.style.display = "block";
  }

  hideLoader() {
    iframeIsLoading = false;
    iframe.style.opacity = "1";
    loaderContainer.style.display = "none";
  }

  iframeUrl(location) {
    const urlFromQuery = location.query.url;
    return urlFromQuery ? urlFromQuery : this.props.url;
  }

  render() {
    return false;
  }
}

function mapStateToProps(state) {
  const { isBannerShown: isPushNotificationsBannerShown } =
    state.settings.pushNotifications;

  return {
    isLandingFooterShown: state.isLandingFooterShown,
    isTopBarShown: isPushNotificationsBannerShown
  };
}
export default withRouter(connect(mapStateToProps)(OldInterfaceIframe));
