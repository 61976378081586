import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import css from "./index.sass";

export default function SelectSystemPage() {
  const { dispatchPageState } = useContext(SettingsContext);

  useEffect(() => {
    dispatchPageState({ type: "setActivePane", payload: "system" });
  }, []);

  return (
    <div className={css.container}>
      <NavLink path="default_time_and_date_restrictions" title="Default Time and Date Restrictions" />
      <NavLink path="team_settings" title="Team Settings" />
      <NavLink path="edit_default_showing_settings" title="Edit Default Listing Setup" />
      <NavLink path="opt_out_of_aligned_showings" title="Opt-Out of Aligned Showings" />
    </div>
  );
}

const NavLink = ({ path, title }) => {
  const history = useHistory();
  const navigate = () => history.push(`/settings/system/${path}`);
  return (
    <a onClick={navigate} className={css.navBtn}>
      {title}
      <ArrowRightSLineIcon size={20} />
    </a>
  );
};
