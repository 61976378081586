import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import ModalContactDetails from "~brokerage/app/components/shared/ModalContactDetails.jsx";
import { callApi } from "~brokerage/middlewares/api";

import Contact from "./Contact";
import css from "./index.sass";

const ShowingContacts = ({ showingId }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const fetchContacts = async () => {
    try {
      const { data } = await callApi(
        `showings/${showingId}/contacts`,
        {},
        {},
        "get"
      );
      setSellers(data.contacts);
      setBuyers(data.buyerContacts);
    } catch (error) {
      toast.error(`Could not retrieve contacts.`);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const openModal = contact => {
    setModalData(contact);
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <ModalContactDetails
          isModalOpen={modalOpen}
          contact={modalData}
          closeModal={() => setModalOpen(false)}
        />
      )}
      <ContactsSection
        title="Sellers / Tenants"
        contacts={sellers}
        openModal={openModal}
      />
      <ContactsSection title="Buyers" contacts={buyers} openModal={openModal} />
    </>
  );
};

const ContactsSection = ({ contacts, title, openModal }) => {
  if (contacts.length > 0)
    return (
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>{title}</h3>
        <div className={css.items}>
          {contacts.map(contact => (
            <Contact
              key={contact.uuid}
              contact={contact}
              openModal={() => openModal(contact)}
            />
          ))}
        </div>
      </div>
    );
  else return null;
};

export default ShowingContacts;
