import {
  LISTING_CATEGORIES_REQUEST,
  LISTING_CATEGORIES_SUCCESS,
  LISTING_CATEGORIES_FAILURE,
  LISTING_CATEGORIES_SELECT
} from '~brokerage/constants/actionTypes'

export const defaultState = {
  entities: [],
  current: {},
  isFetching: false
}

function categories(state = defaultState, action) {
  switch (action.type) {
    case LISTING_CATEGORIES_REQUEST:
      return { ...state, isFetching: true }
    case LISTING_CATEGORIES_SUCCESS:
      return { ...state, entities: action.data.categories, isFetching: false }
    case LISTING_CATEGORIES_FAILURE:
      return { ...state, isFetching: false } // TODO error handling
    case LISTING_CATEGORIES_SELECT:
      return { ...state, current: action.nextCategory }
    default:
      return state
  }
}

export default categories
