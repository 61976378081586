import { connect } from 'react-redux'
import MessageTextarea from './MessageTextarea'

const mapStateToProps = state => {
  const { shouldBeFocused, text } = state.messageField

  return {
    shouldBeFocused,
    text
  }
}
export default connect(mapStateToProps)(MessageTextarea)
