import css from './Header.sass'
import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'

const Header = ({ title, withClose }, { location }) =>
  <div className={css.base}>
    <h1 className={css.h1}>{title}</h1>
    {(() => {
      if (withClose) {
        return (
          <div className={css.btnCancel}>
            <Button to={{ ...location, query: omit(location.query, 'modal') }} variant="cancel">
              <Icon name="decline2"/>
            </Button>
          </div>
        )
      }
    })()}
  </div>

Header.propTypes = {
  title: PropTypes.string,
  withClose: PropTypes.bool
}

Header.contextTypes = {
  location: PropTypes.object
}
export default Header
