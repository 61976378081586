import React, { useState } from "react";

import Loader from "~brokerage/components/shared/Loader";
import MainSection from "./MainSection";
import AgentContactSection from "./AgentContactSection";
import FooterSection from "./FooterSection";
import Header from "./Header";
import moment from "moment";
import css from "./ModalContents.sass";
import Tabs from "~brokerage/components/shared/Tabs";

const ModalContents = ({
  loading,
  details,
  updateDetails,
  changeDetails,
  updateLocalDetails,
  openStatusModal,
  openDateTimeModal,
  failoverDetails,
  openEditDetailModal,
  openEditTeamInstructionsModal,
  closeModal,
  setIsListingAgentPreapproving
}) => {
  const [mode, setMode] = useState("details");

  if (loading) {
    return <Loader active />;
  }

  const {
    unique_id: uniqueId,
    showing_status: showingStatus,
    local_details: localDetails,
    agent_contacts: {
      users,
      user_relation: {
        text: userRelationText,
        is_showing_agent: isShowingAgent,
        is_listing_agent: isListingAgent,
        is_co_listing_agent: isCoListingAgent,
        is_showing_team: isShowingTeam,
        is_listing_team: isListingTeam
      }
    }
  } = details;

  const _isShowingTeam = isShowingAgent || isShowingTeam;
  const _isListingTeam = isListingAgent || isCoListingAgent || isListingTeam;
  const requestedTime = moment(localDetails.requested_time);
  const futureShowing = requestedTime.isAfter();

  return (
    <>
      <Header
        uniqueId={uniqueId}
        details={details}
        closeModal={closeModal}
        showingStatus={showingStatus}
        futureShowing={futureShowing}
      />
      <Tabs.Links variant="fullWidth">
        <Tabs.Link
          variant="flex"
          active={mode === "details"}
          label="Details"
          onClick={() => setMode("details")}
        />
        <Tabs.Link
          variant="flex"
          active={mode === "contacts"}
          label="Contacts"
          onClick={() => setMode("contacts")}
        />
        <Tabs.Link
          variant="flex"
          active={mode === "history"}
          label="History"
          onClick={() => setMode("history")}
        />
      </Tabs.Links>
      <div className={css.scrollContainer}>
        {mode === "details" && (
          <MainSection
            openStatusModal={openStatusModal}
            openDateTimeModal={openDateTimeModal}
            openEditDetailModal={openEditDetailModal}
            openEditTeamInstructionsModal={openEditTeamInstructionsModal}
            details={details}
            updateDetails={updateDetails}
            changeDetails={changeDetails}
            updateLocalDetails={updateLocalDetails}
            showingStatus={showingStatus}
            isShowingTeam={_isShowingTeam}
            isListingTeam={_isListingTeam}
            failoverDetails={failoverDetails}
            closeModal={closeModal}
            futureShowing={futureShowing}
            setIsListingAgentPreapproving={setIsListingAgentPreapproving}
          />
        )}
        {mode === "contacts" && (
          <AgentContactSection text={userRelationText} users={users} />
        )}
        {mode === "history" && <FooterSection id={uniqueId} />}
      </div>
    </>
  );
};

export default ModalContents;
