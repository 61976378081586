import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchListings(_filter, page = 1) {
  let query;
  const filter = Object.assign({}, _filter);
  if (delete filter.query) {
    query = _filter.query;
  }
  return api.myOrganization.fetchListings(filter, page, query, [
    types.LISTINGS_REQUEST,
    types.LISTINGS_SUCCESS,
    types.LISTINGS_FAILURE
  ]);
}

export function fetchListingCategories() {
  return api.listing.fetchListingCategories([
    types.LISTING_CATEGORIES_REQUEST,
    types.LISTING_CATEGORIES_SUCCESS,
    types.LISTING_CATEGORIES_FAILURE
  ]);
}

export function fetchAgents() {
  return api.listing.fetchAgents([
    types.FETCH_FILTER_AGENTS_REQUEST,
    types.FETCH_FILTER_AGENTS_SUCCESS,
    types.FETCH_FILTER_AGENTS_FAILURE
  ]);
}

export function setCurrentAgent(agent) {
  return {
    type: types.LISTING_AGENT_SELECT,
    agent
  };
}

export function setCurrentListingCategory(category) {
  return {
    type: types.LISTING_CATEGORIES_SELECT,
    nextCategory: { ...category }
  };
}

export function fetchListing(id) {
  return api.listings.fetchListing(id, [
    types.LISTING_REQUEST,
    types.LISTING_SUCCESS,
    types.LISTING_FAILURE
  ]);
}

export function changeShowingsDetailsStep(newStep) {
  return {
    type: types.LISTING_SHOWING_DETAILS_CHANGE_STEP,
    newStep
  };
}

export function setListingBroadcastMessageCount(count, broadcastListingId) {
  return {
    type: types.LISTING_BROADCAST_MESSAGE_COUNT,
    count,
    broadcastListingId
  };
}

export function fetchShowingDetails(id) {
  return api.listings.fetchShowingDetails(id, [
    types.LISTING_SHOWING_DETAILS_REQUEST,
    types.LISTING_SHOWING_DETAILS_SUCCESS,
    types.LISTING_SHOWING_DETAILS_FAILURE
  ]);
}

export function fetchShowingInstructions(id) {
  return api.listings.fetchShowingInstructions(id, [
    types.LISTING_SHOWING_INSTRUCTIONS_REQUEST,
    types.LISTING_SHOWING_INSTRUCTIONS_SUCCESS,
    types.LISTING_SHOWING_INSTRUCTIONS_FAILURE
  ]);
}

export function updateShowingDetailsChanges(changes) {
  return {
    type: types.LISTING_SHOWING_DETAILS_CHANGE,
    changes
  };
}

export function addListingRestriction(listingId, restriction) {
  return api.listings.addRestriction(listingId, restriction, [
    types.LISTING_ADD_RESTRICTION_REQUEST,
    types.LISTING_ADD_RESTRICTION_SUCCESS,
    types.LISTING_ADD_RESTRICTION_FAILURE
  ]);
}

export function updateListingRestriction(
  listingId,
  restrictionId,
  restriction
) {
  return api.listings.updateRestriction(listingId, restrictionId, restriction, [
    types.LISTING_UPDATE_RESTRICTION_REQUEST,
    types.LISTING_UPDATE_RESTRICTION_SUCCESS,
    types.LISTING_UPDATE_RESTRICTION_FAILURE
  ]);
}

export function fetchListingRestrictions(listingId) {
  return api.listings.fetchListingRestrictions(listingId, [
    types.FETCH_LISTING_RESTRICTIONS_REQUEST,
    types.FETCH_LISTING_RESTRICTIONS_SUCCESS,
    types.FETCH_LISTING_RESTRICTIONS_FAILURE
  ])
}

export function removeListingRestriction(listingId, id) {
  return api.listings.removeRestriction(listingId, id, [
    types.LISTING_REMOVE_RESTRICTION_REQUEST,
    types.LISTING_REMOVE_RESTRICTION_SUCCESS,
    types.LISTING_REMOVE_RESTRICTION_FAILURE
  ]);
}

export function updateShowingInstructionsChanges(changes) {
  return {
    type: types.LISTING_SHOWING_INSTRUCTIONS_CHANGE,
    changes
  };
}

export function setPreviousAdvanceNoticeDuration(duration) {
  return {
    type: types.LISTING_PREVIOUS_ADVANCE_NOTICE_DURATION,
    duration
  };
}

export function saveShowingInstructionsChanges(listingId, payload) {
  return api.listings.updateShowingInstructions(listingId, payload, [
    types.LISTING_SHOWING_INSTRUCTIONS_SAVE_REQUEST,
    types.LISTING_SHOWING_INSTRUCTIONS_SAVE_SUCCESS,
    types.LISTING_SHOWING_INSTRUCTIONS_SAVE_FAILURE
  ]);
}

export function fetchAvailableMembers(name) {
  return api.myOrganization.fetchAvailableMembers({ name }, [
    types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_REQUEST,
    types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_SUCCESS,
    types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_FAILURE
  ]);
}

export function clearAvailableAgents() {
  return {
    type: types.LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_CLEAR
  };
}

export function fetchListingTeamMembers(listingId) {
  return api.listings.fetchListingTeamMembers(listingId, [
    types.LISTING_TEAM_MEMBERS_REQUEST,
    types.LISTING_TEAM_MEMBERS_SUCCESS,
    types.LISTING_TEAM_MEMBERS_FAILURE
  ]);
}

export function addListingTeamMember(member) {
  return {
    type: types.LISTING_TEAM_MEMBERS_ADD_MEMBER,
    member
  };
}

export function removeListingTeamMember(member) {
  return {
    type: types.LISTING_TEAM_MEMBERS_REMOVE_MEMBER,
    member
  };
}

export function updateListingTeamMember(member) {
  return {
    type: types.LISTING_TEAM_MEMBERS_UPDATE_MEMBER,
    member
  };
}

export function sendBatchTeamMembersUpdate(listingId, payload) {
  return api.listings.updateListingTeamMember(listingId, payload, [
    types.LISTING_TEAM_BATCH_UPDATE_REQUEST,
    types.LISTING_TEAM_BATCH_UPDATE_SUCCESS,
    types.LISTING_TEAM_BATCH_UPDATE_FAILURE
  ]);
}

export function disableSaveButton() {
  return {
    type: types.LISTING_SHOWING_DETAILS_DISABLE_SAVE_BUTTON
  };
}

export function enableSaveButton() {
  return {
    type: types.LISTING_SHOWING_DETAILS_ENABLE_SAVE_BUTTON
  };
}

export function fetchSellerTenantInvolvement(listingId) {
  return api.listings.fetchSellerTenantInvolvement(listingId, [
    types.LISTING_SELLER_TENANT_INVOLVEMENT_REQUEST,
    types.LISTING_SELLER_TENANT_INVOLVEMENT_SUCCESS,
    types.LISTING_SELLER_TENANT_INVOLVEMENT_FAILURE
  ]);
}

export function addSellerOrTenantParticipant(participant) {
  return {
    type: types.LISTING_SELLER_TENANT_ADD_PARTICIPANT,
    participant: {
      ...participant,
      preferredCommunication:
        participant.preferredCommunication || "none_communication",
      showingInvolvement: participant.showingInvolvement || "none_involvement"
    }
  };
}

export function removeSellerOrTenantParticipant(participant) {
  return {
    type: types.LISTING_SELLER_TENANT_REMOVE_PARTICIPANT,
    participant
  };
}

export function sendSellerTenantInvolvementBatchUpdate(listingId, payload) {
  return api.listings.updateSellerTenantInvolvement(listingId, payload, [
    types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_REQUEST,
    types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_SUCCESS,
    types.LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_FAILURE
  ]);
}

export function saveTimeDateRestrictions(changes) {
  return updateShowingDetailsChanges(changes);
}

export function changeSellerTenantPreferredCommunication(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { preferredCommunication: value }
  };
}

export function changeSellerTenantNotifyEmail(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { notifyEmail: value }
  };
}

export function changeSellerTenantNotifyPhone(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { notifyPhone: value }
  };
}

export function changeSellerTenantCanViewFeedback(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { canViewFeedback: value }
  };
}

export function changeSellerTenantFeedbackVisibility(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { sellerTenantFeedbackVisibility: value }
  };
}

export function changeSellerTenantShowingInvolvement(participant, value) {
  return {
    type: types.LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE,
    participant,
    change: { showingInvolvement: value }
  };
}

export function fetchAvailableSellers(id, name) {
  return api.contacts.fetchAvailableSellers(id, name, [
    types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_REQUEST,
    types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_SUCCESS,
    types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_FAILURE
  ]);
}

export function clearAvailableSellers() {
  return {
    type: types.LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_CLEAR
  };
}

export function fetchListingStatuses() {
  return api.listing.fetchListingStatuses(null, [
    types.LISTING_STATUSES_REQUEST,
    types.LISTING_STATUSES_SUCCESS,
    types.LISTING_STATUSES_FAILURE
  ]);
}

export function setCurrentListingStatus(status) {
  return {
    type: types.LISTING_STATUSES_SELECT,
    nextStatus: { ...status }
  };
}

export function toggleListDisplay() {
  return {
    type: types.LISTING_LIST_DISPLAY_TOGGLE
  };
}

export function updateShowingCoordinatorEnabled(id, enabled) {
  return api.listings.updateShowingCoordinatorEnabled(id, enabled, [
    types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST,
    types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS,
    types.LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE
  ]);
}
