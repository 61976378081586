import React, { useState } from "react";
import css from "./Showing.sass";
import { connect } from "react-redux";
import { MARKER_COLORS } from "~brokerage/constants/routes/colors";
import { ALPHABET } from "~brokerage/constants/routes/letters";
import Marker from "./Marker";
import { validateLatLng } from "~brokerage/libs/helpers/GeoHelper";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import TravelTime from "./TravelTime";
import InvalidGeoWarning from "./InvalidGeoWarning";
import { timeToRemoteWithTimeZone } from "~brokerage/libs/helpers/TimeHelper";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import {
  STATUS_CANCELLED,
  STATUS_DECLINED,
  STATUS_NOT_SENT,
  STATUS_PENDING
} from "~brokerage/constants/showings/statuses";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import SmartModalToggle from "~brokerage/components/modals/SmartModalToggle";
import ModalConfirmation from "~brokerage/components/modals/ModalConfirmation";
import {
  titleAllKeys,
  statusIcons
} from "~brokerage/app/constants/appointments.js";
import AlertLineIcon from "remixicon-react/AlertLineIcon";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { updateRouteFromResponse } from "~brokerage/actions/routes";
import AppointmentStatusModal from "~brokerage/components/shared/AppointmentStatusModal";
import Button from "~brokerage/components/shared/Button";
import { fetchShowingRoute } from "~brokerage/actions/routes";
import { statusText } from "~brokerage/constants/appointments";

const Showing = ({
  showing,
  index,
  routeId,
  dispatch,
  originalShowing,
  unsavedChanges
}) => {
  const {
    address,
    status,
    start_time,
    duration,
    travel_time,
    listing_key,
    listing_id,
    unique_id,
    price,
    coordinates,
    conflict
  } = showing;

  const originalStatus = originalShowing?.status?.toLowerCase();

  const titledStatusIcons = titleAllKeys(statusIcons);
  const statusKey = status.toLowerCase();
  const isCancelled = statusKey === STATUS_CANCELLED;
  const isDeclined = statusKey === STATUS_DECLINED;
  const hasValidGeo = validateLatLng(coordinates.lat, coordinates.long);
  const mapLabel = hasValidGeo && ALPHABET[index];
  const fillColor = MARKER_COLORS[status];
  const StatusIcon = statusKey !== STATUS_NOT_SENT && titledStatusIcons[status];
  const statusDisplay =
    statusKey === STATUS_NOT_SENT ? "Not Requested" : statusText[statusKey];
  const startTime = timeToRemoteWithTimeZone(start_time).format("h:mm A");
  const endTime = timeToRemoteWithTimeZone(start_time)
    .add(duration, "minutes")
    .format("h:mm A");
  const timing = `${startTime} - ${endTime} (${duration} minutes)`;
  const priceVal = parseInt(price).toFixed(0);
  const modifier = isCancelled || isDeclined ? "disabled" : "";
  const isConflict = conflict === "unavailable";
  const conflictStyle = isConflict ? { backgroundColor: "#FFEDED" } : {};
  const isRemovable = [
    STATUS_NOT_SENT,
    STATUS_CANCELLED,
    STATUS_DECLINED
  ].includes(originalStatus);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const removeNotSentFromRoute = () => {
    const params = {
      listings: listing_key,
      delete_showings: true
    };

    callApi(`routes/${routeId}/remove_listings`, {}, params, "put")
      .then(response => {
        toast.success("Showing removed successfully.");
        dispatch(updateRouteFromResponse(response.data));
      })
      .catch(error => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  const refreshRoute = () => {
    dispatch(fetchShowingRoute(routeId));
  };

  return (
    <div className={composeClassName(css, "showing", modifier)}>
      {!isCancelled && !isDeclined && travel_time > 0 && (
        <TravelTime travel_time={travel_time} />
      )}
      {!isCancelled && !hasValidGeo && <InvalidGeoWarning />}
      <div className={css.content} style={conflictStyle}>
        <div className={css.timing}>{timing}</div>
        <div className={css.contentLeft}>
          {isConflict && (
            <Tooltip text="Showing is in restricted time.">
              <AlertLineIcon size={22} className={css.alertIcon} />{" "}
            </Tooltip>
          )}
          <div
            className={css.status}
            style={{ backgroundColor: MARKER_COLORS[status] }}
          >
            {statusKey !== STATUS_NOT_SENT && (
              <StatusIcon size={12} className={css.icon} />
            )}
            {statusDisplay}
          </div>
        </div>
      </div>
      <div className={css.details} style={conflictStyle}>
        {hasValidGeo && (
          <div className={css.marker}>
            <Marker fill={fillColor} label={mapLabel} />
          </div>
        )}
        <div className={css.info}>
          <div className={css.address}>{address}</div>
          <div className={css.listingId}>{listing_id}</div>
          <div className={css.price}>{formatPrice(priceVal)}</div>
        </div>
        {isRemovable && (
          <div className={css.actions}>
            <SmartModalToggle
              variant="link"
              modifier="underline colorGray"
              modal={
                <ModalConfirmation
                  heading="Remove Appointment"
                  text="Are you sure?"
                  confirmText="Yes, Remove."
                  confirmVariant="outlineRed"
                  onConfirm={removeNotSentFromRoute}
                  cancelText="No, Keep."
                  warningText={
                    unsavedChanges?.length
                      ? "*Removal will clear unsaved changes from route."
                      : ""
                  }
                  cancelVariant="outlineBlue"
                />
              }
              name={`cancel_showing_${unique_id}`}
            >
              Remove from Route
            </SmartModalToggle>
          </div>
        )}
        {originalStatus === STATUS_PENDING && (
          <div className={css.cancelRouteButton}>
            <Button
              variant="link"
              modifier="underline colorGray"
              onClick={() => setShowCancelModal(true)}
            >
              Cancel Showing
            </Button>
          </div>
        )}
        {showCancelModal && (
          <AppointmentStatusModal
            showingId={unique_id}
            setShowingId={() => {}}
            newStatus={"cancelled"}
            closeModal={() => setShowCancelModal(false)}
            disableModal={() => {}}
            setDisableModal={() => {}}
            fromRoute={true}
            refreshCallback={refreshRoute}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity } = routes.single.route || {};
  const { unsavedChanges } = entity || [];
  return {
    unsavedChanges
  };
};

export default connect(mapStateToProps)(Showing);
