import * as contacts from "./contacts";
import * as listings from "./listings";
import * as listing from "./listing";
import * as messaging from "./messaging";
import * as myAccount from "./myAccount";
import * as myOrganization from "./myOrganization";
import * as people from "./people";
import * as person from "./person";
import * as showings from "./showings";
import * as showing from "./showing";
import * as teams from "./teams";
import * as agents from "./agents";
import * as newShowingForm from "./newShowingForm";
import * as attachments from "./attachments";
import * as settings from "./settings";
import * as routes from "./routes";
import * as notifications from "./notifications";
import * as surveys from "./surveys";
import * as reports from "./reports";

export default {
  contacts,
  listings,
  listing,
  messaging,
  myAccount,
  myOrganization,
  people,
  person,
  showings,
  showing,
  teams,
  agents,
  newShowingForm,
  attachments,
  settings,
  routes,
  notifications,
  surveys,
  reports
};
