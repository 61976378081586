import React from "react";
import { Section, Row } from "~brokerage/components/shared/Form";
import SelectLockbox from "~brokerage/components/shared/AppointmentStatusModal/AppointmentApprove/SelectLockbox";
import ShowingOptions from "~brokerage/components/shared/AppointmentStatusModal/AppointmentApprove/ShowingOptions";
import AdditionalDetails from "~brokerage/components/shared/AppointmentStatusModal/AdditionalDetails";
import Button from "~brokerage/components/shared/Button";

import css from "./index.sass";

const ShowingAccess = ({
  showingAccess: {
    lockboxKind,
    lockboxBrand,
    lockboxCode,
    accompanyAgent,
    accompanySeller,
    requestACall,
    message
  },
  changeDetails,
  isInstructionsEditable,
  resetDetails
}) => (
  <div className={css.showingAccess}>
    <Section>
      <SelectLockbox
        lockboxKind={lockboxKind}
        lockboxBrand={lockboxBrand}
        lockboxCode={lockboxCode}
        changeDetails={changeDetails}
      />
    </Section>
    <Section>
      <ShowingOptions
        accompanyAgent={accompanyAgent}
        accompanySeller={accompanySeller}
        requestACall={requestACall}
        changeDetails={changeDetails}
      />
    </Section>

    <Section heading="Additional Instructions">
      <AdditionalDetails
        message={message}
        changeDetails={changeDetails}
        placeholder="Enter showing instructions"
        isEditable={isInstructionsEditable}
      />
    </Section>

    <Row>
      {isInstructionsEditable && (
        <Button
          type="button"
          float="left"
          variant="outline"
          onClick={resetDetails}
        >
          Clear
        </Button>
      )}
    </Row>
  </div>
);

export default ShowingAccess;
