import React, { Component } from "react";
import PropTypes from "prop-types";
import { EVERYTHING_IS_EMPTY } from "~brokerage/constants/showings/showingDetailsValidation";
import { eventToState } from "~brokerage/libs/helpers/FormHelper";
import { isNotEmpty } from "~brokerage/libs/helpers/ObjectHelper";
import Button from "~brokerage/components/shared/Button";
import {
  Form,
  Section,
  Group,
  Checkbox,
  Textarea,
  Row,
  Errors
} from "~brokerage/components/shared/Form";
import SelectLockbox from "./SelectLockbox";
import { withRouter } from "react-router-dom";

const DEFAULT_STATE = {
  lockboxKind: "",
  lockboxCode: "",
  accompanyAgent: false,
  accompanySeller: false,
  requestACall: false,
  message: "",
  lockboxBrand: ""
};

const errorDescriptions = {
  [EVERYTHING_IS_EMPTY]:
    "Instructions Required: Select access or enter instructions"
};

class EditShowingDetailsForm extends React.PureComponent {
  static propTypes = {
    showingInstructions: PropTypes.object,
    showingHasResponse: PropTypes.bool,
    status: PropTypes.string,
    isSubmitting: PropTypes.bool,
    submitVariant: PropTypes.string,
    submitText: PropTypes.string,
    invalidIfEmpty: PropTypes.bool,
    alwaysSubmit: PropTypes.bool,
    onSave: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      instructions: this.replaceNullsWithDefaults(props.showingInstructions)
    };
    this.previousInstructions = { ...this.state.instructions };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showingInstructions !== nextProps.showingInstructions) {
      this.setState(nextProps.showingInstructions);
      this.previousInstructions = {
        ...this.replaceNullsWithDefaults(nextProps.showingInstructions)
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.errors &&
      prevState.instructions !== this.state.instructions
    ) {
      this.handleValidate();
    }
  }

  replaceNullsWithDefaults(obj) {
    const result = {};
    for (const key in obj) {
      result[key] = obj[key] || DEFAULT_STATE[key];
    }
    return result;
  }

  handleValidate = () => {
    const errors = [];

    if (this.props.invalidIfEmpty) {
      if (!this.areInstructionsNotEmpty()) {
        errors.push(EVERYTHING_IS_EMPTY);
      }
    }

    if (errors.length) {
      this.setState({ errors });
      return false;
    }

    this.setState({ errors: null });
    return true;
  };

  areInstructionsNotEmpty() {
    for (const key in this.state.instructions) {
      if (this.state.instructions[key]) {
        return true;
      }
    }

    return false;
  }

  haveInstructionsChanged() {
    for (const key in this.previousInstructions) {
      if (this.previousInstructions[key] !== this.state.instructions[key]) {
        return true;
      }
    }

    return false;
  }

  closeModal() {
    const { history, location } = this.props;

    history.push({
      ...location,
      query: { ...location.query, modal: void 0 }
    });
  }

  handleSave = () => {
    const showingHasResponse = this.props.showingHasResponse;
    if (
      !this.props.alwaysSubmit &&
      !this.haveInstructionsChanged() &&
      showingHasResponse
    ) {
      return this.closeModal();
    }

    const payload = {};
    const mapping = {
      lockboxKind: "lockbox_kind",
      lockboxCode: "lockbox_code",
      accompanyAgent: "listing_agent_will_accompany",
      accompanySeller: "tenant_will_accompany",
      requestACall: "request_a_call",
      message: "message",
      lockboxBrand: "lockbox_brand"
    };

    for (const key in this.previousInstructions) {
      if (
        !showingHasResponse ||
        this.previousInstructions[key] !== this.state.instructions[key]
      ) {
        payload[mapping[key]] = this.state.instructions[key];
      }
    }

    this.props.onSave(payload);
  };

  handleChange = event => {
    const changes = eventToState(event);
    if (changes.lockboxKind === "") {
      changes.lockboxCode = "";
    }

    this.setState({
      instructions: { ...this.state.instructions, ...changes }
    });
  };

  handleClearClick = () => {
    this.setState({ instructions: DEFAULT_STATE });
  };

  renderShowingOptions() {
    const { accompanyAgent, accompanySeller, requestACall } =
      this.state.instructions;

    return (
      <Group variant="well">
        <Row offset="0">
          <Checkbox
            variant="bigger"
            modifier="rightAligned"
            name="accompanyAgent"
            checked={accompanyAgent}
            onChange={this.handleChange}
          >
            Agent will accompany
          </Checkbox>
        </Row>
        <Row>
          <Checkbox
            variant="bigger"
            modifier="rightAligned"
            name="accompanySeller"
            checked={accompanySeller}
            onChange={this.handleChange}
          >
            Seller will accompany
          </Checkbox>
        </Row>
        <Row>
          <Checkbox
            variant="bigger"
            modifier="rightAligned"
            name="requestACall"
            checked={requestACall}
            onChange={this.handleChange}
          >
            Request call for details
          </Checkbox>
        </Row>
      </Group>
    );
  }

  renderAdditionalInstruction() {
    return (
      <Row offset="0">
        <Textarea
          variant="bigger"
          name="message"
          value={this.state.instructions.message}
          placeholder="Enter showing instructions"
          onChange={this.handleChange}
        />
      </Row>
    );
  }

  render() {
    const { isSubmitting, submitVariant, submitText } = this.props;
    const {
      instructions: { lockboxKind, lockboxCode, lockboxBrand },
      errors
    } = this.state;

    return (
      <Form
        onSubmit={this.handleSave}
        validate
        onValidate={this.handleValidate}
      >
        {errors && (
          <Errors
            variant="form"
            errors={errors}
            descriptions={errorDescriptions}
          />
        )}

        <Section heading="Showing Access">
          <SelectLockbox
            lockboxKind={lockboxKind}
            lockboxCode={lockboxCode}
            lockboxBrand={lockboxBrand}
            onChange={this.handleChange}
          />
        </Section>

        <Section>{this.renderShowingOptions()}</Section>

        <Section heading="Additional Instructions">
          {this.renderAdditionalInstruction()}
        </Section>

        <Row>
          <Button
            disabled={isSubmitting}
            variant={submitVariant || "primary"}
            modifier="bigger"
            type="submit"
            float="left"
          >
            {submitText || "Save Instructions"}
          </Button>

          {isNotEmpty(this.state.instructions) && (
            <Button
              variant="outline"
              modifier="bigger"
              onClick={this.handleClearClick}
              float="right"
            >
              Clear
            </Button>
          )}
        </Row>
      </Form>
    );
  }
}
export default withRouter(EditShowingDetailsForm);
