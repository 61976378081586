import { combineReducers } from 'redux'

import isEqual from 'lodash/isEqual'

import {
  MESSAGES_NEW_RECIPIENTS_ADD_RECIPIENT,
  MESSAGES_NEW_RECIPIENTS_REMOVE_RECIPIENT,
  MESSAGES_NEW_RECIPIENTS_CLEAR,
  MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST,
  MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS,
  MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE,
  MESSAGES_NEW_AVAILABLE_RECIPIENTS_CLEAR,
  MESSAGES_NEW_LEAVE_ONE_RECIPIENT,
  MESSAGES_NEW_PRESERVE_GLOBAL_STATE,
  MESSAGES_NEW_CLEAR_PRESERVE_GLOBAL_STATE,
  MESSAGES_NEW_CLEAR_FORM
} from '~brokerage/constants/actionTypes'

function recipients(state = { entities: [] }, action) {
  switch (action.type) {
    case MESSAGES_NEW_RECIPIENTS_ADD_RECIPIENT:
      return { ...state, entities: [ ...state.entities, action.recipient ] }
    case MESSAGES_NEW_RECIPIENTS_REMOVE_RECIPIENT: {
      return { ...state, entities: state.entities.filter(e => !isEqual(e, action.recipient)) }
    }
    case MESSAGES_NEW_RECIPIENTS_CLEAR:
      return { ...state, entities: [] }
    case MESSAGES_NEW_LEAVE_ONE_RECIPIENT:
      return { ...state, entities: state.entities.slice(0, 1) }
    default:
      return state
  }
}

function availableRecipients(state = { entities: [], isFetching: false }, action) {
  switch (action.type) {
    case MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST:
      return { ...state, isFetching: true }
    case MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.people }
    case MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors }
    case MESSAGES_NEW_AVAILABLE_RECIPIENTS_CLEAR:
      return { ...state, entities: [] }
    default:
      return state
  }
}

function savedMessage(state={ message: '' }, action) {
  switch (action.type) {
    case MESSAGES_NEW_PRESERVE_GLOBAL_STATE:
      return { ...state, message: action.message }
    default:
      return state
  }
}

function preserveGlobalState(state={ shouldPreserveGlobalState: false }, action) {
  switch (action.type) {
    case MESSAGES_NEW_PRESERVE_GLOBAL_STATE:
      return { ...state, shouldPreserveGlobalState: true }
    case MESSAGES_NEW_CLEAR_PRESERVE_GLOBAL_STATE:
      return { ...state, shouldPreserveGlobalState: false }
    default:
      return state
  }
}

function shouldPreserveGlobalState(state){
  return state && state.preserveGlobalState && state.preserveGlobalState.shouldPreserveGlobalState
}

const reducer = combineReducers({
  recipients,
  availableRecipients,
  savedMessage,
  preserveGlobalState
})

const reducerContainer = (state, action) => {
  if (action.type === MESSAGES_NEW_CLEAR_FORM && !shouldPreserveGlobalState(state)) {
    return reducer(void 0, action)
  }
  return reducer(state, action)
}

export default reducerContainer
