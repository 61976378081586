import React from "react";
import InstructionsList from "./InstructionsList";
import {
  StatusChangeText,
  RequestText,
  TimeChangeText,
  RequestACallText
} from "./MessageUtils";

const StatusChange = ({ message, action, showInstructions = true }) => {
  const { explanation, instructions } = message;
  return (
    <>
      <StatusChangeText message={message} action={action} />
      {showInstructions && (
        <InstructionsList
          instructions={instructions}
          explanation={explanation}
        />
      )}
    </>
  );
};

export const Pending = ({ message }) => <RequestText message={message} />;

export const Approved = ({ message }) => (
  <StatusChange message={message} action="Approved" />
);

export const Preapproved = ({ message }) => (
  <StatusChange message={message} action="Pre-approved" />
);

export const Declined = ({ message }) => (
  <StatusChange message={message} action="Declined" />
);

export const Cancelled = ({ message }) => (
  <StatusChange message={message} action="Cancelled" />
);

export const RequestACall = ({ message }) => (
  <RequestACallText message={message} />
);

export const TimeChange = ({ message }) => <TimeChangeText message={message} />;

export const InstructionChange = ({ message }) => (
  <InstructionsList instructions={message.instructions} />
);

export const EventText = ({ message: { msg } }) => msg;
