import React from "react";

import * as reasons from "~brokerage/constants/showings/noShowReasons";
import { Radio } from "~brokerage/components/shared/Form";
import List from "~brokerage/components/shared/List";

const ROWS = [
  {
    text: "Requested time unavailable",
    value: reasons.TIME_UNAVAILABLE
  },
  {
    text: "Property unavailable for showing",
    value: reasons.UNAVAILABLE_FOR_SHOWING
  },
  {
    text: "Seller is unavailable to attend",
    value: reasons.SELLER_UNAVAILABLE
  },
  {
    text: "Listing agent is unavailable to attend",
    value: reasons.LISTING_AGENT_UNAVAILABLE
  }
];

const ShowingExplanation = ({ details, changeDetails }) => {
  return (
    <List variant="bordered" modifier="striped">
      {ROWS.map((row, index) => (
        <List.Item key={`ShowingExplanation-${index}`}>
          <Radio
            variant="listItem"
            modifier="rightAligned"
            value={row.value}
            checked={details.explanation === row.value}
            onChange={() => changeDetails({ explanation: row.value })}
          >
            {row.text}
          </Radio>
        </List.Item>
      ))}
    </List>
  );
};

export default ShowingExplanation;
