import React from "react";
import css from "./RadioButton.sass";

const RadioButton = ({ id, label, checked, onChange }) => {
  return (
    <div
      id={id}
      className={checked ? css.selectedAnswerContainer : css.answerContainer}
      onClick={onChange}
    >
      <input
        type="radio"
        checked={checked}
        className={css.radioButton}
        readOnly={true}
      />
      {label}
    </div>
  );
};

export default RadioButton;
