import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Content from "~brokerage/components/shared/Content";
import PropertySearch from "brokerage/app/components/propertySearch";

export const UserContext = createContext({});

const Search = (props, context) => (
  <UserContext.Provider value={context}>
    <Content style="noPadding">
      <Switch>
        <Route path={`${props.match.path}/`}>
          <PropertySearch variant="showing" location={props.location} />
        </Route>
      </Switch>
    </Content>
  </UserContext.Provider>
);

Search.contextTypes = {
  currentUser: PropTypes.object,
  clientData: PropTypes.object
};

Search.propTypes = {
  match: PropTypes.object
};

export default Search;
