import React, { useState } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";

import css from "./FeedbackSurvey.sass";
import {
  Row,
  Label,
  Controls,
  Dropdown,
  BigRadio
} from "~brokerage/components/shared/Form";
import Switch from "~brokerage/components/shared/Switch";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Tooltip from "~brokerage/components/shared/Tooltip";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import EditSurveyModal from "~brokerage/components/shared/EditSurveyModal";
import SurveyTypeSelect from "~brokerage/components/shared/Survey/SurveyTypeSelect";
import {
  fetchUserSurvey,
  updateDefaultSurveySettings
} from "~brokerage/actions/surveys";
import InformationLineIcon from "remixicon-react/InformationLineIcon";
import {
  FEEDBACK_ATTEMPT_OPTIONS,
  DEFAULT_FEEDBACK_ATTEMPT_OPTION,
  FEEDBACK_ATTEMPT_TOOLTIP
} from "../constants";

const FeedbackSurvey = ({ dispatch, initialSettings }) => {
  const [settings, setSettings] = useState(initialSettings);
  const [editSurvey, setEditSurvey] = useState(null);
  const {
    survey_id,
    use_default_survey,
    allow_feedback,
    survey_name,
    show_feedback_analysis,
    feedback_attempt_count: feedbackAttemptCount
  } = settings;

  const updateSettings = newSettings =>
    setSettings({ ...settings, ...newSettings });

  const handleDone = () => {
    dispatch(updateDefaultSurveySettings(settings));
  };

  const handleSurveyChange = ({ id, name }) => {
    if (id === "new") setEditSurvey("new");
    else
      updateSettings({
        survey_id: id,
        survey_name: name
      });
  };

  const handleDefaultToggle = event => {
    const checked = event.target.value === "default";
    updateSettings({ use_default_survey: checked });
  };

  const openSurvey = surveyId => {
    dispatch(fetchUserSurvey(surveyId));
    setEditSurvey(surveyId);
  };

  const disableDone =
    !use_default_survey && !survey_id
      ? "Attach a survey"
      : isEqual(settings, initialSettings)
      ? "No changes made"
      : false;

  return (
    <>
      <Row className={css.rowStyle}>
        <Label valign="switch">Allow Feedback?</Label>
        <Controls>
          <Switch
            name="allow_feedback"
            checked={allow_feedback}
            onChange={event =>
              updateSettings({ allow_feedback: event.target.checked })
            }
          />
        </Controls>
      </Row>
      {allow_feedback && (
        <Row className={css.rowStyle}>
          <Label valign="dropdown">
            <span>
              <Tooltip
                className={css.feedbackReminderLabel}
                text={FEEDBACK_ATTEMPT_TOOLTIP}
                style={{ display: "flex", alignItems: "center" }}
              >
                Send feedback reminder
                <InformationLineIcon
                  color="#999"
                  size={14}
                  className={css.feedbackReminderIcon}
                />
              </Tooltip>
            </span>
          </Label>
          <Controls>
            <Dropdown
              name="feedback_attempt_count"
              options={FEEDBACK_ATTEMPT_OPTIONS}
              selected={
                feedbackAttemptCount || DEFAULT_FEEDBACK_ATTEMPT_OPTION.value
              }
              onChange={updateSettings}
              variant="outline2"
            />
          </Controls>
        </Row>
      )}
      <Row className={css.rowStyle}>
        <Label valign="switch">Show Feedback Analysis?</Label>
        <Controls>
          <Switch
            name="show_feedback_analysis"
            checked={show_feedback_analysis}
            onChange={event =>
              updateSettings({ show_feedback_analysis: event.target.checked })
            }
          />
        </Controls>
      </Row>
      {allow_feedback && (
        <>
          <Row className={css.rowStyle}>
            <Label valign="switch">Use MLS default feedback survey?</Label>
            <Controls>
              <BigRadio
                icon="templateDotted"
                heading="Use Default survey"
                value="default"
                checked={use_default_survey}
                onChange={handleDefaultToggle}
              />
              <BigRadio
                icon="templateEdit"
                heading="Use Custom survey"
                value="custom"
                checked={!use_default_survey}
                onChange={handleDefaultToggle}
              />
            </Controls>
          </Row>
          <Row className={css.rowStyle}>
            <Label variant="hidden">Custom Survey</Label>
            <Controls>
              {use_default_survey ? (
                <Button
                  variant="outline"
                  onClick={() => {
                    openSurvey("default");
                  }}
                >
                  <div className={css.buttonContent}>
                    <EyeLineIcon size={16} className={css.icon} />
                    View Default Survey
                  </div>
                </Button>
              ) : (
                <SurveyTypeSelect
                  listingSurveyId={survey_id}
                  listingSurveyName={survey_name}
                  onSelectSurvey={({ id, label }) =>
                    handleSurveyChange({ id, name: label })
                  }
                  onEditSurvey={() => {
                    openSurvey(survey_id);
                  }}
                />
              )}
            </Controls>
          </Row>
        </>
      )}
      <Row>
        <ButtonSet align="right">
          <Tooltip
            disabled={!disableDone}
            text={disableDone || ""}
            direction="up"
            variant="error"
          >
            <Button
              variant="primary"
              onClick={handleDone}
              disabled={!!disableDone}
            >
              Done
            </Button>
          </Tooltip>
        </ButtonSet>
      </Row>
      {!!editSurvey && (
        <EditSurveyModal
          surveyId={editSurvey}
          closeModal={() => setEditSurvey(null)}
          onUpdate={handleSurveyChange}
        />
      )}
    </>
  );
};

export default connect()(FeedbackSurvey);
