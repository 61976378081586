import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import some from 'lodash/some'
import RecipientAutocomplete from './RecipientAutocomplete'
import Recipients from './Recipients'
import {
  fetchAvailableDirectRecipients,
  fetchAvailableGroupRecipients,
  clearAvailableRecipients
} from '~brokerage/actions/messages'
export const MODES = {
  ONE: 1,
  MANY: 2
}

class RecipientsInput extends React.PureComponent {
  static propTypes = {
    recipients: PropTypes.array,
    availableRecipients: PropTypes.array,
    isFetching: PropTypes.bool,
    handleAddRecipient: PropTypes.func,
    handleRemoveRecipient: PropTypes.func,
    mode: PropTypes.number,
    dispatch: PropTypes.func
  }

  componentWillMount() {
    this.props.dispatch(clearAvailableRecipients())
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.mode !== nextProps.mode) {
      this.props.dispatch(clearAvailableRecipients())
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAvailableRecipients())
  }

  handleSelect = (option) => {
    if(this.props.mode == MODES.ONE && this.props.recipients.length > 0) {
      this.props.dispatch(clearAvailableRecipients())
      return
    }
    for (const recipient of this.props.availableRecipients) {
      if (recipient.userId === option.value) {
        this.props.dispatch(clearAvailableRecipients())
        this.props.handleAddRecipient(recipient)
        return
      }
    }
  }

  get availableRecipientsWithoutCurrent() {
    return this.props.availableRecipients.filter(({userId}) => !some(this.props.recipients, { userId }))
  }

  autocompleteHandleChange = (value) => {
    clearTimeout(this.fetchAvailableRecipientsTimeout)

    if (value.length > 2) {
      this.fetchAvailableRecipientsTimeout = setTimeout(() => {
        if (this.props.mode === MODES.ONE) {
          this.props.dispatch(fetchAvailableDirectRecipients(value))
        } else {
          this.props.dispatch(fetchAvailableGroupRecipients(value))
        }
      }, 200)
    }
  }

  autocompleteHandleKeyDown = (event) => {
    const { recipients } = this.props
    const inputValue = event.target.value

    if (!inputValue.length && event.key === 'Backspace') {
      const recipient = recipients[recipients.length - 1]

      if (recipient) {
        this.props.handleRemoveRecipient(recipient)
      }
    }
  }

  handleRecipientsInputClick = () => {
    const input = ReactDOM.findDOMNode(this).querySelector('input[type="text"]')

    if (input) {
      input.focus()
    }
  }

  recipientAutocompletePlaceholder() {
    if (this.props.recipients.length) {
      return ''
    } else if (this.props.mode == MODES.MANY){
      return 'Enter recipients'
    } else {
      return 'Enter recipient'
    }
  }

  autocompleteEnabled() {
    return this.props.mode == MODES.MANY || this.props.recipients.length < 1
  }

  render() {
    return (
      <div className={css.recipientsInput} onClick={this.handleRecipientsInputClick}>
        <Recipients recipients={this.props.recipients} />
        <RecipientAutocomplete
          people={this.availableRecipientsWithoutCurrent}
          onSelect={this.handleSelect}
          onChange={this.autocompleteHandleChange}
          fetching={this.props.isFetching}
          placeholder={this.recipientAutocompletePlaceholder()}
          onKeyDown={this.autocompleteHandleKeyDown}
          enabled={this.autocompleteEnabled()}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { entities: availableRecipients, isFetching } = state.messages.new.availableRecipients

  return {
    availableRecipients,
    isFetching
  }
}
export default connect(mapStateToProps)(RecipientsInput)
