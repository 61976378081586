import React from "react";
import PropTypes from "prop-types";
import {
  plural,
  formatPrice,
  formatNumberUsingCommas
} from "~brokerage/libs/helpers/FormatHelper";
import List from "~brokerage/components/shared/List";
import PropertySpecItem from "./PropertySpecItem";
import { ICONS } from "./constants";

const PropertySpecs = ({ mls, price, beds, baths, sqft, agents }) => (
  <List variant="small">
    <PropertySpecItem data={mls} icon={ICONS.mls} />
    <PropertySpecItem
      renderWhenZero
      data={price}
      icon={ICONS.price}
      formatFunction={formatPrice}
      additionalModifier="iconOffset1"
    />
    <PropertySpecItem
      renderWhenZero
      data={beds}
      icon={ICONS.beds}
      suffix={`bed${plural(beds)}`}
    />
    <PropertySpecItem
      renderWhenZero
      data={baths}
      icon={ICONS.baths}
      suffix={`bath${plural(baths)}`}
    />
    <PropertySpecItem
      renderWhenZero
      data={sqft}
      icon={ICONS.sqft}
      formatFunction={formatNumberUsingCommas}
      suffix="sqft"
    />
    <PropertySpecItem data={agents?.join(", ")} icon={ICONS.agents} />
  </List>
);

PropertySpecs.propTypes = {
  mls: PropTypes.string,
  price: PropTypes.number,
  beds: PropTypes.number,
  baths: PropTypes.number,
  sqft: PropTypes.number,
  agents: PropTypes.array
};
export default PropertySpecs;
