import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "mapbox-gl/dist/mapbox-gl.css";
import css from "../index.sass";
import initializeMap from "./util/initializeMap.js";
import plotListings from "./util/plotListings.js";
import plotGeometry from "./util/plotGeometry.js";
import zoomToListings from "./util/zoomToListings.js";
import toGeoJson from "./util/toGeoJson.js";

let map;

const Map = ({ showings, map_geometry, mapboxkey }) => {
  const [mapReady, setMapReady] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current && !mapReady) {
      init();
    }
    return () => {
      setMapReady(false);
    };
  }, [mapRef.current]);

  const init = async () => {
    map = initializeMap(mapRef, mapboxkey);
    map.once("load", () => {
      setMapReady(true);
    });
  };

  useEffect(() => {
    if (showings.length && mapReady) {
      const geoData = toGeoJson(showings);
      if (geoData) {
        plotGeometry(map, map_geometry);
        plotListings(map, geoData);
        zoomToListings(map, geoData);
      }
    }
  }, [showings, mapReady, map_geometry]);

  return <div ref={mapRef} className={css.map} />;
};

Map.prototypes = {
  map_geometry: PropTypes.object,
  showings: PropTypes.array,
  mapboxAPIKey: PropTypes.string
};

export default Map;
