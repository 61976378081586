import React from "react";
import PropTypes from "prop-types";
import config from "~brokerage/config/config";
import TimeRange from "./TimeRange";
import { Row } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";

const TimeRangeList = props => {
  const {
    timeRanges,
    onRangeChange,
    onAdd,
    onRemove
  } = props;

  const  handleAddClick = () => {
    onAdd({
      startAt: `${config.defaultTimeRangeStartHour}:00`,
      endAt: `${config.defaultTimeRangeEndHour}:00`
    })
  };

  return (
    <div>
      {timeRanges && timeRanges.map((range, index) =>
        <TimeRange key={index}
                   id={index}
                   range={range}
                   onRangeChange={onRangeChange}
                   onRemove={onRemove}
        />
      )}
      <Row offset="10">
        <Button variant="link" onClick={handleAddClick}>
          <Icon name="plus"/>
          Add Another
        </Button>
      </Row>
    </div>
  )
};

TimeRangeList.propTypes = {
  timeRanges: PropTypes.array,
  onRangeChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

export default TimeRangeList;
