import React from 'react';
import SettingsApp from './SettingsApp';
import SettingsProvider from './context/SettingsContext';
import PropTypes from 'prop-types';


const Settings = props => (
  <SettingsProvider>
    <SettingsApp { ...props }/>
  </SettingsProvider>

)

Settings.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func
}
export default Settings;
