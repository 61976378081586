import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import InstructionsList from "../InstructionsList";

const explanations = {
  time_unavailable: "Requested Time Unavailable",
  unavailable_for_showing: "Property Unavailable For Showing",
  property_sold: "Property Sold",
  property_under_contract: "Property Under Contract",
  seller_unavailable: "Seller Unavailable To Attend",
  listing_agent_unavailable: "Listing Agent Unavailable To Attend",
  client_availability_changed: "Client Availability Changed",
  my_availability_changed: "Showing Team Availability Changed",
  client_not_interested: "Client No Longer Interested",
  scheduled_in_error: "Scheduled In Error"
};

const Declined = ({ message, bubbleOptions }) => (
  <Bubble variant="red" {...bubbleOptions}>
    <p className={css.p}>
      {(() => {
        if (message.isInstant) {
          return [<b>Instant Decline:</b>, <br />];
        }
      })()}
      {message.senderName} <b>Declined</b> the showing request on{" "}
      <b className={css.nobr}>{message.requestDate}</b> from{" "}
      <b className={css.nobr}>
        {message.requestStartTime} to {message.requestEndTime}
      </b>
      .
    </p>

    {(() => {
      if (message.explanation) {
        return <p className={css.p}>{explanations[message.explanation]}</p>;
      }
    })()}
    <InstructionsList instructions={message.instructions} />
  </Bubble>
);

Declined.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Declined;
