import React from "react";
import css from "./Marker.sass";

function SvgMarker({ fill, label }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40px"
      width="25px"
      viewBox="3.5 3.5 12 12"
      className={css.marker}
    >
      <path
        d="M9.5 2C7.168 2 5 4 5 6.5c0 2.528 3.417 7.056 4.5 8.5 1.083-1.444 4.5-5.972 4.5-8.5C14 4 11.832 2 9.5 2z"
        style={{
          marker: "none"
        }}
        fill={fill}
        overflow="visible"
      />
      <circle fill="#fff" cx="9.5" cy="6.8" r="3.5" />
      <text
        x="7.3"
        y="9"
        textRendering="geometricPrecision"
        className={css.label}
      >
        {label}
      </text>
    </svg>
  );
}

export default SvgMarker;
