import css from './Item.sass'
import React from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Icon from '../Icon'

const Item = (props) => {
  return (
    <li className={composeClassName(css, props.variant, props.modifier)}>
      {(() => {
        if (props.icon) {
          return (
            <div className={css.icon}>
              <Icon name={props.icon}/>
            </div>
          )
        }
      })()}
      <span className={css.text}>
        {props.children}
      </span>
    </li>
  )
}

Item.propTypes = {
  variant: PropTypes.string,
  modifier: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node
}
export default Item
