import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import { connect } from "react-redux";

import { fetchListings } from "~brokerage/actions/listings";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import Loader from "~brokerage/components/shared/Loader";

import NoCoordinatorMessage from "./NoCoordinatorMessage";
import TableView from "./TableView";
import CardView from "./CardView";
import css from "./index.sass";

const Listings = (props, context) => {
  const {
    displayCards,
    dispatch,
    location,
    listings,
    // agent,
    isFetching,
    currentPage,
    totalPages,
    activeListingStatuses
  } = props;

  const agentId = location.query.agent;

  const needLoadMoreListings = listIndex => {
    const hasMoreListings = currentPage < totalPages;
    const isItemLastOfLoaded = listings.length - listIndex === 1;
    return !isFetching && hasMoreListings && isItemLastOfLoaded;
  };

  const loadMoreListings = () => {
    dispatch(fetchListings(location.query, currentPage + 1));
  };

  useEffect(() => {
    if (!isUndefined(agentId)) {
      dispatch(fetchListings(location.query));
    }
  }, [JSON.stringify(omit(location.query, "modal"))]);

  const renderBody = () => {
    if (listings.length > 0) {
      if (displayCards) {
        return (
          <CardView
            agentId={agentId}
            listings={listings}
            needLoadMoreListings={needLoadMoreListings}
            loadMoreListings={loadMoreListings}
            dispatch={dispatch}
            activeListingStatuses={activeListingStatuses}
          />
        );
      } else {
        return (
          <TableView
            agentId={agentId}
            listings={listings}
            needLoadMoreListings={needLoadMoreListings}
            loadMoreListings={loadMoreListings}
          />
        );
      }
    }
    if (isFetching) {
      return <Loader active />;
    }
    if (context.currentUser.isShowingCoordinator) {
      return <NoCoordinatorMessage dispatch={dispatch} />;
    }
    return <EmptyMessage>Nothing to show.</EmptyMessage>;
  };

  return (
    <div className={css.base}>
      <div
        className={
          displayCards && listings.length > 0 ? css.cardInner : css.inner
        }
      >
        {renderBody()}
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { entity: agent } = state.people.single.person;
  const {
    entities: listings,
    page: currentPage,
    totalPages,
    isFetching
  } = state.listings.list.listings;
  const { activeListingStatuses } = state.currentUser;
  const { displayCards } = state.listings.list.display;
  return {
    listings,
    currentPage,
    totalPages,
    agent,
    isFetching,
    displayCards,
    activeListingStatuses
  };
}

Listings.contextTypes = {
  currentUser: PropTypes.object
};

Listings.propTypes = {
  displayCards: PropTypes.bool,
  listings: PropTypes.array,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  agent: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(Listings));
