import React from "react";
import { withRouter } from "react-router-dom";

import css from "./index.sass";
import ModalAddSellerTenant from "./ModalAddSellerTenant";
import ModalAgentDetails from "./ModalAgentDetails";
import ModalApplyTemplate from "./ModalApplyTemplate";
import ModalLogCall from "./ModalLogCall";
import ModalSavedReplies from "./ModalSavedReplies";
import ModalPastMessages from "./ModalPastMessages";
import ModalTeamInstructions from "./ModalTeamInstructions";
import ModalNewMessage from "./ModalNewMessage";
import ModalNewShowing from "./ModalNewShowing";
import ModalUpgradeToPremium from "./ModalUpgradeToPremium";
import ModalInviteUser from "./ModalInviteUser";
import ModalCreateRoute from "./ModalCreateRoute";
import ModalCreateCustomer from "./ModalCreateCustomer";
import ModalEditCustomer from "./ModalEditCustomer";
import ModalDeleteCustomer from "./ModalDeleteCustomer";
import ModalEditRoute from "./ModalEditRoute";
import ModalAddAppointments from "./ModalAddAppointments";
import ModalMaxSelected from "./ModalMaxSelected";
import ModalEditAppointmentTime from "./ModalEditAppointmentTime";
import ModalCreateShowing from "./ModalCreateShowing";
import ModalImpersonateUser from "./ModalImpersonateUser";
import ModalExportContacts from "./ModalExportContacts";
import ModalSendListingBroadcastMessage from "./ModalSendListingBroadcastMessage";
import AddStopModal from "./AddStopModal";
import ModalDeleteSurveyConfirm from "./ModalDeleteSurveyConfirm";
import ModalUpdateContact from "./ModalUpdateContact";

const Modals = props => {
  const modals = {
    agent_details: ModalAgentDetails,
    log_call: ModalLogCall,
    saved_replies: ModalSavedReplies,
    past_messages: ModalPastMessages,
    apply_template: ModalApplyTemplate,
    team_instructions: ModalTeamInstructions,
    new_message: ModalNewMessage,
    add_seller_tenant: ModalAddSellerTenant,
    upgrade_to_premium: ModalUpgradeToPremium,
    invite_user: ModalInviteUser,
    create_route: ModalCreateRoute,
    create_showing: ModalCreateShowing,
    edit_route: ModalEditRoute,
    add_route_appointments: ModalAddAppointments,
    create_customer: ModalCreateCustomer,
    edit_customer: ModalEditCustomer,
    delete_customer: ModalDeleteCustomer,
    delete_survey: ModalDeleteSurveyConfirm,
    max_selected: ModalMaxSelected,
    edit_appointment_time: ModalEditAppointmentTime,
    new_showing: ModalNewShowing,
    impersonate_user: ModalImpersonateUser,
    export_contacts: ModalExportContacts,
    send_listing_broadcast_message: ModalSendListingBroadcastMessage,
    add_stop: AddStopModal,
    update_contact: ModalUpdateContact
  };

  const handleOverlayClick = event => {
    if (event.target.className === css.base) {
      const { modal, ...otherParams } = props.location.query;

      props.history.push({
        pathname: props.location.pathname,
        query: otherParams
      });
    }
  };

  if (!props.location.query.modal) {
    return false;
  }

  const Modal = modals[props.location.query.modal];

  if (!Modal) {
    return false;
  }

  return (
    <div>
      <div>
        <div className={css.base} onClick={handleOverlayClick}>
          <Modal location={props.location} />
        </div>
        <div className={css.overlay} />
      </div>
    </div>
  );
};

export default withRouter(Modals);
