import React, { useRef, useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import css from "../index.sass";

import initializeMap from "./util/initializeMap.js";
import plotListings from "./util/plotListings.js";
import zoomToListings from "./util/zoomToListings.js";
import toGeoJson from "./util/toGeoJson.js";

let map;

const Map = props => {
  const { listings, mapboxkey, setHighlightedListing, selected } = props;
  const [mapReady, setMapReady] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current && !mapReady) {
      init();
    }
    return () => {
      setMapReady(false);
    };
  }, [mapRef.current]);

  const init = async () => {
    map = initializeMap(mapRef, mapboxkey, setHighlightedListing);
    map.once("load", () => {
      setMapReady(true);
    });
  };

  useEffect(() => {
    if (listings && mapReady) {
      const data = toGeoJson(listings, selected);
      plotListings(map, data);
      zoomToListings(map, data);
    }
  }, [listings, mapReady]);

  useEffect(() => {
    const searchResultsSource = map.getSource("search-results");
    if (searchResultsSource) {
      const data = toGeoJson(listings, selected);
      searchResultsSource.setData(data);
    }
  }, [selected]);

  return <div ref={mapRef} className={css.map} />;
};

export default Map;
