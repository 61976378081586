import css from "./misc.sass";
import React from "react";
import Tooltip from "~brokerage/components/shared/Tooltip";

export const SellersTenants = ({ sellersTenants }) => {
  const [firstContact, ...otherContacts] = sellersTenants;
  const othersCount = otherContacts.length;
  if (firstContact) {
    return (
      <div className={css.personName}>
        {othersCount >= 1 ? (
          <>
            <b>{firstContact}</b>
            <span className={css.personLabel}>
              <Tooltip text={otherContacts.join(", ")}>
                {` + ${othersCount} Seller Contacts`}
              </Tooltip>
            </span>
          </>
        ) : (
          <>
            <b>
              {firstContact}
              {" · "}
            </b>
            <span className={css.personLabel}>{"Seller Contact"}</span>
          </>
        )}
      </div>
    );
  } else {
    return <div className={css.personLabel}>No Seller Contacts</div>;
  }
};

export const Address = ({ addressText }) => {
  const EOL = addressText.indexOf(",");
  const street = addressText.slice(0, EOL + 1);
  const state = addressText.slice(EOL + 2);
  return (
    <>
      <div className={css.street}>{street}</div>
      <div className={css.state}>{state}</div>
    </>
  );
};

const counterText = count => {
  if (count === 0) {
    return "No";
  } else if (count > 99) {
    return "99+";
  } else {
    return `${count}`;
  }
};

export const Counter = ({ count, label, suffix }) => {
  const labelText = count == 1 ? label : label + suffix;

  return (
    <span>
      <b>{counterText(count)}</b> {labelText}
    </span>
  );
};
