import React from "react";
import Content from "~brokerage/components/shared/Content";
import Details from "~brokerage/app/components/support/Details";

const Support = () => (
  <Content style="noPadding">
    <Details />
  </Content>
);

export default Support;
