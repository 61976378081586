export const DEFAULT_FEEDBACK_ATTEMPT_OPTION = {
  value: 2,
  label: "2 reminders"
};

export const FEEDBACK_ATTEMPT_OPTIONS = [
  DEFAULT_FEEDBACK_ATTEMPT_OPTION,
  { value: 3, label: "3 reminders" },
  { value: 4, label: "4 reminders" },
  { value: 5, label: "5 reminders" },
  { value: 6, label: "6 reminders" }
];

export const FEEDBACK_ATTEMPT_TOOLTIP =
  "Feedback request notifications are sent to the showing agent one hour after the showing has concluded, with a reminder sent one day after the showing. Use this setting to send additional feedback request reminders, which will be sent once per day.";
