import css from './ModalDownloadApp.sass'
import React, { Component } from 'react'
import { IOS_STORE_URL, ANDROID_STORE_URL } from '~brokerage/constants/links'
import { Header, Body } from '~brokerage/components/modals/BaseModal'
import Button from '~brokerage/components/shared/Button'

export default class ModalApplyTemplate extends React.PureComponent {
  render() {
    return (
      <div className={css.base}>
        <Header title="Agent Inbox Will Change the Way You Work"/>

        <Body>
          <p className={css.p}>Agent Inbox seamlessly transitions you work<br/> from your desktop to your phone and back.</p>

          <div className={css.buttons}>
            <div className={css.btn}>
              <Button href={IOS_STORE_URL} variant="image">
                <i className={css.iconIOS}/>
              </Button>
            </div>

            <div className={css.btn}>
              <Button href={ANDROID_STORE_URL} variant="image">
                <i className={css.iconAndroid}/>
              </Button>
            </div>
          </div>
        </Body>
      </div>
    )
  }
}
