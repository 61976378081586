export const LOCKBOX_TYPE_NONE = ''
export const LOCKBOX_TYPE_COMBO = 'combo'
export const LOCKBOX_TYPE_ELECTRONIC = 'electronic'
export const DEFAULT_LOCKBOX_TYPE = { value: LOCKBOX_TYPE_NONE, label: 'None' }
export const LOCKBOX_TYPES = [
  DEFAULT_LOCKBOX_TYPE,
  { value: LOCKBOX_TYPE_COMBO, label: 'Combo' },
  { value: LOCKBOX_TYPE_ELECTRONIC, label: 'Electronic' }
]

export const COMBO_MAX_LENGTH = 50
