import { combineReducers } from "redux";

import listReducer from "./list";
import singleReducer from "./single";
import defaultSettingsReducer from "./defaultSettings";

export default combineReducers({
  list: listReducer,
  single: singleReducer,
  defaultSettings: defaultSettingsReducer
});
