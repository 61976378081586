/* eslint-disable no-console */

import * as events from "~brokerage/constants/pubnubEvents";

import ShowingMessageHandler from "./handlers/ShowingMessageHandler";
import DirectMessageHandler from "./handlers/DirectMessageHandler";
import SupportMessageHandler from "./handlers/SupportMessageHandler";
import UnreadMessagesCountsHandler from "./handlers/UnreadMessagesCountsHandler";
import UnreadNotificationsHandler from "./handlers/UnreadNotificationsHandler";
import PreferencesHandler from "./handlers/PreferencesHandler";
import ShowingMessageHasReadHandler from "./handlers/ShowingMessageHasReadHandler";
import DirectMessageHasReadHandler from "./handlers/DirectMessageHasReadHandler";

export default class PubnubEventDispatcher {
  constructor(props) {
    this.props = props;
  }

  updateProps(newProps) {
    this.props = newProps;
  }

  dispatch(message) {
    const messageData = message.data;

    switch (message.type) {
      case events.SHOWING_MESSAGE:
        return new ShowingMessageHandler(messageData, this.props).handle();
      case events.DIRECT_MESSAGE:
        return new DirectMessageHandler(messageData, this.props).handle();
      case events.SUPPORT_MESSAGE:
        return new SupportMessageHandler(messageData, this.props).handle();
      case events.UNREAD_MESSAGES_COUNTS:
        return new UnreadMessagesCountsHandler(
          messageData,
          this.props
        ).handle();
      case events.UNREAD_NOTIFICATIONS:
        return new UnreadNotificationsHandler(messageData, this.props).handle();
      case events.PREFERENCES:
        return new PreferencesHandler(messageData, this.props).handle();
      case events.SHOWING_MESSAGE_READ_RECEIPT:
        return new ShowingMessageHasReadHandler(
          messageData,
          this.props
        ).handle();
      case events.DIRECT_MESSAGE_READ_RECEIPT:
        return new DirectMessageHasReadHandler(
          messageData,
          this.props
        ).handle();
      default:
        console.warn("Unresolved pubnub message:", message);
    }
  }
}
