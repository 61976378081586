import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Dropdown } from '~brokerage/components/shared/Form'
import range from 'lodash/range'
import { objectToDatetime } from '~brokerage/libs/helpers/TimeHelper'

export default class Timepicker extends React.PureComponent {
  static propTypes = {
    hour: PropTypes.string,
    minute: PropTypes.string,
    period: PropTypes.string,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.hours = [12, ...range(1, 12)].map(_hour => {
      const hour = String(_hour)
      return { value: hour, label: hour }
    })

    this.minutes = range(0, 60, 5).map(_minute => {
      const minute = _minute < 10 ? `0${_minute}`: String(_minute)
      return { value: minute, label: minute }
    })

    this.periods = [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM' }
    ]
  }

  handleChange = (value) => {
    const { hour, minute, period } = this.props
    const obj = Object.assign({ hour, minute, period }, value)
    this.props.onChange(objectToDatetime(obj))
  }

  render() {
    const { hour, minute, period } = this.props

    return (
      <div>
        <Col>
          <Dropdown
            options={this.hours}
            selected={hour}
            name="hour"
            variant="outline2"
            onChange={this.handleChange}
          />
        </Col>
        <Col modifier="offset5">
          <Dropdown
            options={this.minutes}
            selected={minute}
            name="minute"
            variant="outline2"
            onChange={this.handleChange}
          />
        </Col>
        <Col modifier="offset5">
          <Dropdown
            options={this.periods}
            selected={period}
            name="period"
            variant="outline2"
            onChange={this.handleChange}
          />
        </Col>
      </div>
    )
  }
}
