import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import SellerLandingPage from "~brokerage/app/components/seller/SellerLandingPage";
import UnsubscribeEmailPage from "~brokerage/app/components/seller/UnsubscribeEmailPage";

const LandingRoutes = ({ history, localDetails }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/"
          exact={true}
          render={() => <SellerLandingPage localDetails={localDetails} />}
        />
        <Route
          path="/unsubscribe"
          exact={true}
          render={() => <UnsubscribeEmailPage localDetails={localDetails} />}
        />
      </Switch>
    </Router>
  );
};

export default LandingRoutes;
