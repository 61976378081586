import css from "./Form.sass";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { focus, changeText } from "~brokerage/actions/messageField";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import Button from "~brokerage/components/shared/Button";
import MessageTextarea from "./MessageTextareaContainer";
import ShowingActions from "./ShowingActions";
import Tabs from "~brokerage/components/shared/Tabs";
import Tooltip from "~brokerage/components/shared/Tooltip";
import Actions from "./Attachments/Actions";
import useFileUpload from "~brokerage/app/hooks/useFileUpload";
import useClickOutside from "~brokerage/app/hooks/useClickOutside";
import FileUploadComponent from "./Message/Attachments/FileUploadComponent";
import FileList3LineIcon from "remixicon-react/FileList3LineIcon";
import AttachmentList from "./Attachments/List";

import { isEmpty } from "lodash";
import { getMessage, getAttachmentIds, buildFilePreviews } from "./utils";
import { FILE_UPLOAD_UPLOAD_SOURCE } from "~brokerage/app/hooks/constants";

const Form = ({
  hasActions,
  isNotesMode,
  text,
  onMessageSubmit,
  onNoteMessageSubmit,
  onTabChange,
  dispatch,
  teamNoteEnabled,
  elRef,
  onHeightChange,
  listing,
  isSearchBarFocused,
  showingId,
  isShowingChat,
  mlsAllowMessageAttachments
}) => {
  const attachmentListRef = useRef(null);
  const [filePreviews, setFilePreviews] = useState([]);
  const [isAttachmentListOpen, setIsAttachmentsListOpen] = useState(false);

  const {
    files,
    uploadProgress,
    uploadError,
    onFilesAccepted,
    removeFile,
    isUploading,
    resetFiles
  } = useFileUpload({
    multiple: true,
    addTimeStamp: true,
    uploadSource: FILE_UPLOAD_UPLOAD_SOURCE.messages
  });

  useClickOutside(attachmentListRef, () => setIsAttachmentsListOpen(false));

  const handleSubmit = () => {
    const listingId = listing.id;
    const attachmentIds = getAttachmentIds(files);
    const isAttachmentsEmpty = isEmpty(attachmentIds);
    const message = getMessage(text, isAttachmentsEmpty);

    if (isEmpty(message) && isAttachmentsEmpty && !listingId) return;

    const attachmentsData = { attachmentIds, listingId };
    const submitAction = isNotesMode ? onNoteMessageSubmit : onMessageSubmit;

    submitAction(message, attachmentsData);
    dispatch(changeText(""));
    dispatch(focus());
    handleFileChange([]);
    resetFiles();
  };

  const handleTabLinkClick = tabIndex => {
    onTabChange(tabIndex);
    dispatch(focus());
  };

  const handleFileChange = files => {
    const previews = buildFilePreviews(files);
    setFilePreviews(previews);
    onFilesAccepted(files);
  };

  return (
    <div
      className={composeClassName(css, "base", {
        focused: false,
        notesMode: isNotesMode,
        withShowingActions: hasActions
      })}
      ref={el => {
        elRef && elRef(el);
      }}
    >
      {hasActions && <ShowingActions showingId={showingId} />}
      <div className={css.messageActions}>
        <div className={css.links}>
          <Tabs.Links>
            <Tabs.Link
              active={!isNotesMode}
              label="Message"
              onClick={() => handleTabLinkClick(0)}
            />
            {teamNoteEnabled && (
              <Tabs.Link
                active={isNotesMode}
                label="Team Note"
                info="Team notes are visible only to you and your team"
                onClick={() => handleTabLinkClick(1)}
              />
            )}
          </Tabs.Links>
        </div>
        {mlsAllowMessageAttachments && (
          <div className={css.attachmentsListTrigger}>
            <span className={css.icon}>
              <Tooltip text="File List" direction="bottom">
                <FileList3LineIcon
                  size="20"
                  onClick={() =>
                    setIsAttachmentsListOpen(!isAttachmentListOpen)
                  }
                />
              </Tooltip>
            </span>
            {isAttachmentListOpen && (
              <div ref={attachmentListRef}>
                <AttachmentList
                  closeList={() => setIsAttachmentsListOpen(false)}
                  showingId={showingId}
                  isShowingChat={isShowingChat}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {mlsAllowMessageAttachments && (
        <FileUploadComponent
          setFilePreviews={setFilePreviews}
          removeFile={removeFile}
          filePreviews={filePreviews}
          progress={uploadProgress}
          uploadError={uploadError}
          isUploading={isUploading}
          isNotesMode={isNotesMode}
        />
      )}
      <MessageTextarea
        isSearchBarFocused={isSearchBarFocused}
        isNotesMode={isNotesMode}
        onSubmit={handleSubmit}
        onHeightChange={onHeightChange}
      />
      <div
        className={
          isNotesMode
            ? css.messageActionsWrapperNote
            : css.messageActionsWrapper
        }
      >
        <div className={css.messageActionButtons}>
          {mlsAllowMessageAttachments && (
            <Actions setFiles={handleFileChange} />
          )}
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isUploading}
          >
            {isNotesMode ? "Send Note" : "Send Reply"}
          </Button>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  hasActions: PropTypes.bool,
  isNotesMode: PropTypes.bool,
  isFocused: PropTypes.bool,
  text: PropTypes.string,
  onNewMessage: PropTypes.func,
  onMessageSubmit: PropTypes.func,
  onNoteMessageSubmit: PropTypes.func,
  onNewNote: PropTypes.func,
  onTabChange: PropTypes.func,
  dispatch: PropTypes.func,
  logACallEnabled: PropTypes.bool,
  teamNoteEnabled: PropTypes.bool,
  elRef: PropTypes.func,
  onHeightChange: PropTypes.func,
  files: PropTypes.object,
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  listing: PropTypes.object,
  isSearchBarFocused: PropTypes.bool
};

const mapStateToProps = ({
  messageField,
  attachments,
  messages,
  currentUser
}) => {
  const { isFocused, text } = messageField;
  const {
    files: { entities: files },
    listing: { entity: listing }
  } = attachments;
  const { isSearchBarFocused } = messages.list.messages;
  const { mlsAllowMessageAttachments } = currentUser;

  return {
    isFocused,
    text,
    files,
    listing,
    isSearchBarFocused,
    mlsAllowMessageAttachments
  };
};
export default connect(mapStateToProps)(Form);
