import React, { useMemo } from "react";
import Question from "./Question";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import css from "./SurveyForm.sass";
import { Row } from "~brokerage/components/shared/Form";
import { processQuestionsForDisplay } from "./utils";
import { MAX_QUESTIONS_PER_SURVEY } from "~brokerage/constants/surveys";
import { OPTIONS_TYPE } from "./constants";

export default function ({ survey, setSurvey, isDefaultSurvey }) {
  const { questions, name } = survey;

  const questionsToDisplay = useMemo(
    () => processQuestionsForDisplay(questions),
    [questions]
  );

  const onNameChange = nameText => {
    setSurvey({ ...survey, name: nameText });
  };

  const onRemoveQuestion = qIndex => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].remove = true;
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onAddQuestion = () => {
    const updatedSurvey = {
      ...survey,
      questions: [
        ...questions,
        {
          question: "",
          answer_type: OPTIONS_TYPE,
          options: [{ option: "" }, { option: "" }]
        }
      ]
    };
    setSurvey(updatedSurvey);
  };

  const onEditQuestionText = (qIndex, qText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].question = qText;
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onEditOptionText = (qIndex, optIndex, optText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[optIndex].option = optText;
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onAddOption = qIndex => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.push({ option: "" });
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onRemoveOption = (qIndex, optIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[optIndex].remove = true;
    setSurvey({ ...survey, questions: updatedQuestions });
  };

  const onChangeAnswerType = (qIndex, type) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answer_type = type;
    if (updatedQuestions[qIndex].options.length === 0) {
      updatedQuestions[qIndex].options.push({ option: "" }, { option: "" });
    }
    setSurvey({ ...survey, questions: updatedQuestions });
  };
  return (
    <>
      <Row>
        <strong className={css.label}>Survey Name: </strong>
        <input
          placeholder="Enter a survey name"
          className={css.input}
          value={name}
          onChange={event => onNameChange(event.target.value)}
          disabled={isDefaultSurvey}
        />
      </Row>
      <>
        {questionsToDisplay.map((question, questionIndex) => (
          <Question
            key={`question-${questionIndex}`}
            questionData={question}
            questionIndex={questionIndex + 1}
            onRemoveQuestion={onRemoveQuestion}
            onEditQuestionText={onEditQuestionText}
            onEditOptionText={onEditOptionText}
            onAddOption={onAddOption}
            onRemoveOption={onRemoveOption}
            onChangeAnswerType={onChangeAnswerType}
            disableQuestionRemoval={questionsToDisplay.length <= 1}
            isDefaultSurvey={isDefaultSurvey}
          />
        ))}
      </>
      <Row>
        <Button
          variant="outline"
          onClick={onAddQuestion}
          disabled={
            questionsToDisplay.length >= MAX_QUESTIONS_PER_SURVEY ||
            isDefaultSurvey
          }
        >
          <Icon name="plus" /> New Question
        </Button>
      </Row>
    </>
  );
}
