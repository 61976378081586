import React from "react";
import css from "./Content.sass";
import Link from "~brokerage/components/shared/Link";

const DetailContainer = ({ DetailIcon, detailTextArray, link }) => (
  <div className={css.iconBlock}>
    <div className={css.detailsIconContainer}>
      {DetailIcon && <DetailIcon color="#aaa" size={17} />}
    </div>
    <div className={css.detailsBlock}>
      {detailTextArray.map((detailText, index) => (
        <div className={css.InnerText} key={index}>
          {link ? (
            <Link href={link} external>
              {detailText}
            </Link>
          ) : (
            detailText
          )}
        </div>
      ))}
    </div>
  </div>
);

export default DetailContainer;
