const transformArray = array => {
  return array.map(({ agentId, notificationLevel, includeCalendarEvent }) => ({
    agent_id: agentId,
    notification_level: notificationLevel,
    include_calendar_event: includeCalendarEvent
  }))
}

const prepareFormData = data => {
  const { create, update, destroy } = data.officeInvolvementData.diff;
  const member_payload = {
    create: transformArray(create),
    update: transformArray(update),
    remove_ids: destroy.map(o => o.agentId)
  };

  return {
    member_diff: member_payload,
    accept_showing_request: data.accept_showing_request,
    team_instructions:
      data.officeInvolvementData.systemPreference.teamInstructions,
    accept_showing_request_for_current_listings: data.update_current_listings
  };
};

export default prepareFormData;
