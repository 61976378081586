import css from './Button.sass'
import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import CustomPropTypes from '~brokerage/libs/CustomPropTypes'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'

export default class Button extends React.PureComponent {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.node,
    variant: PropTypes.string,
    active: PropTypes.bool,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    align: PropTypes.string,
    float: PropTypes.string,
    type: PropTypes.string,
    modifier: CustomPropTypes.modifier
  }

  componentDidMount() {
    this.el = ReactDOM.findDOMNode(this)
  }

  click() {
    this.el.click()
  }

  render() {
    const { to, children, variant = 'base', modifier, active, block, float, disabled, ...rest } = this.props

    const className = composeClassName(css, `${variant}${active ? 'Active' : ''}`, [modifier, {
      floatLeft: float === 'left',
      floatRight: float === 'right',
      block,
      disabled
    }])

    if (to) {
      return (
        <Link to={to} className={className} {...rest}>
          {children}
        </Link>
      )
    }

    if (this.props.type) {
      return (
        <button className={className} {...rest}>
          {children}
        </button>
      )
    }

    return (
      <a className={className} {...rest}>
        {children}
      </a>
    )
  }
}
