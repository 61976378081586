import css from './ShowingsHeader.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { Dropdown } from '~brokerage/components/shared/Form'
import { withRouter } from 'react-router-dom'

class AgentFilter extends React.PureComponent {
  static contextTypes = {
    currentUser: PropTypes.object
  }

  static propTypes = {
    filterAgentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterAgentName: PropTypes.string,
    filterAgents: PropTypes.array,
    currentUser: PropTypes.object,
    onChange: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object
  }

  state = {
    filteredOptions: null,
    title: null
  }

  componentWillMount() {
    if (this.props.filterAgents.length &&
        !find(this.props.filterAgents, a => a.id === this.props.filterAgentId)) {
      this.clearAgentFromLocation()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.filterAgents, this.props.filterAgents)) {
      this.setState({filteredOption: this.generateAgentOptions(nextProps.filterAgents)})
    }

    if (nextProps.filterAgentId &&
        (nextProps.filterAgents && !find(nextProps.filterAgents, a => a.id === nextProps.filterAgentId)) &&
        !find(this.props.filterAgents, a => a.id === nextProps.filterAgentId)) {
      this.clearAgentFromLocation()
    }
  }

  clearAgentFromLocation() {
    const { location } = this.props
    this.props.history.push({
      pathname: location.pathname,
      query: { ...location.query, agent: void 0 }
    })
  }

  generateAgentOptions() {
    const { filterAgents } = this.props

    return filterAgents.reduce((acc, a) => (acc.concat({label: this.agentNameLable(a.name), value: a.id })),
                               [{ label: 'Agent: All' }])
  }

  title() {
    const { filterAgentId } = this.props

    if (typeof filterAgentId === 'undefined' || !filterAgentId) {
      return 'Agent: All'
    } else {
      const agent = find(this.props.filterAgents, a => a.id === filterAgentId)
      return  agent ? this.agentNameLable(agent.name) : 'Loading...'
    }
  }

  agentNameLable(name) {
    return `Agent: ${name}`
  }

  handleChange = agent => {
    const { location } = this.props

    this.setState({
      filteredOptions: null
    })
    this.props.history.push({
      pathname: location.pathname,
      query: { ...location.query, agent: agent.value }
    })
  }

  render() {
    const { filterAgentId, filterAgentName } = this.props

    const options = this.generateAgentOptions()

    return (
      <Dropdown
        title={this.title()}
        options={options}
        selected={filterAgentId}
        toggleClassName={css.dropdownToggle}
        variant="header2"
        minWidth={155}
        disabled={typeof filterAgentId !== 'undefined' && typeof filterAgentName === 'undefined'}
        onChange={this.handleChange}
      />
    )
  }
}

function mapStateToProps(state) {
  const { filter, filterAgentName, filterAgents } = state.showings.list.showings

  return {
    currentUser: state.currentUser,
    filterAgentId: +filter.agent,
    filterAgentName,
    filterAgents
  }
}
export default withRouter(connect(mapStateToProps)(AgentFilter));
