import css from "./Contacts.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "~brokerage/components/shared/Icon";
import { format } from "~brokerage/libs/helpers/PhonesHelper";

const Contacts = ({ contacts }) => {
  const renderPhones = phones =>
    phones
      .filter(n => n.value)
      .map((n, i) => <div key={i}>{format(n.value)}</div>);

  const renderEmails = emails =>
    emails.filter(n => n.value).map((n, i) => <div key={i}>{n.value}</div>);

  return (
    <ul className={css.ul}>
      {contacts.map(s => (
        <li key={s.id} className={css.li}>
          <div className={css.tableCell}>
            <div className={css.name}>
              <div className={css.icon}>
                <Icon name="user" />
              </div>
              {s.name}{" "}
              <span className={css.license}>(License #: {s.license})</span>
            </div>
            <div className={css.office}>
              <div className={css.icon}>
                <Icon name="building" />
              </div>
              {s.office}
            </div>
            <div className={css.phones}>
              <div className={css.iconPhone}>
                <Icon name="phone" />
              </div>
              {renderPhones(s.phones)}
            </div>
            <div className={css.emails}>
              <div className={css.icon}>
                <Icon name="mail" />
              </div>
              {renderEmails(s.emails)}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.array
};

export default Contacts;
