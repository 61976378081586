import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import MapPin2LineIcon from "remixicon-react/MapPin2LineIcon";
import HomeSmileLineIcon from "remixicon-react/HomeSmileLineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";
import ContactsLineIcon from "remixicon-react/ContactsLineIcon";
import StopCircleFillIcon from "remixicon-react/StopCircleFillIcon";
import FileChartLineIcon from "remixicon-react/FileChartLineIcon";
import QuestionLineIcon from "remixicon-react/QuestionLineIcon";
import Notification4LineIcon from "remixicon-react/Notification4LineIcon";
import Settings3LineIcon from "remixicon-react/Settings3LineIcon";
import LightbulbLineIcon from "remixicon-react/LightbulbLineIcon";
import MapPinTimeIcon from "remixicon-react/MapPinTimeLineIcon";
import EyeOffLineIcon from "remixicon-react/EyeOffLineIcon";

const navigationIcons = {
  calendar: CalendarLineIcon,
  routes: MapPin2LineIcon,
  myListings: HomeSmileLineIcon,
  notifications: Notification4LineIcon,
  messages: MailLineIcon,
  contacts: ContactsLineIcon,
  adminPanel: StopCircleFillIcon,
  reports: FileChartLineIcon,
  support: QuestionLineIcon,
  settings: Settings3LineIcon,
  learning: LightbulbLineIcon,
  newShowing: MapPinTimeIcon,
  stopImpersonating: EyeOffLineIcon
};

export const navigationIcon = name => navigationIcons[name];
