import { maskPhoneValue } from "~brokerage/components/settings/utilities/utils";

const prepareFormData = data => {
  const formData = new FormData();

  const directFields = [
    "email",
    "listing_email_notification_enabled",
    "buying_email_notification_enabled",
    "listing_text_notification_enabled",
    "buying_text_notification_enabled",
    "daily_summary_emails_enabled"
  ];
  const conditionalFields = [
    {
      key: "accept_showing_request",
      condition: typeof data.accept_showing_request !== "undefined"
    },
    {
      key: "accept_showing_request_for_current_listings",
      condition:
        data.accept_showing_request &&
        typeof data.update_current_listings !== "undefined"
    }
  ];

  directFields.forEach(field => {
    formData.append(field, data[field]);
  });

  conditionalFields.forEach(({ key, condition }) => {
    if (condition) {
      formData.append(key, data[key]);
    }
  });

  formData.append("phone", parseInt(maskPhoneValue(data.phone, true), 10));

  if (data.userPicture?.length) {
    formData.append("remote_avatar_data", data.userPicture[0]);
  }

  return formData;
};

export default prepareFormData;
