import css from "./index.sass";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { withFormBinding } from "../Form";
import Filter from "./Filter";
import List from "./List";
import Toggle from "./Toggle";

class Dropdown extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    options: PropTypes.array.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    variant: PropTypes.string,
    modifier: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filter: PropTypes.bool,
    filterPlacement: PropTypes.string,
    filterPlaceholder: PropTypes.string,
    listDirection: PropTypes.string,
    block: PropTypes.bool,
    minWidth: PropTypes.number,
    disabled: PropTypes.bool,
    optionsPostfix: PropTypes.node,
    onFilter: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onOpen: PropTypes.func
  };

  static defaultProps = {
    variant: "base"
  };

  state = {
    shown: false,
    options: this.props.options
  };

  componentDidMount() {
    this.listContainer = document.createElement("div");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shown && prevState.options !== this.state.options) {
      ReactDOM.render(this.renderList(), this.listContainer);
    }
  }

  componentWillUnmount() {
    this.hideList();
  }

  showList = () => {
    document.body.appendChild(this.listContainer);
    ReactDOM.render(this.renderList(), this.listContainer);
    this.setState({
      shown: true
    });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  hideList = () => {
    if (!this.state.shown) {
      return;
    }
    try {
      ReactDOM.unmountComponentAtNode(this.listContainer);
      document.body.removeChild(this.listContainer);
      this.setState({
        shown: false
      });
    } catch (err) {}
  };

  handleOptionClick = (option, event) => {
    event.stopPropagation();
    if (this.props.selected !== option.value) {
      if (this.props.name) {
        this.props.onChange({
          [this.props.name]: option.value
        });
      } else {
        this.props.onChange(option);
      }
    }

    this.hideList();
  };

  handleClickOutside = () => {
    setTimeout(this.hideList, 0);
  };

  handleFilter = filterValue => {
    if (this.props.onFilter) {
      this.props.onFilter(filterValue);
    } else {
      if (filterValue) {
        const options = this.props.options.map(option => {
          const _option = Object.assign({}, option);
          const optionText = option.text || option.label;
          if (
            optionText.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
          ) {
            _option.isHidden = true;
          }

          return _option;
        });

        this.setState({
          options: options
        });
      } else {
        this.setState({
          options: this.props.options
        });
      }
    }
  };

  renderList() {
    return (
      <List
        options={this.state.options}
        selected={this.props.selected}
        variant={this.props.variant}
        toggleWidth={this.$toggle.outerWidth()}
        toggleHeight={this.$toggle.outerHeight()}
        top={this.$toggle.offset().top}
        left={this.$toggle.offset().left}
        filter={this.props.filter && !this.props.filterPlacement}
        optionsPostfix={this.props.optionsPostfix}
        onOptionClick={this.handleOptionClick}
        onClickOutside={this.handleClickOutside}
        onFilter={this.handleFilter}
        listDirection={this.props.listDirection}
      />
    );
  }

  title() {
    if (this.props.title) {
      return this.props.title;
    }

    for (const option of this.props.options) {
      if (option.value === this.props.selected) {
        return option.label;
      }
    }

    return this.props.placeholder;
  }

  validate = () => {
    return [];
  };

  handleErrors = () => {
    return false;
  };

  value = () => {
    return {
      [this.props.name]: this.props.selected
    };
  };

  render() {
    const { variant, modifier, filterPlacement, minWidth, selected, disabled } =
      this.props;

    const styles = {
      minWidth: minWidth,
      overflow: "hidden"
    };

    const extraModifier = disabled
      ? "disabled"
      : this.state.shown
      ? "active"
      : "";

    return (
      <div className={composeClassName(css, variant, modifier)}>
        <Toggle
          variant={variant}
          modifier={`${modifier || ""} ${extraModifier}`}
          style={styles}
          onClick={this.showList}
          ref={c => (this.$toggle = $(ReactDOM.findDOMNode(c)))}
        >
          <input type="hidden" value={selected} />
          {(() => {
            if (filterPlacement === "toggle" && this.state.shown) {
              return (
                <Filter
                  variant="replaceToggle"
                  onChange={this.handleFilter}
                  placeholder={this.props.filterPlaceholder}
                />
              );
            }

            return this.title();
          })()}
        </Toggle>
      </div>
    );
  }
}
export default withFormBinding(Dropdown);
