import React from "react";
import { maskPhoneValue } from "~brokerage/components/settings/utilities/utils";
import css from "./shared.sass";
import Row from "~brokerage/components/shared/Row";
import Column from "~brokerage/components/shared/Column";
import InputLabel from "~brokerage/components/settings/components/InputLabel";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "~brokerage/components/settings/components/InputMask";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";

const MobilePhone = ({ errors, phone }) => {
  return (
    <Row className={composeClassName(css, "inputRow", "marginBottom")}>
      <Column
        className={composeClassName(css, "inputColumnCentered", "rowLabel")}
      >
        <InputLabel required>Mobile Phone</InputLabel>
      </Column>
      <Column className={composeClassName(css, "inputColumn", "mobileInput")}>
        <InputMask
          maskingFunction={maskPhoneValue}
          type="tel"
          name="phone"
          aria-invalid={errors.phone ? "true" : "false"}
          placeholder="Mobile Phone"
          defaultValue={maskPhoneValue(phone)}
          required="Mobile Phone is required"
          pattern={{
            value: /\d/,
            message: "Please enter a valid phone number"
          }}
        />
        <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => (
            <p className={css.errorMessage}>{message}</p>
          )}
        />
      </Column>
    </Row>
  );
};

export default MobilePhone;
