import React from "react";
import css from "./SurveyListItem.sass";
import PencilLineIcon from "remixicon-react/PencilLineIcon";
import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";

export default function SurveyListItem({ survey, onEditClick, onDeleteClick }) {
  const { id, name } = survey;
  return (
    <div className={css.container}>
      <p className={css.name}>{name}</p>
      <div className={css.buttons}>
        <button className={css.editBtn} onClick={() => onEditClick(id)}>
          <PencilLineIcon size={16} />
        </button>
        <button className={css.deleteBtn} onClick={() => onDeleteClick(id)}>
          <DeleteBin7LineIcon size={16} />
        </button>
      </div>
    </div>
  );
}
