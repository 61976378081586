/* eslint react/no-did-mount-set-state: 0 */
import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { PIXELS_PER_MINUTE, MINUTES_STEP } from '~brokerage/constants/timeline'
import { pixelsToDatetime, datetimeToPixels, timeToRemoteWithTimeZone } from '~brokerage/libs/helpers/TimeHelper'
import Grid from './Grid'
import Restrictions from './Restrictions'
import Showings from './Showings'
import NewShowing from './NewShowing'
import SideControl from './SideControl'
class TimelineMain extends Component {
  static propTypes = {
    time: PropTypes.object,
    onChange: PropTypes.func,
    showing: PropTypes.object,
    dispatch: PropTypes.func
  }

  componentDidMount() {
    this.$el = $(ReactDOM.findDOMNode(this))
    this.$el.scrollTop(datetimeToPixels(this.props.time) - Math.floor(this.$el.height() / 2))

    this.offsetTop = this.$el.offset().top + parseInt(this.$el.css('paddingTop'))
  }

  componentWillReceiveProps(nextProps) {
    const nextTop = datetimeToPixels(nextProps.time)
    const containerHeight = this.$el.height()
    const containerScrollTop = this.$el.scrollTop()
    if (nextTop > containerScrollTop + containerHeight || nextTop < containerScrollTop) {
      this.$el.animate({
        scrollTop: nextTop - Math.floor(containerHeight / 2)
      }, 150)
    }
  }

  getNextDate(topDisregardingContainer) {
    let top = topDisregardingContainer - this.offsetTop + this.$el.scrollTop()
    top = top - top % (MINUTES_STEP * PIXELS_PER_MINUTE)
    return pixelsToDatetime(top)
  }

  handleChange = topDisregardingContainer => {
    const maxDate = timeToRemoteWithTimeZone().hours(23).minutes(55).second(0)
    const minDate = timeToRemoteWithTimeZone().hour(0).minute(0).second(0)
    let nextDate = this.getNextDate(topDisregardingContainer)

    if (nextDate < minDate) {
      nextDate = minDate
    } else if (nextDate > maxDate) {
      nextDate = maxDate
    }
    if (nextDate !== this.props.time) {
      this.props.onChange(nextDate)
    }
  }

  render() {
    return (
      <div className={css.base}>
        <div className={css.inner}>
          <Grid/>
          <Restrictions/>
          <Showings showing={this.props.showing}/>
          <SideControl onChange={this.handleChange}/>
          <NewShowing onChange={this.handleChange}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { time } = state.showings.new

  return {
    time
  }
}
export default connect(mapStateToProps)(TimelineMain)
