import React, { useState } from "react";
import PropTypes from "prop-types";
import css from "./RouteInfo.sass";
import moment from "moment";
import { toast } from "react-toastify";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";
import ShareFillIcon from "remixicon-react/ShareFillIcon";
import { useHistory, useLocation } from "react-router-dom";
import { timeToRemoteWithTimeZone } from "~brokerage/libs/helpers/TimeHelper";
import { parseRouteShareMessage, printShowings } from "./util/shareUtil";
import { Dropdown } from "~brokerage/components/shared/Form";
import Modal from "react-modal";

const RouteInfo = ({ isFetching, route, showings }) => {
  const history = useHistory();
  const location = useLocation();
  const { unsavedChanges = [] } = route;
  const [sharing, setSharing] = useState(null);

  const { name, date: routeDate, status } = route || {};

  if (isFetching) return <div />;

  if (!route) {
    return (
      <div className={css.routeInfo}>
        <div className={css.name}>Route not found.</div>
      </div>
    );
  }

  let date = timeToRemoteWithTimeZone(routeDate);
  const hasShowings = showings.length > 0;

  let endTime;
  let duration;

  if (hasShowings) {
    const lastShowing = showings[showings.length - 1];
    date = timeToRemoteWithTimeZone(showings[0].start_time);
    endTime = timeToRemoteWithTimeZone(lastShowing.start_time).add(
      lastShowing.duration,
      "minutes"
    );
    duration = endTime.diff(date);
  }

  const handleEdit = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "edit_route" }
    });
  };

  const copyToClipboard = () => {
    toast.info("Copied route details to clipboard");
    navigator.clipboard.writeText(parseRouteShareMessage(route));
  };

  // const editable = status === "active";

  const editable = false;
  const formatRouteDuration = duration => {
    if (duration < 3600000) {
      return moment.utc(duration).format("mm [minutes]");
    }

    return moment.utc(duration).format("H [hours] mm [minutes]");
  };

  const handleShare = type => {
    if (type === "copy") copyToClipboard();
    else printShowings();
    setSharing(null);
  };

  return (
    <>
      <Modal
        isOpen={!!sharing}
        className={css.modalContent}
        overlayClassName={css.modalOverlay}
        onRequestClose={() => setSharing(null)}
      >
        <div className={css.modalHeader}>Unsaved changes warning!</div>
        <div className={css.modalBody}>
          Unsaved changes in route will not be present in the shared data.
        </div>
        <div className={css.modalFooter}>
          <Button variant="outline" onClick={() => setSharing(null)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleShare(sharing)}>
            OK
          </Button>
        </div>
      </Modal>
      <div className={css.routeInfoWrapper}>
        <div className={css.routeInfo}>
          <div className={css.name}>
            <span>{`Route Name: ${name}`}</span>
            {editable && (
              <Button onClick={handleEdit} className={css.editButton}>
                <Icon name="edit" /> Edit
              </Button>
            )}
          </div>
          <div className={css.date}>
            <span>{`Date: ${date.format("LL")}`}</span>
          </div>
          <div className={css.time}>
            <span>{`Start Time: ${date.format("LT")}`}</span>
          </div>
        </div>
        <div className={css.shareButton}>
          <ShareFillIcon className={css.shareIcon} size={16} />
          <Dropdown
            title="Share"
            options={[
              { label: "Copy to clipboard", value: "copy" },
              { label: "Share via PDF", value: "pdf" }
            ]}
            selected={""}
            variant="blue"
            onChange={({ value }) => {
              if (unsavedChanges.length > 0) setSharing(value);
              else handleShare(value);
            }}
          />
        </div>
      </div>
      {hasShowings && (
        <div className={css.totalRoutetime}>
          Total Route Time: {date.format("LT")} - {endTime.format("LT")} (
          {formatRouteDuration(duration)})
        </div>
      )}
    </>
  );
};

RouteInfo.propTypes = {
  isFetching: PropTypes.bool,
  route: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default RouteInfo;
