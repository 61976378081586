import css from "./index.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Bubble from "./Bubble";
import he from "he";

const InstantPending = ({ message, bubbleOptions }) => (
  <Bubble variant="blue" {...bubbleOptions}>
    <p className={css.p}>
      <b>Instant Reply:</b> <br />
      {message.msg && <span>{he.decode(message.msg)}</span>}
    </p>
    <p className={classNames(css.p, css.nobr)}>
      This showing request is still pending.
    </p>
  </Bubble>
);

InstantPending.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default InstantPending;
