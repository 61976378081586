import { NEW_SHOWING_CLEAR_FORM } from '~brokerage/constants/actionTypes'
import { CALL_API } from '~brokerage/middlewares/api'

export function clearForm() {
  return {
    type: NEW_SHOWING_CLEAR_FORM
  }
}

export function fetchAgents(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `new_showing/agents`,
      params
    }
  }
}
