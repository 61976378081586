import { PRICE_VALUES } from "~brokerage/constants/reports/priceAnalysis";

const formatPrice = price => {
  const [fraction, power] = parseInt(price)
    .toExponential()
    .split("e")
    .map(item => Number(item));
  const pricePrefix = fraction.toFixed(2) * 10 ** (power % 3);
  const priceSuffix = ["", "K", "M", "B", "T"][Math.floor(power / 3)];

  return `$${pricePrefix}${priceSuffix}`;
};

export const formatTip = value =>
  value === PRICE_VALUES.length - 1
    ? `${formatPrice(PRICE_VALUES[value - 1])}+`
    : formatPrice(PRICE_VALUES[value]);
