import React, { Component } from "react";
import PropTypes from "prop-types";
import { LOCKBOX_TYPES, DEFAULT_LOCKBOX_TYPE, COMBO_MAX_LENGTH } from "~brokerage/constants/lockboxTypes";
import { LOCKBOX_BRANDS, DEFAULT_LOCKBOX_BRAND } from "~brokerage/constants/lockboxBrands";
import { REQUIRED_IS_EMPTY } from "~brokerage/constants/validation";
import { Row, Label, Controls, Group, Radio, TextField, ErrorContainer, Dropdown, Col } from "~brokerage/components/shared/Form";
import Icon from "~brokerage/components/shared/Icon";
import css from "./SelectLockbox.sass";

export default class SelectLockbox extends React.PureComponent {
  static propTypes = {
    lockboxKind: PropTypes.string,
    lockboxCode: PropTypes.string,
    onChange: PropTypes.func,
    lockboxBrand: PropTypes.string
  }

  render() {
    const { lockboxKind } = this.props || DEFAULT_LOCKBOX_TYPE.value;
    const iconMapping = {
      combo: "combo",
      electronic: "lockbox"
    };
    const { lockboxBrand } = this.props;

    return (
      <div className={css.base}>
        <div className={css.types}>
          <Group
            variant="well"
            modifier={["centeredInlineChildren", { noBottomBorder: lockboxKind === "combo" }]}
          >
            {LOCKBOX_TYPES.map((type, typeIndex) =>
              <Radio
                key={typeIndex}
                variant="lockboxKind"
                name="lockboxKind"
                value={type.value}
                checked={lockboxKind === type.value}
                onChange={this.props.onChange}
              >
                <div className={css.icon}>
                  <Icon name={iconMapping[type.value] || "decline"}/>
                </div>
                {type.label}
              </Radio>
            )}
          </Group>
        </div>

        {(() => {
          if (lockboxKind === "combo") {
            return (
              <div className={css.combination}>
                <ErrorContainer name="lockboxCode">
                  <Row offset="0">
                    <Label variant="combination">Combination</Label>
                    <Controls>
                      <TextField
                        variant="combination"
                        placeholder="Type Combo"
                        name="lockboxCode"
                        value={this.props.lockboxCode}
                        autoComplete="off"
                        focused
                        maxLength={COMBO_MAX_LENGTH}
                        block
                        required
                        trimValue
                        errorDescriptions={{ [REQUIRED_IS_EMPTY]: "Combination can not be blank"}}
                        onChange={this.props.onChange}
                      />
                    </Controls>
                  </Row>
                </ErrorContainer>
              </div>
            )
          }
          else if (lockboxKind === "electronic") {
            return (
              <div className={css.brand}>
                <ErrorContainer name="lockboxBrand">
                  <Row offset="0" className={css.brandContainer}>
                    <Label className={css.brandLabel}>Lockbox Brand</Label>
                    <Controls>
                      <Col>
                        <Dropdown
                          name="brand"
                          options={LOCKBOX_BRANDS}
                          selected={lockboxBrand}
                          onChange={
                            e => this.props.onChange({
                              target: { type: "dropdown", name: "lockboxBrand", value: e.brand }
                            })
                          }
                          variant="modalShowingDetailsLockboxBrand"
                        />
                      </Col>
                    </Controls>
                  </Row>
                </ErrorContainer>
              </div>
            )
          }
        })()}
      </div>
    )
  }
}
