import {
  timeToRemoteWithTimeZone,
  inUsersTimezone,
  addMinutes,
  checkTimeRangesForConflicts
} from "./TimeHelper";
import * as statuses from "~brokerage/constants/messages/showingStatuses";
import { TIME_PASSED } from "~brokerage/constants/showings/newShowingSections";

const filterConflictingShowings = (showings, time, timeEnd) => {
  return showings
    .filter(showing => {
      const showingTime = new Date(showing.requestedTimeRaw);
      const showingTimeEnd = addMinutes(showingTime, showing.duration);
      return checkTimeRangesForConflicts(
        time,
        timeEnd,
        showingTime,
        showingTimeEnd
      );
    })
    .map(showing => showing.id);
};

const checkForConflictingRestrictions = (restrictions, time, timeEnd) => {
  for (const restriction of restrictions) {
    const restrictionTime = new Date(restriction.time);
    const restrictionTimeEnd = new Date(restriction.timeEnd);

    if (
      checkTimeRangesForConflicts(
        time,
        timeEnd,
        restrictionTime,
        restrictionTimeEnd
      )
    ) {
      return true;
    }
  }

  return false;
};

const checkForTimePassed = datetime => {
  const currentDatetime = timeToRemoteWithTimeZone();
  return datetime < currentDatetime;
};

export const checkForConflicts = (restrictions, datetime, duration) => {
  if (checkForTimePassed(datetime)) {
    return TIME_PASSED;
  }

  const timeEnd = addMinutes(datetime, duration);

  return checkForConflictingRestrictions(restrictions, datetime, timeEnd);
};

export const detectOverlappingShowings = (showings, datetime, duration) => {
  const timeEnd = addMinutes(datetime, duration);
  const overlappingShowings = filterConflictingShowings(
    showings,
    datetime,
    timeEnd
  );

  if (overlappingShowings && overlappingShowings.length > 0) {
    return overlappingShowings;
  } else {
    return [];
  }
};

export function extractStreetFromFullAddress(fullAddress) {
  const matches = fullAddress.match(/(.*),(.+,.+)/);
  return matches ? matches[1] || matches[2] : fullAddress;
}

export const preparePayloadForNewShowing = (
  listing,
  agent,
  _date,
  time,
  duration
) => {
  const date = inUsersTimezone(_date).format("YYYY-MM-DD");
  const datetime = date + time.format().slice(10);

  return {
    listing_id: listing.id,
    buyer_agent_user_id: agent.id,
    showing_request: {
      duration,
      requested_time: datetime
    }
  };
};

export const composeStatusChangedMessage = (status, name) => {
  switch (status) {
    case statuses.STATUS_APPROVED:
    case statuses.STATUS_INSTANT_APPROVED:
      return `${name} approved the showing`;
    case statuses.STATUS_DECLINED:
    case statuses.STATUS_CANCELLED:
      return `${name} ${status} the showing`;
    case statuses.STATUS_PENDING:
    case statuses.STATUS_INSTANT_PENDING:
      return `${name} has requested to see your property`;
    case statuses.STATUS_REQUEST_A_CALL:
      return `${name} has requested you call first`;
    case statuses.STATUS_RESCHEDULED_REQUEST:
      return `${name} has rescheduled the request`;
    case statuses.STATUS_TIME_CHANGED:
      return `${name} has changed time`;
    case statuses.STATUS_FEEDBACK_RECEIVED:
      return `${name} has left feedback`;
    case statuses.STATUS_FEEDBACK_REQUESTED:
      return `Showing feedback has been requested`;
    case statuses.STATUS_MUTED_LISTING:
      return `${name} has muted showing requests`;
    case statuses.STATUS_NEW_PARTICIPANT:
      return `${name} has added you to a showing`;
    case statuses.STATUS_PASSED:
      return "Showing has passed";
    default:
      return "Showing status was changed";
  }
};
