import mapboxgl from "!mapbox-gl";
import Marker from "~brokerage/assets/icons/mapbox-marker-icon-20px-blue.png";
import SelectedMarker from "~brokerage/assets/icons/mapbox-marker-icon-20px-blue-filled.png";

const initializeMap = (mapRef, mapboxkey, setHighlightedListing) => {
  const options = {
    accessToken: mapboxkey,
    crossSourceCollisions: false,
    container: mapRef.current,
    style: "mapbox://styles/mapbox/streets-v11",
    center: [-96, 37.8], // US center
    zoom: 3
  };
  const map = mapRef.current && new mapboxgl.Map(options);

  map.on("load", () => {
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      })
    );
    map.loadImage(SelectedMarker, (e, img) => {
      if (e) console.log(e);
      map.addImage("base-selected-marker", img);
    });
    map.loadImage(Marker, (e, img) => {
      if (e) console.log(e);
      map.addImage("base-marker", img);
    });
  });

  map.on("click", () => {
    console.log("mapClick");
    setHighlightedListing(null);
  });

  map.on("click", "listings", e => {
    const mlsNumber = e.features[0].properties.mlsNumber;
    const listing = document.getElementById(`listing_${mlsNumber}`);
    if (listing) {
      setTimeout(() =>
        listing.scrollIntoView(
          {
            behavior: "smooth",
            block: "center"
          },
          100
        )
      );
    }
    setHighlightedListing(mlsNumber);
  });

  map.on("mouseenter", "listings", () => {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "listings", () => {
    map.getCanvas().style.cursor = "";
  });

  return map;
};
export default initializeMap;
