import css from './Checkbox.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { REQUIRED_IS_EMPTY } from '~brokerage/constants/validation'
import CustomPropTypes from '~brokerage/libs/CustomPropTypes'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Errors from './Errors'
import Icon from '~brokerage/components/shared/Icon'
import { withFormBinding } from './Form'
class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    name: PropTypes.string,
    variant: PropTypes.string,
    modifier: CustomPropTypes.modifier,
    errorDescriptions: PropTypes.object,
    children: PropTypes.node,
    onChange: PropTypes.func
  }

  state = {
    checked: this.props.defaultChecked,
    errors: null
  }

  componentDidMount() {
    if (this.props.checked === void 0) {
      $(this.input).on('change', (event) => {
        this.setState({
          checked: event.target.checked
        })
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultChecked !== void 0 && this.props.checked === void 0) {
      this.input.checked = nextProps.defaultChecked
      this.setState({checked: nextProps.defaultChecked})
    }
  }

  validate = () => {
    if (this.input.required) {
      if (this.input.checked) {
        this.setState({ errors: null })
      } else {
        this.setState({ errors: [ REQUIRED_IS_EMPTY ] })
        return false
      }
    }

    return true
  }

  value = () => {
    return {
      [this.props.name]: this.input.checked
    }
  }

  handleChange = (...args) => {
    if (this.state.errors) {
      this.validate()
    }

    if (this.props.onChange) {
      this.props.onChange(...args)
    }
  }

  render() {
    const { children, variant = 'base', modifier, errorDescriptions, isDisabled, ...rest } = this.props;
    const { errors } = this.state
    const uuid = v4()

    const checked = this.props.checked || this.state.checked

    return (
      <div className={composeClassName(css, variant, modifier)}>
        <input
          id={`checkbox-${uuid}`}
          type="checkbox"
          ref={(el) => this.input = el}
          {...rest}
          onChange={this.handleChange}
          disabled={isDisabled}
        />
        <label className={css[`${variant}Label${checked ? "Active" : ""}${isDisabled ? "Disabled" : ""}`]} htmlFor={`checkbox-${uuid}`}>
          <div className={css.icon}><Icon size={variant === 'bigger' ? '24' : '12'} name="check"/></div>
          {children}
        </label>

        {errors && <Errors name={name} errors={errors} variant="checkbox" descriptions={errorDescriptions}/>}
      </div>
    )
  }
}
export default withFormBinding(Checkbox)
