import css from "./ModalConfirmation.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import Button from "~brokerage/components/shared/Button";
import { withRouter } from "react-router-dom";

class ModalConfirmation extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    confirmText: PropTypes.string,
    confirmVariant: PropTypes.string,
    onConfirm: PropTypes.func,
    cancelText: PropTypes.string,
    cancelVariant: PropTypes.string,
    location: PropTypes.object
  };

  render() {
    const {
      heading,
      location,
      text,
      confirmText,
      confirmVariant,
      onConfirm,
      cancelText,
      cancelVariant,
      warningText = null
    } = this.props;

    return (
      <div className={css.base}>
        <p className={css.heading}>{heading}</p>
        <p className={css.p}>{text}</p>
        {!!warningText && <p className={css.warn}>{warningText}</p>}

        <div className={css.buttons}>
          <div className={css.btn}>
            <Button
              onClick={onConfirm}
              variant={confirmVariant || "primary"}
              modifier="block textAlignCenter"
            >
              {confirmText}
            </Button>
          </div>

          <div className={css.btn}>
            <Button
              variant={cancelVariant || "outline"}
              modifier="block textAlignCenter"
              to={{
                ...location,
                query: { ...omit(location.query, "modal") }
              }}
            >
              {cancelText || "Cancel"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalConfirmation);
