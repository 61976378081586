import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import css from "./index.sass";
import { Row, Dropdown, Radio } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import { fetchSellerTenantInvolvement } from "~brokerage/actions/listings";
import { sellerDropdownOptions, buildParamsForReport } from "./utils";

import {
  DEFAULT_DROPDOWN_OPTION,
  ACTIVITY_REPORT_META_DATA_OPTIONS
} from "./constants";
import { isEmpty } from "lodash";

const ActivityReportDownloadModal = ({
  isOpen,
  onClose,
  listingId,
  dispatch,
  sellers
}) => {
  const [selectedSeller, setSelectedSeller] = useState(DEFAULT_DROPDOWN_OPTION);
  const [reportMetadata, setReportMetadata] = useState(
    ACTIVITY_REPORT_META_DATA_OPTIONS.NAME_AND_BROKERAGE
  );

  useEffect(() => {
    if (listingId && isOpen) {
      dispatch(fetchSellerTenantInvolvement(listingId));
    }
  }, [listingId, isOpen]);

  const handleChange = seller => {
    setSelectedSeller(seller);
  };

  const handleMetaDataChange = ({ target: { value } }) => {
    setReportMetadata(value);
  };

  const onRequestClose = () => {
    setReportMetadata(ACTIVITY_REPORT_META_DATA_OPTIONS.NAME_AND_BROKERAGE);
    onClose();
  };

  const openReport = () => {
    const params = buildParamsForReport({ selectedSeller, reportMetadata });
    onClose();

    window.open(
      `/my_listings/${listingId}/activity_reports.pdf?${params.toString()}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const dropDownOptions = [
    DEFAULT_DROPDOWN_OPTION,
    ...sellerDropdownOptions(sellers)
  ];

  const { NAME_AND_BROKERAGE, NAME_AND_BROKERAGE_WITH_PHONE, NO_DETAILS } =
    ACTIVITY_REPORT_META_DATA_OPTIONS;

  return (
    <Modal
      isOpen={isOpen}
      className={css.modalContent}
      overlayClassName={css.modalOverlay}
      onRequestClose={onRequestClose}
    >
      <div className={css.mdHeader}>
        <span className={css.title}>Download Activity Report</span>
      </div>
      <div className={css.content}>
        <span className={css.description}>
          Activity report provides an overview of listing appointment activity
          and feedback.
        </span>
        <Row offset="10">
          <div className={css.sellerSelector}>
            <div className={css.activityReportLabel}>
              Generate Activity Report for
            </div>
            <div className={css.activityReportDropdown}>
              <Dropdown
                options={dropDownOptions}
                selected={selectedSeller?.value}
                onChange={handleChange}
                variant="outlineSellerTenantInvolvement"
              />
            </div>
          </div>
          <div className={css.showingAgentRadio}>
            <Radio
              name="reportMetadata"
              value={NAME_AND_BROKERAGE}
              checked={reportMetadata === NAME_AND_BROKERAGE}
              onChange={handleMetaDataChange}
            >
              Include showing agent name/brokerage
            </Radio>
            <Radio
              name="reportMetadata"
              value={NAME_AND_BROKERAGE_WITH_PHONE}
              checked={reportMetadata === NAME_AND_BROKERAGE_WITH_PHONE}
              onChange={handleMetaDataChange}
            >
              Include showing agent name/brokerage with phone number
            </Radio>
            <Radio
              name="reportMetadata"
              value={NO_DETAILS}
              checked={reportMetadata === NO_DETAILS}
              onChange={handleMetaDataChange}
            >
              Exclude showing agent details
            </Radio>
          </div>
        </Row>
        <Row>
          <ButtonSet align="right">
            <Button
              variant="primary"
              onClick={openReport}
              disabled={isEmpty(selectedSeller)}
            >
              Download
            </Button>
          </ButtonSet>
        </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { entities: sellers } = state.listings.single.sellerTenantInvolvement;
  return {
    sellers
  };
};

export default connect(mapStateToProps)(ActivityReportDownloadModal);
