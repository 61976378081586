import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SettingsSideBar from "~brokerage/components/settings/SettingsSideBar";
import SettingsPane from "~brokerage/components/settings/SettingsPane";
import SettingsContent from "~brokerage/components/settings/SettingsContent";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";

import ManageSurvey from "./ManageSurvey";
import SelectPage from "./SelectPage";
import SurveySettings from "./SurveySettings";

const Feedback = ({ match }) => {
  const { dispatchPageState } = useContext(SettingsContext);

  useEffect(() => {
    dispatchPageState({ type: "setActivePane", payload: "feedback" });
  }, []);

  return (
    <SettingsPane>
      <SettingsSideBar />
      <SettingsContent>
        <Switch>
          <Route
            path={`${match.path}/default_settings`}
            exact
            component={SurveySettings}
          />
          <Route
            path={`${match.path}/manage_surveys`}
            exact
            component={ManageSurvey}
          />
          <Route path={`${match.path}`} component={SelectPage} />
        </Switch>
      </SettingsContent>
    </SettingsPane>
  );
};

export default Feedback;
