import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import omit from "lodash/omit";

import { callApi } from "~brokerage/middlewares/api";
import ModalConfirmation from "~brokerage/components/modals/ModalConfirmation";
import css from "~brokerage/components/modals/ModalExportContacts.sass";

const ModalExportContacts = props => {
  const [contactsData, setContactsData] = useState([]);
  const csvLink = useRef();
  const history = useHistory();
  const {
    categories,
    location,
    location: { query: { category: currentCategory} }
  } = props;

  const handleExport = async () => {
    await callApi(`people/export`, { filter: { category: currentCategory } }, {}, "get")
      .then(response => {
        setContactsData(response.data.data)
      })
      .catch(error => {
        toast.error(error.response.data.message || "Something went wrong.");
      })
    csvLink.current.link.click();
  };

  const closeModal = () => {
    history.push({
      pathname: `/people`,
      query: { ...omit(location.query, "modal") }
    });
  };

  const categoryLabel = categories.find(category => category.value === currentCategory).label;

  return (
    <div className={css.base}>
      <ModalConfirmation
        heading={`Export all the ${categoryLabel} contacts`}
        text="Are you sure ?"
        confirmText="Yes, Export."
        confirmVariant="outlineBlue"
        onConfirm={handleExport}
        cancelText="No, Cancel."
        location={location}
      />
      <CSVLink
        data={contactsData}
        filename={`${categoryLabel}.csv`}
        target="_blank"
        className='hidden'
        ref={csvLink}
        onClick={closeModal}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { entities: categories } = state.people.categories;

  return { categories };
}

export default connect(mapStateToProps)(ModalExportContacts);
