import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  TextField,
  Label,
  CalendarDropdown,
  Timepicker,
  Dropdown
} from "~brokerage/components/shared/Form";
import { datetimeToObject } from "~brokerage/libs/helpers/TimeHelper";
import { setSelectedProperties } from "~brokerage/actions/propertySearch";
import { useHistory } from "react-router-dom";
import Tabs from "~brokerage/components/shared/Tabs";
import { clearRequest, fetchShowingRoutes } from "~brokerage/actions/routes";
import { toast } from "react-toastify";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import moment from "moment";
import { getUnavailableListings } from "./util";
import BuyerSection from "~brokerage/components/modals/ModalCreateShowing/steps/SendRequest/BuyerSection";

import { buildBuyerOptions } from "~brokerage/components/modals/utils";
import AppointmentList from "../AppointmentList";
import { isWithinAdvanceNoticeTime } from "../util";

import BuyerBrokerAgreement from "~brokerage/components/shared/BuyerBrokerAgreement";

const SelectDate = ({
  selected,
  dispatch,
  isFetching,
  message,
  errors,
  routeId,
  routeOptions,
  name,
  setName,
  time,
  setTime,
  mode,
  setMode,
  selectedRoute,
  setSelectedRoute,
  unavailableListings,
  setUnavailableListings,
  fetchListingRestrictions,
  restrictions,
  setRestrictions,
  setAdvanceNotices,
  advanceNotices,
  buyers,
  setBuyers,
  onNewBuyer,
  mlsBuyerRequiredEnabled,
  setDetailedListings,
  mlsBuyerBrokerAgreementEnabled
}) => {
  const history = useHistory();
  const date = moment(time).format("YYYY-MM-DD");

  useEffect(() => {
    if (mode === "new") updateRestrictions(date);
    else if (selectedRoute.routeId)
      updateRestrictions(selectedRoute.date.format("YYYY-MM-DD"));
    else setRestrictions({});
  }, [date, mode]);

  useEffect(() => {
    dispatch(fetchShowingRoutes({ page: "all" }));
    return () => dispatch(clearRequest());
  }, []);

  useEffect(() => {
    !isFetching && errors && toast.error(errors);
  }, [isFetching, errors]);

  useEffect(() => {
    if (!isFetching && message) {
      toast.success(message);
      dispatch(setSelectedProperties([]));
      history.push({
        pathname: `/my_showings/${routeId}/schedule`
      });
    }
  }, [isFetching, message]);

  useEffect(() => {
    const _unavailableListings = getUnavailableListings(selected, restrictions);
    setUnavailableListings(_unavailableListings);
  }, [restrictions]);

  useEffect(() => {
    let newAdvanceNotices = {};
    if (mode === "new")
      selected.forEach(s => {
        newAdvanceNotices[s.id] = isWithinAdvanceNoticeTime(
          s.id,
          time,
          restrictions
        );
      });
    else if (selectedRoute.routeId)
      selected.forEach(s => {
        newAdvanceNotices[s.id] = isWithinAdvanceNoticeTime(
          s.id,
          selectedRoute.date,
          restrictions
        );
      });
    setAdvanceNotices(newAdvanceNotices);
  }, [restrictions, mode, time]);

  useEffect(() => {
    if (selectedRoute.routeId) {
      setBuyers(selectedRoute.buyers);
    }

    if (selectedRoute.routeId && mode === "new") setBuyers([]);
  }, [selectedRoute?.routeId, mode]);

  const updateRestrictions = routeDate => {
    fetchListingRestrictions(routeDate);
    setUnavailableListings([]);
  };

  const handleTimeChange = changedDatetime => {
    const currentTime = time.clone();
    currentTime
      .hours(changedDatetime.hours())
      .minutes(changedDatetime.minutes());
    setTime(currentTime);
  };

  const handleCalendarChange = e => {
    const currentTime = time.clone();
    const currentDate = inUsersTimezone(e);
    currentTime
      .month(currentDate.month())
      .date(currentDate.date())
      .year(currentDate.year());
    setTime(currentTime);
  };

  const handleRouteSelection = ({ routeId }) => {
    const selectedRoute = routeOptions.find(s => s.value === routeId);
    updateRestrictions(selectedRoute.date.format("YYYY-MM-DD"));
    setSelectedRoute({
      ...selectedRoute,
      routeId
    });
  };

  const handleRemoveListing = id => {
    const selectionUpdate = selected.filter(l => l.id !== id);
    dispatch(setSelectedProperties(selectionUpdate));
    const { [id]: deselected, ...restrictionsUpdate } = restrictions;
    setRestrictions(restrictionsUpdate);
    setDetailedListings(selectionUpdate);
  };

  return (
    <>
      <Tabs.Links variant="modal">
        <Tabs.Link
          variant="modal"
          active={mode === "new"}
          label="New Route"
          onClick={() => setMode("new")}
        />
        {routeOptions.length > 0 && (
          <Tabs.Link
            variant="modal"
            active={mode === "existing"}
            label="Existing Route"
            info="Add appointments to an existing route."
            onClick={() => setMode("existing")}
          />
        )}
      </Tabs.Links>
      <Row offset="20">
        {mode === "new" && (
          <>
            <Label variant="vertical">Name</Label>
            <TextField
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              block
              placeholder="Route Name"
              required
              focused
            />
          </>
        )}
        {mode === "existing" && (
          <>
            <Label variant="vertical">Existing Routes</Label>
            <Dropdown
              filter
              title={selectedRoute?.label || "Select a Route"}
              selected={selectedRoute?.value}
              name="routeId"
              variant="outline2"
              minWidth={180}
              onChange={handleRouteSelection}
              options={routeOptions}
            />
            <Row>
              <Label variant="vertical">Buyer</Label>
              <BuyerSection
                buyers={buyers}
                setBuyers={setBuyers}
                createBuyer={onNewBuyer}
              />
            </Row>
          </>
        )}
      </Row>
      {mode === "new" && (
        <>
          <Row>
            <Col>
              <Label variant="vertical">Showing Date</Label>
              <CalendarDropdown
                date={time.toDate().toUTCString()}
                opened={false}
                onChange={handleCalendarChange}
              />
            </Col>
            <Col modifier="offset20">
              <Label variant="vertical">Start Time</Label>
              <Timepicker
                {...datetimeToObject(time)}
                onChange={handleTimeChange}
              />
            </Col>
          </Row>
          <Row>
            <Label variant="vertical">Buyer</Label>
            <BuyerSection
              buyers={buyers}
              setBuyers={setBuyers}
              createBuyer={onNewBuyer}
            />
          </Row>
        </>
      )}
      <Row>
        <AppointmentList
          appointments={selected}
          unavailableListings={unavailableListings}
          advanceNotices={advanceNotices}
          mlsBuyerRequiredEnabled={mlsBuyerRequiredEnabled}
          buyers={buyers}
          handleRemoveListing={handleRemoveListing}
        />
      </Row>
      {mlsBuyerBrokerAgreementEnabled && (
        <BuyerBrokerAgreement actionText="Next" path="route" />
      )}
    </>
  );
};

function mapStateToProps({
  routes,
  currentUser: { mlsBuyerRequiredEnabled, mlsBuyerBrokerAgreementEnabled }
}) {
  const { isFetching, message, errors, updatedRouteId } = routes.single.route;
  const { entities } = routes.list.routes;

  const routeOptions = entities.map(({ id, name, date, buyer_contacts }) => ({
    label: name,
    value: id,
    date: moment(date),
    buyers: buildBuyerOptions(buyer_contacts)
  }));

  return {
    isFetching,
    message,
    errors,
    routeId: updatedRouteId,
    routeOptions,
    mlsBuyerRequiredEnabled,
    mlsBuyerBrokerAgreementEnabled
  };
}
export default connect(mapStateToProps)(SelectDate);
