import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { externalApi } from "~brokerage/middlewares/api";
import AppLogo from "~brokerage/app/components/login/AppLogo.jsx";
import MlsAlignedLogo from "~brokerage/app/components/login/MlsAlignedLogo.jsx";
import SplashScreen from "~brokerage/app/components/login/SplashScreen";
import css from "./layout.sass";
import {
  LIST_MLSES_URL,
  SSO_LOGIN_URL
} from "~brokerage/app/constants/apiUrls";
import Loader from "brokerage/app/components/shared/Loader";
import { withRouter } from "react-router-dom";
import Button from "~brokerage/app/components/shared/Button";
import { isMobileBrowser } from "~brokerage/app/components/shared/SmartAppBanner/util.js";

const MLS_ALIGNED_ADMINISTRATOR = "mls_aligned_administrator";

const defaultOptions = [
  {
    label: "MLS ALIGNED",
    options: [{ value: MLS_ALIGNED_ADMINISTRATOR, label: "MLS Aligned" }]
  }
];

class MarketSelectionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayLoginContainer: true,
      selectedMls: null,
      availableMlses: [],
      isLoading: true,
      invalidLogin: this.props.invalidLogin
    };
  }

  componentDidMount() {
    externalApi(LIST_MLSES_URL, {}, {}, "get")
      .then(response => {
        this.setState({ availableMlses: response.data.data });
      })
      .catch(() => {
        this.setState({ availableMlses: [] });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  mlsOptions = () => {
    const { availableMlses } = this.state;
    let groupedOptions = [];

    if (availableMlses.length !== 0) {
      availableMlses.map(mlsGroup => {
        const group = this.createGroupedOptions(mlsGroup);
        groupedOptions.push(group);
      });
    }
    return groupedOptions.concat(defaultOptions);
  };

  createGroupedOptions = mlsGroup => {
    return {
      label: mlsGroup[0],
      options: this.getOptions(mlsGroup[1])
    };
  };

  getOptions = mlses => {
    let options = [];
    mlses.map(mls => {
      options.push({ value: mls[1], label: mls[0] });
    });
    return options;
  };

  handleChange = selectedMls => {
    this.setState({ selectedMls, invalidLogin: false });
  };

  handleMenuOpen = () => {
    this.setState({ displayLoginContainer: false });
  };

  handleMenuClose = () => {
    this.setState({ displayLoginContainer: true });
  };

  openLoginPage = () => {
    const { selectedMls } = this.state;

    if (selectedMls.value === MLS_ALIGNED_ADMINISTRATOR) {
      this.props.history.push("/login");
    } else {
      externalApi(
        SSO_LOGIN_URL,
        { selected_mls_id: selectedMls.value },
        {},
        "get"
      ).then(response => {
        window.location.href = response.data.sso_login_path;
      });
    }
  };

  renderMarketSelectionForm = () => {
    const { selectedMls, invalidLogin } = this.state;
    const fullWidth = { width: "100%", textAlign: "center", maxWidth: "400px" };

    return (
      <div className={css.formSection}>
        <div
          className={css.headingWrapper}
          style={this.state.displayLoginContainer ? {} : { display: "none" }}
        >
          <p
            className={
              invalidLogin ? css.invalidMessage : css.invalidMessageHidden
            }
          >
            User not found !
          </p>
          <AppLogo />
          <h1 className={css.heading1}>Welcome!</h1>
          <h2 className={css.heading2}>Please select your market below.</h2>
        </div>
        {isMobileBrowser() ? (
          <Select
            value={selectedMls}
            maxMenuHeight={250}
            onChange={this.handleChange}
            options={this.mlsOptions()}
            className={css.selectMLS}
            placeholder="Select your Market"
            onMenuOpen={this.handleMenuOpen}
            onMenuClose={this.handleMenuClose}
          />
        ) : (
          <Select
            value={selectedMls}
            onChange={this.handleChange}
            options={this.mlsOptions()}
            className={css.selectMLS}
            placeholder="Select your Market"
            maxMenuHeight="35vh"
          />
        )}
        <Button
          disabled={selectedMls === null}
          onClick={this.openLoginPage}
          variant="primary"
          modifier="bigger"
          style={fullWidth}
        >
          Continue
        </Button>

        <MlsAlignedLogo />
      </div>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader active />;
    }
    return (
      <div className={css.homePage}>
        <div className={css.loginContainer}>
          {!isMobileBrowser() ? <SplashScreen /> : null}
          {this.renderMarketSelectionForm()}
        </div>
      </div>
    );
  }
}

export default withRouter(MarketSelectionPage);
