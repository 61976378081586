import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from "react-router-dom";
import PagesList from '~brokerage/components/settings/brokerage/PagesList'
import SettingsPage from '~brokerage/components/settings/brokerage/SettingsPage'
import NoMatch from '~brokerage/components/shared/NoMatch'
import AddUser from './users/add'
import ShowingCoordinator from './users/showing-coordinator'
import Assistants from './users/assistants'

export default class BrokerageSettings extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
  }
  render() {
    const { match } = this.props

    return (
      <div>
        <PagesList/>
        <SettingsPage>
          <Switch>
            <Route path={`${match.path}/users`} exact={true}>
              <Redirect to={`${match.path}/users/add`} />
            </Route>
            <Route path={`${match.path}/users/add`} component={AddUser} />
            <Route path={`${match.path}/users/showingCoordinator`} component={ShowingCoordinator} />
            <Route path={`${match.path}/users/assistants`} component={Assistants} />
            <Route path={`${match.path}/users`} component={NoMatch} />
            <Route path={`${match.path}`} component={NoMatch} />
          </Switch>
        </SettingsPage>
      </div>
    )
  }
}
