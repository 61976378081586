const supportData = {
  m00000628: {
    name: "UtahRealEstate.com",
    phone: "801-676-5400",
    email: "Support@UtahRealEstate.com",
    website: "http://help.utahrealestate.com"
  },
  m00000048: {
    name: "Arizona Regional Multiple Listing Service (ARMLS)",
    phone: "480-921-7777",
    email: "",
    website: "https://armls.com/who-do-i-contact",
    feedback: "https://armls.com/aligned-showings#feedbackform"
  },
  m00000101: {
    name: "MLSListings",
    phone: "1-866-734-5787",
    email: "support@mlslistings.com",
    website: "https://support.mlslistings.com/s/ "
  },
  m00000662: {
    name: "Metro MLS",
    phone: "414-778-5400",
    email: "support@metromls.com",
    website: "https://metromls.com/contact-us/"
  },
  m00000531: {
    name: "Regional Multiple Listing Service (RMLS)",
    phone: "503-872-8002",
    email: "helpdesk@rmls.com",
    website: "https://rmlscentral.com/rmls-alignedshowings/"
  },
  m00000462: {
    name: "New Mexico MLS",
    phone: "505-231-0224",
    email: "megsmcfarlane@nmmls.net",
    website: "https://realestateofnewmexico.com" 
  },
  m00000659: {
    name: "South Central Wisconsin Multiple Listing Service",
    phone: "608-240-2800",
    email: "patty@wisre.com",
    website: "https://scwmls.com" 
  },
  t00000039: {
    name: "Aligned Showings",
    phone: "414-778-5400",
    email: "support@mlsaligned.com",
    website: ""
  }
};

export default supportData;
