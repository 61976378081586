import React, { useEffect, useState } from "react";
import { Dropdown } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import { callApi } from "brokerage/middlewares/api";
import css from "./SurveyTypeSelect.sass";

export default function SurveyTypeSelect({
  listingSurveyId,
  listingSurveyName,
  onSelectSurvey,
  onEditSurvey
}) {
  const [surveys, setSurveys] = useState([
    { value: 0, label: "+ New Survey", id: "new" }
  ]);
  const [fetched, setFetched] = useState(false);
  const selectedSurvey = listingSurveyId
    ? surveys.find(({ id }) => id === listingSurveyId)?.value
    : null;

  const fetchSurveys = async () => {
    try {
      const { data } = await callApi("surveys", {}, {}, "get");
      if (data) {
        const optionSurveys = data.map(({ name, id }, index) => ({
          value: index + 1,
          label: name,
          id
        }));
        setSurveys([...surveys, ...optionSurveys]);
      }
      setFetched(true);
    } catch (err) {
      //ignore
    }
  };

  useEffect(fetchSurveys, []);

  useEffect(() => {
    if (fetched && listingSurveyId) {
      const selectedIndex = surveys.findIndex(
        ({ id }) => id === listingSurveyId
      );
      if (selectedIndex > 0) {
        const surveysCopy = [...surveys];
        if (surveysCopy[selectedIndex].label !== listingSurveyName) {
          surveysCopy[selectedIndex].label = listingSurveyName;
          setSurveys(surveysCopy);
        }
      } else
        setSurveys([
          ...surveys,
          {
            value: surveys.length,
            label: listingSurveyName,
            id: listingSurveyId
          }
        ]);
    }
  }, [listingSurveyId, listingSurveyName, fetched]);

  return (
    <div className={css.surveyWrapper}>
      <div className={css.controlRow}>
        <p className={css.innerLabel}>Select survey:</p>
        <Dropdown
          options={surveys}
          selected={selectedSurvey}
          placeholder="Pick Survey"
          onChange={({ value }) => onSelectSurvey(surveys[value])}
          variant="outline"
          listDirection="up"
        />
        {!!listingSurveyId && (
          <div className={css.editWrapper}>
            <Button variant="outline" onClick={onEditSurvey}>
              Edit Survey
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
