/* eslint-disable no-console */
import css from './index.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectListing,
  selectAgent,
  save,
  send,
  closeModal,
  clearPreserveGlobalState,
  clearForm
} from '~brokerage/actions/newShowing'
import { fetchShowing } from '~brokerage/actions/showings'
import Sidebar from './Sidebar'
import Content from './Content'
import { withRouter } from 'react-router-dom'

class ModalNewShowing extends Component {
  static propTypes = {
    listingId: PropTypes.string,
    showing: PropTypes.object,
    dispatch: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object
  }

  static contextTypes = {
    currentUser: PropTypes.object
  }

  componentDidMount() {
    this.initializeFromShowing() || this.initializeFromListing()
    this.props.dispatch(clearPreserveGlobalState())
  }

  initializeFromShowing() {
    const showing = this.props.showing
    if (showing === void 0) return false

    const { currentUser } = this.context
    this.props.dispatch(selectListing(this.props.showing.listingKey))
    const agent = showing.showingAgents[0] || {
        userId: currentUser.platformId,
        name: currentUser.name,
        phone: currentUser.phone
      }
    this.props.dispatch(selectAgent(agent))
  }

  initializeFromListing() {
    if(!this.props.listingId) {
      return false
    }

    this.props.dispatch(selectListing(this.props.listingId))
  }

  componentWillUnmount() {
    this.props.dispatch(closeModal())
    this.props.dispatch(clearForm())
  }

  saveOrUpdate(payload) {
    const { showing } = this.props
    return showing !== void 0 ? send(showing.id, payload) : save(payload)
  }

  handleCreateAndExitClick = (payload) => {
    const { history, location } = this.props

    this.props
        .dispatch(this.saveOrUpdate(payload))
        .then(({data}) => {
          this.props.dispatch(fetchShowing(data.unique_id))
          this.props.dispatch(clearForm())
          history.push({
            ...location,
            query: { ...location.query, modal: void 0 }
          })
        })
        .catch(this.handleSaveError)
  }

  handleCreateAndViewClick = (payload) => {
    const { history } = this.props

    this.props
        .dispatch(this.saveOrUpdate(payload))
        .then(({ data }) => {
          this.props.dispatch(clearForm())
          history.push(`/showings/${data.unique_id}`)
        })
        .catch(this.handleSaveError)
  }

  handleSaveError(error) {
    if (typeof error === 'object' && error.data && error.data.errors) {
      alert(error.data.errors)
      return
    }

    console.error(error)
  }

  render() {
    return (
      <div className={css.base}>
        <Sidebar/>
        <Content
          onCreateAndExitClick={this.handleCreateAndExitClick}
          onCreateAndViewClick={this.handleCreateAndViewClick}
          showing={this.props.showing}
        />
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  const { id: listingId } = state.showings.new.selectedListing.entity

  return {
    listingId
  }
}
export default withRouter(connect(mapStateToProps)(ModalNewShowing));
