import css from './SettingsHeader.sass'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import { SettingsContext } from '~brokerage/app/pages/settings/context/SettingsContext';

const SettingsHeader = props => {
  const { isLegacySettingsModalOpen, setisLegacySettingsModalOpen, pageState  } = useContext(SettingsContext);

  return (
    <div className={css.base}></div>
  );
}


SettingsHeader.contextTypes = {
  currentUser: PropTypes.object,
}

export default connect()(SettingsHeader)
