import React from "react";
import { connect } from "react-redux";
import TeamMemberAddForm from "~brokerage/components/shared/TeamMemberAddForm";

const PeopleAddForm = ({
  availableMembers,
  onCancel,
  onSubmit,
  person,
  notificationLevels,
  teamMembers,
  dispatch
}) => (
  <TeamMemberAddForm
    onCancel={onCancel}
    onSubmit={onSubmit}
    person={person}
    dispatch={dispatch}
    availableMembers={availableMembers}
    notificationLevels={notificationLevels}
    teamMembers={teamMembers}
  />
);

function mapStateToProps(state) {
  const { entities: availableMembers, isFetching } =
    state.listings.single.availableMembers;
  const { entities: teamMembers } = state.listings.single.teamMembers;
  const { notificationLevels } = state.listings.single.showingDetails.entity;

  return {
    teamMembers,
    notificationLevels,
    availableMembers,
    isFetching
  };
}
export default connect(mapStateToProps)(PeopleAddForm);
