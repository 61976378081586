import filter from 'lodash/filter'

export const filterUnregisteredAgents = (agents, originalQuery) => {
  if (originalQuery) {
    const query = originalQuery.toLowerCase()
    agents = filter(agents,
      (agent) => {
        let city = agent.city ? agent.city.toLowerCase() : ''
        let firstName = agent.firstName ? agent.firstName.toLowerCase() : ''
        let lastName = agent.lastName ? agent.lastName.toLowerCase() : ''
        let fullName = [firstName, lastName].join(' ')
        let reversedFullName = [lastName, firstName].join(' ')

        return (fullName.includes(query) || reversedFullName.includes(query) || city.includes(query))
      }
    )
  }
  return agents
}
