import css from "./Listing.sass";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  plural,
  formatNumberUsingCommas,
  formatPrice
} from "~brokerage/libs/helpers/FormatHelper";
import { sellerTenantWithPreferredCommunication } from "~brokerage/libs/helpers/SellerTenantHelper";
import {
  sortRestrictions,
  renderTimeRangesFor,
  renderDates,
  renderDays
} from "~brokerage/libs/helpers/RestrictionsHelper";
import Avatar from "~brokerage/components/shared/Avatar";
import List from "~brokerage/components/shared/List";

const Listing = ({
  listing,
  showingInstructions,
  sellerTenants,
  teamMembers,
  restrictions,
  step,
  dispatch
}) => {
  const renderBeds = () => {
    if (listing.specs && listing.specs.beds) {
      return (
        <List.Item icon="listingBed" modifier="pullLeft">
          {listing.specs.beds} Bed{plural(listing.specs.beds)}
        </List.Item>
      );
    }
  };

  const renderBaths = () => {
    if (listing.specs && listing.specs.baths) {
      return (
        <List.Item icon="listingBath" modifier="pullLeft">
          {listing.specs.baths} Bath{plural(listing.specs.baths)}
        </List.Item>
      );
    }
  };

  const renderSqft = () => {
    if (listing.specs && listing.specs.sqft) {
      return (
        <List.Item icon="listingSqft">
          {formatNumberUsingCommas(listing.specs.sqft)} sqft
        </List.Item>
      );
    }
  };

  const renderTeamMembers = () => {
    if (teamMembers.length) {
      return (
        <List.Item icon="userMultiple" modifier="overflowHidden">
          {teamMembers.map(m => m.name).join(", ")}
        </List.Item>
      );
    }
  };

  const renderInstructionsRequestHandling = () => {
    switch (showingInstructions.requestHandling) {
      case "require_approval":
        return <List.Item icon="info">Require Approval</List.Item>;
      case "instant_approval":
        return <List.Item icon="info">Instant Approval</List.Item>;
    }
  };

  const renderInstructionsAccess = () => {
    switch (showingInstructions.access) {
      case "combo":
        return (
          <List.Item icon="combo">
            Combo Lockbox, {showingInstructions.combo}
          </List.Item>
        );
      case "electronic":
        return <List.Item icon="lockbox">Electronic Lockbox</List.Item>;
    }
  };

  const renderInstructions = () => {
    if (showingInstructions.instructions) {
      return (
        <List heading="SHOWING INSTRUCTIONS">
          <List.Item icon="info">{showingInstructions.instructions}</List.Item>
        </List>
      );
    }
  };

  const renderRestrictions = () => {
    if (restrictions.length) {
      const sortedRestrictions = sortRestrictions(restrictions);

      return (
        <List heading="TIME/DATE RESTRICTIONS">
          {sortedRestrictions.map(r => (
            <List.Item icon="showingRestricted" key={r.id}>
              {[renderPeriod(r), renderTimeRangesFor(r)].join(
                restrictionSeparator(r)
              )}
            </List.Item>
          ))}
        </List>
      );
    }
  };

  const restrictionSeparator = restriction => {
    return typeof restriction.timeRanges !== "undefined" &&
      restriction.timeRanges.length
      ? " at "
      : " ";
  };

  const renderPeriod = restriction => {
    return restriction.repetitionType === "weekly"
      ? renderDays(restriction)
      : renderDates(restriction);
  };

  const renderListingTeamInstructions = () => {
    if (listing.teamInstructions) {
      return (
        <List heading="TEAM INSTRUCTIONS">
          <List.Item icon="info">{listing.teamInstructions}</List.Item>
        </List>
      );
    }
  };

  const renderSellerTenats = () => {
    if (sellerTenants.length) {
      return (
        <List heading={`SELLER${plural(sellerTenants.length, "", "S")}`}>
          {sellerTenants.map(seller => (
            <List.Item key={seller.uuid} icon="info">
              {sellerTenantWithPreferredCommunication(seller)}
            </List.Item>
          ))}
        </List>
      );
    }
  };

  return (
    <div>
      <List>
        <List.Item>
          <div className={css.photoAndAddressContainer}>
            <div className={css.photoAndAddress}>
              <div className={css.photo}>
                <Avatar src={listing.photoUrl} />
              </div>
              <div className={css.address}>{listing.address}</div>
            </div>
          </div>
        </List.Item>
        <List.Item icon="info" modifier="pullLeft">
          {listing.mls}
        </List.Item>
        <List.Item icon="dollar">{formatPrice(listing.price)}</List.Item>
        {renderBeds()}
        {renderBaths()}
        {renderSqft()}
        {renderTeamMembers()}
      </List>

      <List heading="SHOWING DETAILS">
        <List.Item icon="info">
          {showingInstructions.shown ? "Allow" : "Do not allow"} property to be
          shown
        </List.Item>
        {renderInstructionsRequestHandling()}
        {showingInstructions.accompanyAgent && (
          <List.Item icon="user">Agent will accompany</List.Item>
        )}
        {showingInstructions.accompanySeller && (
          <List.Item icon="user">Seller/Tenant will accompany</List.Item>
        )}
        {renderInstructionsAccess()}
      </List>

      {renderRestrictions()}
      {renderInstructions()}
      {renderListingTeamInstructions()}
      {renderSellerTenats()}
    </div>
  );
};

Listing.propTypes = {
  listing: PropTypes.object,
  showingInstructions: PropTypes.object,
  sellerTenants: PropTypes.array,
  restrictions: PropTypes.array,
  teamMembers: PropTypes.array,
  dispatch: PropTypes.func,
  step: PropTypes.string
};

function mapStateToProps(state) {
  const {
    entity: listing,
    showingInstructions,
    restrictions,
    sellerTenants,
    teamMembers
  } = state.showings.new.selectedListing;

  return {
    listing,
    showingInstructions,
    sellerTenants,
    teamMembers,
    restrictions
  };
}
export default connect(mapStateToProps)(Listing);
