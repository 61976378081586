import { combineReducers } from "redux";

import {
  PERSON_REQUEST,
  PERSON_SUCCESS,
  PERSON_FAILURE,
  PERSON_UPDATE,
  PERSON_LISTINGS_REQUEST,
  PERSON_LISTINGS_SUCCESS,
  PERSON_LISTINGS_FAILURE
} from "~brokerage/constants/actionTypes";

function person(state = {}, action) {
  switch (action.type) {
    case PERSON_REQUEST:
      return { ...state, isFetching: true };
    case PERSON_SUCCESS:
      return {
        ...state,
        entity: action.data.person,
        errors: false,
        isFetching: false
      };
    case PERSON_FAILURE:
      return { ...state, isFetching: false, errors: true };
    case PERSON_UPDATE:
      return {
        ...state,
        entity: action.person,
        errors: false,
        isFetching: false
      };
    default:
      return state;
  }
}

const defaultPersonListingsState = {
  entities: [],
  isFetching: false,
  page: 1
};

function listings(state = defaultPersonListingsState, action) {
  switch (action.type) {
    case PERSON_LISTINGS_REQUEST:
      return { ...state, isFetching: true };
    case PERSON_LISTINGS_SUCCESS: {
      const isFirstPage = action.data.page === 1;
      const newEntities = isFirstPage
        ? action.data.listings
        : [...state.entities, ...action.data.listings];

      return {
        ...state,
        entities: newEntities,
        page: action.data.page,
        totalPages: action.data.totalPages,
        totalListings: action.data.totalListings,
        isFetching: false
      };
    }
    case PERSON_LISTINGS_FAILURE:
      return {
        ...state,
        errors: true,
        isFetching: false,
        entities: [],
        totalPages: null,
        totalListings: null
      };
    default:
      return state;
  }
}

export default combineReducers({
  person,
  listings
});
