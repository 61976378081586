import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchConversations(filter = {}) {
  return api.messaging.fetchConversations(filter, [
    types.FETCH_CONVERSATIONS_REQUEST,
    types.FETCH_CONVERSATIONS_SUCCESS,
    types.FETCH_CONVERSATIONS_FAILURE
  ]);
}

export function setBroadcastListingId(listingId) {
  return {
    type: types.SET_BROADCAST_LISTING_ID,
    listingId
  };
}

export function searchMessages(filter) {
  return api.messaging.searchMessages(filter, [
    types.SEARCH_MESSAGES_REQUEST,
    types.SEARCH_MESSAGES_SUCCESS,
    types.SEARCH_MESSAGES_FAILURE
  ]);
}

export function setSearchTerm(search_term) {
  return {
    type: types.SET_SEARCH_MESSAGE_TERM,
    search_term
  };
}

export function setSearchBarFocus(search_bar_focus_status) {
  return {
    type: types.SET_SEARCH_BAR_FOCUS,
    search_bar_focus_status
  };
}

export function updateConversationsFilter(newFilter = {}) {
  return { newFilter, type: types.UPDATE_CONVERSATIONS_FILTER };
}

export function updateConversationsList(message, type, isMessageRead) {
  return {
    type: types.UPDATE_CONVERSATIONS_LIST,
    conversationType: type,
    message,
    isMessageRead
  };
}

export function scrollChatThread(type, id, filter = {}) {
  return api.messaging.fetchConversationMessages(type, id, filter, [
    types.SCROLL_CHAT_THREAD_REQUEST,
    types.SCROLL_CHAT_THREAD_SUCCESS,
    types.SCROLL_CHAT_THREAD_FAILURE
  ]);
}

export function updateDirectMessageReadReceipt(message) {
  return {
    type: types.UPDATE_DIRECT_MESSAGE_HAS_READ,
    message
  };
}

export function updateLastMessageForConversation(type, message) {
  return {
    type: types.UPDATE_LAST_MESSAGE_FOR_CONVERSATION,
    conversationType: type,
    message
  };
}

export function scrollConversations(filter = {}) {
  return api.messaging.fetchConversations(filter, [
    types.CONVERSATION_LIST_SCROLLED,
    types.CONVERSATION_LIST_SCROLLED_SUCCESS,
    types.CONVERSATION_LIST_SCROLLED_FAILURE
  ]);
}

export function scrollConversationsSearchMessages(filter = {}) {
  return api.messaging.searchMessages(filter, [
    types.SEARCH_MESSAGES_SCROLLED,
    types.SEARCH_MESSAGES_SCROLLED_SUCCESS,
    types.SEARCH_MESSAGES_SCROLLED_FAILURE
  ]);
}

export function createDirectConversation(agentIDs, conversationName, text) {
  return api.messaging.createDirectConversation(
    agentIDs,
    conversationName,
    text,
    [
      types.CREATE_DIRECT_CONVERSATION_REQUEST,
      types.CREATE_DIRECT_CONVERSATION_SUCCESS,
      types.CREATE_DIRECT_CONVERSATION_FAILURE
    ]
  );
}

export function updateDirectConversationName(conversationId, name) {
  return api.messaging.updateDirectConversationName(conversationId, name, [
    types.UPDATE_DIRECT_CONVERSATION_NAME_REQUEST,
    types.UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS,
    types.UPDATE_DIRECT_CONVERSATION_NAME_FAILURE
  ]);
}

export function markConversationAsRead(id, type) {
  return dispatch => {
    dispatch({
      type: types.MARK_CONVERSATION_AS_READ,
      conversationType: type,
      id
    });
    dispatch(
      api.messaging.markConversationAsRead(id, type, [
        types.MARK_CONVERSATION_AS_READ_REQUEST,
        types.MARK_CONVERSATION_AS_READ_SUCCESS,
        types.MARK_CONVERSATION_AS_READ_FAILURE
      ])
    );
  };
}

export function fetchUnreadMessagesCount() {
  return api.messaging.fetchUnreadMessagesCount([
    types.FETCH_UNREAD_MESSAGES_COUNTS_REQUEST,
    types.FETCH_UNREAD_MESSAGES_COUNTS_SUCCESS,
    types.FETCH_UNREAD_MESSAGES_COUNTS_FAILURE
  ]);
}

export function updateUnreadMessagesCounters(counters) {
  return api.messaging.updateUnreadMessagesCounters(
    counters,
    types.UPDATE_UNREAD_MESSAGES_COUNTERS
  );
}

export function unsetCreatedConversationId() {
  return { type: types.UNSET_CREATED_CONVERSATION_ID };
}

export function lockMessagesList() {
  return {
    type: types.LOCK_MESSAGES_LIST
  };
}

export function unlockMessagesList() {
  return {
    type: types.UNLOCK_MESSAGES_LIST
  };
}

export function fetchAvailableAgents(name) {
  return api.myOrganization.fetchAvailableMembers({ name }, [
    types.MESSAGING_AVAILABLE_MEMBERS_REQUEST,
    types.MESSAGING_AVAILABLE_MEMBERS_SUCCESS,
    types.MESSAGING_AVAILABLE_MEMBERS_FAILURE
  ]);
}

export function addMessageFromPubnub(message) {
  return {
    type: types.MESSAGING_ADD_MESSAGE_FROM_PUBNUB,
    message
  };
}

export function addMessage(type, id, message, attachments = {}, filter = {}) {
  return api.messaging.createMessage(type, id, message, attachments, filter, [
    types.CONVERSATION_ADD_MESSAGE_REQUEST,
    types.CONVERSATION_ADD_MESSAGE_SUCCESS,
    types.CONVERSATION_ADD_MESSAGE_FAILURE
  ]);
}

export function addNoteMessage(
  type,
  id,
  message,
  attachments = {},
  filter = {}
) {
  return api.messaging.createNoteMessage(
    type,
    id,
    message,
    attachments,
    filter,
    [
      types.CONVERSATION_ADD_NOTE_MESSAGE_REQUEST,
      types.CONVERSATION_ADD_NOTE_MESSAGE_SUCCESS,
      types.CONVERSATION_ADD_NOTE_MESSAGE_FAILURE
    ]
  );
}

export function fetchPubnubData(conversationType, conversationId, filter = {}) {
  return api.messaging.fetchPubnubData(
    conversationType,
    conversationId,
    filter,
    [
      types.FETCH_CONVERSATION_PUBNUB_DATA_REQUEST,
      types.FETCH_CONVERSATION_PUBNUB_DATA_SUCCESS,
      types.FETCH_CONVERSATION_PUBNUB_DATA_FAILURE
    ]
  );
}

export function fetchConversationMessages(
  conversationType,
  conversationId,
  filter = {}
) {
  return api.messaging.fetchConversationMessages(
    conversationType,
    conversationId,
    filter,
    [
      types.FETCH_CONVERSATION_MESSAGES_REQUEST,
      types.FETCH_CONVERSATION_MESSAGES_SUCCESS,
      types.FETCH_CONVERSATION_MESSAGES_FAILURE
    ]
  );
}

export function setRoleForMessages(role) {
  return {
    type: types.SET_ROLE_FOR_MESSAGES,
    role
  };
}

export function fetchConversationParticipants(
  conversationType,
  conversationId,
  filter = {}
) {
  return api.messaging.fetchConversationParticipants(
    conversationType,
    conversationId,
    filter,
    [
      types.FETCH_CONVERSATION_PARTICIPANTS_REQUEST,
      types.FETCH_CONVERSATION_PARTICIPANTS_SUCCESS,
      types.FETCH_CONVERSATION_PARTICIPANTS_FAILURE
    ]
  );
}

export function clearAvailableAgents() {
  return {
    type: types.MESSAGING_AVAILABLE_MEMBERS_CLEAR
  };
}

export function fetchAvailableRecipients(search) {
  return api.people.fetchPeople({ filter: { search }, exclude_self: true }, [
    types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST,
    types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS,
    types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE
  ]);
}

export function fetchAvailableDirectRecipients(search) {
  return api.people.fetchPeople(
    { filter: { search, category: "direct_chat" }, exclude_self: true },
    [
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST,
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS,
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE
    ]
  );
}

export function fetchAvailableGroupRecipients(search) {
  return api.people.fetchPeople(
    { filter: { search, category: "group_chat" }, exclude_self: true },
    [
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST,
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS,
      types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE
    ]
  );
}

export function addAgentToTeam(conversationType, conversationId, agentId) {
  return api.messaging.addAgentToTeam(
    conversationType,
    conversationId,
    agentId,
    [
      types.MESSAGING_ADD_AGENT_TO_THE_TEAM_REQUEST,
      types.MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS,
      types.MESSAGING_ADD_AGENT_TO_THE_TEAM_FAILURE
    ]
  );
}

export function removeAgentFromTeam(conversationType, conversationId, agentId) {
  return api.messaging.removeAgentFromTeam(
    conversationType,
    conversationId,
    agentId,
    [
      types.MESSAGING_REMOVE_AGENT_FROM_TEAM_REQUEST,
      types.MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS,
      types.MESSAGING_REMOVE_AGENT_FROM_TEAM_FAILURE
    ]
  );
}

export function fetchAvailableParticipants(type, id, name) {
  return api.messaging.fetchAvailableParticipants(type, id, name, [
    types.MESSAGING_AVAILABLE_AGENTS_REQUEST,
    types.MESSAGING_AVAILABLE_AGENTS_SUCCESS,
    types.MESSAGING_AVAILABLE_AGENTS_FAILURE
  ]);
}

export function addRecipientToNewMessageRecipients(recipient) {
  return {
    type: types.MESSAGES_NEW_RECIPIENTS_ADD_RECIPIENT,
    recipient
  };
}

export function removeRecipientFromNewMessageRecipients(recipient) {
  return {
    type: types.MESSAGES_NEW_RECIPIENTS_REMOVE_RECIPIENT,
    recipient
  };
}

export function clearNewMessageRecipients() {
  return {
    type: types.MESSAGES_NEW_RECIPIENTS_CLEAR
  };
}

export function preserveNewMessageGlobalState(message) {
  return {
    type: types.MESSAGES_NEW_PRESERVE_GLOBAL_STATE,
    message
  };
}

export function clearPreserveNewMessageGlobalState() {
  return {
    type: types.MESSAGES_NEW_CLEAR_PRESERVE_GLOBAL_STATE
  };
}

export function clearNewMessageForm() {
  return {
    type: types.MESSAGES_NEW_CLEAR_FORM
  };
}

export function clearAvailableRecipients() {
  return {
    type: types.MESSAGES_NEW_AVAILABLE_RECIPIENTS_CLEAR
  };
}

export function fetchShowing(id) {
  return api.showings.fetchShowing(id, [
    types.MESSAGES_SHOWING_REQUEST,
    types.MESSAGES_SHOWING_SUCCESS,
    types.MESSAGES_SHOWING_FAILURE
  ]);
}
