import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ModalShowingDetailsContainer extends React.PureComponent {
  static propTypes = {
    showingInstructions: PropTypes.shape({
      lockboxKind: PropTypes.string,
      lockboxCode: PropTypes.string,
      accompanyAgent: PropTypes.bool,
      accompanySeller: PropTypes.bool,
      requestACall: PropTypes.bool,
      message: PropTypes.string
    }),
    status: PropTypes.string,
    hasResponse: PropTypes.bool,
    role: PropTypes.string,
    showingId: PropTypes.string,
    isSubmitting: PropTypes.bool,
    children: PropTypes.element,
    onClose: PropTypes.func
  };

  render() {
    const { children, ...rest } = this.props;
    return React.cloneElement(children, {
      ...rest,
      onSave: this.props.onClose
    });
  }
}

function mapStateToProps(state) {
  const {
    isEditInstructionsSubmitting: isSubmitting,
    roleForLastShowing: role
  } = state.showings.single.showing;
  const {
    id: showingId,
    instructions: showingInstructions,
    status,
    hasResponse: showingHasResponse
  } = state.showings.single.showing.entity;

  return {
    role,
    showingId,
    status,
    showingHasResponse,
    showingInstructions,
    isSubmitting
  };
}
export default withRouter(
  connect(mapStateToProps)(ModalShowingDetailsContainer)
);
