import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Content from "~brokerage/components/shared/Content";
import MyShowings from "brokerage/app/components/myShowings";
import PropertySearch from "brokerage/app/components/propertySearch";
import ScheduleRoute from "brokerage/app/components/scheduleRoute";

export const UserContext = createContext({});

const ShowingRoutes = (props, context) => (
  <UserContext.Provider value={context}>
    <Content style="noPadding">
      <Switch>
        <Route path={`${props.match.path}/create`} component={PropertySearch} />
        <Route
          path={`${props.match.path}/:id/schedule`}
          component={ScheduleRoute}
        />
        <Route path={`${props.match.path}/:id/add-appointments`}>
          <PropertySearch location={props.location} variant="addAppointments" />
        </Route>
        <Route path={`${props.match.path}`} component={MyShowings} />
      </Switch>
    </Content>
  </UserContext.Provider>
);

ShowingRoutes.contextTypes = {
  currentUser: PropTypes.object,
  clientData: PropTypes.object
};

ShowingRoutes.propTypes = {
  match: PropTypes.object
};

export default ShowingRoutes;
