import AlertFillIcon from "remixicon-react/AlertFillIcon";
import IndeterminateCircleFillIcon from "remixicon-react/IndeterminateCircleFillIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import PhoneFillIcon from "remixicon-react/PhoneFillIcon";
import TimeFillIcon from "remixicon-react/TimeFillIcon";

export const eventIcons = {
  approved: CheckboxCircleFillIcon,
  preapproved: CheckboxCircleFillIcon,
  instant_approved: CheckboxCircleFillIcon,
  declined: CloseCircleFillIcon,
  instant_declined: CloseCircleFillIcon,
  pending: AlertFillIcon,
  instant_pending: AlertFillIcon,
  cancelled: IndeterminateCircleFillIcon,
  instructions: InformationFillIcon,
  request_a_call: PhoneFillIcon,
  time_changed: TimeFillIcon
};

export const eventColors = {
  misc: "#2980b9", //Belize Hole
  approved: "#27ae60", //Nephritis
  preapproved: "#27ae60", //Nephritis
  instant_approved: "#27ae60", //Nephritis
  declined: "#c0392b", //Pomegranate
  instant_declined: "#c0392b", //Pomegranate
  pending: "#f1c40f", //Orange
  instant_pending: "#f1c40f", //Orange
  cancelled: "#34495e", //Wet Asphalt
  instructions: "#2980b9", //Belize Hole
  request_a_call: "#2980b9", //Belize Hole
  time_changed: "#2980b9" //Belize Hole
};
