import React from "react";
import css from "./index.sass";

import { getSender } from "./utils";

export const RequestText = ({
  message: {
    requestDate,
    requestStartTime,
    requestEndTime,
    senderName,
    isInstant,
    isFromMe
  }
}) => {
  const sender = getSender({ isFromMe, senderName, senderSuffix: " has" });

  if (isInstant) {
    return (
      <div className={css.messageBlock}>
        <b>Instant Reply:</b> The showing request is still pending.
      </div>
    );
  }
  return (
    <div className={css.messageBlock}>
      {sender} requested to see the property on <b>{requestDate}</b> from{" "}
      <b>{requestStartTime}</b> to <b>{requestEndTime}</b>.
    </div>
  );
};

export const StatusChangeText = ({ action, message }) => {
  const sender = getSender(message);
  const { requestDate, requestStartTime, requestEndTime } = message;

  return (
    <div className={css.messageBlock}>
      {message.isInstant && <b>Instant Reply: </b>}
      {sender} <b>{action}</b> the showing request for <b>{requestDate}</b> from{" "}
      <b>{requestStartTime}</b> to <b>{requestEndTime}</b>.
    </div>
  );
};

export const TimeChangeText = ({
  message: {
    requestDate,
    requestStartTime,
    requestEndTime,
    senderName,
    isFromMe
  }
}) => {
  const sender = getSender({ isFromMe, senderName });

  return (
    <div className={css.messageBlock}>
      {sender} <b>Changed the time</b> to <b>{requestDate}</b> at{" "}
      <b>{requestStartTime}</b> to <b>{requestEndTime}</b>.
    </div>
  );
};

export const RequestACallText = ({
  message: { senderName, isFromMe, phone }
}) => {
  const sender = getSender({ isFromMe, senderName });

  return (
    <div className={css.messageBlock}>
      {sender} has requested you call first at <b>{phone}</b>
    </div>
  );
};
