import css from "./ShowingItem.sass";
import React from "react";
import Link from "~brokerage/components/shared/Link";
import { withRouter } from "react-router-dom";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil";
import Icon from "~brokerage/components/shared/Icon";
import moment from "moment";

const ShowingItem = props => {
  const { address, status, listing_image, unique_id, listing_id, start_time } =
    props.showing;
  const date = moment(start_time);
  const statusDisplay = status === "Not_sent" ? "Incomplete" : status;
  return (
    <Link to={`/showings/${unique_id}`}>
      <div className={css.base}>
        <PropertyThumbnail image={listing_image} className={css.photo} />
        <div className={css.addressContainer}>
          <div className={css.address}>
            <span className={css.addressStatus}>{statusDisplay}</span>
            <h1 className={css.h1}>{address}</h1>
          </div>
        </div>
        <div>
          <ul className={css.ul}>
            <li className={css.li}>
              <div className={css.listIcon}>
                <Icon name="calendar" />
                {date.format("lll")}
              </div>
            </li>
            <li className={css.li}>
              <div className={css.listIcon}>
                <Icon name="info" />
                {`MLS# ${listing_id}`}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Link>
  );
};
export default withRouter(ShowingItem);
