import css from './List.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchPersonListings } from '~brokerage/actions/people'
import ReactList from 'react-list'
import EmptyMessage from '~brokerage/components/shared/EmptyMessage'
import Loader from '~brokerage/components/shared/Loader'
import ListItem from './ListItem'
import ListItemLoader from '~brokerage/components/shared/ListItemLoader'


class List extends React.PureComponent {
  static propTypes = {
    personId: PropTypes.string,
    listings: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func
  }

  needLoadMoreListings(listingIndex) {
    const { currentPage, totalPages, listings, isFetching } = this.props

    const hasMoreListings = currentPage < totalPages
    const isItemLastOfLoaded = listings.length - listingIndex === 1

    return !isFetching && hasMoreListings && isItemLastOfLoaded
  }

  loadMoreListings() {
    const { personId, currentPage, dispatch } = this.props
    dispatch(fetchPersonListings(personId, currentPage + 1))
  }

  renderReactList() {
    return (
      <ReactList
        itemsRenderer={::this.renderTable}
        itemRenderer={::this.renderListing}
        length={this.props.listings.length}
        type="simple"
      />
    )
  }

  renderTable(items, ref) {
    return(
      <div className={css.base}>
        <table className={css.table}>
          <tbody ref={ref}>
            {items}
          </tbody>
        </table>
      </div>
    )
  }

  renderListing(index, key) {
    const listing = this.props.listings[index]

    return (
      <ListItemLoader
        key={key}
        needLoadMore={this.needLoadMoreListings(index)}
        loadMore={::this.loadMoreListings}
        uniqueId={listing.id}
      >
        <tr className={css.tr}>
          <td className={css.td}>
            <ListItem listing={listing} />
          </td>
        </tr>
      </ListItemLoader>
    )
  }

  render() {
    const { isFetching } = this.props
    const anyListing = this.props.listings.length > 0

    const loader = <Loader active={isFetching} />
    const emptyMessage = <EmptyMessage>No listings.</EmptyMessage>

    return (
      <div>
        {isFetching && !anyListing ? loader : anyListing ? this.renderReactList() : emptyMessage}
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { entities: listings, page: currentPage, totalPages, isFetching } = state.people.single.listings

  return {
    listings,
    currentPage,
    totalPages,
    isFetching
  }
}
export default connect(mapStateToProps)(List)
