import {
  FEEDBACK_SURVEYS_REQUEST,
  FEEDBACK_SURVEYS_SUCCESS,
  FEEDBACK_SURVEYS_FAILURE
} from "~brokerage/constants/actionTypes";

const defaultState = {
  isFetching: false,
  entities: []
};

export default function surveysReducer(state = defaultState, action) {
  switch (action.type) {
    case FEEDBACK_SURVEYS_REQUEST:
      return { ...state, isFetching: true };
    case FEEDBACK_SURVEYS_SUCCESS:
      return {
        ...state,
        entities: action.data,
        isFetching: false
      };
    case FEEDBACK_SURVEYS_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetching: false
      };
    default:
      return state;
  }
}
