import css from './NoReplyMessage.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'
import Link from '~brokerage/components/shared/Link'
import { withRouter } from 'react-router-dom'
import { trigger } from '~brokerage/app/helpers/events.js'

class NoReplyMessage extends React.PureComponent {
  static propTypes = {
    showing: PropTypes.shape({
      showingAgents: PropTypes.array,
      listingAgents: PropTypes.array
    }),
    role: PropTypes.string,
    isStatusEditable: PropTypes.bool,
    location: PropTypes.object
  };

  handleRequestReview = () => {
    trigger('requestReview', { message: `Per our conversation, please update the appointment status to "Approved".` });
  };

  render() {
    const { showing, role, isStatusEditable, location } = this.props;

    const agents = showing[role === "ba" ? "listingAgents" : "showingAgents"];
    const agent = agents[0];

    return (
      <div className={css.base}>
        <div className={css.row}>
          <div className={css.icon}>
            <Icon name="phone" />
          </div>
          No response yet?{" "}
          <Link
            to={{
              ...location,
              query: {
                ...location.query,
                modal: "agent_details",
                agent_id: agent.userId
              }
            }}
          >
            Call Agent
          </Link>
        </div>
        {isStatusEditable && (
          <div className={css.row}>
            <div className={css.icon}>
              <Icon name="edit" />
            </div>
            Verbal approval & instructions?{" "}
            <Link onClick={this.handleRequestReview}>Request a Status Change</Link>
          </div>
        )}

      </div>
    );
  }
}

export default withRouter(NoReplyMessage);
