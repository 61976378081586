import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Content from "~brokerage/components/shared/Content";
import PeopleHeader from "~brokerage/components/people/PeopleHeader";
import { Switch, Route } from "react-router-dom";
import List from "./list";
import Show from "./show";
import ShowContact from "./showContact";
class People extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    children: PropTypes.node,
    dispatch: PropTypes.func
  };

  render() {
    return (
      <div>
        <PeopleHeader location={this.props.location} />
        <Content header="single">
          <Switch>
            <Route
              path={`${this.props.match.path}/contact/:personId`}
              component={ShowContact}
            />
            <Route
              path={`${this.props.match.path}/:personId`}
              component={Show}
            />
            <Route path={`${this.props.match.path}`} component={List} />
          </Switch>
        </Content>
      </div>
    );
  }
}
export default connect()(People);
