import css from "./SmartModal.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";

class SmartModal extends Component {
  static propTypes = {
    modal: PropTypes.node,
    variant: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  modalContainer = document.createElement("div");

  componentDidMount() {
    document.body.appendChild(this.modalContainer);
  }

  componentWillUnmount() {
    document.body.removeChild(this.modalContainer);
  }

  handleOverlayClick = event => {
    const { variant = "base" } = this.props;

    if (event.target.className === css[`${variant}Container`]) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        query: { ...this.props.location.query, modal: void 0 }
      });
    }
  };

  renderModal() {
    const { variant = "base" } = this.props;

    return (
      <div>
        <div
          className={css[`${variant}Container`]}
          onClick={this.handleOverlayClick}
        >
          {this.props.modal}
        </div>
        <div className={css[`${variant}Overlay`]} />
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), this.modalContainer);
  }
}

export default withRouter(SmartModal);
