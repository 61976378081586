import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from '~brokerage/components/shared/Form'

export default class Durationpicker extends React.PureComponent {
  static propTypes = {
    duration: PropTypes.number,
    onChange: PropTypes.func
  }

  get options() {
    return [
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 30, label: '30' },
      { value: 45, label: '45' },
      { value: 60, label: '60' }
    ]
  }

  handleChange = (option) => {
    this.props.onChange(option.value)
  }

  render() {
    return (
      <Dropdown
        options={this.options}
        selected={this.props.duration}
        onChange={this.handleChange}
        variant="outline2"
      />
    )
  }
}
