import { connect } from 'react-redux'
import ConversationChat from './ConversationChat'

function mapStateToProps(state) {
  const {
    entities: messages,
    isFetching,
    isChatScrollFetching,
    hasMoreMessages
  } = state.messages.single.conversationMessages
  const { role } = state.messages.list.messages

  const currentUserName = `${state.currentUser.firstName} ${state.currentUser.lastName}`

  return {
    role,
    messages,
    isFetching,
    currentUserName,
    isChatScrollFetching,
    hasMoreMessages
  }
}
export default connect(mapStateToProps)(ConversationChat)
