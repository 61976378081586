import { PIXELS_PER_MINUTE } from "~brokerage/constants/timeline";
import memoize from "lodash/memoize";
import moment from "moment";
import { callApi } from "~brokerage/middlewares/api";
import * as moment_tz from "moment-timezone";
import { MINUTES_DISPLAY_THRESHOLD, MINUTES_PER_HOUR } from "~brokerage/constants/showings";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

// const MILLISECONDS_IN_SECOND = 1e3
const MILLISECONDS_IN_MINUTE = 6e4;
const MILLISECONDS_IN_HOUR = 36e5;
const MILLISECONDS_IN_DAY = 864e5;
const MILLISECONDS_IN_WEEK = 6048e5;

let defaultTimezone;

const _getUnit = diff => {
  if (diff < MILLISECONDS_IN_MINUTE) return "s";
  if (diff < MILLISECONDS_IN_HOUR) return "m";
  if (diff < MILLISECONDS_IN_DAY) return "h";
  if (diff < MILLISECONDS_IN_WEEK) return "d";
};

export const formatTime = (...args) => {
  let hour, minute;
  if (args.length === 2) {
    hour = Number(args[0]);
    minute = Number(args[1]);
  } else {
    const datetime = new Date(args[0]);
    hour = datetime.getHours();
    minute = datetime.getMinutes();
  }

  const period = hour >= 12 ? "PM" : "AM";
  hour = hour === 0 ? 12 : hour;
  hour = hour > 12 ? hour % 12 : hour;
  minute = `${minute < 10 ? "0" : ""}${minute}`;

  return `${hour}:${minute} ${period}`;
};

export const timezone = timezone => {
  if (timezone) defaultTimezone = timezone;

  return defaultTimezone;
};

export const initialTimeForNewShowing = () => {
  let initialTime = timeToRemoteWithTimeZone();
  initialTime
    .hours(initialTime.hours() + 1)
    .minute(0)
    .second(0);
  if (initialTime.hours() < 10) {
    initialTime.hours(10);
  }
  if (initialTime.hours() > 22) {
    initialTime.add(1, "days").hours(10);
  }
  return initialTime;
};

export const minutesToDatetime = _minutes => {
  const hours = Math.floor(_minutes / 60);
  const minutes = _minutes % 60;

  return timeToRemoteWithTimeZone().hours(hours).minutes(minutes);
};

export const pixelsToMinutes = pixels => {
  return pixels / PIXELS_PER_MINUTE;
};

export const pixelsToDatetime = pixels => {
  return minutesToDatetime(pixelsToMinutes(pixels));
};

export const datetimeToMinutes = _datetime => {
  const datetime = timeToRemoteWithTimeZone(_datetime);
  return datetime.hours() * 60 + datetime.minutes();
};

export const datetimeToPixels = datetime => {
  return minutesToPixels(datetimeToMinutes(datetime.toString()));
};

export const minutesToPixels = minutes => {
  return minutes * PIXELS_PER_MINUTE;
};

export const timeAgo = (_datetime, suffix = "") => {
  const datetime = new Date(_datetime);
  const diff = new Date() - datetime;
  let unit = _getUnit(diff);

  if (unit) {
    return duration(diff)[unit] + unit + suffix;
  } else {
    return moment(datetime).format("MMM D, h:mma");
  }
};

export const daysAgo = (_datetime, suffix = "") => {
  const datetime = new Date(_datetime);
  const diff = new Date() - datetime;
  let unit = _getUnit(diff);

  if (unit) {
    return duration(diff)[unit] + unit + suffix;
  } else {
    return `${months[datetime.getMonth()]} ${datetime.getDate()}`;
  }
};

export const duration = timestamp => {
  const s = Math.floor(timestamp / 1000);
  const m = Math.floor(s / 60);
  const h = Math.floor(m / 60);
  const d = Math.floor(h / 24);

  return { s, m, h, d };
};

export const startOfDay = _datetime => {
  const datetime = new Date(_datetime || Date.now());
  datetime.setHours(0, 0, 0, 0);
  return datetime;
};

export const startOfMonth = _datetime => {
  const datetime = new Date(_datetime || Date.now());
  datetime.setDate(0);
  return startOfDay(datetime);
};

export const datetimeToObject = datetime => {
  return {
    hour: datetime.format("h"),
    minute: datetime.format("mm"),
    period: datetime.format("A")
  };
};

export const objectToDatetime = obj => {
  const { hour, minute, period } = obj;
  const datetime = timeToRemoteWithTimeZone().startOf("day");
  let convertedHours = hour;
  if (period.toUpperCase() == "PM") {
    convertedHours = hour < 12 ? Number(hour) + 12 : hour;
  } else {
    convertedHours = hour == 12 ? 0 : hour;
  }
  return datetime.hours(convertedHours).minutes(minute);
};

export const addMinutes = (_time, duration) => {
  let time = new Date(_time);
  time.setMinutes(time.getMinutes() + duration);
  return time;
};

export const checkTimeRangesForConflicts = (aStart, aEnd, bStart, bEnd) => {
  return (
    (aStart < bEnd && aEnd > bStart) ||
    (aStart < bStart && aEnd > bStart) ||
    (aStart > bStart && aEnd < bEnd) ||
    (bStart > aStart && bEnd < aEnd)
  );
};

export const localOffset = memoize(() => {
  const date = new Date();
  return date.getTimezoneOffset();
});

export const timeToRemoteWithTimeZone = _datetime => {
  let moment_datetime = moment_tz(_datetime || moment_tz())
    .second(0)
    .millisecond(0);

  if (timezone()) {
    return moment_datetime.tz(timezone());
  }
  return moment_datetime;
};

export const inUsersTimezone = date => {
  return moment_tz.tz(date, timezone());
};

export const roundTime = (dateTime, type, offset) => {
  const originalValue = dateTime[type]();
  const roundedValue =
    originalValue % offset === 0
      ? originalValue
      : Math.ceil((originalValue + 1) / offset) * offset;
  return dateTime[type](roundedValue);
};

export const inLocalTimeZoneWithoutConversion = _datetime => {
  const datetime = timeToRemoteWithTimeZone(_datetime);
  return new Date().setHours(
    datetime.hours(),
    datetime.minutes(),
    datetime.seconds()
  );
};

const timeList = () => {
  const result = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 59; minute += 30) {
      result.push(formatTime(hour, minute));
    }
  }
  return result;
};

export const dayInHalfHours = timeList();

export const minutesToShowingDuration = duration => {
  if (duration > MINUTES_DISPLAY_THRESHOLD){
    return `${duration/MINUTES_PER_HOUR} hours`;
  } else {
    return `${duration} minutes`;
  }
};
