import * as types from '~brokerage/constants/actionTypes'
import api from './api'

export function fetchUnreadNotificationsCount() {
  return api.myAccount.fetchUnreadNotificationsCount([
    types.MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_REQUEST,
    types.MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
    types.MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_FAILURE
  ])
}

export function fetchPreferences() {
  return api.myAccount.fetchPreferences([
    types.MY_ACCOUNT_PREFERENCES_REQUEST,
    types.MY_ACCOUNT_PREFERENCES_SUCCESS,
    types.MY_ACCOUNT_PREFERENCES_FAILURE
  ])
}

export function setInitialPreferences(preferences) {
  return {
    type: types.MY_ACCOUNT_SET_INITIAL_PREFERENCES,
    preferences
  }
}

export function fetchPreferencesIfNeeded() {
  return (dispatch, getState) => {
    const { isFetching, fetched } = getState().myAccount.preferences

    if (!(isFetching || fetched)) {
      dispatch(fetchPreferences())
    }
  }
}

export function hideWelcomeScreen() {
  return api.myAccount.hideWelcomeScreen([
    types.MY_ACCOUNT_HIDE_WELCOME_SCREEN_REQUEST,
    types.MY_ACCOUNT_HIDE_WELCOME_SCREEN_SUCCESS,
    types.MY_ACCOUNT_HIDE_WELCOME_SCREEN_FAILURE
  ])
}

export function updatePreferences(preferences) {

  return api.myAccount.updatePreferences(
    preferences,
    types.MY_ACCOUNT_UPDATE_PREFERENCES
  )
}

export function updatePremiumBetaSubscription(enabled) {
  return api.myAccount.updatePremiumBetaSubscription(
    [
      types.MY_ACCOUNT_UPDATE_PREFERENCES_REQUEST,
      types.MY_ACCOUNT_UPDATE_PREFERENCES_SUCCESS,
      types.MY_ACCOUNT_UPDATE_PREFERENCES_FAILURE
    ],
    enabled
  )
}

export function updateUnreadNotifications(count) {
  return api.myAccount.updateUnreadNotifications(
    count,
    types.UPDATE_UNREAD_NOTIFICATIONS
  )
}
