import React from "react";
import css from "./Footer.sass";
import values from "lodash/values";
import without from "lodash/without";

const Footer = ({ submitFeedback, selectedAnswers }) => {
  const answers = values(selectedAnswers);
  const completedLength = without(answers, null, "").length;
  const totalLength = answers.length;
  const buttonText =
    completedLength === 0 || completedLength === totalLength
      ? "Submit"
      : "Skip Remaining";

  return (
    <div className={css.footer}>
      <div className={css.footerText}>
        {completedLength > 0 && (
          <>
            <b>{completedLength}</b> of <b>{totalLength}</b> Answered
          </>
        )}
      </div>

      <button
        className={completedLength > 0 ? css.submitButton : css.disabledButton}
        onClick={submitFeedback}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Footer;
