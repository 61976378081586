import css from './Popup.sass'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
import Icon from '~brokerage/components/shared/Icon'
import LinkOrText from '~brokerage/components/shared/LinkOrText'

export default class TooltipBody extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    direction: PropTypes.string,
    baseTop: PropTypes.number,
    baseBottom: PropTypes.number,
    baseHorizontalCenter: PropTypes.number,
    text: PropTypes.string,
    additional: PropTypes.array,
    footer: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  }

  tooltipContainer = document.createElement('div')
  
  componentDidMount() {
    const { baseTop, baseBottom, baseHorizontalCenter, direction } = this.props

    document.body.appendChild(this.tooltipContainer)
    this.el.style.left = `${baseHorizontalCenter - Math.floor(this.el.offsetWidth / 2)}px`

    if (direction === 'up') {
      this.el.style.top = `${baseTop - this.el.offsetHeight}px`
    } else {
      this.el.style.top = `${baseBottom}px`
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.tooltipContainer)
  }

  renderBody() {
    const { variant = 'base', text, additional, onMouseEnter, onMouseLeave, footer, direction } = this.props

    return (
      <div
        className={composeClassName(css, variant, direction && `direction${capitalize(direction)}`)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={el => this.el = el}
      >
        <div className={css.body}>
          {text}
          {additional &&
            <ul className={css.ul}>
              {additional.map((entry, entryIndex) =>
                <li className={css.li} key={entryIndex}>
                  <LinkOrText href={entry.url} variant="textWhite">
                    {entry.icon && <i className={css.liIcon}><Icon name={entry.icon}/></i>}
                    {entry.text}
                  </LinkOrText>
                </li>
              )}
            </ul>
          }
          {footer &&
            <div className={css.footer}>{footer}</div>
          }
        </div>
      </div>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderBody(), this.tooltipContainer)
  }
}
