import css from "./ModalMaxSelected.sass";
import React from "react";
import PropTypes from "prop-types";
import Button from "~brokerage/components/shared/Button";
import { withRouter } from 'react-router-dom'

class ModalMaxSelected extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    confirmText: PropTypes.string,
    confirmVariant: PropTypes.string,
    onConfirm: PropTypes.func,
    cancelText: PropTypes.string,
    cancelVariant: PropTypes.string
  };

  close = () => {
    const { modal, ...query } = this.props.location.query; // eslint-disable-line no-unused-vars
    this.props.history.push({
      pathname: this.props.location.pathname,
      query: query
    });
  };
  render() {
    return (
      <div className={css.base}>
        <p className={css.heading}>Maximum Selections</p>
        <p className={css.p}>You've selected the maximum number of listings.</p>

        <div className={css.buttons}>
          <div className={css.btn}>
            <Button
              onClick={this.close}
              variant={"primary"}
              modifier="block textAlignCenter"
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModalMaxSelected);
