import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactList from "react-list";

import css from "./NotificationsList.sass";
import NotificationItem from "./NotificationItem";
import Loader from "~brokerage/components/shared/Loader";
import { fetchNotifications } from "~brokerage/actions/notifications";
import ListItemLoader from "~brokerage/components/shared/ListItemLoader";

const NotificationsList = ({
  notifications,
  dispatch,
  isFetching,
  currentPage,
  totalPages
}) => {
  const loadUserNotifications = () => {
    dispatch(fetchNotifications());
  };

  useEffect(loadUserNotifications, []);

  const renderNotifList = () => {
    return (
      <ReactList
        itemsRenderer={renderTable}
        itemRenderer={renderNotif}
        length={notifications.length}
        type="simple"
      />
    );
  };

  const renderTable = (items, ref) => (
    <table>
      <tbody ref={ref}>{items}</tbody>
    </table>
  );

  const needLoadMoreNotifs = notifIndex => {
    const hasMoreNotifs = currentPage < totalPages;
    const isItemLastOfLoaded = notifications.length - notifIndex === 1;

    return !isFetching && hasMoreNotifs && isItemLastOfLoaded;
  };

  const loadMoreNotifs = () => {
    dispatch(fetchNotifications(currentPage + 1));
  };

  const renderNotif = (index, key) => (
    <ListItemLoader
      key={key}
      needLoadMore={needLoadMoreNotifs(index)}
      loadMore={loadMoreNotifs}
      uniqueId={`notificationitem-${index}`}
    >
      <NotificationItem notification={notifications[index]} />
    </ListItemLoader>
  );

  if (isFetching && !notifications.length) {
    return <Loader active />;
  }

  return (
    <div className={css.notificationsList}>
      {notifications.length > 0 ? (
        renderNotifList()
      ) : (
        <p>No recent notications.</p>
      )}
    </div>
  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.any,
  isFetching: PropTypes.bool
};

function mapStateToProps(state) {
  const {
    entities: notifications,
    page: currentPage,
    totalPages,
    isFetching
  } = state.notifications.list;

  return {
    notifications,
    currentPage,
    totalPages,
    isFetching
  };
}

export default connect(mapStateToProps)(NotificationsList);
