import React, { createContext, useState } from "react";

export const ShowingContext = createContext();

const ShowingProvider = ({ children }) => {
  const [times, setTimes] = useState([]);
  const [showingDate, setShowingDate] = useState();
  const [restrictions, setRestrictions] = useState({});

  return (
    <ShowingContext.Provider
      value={{
        times,
        setTimes,
        showingDate,
        setShowingDate,
        restrictions,
        setRestrictions
      }}
    >
      {children}
    </ShowingContext.Provider>
  );
};

export default ShowingProvider;
