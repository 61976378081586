// Used to detect the click on outside the ref element

import { useEffect } from "react";

const useClickOutside = (ref, callback) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Escape") {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref, callback]);
};

export default useClickOutside;
