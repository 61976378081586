import React, { useContext } from "react";
import { connect } from "react-redux";
import css from "./SettingsSideBar.sass";
import SettingsNavCard from "~brokerage/components/settings/SettingsNavCard";
import SettingsNavLink from "~brokerage/components/settings/SettingsNavLink";
import { SHOWINGS_TOS_URL } from "~brokerage/constants/links";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ArrowDropDownFillIcon from "remixicon-react/ArrowDropDownFillIcon";
import FileShield2LineIcon from "remixicon-react/FileShield2LineIcon";
import AccountCircleLineIcon from "remixicon-react/AccountCircleLineIcon";
import Settings3LineIcon from "remixicon-react/Settings3LineIcon";
import SurveyLineIcon from "remixicon-react/SurveyLineIcon";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";
import { Link } from "react-router-dom";

const SettingsSideBar = (props) => {
  const { pageState } = useContext(SettingsContext);
  const { currentUser: { isImpersonating } } = props;
  return (
    <div className={css.sidebar}>
      <div className={css.sidebarHeading}>
        <div className={css.sidebarHeadingText}>Settings</div>
        <div className={css.dropDownMenu}>
          <Menu
            direction="bottom"
            align="end"
            position="auto"
            viewScroll="auto"
            className={css.menu}
            menuButton={
              <div className={css.button}>
                {pageState.activePane}
                <ArrowDropDownFillIcon size={15} />
              </div>
            }
          >
            <MenuItem className={css.menuItem}>
              <Link to={"/settings"} className={css.menuItemLink}>
                Profile
              </Link>
            </MenuItem>
            <MenuItem className={css.menuItem}>
              <Link to={"/settings/system"} className={css.menuItemLink}>
                System
              </Link>
            </MenuItem>
            <MenuItem className={css.menuItem}>
              <Link to={"/settings/feedback"} className={css.menuItemLink}>
                Feedback
              </Link>
            </MenuItem>
            <MenuItem className={css.menuItem} href={SHOWINGS_TOS_URL}>
              Privacy and Terms
            </MenuItem>
            <MenuItem className={css.menuItem} href="/users/sign_out">
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>

      <SettingsNavCard
        active={pageState.activePane == "profile"}
        Icon={AccountCircleLineIcon}
        title="Profile"
        to="/settings"
      />

      <SettingsNavCard
        active={pageState.activePane == "system"}
        Icon={Settings3LineIcon}
        title="System"
        to="/settings/system"
      />

      <SettingsNavCard
        active={pageState.activePane == "feedback"}
        Icon={SurveyLineIcon}
        title="Feedback Surveys"
        to="/settings/feedback"
      />

      <SettingsNavLink
        Icon={FileShield2LineIcon}
        title="Privacy and Terms"
        to={SHOWINGS_TOS_URL}
      />

      {!isImpersonating && (
        <a href="/users/sign_out" className={css.logoutBtn}>
          Logout
        </a>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { currentUser } = state;
  return { currentUser };
}

export default connect(mapStateToProps)(SettingsSideBar);
