import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Loader from "~brokerage/components/shared/Loader";
import { fetchUserSurveys, fetchUserSurvey } from "~brokerage/actions/surveys";
import SurveyListItem from "./SurveyListItem";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import css from "./index.sass";
import EditSurveyModal from "~brokerage/components/shared/EditSurveyModal";

const ManageSurvey = ({ dispatch, isFetching, surveys }) => {
  const [editSurvey, setEditSurvey] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const handleDeleteClick = id => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "delete_survey", survey_id: id }
    });
  };

  useEffect(() => {
    dispatch(fetchUserSurveys());
  }, []);
  return (
    <>
      <div className={css.titleRow}>
        <div>
          <Button
            variant="link"
            onClick={() => history.replace("/settings/feedback")}
          >
            <Icon name="arrowLeft" modifier="offset5 valignTextDefault" />
            Back
          </Button>
          <h3 className={css.title}>Manage Surveys</h3>
        </div>
        <Button variant="outlineBlue" onClick={() => setEditSurvey("new")}>
          <Icon name="plus" modifier="offset5 valignTextDefault" />
          New Survey
        </Button>
      </div>
      {surveys.length ? (
        surveys.map(survey => (
          <SurveyListItem
            survey={survey}
            onEditClick={id => {
              dispatch(fetchUserSurvey(id));
              setEditSurvey(id);
            }}
            onDeleteClick={handleDeleteClick}
            key={`survey-${survey.id}`}
          />
        ))
      ) : (
        <p>No surveys found</p>
      )}
      <Loader active={isFetching} />
      {!!editSurvey && (
        <EditSurveyModal
          surveyId={editSurvey}
          closeModal={() => setEditSurvey(null)}
          onUpdate={() => dispatch(fetchUserSurveys())}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { entities: surveys, isFetching } = state.surveys.list;

  return {
    surveys,
    isFetching
  };
};

export default connect(mapStateToProps)(ManageSurvey);
