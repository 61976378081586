import css from './Filter.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '~brokerage/components/shared/Icon'

export default class DropdownFilter extends React.PureComponent {
  static propTypes = {
    variant: PropTypes.string,
    loupe: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  }

  componentDidMount() {
    this.$input.focus()
  }

  handleFilterKeyUp = (event) => {
    this.props.onChange(event.currentTarget.value)
  }

  render() {
    return (
      <div className={classNames(css[this.props.variant || 'base'], this.props.loupe && css.hasLoupe)}>
        {(() => {
          if (this.props.loupe) {
            return <label className={css.label}><Icon name="search"/></label>
          }
        })()}
        <input
          className={css.input}
          type="text"
          placeholder={this.props.placeholder}
          onKeyUp={this.handleFilterKeyUp}
          ref={(el) => this.$input = $(el)}
        />
      </div>
    )
  }
}
