import { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormBinding } from './Form'
class ErrorContainer extends Component {
  static propTypes = {
    children: PropTypes.element
  }

  render () {
    return this.props.children
  }
}
export default withFormBinding(ErrorContainer, 'errorContainers')
