import css from "./PersonAutocomplete.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import Autocomplete from "~brokerage/components/shared/Autocomplete";

export default class PersonAutocomplete extends React.PureComponent {
  static propTypes = {
    people: PropTypes.array,
    onChange: PropTypes.func
  };

  state = {
    currentValue: "",
    options: this.options()
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.people !== nextProps.people) {
      this.setState({
        options: this.options(nextProps.people)
      });
    }
  }

  handleChange = event => {
    const value = event.target.value;

    this.setState({
      currentValue: value
    });

    this.props.onChange(value);
  };

  options(people) {
    let result = [];

    if (people && people.length) {
      result = people.map(seller => ({
        value: seller.uuid,
        label: `${seller.firstName} ${seller.lastName}`
      }));
    }

    result.push({ label: "Invite New User" });

    return result;
  }

  render() {
    const { currentValue, options } = this.state;

    return (
      <div className={css.base}>
        <Autocomplete
          value={currentValue}
          options={options}
          onChange={this.handleChange}
          placeholder="Enter Seller/Tenant Name"
          variant="person"
          block
          {...omit(this.props, Object.keys(PersonAutocomplete.propTypes))}
        />
      </div>
    );
  }
}
