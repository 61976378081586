import {
  BROKERAGE_SETTINGS_ASSISTANTS_REQUEST,
  BROKERAGE_SETTINGS_ASSISTANTS_SUCCESS,
  BROKERAGE_SETTINGS_ASSISTANTS_FAILURE
} from '~brokerage/constants/actionTypes'
import _keyBy from 'lodash/keyBy'

const defaultState = {
  isFetching: false,
  assistants: {}
}

export default function assistantsReducer(state = defaultState, action) {
  switch (action.type) {
    case BROKERAGE_SETTINGS_ASSISTANTS_REQUEST:
      return { ...state, isFetching: true }
    case BROKERAGE_SETTINGS_ASSISTANTS_SUCCESS:
      return {
        ...state,
        assistants: _keyBy(action.data.assistants, 'id'),
        isFetching: false
      }
    case BROKERAGE_SETTINGS_ASSISTANTS_FAILURE:
      return { ...state, errors: action.errors }
    default:
      return state
  }
}
