import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import omit from "lodash/omit";

import Button from "~brokerage/components/shared/Button";
import { steps } from "../constants";
import css from "../index.sass";
import { Footer } from "~brokerage/components/modals/BaseModal";

export default function Actions({ setCurrentStep, stopPlace }) {
  const history = useHistory();
  const location = useLocation();

  const closeModal = () => {
    history.replace({
      ...location,
      query: omit(location.query, ["modal", "routeId"])
    });
  };

  return (
    <Footer>
      <div className={css.buttonContainer}>
        <Button variant="outline" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={!stopPlace}
          variant="primary"
          onClick={() => {
            setCurrentStep(steps.ORDER_STEP);
          }}
        >
          Next
        </Button>
      </div>
    </Footer>
  );
}
