import React from "react";
import { isEmpty } from "lodash";
import FilePreview from "~brokerage/components/shared/FileUpload/FilePreview";
import css from "./index.sass";

const Attachments = ({ attachments }) => {
  if (isEmpty(attachments)) return null;

  const files = attachments.map(attachment => ({
    file: { ...attachment }
  }));

  return (
    <FilePreview
      linkify
      files={files}
      imagesWrapperClassName={css.chatAttachmentsWrapper}
      imageWrapperClassName={css.chatAttachmentWrapper}
    />
  );
};

export default Attachments;
