import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchShowings(params) {
  return api.showings.fetchShowings(params, [
    types.SHOWINGS_REQUEST,
    types.SHOWINGS_SUCCESS,
    types.SHOWINGS_FAILURE
  ]);
}

export function showingChangedByMessage(data) {
  return dispatch => {
    dispatch({
      type: types.SHOWINGS_SHOWING_CHANGED_BY_MESSAGE,
      showing: data.showingData
    });
    dispatch({
      type: types.SHOWING_CHANGED_BY_MESSAGE,
      showing: data.showingDataForShow
    });
  };
}

export function clearAvailableAgents() {
  return {
    type: types.SHOWING_CLEAR_AVAILABLE_AGENTS
  };
}

export function markShowingAsRead(id) {
  return {
    type: types.MARK_SHOWING_AS_READ,
    id
  };
}

export function updateTeamInstructions(showingId, data) {
  return api.showings.updateTeamInstructions(showingId, data, [
    types.UPDATE_SHOWING_TEAM_INSTRUCTIONS_REQUEST,
    types.UPDATE_SHOWING_TEAM_INSTRUCTIONS_SUCCESS,
    types.UPDATE_SHOWING_TEAM_INSTRUCTIONS_FAILURE
  ]);
}

export function fetchAvailableRoles() {
  return api.showing.fetchAvailableRoles([
    types.AVAILABLE_ROLES_REQUEST,
    types.AVAILABLE_ROLES_SUCCESS,
    types.AVAILABLE_ROLES_FAILURE
  ]);
}

export function fetchShowingsCounts(filter) {
  return api.showing.fetchShowingsCounts(filter, [
    types.SHOWINGS_COUNTS_REQUEST,
    types.SHOWINGS_COUNTS_SUCCESS,
    types.SHOWINGS_COUNTS_FAILURE
  ]);
}

export function scrollShowings(filter) {
  return api.showings.fetchShowings(filter, [
    types.SHOWINGS_PAGE_SCROLLED,
    types.SHOWINGS_PAGE_SCROLLED_SUCCESS,
    types.SHOWINGS_FAILURE
  ]);
}

export function scrollShowingChatThread(id, filter = {}) {
  return api.showings.scrollShowingChatThread(id, filter, [
    types.SCROLL_SHOWING_CHAT_THREAD_REQUEST,
    types.SCROLL_SHOWING_CHAT_THREAD_SUCCESS,
    types.SCROLL_SHOWING_CHAT_THREAD_FAILURE
  ]);
}

export function fetchShowing(id) {
  return api.showings.fetchShowing(id, [
    types.SHOWING_REQUEST,
    types.SHOWING_SUCCESS,
    types.SHOWING_FAILURE
  ]);
}

export function changeShowing(id, changes) {
  return {
    changes: changes,
    ...api.showings.changeShowing(id, changes, [
      types.SHOWING_CHANGE_REQUEST,
      types.SHOWING_CHANGE_SUCCESS,
      types.SHOWING_CHANGE_FAILURE
    ])
  };
}

export function fetchShowingMessages(id, role) {
  return api.showings.fetchShowingMessages(id, role, [
    types.SHOWING_MESSAGES_REQUEST,
    types.SHOWING_MESSAGES_SUCCESS,
    types.SHOWING_MESSAGES_FAILURE
  ]);
}

export function addMessage(showing_id, message, attachments = {}, role) {
  return api.showings.createMessage(showing_id, message, attachments, role, [
    types.SHOWING_MESSAGE_REQUEST,
    types.SHOWING_MESSAGE_SUCCESS,
    types.SHOWING_MESSAGE_FAILURE
  ]);
}

export function addNoteMessage(showing_id, message, attachments = {}, role) {
  return api.showings.createNoteMessage(
    showing_id,
    message,
    attachments,
    role,
    [
      types.SHOWING_NOTE_MESSAGE_REQUEST,
      types.SHOWING_NOTE_MESSAGE_SUCCESS,
      types.SHOWING_NOTE_MESSAGE_FAILURE
    ]
  );
}

export function logCall(showing_id, message) {
  return api.showings.logCall(showing_id, message, [
    types.SHOWING_NOTE_MESSAGE_REQUEST,
    types.SHOWING_NOTE_MESSAGE_SUCCESS,
    types.SHOWING_NOTE_MESSAGE_FAILURE
  ]);
}

export function changeShowingListFilter(changes) {
  return {
    type: types.SHOWINGS_FILTER_CHANGE,
    changes
  };
}

export function fetchPubnubData(id, role) {
  return api.showings.fetchPubnubData(id, role, [
    types.FETCH_PUBNUB_DATA_REQUEST,
    types.FETCH_PUBNUB_DATA_SUCCESS,
    types.FETCH_PUBNUB_DATA_FAILURE
  ]);
}

export function addMessageFromPubnub(message) {
  return {
    type: types.SHOWING_MESSAGES_ADD,
    message
  };
}

export function updateShowingMessageReadReceipt(message) {
  return {
    type: types.UPDATE_SHOWING_MESSAGE_HAS_READ,
    message
  };
}

export function updateShowingType(newType) {
  return {
    type: types.SHOWING_UPDATE_SHOWING_TYPE,
    newType
  };
}

export function fetchAgents() {
  return api.showings.fetchAgents([
    types.FETCH_FILTER_AGENTS_REQUEST,
    types.FETCH_FILTER_AGENTS_SUCCESS,
    types.FETCH_FILTER_AGENTS_FAILURE
  ]);
}

export function fetchShowingListingTeamMembers(showingId) {
  return api.showings.fetchShowingListingTeamMembers(showingId, [
    types.SHOWING_LISTING_TEAM_MEMBERS_REQUEST,
    types.SHOWING_LISTING_TEAM_MEMBERS_SUCCESS,
    types.SHOWING_LISTING_TEAM_MEMBERS_FAILURE
  ]);
}

export function fetchShowingBuyerTeamMembers(showingId) {
  return api.showings.fetchShowingBuyerTeamMembers(showingId, [
    types.SHOWING_BUYER_TEAM_MEMBERS_REQUEST,
    types.SHOWING_BUYER_TEAM_MEMBERS_SUCCESS,
    types.SHOWING_BUYER_TEAM_MEMBERS_FAILURE
  ]);
}

export function fetchShowingSellerTenantInvolvement(showingId) {
  return api.showings.fetchSellerTenantInvolvement(showingId, [
    types.SHOWING_SELLER_TENANT_INVOLVEMENT_REQUEST,
    types.SHOWING_SELLER_TENANT_INVOLVEMENT_SUCCESS,
    types.SHOWING_SELLER_TENANT_INVOLVEMENT_FAILURE
  ]);
}

export function fetchAvailableAgents(type, id, name) {
  return api.messaging.fetchAvailableParticipants(type, id, name, [
    types.SHOWING_AVAILABLE_AGENTS_REQUEST,
    types.SHOWING_AVAILABLE_AGENTS_SUCCESS,
    types.SHOWING_AVAILABLE_AGENTS_FAILURE
  ]);
}

export function addAgentToMyTeam(conversationId, teamType, agentId) {
  return api.messaging.addAgentToShowingTeam(
    conversationId,
    teamType,
    agentId,
    [
      types.SHOWING_ADD_AGENT_TO_MY_TEAM_REQUEST,
      types.SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS,
      types.SHOWING_ADD_AGENT_TO_MY_TEAM_FAILURE
    ]
  );
}

export function removeAgentFromMyTeam(conversationId, teamType, agentId) {
  return api.messaging.removeAgentFromShowingTeam(
    conversationId,
    teamType,
    agentId,
    [
      types.SHOWING_REMOVE_AGENT_FROM_MY_TEAM_REQUEST,
      types.SHOWING_REMOVE_AGENT_FROM_MY_TEAM_SUCCESS,
      types.SHOWING_REMOVE_AGENT_FROM_MY_TEAM_FAILURE
    ]
  );
}

export function updateShowingInstructions(showingId, role, payload) {
  return api.showings.updateShowingInstructions(showingId, role, payload, [
    types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_REQUEST,
    types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_SUCCESS,
    types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_FAILURE
  ]);
}

export function declineShowing(showingId, payload) {
  return api.showing.declineShowing(showingId, payload, [
    types.SHOWINGS_DECLINE_SHOWING_REQUEST,
    types.SHOWINGS_DECLINE_SHOWING_SUCCESS,
    types.SHOWINGS_DECLINE_SHOWING_FAILURE
  ]);
}

export function approveShowing(showingId, payload) {
  return api.showing.approveShowing(showingId, payload, [
    types.SHOWINGS_APPROVE_SHOWING_REQUEST,
    types.SHOWINGS_APPROVE_SHOWING_SUCCESS,
    types.SHOWINGS_APPROVE_SHOWING_FAILURE
  ]);
}

export function cancelShowing(showingId) {
  return api.showing.cancelShowing(showingId, [
    types.SHOWING_CANCEL_SHOWING_REQUEST,
    types.SHOWING_CANCEL_SHOWING_SUCCESS,
    types.SHOWING_CANCEL_SHOWING_FAILURE
  ]);
}

export function unhideFeedback(showingId) {
  return api.showing.unhideFeedback(showingId, [
    types.SHOWING_UNHIDE_FEEDBACK_REQUEST,
    types.SHOWING_UNHIDE_FEEDBACK_SUCCESS,
    types.SHOWING_UNHIDE_FEEDBACK_FAILURE
  ]);
}

export function hideFeedback(showingId) {
  return api.showing.hideFeedback(showingId, [
    types.SHOWING_HIDE_FEEDBACK_REQUEST,
    types.SHOWING_HIDE_FEEDBACK_SUCCESS,
    types.SHOWING_HIDE_FEEDBACK_FAILURE
  ]);
}

export function sendAnswer(showingId, questionId, answerIndex) {
  return api.showing.createFeedbackAnswer(showingId, questionId, answerIndex, [
    types.SHOWING_FEEDBACK_ANSWER_REQUEST,
    types.SHOWING_FEEDBACK_ANSWER_SUCCESS,
    types.SHOWING_FEEDBACK_ANSWER_FAILURE
  ]);
}

export function fetchFeedbackQuestions(showingId) {
  return api.showing.fetchFeedbackQuestions(showingId, [
    types.SHOWING_FEEDBACK_QUESTIONS_REQUEST,
    types.SHOWING_FEEDBACK_QUESTIONS_SUCCESS,
    types.SHOWING_FEEDBACK_QUESTIONS_FAILURE
  ]);
}
