import { CALL_API } from "~brokerage/middlewares/api";

export function fetchAvailableMembers(params, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `my_organization/members`,
      params
    }
  };
}

export function fetchListings(filter, page, query, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "my_organization/listings",
      params: { filter, page, query }
    }
  };
}

export function propertySearch(filter, page, query, enabled_only, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: "my_organization/listings/search",
      params: { filter, page, query, enabled_only }
    }
  };
}
