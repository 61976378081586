import React, { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { isEmpty } from "lodash";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Loader from "~brokerage/components/shared/Loader";
import Spinner from "~brokerage/components/shared/Spinner";
import { callApi } from "brokerage/middlewares/api";
import EmptyMessage from "~brokerage/components/shared/EmptyMessage";
import File from "./File";
import { DEFAULT_PAGE, INFINITE_SCROLL_DELAY } from "./constants";
import css from "./index.sass";

const List = ({ showingId, closeList, isShowingChat }) => {
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  const fetchMessagesAttachments = async page => {
    try {
      const response = await callApi(
        `showings/${showingId}/messages_attachments?isShowing=${!!isShowingChat}&page=${page}`
      );
      const {
        data: { attachments: messagesAttachments, hasNextPage }
      } = response;
      setAttachments(prevAttachments => [
        ...prevAttachments,
        ...messagesAttachments
      ]);

      setHasNextPage(hasNextPage);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching message attachments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => fetchMessagesAttachments(currentPage + 1);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    delayInMs: INFINITE_SCROLL_DELAY
  });

  useEffect(() => {
    fetchMessagesAttachments(currentPage);
  }, []);

  return (
    <div className={css.attachmentList}>
      <div className={css.header}>
        <div className={css.headerText}>Attachments</div>
        <CloseLineIcon className={css.closeIcon} onClick={closeList} />
      </div>

      <div className={css.files} ref={rootRef}>
        <Loader modifier="z99" active={isLoading} />

        {isEmpty(attachments) && !isLoading ? (
          <EmptyMessage>No Attachments</EmptyMessage>
        ) : (
          attachments.map(attachment => {
            return <File file={attachment} />;
          })
        )}
        {hasNextPage && (
          <div className={css.hasMoreLoader} ref={sentryRef}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
