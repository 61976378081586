import React from "react";
import { hashHistory } from "~brokerage/app/helpers/history";
import configureStore from "../brokerage/app/store/configureStore";
import Root from "../brokerage/app/containers/Root";

const store = configureStore()
const history = hashHistory

const Home = props => {
  return (
    <Root store={store} history={history} invalidLogin={props.invalidLogin}/>
  );
}

export default Home;
