import React, { useContext, useEffect, useState } from "react";
import SettingsSideBar from "~brokerage/components/settings/SettingsSideBar";
import SettingsPane from "~brokerage/components/settings/SettingsPane";
import SettingsContent from "~brokerage/components/settings/SettingsContent";
import { SettingsContext } from '~brokerage/app/pages/settings/context/SettingsContext';
import Loader from "~brokerage/components/shared/Loader";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

const Terms = (props) => {
  const { pageState, dispatchPageState } = useContext(SettingsContext);
  const [termsHtmlString, settermsHtmlString] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTerms = async function() {
    return fetch('/htmlFragments/terms.html')
    .then((response) => response.text())
    .then((html) => {
      const cleanedHtml = DOMPurify.sanitize(html , {USE_PROFILES: {html: true}});
      return cleanedHtml;
    }).catch(function (error) {
      toast.error(`Error loading terms: ${error}`);
      return false;
    });
  }

  useEffect(async function() {

    dispatchPageState({ type: "setActivePane", payload: "terms" });
    const termsHtml = await fetchTerms();

    if(termsHtml){
      settermsHtmlString({__html: termsHtml });
    }

    setIsLoading(false);
  },[])



  return (
    <SettingsPane>
      <SettingsSideBar />
      <SettingsContent>
        {
          isLoading ? <Loader active /> :
          <>{
            termsHtmlString && <div style={{ width: "775px", marginLeft: "1rem" }}>
              <div dangerouslySetInnerHTML={termsHtmlString} />
            </div>
          }</>
        }
      </SettingsContent>
    </SettingsPane>
  );
};

export default Terms;
