import css from "./index.sass";
import React from "react";
import Calendar from "~brokerage/components/shared/Calendar";

const SelectDate = ({ handleChange, times }) => {
  const activeShowing = times[0] || {};
  let { startTime } = activeShowing;

  if (!startTime) {
    return <></>;
  }

  return (
    <div className={css.dateSelect}>
      <Calendar name="date" selected={startTime} onChange={handleChange} />
    </div>
  );
};

export default SelectDate;
