import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import css from "./UserPicture.sass";

const UserPicture = ({ hasPhoto, photoUrl, userInitials }) => {

  const [imageUrlFailed, setImageUrlFailed] = useState(false);

  const setImageToFallBackSRC = () => {
    setImageUrlFailed(true);
  };

  const imageRef = useRef();

  return hasPhoto && photoUrl && imageUrlFailed != true ? (
    <img className={css.picture} src={photoUrl} alt="" ref={imageRef} onError={setImageToFallBackSRC} />
  ) : (
    <div className={css.picture}>
      <div className={css.placeholder}>{userInitials}</div>
    </div>
  );
}

UserPicture.propTypes = {
  hasPhoto: PropTypes.bool,
  photoUrl: PropTypes.string,
  userInitials: PropTypes.string
};

export default UserPicture;