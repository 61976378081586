import React from "react";
import css from "../index.sass";
import CheckboxBlank from "remixicon-react/CheckboxBlankLineIcon";
import Checkbox from "remixicon-react/CheckboxFillIcon";
import Radio from "remixicon-react/RadioButtonFillIcon";
import RadioBlank from "remixicon-react/CheckboxBlankCircleLineIcon";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";
import { unavailableMessages } from "~brokerage/constants/showings/noShowReasons";

const Property = ({
  listing,
  updateSelected,
  selected,
  variant,
  highlight,
  setHighlightedListing
}) => {
  const {
    id,
    mlsNumber,
    address,
    status,
    price,
    photoUrl,
    contingencyString,
    showingsEnabled: { status: showable }
  } = listing;
  const notShowableReason = !showable && unavailableMessages["default"];
  const isChecked = selected.findIndex(s => s.id === id) !== -1;
  const photoClassName = photoUrl ? "" : css.noPhoto;
  const checkbox = !showable ? (
    variant === "showing" ? (
      <RadioBlank className={css.iconDisabled} />
    ) : (
      <CheckboxBlank className={css.iconDisabled} />
    )
  ) : isChecked ? (
    variant === "showing" ? (
      <Radio className={css.icon} />
    ) : (
      <Checkbox className={css.icon} />
    )
  ) : variant === "showing" ? (
    <RadioBlank className={css.icon} />
  ) : (
    <CheckboxBlank className={css.icon} />
  );

  return (
    <div
      id={`listing_${mlsNumber}`}
      className={highlight ? `${css.property} ${css.pulse}` : css.property}
      onClick={() => showable && updateSelected(listing)}
    >
      <div className={css.select}>{checkbox}</div>
      <div className={css.imageContainer}>
        <div className={css.status}>{`${status}${contingencyString}`}</div>
        <PropertyThumbnail image={photoUrl} className={photoClassName} />
      </div>
      <div className={css.details}>
        <div className={css.mlsNum}>{mlsNumber}</div>
        <div className={css.price}>{formatPrice(price)}</div>
        <div className={css.address}>{address}</div>
        {!showable && (
          <div>
            <span className={css.notAvailable}>{notShowableReason}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Property;
