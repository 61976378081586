import css from './ModalUpgradeToPremium.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Header, Body } from '~brokerage/components/modals/BaseModal'
import Button from '~brokerage/components/shared/Button'
import Icon from '~brokerage/components/shared/Icon'
import { updatePremiumBetaSubscription } from '~brokerage/actions/myAccount'
import { withRouter } from 'react-router-dom'

class ModalUpgradeToPremium extends React.PureComponent {
  static propTypes = {
    showingInstructions: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string,
    showingId: PropTypes.string,
    role: PropTypes.string,
    onSave: PropTypes.func,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  joinBetaClicked = () => {
    this.props.dispatch(updatePremiumBetaSubscription(true));
    this.closeModal();
  };

  closeModal = () => {
    const { location, history } = this.props;
    if (location.query.back_path) {
      history.push(location.query.back_path);
    } else {
      history.push({
        pathname: location.pathname,
        query: { ...location.query, modal: void 0 }
      });
    }
  };

  render() {
    return (
      <Modal>
        <Header
          modifier="centered paddingVertical30 headingLarge"
          title="We invite you to join Agent Inbox Premium!"
          cancelPath={this.props.location.query.back_path}
        />
        <Body>
          <div className={css.cols}>
            <div className={css.col}>
              <h2 className={css.h2}>Core</h2>
              <div className={css.price}>
                <div className={css.amount}>$0</div>
                per user
                <br />
                per month
              </div>

              <ul className={css.ul}>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited Agent-to-Agent Messaging
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited Agent Showings
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited Instant Showing Replies
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited Routing Automation
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Listing Messages
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  MLS Agent Directory
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Desktop, Mobile, Tablet
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Private Notes
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Basic Feedback
                </li>
              </ul>

              <div className={css.colFooterBordered}>Current Plan</div>
            </div>

            <div className={css.col2}>
              <h2 className={css.h2}>Premium Products</h2>
              <div className={css.pricePlaceholder}>
                <p>Agents get early access to premium products.</p>
                <p>
                  It is free to use now, but will be a paid subscription in the
                  future.
                </p>
              </div>

              <h3 className={css.h3}>Showing Coordinator:</h3>
              <p className={css.pAfterH3}>Manage showings by a front desk</p>
              <ul className={css.ul}>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Showing coordinator role
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Office showing settings
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Call Logging & Showing Templates
                </li>
              </ul>

              <h3 className={css.h3}>Transaction Chat:</h3>
              <p className={css.pAfterH3}>Negotiate transactions as a team</p>
              <ul className={css.ul}>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited, private team transaction chat
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Co-listing agent compatible
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Listing team and notification settings
                </li>
              </ul>

              <h3 className={css.h3}>Group Chat:</h3>
              <p className={css.pAfterH3}>Group messaging inside brokerages</p>
              <ul className={css.ul}>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Unlimited brokerage group chat rooms
                </li>
                <li className={css.li}>
                  <div className={css.icon}>
                    <Icon name="checkThick" />
                  </div>
                  Centralized internal office communication
                </li>
              </ul>

              <div className={css.colFooter}>
                <Button
                  variant="primary"
                  modifier="block bigger noTopRadius"
                  onClick={this.joinBetaClicked}
                >
                  Join Premium Early Access
                </Button>
              </div>
            </div>
          </div>

          <div className={css.footer}>
            <p>No credit card is required until you upgrade.</p>
            <p>You will not be billed unless you choose to upgrade later.</p>
          </div>
        </Body>
      </Modal>
    );
  }
}
export default withRouter(connect()(ModalUpgradeToPremium));
