import { combineReducers } from "redux";
import he from "he";

import {
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_FAILURE,
  SET_ROLE_FOR_MESSAGES,
  LOCK_MESSAGES_LIST,
  UNLOCK_MESSAGES_LIST,
  CREATE_DIRECT_CONVERSATION_REQUEST,
  CREATE_DIRECT_CONVERSATION_SUCCESS,
  CREATE_DIRECT_CONVERSATION_FAILURE,
  UNSET_CREATED_CONVERSATION_ID,
  FETCH_UNREAD_MESSAGES_COUNTS_SUCCESS,
  MARK_CONVERSATION_AS_READ,
  MARK_SHOWING_AS_READ,
  CONVERSATION_LIST_SCROLLED,
  CONVERSATION_LIST_SCROLLED_SUCCESS,
  CONVERSATION_LIST_SCROLLED_FAILURE,
  SEARCH_MESSAGES_SCROLLED,
  SEARCH_MESSAGES_SCROLLED_SUCCESS,
  SEARCH_MESSAGES_SCROLLED_FAILURE,
  UPDATE_LAST_MESSAGE_FOR_CONVERSATION,
  UPDATE_CONVERSATIONS_LIST,
  MESSAGING_AVAILABLE_MEMBERS_REQUEST,
  MESSAGING_AVAILABLE_MEMBERS_SUCCESS,
  MESSAGING_AVAILABLE_MEMBERS_FAILURE,
  MESSAGING_AVAILABLE_MEMBERS_CLEAR,
  UPDATE_CONVERSATIONS_FILTER,
  FETCH_UNREAD_MESSAGES_COUNTS_FAILURE,
  FETCH_UNREAD_MESSAGES_COUNTS_REQUEST,
  UPDATE_UNREAD_MESSAGES_COUNTERS,
  UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS,
  SEARCH_MESSAGES_REQUEST,
  SEARCH_MESSAGES_SUCCESS,
  SEARCH_MESSAGES_FAILURE,
  SET_SEARCH_MESSAGE_TERM,
  SET_SEARCH_BAR_FOCUS,
  SET_BROADCAST_LISTING_ID
} from "~brokerage/constants/actionTypes";

const messagesInitialState = {
  entities: [],
  isCountsFetching: false,
  isFetching: false,
  createdConversationId: null,
  messagesListLocked: false,
  role: "la",
  hasMoreMessages: true,
  isScrollFetching: false,
  counts: {
    direct: 0,
    showing: 0,
    helpdesk: 0
  },
  filter: {
    page: 2
  }
};

function messages(state = messagesInitialState, action) {
  switch (action.type) {
    case CONVERSATION_LIST_SCROLLED:
      return { ...state, isScrollFetching: true };
    case SEARCH_MESSAGES_SCROLLED:
      return { ...state, isScrollFetching: true };
    case UPDATE_CONVERSATIONS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.newFilter
        }
      };
    case CONVERSATION_LIST_SCROLLED_SUCCESS: {
      let page = action.data.conversations.length
        ? state.filter.page + 1
        : state.filter.page;
      return {
        ...state,
        entities: state.entities.concat(action.data.conversations),
        isScrollFetching: false,
        hasMoreMessages: Boolean(action.data.conversations.length),
        filter: {
          ...state.filter,
          page: page
        }
      };
    }
    case SEARCH_MESSAGES_SCROLLED_SUCCESS: {
      let page = action.data.conversations.length
        ? state.filter.page + 1
        : state.filter.page;
      return {
        ...state,
        entities: state.entities.concat(action.data.conversations),
        isScrollFetching: false,
        hasMoreMessages: Boolean(action.data.conversations.length),
        filter: {
          ...state.filter,
          page: page
        }
      };
    }
    case UPDATE_LAST_MESSAGE_FOR_CONVERSATION:
      return {
        ...state,
        entities: updateBodyForConversation(
          state.entities,
          action.conversationType,
          action.message
        )
      };
    case UPDATE_CONVERSATIONS_LIST:
      return {
        ...state,
        entities: updateEntitiesOnNewMessage(
          state.entities,
          action.message,
          action.conversationType,
          action.isMessageRead
        )
      };
    case CONVERSATION_LIST_SCROLLED_FAILURE:
      return { ...state, isScrollFetching: false, errors: action.errors };
    case SEARCH_MESSAGES_SCROLLED_FAILURE:
      return { ...state, isScrollFetching: false, errors: action.errors };
    case FETCH_CONVERSATIONS_REQUEST:
      return { ...state, isFetching: true, isSearching: false, searchTerm: "" };
    case FETCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        entities: action.data.conversations,
        isFetching: false,
        hasMoreMessages: true,
        isSearching: false,
        searchTerm: "",
        filter: {
          ...state.filter,
          page: 2
        }
      };
    case FETCH_CONVERSATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
        searchTerm: "",
        errors: action.errors
      };
    case SEARCH_MESSAGES_REQUEST:
      return { ...state, isFetching: true, isSearching: true };
    case SEARCH_MESSAGES_SUCCESS:
      return {
        ...state,
        entities: action.data.conversations,
        isFetching: false,
        isSearching: true,
        hasMoreMessages: true,
        filter: {
          ...state.filter,
          page: 2
        }
      };
    case SEARCH_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
        errors: action.errors
      };
    case SET_SEARCH_MESSAGE_TERM:
      return {
        ...state,
        searchTerm: action.search_term
      };
    case SET_BROADCAST_LISTING_ID:
      return {
        ...state,
        broadcastListingId: action.listingId
      };
    case SET_SEARCH_BAR_FOCUS:
      return {
        ...state,
        isSearchBarFocused: action.search_bar_focus_status
      };
    case SET_ROLE_FOR_MESSAGES:
      return { ...state, role: action.role };
    case LOCK_MESSAGES_LIST:
      return { ...state, messagesListLocked: true };
    case UNLOCK_MESSAGES_LIST:
      return { ...state, messagesListLocked: false };
    case CREATE_DIRECT_CONVERSATION_REQUEST:
      return { ...state, isPostingNewMessage: true };
    case CREATE_DIRECT_CONVERSATION_SUCCESS:
      return {
        ...state,
        isPostingNewMessage: false,
        createdConversationId: action.data.id
      };
    case CREATE_DIRECT_CONVERSATION_FAILURE:
      return { ...state, isPostingNewMessage: false, errors: action.errors };
    case UNSET_CREATED_CONVERSATION_ID:
      return { ...state, createdConversationId: null };
    case FETCH_UNREAD_MESSAGES_COUNTS_REQUEST:
      return { ...state, isCountsFetching: true };
    case FETCH_UNREAD_MESSAGES_COUNTS_FAILURE:
      return { ...state, isCountsFetching: false };
    case FETCH_UNREAD_MESSAGES_COUNTS_SUCCESS:
      return { ...state, counts: action.data, isCountsFetching: false };
    case UPDATE_UNREAD_MESSAGES_COUNTERS:
      return { ...state, counts: action.counts };
    case MARK_CONVERSATION_AS_READ:
      return {
        ...state,
        entities: markConversationAsRead(
          state.entities,
          action.conversationType,
          action.id
        )
      };
    case MARK_SHOWING_AS_READ:
      return {
        ...state,
        entities: markConversationAsRead(state.entities, "showing", action.id)
      };
    case UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS:
      return {
        ...state,
        entities: updateConversationInList(state.entities, action.data)
      };
    default:
      return state;
  }
}

function updateEntitiesOnNewMessage(entities, message, type, isMessageRead) {
  return [
    {
      ...message.conversationData,
      hasRead: isMessageRead
    },
    ...entities.filter(e => {
      return !(e.type === type && e.id == message.conversationId);
    })
  ];
}

function markConversationAsRead(entities, type, id) {
  return entities.map(c => {
    if (c.type === type && c.id == id) {
      return { ...c, hasRead: true };
    } else {
      return c;
    }
  });
}

function updateBodyForConversation(entities, type, message) {
  return entities.map(c => {
    if (c.type === type && c.id == message.conversationId && message.msg) {
      return {
        ...c,
        body: he.decode(message.msg),
        isLastMessageFromMe: message.isFromMe
      };
    } else {
      return c;
    }
  });
}

function updateConversationInList(entities, conversation) {
  return entities.map(c => {
    if (c.type === conversation.type && c.id == conversation.id) {
      return conversation;
    } else {
      return c;
    }
  });
}

const availableAgentsInitialState = {
  entities: [],
  filter: null,
  errors: null
};

function availableAgents(state = availableAgentsInitialState, action) {
  switch (action.type) {
    case MESSAGING_AVAILABLE_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case MESSAGING_AVAILABLE_MEMBERS_SUCCESS:
      return { ...state, entities: action.data.members, isFetching: false };
    case MESSAGING_AVAILABLE_MEMBERS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    case MESSAGING_AVAILABLE_MEMBERS_CLEAR:
      return { ...state, entities: [] };
    default:
      return state;
  }
}

export default combineReducers({
  messages,
  availableAgents
});
