import React, { useState, useContext } from "react";
import css from "./CalcFastestRoute.sass";
import { connect } from "react-redux";
import Button from "~brokerage/components/shared/Button";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import Modal from "react-modal";
import RouteIcon from "remixicon-react/RouteFillIcon";
import { Dropdown, Label } from "~brokerage/components/shared/Form";
import {
  calcBestDestination,
  reorderShowings,
  fetchRoute,
  updateShowingsForRoute,
  getCoordinates
} from "./util";

Modal.setAppElement(document.body);

const CalcFastestRoute = ({
  mapboxkey,
  time,
  currentFastestRouteShowing,
  setCurrentFastestRouteShowing,
  restrictions
}) => {
  const [loading, setLoading] = useState(false);
  const { times, setTimes } = useContext(ShowingContext);
  const [startShowing, setStartShowing] = useState();
  const [selectShowingModalOpen, setSelectShowingModalOpen] = useState();

  const calculate = async () => {
    setLoading(true);
    const orderedShowings = startShowing
      ? reorderShowings(times, startShowing)
      : showings;
    const startTime = time;
    const matrixCoords = getCoordinates(orderedShowings);
    const destinationIndex = await calcBestDestination(matrixCoords, mapboxkey);
    orderedShowings.push(orderedShowings.splice(destinationIndex, 1)[0]);
    const coordinates = getCoordinates(orderedShowings);
    const routeData = await fetchRoute(coordinates, mapboxkey);
    const updatedShowings = updateShowingsForRoute(
      times,
      routeData,
      startTime,
      restrictions
    );
    setTimes(updatedShowings);
    setSelectShowingModalOpen(false);
    setCurrentFastestRouteShowing(startShowing);
    setLoading(false);
  };

  const handleStartChange = ({ value }) => {
    setStartShowing(value);
  };

  const alreadyOptimized =
    currentFastestRouteShowing && currentFastestRouteShowing === startShowing;

  return (
    <div>
      <Button
        modifier="flexCenter"
        float="left"
        onClick={() => setSelectShowingModalOpen(true)}
      >
        <RouteIcon size={14} className={css.routeIcon} />
        Fastest Route
      </Button>
      <Modal
        isOpen={selectShowingModalOpen}
        className={css.modalContent}
        overlayClassName={css.modalOverlay}
        onRequestClose={() => setSelectShowingModalOpen(false)}
      >
        <div className={css.modalHeader}>Calculate Fastest Route</div>
        <div className={css.modalBody}>
          <Label variant="vertical">
            Select the listing you wish to show first:
          </Label>
          <Dropdown
            title={startShowing ? startShowing.address : "Select a showing."}
            onChange={handleStartChange}
            variant={"outline2Block"}
            selected={startShowing || ""}
            options={times.map(s => ({
              label: s.address,
              value: s.id
            }))}
          />
          {alreadyOptimized && (
            <div className={css.modalError}>
              Route already optimized for this starting point.
            </div>
          )}
        </div>
        <div className={css.modalFooter}>
          <Button
            variant="outline"
            onClick={() => setSelectShowingModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || alreadyOptimized}
            variant="primary"
            onClick={calculate}
          >
            Calculate Route
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ keys }) => {
  const { mapboxkey } = keys;
  return {
    mapboxkey
  };
};
export default connect(mapStateToProps)(CalcFastestRoute);
