import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";

import fetch from "~brokerage/libs/fetch.js";
import Icon from "~brokerage/components/shared/Icon";
import StatusPill from "~brokerage/app/components/shared/StatusPill.jsx";
import Loader from "~brokerage/app/components/shared/Loader";
import Tooltip from "~brokerage/components/shared/Tooltip";
import useClickOutside from "~brokerage/app/hooks/useClickOutside";

import { statusColor } from "~brokerage/app/constants/appointments.js";
import ShowingStatusDropdown from "./ShowingStatusDropdown";
import AddToCalendar from "./AddToCalendar";
import css from "./index.sass";
import calendarAdd from "~brokerage/assets/images/calendar-add.svg";

const DetailsSection = ({ showingDetails }) => {
  const {
    status,
    requested_time: requestedTime,
    duration,
    address,
    unique_id: uniqueId
  } = showingDetails;

  const calendarImportRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const sellerAuthToken = queryParams.get("token");
  const [showingAppointments, setShowingAppointments] = useState([]);
  const [selectedDropdownStatus, setSelectedDropdownStatus] =
    useState("approved");
  const [loading, setLoading] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useClickOutside(calendarImportRef, () => setSelectedAppointment(null));

  const handleShowingStatusChange = ({ value }) =>
    setSelectedDropdownStatus(value);

  const pillColor = statusColor(selectedDropdownStatus);

  const getAppointments = () => {
    !loading && setLoading(true);
    fetch(
      `/api/v2/seller_landing/${uniqueId}/showing_appointments`,
      {
        method: "get",
        headers: { "Seller-Authorization": sellerAuthToken },
        params: { status: selectedDropdownStatus }
      },
      false
    )
      .then(response => {
        setShowingAppointments(response.data.appointments);
      })
      .catch(error => {
        toast.error(error.message || "Something went wrong.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderAppointmentList = () => {
    if (loading) {
      return <Loader active />;
    } else if (showingAppointments.length === 0) {
      return (
        <li className={css.li}>
          {`No ${selectedDropdownStatus} appointments.`}
        </li>
      );
    } else {
      return (
        <>
          {showingAppointments.map((appointmentDetail, index) => (
            <li
              className={css.li}
              key={index}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className={css.dateTime}>
                {moment
                  .parseZone(appointmentDetail["time"])
                  .format("ddd MMM D, h:mma")}
              </span>
              <span
                className={css.cPill}
                style={{ backgroundColor: pillColor }}
              >
                {appointmentDetail["duration"]} mins
              </span>
              {selectedDropdownStatus === "approved" && (
                <div className={css.addToCalendar}>
                  <Tooltip text="Add to calendar" direction="up">
                    <span
                      className={css.calendarIcon}
                      onClick={() => {
                        setSelectedAppointment(
                          selectedAppointment === index ? null : index
                        );
                      }}
                    >
                      <img src={calendarAdd} className={css.logo} />
                    </span>
                  </Tooltip>
                  {selectedAppointment === index && (
                    <div ref={calendarImportRef}>
                      <AddToCalendar
                        appointmentDetail={appointmentDetail}
                        showingDetails={showingDetails}
                      />
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
        </>
      );
    }
  };

  useEffect(getAppointments, [selectedDropdownStatus]);

  return (
    <>
      <div className={css.sectionWithoutBorder}>
        <h3 className={css.h3}>Appointment Status</h3>
        <div className={css.showingActionsWrapper}>
          <StatusPill
            showingStatus={status}
            futureShowing={moment(requestedTime).isAfter()}
            style={{ paddingLeft: "6px", paddingRight: "11px" }}
          />
        </div>
      </div>
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>Address</h3>
        <ul className={css.ul}>
          <li className={css.li}>{address}</li>
        </ul>
      </div>
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>Date & Time</h3>
        <ul className={css.ul}>
          <li className={css.li}>
            {moment.parseZone(requestedTime).format("ddd MMM D, h:mma")} (
            {duration} mins)
          </li>
        </ul>
      </div>
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>Other Appointments</h3>
        <ShowingStatusDropdown
          currentStatus={selectedDropdownStatus}
          handleChange={handleShowingStatusChange}
        />
        <ul className={css.appointmentList}>{renderAppointmentList()}</ul>
      </div>
      <ShowingDetails showingDetails={showingDetails} />
    </>
  );
};

const ShowingDetails = ({
  showingDetails: { accompanyAgent, accompanySeller, requestACall, message }
}) => {
  const hasInstructions = [
    accompanyAgent,
    accompanySeller,
    requestACall,
    message
  ].some(x => x);

  const Instruction = ({ condition, text, icon }) =>
    condition && (
      <li className={css.li}>
        <i className={css.liIcon}>
          <Icon name={icon} />
        </i>{" "}
        {text}
      </li>
    );

  const accompanyText = `${
    accompanyAgent ? (accompanySeller ? "Agent & Seller" : "Agent") : "Seller"
  } will Accompany`;

  if (hasInstructions) {
    return (
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>Details</h3>
        <ul className={css.ul}>
          <Instruction
            condition={accompanyAgent || accompanySeller}
            text={accompanyText}
            icon="user"
          />
          <Instruction
            condition={requestACall}
            text="Callback is requested"
            icon="phone"
          />
          {message && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <i className={css.liIcon}>
                <Icon name="info" />
              </i>{" "}
              {message.length > 300 ? (
                <ShowMoreText lines={3} className={css.messageText}>
                  {message}
                </ShowMoreText>
              ) : (
                <div className={css.messageText}>{message}</div>
              )}
            </div>
          )}
        </ul>
      </div>
    );
  } else {
    return <></>;
  }
};

export default DetailsSection;
