import {
  SLIDER_MIN_RANGE,
  SLIDER_COLOR_BLUE,
  SLIDER_COLOR_BLACK
} from "./constants";
import {
  SHOWING_COUNTS,
  SHOWING_DURATIONS
} from "~brokerage/constants/showings";

export const genSliderMarks = advanceNoticeDuration => {
  const selectedValue = advanceNoticeDuration / 30;

  const marks = {};

  const hours = advanceNoticeDuration / 60;
  const label = `${hours}`;

  marks[selectedValue] = {
    style: { color: SLIDER_COLOR_BLUE },
    label
  };

  return marks;
};

export const buildMaximumOverlapLimitOptions = () =>
  [{ value: null, label: "No overlap limit" }].concat(
    SHOWING_COUNTS.map(count => ({
      value: count,
      label: `${count} appointments maximum`
    }))
  );

export const buildMaxAppointmentLengthOptions = () =>
  [{ value: null, label: "None" }].concat(
    SHOWING_DURATIONS.map(duration => ({
      value: duration,
      label: `${duration} minutes`
    }))
  );
