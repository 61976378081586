import { combineReducers } from 'redux'

import {
  SHOWINGS_REQUEST,
  SHOWINGS_SUCCESS,
  SHOWINGS_FAILURE,
  SHOWINGS_FILTER_CHANGE,
  SHOWINGS_AGENT_NAME_REQUEST,
  SHOWINGS_AGENT_NAME_SUCCESS,
  SHOWINGS_AGENT_NAME_FAILURE,
  SHOWINGS_PAGE_SCROLLED,
  SHOWINGS_PAGE_SCROLLED_SUCCESS,
  SHOWINGS_PAGE_SCROLLED_FAILURE,
  SHOWINGS_COUNTS_SUCCESS,
  SHOWINGS_COUNTS_FAILURE,
  AVAILABLE_ROLES_SUCCESS,
  AVAILABLE_ROLES_FAILURE,
  SHOWINGS_UNREAD_COUNT_SUCCESS,
  SHOWINGS_UNREAD_COUNT_FAILURE,
  MARK_SHOWING_AS_READ,
  FETCH_FILTER_AGENTS_SUCCESS,
  SHOWINGS_SHOWING_CHANGED_BY_MESSAGE,
  NEW_SHOWING_SAVE_SUCCESS,
  SHOWING_CANCEL_SHOWING_SUCCESS
} from '~brokerage/constants/actionTypes'

import { STATUS_CANCELLED } from '~brokerage/constants/showings/statuses'

const defaultState = {
  entities: [],
  filter: { page: 2 },
  counts: {},
  availableRoles: null,
  filterAgents: [],
  hasMoreShowings: true
}

function showings(state = defaultState, action) {
  switch (action.type) {
  case SHOWINGS_REQUEST:
    return { ...state, isFetching: true }
  case SHOWINGS_SUCCESS:
    return {
      ...state,
      entities: action.data.showings,
      isFetching: false,
      didInvalidate: false,
      hasMoreShowings: true,
      filter: {
        ...state.filter,
        page: 2
      }
    }
  case SHOWINGS_FAILURE:
    return { ...state, isFetching: false } //TODO error handling
  case SHOWINGS_PAGE_SCROLLED:
    return { ...state, isScrollFetching: true }
  case SHOWINGS_PAGE_SCROLLED_SUCCESS: {
    let page = action.data.showings.length ? ++state.filter.page : state.filter.page
    return {
      ...state,
      entities: action.data.showings.length ? state.entities.concat(action.data.showings) : state.entities,
      isScrollFetching: false,
      hasMoreShowings: Boolean(action.data.showings.length),
      filter: {
        ...state.filter,
        page: page
      }
    }
  }
  case FETCH_FILTER_AGENTS_SUCCESS:
    return { ...state, filterAgents: action.data.agents }
  case SHOWINGS_PAGE_SCROLLED_FAILURE:
    return { ...state, isScrollFetching: false }
  case MARK_SHOWING_AS_READ:
    return { ...state, entities: markShowingAsRead(state.entities, action.id) }
  case SHOWINGS_FILTER_CHANGE:
    return { ...state, filter: { ...state.filter, ...action.changes } }
  case SHOWINGS_AGENT_NAME_REQUEST:
    return { ...state, filterAgentName: void 0 }
  case SHOWINGS_AGENT_NAME_SUCCESS:
    return { ...state, filterAgentName: action.data.name }
  case SHOWINGS_AGENT_NAME_FAILURE:
    return { ...state } //TODO error handling
  case SHOWINGS_COUNTS_SUCCESS:
    return { ...state, counts: action.data.counts }
  case SHOWINGS_COUNTS_FAILURE:
    return { ...state } //TODO error handling
  case AVAILABLE_ROLES_SUCCESS:
    return { ...state, availableRoles: action.data.roles }
  case AVAILABLE_ROLES_FAILURE:
    return { ...state } //TODO error handling
  case SHOWINGS_UNREAD_COUNT_SUCCESS:
    return { ...state, showingsUnreadCount: action.data.count }
  case SHOWINGS_UNREAD_COUNT_FAILURE:
    return { ...state } //TODO error handling
  case SHOWINGS_SHOWING_CHANGED_BY_MESSAGE:
    return { ...state, entities: state.entities.map(entity => action.showing.id === entity.id ? action.showing : entity) }
  case NEW_SHOWING_SAVE_SUCCESS:
    return { ...state, didInvalidate: true }
  case SHOWING_CANCEL_SHOWING_SUCCESS:
    return {
      ...state,
      entities: state.entities.map(entity => {
        if (action.id === entity.id)
          return { ...entity, status: STATUS_CANCELLED }
        else
          return entity
      })
    }
  default:
    return state
  }
}

function markShowingAsRead(entities, id) {
  return entities.map(s => {
    if (s.id === id) {
      return { ...s, isUnread: false }
    } else {
      return s
    }
  })
}

export default combineReducers({
  showings
})
