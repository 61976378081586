import css from "~brokerage/components/modals/ModalLogCall.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "~brokerage/components/shared/Form";
import { Header, Body, Footer } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { withRouter } from "react-router-dom";

class ModalApplyTemplate extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object
  };

  handleSubmit = () => {
    this.props.history.push("/listings");
  };

  render() {
    return (
      <div className={css.base}>
        <Header title="Select Template to Apply" />

        <Body>
          <div className={css.section}>
            <h2 className={css.h2}>MY LISTING TEMPLATES</h2>
            <div className={css.well}>
              <table className={css.table}>
                <tbody>
                  <tr>
                    <td className={css.tdCheckbox}>
                      <Checkbox />
                    </td>
                    <td>
                      <h3 className={css.h3}>Default Template</h3>
                      <ul className={css.ul2}>
                        <li>
                          <strong>Allow Property to be shown?</strong> Yes
                        </li>
                        <li>
                          <strong>How are requests handled?</strong> Require
                          Approval
                        </li>
                        <li>
                          <strong>Showing arrangement:</strong> Agent will
                          accompany
                        </li>
                        <li>
                          <strong>Office Involvement:</strong> Jennifer Elliot,
                          Samantha Ray, Front Desk
                        </li>
                        <li>
                          <strong>Seller/Tenant Involvement:</strong> None
                        </li>
                        <li>
                          <strong>Approval:</strong> Jennifer Elliot must
                          approve showing requests
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <h2 className={css.h2}>OFFICE TEMPLATES</h2>
            <div className={css.well}>
              <table className={css.table}>
                <tbody>
                  <tr>
                    <td className={css.tdCheckbox}>
                      <Checkbox />
                    </td>
                    <td>
                      <h3 className={css.h3}>Require Approval Template</h3>
                      <ul className={css.ul2}>
                        <li>
                          <strong>Allow Property to be shown?</strong> Yes
                        </li>
                        <li>
                          <strong>How are requests handled?</strong> Require
                          Approval
                        </li>
                        <li>
                          <strong>Showing arrangement:</strong> Agent will
                          accompany
                        </li>
                        <li>
                          <strong>Office Involvement:</strong> Listing Agent,
                          Assistant, Front Desk
                        </li>
                        <li>
                          <strong>Seller/Tenant Involvement:</strong> None
                        </li>
                        <li>
                          <strong>Approval:</strong> Listing Agent must approve{" "}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Body>

        <Footer>
          <div className={css.footerBtn}>
            <Button variant="primary" onClick={this.handleSubmit}>
              Apply Template
            </Button>
          </div>
        </Footer>
      </div>
    );
  }
}

export default withRouter(ModalApplyTemplate);
