import React from "react";
import ReactList from "react-list";

import ListItemLoader from "~brokerage/components/shared/ListItemLoader";
import ListItem from "./ListItem";
import css from "./index.sass";
import TableHeader from "./TableHeader";

const TableView = ({
  agentId,
  listings,
  needLoadMoreListings,
  loadMoreListings
}) => {
  const itemRenderer = (index, key) => {
    if (index >= listings.length) {
      return null;
    }

    const listing = listings[index];

    return (
      <ListItemLoader
        key={key}
        needLoadMore={needLoadMoreListings(index)}
        loadMore={loadMoreListings}
        uniqueId={listing.id}
      >
        <ListItem listing={listing} agentId={agentId} />
      </ListItemLoader>
    );
  };

  return (
    <div className={css.listContainer}>
      <TableHeader />
      <div className={css.listItemsWrapper}>
        <ReactList
          itemRenderer={itemRenderer}
          length={listings.length}
          type="uniform"
        />
      </div>
    </div>
  );
};

export default TableView;
