import css from "./StaticAppointments.sass";
import React from "react";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { datetimeToPixels } from "~brokerage/libs/helpers/TimeHelper";
import Showing from "./Showing";
import { timeToRemoteWithTimeZone } from "~brokerage/libs/helpers/TimeHelper";

const StaticAppointmentList = ({ showings = [], variant = "base" }) => (
  <>
    {showings.map(showing => {
      const travelStartTime = timeToRemoteWithTimeZone(
        showing.requestedTimeRaw
      );
      const top = datetimeToPixels(travelStartTime);
      return (
        <div
          key={showing.id}
          className={composeClassName(css, variant)}
          style={{ top }}
        >
          <Showing
            id={showing.id}
            address={showing.address}
            status={showing.status}
            duration={showing.duration}
            disabled
          />
        </div>
      );
    })}
  </>
);

export default StaticAppointmentList;
