import css from "./MainNavigation.sass";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { callApi } from "~brokerage/middlewares/api";
import { fetchUnreadNotificationsCount } from "~brokerage/actions/myAccount";
import { fetchUnreadMessagesCount } from "~brokerage/actions/messages";
import Badge from "~brokerage/components/shared/Badge";
import MainNavigationLink from "~brokerage/components/shared/MainNavigationLink";
import { navigationIcon } from "~brokerage/components/shared/MainNavigationIcons";
import { DEFAULT_LANDING_PAGE_URL } from "~brokerage/app/constants/apiUrls";
import AppLogo from "~brokerage/components/shared/AppLogo";
import GuestNavigation from "./GuestNavigation";

const MainNavigation = props => {
  useEffect(() => {
    props.dispatch(fetchUnreadNotificationsCount());
    props.dispatch(fetchUnreadMessagesCount());
  }, []);

  const RenderCounter = ({ counter }) => (
    <>
      {counter > 0 && (
        <div className={css.unreadCounter}>
          <Badge variant="orange">{counter > 99 ? "99+" : counter}</Badge>
        </div>
      )}
    </>
  );

  const {
    hasAccessToTransactionsChat,
    isAdmin,
    isRoutesEnabled,
    isImpersonating,
    isTrueUserAdmin,
    isContactsEnabled,
    isAddShowingEnabled,
    isGuestLogin
  } = props;

  const isImpersonatedByNonAdmin = isImpersonating && !isTrueUserAdmin;

  const stopImpersonating = () => {
    event.preventDefault();
    callApi(`people/stop_impersonating`, {}, {}, "post")
      .then(() => {
        window.location = DEFAULT_LANDING_PAGE_URL;
      })
      .catch(error => {
        toast.error(error.response.data.message || "Something went wrong.");
      });
  };

  return (
    <header className={css.base}>
      <h1 className={css.logo}>
        <NavLink
          className={css.logoLink}
          to="/"
          exact={true}
          activeClassName={css.active}
        >
          <AppLogo scale="1.5" />
        </NavLink>
      </h1>

      <nav className={css.nav}>
          {isGuestLogin ? (
            <GuestNavigation unreadMessages={props.unreadMessages} />
            ) : (
            <>
            <ul className={css.navUl}>
            {hasAccessToTransactionsChat && (
              <>
                <MainNavigationLink
                  to="/"
                  exact={true}
                  icon={navigationIcon("calendar")}
                  label="Calendar"
                />
                {!isImpersonatedByNonAdmin && isRoutesEnabled && (
                  <MainNavigationLink
                    to="/my_showings"
                    icon={navigationIcon("routes")}
                    label="Routes"
                  />
                )}
                <MainNavigationLink
                  to="/listings"
                  icon={navigationIcon("myListings")}
                  label="My Listings"
                />
                {!isImpersonatedByNonAdmin && (
                  <>
                    <MainNavigationLink
                      to="/notifications"
                      icon={navigationIcon("notifications")}
                    >
                      Alerts
                      <RenderCounter counter={props.unreadNotifications} />
                    </MainNavigationLink>
                    <MainNavigationLink
                      to="/reports"
                      icon={navigationIcon("reports")}
                    >
                      Reports
                    </MainNavigationLink>
                  </>
                )}
              </>
            )}
            <div className={css.lineSeparator} />
            <MainNavigationLink to="/messages" icon={navigationIcon("messages")}>
              Messages
              <RenderCounter counter={props.unreadMessages} />
            </MainNavigationLink>
            {isContactsEnabled && (
              <MainNavigationLink
                to="/people"
                icon={navigationIcon("contacts")}
                label="Contacts"
              />
            )}
            {isAddShowingEnabled && (
              <>
                <div className={css.lineSeparator} />
                <MainNavigationLink
                  to="/search"
                  icon={navigationIcon("newShowing")}
                >
                  Add a Showing
                </MainNavigationLink>
              </>
            )}
            {!isImpersonatedByNonAdmin && isAdmin && (
              <MainNavigationLink
                href="/active_admin"
                icon={navigationIcon("adminPanel")}
                label="Admin panel"
              />
            )}
            {isImpersonating && (
              <>
                <div className={css.lineSeparator} />
                <div className={css.highlighted}>
                  <MainNavigationLink
                    onClick={stopImpersonating}
                    icon={navigationIcon("stopImpersonating")}
                  >
                    Stop Work As
                  </MainNavigationLink>
                </div>
              </>
            )}
          </ul>

          <ul className={css.navUlBottom}>
            {!isImpersonatedByNonAdmin && (
              <MainNavigationLink
                to="/support"
                icon={navigationIcon("support")}
                label="Support"
              />
            )}
            <MainNavigationLink
              to="/settings"
              icon={navigationIcon("settings")}
              label="Settings"
            />
          </ul>
          </>
          )}
        </nav>
    </header>
  );
};

MainNavigation.propTypes = {
  unreadListings: PropTypes.number,
  unreadShowings: PropTypes.number,
  dispatch: PropTypes.func,
  unreadMessages: PropTypes.number,
  unreadNotifications: PropTypes.number,
  isUnreadNotificationsCountFetching: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isRoutesEnabled: PropTypes.bool,
  isCountsFetching: PropTypes.bool,
  location: PropTypes.object,
  hasAccessToTransactionsChat: PropTypes.bool
};

MainNavigation.contextTypes = {
  location: PropTypes.object
};

const mapStateToProps = state => {
  const { isCountsFetching, counts } = state.messages.list.messages;
  const {
    value: unreadNotifications,
    isFetching: isUnreadNotificationsCountFetching
  } = state.notifications.unreadCounters;
  const { hasAccessToTransactionsChat } = state.myAccount.preferences;

  const {
    isAdmin,
    isRoutesEnabled,
    isImpersonating,
    isTrueUserAdmin,
    isContactsEnabled,
    isAddShowingEnabled,
    isGuestLogin
  } = state.currentUser;

  return {
    unreadMessages: Object.values(counts).reduce((acc, v) => acc + v),
    unreadNotifications,
    isUnreadNotificationsCountFetching,
    isCountsFetching,
    hasAccessToTransactionsChat,
    isAdmin,
    isRoutesEnabled,
    isImpersonating,
    isTrueUserAdmin,
    isContactsEnabled,
    isAddShowingEnabled,
    isGuestLogin
  };
};
export default connect(mapStateToProps, null, null, { pure: false })(
  MainNavigation
);
