import React from "react";
import CloseFillIcon from "remixicon-react/CloseLineIcon";
import FilePdfFillIcon from "remixicon-react/FilePdfFillIcon";
import { isEmpty } from "lodash";
import classNames from "classnames";
import {
  IMAGE_CONTENT_TYPES,
  DOCUMENT_CONTENT_TYPES,
  TIMESTAMP_REGEX
} from "~brokerage/components/shared/FileUpload/constants";
import { getFileNameWithoutExtension, formatSize } from "./utils";
import Link from "../Link";
import css from "./FilePreview.sass";

const RenderFile = ({
  file: { type, contentType, name, size, url },
  progress,
  linkify
}) => {
  const fileId = getFileNameWithoutExtension({ name });
  const mimeType = type || contentType;

  const fileName = name.replace(TIMESTAMP_REGEX, "");

  if (IMAGE_CONTENT_TYPES.includes(mimeType)) {
    return <img src={URL.createObjectURL(file)} className={css.imagePreview} />;
  } else if (DOCUMENT_CONTENT_TYPES.includes(mimeType)) {
    return (
      <div className={css.fileCard}>
        <FilePdfFillIcon className={css.fileIcon} />
        <div>
          <div className={css.fileInfo}>
            {linkify ? (
              <Link href={url} external={true}>
                <div className={css.fileName}>{fileName}</div>
              </Link>
            ) : (
              <div className={css.fileName}>{fileName}</div>
            )}
            <div className={css.fileSize}>{formatSize(size)}</div>
          </div>
          {progress && (
            <div
              className={css.progressBar}
              style={{
                width: `${progress[fileId]}%`,
                animationDuration: `${progress[fileId] / 50}s`
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <div>File type not handled</div>;
  }
};

const FilePreview = ({
  files,
  handleRemoveFile,
  progress,
  isNotesMode,
  isUploading = false,
  imagesWrapperClassName = "",
  imageWrapperClassName = "",
  linkify = false
}) => {
  if (isEmpty(files)) return null;

  const showRemoveIcon = !isUploading && handleRemoveFile;

  return (
    <div
      className={classNames(css.imagesWrapper, imagesWrapperClassName, {
        [css.notesEnabled]: isNotesMode
      })}
    >
      {files.map(({ file }, index) => {
        return (
          <div
            key={index}
            className={`${css.imageWrapper} ${imageWrapperClassName}`}
          >
            <RenderFile file={file} progress={progress} linkify={linkify} />
            {showRemoveIcon && (
              <CloseFillIcon
                size={17}
                onClick={() => handleRemoveFile(file.name)}
                className={css.closeButton}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FilePreview;
