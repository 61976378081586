import css from "./index.sass";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import Bubble from "./Bubble";
import Link from "~brokerage/components/shared/Link";
import { withRouter } from "react-router-dom";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";

export class Listing extends React.PureComponent {
  static propTypes = {
    userHasAccessToTransactionsChat: PropTypes.bool,
    message: PropTypes.object,
    bubbleOptions: PropTypes.object,
    location: PropTypes.object
  };

  renderListingAddress() {
    const { location, message, userHasAccessToTransactionsChat } = this.props;
    const { id: listingKey, address } = message.listing;

    if (!userHasAccessToTransactionsChat) return address;

    return (
      <Link
        className={css.addressLink}
        to={{
          pathname: `/listings/${listingKey}/details`,
          query: { backToPath: `${location.pathname}${location.search}` }
        }}
      >
        {address}
      </Link>
    );
  }

  render() {
    const { message, bubbleOptions } = this.props;
    const {
      specs,
      photoUrl,
      mls: mlsNumber,
      status,
      price,
      type
    } = message.listing;

    return (
      <Bubble {...bubbleOptions}>
        <div
          className={css.listingImage}
          style={{ backgroundImage: `url(${photoUrl})` }}
        />
        <p className={css.p}>{this.renderListingAddress()}</p>

        <ul className={css.listingSummary}>
          <li>{mlsNumber}</li>
          <li>{status}</li>
          <li>{formatPrice(price)}</li>
        </ul>

        <ul className={css.listingSummary}>
          {specs.beds && (
            <li>
              {specs.beds} {specs.beds === 1 ? "beds" : "beds"}
            </li>
          )}
          {specs.baths && (
            <li>
              {specs.baths} {specs.baths === 1 ? "bath" : "baths"}
            </li>
          )}
          {specs.sqft && <li>{formatNumberUsingCommas(specs.sqft)} sqft</li>}
          <li>{type}</li>
        </ul>
      </Bubble>
    );
  }
}

function mapStateToProps(state) {
  const { hasAccessToTransactionsChat: userHasAccessToTransactionsChat } =
    state.myAccount.preferences;
  return { userHasAccessToTransactionsChat };
}
export default withRouter(connect(mapStateToProps)(Listing));
