import { combineReducers } from "redux";

import {
  FETCH_CONVERSATION_MESSAGES_REQUEST,
  FETCH_CONVERSATION_MESSAGES_SUCCESS,
  FETCH_CONVERSATION_MESSAGES_FAILURE,
  FETCH_CONVERSATION_PARTICIPANTS_REQUEST,
  FETCH_CONVERSATION_PARTICIPANTS_SUCCESS,
  FETCH_CONVERSATION_PARTICIPANTS_FAILURE,
  FETCH_CONVERSATION_MESSAGES_CLEAR,
  FETCH_CONVERSATION_PUBNUB_DATA_REQUEST,
  FETCH_CONVERSATION_PUBNUB_DATA_SUCCESS,
  MESSAGING_ADD_MESSAGE_FROM_PUBNUB,
  CONVERSATION_ADD_MESSAGE_REQUEST,
  CONVERSATION_ADD_MESSAGE_SUCCESS,
  CONVERSATION_ADD_MESSAGE_FAILURE,
  CONVERSATION_ADD_NOTE_MESSAGE_REQUEST,
  CONVERSATION_ADD_NOTE_MESSAGE_SUCCESS,
  CONVERSATION_ADD_NOTE_MESSAGE_FAILURE,
  SCROLL_CHAT_THREAD_REQUEST,
  SCROLL_CHAT_THREAD_SUCCESS,
  MESSAGING_AVAILABLE_AGENTS_SUCCESS,
  MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS,
  MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS,
  SCROLL_CHAT_THREAD_FAILURE,
  UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS,
  UPDATE_DIRECT_MESSAGE_HAS_READ
} from "~brokerage/constants/actionTypes";

import { addMessageToList } from "../showings/single";

const conversationMessagesInitialState = {
  entities: [],
  participants: [],
  availableParticipants: [],
  isSendingMessage: false,
  isChatScrollFetching: false,
  hasMoreMessages: true,
  filter: null,
  errors: null
};

export function updateMessageHasRead(messages, message_id) {
  if (message_id.length === 0) {
    return messages;
  }

  const updatedMessages = messages.map(message => {
    if (message_id.includes(message.id)) {
      return { ...message, hasRead: true };
    }

    return message;
  });

  return updatedMessages;
}

function conversationMessages(
  state = conversationMessagesInitialState,
  action
) {
  switch (action.type) {
    case SCROLL_CHAT_THREAD_REQUEST:
      return { ...state, isChatScrollFetching: true };
    case SCROLL_CHAT_THREAD_SUCCESS:
      return {
        ...state,
        entities: action.data.messages.concat(state.entities),
        hasMoreMessages: Boolean(action.data.messages.length),
        isChatScrollFetching: false
      };
    case SCROLL_CHAT_THREAD_FAILURE:
      return { ...state, isChatScrollFetching: false, errors: action.errors };
    case FETCH_CONVERSATION_MESSAGES_REQUEST:
      return { ...state, isFetching: true, hasMoreMessages: true };
    case FETCH_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        entities: action.data.messages,
        conversationData: action.data.conversationData,
        isFetching: false
      };
    case FETCH_CONVERSATION_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
        hasMoreMessages: true
      };
    case FETCH_CONVERSATION_PARTICIPANTS_REQUEST:
      return { ...state, participantsAreFetching: true };
    case FETCH_CONVERSATION_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.data.participants,
        participantsAreFetching: false
      };
    case FETCH_CONVERSATION_PARTICIPANTS_FAILURE:
      return {
        ...state,
        errors: action.errors,
        participantsAreFetching: false
      };
    case FETCH_CONVERSATION_MESSAGES_CLEAR:
      return { ...state, entities: [] };
    case MESSAGING_ADD_MESSAGE_FROM_PUBNUB:
      return {
        ...state,
        entities: addMessageToList(state.entities, action.message)
      };
    case MESSAGING_AVAILABLE_AGENTS_SUCCESS:
      return {
        ...state,
        availableParticipants: action.data.members,
        isFetching: false
      };
    case MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS:
      return {
        ...state,
        participants: [...state.participants, action.data.agent],
        availableParticipants: []
      };
    case MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS:
      return {
        ...state,
        participants: state.participants.filter(
          a => a.userId !== action.data.agent.userId
        )
      };
    case CONVERSATION_ADD_MESSAGE_REQUEST:
      return { ...state, isSendingMessage: true };
    case CONVERSATION_ADD_MESSAGE_SUCCESS:
      return { ...state, isSendingMessage: false };
    case CONVERSATION_ADD_MESSAGE_FAILURE:
      return { ...state, isSendingMessage: false, errors: action.errors };
    case CONVERSATION_ADD_NOTE_MESSAGE_REQUEST:
      return { ...state, isSendingMessage: true };
    case CONVERSATION_ADD_NOTE_MESSAGE_SUCCESS:
      return { ...state, isSendingMessage: false };
    case CONVERSATION_ADD_NOTE_MESSAGE_FAILURE:
      return { ...state, isSendingMessage: false, errors: action.errors };
    case UPDATE_DIRECT_MESSAGE_HAS_READ:
      return {
        ...state,
        entities: updateMessageHasRead(state.entities, action.message)
      };
    default:
      return state;
  }
}

function pubnub(state = {}, action) {
  switch (action.type) {
    case FETCH_CONVERSATION_PUBNUB_DATA_REQUEST:
      return { data: null };
    case FETCH_CONVERSATION_PUBNUB_DATA_SUCCESS:
      return { data: action.data.pubnubData };
    default:
      return { ...state };
  }
}

function interlocutors(state = null, action) {
  switch (action.type) {
    case FETCH_CONVERSATION_MESSAGES_REQUEST:
      return null;
    case FETCH_CONVERSATION_MESSAGES_SUCCESS:
      return action.data.interlocutors;
    case MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS:
      return [...state, action.data.agent];
    case MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS:
      return state.filter(i => i.userId !== action.data.agent.userId);
    default:
      return state;
  }
}

function listing(state = null, action) {
  switch (action.type) {
    case FETCH_CONVERSATION_MESSAGES_REQUEST:
      return null;
    case FETCH_CONVERSATION_MESSAGES_SUCCESS:
      return action.data.listing || null;
    default:
      return state;
  }
}

function name(state = null, action) {
  switch (action.type) {
    case FETCH_CONVERSATION_MESSAGES_REQUEST:
      return null;
    case FETCH_CONVERSATION_MESSAGES_SUCCESS:
      return action.data.name || null;
    case UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS:
      return action.data.conversationName || null;
    case MESSAGING_ADD_MESSAGE_FROM_PUBNUB:
      return action.message.conversationData.conversationName || null;
    default:
      return state;
  }
}

export default combineReducers({
  conversationMessages,
  pubnub,
  interlocutors,
  name,
  listing
});
