import css from './List.sass'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Icon from '~brokerage/components/shared/Icon'
import Filter from './Filter'

let id = 0

export default class DropdownList extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array,
    selected: PropTypes.node,
    variant: PropTypes.string,
    toggleWidth: PropTypes.number,
    toggleHeight: PropTypes.number,
    listDirection: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
    filter: PropTypes.bool,
    optionsPostfix: PropTypes.node,
    onOptionClick: PropTypes.func,
    onClickOutside: PropTypes.func,
    onFilter: PropTypes.func
  }

  componentWillReceiveProps() {
    this.activeOptionEl = null
  }

  componentDidMount() {
    this.id = id++
    this.$list = $(ReactDOM.findDOMNode(this))
    this.$arrow = $(`<i class="${css.arrow}"/>`)
    this.$window = $(window)
    
    if (this.props.listDirection === "up")
      this.$list.css("top", this.props.top - this.$list.outerHeight());
    this.updatePosition()
    this.scrollToActiveOption()

    $(document).on(`mouseup.aiDropdown${this.id}`, (event) => {
      if (!$(event.target).closest(this.$list).length) {
        this.props.onClickOutside()
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.updatePosition()
    }
  }

  componentWillUnmount() {
    $(document).off(`mouseup.aiDropdown${this.id}`)
  }

  updatePosition() {
    const listOuterWidth = this.$list.outerWidth()
    const listOffsetLeft = this.$list.offset().left
    const windowWidth = this.$window.width()
    const windowScrollLeft = this.$window.scrollLeft()

    if (listOffsetLeft + listOuterWidth > windowWidth) {
      const newListOffsetLeft = windowWidth - listOuterWidth - 10 + windowScrollLeft
      this.$list.css('left', newListOffsetLeft).addClass(css.listDisplaced)
      this.$arrow.appendTo(this.$list)
      this.$arrow.css('left', listOffsetLeft - newListOffsetLeft + Math.floor(this.props.toggleWidth / 2) - Math.floor(this.$arrow.width() / 2))
    } else {
      if (listOuterWidth > this.props.toggleWidth) {
        this.$list.addClass(css.listBigger)
      } else {
        this.$list.outerWidth(this.props.toggleWidth)
      }
    }
  }

  scrollToActiveOption() {
    if (!this.activeOptionEl) {
      return
    }
    const $active = $(this.activeOptionEl)
    const activePositionTop = $active.position().top

    if (activePositionTop + $active.outerHeight() > this.$ul.scrollTop()) {
      this.$ul.scrollTop(activePositionTop)
    }
  }

  renderFilter() {
    if (this.props.filter) {
      return (
        <Filter onChange={this.props.onFilter} loupe={true}/>
      )
    }
  }

  getClassName(option) {
    if (option.isHidden) return css.liHidden
    if (option.isDisabled) return css.liDisabled

    return this.isActive(option) ? css.liActive : css.li
  }

  renderPostfix(postfix) {
    if (postfix) {
      return (
        <div className={css.optionPostfix}>
          {postfix}
        </div>
      )
    }
  }

  renderCheckedIcon(option) {
    if (this.isActive(option)) {
      return <Icon name="check"/>
    }
  }

  isActive(option) {
    return option.value === this.props.selected
  }

  renderOption(option, optionIndex, className) {
    const { optionsPostfix, onOptionClick } = this.props
    const isActiveOption = this.isActive(option),
          liCellClassName = isActiveOption ? css.liCellActive : css.liCell


    return (
      <li
        className={className}
        key={optionIndex}
        onClick={option.isDisabled ? null : onOptionClick.bind(this, option)}
        ref={(el) => isActiveOption && (this.activeOptionEl = el)}
      >
        <div className={liCellClassName}>{option.label}</div>
        <div className={css.iconCheck}>
          {this.renderCheckedIcon(option)}
        </div>
        {this.renderPostfix(optionsPostfix)}
      </li>
    )
  }

  renderOptions() {
    const { options } = this.props

    if (!options.length) return

    return (
      <ul className={css.ul} ref = { (el) => { this.$ul = $(el) }}>
        {options.map((option, optionIndex) => {
          return this.renderOption(option, optionIndex, this.getClassName(option))
        })}
      </ul>
    )
  }

  render() {
    const { variant = 'base' } = this.props

    const styles = {
      top: this.props.top + this.props.toggleHeight,
      left: this.props.left
    };

    return (
      <div className={css[variant] || css.base} style={styles}>
        {this.renderFilter()}
        {this.renderOptions()}
      </div>
    )
  }
}
