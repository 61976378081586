export const DEFAULT_LANDING_PAGE_URL = "/brokerage";
export const LOGIN_URL = "/users/sign_in";
export const LIST_MLSES_URL = "/auth/external/list_mls";
export const SSO_LOGIN_URL = "/api/v2/start_sso";
export const MAPBOX_OPTIMIZE_URL =
  "https://api.mapbox.com/optimized-trips/v1/mapbox/driving/";
export const MAPBOX_DIRECTIONS_URL =
  "https://api.mapbox.com/directions/v5/mapbox/driving/";
export const MAPBOX_MATRIX_URL =
  "https://api.mapbox.com/directions-matrix/v1/mapbox/driving/";
export const GOOGLE_MAPS_SEARCH_URL =
  "https://www.google.com/maps/search/?api=1&query=";
export const MAPBOX_PLACES_URL =
  "https://api.mapbox.com/geocoding/v5/mapbox.places/";
