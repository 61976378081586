import { combineReducers } from 'redux'

import listReducer from './list'
import singleReducer from './single'
import categoriesReducer from './categories'
import statusesReducer from './statuses'
import agentsReducer from './agents'

export default combineReducers({
  list: listReducer,
  single: singleReducer,
  categories: categoriesReducer,
  statuses: statusesReducer,
  agents: agentsReducer
})
