import css from './Controls.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
export default class Controls extends Component {
  static propTypes = {
    variant: PropTypes.string,
    modifier: PropTypes.string,
    children: PropTypes.node
  }

  render () {
    const { variant = 'base', modifier } = this.props

    return (
      <div className={composeClassName(css, variant, modifier)}>
        {this.props.children}
      </div>
    )
  }
}
