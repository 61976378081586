import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from "react-router-dom";
import { fetchListing } from '~brokerage/actions/listings'
import Content from '~brokerage/components/shared/Content'
import NoMatch from '~brokerage/components/shared/NoMatch'
import Loader from '~brokerage/components/shared/Loader'
import Summary from '~brokerage/components/listings/Summary'
import Details from './details'
import Edit from './edit'

class Single extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    listing: PropTypes.object,
    isFetching: PropTypes.bool,
    listingsStatus: PropTypes.string,
    errors: PropTypes.bool,
    children: PropTypes.node,
    match: PropTypes.object,
    dispatch: PropTypes.func
  }

  static defaultProps = {
    isFetching: true
  }

  componentWillMount() {
    this.props.dispatch(fetchListing(this.props.match.params.listingId))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.listing && !nextProps.listing.editableByCurrentUser) {
      return this.props.history.push('/listings')
    }
    if (this.props.match.params.listingId !== nextProps.match.params.listingId) {
      this.props.dispatch(fetchListing(nextProps.match.params.listingId))
    }
  }

  render() {
    const { listing, errors, isFetching, listingsStatus } = this.props

    if (isFetching) return <Loader active />
    if (errors) return <NoMatch notify={false} />

    return (
      <div>
        <Summary
          id={listing.id}
          address={listing.address}
          price={listing.price}
          specs={listing.specs}
          mls={listing.mls}
          status={listing.status}
          photoUrl={listing.photoUrl}
          listingsStatus={listingsStatus}
          platformName={listing.platformName}
          contingencyString={listing.contingencyString}
        />
        <Content header="summary">
          <Switch>
            <Route path={`${this.props.match.path}/edit`} exact={true} component={Edit} />
            <Route path={`${this.props.match.path}`} exact={true} component={Details} />
          </Switch>
        </Content>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { entity: listing, isFetching, errors } = state.listings.single.listing
  const { name: listingsStatus } = state.listings.statuses.current

  return {
    listing,
    listingsStatus,
    isFetching,
    errors
  }
}
export default connect(mapStateToProps)(Single)
