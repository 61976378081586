import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import InstructionsList from "../InstructionsList";
import { buildNames } from "./utils";
import { plural } from "~brokerage/libs/helpers/FormatHelper";

const Preapproved = ({
  message,
  bubbleOptions,
  showBuyersInApprovalMsg,
  buyers = []
}) => {
  const {
    isInstant,
    senderName,
    instructions,
    explanation,
    isPreapproved,
    requestDate,
    requestStartTime,
    requestEndTime
  } = message;

  const messageAction = isPreapproved ? "Pre-approved" : "Approved";
  const buyerNames = buildNames(buyers).join(", ");
  const renderBuyerNames = buyers.length > 0 && showBuyersInApprovalMsg;

  return (
    <Bubble variant="green" {...bubbleOptions}>
      <p className={css.p}>
        {isInstant && [<b>Instant Approve:</b>, <br />]}
        {senderName} <b>{messageAction}</b> the showing request on{" "}
        <b className={css.nobr}>{requestDate}</b> from{" "}
        <b className={css.nobr}>
          {requestStartTime} to {requestEndTime}
        </b>
        .
      </p>
      {explanation && <p className={css.p}>{explanation}</p>}
      {renderBuyerNames && (
        <div className={css.buyerNames}>
          <span>
            <b>{`Buyer Name${plural(buyers.length)}:`}</b>
          </span>

          <span className={css.names}>{buyerNames}</span>
        </div>
      )}
      <InstructionsList instructions={instructions} />
    </Bubble>
  );
};

Preapproved.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Preapproved;
