import { CALL_API } from '~brokerage/middlewares/api'
import { STATUS_APPROVED, STATUS_DECLINED } from '~brokerage/constants/showings/statuses'

export function fetchAvailableRoles(types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'showing/roles'
    }
  }
}

export function fetchShowingsCounts(filter, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: 'showing/counts',
      params: {
        role: filter.role || '',
        user_id: filter.agentId || ''
      }
    }
  }
}

export function declineShowing(showingId, payload, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      endpoint: `showings/${showingId}`,
      data: {
        showing_response: payload,
        status: STATUS_DECLINED
      }
    }
  }
}

export function approveShowing(showingId, payload, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      endpoint: `showings/${showingId}`,
      data: {
        showing_response: payload,
        status: STATUS_APPROVED
      }
    }
  }
}

export function cancelShowing(showingId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showingId}/cancel`,
      actionParams: {id: showingId}
    }
  }
}

export function fetchFeedbackQuestions(showingId, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `showings/${showingId}/survey`
    }
  }
}

export function createFeedbackAnswer(showingId, questionId, answerIndex, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showingId}/survey`,
      data: { question_id: questionId, answer_index: answerIndex }
    }
  }
}

export function unhideFeedback(showingId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: `showings/${showingId}/survey/unhide`
    }
  }
}

export function hideFeedback(showingId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'delete',
      endpoint: `showings/${showingId}/survey`
    }
  }
}
