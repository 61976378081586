import css from './Avatar.sass'
import React from 'react'
import PropTypes from 'prop-types'
import Initials from '~brokerage/components/shared/Initials'

export default class Avatar extends React.PureComponent {
  static propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
    name: PropTypes.string
  }

  render() {
    const { size = '20', src, name } = this.props

    if (src) {
      const style = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${src || '/assets/tmp/agent4.png'})`
      }

      return <div className={css.base} style={style}/>
    } else if (name) {
      return <Initials name={name} size={size}/>
    }

    return false
  }
}
