import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "~brokerage/libs/bugsnag";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import {
  setCurrentUser,
  showLandingFooter,
  hideLandingFooter,
  setMapboxKey
} from "~brokerage/actions/common";
import { setInitialPreferences } from "~brokerage/actions/myAccount";
import PubNub from "pubnub";
import PubNubEventDispatcher from "~brokerage/app/helpers/pubnub-event-dispatcher";
import LandingFooter from "~brokerage/components/shared/LandingFooter";
import MainNavigation from "~brokerage/components/shared/MainNavigation";
import Modals from "~brokerage/components/modals";
import { timezone } from "~brokerage/libs/helpers/TimeHelper";
import NoMatch from "brokerage/components/shared/NoMatch";

import Messages from "./messages";
import Showings from "./showings";
import ShowingEditRequestedTime from "./showings/edit-requested-time";
import Listings from "./listings";
import People from "./people";
import Assistants from "./people/assistants";
import Customers from "./people/customers";
import Settings from "./settings";
import Alerts from "./alerts";
import Search from "./search";
import Routes from "./routes";
import ShowingRoutes from "brokerage/app/pages/myShowings";
import NotificationRoutes from "brokerage/app/pages/notifications";
import ReportRoutes from "brokerage/app/pages/reports";
import Calendar from "brokerage/app/pages/calendar";
import Support from "brokerage/app/pages/support";
import moment from "moment";
import TermsAndConditions from "brokerage/components/shared/TermsAndConditions";
import { datadogRum } from "@datadog/browser-rum";

const META_TAG_WITH_CREDENTIALS_SELECTOR = "meta[name=pubnub_subscribe_key]";

class Pages extends React.PureComponent {
  static contextTypes = {
    currentUser: PropTypes.object,
    clientData: PropTypes.object
  };

  static childContextTypes = {
    location: PropTypes.object
  };

  static propTypes = {
    location: PropTypes.object,
    isLandingFooterShown: PropTypes.bool,
    isRoutesEnabled: PropTypes.bool,
    termsAccepted: PropTypes.bool,
    isTopBarShown: PropTypes.bool,
    children: PropTypes.node,
    dispatch: PropTypes.func
  };

  state = {
    location: this.props.location
  };

  constructor(props) {
    super(props);
    this.pubnubEventDispatcher = new PubNubEventDispatcher(props);
  }

  getChildContext() {
    return {
      location: this.props.location
    };
  }

  componentWillMount() {
    const user = this.context.currentUser;
    const preferences = this.context.clientData.preferences;
    const apiKey = this.context.clientData.mapboxAPIKey;
    this.props.dispatch(setCurrentUser(user));
    this.props.dispatch(setInitialPreferences(preferences));
    this.props.dispatch(setMapboxKey(apiKey));
    timezone(user.timezone);
    moment.tz.setDefault(user.timezone);

    if (this.context.clientData.fromLanding) {
      this.props.dispatch(showLandingFooter());
    }

    this.initializePubNub();
    this.initializeBugsnag(user);
    this.initializeDataDog();
  }

  componentWillReceiveProps(nextProps) {
    this.pubnubEventDispatcher.updateProps(nextProps);
    if (
      this.props.isLandingFooterShown &&
      nextProps.location.action === "PUSH"
    ) {
      this.props.dispatch(hideLandingFooter());
    }
  }

  initializePubNub() {
    this.pubnub = new PubNub({
      subscribe_key: document.querySelector(META_TAG_WITH_CREDENTIALS_SELECTOR)
        .content,
      ssl: document.location.protocol.startsWith("https")
    });

    this.pubnub.subscribe({
      channel: this.context.clientData.commonChannel,
      message: message => this.pubnubEventDispatcher.dispatch(message)
    });
  }

  initializeBugsnag(user) {
    if (!window.Bugsnag) return;

    const { id, name, email } = user;
    window.Bugsnag.user = { id, name, email };
  }

  initializeDataDog() {
    const applicationId = this.context.clientData.datadogRumApplicationId;
    const clientToken = this.context.clientData.datadogRumClientToken;
    const env = this.context.clientData.datadogEnv;
    const rumServiceName = this.context.clientData.datadogRumServiceName;
    if (env === "development" || env === "staging" || env === "qa") return;

    datadogRum.init({
      applicationId: applicationId,
      clientToken: clientToken,
      site: "datadoghq.com",
      service: rumServiceName,

      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 50,
      sessionReplaySampleRate: 5,
      trackUserInteractions: true,
      defaultPrivacyLevel: "mask-user-input"
    });

    datadogRum.startSessionReplayRecording();
  }

  render() {
    const {
      termsAccepted,
      isTopBarShown: hasTopBar,
      isLandingFooterShown: hasLandingFooter,
      isRoutesEnabled,
      location,
      isContactsEnabled,
      isAddShowingEnabled,
      isGuestLogin
    } = this.props;

    return (
      <div
        className={composeClassName(css, "base", {
          hasLandingFooter,
          hasTopBar
        })}
      >
        <ToastContainer
          position="bottom-center"
          pauseOnFocusLoss
          pauseOnHover
          draggable
        />

        <MainNavigation location={location} />

        <div className={css.content}>
          <Switch>
            {isGuestLogin ? <Route path="/" component={Search} exact={true} /> : <Route path="/" component={Calendar} exact={true} />}
            <Route path="/calendar" component={Calendar} />
            <Route
              path="/messages/:conversationType/:id?"
              exact={true}
              component={Messages}
            />
            <Route path="/messages" exact={true} component={Messages} />
            <Route
              path="/showings/:showingId/edit_requested_time"
              exact={true}
              component={ShowingEditRequestedTime}
            />
            <Route path="/showings/:showingId?" component={Showings} />
            <Route path="/listings" component={Listings} />
            {isContactsEnabled && <Route path="/people" component={People} />}
            <Route path="/settings" component={Settings} />
            <Route path="/alerts" component={Alerts} />
            {isAddShowingEnabled && <Route path="/search" component={Search} />}
            <Route path="/routes" component={Routes} />
            {isRoutesEnabled && (
              <Route path="/my_showings" component={ShowingRoutes} />
            )}
            <Route path="/notifications" component={NotificationRoutes} />
            <Route path="/reports" component={ReportRoutes} />
            <Route path="/support" component={Support} />
            <Route path="*" component={NoMatch} />
          </Switch>
        </div>
        <TermsAndConditions alreadyAccepted={termsAccepted} />
        <Modals />
        {hasLandingFooter && <LandingFooter />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isBannerShown: isPushNotificationsBannerShown } =
    state.settings.pushNotifications;

  const {
    isRoutesEnabled,
    termsAccepted,
    isContactsEnabled,
    isAddShowingEnabled,
    isGuestLogin
  } = state.currentUser;

  return {
    isLandingFooterShown: state.isLandingFooterShown,
    isTopBarShown: isPushNotificationsBannerShown,
    isRoutesEnabled,
    termsAccepted,
    isContactsEnabled,
    isAddShowingEnabled,
    isGuestLogin
  };
}
export default connect(mapStateToProps)(Pages);
