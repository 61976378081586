import React, { useState } from "react";
import css from "./index.sass";
import PencilFillIcon from "remixicon-react/PencilFillIcon";
import BroadcastFillIcon from "remixicon-react/BroadcastFillIcon";
import Download2FillIcon from "remixicon-react/Download2FillIcon";
import FeedbackFillIcon from "remixicon-react/FeedbackFillIcon";
import FileChartFillIcon from "remixicon-react/FileChartFillIcon";
import Tooltip from "~brokerage/components/shared/Tooltip";
import SurveyFeedbackModal from "~brokerage/components/shared/SurveyFeedbackModal";
import ActivityReportDownloadModal from "~brokerage/components/shared/ActivityReportDownloadModal";

const Actions = ({
  canEdit,
  onEditClick,
  onBroadCastClick,
  showFeedback,
  listing: {
    address,
    mlsNumber,
    feedback,
    broadcastMessageCount,
    isBuyersReportAvailable,
    id
  },
  showings: { approvedCount, unlimitedBroadcastMessagesAllowed },
  dispatch
}) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [
    isDownloadActivityReportModalOpen,
    setIsDownloadActivityReportModalIsOpen
  ] = useState(false);

  const openFeedbackModal = event => {
    event.stopPropagation();
    setIsFeedbackModalOpen(true);
  };

  const showBroadCast =
    (unlimitedBroadcastMessagesAllowed || broadcastMessageCount === 0) &&
    approvedCount > 0;

  const openReport = () => {
    window.open(
      `/my_listings/${id}/buyers_report.pdf`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  if (canEdit)
    return (
      <div className={css.actions}>
        <div className={css.iconButton} onClick={onEditClick}>
          <Tooltip text="Edit listing setup" direction="up">
            <PencilFillIcon size={17} className={css.icon} />
          </Tooltip>
        </div>
        {showBroadCast && (
          <div className={css.iconButton} onClick={onBroadCastClick}>
            <Tooltip
              text="Broadcast a message to all showing-side appointment agents"
              direction="up"
            >
              <BroadcastFillIcon size={17} className={css.icon} />
            </Tooltip>
          </div>
        )}
        {isBuyersReportAvailable && (
          <div className={css.iconButton}>
            <Tooltip text="Download Buyer Report" direction="up">
              <Download2FillIcon
                size={17}
                className={css.icon}
                onClick={openReport}
              />
            </Tooltip>
          </div>
        )}
        {showFeedback && (
          <div className={css.iconButton} onClick={e => e.stopPropagation()}>
            {isFeedbackModalOpen && (
              <SurveyFeedbackModal
                isModalOpen={isFeedbackModalOpen}
                setIsModalOpen={setIsFeedbackModalOpen}
                listingAddress={address}
                mlsNumber={mlsNumber}
                feedbackCount={feedback.total}
                isEditable={canEdit}
              />
            )}
            <Tooltip text="View Feedback" direction="up">
              <FeedbackFillIcon
                size={17}
                onClick={openFeedbackModal}
                className={css.icon}
              />
            </Tooltip>
          </div>
        )}
        <div className={css.iconButton} onClick={e => e.stopPropagation()}>
          <ActivityReportDownloadModal
            isOpen={isDownloadActivityReportModalOpen}
            onClose={() => setIsDownloadActivityReportModalIsOpen(false)}
            dispatch={dispatch}
            listingId={id}
          />
          <Tooltip text="Download Activity Report" direction="up">
            <FileChartFillIcon
              size={17}
              className={css.icon}
              onClick={() => setIsDownloadActivityReportModalIsOpen(true)}
            />
          </Tooltip>
        </div>
      </div>
    );
  else return null;
};

export default Actions;
