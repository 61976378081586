import {
  BROKERAGE_SETTINGS_SHOWING_COORDINATORS_REQUEST,
  BROKERAGE_SETTINGS_SHOWING_COORDINATORS_SUCCESS,
  BROKERAGE_SETTINGS_SHOWING_COORDINATORS_FAILURE,
  BROKERAGE_SETTINGS_REMOVE_SHOWING_COORDINATOR_SUCCESS,
  BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST,
  BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS,
  BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE
} from '~brokerage/constants/actionTypes'
import keyBy from 'lodash/keyBy'

const defaultState = {
  isFetching: false,
  showingCoordinatorEnabled: false,
  coordinators: {}
}

export default function showingCoordinatorsReducer(state = defaultState, action) {
  switch (action.type) {
    case BROKERAGE_SETTINGS_SHOWING_COORDINATORS_REQUEST:
      return { ...state, isFetching: true }
    case BROKERAGE_SETTINGS_SHOWING_COORDINATORS_SUCCESS:
      return {
        ...state,
        coordinators: keyBy(action.data.showingCoordinators, 'id'),
        showingCoordinatorEnabled: action.data.showingCoordinatorEnabled,
        isFetching: false
      }
    case BROKERAGE_SETTINGS_SHOWING_COORDINATORS_FAILURE:
      return { ...state, errors: action.errors }
    case BROKERAGE_SETTINGS_REMOVE_SHOWING_COORDINATOR_SUCCESS:
      return { ...state }
    case BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST:
      return { ...state }
    case BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS:
      return {
        ...state,
        showingCoordinatorEnabled: action.data.showing_coordinator_enabled
      }
    case BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE:
      return { ...state, errors: action.errors }
    default:
      return state
  }
}
