import css from './showing-coordinator.sass'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchAssistants, removeInvitedBrokerageUser } from '~brokerage/actions/settings'
import {
  addRecipientToNewMessageRecipients,
  clearNewMessageRecipients
} from '~brokerage/actions/messages'
import Avatar from '~brokerage/components/shared/Avatar'
import Button from '~brokerage/components/shared/Button'
import IconedLabel from '~brokerage/components/shared/IconedLabel'
import Loader from '~brokerage/components/shared/Loader'
import { format as formatPhone } from '~brokerage/libs/helpers/PhonesHelper'
import { plural } from '~brokerage/libs/helpers/FormatHelper'
import _values from 'lodash/values'

export class Assistants extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
    assistants: PropTypes.object
  }

  componentWillMount() {
    this.props.dispatch(fetchAssistants())
  }

  handleNewMessageButtonClick = (event, assistant) => {
    const { location, history, dispatch } = this.props

    dispatch(clearNewMessageRecipients())
    dispatch(addRecipientToNewMessageRecipients(assistant))

    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: 'new_message' }
    })

    event.stopPropagation()
  }

  editAssistant = (id) => {
    const { location, history } = this.props
    history.push({...location, query: { modal: 'invite_user', user_id: id } })
  }

  removeAssistant = (id) => {
    this.props.dispatch(removeInvitedBrokerageUser(id))
      .then(() => this.props.dispatch(fetchAssistants()))
  }

  renderAssistantsSection() {
    let assistants = _values(this.props.assistants)
    let assistantsLength = assistants.length

    if(!assistantsLength) return

    return(
      <div className={css.tableWrapper}>
        <div className={css.tableHeader}>
          { assistantsLength } Brokerage Assistant{plural(assistantsLength)}
        </div>
        <table className={css.table}>
          <tbody>
            {
              assistants.map(assistant => {
                const assistantName = [assistant.firstName, assistant.lastName].join(' ')
                return(
                  <tr key={assistant.id} className={css.tr}>
                    <td className={css.td}>
                      <div className={css.avatar}>
                        <Avatar size="30" src={assistant.photoUrl} name={assistantName}/>
                      </div>
                      <div className={css.name}>{assistantName}</div>
                    </td>
                    <td className={css.td}>{assistant.email}</td>
                    <td className={css.td}>{assistant.phone ? formatPhone(assistant.phone) : '-'}</td>
                    <td className={css.td} width="95px">
                      <Button variant="grayText" onClick={() => this.editAssistant(assistant.id)}>
                        <IconedLabel icon="edit" label="Edit"/>
                      </Button>
                    </td>
                    <td className={css.td} width="120px">
                      <Button variant="grayText" onClick={() => this.removeAssistant(assistant.id)}>
                        <IconedLabel icon="close" label="Remove"/>
                      </Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    if(this.props.isFetching) return <Loader active/>

    return (
      <div className={css.base}>
        { this.renderAssistantsSection() }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isFetching, assistants } = state.settings.assistants

  return { isFetching, assistants }
}
export default connect(mapStateToProps)(Assistants)
