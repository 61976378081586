import css from "./InstructionsList.sass";
import React from "react";

const AccompanyText = ({ changed, willAccompany, role }) => {
  const Tag = willAccompany ? "span" : "s";
  return (
    changed && (
      <li className={css.li}>
        <Tag>
          The <b>{role}</b> will accompany your Showing
        </Tag>
      </li>
    )
  );
};

const lockboxTypes = {
  combo: "Combination Lockbox",
  electronic: "Electronic Lockbox"
};

const explanationList = {
  time_unavailable: "Requested Time Unavailable",
  unavailable_for_showing: "Property Unavailable For Showing",
  property_sold: "Property Sold",
  property_under_contract: "Property Under Contract",
  seller_unavailable: "Seller Unavailable To Attend",
  listing_agent_unavailable: "Listing Agent Unavailable To Attend",
  client_availability_changed: "Client Availability Changed",
  my_availability_changed: "Showing Team Availability Changed",
  client_not_interested: "Client No Longer Interested",
  scheduled_in_error: "Scheduled In Error"
};

const LockBoxText = ({
  hasLockboxKindChanged,
  lockboxCode,
  lockboxKind,
  lockboxKindChangedFrom,
  lockboxBrand
}) => {
  const oldLockbox = lockboxTypes[lockboxKindChangedFrom];
  const newLockbox = lockboxTypes[lockboxKind];
  return (
    hasLockboxKindChanged && (
      <li className={css.li}>
        <s>{oldLockbox}</s> <span>{newLockbox}</span>{" "}
        {lockboxKind === "combo" && (
          <>
            {" : "}
            <b>{lockboxCode}</b>
          </>
        )}
        {lockboxKind === "electronic" && (
          <>
            {lockboxBrand ? ": " : ""}
            <b>{lockboxBrand}</b>
          </>
        )}
      </li>
    )
  );
};

const InstructionsList = ({
  explanation,
  instructions: {
    hasListingAgentAccompanyChanged,
    willListingAgentAccompany,
    hasTenantAccompanyChanged,
    willTenantAccompany,
    hasMessageChanged,
    hasExplanationChanged,
    message,
    hasLockboxKindChanged,
    lockboxCode,
    lockboxKind,
    lockboxKindChangedFrom,
    lockboxBrand
  }
}) => {
  const hasInstructionChanges =
    hasListingAgentAccompanyChanged ||
    hasTenantAccompanyChanged ||
    hasMessageChanged ||
    hasExplanationChanged ||
    hasLockboxKindChanged;

  return (
    hasInstructionChanges && (
      <div className={css.base}>
        <div className={css.title}>Showing Instructions :</div>

        <ul className={css.ul}>
          <AccompanyText
            changed={hasListingAgentAccompanyChanged}
            willAccompany={willListingAgentAccompany}
            role="Listing Agent"
          />
          <AccompanyText
            changed={hasTenantAccompanyChanged}
            willAccompany={willTenantAccompany}
            role="Seller / Tenant"
          />
          <LockBoxText
            hasLockboxKindChanged={hasLockboxKindChanged}
            lockboxCode={lockboxCode}
            lockboxKind={lockboxKind}
            lockboxKindChangedFrom={lockboxKindChangedFrom}
            lockboxBrand={lockboxBrand}
          />
          {hasMessageChanged && (
            <li className={css.li}>
              <b>Additional Instructions:</b> {message}
            </li>
          )}
          {hasExplanationChanged && (
            <li className={css.li}>
              <b>Explanation:</b> {explanationList[explanation]}
            </li>
          )}
        </ul>
      </div>
    )
  );
};

export default InstructionsList;
