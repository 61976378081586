import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SettingsSideBar from "~brokerage/components/settings/SettingsSideBar";
import SettingsPane from "~brokerage/components/settings/SettingsPane";
import SettingsContent from "~brokerage/components/settings/SettingsContent";
import { SettingsContext } from "~brokerage/app/pages/settings/context/SettingsContext";

import Preferences from "./Preferences";
import SelectSystemPage from "./SelectSystemPage";
import DefaultShowingSettings from "~brokerage/components/settings/components/DefaultShowingSettings";
import DefaultDateAndTimeRestrictions from "./Preferences/DefaultDateAndTimeRestrictions";

const System = ({ match }) => {
  const { dispatchPageState } = useContext(SettingsContext);

  useEffect(() => {
    dispatchPageState({ type: "setActivePane", payload: "system" });
  }, []);

  return (
    <SettingsPane>
      <SettingsSideBar />
      <SettingsContent>
        <Switch>
          <Route
            path={`${match.path}/default_time_and_date_restrictions`}
            exact={true}
            component={DefaultDateAndTimeRestrictions}
          />
          <Route
            path={`${match.path}/team_settings`}
            exact={true}
            component={Preferences}
          />
          <Route
            path={`${match.path}/edit_default_showing_settings`}
            exact={true}
            component={DefaultShowingSettings}
          />
          <Route
            path={`${match.path}/opt_out_of_aligned_showings`}
            exact={true}
            component={Preferences}
          />
          <Route path={`${match.path}`} component={SelectSystemPage} />
        </Switch>
      </SettingsContent>
    </SettingsPane>
  );
};

export default System;
