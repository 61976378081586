import React from "react";
import css from "brokerage/app/components/myShowings/RouteListingImagesCollage.sass";
import PropertyThumbnail from "~brokerage/app/components/shared/PropertyThumbanil.jsx";
import PropTypes from "prop-types";

const RouteListingImagesCollage = ({ route: { showings } }) => {
  const listingImages = showings.map(s => s.listing_image);

  const DisplayListingImage = Image => {
    const { image } = Image;

    if (image) {
      return <PropertyThumbnail image={image} className={css.listingImage} />;
    }

    return <div className={css.emptyImage} />;
  };

  return (
    <div className={css.imageCollage}>
      <div className={css.imageRow}>
        <DisplayListingImage image={listingImages[0]} />
        <DisplayListingImage image={listingImages[1]} />
        <DisplayListingImage image={listingImages[2]} />
      </div>
      <div className={css.imageRow}>
        <DisplayListingImage image={listingImages[3]} />
        <DisplayListingImage image={listingImages[4]} />
        <DisplayListingImage image={listingImages[5]} />
      </div>
    </div>
  );
};

RouteListingImagesCollage.propTypes = {
  route: PropTypes.object.isRequired
};

export default RouteListingImagesCollage;
