import React from "react";
import { isEmpty } from "lodash";
import Button from "~brokerage/components/shared/Button";
import { Label } from "~brokerage/components/shared/Form";
import RestrictedBadge from "./RestrictedBadge";
import classNames from "classnames";

import css from "./index.sass";

const AppointmentList = ({
  appointments,
  unavailableListings,
  advanceNotices,
  mlsBuyerRequiredEnabled,
  buyers,
  handleRemoveListing
}) => {
  const showEmpty = isEmpty(appointments);

  return (
    <>
      <Label variant="vertical">Appointments:</Label>
      {showEmpty ? (
        <div className={css.noAppointmentsText}>No Appointments Added</div>
      ) : (
        <div>
          {appointments.map(({ id, buyerNameRequired, address }) => {
            const fullDayRestriction = unavailableListings.includes(id);
            const advanceNotice = advanceNotices[id];
            const requireBuyer =
              mlsBuyerRequiredEnabled && buyerNameRequired && isEmpty(buyers);
            const showReasonText =
              fullDayRestriction || advanceNotice || requireBuyer;

            return (
              <div
                className={classNames(css.appointment, {
                  [css.restrictedAppointment]: showReasonText
                })}
                key={id}
              >
                <div className={css.address}>
                  {address}
                  {showReasonText && (
                    <div className={css.restricted}>
                      Remove the selected listing or adjust its scheduled date
                      and time
                    </div>
                  )}
                  <div className={css.restrictedBadgeWrapper}>
                    {fullDayRestriction && (
                      <RestrictedBadge
                        tooltipText="Full Day Restriction: This listing has a full day restriction, and cannot be
            included in the route."
                        badgeVariant="orange"
                        badgeLabel="FDR"
                      />
                    )}
                    {advanceNotice && (
                      <RestrictedBadge
                        tooltipText="Advance Notice: To proceed, it is essential to either remove the listing or adjust its scheduled time, as the selected time falls within the advance notice period."
                        badgeVariant="orange"
                        badgeLabel="AN"
                      />
                    )}
                    {requireBuyer && (
                      <RestrictedBadge
                        tooltipText="Buyer Required: To proceed, it is essential to either remove the listing or add at least a single buyer"
                        badgeVariant="orange"
                        badgeLabel="BR"
                      />
                    )}
                  </div>
                </div>
                <Button
                  style={{ backgroundColor: "white" }}
                  onClick={() => handleRemoveListing(id)}
                  variant="outline"
                >
                  Remove
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AppointmentList;
