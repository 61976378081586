import React from 'react';
import css from './SettingsContent.sass'

const SettingsContent = ({ children, ...rest }) => (
  <div className={css.paneContents} {...rest}>
    {children}
  </div>
)

export default SettingsContent;
