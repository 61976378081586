import css from "./NewMessageButton.sass";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";

class NewMessageButton extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object
  };

  linkObject() {
    return {
      pathname: this.props.location.pathname,
      query: { ...this.props.location.query, modal: "new_message" }
    };
  }

  render() {
    return (
      <div className={css.newMessage}>
        <Button variant="green" to={this.linkObject()}>
          <i className={css.icon}>
            <Icon name="message" />
          </i>
          <span className={css.newMessageButtonText}>New Message</span>
        </Button>
      </div>
    );
  }
}
export default connect()(NewMessageButton);
