import React from "react";
import css from "./Stop.sass";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import omit from "lodash/omit";

import TravelTime from "./TravelTime";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import Marker from "./Marker";
import { MARKER_COLORS } from "~brokerage/constants/routes/colors";
import { ALPHABET } from "~brokerage/constants/routes/letters";
import SmartModalToggle from "~brokerage/components/modals/SmartModalToggle";
import ModalConfirmation from "~brokerage/components/modals/ModalConfirmation";
import {
  updateShowingRoute,
  removeRouteUnsaved
} from "~brokerage/actions/routes";
import { callApi } from "~brokerage/middlewares/api";

const Stop = ({ details, index, routeId, dispatch, unsavedChanges }) => {
  const history = useHistory();
  const location = useLocation();
  const { travel_time, address, start_time, duration, status, unique_id, id } =
    details;
  const startTime = inUsersTimezone(start_time).format("hh:mm A");
  const endTime = inUsersTimezone(start_time)
    .add(duration, "minutes")
    .format("h:mm A");
  const timing = `${startTime} - ${endTime}`;
  const fillColor = MARKER_COLORS[status];
  const mapLabel = ALPHABET[index];

  const removeStopFromRoute = () => {
    if (id < 0) {
      dispatch(removeRouteUnsaved());
      resetModal();
    } else {
      const params = { stop_id: id };
      callApi(`routes/${routeId}/remove_stop`, {}, params, "post")
        .then(response => {
          toast.success("Stop removed successfully.");
          dispatch(updateShowingRoute(response.data.data.route));
          dispatch(removeRouteUnsaved());
        })
        .catch(error => {
          console.log(error);
          toast.error(error.response.data.error);
        })
        .finally(() => {
          resetModal();
        });
    }
  };

  const resetModal = () => {
    history.replace({
      ...location,
      query: omit(location.query, ["modal"])
    });
  };

  return (
    <div className={css.stop}>
      {travel_time > 0 && <TravelTime travel_time={travel_time} />}
      <div className={css.content}>
        <div className={css.timingLine}>
          <p>
            {timing}
            {` (${duration} minutes)`}
          </p>
          <p className={css.statusLabel}>Stop</p>
        </div>
        <div className={css.addressLine}>
          <div className={css.marker}>
            <Marker fill={fillColor} label={mapLabel} />
          </div>
          <p>{address}</p>
        </div>
        <div className={css.removeRow}>
          <SmartModalToggle
            variant="link"
            modifier="underline colorGray"
            modal={
              <ModalConfirmation
                heading="Remove Stop"
                text="Are you sure?"
                confirmText="Yes, Remove."
                confirmVariant="outlineRed"
                onConfirm={removeStopFromRoute}
                cancelText="No, Keep."
                warningText={
                  unsavedChanges?.length
                    ? "*Removal will clear unsaved changes from route."
                    : ""
                }
                cancelVariant="outlineBlue"
              />
            }
            name={`remove_stop_${unique_id}`}
          >
            Remove Stop
          </SmartModalToggle>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ routes }) => {
  const { entity } = routes.single.route || {};
  const { unsavedChanges } = entity || [];
  return {
    unsavedChanges
  };
};

export default connect(mapStateToProps)(Stop);
