import { CALL_API } from '~brokerage/middlewares/api'

export function createAttachment(file, types) {
  return {
    [CALL_API]: {
      types,
      method: 'post',
      endpoint: 'attachments',
      data: {
        attachment: {
          name: file.name,
          size: file.size,
          content_type: file.type
        }
      },
      actionParams: {file}
    }
  }
}

export function confirmAttachment(attachmentId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'patch',
      endpoint: `attachments/${attachmentId}`,
      actionParams: {id: attachmentId}
    }
  }
}

export function destroyAttachment(attachmentId, types) {
  return {
    [CALL_API]: {
      types,
      method: 'delete',
      endpoint: `attachments/${attachmentId}`,
      actionParams: {id: attachmentId}
    }
  }
}

export function fetchListing(mlsNumber, types) {
  return {
    [CALL_API]: {
      types,
      method: 'get',
      endpoint: 'listings/find_by_mls_number',
      params: { mls_number: mlsNumber }
    }
  }
}
