import css from './CalendarDropdown.sass'
import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { timeToRemoteWithTimeZone } from '~brokerage/libs/helpers/TimeHelper'
import Calendar from '~brokerage/components/shared/Calendar'
import Toggle from './Dropdown/Toggle'

export default class CalendarDropdown extends React.PureComponent {
  static propTypes = {
    date: PropTypes.string,
    opened: PropTypes.bool,
    onChange: PropTypes.func,
    calendarAlign: PropTypes.string,
    minDate: PropTypes.object,
  }

  state = {
    active: false
  }

  componentDidMount() {
    this.container = document.createElement('div')
    if (this.props.opened) {
      this.showCalendar()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.active && this.props.date !== prevProps.date) {
      ReactDOM.render(this.renderCalendar(), this.container)
    }
  }

  componentWillMount() {
    this.hideCalendar()
  }

  showCalendar() {
    document.body.appendChild(this.container)
    ReactDOM.render(this.renderCalendar(), this.container)

    this.setState({
      active: true
    })

    $(document).on('click.CalendarDropdown', event => {
      if (!$(event.target).closest(this.container).length && !$(event.target).closest(this.$toggle).length) {
        this.hideCalendar()
      }
    })
  }

  hideCalendar() {
    if (!this.state.active) {
      return
    }

    ReactDOM.unmountComponentAtNode(this.container)
    document.body.removeChild(this.container)

    this.setState({
      active: false
    })

    $(document).off('click.CalendarDropdown')
  }

  handleToggleClick = () => {
    this[this.state.active ? 'hideCalendar' : 'showCalendar']()
  }

  handleCalendarChange = (dates) => {
    this.props.onChange(dates)
    this.hideCalendar()
  }

  renderCalendar() {
    const toggleOffset = this.$toggle.offset();
    const style = {
      top: toggleOffset.top + this.$toggle.height()
    };
    if(this.props.calendarAlign === "right")
      style.right = 
        window.innerWidth - (toggleOffset.left + this.$toggle.width());
    else style.left = toggleOffset.left;
    

    return (
      <div className={css.calendar} style={style}>
        <Calendar
          selected={this.props.date}
          initialDate={this.props.date}
          onChange={this.handleCalendarChange}
          minDate={this.props.minDate}
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className={css.toggle} ref={el => this.$toggle = $(el)}>
          <Toggle
            variant="outline2"
            modifier={{ block: true, active: this.state.active }}
            onClick={this.handleToggleClick}
          >
            {timeToRemoteWithTimeZone(this.props.date).format('MM/DD/YYYY')}
          </Toggle>
        </div>
      </div>
    )
  }
}
