import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Row,
  Col,
  Label,
  Controls,
  TextField
} from "~brokerage/components/shared/Form";
import {
  LOCKBOX_TYPES,
  COMBO_MAX_LENGTH
} from "~brokerage/constants/lockboxTypes";
import { LOCKBOX_BRANDS } from "~brokerage/constants/lockboxBrands";
import css from "./ShowingAccess.sass";

export default class ShowingAccess extends React.PureComponent {
  static propTypes = {
    access: PropTypes.string,
    combo: PropTypes.string,
    handleChange: PropTypes.func,
    brand: PropTypes.string
  };

  renderCombo() {
    const { access, combo, handleChange } = this.props;

    if (access === "combo") {
      return (
        <Col modifier="offset10">
          <TextField
            placeholder="Type Combo"
            name="combo"
            value={combo}
            focused
            onChange={handleChange}
            maxLength={COMBO_MAX_LENGTH}
            required
          />
        </Col>
      );
    }
  }

  renderBrand = () => {
    const { access, brand, handleChange } = this.props;

    if (access === "electronic") {
      return (
        <Row class={css.showingAccess}>
          <Label valign="dropdown">Lockbox brand</Label>
          <Controls>
            <Col>
              <Dropdown
                name="brand"
                options={LOCKBOX_BRANDS}
                selected={brand}
                onChange={handleChange}
                variant="outline2"
              />
            </Col>
          </Controls>
        </Row>
      );
    }
  }

  render() {
    const { access, handleChange } = this.props;

    return (
      <div>
        <Row class={css.showingAccess}>
          <Label valign="dropdown">Lockbox access</Label>
          <Controls>
            <Col>
              <Dropdown
                name="access"
                options={LOCKBOX_TYPES}
                selected={access}
                onChange={handleChange}
                variant="outline2"
              />
            </Col>
            {this.renderCombo()}
          </Controls>
        </Row>
        {this.renderBrand()}
      </div>
    );
  }
}
