import React, { useState } from "react";
import Link from "~brokerage/components/shared/Link";
import Icon from "~brokerage/components/shared/Icon";
import { Dropdown } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";

import {
  getAppointmentStatusOptions,
  getStatus,
  getInitialStatus
} from "./utils";
import useAppointmentStatus from "./useAppointmentStatus";

import css from "../LocalDetails.sass";

const AppointmentStatus = ({
  showingStatus,
  updateDetails,
  updateLocalDetails,
  showingId
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const initialStatus = getInitialStatus(showingStatus);

  const { status, isUpdating, handleChange, handleSave } = useAppointmentStatus(
    initialStatus,
    showingId,
    () => {
      const updatedStatus = getStatus(status);
      updateLocalDetails({ status: updatedStatus });
      updateDetails({ showing_status: updatedStatus });
    }
  );

  const appointmentStatusOptions = getAppointmentStatusOptions();
  const statusChanged = status !== showingStatus;

  return (
    <div className={css.section}>
      <h3 className={css.h3}>
        APPOINTMENT STATUS{" "}
        <Link
          onClick={e => {
            e.stopPropagation();
            setIsEditing(!isEditing);
          }}
        >
          {isEditing ? (
            <>Cancel</>
          ) : (
            <>
              <Icon name="edit" /> Edit
            </>
          )}
        </Link>
      </h3>
      {isEditing && (
        <>
          <Dropdown
            name="status"
            options={appointmentStatusOptions}
            selected={status}
            onChange={handleChange}
            variant="outline2Block"
          />
          {statusChanged && (
            <Button
              variant="outlineBlue"
              style={{ display: "block", textAlign: "center" }}
              disabled={isUpdating}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentStatus;
