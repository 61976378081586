import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import config from "~brokerage/config/config";
import { processChange } from "~brokerage/libs/helpers/FormHelper";
import {
  convertDaysIndicesToDays,
  convertTimeRangesToStrings
} from "~brokerage/libs/helpers/RestrictionsHelper";
import {
  Row,
  Col,
  Controls,
  RadioSet,
  Radio
} from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Calendar from "~brokerage/components/shared/Calendar";
import Days from "./Days";
import Icon from "~brokerage/components/shared/Icon";
import Switch from "~brokerage/components/shared/Switch";
import TimeRangeList from "./TimeRangeList";
import css from "./RestrictionsAddForm.sass";

const RestrictionsAddForm = props => {
  const {
    onAdd,
    onCancel,
    currentRestriction
  } = props;

  const [id, setId] = useState(null);
  const [repetitionType, setRepetitionTye] = useState("one_time");
  const [dates, setDates] = useState([]);
  const [days, setDays] = useState([]);
  const [allDay, setAllDay] = useState(true);
  const [timeRangesToCreate, setTimeRangesToCreate] = useState([]);

  const canBeSaved = (repetitionType === "one_time" && dates.length) || (repetitionType === "weekly" && days.length) && (allDay || timeRangesToCreate.length);

  const handleChange = event => {
    const attributes = processChange(event);

    attributes.id && setId(attributes.id);
    attributes.repetitionType && setRepetitionTye(attributes.repetitionType);
    attributes.dates && setDates(attributes.dates);
    attributes.days && setDays(attributes.days);
    attributes.allDay && setAllDay(attributes.allDay);
    attributes.timeRangesToCreate && setTimeRangesToCreate(attributes.timeRangesToCreate);
  };

  const handleAllDaySwitch = () => {
    const newAllDay = !allDay;
    setAllDay(newAllDay);

    if (!newAllDay && !timeRangesToCreate.length) {
      setTimeRangesToCreate([
        {
          startAt: `${config.defaultTimeRangeStartHour}:00`,
          endAt: `${config.defaultTimeRangeEndHour}:00`
        }
      ]);
    }
  };

  const handleNewTimeRangeAdded = timeRange => {
    setTimeRangesToCreate(timeRangesToCreate.concat([timeRange]));
  };

  const handleRemoveTimeRange = timeRange => {
    setTimeRangesToCreate(
      timeRangesToCreate.filter(
        tr => tr != timeRange
      )
    );
  };

  const handleTimeRangeChange = (rangeIndex, changes) => {
    const { startAt: currentStartAt, endAt: currentEndAt } = timeRangesToCreate[rangeIndex];
    const { startAt, endAt } = changes;

    // if startAt && startAt > currentEndAt ? set endAt to 1hr after startAt
    if (startAt) {
      const startAtInt = parseInt(startAt.split(":").join(""), 10);
      const endAtInt = parseInt(currentEndAt.split(":").join(""), 10);

      // is startAt >= endAt
      if (startAtInt >= endAtInt) {
        const [hour, minutes] = startAt.split(":");
        changes.endAt = `${parseInt(hour, 10) + 1}:${minutes}`;
      }
    }
    // if endAt && endAt < currentStartAt ? set startAt to 1h before endAt
    if (endAt) {
      const startAtInt = parseInt(currentStartAt.split(":").join(""), 10);
      const endAtInt = parseInt(endAt.split(":").join(""), 10);

      // is startAt >= endAt
      if (startAtInt >= endAtInt) {
        const [hour, minutes] = endAt.split(":");
        changes.startAt = `${parseInt(hour, 10) - 1}:${minutes}`;
      }
    }

    setTimeRangesToCreate(
      timeRangesToCreate.map((e, i) => {
        return i === rangeIndex ? { ...e, ...changes } : e;
      })
    );
  };

  const handleDayToggle = (changedDay, addOrRemove) => {
    if (addOrRemove) {
      const newDays = days.concat([changedDay]).sort();
      setDays(newDays);
    } else {
      const newDays = days.filter(e => e !== changedDay).sort();
      setDays(newDays);
    }
  };

  const handleSubmit = () => {
    const restriction = {
      id: id,
      repetitionType: repetitionType,
      allDay: allDay
    };

    if (repetitionType === "weekly") {
      restriction.days = days;
    } else {
      restriction.dates = dates;
    }

    if (!allDay) {
      restriction.timeRanges = timeRangesToCreate.map(timeRange => ({
        start_at: timeRange.startAt,
        end_at: timeRange.endAt
      }));
    }

    onAdd(restriction);
  };

  useEffect(() => {
    if (currentRestriction) {
      setId(currentRestriction.id);
      setRepetitionTye(currentRestriction.repetitionType);
      setDates(currentRestriction.dates);
      setDays(convertDaysIndicesToDays(currentRestriction.days));
      setAllDay(Boolean(currentRestriction.allDay));
      setTimeRangesToCreate(convertTimeRangesToStrings(currentRestriction.timeRanges));
    }
  }, [currentRestriction]);

  return (
    <Controls
      className={css.calendar}
      variant="outlined"
      modifier="vPadding20"
    >
      <Row offset="0">
        <p className={css.p}>Select Type of Restriction</p>
        <div className={css.radioPillDates}>
          <RadioSet variant="pills">
            <Radio
              name="repetitionType"
              value="one_time"
              checked={repetitionType === "one_time"}
              onChange={handleChange}
            >
              Specific Dates
            </Radio>
            <Radio
              name="repetitionType"
              value="weekly"
              checked={repetitionType === "weekly"}
              onChange={handleChange}
            >
              Recurring
            </Radio>
          </RadioSet>
        </div>
      </Row>
      <Row offset="20">
        <Col>
          <div className={css.calendar}>
            <p className={css.p}>Select days to restrict</p>
            {repetitionType === "one_time" ? (
              <Calendar
                multiple
                name="dates"
                selected={dates}
                modifier="bordered"
                onChange={handleChange}
              />
            ) : (
              <Days days={days} onDayToggle={handleDayToggle} />
            )}
          </div>
        </Col>
        <Col className={css.allDayRestrictionSwitch} modifier="offset20">
          <p className={css.p}>Is this restriction all day?</p>
          <Switch
            name="allDay"
            checked={allDay}
            onChange={handleAllDaySwitch}
          />
          {!allDay && (
            <TimeRangeList
              timeRanges={timeRangesToCreate}
              onRangeChange={handleTimeRangeChange}
              onAdd={handleNewTimeRangeAdded}
              onRemove={handleRemoveTimeRange}
            />
          )}          
        </Col>
      </Row>
      <Row>
        <ButtonSet className={css.restrictionButtonSet}>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <div className={css.restrictionButton}>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!canBeSaved}
            >
              <Icon
                name="showingRestricted"
                modifier="offset5 valignTextDefault"
              />
              Save Time/Date Restriction
            </Button>
          </div>
        </ButtonSet>
      </Row>
    </Controls>
  );
};

RestrictionsAddForm.propTypes = {
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  currentRestriction: PropTypes.object
};

export default RestrictionsAddForm;
