import { CALL_API } from "~brokerage/middlewares/api";

export function fetchAvailableSellers(id, name, types) {
  return {
    [CALL_API]: {
      types,
      endpoint: `contacts`,
      params: { name, listing_id: id, type: "seller_tenant_involvement" }
    }
  };
}
