import css from './ListingHorizontal.sass'
import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '~brokerage/components/shared/Avatar'
import PropertySpecs from '~brokerage/components/shared/PropertySpecs'

const ListingHorizontal = (props) => {
  const { address, price, specs, mls, status, photoUrl, photoSize = '30', fullListingUrl, agents } = props

  return (
    <div>
      <div className={css[`photo${photoSize}`]}>
        <Avatar size={photoSize} src={photoUrl}/>
      </div>
      <div className={css.addressContainer}>
        <div className={css.address}>
          {(() => {
            if (fullListingUrl) {
              return <a className={css.addressLink} href={fullListingUrl}>View Listing Details</a>
            }
          })()}
          <span className={css.addressStatus}>{status}</span>
          <h1 className={css.h1}>{address}</h1>
        </div>
      </div>
      <PropertySpecs
        price={price}
        mls={mls}
        beds={specs.beds}
        baths={specs.baths}
        sqft={specs.sqft}
        agents={agents}
      />
    </div>
  )
}

ListingHorizontal.propTypes = {
  address: PropTypes.string,
  price: PropTypes.number,
  specs: PropTypes.object,
  mls: PropTypes.string,
  status: PropTypes.string,
  photoUrl: PropTypes.string,
  photoSize: PropTypes.string,
  fullListingUrl: PropTypes.string,
  agents: PropTypes.array
}
export default ListingHorizontal
