import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Dropdown } from "~brokerage/components/shared/Form";

const StatusDropdown = props => {
  const { statuses, currentStatus, handleChange } = props;

  const selectedStatus = statuses.find(
    status => status.id === currentStatus.id
  );

  const title = `Listing Status: ${
    selectedStatus ? selectedStatus.name : "All"
  }`;

  const selected = selectedStatus && selectedStatus.id;

  const statusOptions = statuses.map(status => ({
    value: status.id,
    label: status.name
  }));

  return (
    <Dropdown
      title={title}
      options={statusOptions}
      onChange={handleChange}
      variant={selected ? "header2" : "header2Transparent"}
      selected={selected}
    />
  );
};

const mapStateToProps = state => {
  const { current: currentStatus } = state.listings.statuses;

  return {
    currentStatus
  };
};

StatusDropdown.propTypes = {
  statuses: PropTypes.array,
  currentStatus: PropTypes.object,
  location: PropTypes.object
};

export default connect(mapStateToProps)(StatusDropdown);
