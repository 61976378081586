import mapValues from 'lodash/mapValues'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import isPlainObject from 'lodash/isPlainObject'

export const deepObjectKeysToSnakeCase = (obj) => {
  const deeper = (obj) => {
    if (isPlainObject(obj)) {
      return mapKeys(mapValues(obj, value => deeper(value)), (value, key) => snakeCase(key))
    } else if (Array.isArray(obj)) {
      return obj.map(x => deeper(x))
    } else {
      return obj
    }
  }

  return deeper(obj)
}

export const isNotEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      return true
    }
  }
}
