import css from './Group.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CustomPropTypes from '~brokerage/libs/CustomPropTypes'
import { composeClassName } from '~brokerage/libs/helpers/ClassNameHelper'
export default class Group extends Component {
  static propTypes = {
    variant: PropTypes.string,
    modifier: CustomPropTypes.modifier,
    children: PropTypes.node
  }

  render() {
    const { variant = 'base', modifier, children } = this.props

    return (
      <div className={composeClassName(css, variant, modifier)}>
        {React.Children.map(children, child =>
          <div className={css.child}>
            {child}
          </div>
        )}
      </div>
    )
  }
}
