import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  sendBatchTeamMembersUpdate,
  fetchShowingDetails,
  updateShowingCoordinatorEnabled
} from "~brokerage/actions/listings";
import {
  fetchTeamsOptions,
  setTeamInstructions
} from "~brokerage/actions/teams";

import {
  Form,
  Textarea,
  Row,
  Label,
  Controls
} from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import People from "./People";
// import Switch from '~brokerage/components/shared/Switch'
import PremiumButtonWrapper, {
  PREMIUM_FEATURE_TYPES
} from "~brokerage/components/shared/PremiumButtonWrapper";
import css from "~brokerage/components/listings/edit/OfficeInvolvement/index.sass";

export class OfficeInvolvement extends React.PureComponent {
  static propTypes = {
    showingDetails: PropTypes.object,
    people: PropTypes.array,
    listingId: PropTypes.string,
    teamMembersDiff: PropTypes.object,
    onNextClick: PropTypes.func,
    teamInstructions: PropTypes.string,
    isSaveDisabled: PropTypes.bool,
    showingCoordinatorEnabled: PropTypes.bool,
    showingCoordinatorEnabledForOrganization: PropTypes.bool,
    onChange: PropTypes.func,
    dispatch: PropTypes.func
  };

  state = {
    showingCoordinatorEnabled: this.props.showingCoordinatorEnabled,
    teamInstructions: this.props.teamInstructions,
    hasChanged: false
  };

  componentWillMount() {
    this.props.dispatch(fetchTeamsOptions());
    this.props.dispatch(fetchShowingDetails(this.props.listingId));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.teamInstructions !== nextProps.teamInstructions) {
      this.setState({ teamInstructions: nextProps.teamInstructions });
    }
  }

  updateHasChanged() {
    if (!this.state.hasChanged) this.setState({ hasChanged: true });
  }

  handleChange = () => {
    if (this.props.onChange) this.props.onChange();
    this.updateHasChanged();
  };

  handleTextAreaChange(event) {
    this.setState({ teamInstructions: event.target.value });
    this.updateHasChanged();
  }

  handleShowingCoordinatorEnabled = event => {
    this.setState({ showingCoordinatorEnabled: event.target.checked });
    this.updateHasChanged();
  };

  saveData = () => {
    const { create, update, destroy } = this.props.teamMembersDiff;

    const transformArray = array => {
      return array.map(({ agentId, notificationLevel, includeCalendarEvent }) => ({
        agent_id: agentId,
        notification_level: notificationLevel,
        include_calendar_event: includeCalendarEvent
      }))
    }
    const payload = {
      create: transformArray(create),
      update: transformArray(update),
      remove_ids: destroy.map(o => o.agentId),
      instructions: this.state.teamInstructions
    };

    this.props.dispatch(
      updateShowingCoordinatorEnabled(
        this.props.listingId,
        this.state.showingCoordinatorEnabled
      )
    );

    this.props.dispatch(
      sendBatchTeamMembersUpdate(this.props.listingId, payload)
    );
    this.props.dispatch(
      setTeamInstructions(this.state.teamInstructions, this.props.listingId)
    );
  };

  handleSave(callNextClick = true) {
    if (this.state.hasChanged) this.saveData();
    if (callNextClick) this.props.onNextClick();
  }

  // renderShowingCoordinatorSwitcher() {
  //   if (!this.props.showingCoordinatorEnabledForOrganization) return(
  //     `To be able to manage showing coordinator for
  //      listing please be sure showing coordinator is enabled
  //      for your organization in brokerage settings.`
  //   )
  //
  //   return(
  //     <PremiumButtonWrapper
  //       featureType={PREMIUM_FEATURE_TYPES.ENABLE_SHOWING_COORDINATOR_FOR_LISTING}
  //       featureDisabledContent={
  //         <Button variant="outline">
  //           Upgrade to manage Showing Coordinator
  //         </Button>
  //       }
  //     >
  //       <Switch
  //         name="showingCoordinatorEnabled"
  //         checked={this.state.showingCoordinatorEnabled}
  //         onChange={this.handleShowingCoordinatorEnabled}
  //       />
  //     </PremiumButtonWrapper>
  //   )
  // }

  render() {
    const { isSaveDisabled, people, listingId } = this.props;

    return (
      <Form
        onSubmit={() => {
          return false;
        }}
      >
        <Row offset="0">
          <Label>People involved in the showings for this listing</Label>
          <div className={css.peopleComponentControls}>
            <Controls>
              <People
                name="officeInvolvement"
                listingId={listingId}
                people={people}
                onChange={this.handleChange}
              />
            </Controls>
          </div>
        </Row>
        {/*<Row>*/}
        {/*<Label>*/}
        {/*Enable Showing Coordinator*/}
        {/*<small>*/}
        {/*Can create showings, and receives notifications*/}
        {/*for showing updates and messages for upcoming showings.*/}
        {/*User is removed from thread from after day of showing passes.*/}
        {/*</small>*/}
        {/*</Label>*/}
        {/*<Controls>*/}
        {/*{this.renderShowingCoordinatorSwitcher()}*/}
        {/*</Controls>*/}
        {/*</Row>*/}
        <Row>
          <Label>
            Team Instructions
            <small>
              Instructions for your team members to schedule a showing or know
              more about the listing. Only your team members will see these
              instructions
            </small>
          </Label>
          <div className={css.textArea}>
            <Controls>
              <PremiumButtonWrapper
                featureType={PREMIUM_FEATURE_TYPES.ADD_FOLLOWERS_TO_LISTING}
                featureDisabledContent={
                  <Button variant="outline">
                    Upgrade to edit Team Instructions
                  </Button>
                }
              >
                <Textarea
                  name="teamInstructions"
                  value={this.state.teamInstructions || ""}
                  placeholder="Enter Team Instructions"
                  onChange={this.handleTextAreaChange.bind(this)}
                />
              </PremiumButtonWrapper>
            </Controls>
          </div>
        </Row>
        <Row>
          <ButtonSet align="right">
            <Button
              variant="primary"
              disabled={isSaveDisabled}
              onClick={this.handleSave.bind(this)}
            >
              Done
            </Button>
          </ButtonSet>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const { diff: teamMembersDiff } = state.listings.single.teamMembers;
  const { id: listingId } = state.listings.single.listing.entity;
  const team = state.teams.options.entities.filter(
    team => team.listingId === listingId
  )[0];
  const teamInstructions = team ? team.instructions : "";
  const {
    isSaveDisabled,
    showingCoordinatorEnabled,
    showingCoordinatorEnabledForOrganization
  } = state.listings.single.showingDetails;

  return {
    isSaveDisabled,
    showingCoordinatorEnabled,
    showingCoordinatorEnabledForOrganization,
    teamMembersDiff,
    teamInstructions,
    listingId
  };
}
export default connect(mapStateToProps, null, null, { withRef: true })(
  OfficeInvolvement
);
