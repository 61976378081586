import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox } from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Modal from "react-modal";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import AccountIcon from "remixicon-react/AccountCircleFillIcon";
import Loader from "~brokerage/app/components/shared/Loader";
import Tooltip from "~brokerage/components/shared/Tooltip";
import Pagination from "./Pagination";
import { getFormattedDate } from "./utils";
import {
  SELLER_SURVEY_FEEDBACK_URL,
  SELLER_TENANT_APPROVED_TOOLTIP,
  SELLER_TENANT_ALREADY_APPROVED_TOOLTIP
} from "./constants";
import css from "./index.sass";

const SurveyFeedbackModal = ({
  listingAddress,
  isModalOpen,
  setIsModalOpen,
  isEditable = false,
  isSeller = false,
  mlsNumber = null
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const uniqueId = queryParams.get("showing");
  const sellerAuthToken = queryParams.get("token");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [details, setDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [approvedForSellerTenantViewing, setApprovedForSellerTenantViewing] =
    useState(null);

  const setFeedbackAttemptVisibility = async () => {
    callApi(
      `listing/feedback_attempts/${details.feedbackAttemptId}`,
      {},
      { approved_for_seller_tenant_viewing: !approvedForSellerTenantViewing },
      "patch"
    )
      .then(response => {
        if (response.data) {
          setApprovedForSellerTenantViewing(!approvedForSellerTenantViewing);
          toast.success("Feedback updated successfully");
        } else {
          setLoading(false);
          toast.error("Please contact support for further assistance");
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
      });
  };

  const getDetailsForAgent = async () => {
    !loading && setLoading(true);

    const params = new URLSearchParams({
      mls_number: mlsNumber,
      page: page
    });

    callApi(`survey_feedbacks?${params.toString()}`, {}, {}, "get")
      .then(response => {
        if (response.data) {
          setDetails(response.data);

          if (response.data.approvedForSellerTenantViewing !== undefined) {
            setApprovedForSellerTenantViewing(
              response.data.approvedForSellerTenantViewing
            );
          }

          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Please contact support for further assistance");
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
      });
  };

  const getDetailsForSeller = async () => {
    try {
      !loading && setLoading(true);

      const params = new URLSearchParams({
        showing_id: uniqueId,
        page: page,
        seller_tenant: true
      });

      const response = await fetch(
        `${SELLER_SURVEY_FEEDBACK_URL}?${params.toString()}`,
        {
          method: "get",
          headers: { "Seller-Authorization": sellerAuthToken }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setDetails(data);
    } catch (error) {
      console.error(error.message.split(" ").pop());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSeller) {
      getDetailsForSeller();
    } else {
      getDetailsForAgent();
    }
  }, [page]);

  const handlePageChange = (pageNum, totalPages) => {
    if (pageNum > 0 && pageNum <= totalPages) {
      setPage(pageNum);
      setCurrentPage(pageNum);
    }
  };

  const {
    feedbackCreator,
    feedback,
    submittedAt,
    feedbackVisibilityApprovedOnly,
    totalPages
  } = details;

  return feedback && feedback.length > 0 ? (
    <Modal
      isOpen={isModalOpen}
      className={css.content}
      overlayClassName={css.overlay}
      onRequestClose={() => setIsModalOpen(false)}
      shouldCloseOnOverlayClick={true}
    >
      <div className={css.base}>
        <Header title="Feedback Survey" onClose={() => setIsModalOpen(false)} />
        <Body>
          <Row offset="0" className={css.details}>
            <div className={css.address}>{listingAddress}</div>
            <div className={css.subDetails}>
              <div className={css.submittedDate}>
                <span className={css.submittedOnTitle}>Submitted on</span>
                <div className={css.highlightedDate}>
                  {getFormattedDate(submittedAt)}
                  {!isSeller && (
                    <div className={css.highlightedSubmittedUser}>
                      {`By ${feedbackCreator?.name} (${feedbackCreator?.office})`}
                    </div>
                  )}
                  {feedbackCreator?.isListingTeam && (
                    <div className={css.listingTeamSubmission}>
                      (*This survey has been filled out by the listing team on
                      behalf of the showing agent)
                    </div>
                  )}
                </div>
              </div>
              {isEditable && feedbackVisibilityApprovedOnly && (
                <>
                  <div className={css.feedbackVisibilityApproval}>
                    <div className={css.feedbackVisibilityApprovalLabel}>
                      <Tooltip
                        text={SELLER_TENANT_APPROVED_TOOLTIP}
                        direction="up"
                      >
                        Approve For Seller/Tenant Viewing
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        text={SELLER_TENANT_ALREADY_APPROVED_TOOLTIP}
                        direction="up"
                        disabled={!approvedForSellerTenantViewing}
                      >
                        <Checkbox
                          name="advanceNoticeDisableSameDayRequests"
                          checked={approvedForSellerTenantViewing}
                          onChange={() =>
                            !approvedForSellerTenantViewing &&
                            setFeedbackAttemptVisibility()
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
              {details.showFeedbackAnalysis && (
                <div className={css.sentimentAnalysisHeader}>
                  <Col variant="liquid" modifier="half">
                    Feedback Analysis
                  </Col>
                  <Col variant="liquid" modifier="half">
                    <div className={css.feedbackAnalysisInfo}>
                      <div>
                        <span className={`${css.circle} ${css.positive}`} />
                        Positive
                      </div>
                      <div>
                        <span className={`${css.circle} ${css.negative}`} />
                        Negative
                      </div>
                    </div>
                  </Col>
                </div>
              )}
            </div>
          </Row>
          <div className={css.feedbackList}>
            {feedback.map((item, index) => (
              <div className={css.segment} key={index}>
                <Row className={css.question}>{item.question}</Row>
                <Row className={css.answer}>
                  <AccountIcon size={18} className={css.accountIcon} />
                  {details.showFeedbackAnalysis && (
                    <span
                      className={`${css.circle} ${css[item.sentiment]}`}
                    ></span>
                  )}
                  {item.answer}
                </Row>
              </div>
            ))}
            <div className={css.loaderWrapper}>
              <Loader active={loading} />
            </div>
          </div>
        </Body>

        <Footer>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </Footer>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default SurveyFeedbackModal;
