import React from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import InstructionsList from "../InstructionsList";

const Instructions = ({ message, bubbleOptions }) =>
  Object.values(message.instructions).every(instruction => !instruction) ? (
    <div></div>
  ) : (
    <Bubble variant="green" {...bubbleOptions}>
      <InstructionsList instructions={message.instructions} />
    </Bubble>
  );

Instructions.propTypes = {
  message: PropTypes.object,
  bubbleOptions: PropTypes.object
};

export default Instructions;
