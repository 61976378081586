import * as types from '~brokerage/constants/actionTypes'
import api from './api'

export function fetchTeamsOptions() {
  return api.teams.fetchTeamsOptions([
    types.TEAMS_OPTIONS_REQUEST,
    types.TEAMS_OPTIONS_SUCCESS,
    types.TEAMS_OPTIONS_FAILURE
  ])
}

export function setTeamInstructions(teamInstructions, listingId) {
  return {
    type: types.TEAM_INSTRUCTIONS_UPDATE,
    value: teamInstructions,
    listingId
  }
}
