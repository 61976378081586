import css from './ModalAgentDetails.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatPhone } from '~brokerage/libs/helpers/FormatHelper'
import Avatar from '~brokerage/components/shared/Avatar'
import List from '~brokerage/components/shared/List'
import { Header } from '~brokerage/components/modals/BaseModal'
import Loader from '~brokerage/components/shared/Loader'
import Button from '~brokerage/components/shared/Button'

class ModalAgentDetails extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    agent: PropTypes.object
  }

  renderItems(items, urlScheme, format) {
    return items.filter(item => Boolean(item.value)).map(({ value, type }, itemIndex) =>
      <List.Item key={itemIndex}>
        <a className={css.listItemLink} href={urlScheme + value}>
          <div className={css.title}>{type}</div>
          <div className={css.value}>{format ? format(value) : value}</div>
        </a>
      </List.Item>
    )
  }

  render() {
    const { agent } = this.props

    return (
      <div className={css.base}>
        {(() => {
          if (!agent) {
            return (
              <div className={css.loader}>
                <Loader variant="inline" active/>
              </div>
            )
          } else {
            return (
              <div>
                <Header variant="custom">
                  <div className={css.header}>
                    <div className={css.avatar}>
                      <Avatar size="48" src={agent.photoUrl} name={[agent.firstName, agent.lastName].join(" ")}/>
                    </div>
                    <div className={css.headerText}>
                      <h1 className={css.h1}>{agent.name}</h1>
                      {agent.office && <p className={css.p}>{agent.office}</p>}
                      {agent.license && <p className={css.p}>License# {agent.license}</p>}
                    </div>
                  </div>
                </Header>

                <div className={css.body}>
                  <List variant="bordered" modifier="overflowHidden">
                    {this.renderItems(agent.phones, 'tel:', formatPhone)}
                    {this.renderItems(agent.emails, 'mailto:')}

                    {agent.remarks &&
                      <List.Item>
                        <p>Remarks: {agent.remarks}</p>
                      </List.Item>
                    }
                  </List>
                </div>

                <div className={css.footer}>
                  <Button to={`/people/${agent.userId}`} variant="outline">View Full Profile</Button>
                </div>
              </div>
            )
          }
        })()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { entity } = state.showings.single.showing
  const { participants } = state.messages.single.conversationMessages
  let { agent_id: agentId, conversation_type: conversationType} = ownProps.location.query
  let agent

  agentId = parseInt(agentId)

  if (conversationType === 'direct' && participants){
    agent = participants.find(({userId}) => userId === agentId)
  } else if (entity) {
    const agents = entity.showingAgents.concat(entity.listingAgents)
    agent = agents.find(({userId}) => userId === agentId)
  }

  return {
    agent: agent
  }
}
export default connect(mapStateToProps)(ModalAgentDetails)
