import css from "./index.sass";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { callApi } from "brokerage/middlewares/api";
import { changeShowing, fetchShowing } from "~brokerage/actions/showings";
import { STATUS_PENDING } from "~brokerage/constants/showings/statuses";
import ErrorMessage from "~brokerage/components/shared/ErrorMessage";
import SidebarContainer from "./SidebarContainer";
import ShowingChatContainer from "./ShowingChatContainer";
import Summary from "./Summary";
// import Feedback from "./FeedbackContainer"; #Old Feedback
import FeedbackModal from "./FeedbackModal";

const ShowingScreen = (props) => {
  const {
    showing,
    shouldShowFeedback,
    errors,
    showingIsFetching,
    currentShowingRole,
    dispatch,
    showingId,
    showOptionsPanel
  } = props;
  const { listingKey } = showing;

  const [broadcastEnabled, setBroadcastEnabled] = useState(true);
  const [isBroadcastSettingLoading, setIsBroadcastSettingLoading] = useState(false);

  useEffect(() => {
    if(showingId !== null){
      dispatch(fetchShowing(showingId));
    }
  }, [showingId]);

  useEffect(() => {
    if(!listingKey){
      return;
    }

    setIsBroadcastSettingLoading(true);
    callApi("user_listing_settings/broadcast_messages_enabled_status", { listing_key: listingKey }, {}, "get")
      .then(response => {
        setBroadcastEnabled(response.data.data.broadcast_enabled);
      })
      .finally(() => {
        setIsBroadcastSettingLoading(false);
      });

  }, [listingKey]);

  const hasActions = () => {
    const { isPassed, status } = showing;

    const isLA = currentShowingRole === "la";

    return isLA && !isPassed && status === STATUS_PENDING;
  };

  const handleStatusChange = status => {
    dispatch(changeShowing(showingId, { status }));
  };

  const handleBroadcastSettingChange = async () => {
    const responseStatus = broadcastEnabled ? "disabled" : "enabled";

    await callApi(
      "user_listing_settings",
      { listing_key: listingKey, user_listing_setting: { receive_broadcast_message: !broadcastEnabled } },
      {},
      "patch"
    )
    .then(response => {
      if (response.data) {
        setBroadcastEnabled(response.data.data.broadcast_enabled);
        toast.success(`Broadcast messages from this listing ${responseStatus} successfully.`);
      } else {
        toast.error(`Could not ${responseStatus} broadcast messages from this listing.`);
      }
    })
    .catch(err => {
      toast.error(err);
    });
  };

  const optionsPanelClassName = showOptionsPanel ? css.showOptions : css.sidebar;

  if (errors) {
    return (
      <article className={css.base}>
        <ErrorMessage message={errors} />
      </article>
    );
  }

  return (
    <article className={css.base}>
      <aside className={optionsPanelClassName}>
        <SidebarContainer
          showingId={showingId}
          onStatusChange={handleStatusChange}
        />
      </aside>
      <div className={css.main}>
        <Summary
          showing={showing}
          showingIsFetching={showingIsFetching}
          broadcastEnabled={broadcastEnabled}
          onBroadcastSettingChange={handleBroadcastSettingChange}
          isBroadcastSettingLoading={isBroadcastSettingLoading}
        />
        <ShowingChatContainer
          showingId={showingId}
          hasActions={hasActions()}
          showingIsFetching={showingIsFetching}
        />
        <FeedbackModal
          showingId={showingId}
          broadcastEnabled={broadcastEnabled}
          onBroadcastSettingChange={handleBroadcastSettingChange}
        />
      </div>
    </article>
  );
};

ShowingScreen.propTypes = {
  showingId: PropTypes.string.isRequired,
  showing: PropTypes.object,
  currentShowingRole: PropTypes.string,
  errors: PropTypes.string,
  showingIsFetching: PropTypes.bool,
  shouldShowFeedback: PropTypes.bool,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const {
    entity: showing,
    errors,
    roleForLastShowing: currentShowingRole,
    isFetching: showingIsFetching
  } = state.showings.single.showing;
  const { shouldShowFeedback } = state.showings.single.feedback;

  return {
    showing,
    shouldShowFeedback,
    errors,
    showingIsFetching,
    currentShowingRole
  };
}

export default connect(mapStateToProps)(ShowingScreen);
