import React, { useState } from "react";
import { toast } from "react-toastify";

import { callApi } from "brokerage/middlewares/api";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";
import Autocomplete from "~brokerage/components/shared/Autocomplete";
import css from "./OnBehalfAgentSearch.sass";

const OnBehalfAgentSearch = props => {
  const {
    selectedBuyerAgent,
    setSelectedBuyerAgent
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [people, setPeople] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const options = people.map(person => ({ value: person.id, label: `${person.name} (${person.officeName})` }));

  const handleSearch = async (searchInput) => {
    setIsFetching(true);
    await callApi("people/restricted_search", {filter: { search: searchInput}}, {}, "get")
      .then(response => {
        setIsFetching(false);
        if (response.data && response.data.people) {
          setPeople(response.data.people);
        } else {
          toast.error("Invalid API response for agents search");
        }
      })
      .catch(err => {
        setIsFetching(false);
        toast.error(err);
      });
  };

  const handleChange = event => {
    const value = event.target.value;

    if (value.length >= 4) {
      handleSearch(value);
    }
    if(value.length === 0) {
      setPeople([]);
    }
    setSearchTerm(value);
  };

  const handleSelect = (option) => {
    if (!option.value) {
      return
    }
    const person = people.find(person => person.id === option.value);

    setSelectedBuyerAgent({...person});
  }

  const handleSubmit = () => {
    if (searchTerm.length > 2) {
      handleSearch(searchTerm);
    }
  }

  const handleRemoveClick = () => {
    setSelectedBuyerAgent(null);
    setSearchTerm("");
    setPeople([]);
  }

  return (
    <div>
      {!selectedBuyerAgent ?
        (<div className={css.search}>
          <Autocomplete
            value={searchTerm}
            options={options}
            onSelect={handleSelect}
            onChange={handleChange}
            scrollable={true}
            height={150}
            placeholder="Setup a showing on behalf of"
            variant="createAppointmentModalWindow"
            fetching={isFetching}
          />
          <Button variant="search" type="submit" onClick={handleSubmit}>
            <div className={css.icon}> <Icon shade="gray4" name="search" size={18} /> </div>
          </Button>
  	    </div>) :
        (<div className={css.result}>
           <div>
            {selectedBuyerAgent.name}
            <br/>
            {`(${selectedBuyerAgent.officeName})`}
         </div>
           <div>
             <Button variant="icon" onClick={handleRemoveClick} >
               <div className={css.icon}><Icon name="close" size={18}/></div>
             </Button>
           </div>
        </div>)
      }
    </div>
  );
};

export default OnBehalfAgentSearch;
