import css from './Showings.sass'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchShowings } from '~brokerage/actions/newShowing'
import { datetimeToPixels } from '~brokerage/libs/helpers/TimeHelper'
import Showing from './Showing'

class Showings extends React.PureComponent {
  static propTypes = {
    conflicts: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.string]),
    overlappingShowings: PropTypes.array,
    showings: PropTypes.array,
    date: PropTypes.string,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func,
    showing: PropTypes.object
  }

  componentWillMount() {
    this.props.dispatch(fetchShowings(this.props.date, this.props.showing))
  }

  renderShowing(showing) {
    const hasOverlapping = this.props.overlappingShowings.includes(showing.id)
    const className = hasOverlapping ? 'showingOverlapping' : 'showing'
    const top = datetimeToPixels(showing.requestedTimeRaw)

    return (
      <div key={showing.id} className={css[className]} style={{ top }}>
        <Showing showing={showing}/>
      </div>
      )
  }

  render() {
    const { showings } = this.props
    return (
      <div className={css.base}>
        {showings && showings.map(showing => this.renderShowing(showing))}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { entities: showings, isFetching } = state.showings.new.showings
  const { conflicts, date, overlappingShowings } = state.showings.new

  return {
    showings,
    isFetching,
    conflicts,
    overlappingShowings,
    date
  }
}
export default connect(mapStateToProps)(Showings)
