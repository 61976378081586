import {
  MAPBOX_OPTIMIZE_URL,
  MAPBOX_MATRIX_URL
} from "~brokerage/app/constants/apiUrls";
import { externalApi } from "brokerage/middlewares/api";
import getCoordinates from "./getCoordinates";
import moment from "moment";
import { updateShowingRoute } from "~brokerage/actions/routes";
import { toast } from "react-toastify";
import { determineConflicts } from "~brokerage/components/shared/Timeline/Main/ActiveAppointmentList/util";
import {
  sortShowingsBasedOnWaypoints,
  buildShowingChanges,
  reorderShowings
} from "./helper";

const toastMessages = {
  pending: "Calculating fastest route",
  success: "Fastest route calculated",
  error: "Sorry, Something went wrong"
};

const toastRestrictionError = () =>
  toast.error(
    "One or more showings have moved into restricted times. Please reorder restricted showings in order to request them.",
    { toastId: "restriction-error" }
  );

const fetchOptimizedRoute = async (coordinates, mapboxkey) => {
  const { data } = await toast.promise(
    externalApi(
      `${MAPBOX_OPTIMIZE_URL}${coordinates}`,
      {
        overview: "full",
        source: "first",
        destination: "last",
        roundtrip: false,
        access_token: mapboxkey
      },
      {},
      "get"
    ),
    toastMessages
  );

  return data;
};

const calculateUnsavedChanges = ({
  showingOrder,
  orderedShowings,
  legs,
  showings,
  restrictions
}) => {
  const unsavedChanges = [];
  let nextShowingStartTime = moment(showings[0].start_time);

  const sortedShowings = sortShowingsBasedOnWaypoints(
    orderedShowings,
    showingOrder,
    legs
  );

  sortedShowings.forEach((showing, index) => {
    const isStop = showing.type === "stop";

    if (index > 0) nextShowingStartTime.add(showing.travelTime, "minutes");

    const conflict = isStop
      ? null
      : calculateConflict(showing, nextShowingStartTime, restrictions);

    if (conflict === "unavailable") toastRestrictionError();

    const changes = buildShowingChanges({
      showing,
      isStop,
      nextShowingStartTime,
      index,
      conflict
    });

    if (isStop) {
      Object.assign(changes, {
        address: showing.address,
        type: "stop",
        coordinates: showing.coordinates
      });
    }

    unsavedChanges.push(changes);
    nextShowingStartTime.add(showing.duration, "minutes");
  });

  return unsavedChanges;
};

const calculateFastestRoute = async ({
  showings,
  startShowing,
  mapboxkey,
  dispatch,
  restrictions
}) => {
  const orderedShowings = startShowing
    ? reorderShowings(showings, startShowing)
    : showings;

  const matrixCoords = getCoordinates(orderedShowings);
  const destinationIndex = await calcBestDestination(matrixCoords, mapboxkey);
  orderedShowings.push(orderedShowings.splice(destinationIndex, 1)[0]);
  const coordinates = getCoordinates(orderedShowings);

  const { trips, waypoints } = await fetchOptimizedRoute(
    coordinates,
    mapboxkey
  );

  const showingOrder = waypoints.map(w => w.waypoint_index);
  const unsavedChanges = calculateUnsavedChanges({
    showingOrder,
    orderedShowings,
    legs: trips[0].legs,
    showings,
    restrictions
  });

  dispatch(
    updateShowingRoute({
      unsavedChanges,
      optimized: startShowing,
      map_geometry: trips[0].geometry
    })
  );
};

const calculateConflict = (showing, newStartTime, restrictions) => {
  const { duration, listing_key, unique_id } = showing;
  const { unavailability = [], allowOverlap = false } =
    restrictions[listing_key];
  return determineConflicts({
    unavailability,
    nextDate: newStartTime,
    duration,
    showingId: unique_id,
    allowOverlap
  });
};

const calcBestDestination = async (coordinates, mapboxkey) => {
  const { data } = await externalApi(
    `${MAPBOX_MATRIX_URL}${coordinates}`,
    {
      access_token: mapboxkey
    },
    {},
    "get"
  );
  const furthest = Math.max(...data.durations[0]);
  return data.durations[0].indexOf(furthest);
};

export default calculateFastestRoute;
