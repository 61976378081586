import axios from 'axios'
import memoize from 'lodash/memoize'
const CancelToken = axios.CancelToken

const jwtToken = memoize(function () {
  return document.querySelector('meta[name=jwt_token]').content
})

const fetch = (url, options, withJWT = true) => {
  const source = CancelToken.source()

  if (withJWT) {
    if (!options.headers) {
      options.headers = {}
    }

    options.headers.Authorization = `Bearer ${jwtToken()}`
  }

  Object.assign(options, {
    url,
    cancelToken: source.token,
    paramsSerializer: function (params) {
      return $.param(params)
    }
  })

  const request = axios(options)
  request.cancel = source.cancel

  return request
}

export default fetch
