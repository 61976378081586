import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";
import css from "~brokerage/components/people/CategoryButton.sass";

export default class CategoryButton extends React.PureComponent {
  static propTypes = {
    categoryValue: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    location: PropTypes.object,
    count: PropTypes.number,
    active: PropTypes.bool,
    href: PropTypes.string
  };

  render() {
    const {
      categoryValue,
      categoryLabel,
      location,
      iconName,
      count,
      active,
      href
    } = this.props;
    const query = { ...location.query };

    if (categoryValue === "all_agents") {
      delete query.category;
    } else {
      query.category = categoryValue;
    }

    const to = href || { pathname: "/people", query: query };

    return (
      <Button to={to} variant="header2" active={active}>
        <i className={css.icon}>
          <Icon name={iconName} />
        </i>
        <span>{categoryLabel}</span>{" "}
        <span className={css.categoryCount}>
          {count ? formatNumberUsingCommas(count) : ""}
        </span>
      </Button>
    );
  }
}
