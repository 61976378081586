import React, { useState } from "react";
// import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";

import Button from "brokerage/app/components/shared/Button";
import Icon from "brokerage/app/components/shared/Icon";

import css from "./SearchBar.sass";

const SearchBar = ({ setSearchTerm, placeholderText, value }) => {
  const [inputText, setInputText] = useState(
    typeof value !== "undefined" ? value : ""
  );

  const onSearchTermChange = event => {
    const searchTerm = event.target.value;
    setInputText(searchTerm);
    if (searchTerm.length > 2) {
      setSearchTerm(searchTerm);
    } else {
      setSearchTerm("");
    }
  };

  const resetSearchTerm = () => {
    setInputText("");
    setSearchTerm("");
  };

  return (
    <div className={css.container}>
      <input
        type="text"
        placeholder={placeholderText}
        onChange={onSearchTermChange}
        autoComplete="off"
        className={css.base}
        value={inputText}
      />
      <div className={css.btnSearch}>
        <Button type="submit" variant="searchDark" onClick={resetSearchTerm}>
          <div className={css.btnContent}>
            {inputText.length > 0 ? (
              <CloseCircleFillIcon size={18} />
            ) : (
              <Icon name="search" />
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
