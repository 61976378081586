import React from "react";
import { Route, Switch } from "react-router-dom";
import MarketSelectionPage from "~brokerage/app/components/login/MarketSelectionPage";
import LoginPage from "~brokerage/app/components/login/LoginPage";

const HomePage = props => {
  return (
    <Switch>
      <Route path='/' exact={true} component={() => <MarketSelectionPage invalidLogin={props.invalidLogin}/>} />
      <Route path='/login' exact={true} component={LoginPage} />
    </Switch>
  );
}

export default HomePage;
