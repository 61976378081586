import css from "./ShortInfoCustomer.sass";
import React from "react";
import PropTypes from "prop-types";
import Avatar from "~brokerage/components/shared/Avatar";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import { useLocation, useHistory } from "react-router-dom";

const ShortInfoCustomer = props => {
  const { name, role, photoUrl, id } = props.person;

  return (
    <div className={css.base}>
      <div className={css.photo}>
        <Avatar size="37" src={photoUrl} name={name} />
      </div>
      <div className={css.nameRoleContainer}>
        <div className={css.name}>
          <h1 className={css.h1}>{name}</h1>
          <span className={css.role}>{role}</span>
        </div>
      </div>
    </div>
  );
};

ShortInfoCustomer.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string
  }),
  dispatch: PropTypes.func
};

export default ShortInfoCustomer;
