import React from "react";
import css from "./Footer.sass";

const Footer = ({ changeDetails }) => {
  return (
    <div className={css.footer}>
      <a href="/users/sign_out">
        <button className={css.logoutButton}>Logout</button>
      </a>
      <button className={css.acceptButton} onClick={changeDetails}>
        Accept
      </button>
    </div>
  );
};

export default Footer;
