import {
  PUSH_NOTIFICATIONS_BANNER_SHOWN,
  PUSH_NOTIFICATIONS_MODAL_SHOWN,
  PUSH_NOTIFICATIONS_REQUEST_SUBSCRIPTION,
  PUSH_NOTIFICATIONS_BLOCKED,

  PUSH_NOTIFICATIONS_SUBSCRIBE_REQUEST,
  PUSH_NOTIFICATIONS_SUBSCRIBE_SUCCESS,
  PUSH_NOTIFICATIONS_SUBSCRIBE_FAILURE,

  PUSH_NOTIFICATIONS_UNSUBSCRIBE_REQUEST,
  PUSH_NOTIFICATIONS_UNSUBSCRIBE_SUCCESS,
  PUSH_NOTIFICATIONS_UNSUBSCRIBE_FAILURE,

  PUSH_NOTIFICATIONS_DISMISSED
} from '~brokerage/constants/actionTypes'

const defaultState = {
  isBannerShown: false,
  isModalShown: false,
  isGuideModalShown: false,

  isBlocked: false,
  isSubscribed: false,
  isDismissed: false,
  isSendingSubscriptionToServer: false
}

export default function pushNotificationsReducer(state = defaultState, action) {
  switch (action.type) {
    case PUSH_NOTIFICATIONS_BANNER_SHOWN:
      return {
        ...state,
        isBannerShown: true,
        isModalShown: false,
        isGuideModalShown: false
      }
    case PUSH_NOTIFICATIONS_MODAL_SHOWN:
      return {
        ...state,
        isBannerShown: false,
        isModalShown: true,
        isGuideModalShown: false
      }
    case PUSH_NOTIFICATIONS_REQUEST_SUBSCRIPTION:
      return {
        ...state,
        isBannerShown: false,
        isModalShown: false,
        isGuideModalShown: true
      }
    case PUSH_NOTIFICATIONS_BLOCKED:
      return {
        ...state,
        isGuideModalShown: false,
        isBlocked: true
      }
    case PUSH_NOTIFICATIONS_SUBSCRIBE_REQUEST:
      return {
        ...state,
        isGuideModalShown: false,
        isSendingSubscriptionToServer: true,

        isDismissed: false,
        dismissedAt: null
      }
    case PUSH_NOTIFICATIONS_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSendingSubscriptionToServer: false,
        isSubscribed: true,
        endpoint: action.endpoint
      }
    case PUSH_NOTIFICATIONS_SUBSCRIBE_FAILURE:
      return {
        ...state,
        isSendingSubscriptionToServer: false
      }
    case PUSH_NOTIFICATIONS_UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        isDeletingSubscriptionFromServer: true,

        isDismissed: false,
        dismissedAt: null
      }
    case PUSH_NOTIFICATIONS_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isDeletingSubscriptionFromServer: false,
        isSubscribed: false,
        endpoint: null
      }
    case PUSH_NOTIFICATIONS_UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        isDeletingSubscriptionFromServer: false
      }
    case PUSH_NOTIFICATIONS_DISMISSED:
      return {
        ...state,
        isBannerShown: false,
        isModalShown: false,
        isGuideModalShown: false,

        isDismissed: true,
        dismissedAt: action.time
      }
    default:
      return state
  }
}
