import React, { useState, useEffect } from "react";
import css from "./SmartAppBanner.sass";
import appIcon from "~brokerage/assets/images/app-logo-mark.svg";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import { disableBanner, isBannerDisabled, isMobileBrowser } from "./util";

const META_TAG_WITH_APP_LINK = "meta[name=application_store_link]";

const SmartAppBanner = () => {
  const [shouldShow, setShouldShow] = useState(false);
  const linkToApp = document.querySelector(META_TAG_WITH_APP_LINK).content;

  useEffect(() => {
    if (isMobileBrowser() && !isBannerDisabled()) setShouldShow(true);
  }, []);

  const closeBanner = () => {
    disableBanner();
    setShouldShow(false);
  };

  if (shouldShow)
    return (
      <div className={css.bannerOverlay}>
        <div className={css.bannerContainer}>
          <div className={css.appLogo}>
            <img src={appIcon} width="46" height="46" alt="app-icon" />
          </div>
          <div className={css.flex}>
            <h4>Aligned Showings</h4>
            <p>MLS Aligned</p>
            {!!linkToApp ? (
              <a href={linkToApp} className={css.getBtn}>
                Get it on the store
              </a>
            ) : (
              <p>Coming soon to Appstore and Playstore!</p>
            )}
          </div>
          <button className={css.closeBtn} onClick={closeBanner}>
            <CloseLineIcon size={20} />
          </button>
        </div>
      </div>
    );
  else return null;
};

export default SmartAppBanner;
