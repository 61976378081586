import css from "./ListItem.sass";
import React from "react";
import PropTypes from "prop-types";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { setBroadcastListingId } from "~brokerage/actions/messages";
import Actions from "../Actions";

const counterShape = {
  total: PropTypes.number,
  approvedCount: PropTypes.number,
  unread: PropTypes.number
};

class ListItem extends React.PureComponent {
  static propTypes = {
    listing: PropTypes.shape({
      id: PropTypes.string,
      editableByCurrentUser: PropTypes.bool,
      address: PropTypes.string,
      mlsNumber: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      status: PropTypes.string,
      type: PropTypes.string,
      isSetup: PropTypes.bool,
      showings: PropTypes.shape(counterShape),
      messages: PropTypes.shape(counterShape),
      feedback: PropTypes.shape(counterShape),
      listingAgent: PropTypes.string,
      sellersTenants: PropTypes.array
    })
  };

  static contextTypes = {
    currentUser: PropTypes.object
  };

  get isEditable() {
    return this.props.listing.editableByCurrentUser;
  }

  handleClick = () => {
    if (this.isEditable) {
      this.props.history.push(`/listings/${this.props.listing.id}`);
    }
  };

  handleEditClick = e => {
    if (this.isEditable) {
      this.props.history.push(`/listings/${this.props.listing.id}/edit`);
      e.stopPropagation();
    }
  };

  handleBroadCastClick = e => {
    this.props.dispatch(setBroadcastListingId(this.props.listing.id));
    this.props.history.push({
      pathname: this.props.location.pathname,
      query: {
        ...this.props.location.query,
        modal: "send_listing_broadcast_message"
      }
    });
    e.stopPropagation();
  };

  renderCounter(counter) {
    if (counter.unread > 0) {
      return <strong>{counter.unread} new</strong>;
    } else {
      return counter.total;
    }
  }

  renderSellersTenants() {
    const [firstContact, ...otherContacts] = this.props.listing.sellersTenants;
    const othersCount = otherContacts.length;
    const text = `+ ${othersCount} more`;

    if (othersCount < 1) {
      return firstContact;
    } else {
      return (
        <span>
          <Tooltip className={css.tooltip} text={otherContacts.join(", ")}>
            {firstContact}
            <span className={css.otherSellersWrapper}>{text}</span>
          </Tooltip>
        </span>
      );
    }
  }

  render() {
    const displayShowings = this.isEditable && this.props.agentId != null;
    const rowClass = this.isEditable ? css.rowClickable : css.row;
    const {
      showings: { approvedCount },
      address,
      feedback,
      mlsNumber,
      price,
      status,
      showingsEnabled,
      showings,
      messages,
      listingAgent,
      contingencyString
    } = this.props.listing;
    const { unlimitedBroadcastMessagesAllowed } = this.context.currentUser;

    return (
      <div className={rowClass} onClick={this.handleClick}>
        <div className={css.col6}>
          <div className={css.address}>{address}</div>
          <Actions
            canEdit={this.isEditable}
            showFeedback={feedback.total > 0}
            onEditClick={this.handleEditClick}
            onBroadCastClick={this.handleBroadCastClick}
            listing={this.props.listing}
            showings={{ approvedCount, unlimitedBroadcastMessagesAllowed }}
            dispatch={this.props.dispatch}
          />
        </div>
        <div className={css.col2}>{mlsNumber}</div>
        <div className={css.col2}>{formatPrice(price)}</div>
        <div className={`${css.col} ${css.hiddenResponsiveTableUnit}`}>
          {`${status}${contingencyString}`}
        </div>
        <div className={css.col2}>{showingsEnabled ? "Yes" : "No"}</div>

        {/* <div className={css.col}>{listing.type}</div>
        <div className={css.col}>{listing.isSetup ? 'Yes' : 'No'}</div> */}
        <div className={css.col}>
          {displayShowings && this.renderCounter(showings)}
        </div>
        <div className={css.col}>{this.renderCounter(messages)}</div>
        <div className={css.col}>{this.renderCounter(feedback)}</div>
        <div className={`${css.col2} ${css.hiddenResponsiveTableUnit}`}>
          {listingAgent}
        </div>
        <div className={`${css.col2} ${css.hiddenResponsiveTableUnit}`}>
          {this.renderSellersTenants()}
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ListItem));
