import ReactOnRails from "react-on-rails";
import Client from "../brokerage/client";
import Home from "../brokerage/home";
import SellerLanding from "../brokerage/SellerLanding";
import BuyerLanding from "../brokerage/BuyerLanding";

ReactOnRails.register({ Home });
ReactOnRails.register({ Client });
ReactOnRails.register({ SellerLanding });
ReactOnRails.register({ BuyerLanding });
