import React from "react";

import Loader from "~brokerage/components/shared/Loader";
import { Form, Row, Section } from "~brokerage/components/shared/Form";

import ShowingOptions from "./ShowingOptions";
import ShowingExplanation from "./ShowingExplanation";
import Header from "../Header";
import AdditionalDetails from "../AdditionalDetails";
import Button from "~brokerage/components/shared/Button";

const ModalContents = ({
  loading,
  details,
  changeDetails,
  pushDetails,
  resetDetails,
  closeModal,
  seller,
  isInstructionsEditable
}) => {
  if (loading) {
    return <Loader active />;
  }

  const { message, accompanyAgent, accompanySeller, requestACall } = details;

  return (
    <Form>
      <Header headerText="Add Comments" closeModal={closeModal} />

      <Section heading="Why can’t the property be shown?">
        <ShowingExplanation details={details} changeDetails={changeDetails} />
      </Section>

      {!seller && (
        <Section>
          <ShowingOptions
            accompanyAgent={accompanyAgent}
            accompanySeller={accompanySeller}
            requestACall={requestACall}
            changeDetails={changeDetails}
          />
        </Section>
      )}

      <Section heading="Optional Message">
        <AdditionalDetails
          message={message}
          changeDetails={changeDetails}
          placeholder="Enter Optional Message for Listing Team"
          isEditable={isInstructionsEditable}
        />
      </Section>

      <div style={{ marginTop: "17px", color: "#97291d" }}>
        Declined appointments cannot be reversed and a new appointment will need
        to be requested.
      </div>

      <Row>
        {isInstructionsEditable && (
          <Button
            onClick={resetDetails}
            float="left"
            variant="outline"
            modifier="bigger"
          >
            Clear
          </Button>
        )}
        <Button
          onClick={pushDetails}
          float="right"
          variant="red"
          modifier="bigger"
        >
          {`Decline and Send ${isInstructionsEditable ? "Instructions" : ""}`}
        </Button>
      </Row>
    </Form>
  );
};

export default ModalContents;
