import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function fetchNotifications(page = 1) {
  return api.notifications.fetchNotifications({ page }, [
    types.FETCH_NOTIFICATIONS_REQUEST,
    types.FETCH_NOTIFICATIONS_SUCCESS,
    types.FETCH_NOTIFICATIONS_FAILURE
  ]);
}
