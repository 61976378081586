import React from "react";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import css from "./Question.sass";
import { Dropdown } from "~brokerage/components/shared/Form";
import { ANSWER_OPTIONS, OPTIONS_TYPE } from "./constants";

export default function Question({
  questionData = {},
  questionIndex,
  onRemoveQuestion,
  onEditQuestionText,
  onChangeAnswerType,
  onEditOptionText,
  onAddOption,
  onRemoveOption,
  disableQuestionRemoval,
  isDefaultSurvey
}) {
  const { qIndex, question, options, answer_type } = questionData;
  const disabledOptionRemove = options.length <= 2;
  const showOptions = answer_type === OPTIONS_TYPE;

  return (
    <div className={css.questionWrapper}>
      <div className={css.row}>
        <p className={css.label}>Question {questionIndex}:</p>
        <input
          placeholder="Enter survey question"
          className={css.input}
          value={question}
          onChange={event => onEditQuestionText(qIndex, event.target.value)}
          disabled={isDefaultSurvey}
        />
      </div>
      <div className={css.row}>
        <p className={css.label}>Response Type:</p>
        <Dropdown
          name="answerType"
          options={ANSWER_OPTIONS}
          selected={answer_type}
          onChange={({ answerType }) => onChangeAnswerType(qIndex, answerType)}
          variant="outline2"
          disabled={isDefaultSurvey}
        />
      </div>
      {showOptions ? (
        <div className={css.row}>
          <div className={css.answersContainer}>
            {options.map(({ option, optIndex }, answerIndex) => (
              <div className={css.row} key={`answer-${qIndex}-${optIndex}`}>
                <p className={css.label}>Option {answerIndex + 1}:</p>
                <input
                  placeholder="Enter survey reply"
                  style={{ marginRight: "12px" }}
                  className={css.input}
                  value={option}
                  onChange={event =>
                    onEditOptionText(qIndex, optIndex, event.target.value)
                  }
                  disabled={isDefaultSurvey}
                />
                {!isDefaultSurvey && (
                  <Button
                    variant="outlineRed"
                    onClick={() => onRemoveOption(qIndex, optIndex)}
                    disabled={disabledOptionRemove}
                  >
                    <Icon name="close" /> Remove
                  </Button>
                )}
              </div>
            ))}
            {!isDefaultSurvey && (
              <Button variant="outline" onClick={() => onAddOption(qIndex)}>
                <Icon name="plus" /> New Option
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={css.demoInputWrapper}>
          <input
            className={css.demoInput}
            placeholder="Survey recipient's response will be entered in a similar field."
            disabled
          />
        </div>
      )}
      {!isDefaultSurvey && (
        <div className={css.removeRow}>
          <Button
            variant="outlineRed"
            onClick={() => onRemoveQuestion(qIndex)}
            disabled={disableQuestionRemoval}
          >
            <Icon name="close" /> Remove Question
          </Button>
        </div>
      )}
    </div>
  );
}
