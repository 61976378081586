import React from "react";
import css from "./index.sass";

export default function TableHeader() {
  return (
    <div className={css.header}>
      <div className={css.col6}>Address</div>
      <div className={css.col2}>MLS #</div>
      <div className={css.col2}>Price</div>
      <div className={`${css.col} ${css.hiddenResponsiveTableUnit}`}>
        Status
      </div>
      <div className={css.col2}>Allow Showings?</div>

      {/* <div className={css.col}>Type</div>
            <div className={css.col}>Showing Setup</div> */}
      <div className={css.col}>Showings</div>
      <div className={css.col}>Messages</div>
      <div className={css.col}>Feedback</div>
      <div className={`${css.col2} ${css.hiddenResponsiveTableUnit}`}>
        Listing Agent
      </div>
      <div className={`${css.col2} ${css.hiddenResponsiveTableUnit}`}>
        Seller
      </div>
    </div>
  );
}
